/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type TimeReportStatus =
  (typeof TimeReportStatus)[keyof typeof TimeReportStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TimeReportStatus = {
  Created: 1,
  Approved: 2,
  Declined: 3,
  Removed: 4,
} as const;
