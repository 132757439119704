import { AuthenticationClient, ResetPasswordRequest } from 'api';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import SectionHeader from 'components/SectionHeader';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import Routes from 'constants/Routes';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import { validPasswordDescription, validPasswordRegex } from 'utils/regex';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  gap: 20px;
`;

const P = styled.p`
  color: red;
`;

const ResetPasswordPage: React.FC = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const emailParam = searchParams.get('email');
  const tokenParam = searchParams.get('token');

  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [message, setMessage] = useState('');

  const resetPasswordCall = useApiCall(
    AuthenticationClient,
    (c, email: string, token: string, password: string) =>
      c.resetPassword({
        email,
        token,
        newPassword: password,
      } as ResetPasswordRequest)
  );

  useEffect(() => {
    if (message !== '' && newPassword !== '' && confirmPassword !== '') {
      setMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newPassword, confirmPassword]);

  const handleSubmit = useCallback(async () => {
    if (newPassword !== confirmPassword) {
      setNewPassword('');
      setConfirmPassword('');
      setMessage('Du har skrivit in olika lösenord.');
      return;
    }

    if (!emailParam || !tokenParam) {
      return;
    }

    const [error] = await resetPasswordCall.run(
      emailParam,
      tokenParam,
      newPassword
    );

    if (!error) {
      navigate('/');
    } else {
      setNewPassword('');
      setConfirmPassword('');
      setMessage('Misslyckades, försök igen med ett säkrare lösenord.');
    }
  }, [
    confirmPassword,
    emailParam,
    navigate,
    newPassword,
    resetPasswordCall,
    tokenParam,
  ]);

  const handleEnterPress = (key: string) => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  const isPasswordValid = useMemo(
    () =>
      newPassword === confirmPassword && newPassword.match(validPasswordRegex),
    [newPassword, confirmPassword]
  );

  if (!emailParam || !tokenParam) {
    return <Navigate replace to={Routes.index} />;
  }

  return (
    <Container>
      <SectionHeader>Välj ett nytt lösenord</SectionHeader>

      <p>{validPasswordDescription}</p>
      <Input
        onChange={(e) => setNewPassword(e.currentTarget.value)}
        placeholder="Nytt lösenord"
        type="password"
        value={newPassword}
        width="100%"
      />
      <Input
        onChange={(e) => setConfirmPassword(e.currentTarget.value)}
        placeholder="bekräfta lösenordet"
        type="password"
        value={confirmPassword}
        width="100%"
      />

      <Button
        disabled={
          !isPasswordValid ||
          resetPasswordCall.status === RequestStatus.Fetching
        }
        onClick={() => handleSubmit()}
        onKeyPress={(e) => handleEnterPress(e.key)}
        type="button"
      >
        {resetPasswordCall.status === RequestStatus.Fetching ? (
          <TransparentSpinner color={theme.colors.foreground.button} small />
        ) : (
          'Byt lösenord'
        )}
      </Button>

      <P>{message}</P>
    </Container>
  );
};

export default ResetPasswordPage;
