import { ExpenseModel } from 'api';
import Modal, { modalContentClass } from 'components/Modal';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { useDetailedCaseContext } from '../DetailedCaseProvider';
import { ExpenseEditorInputs } from './ExpenseEditor';
import useExpenseEditorForm from './ExpenseEditor/useExpenseEditorForm';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    min-width: 500px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    label,
    input,
    select {
      flex: 1;
      width: auto;
    }
  }
`;

interface Props {
  assignmentId: number;
  onCancel(): void;
}

const NewExpenseModal: FC<Props> = ({ assignmentId, onCancel }) => {
  const {
    onSaveExpense,
    caseResponse: { response: currentCase },
  } = useDetailedCaseContext();
  const currentCaseId = currentCase?.case.caseID;

  const handleSaveExpense = useCallback(
    (expense: ExpenseModel) => {
      if (currentCaseId === undefined) return;

      const newExpense = new ExpenseModel(expense);
      newExpense.assignmentID = assignmentId;
      onSaveExpense(newExpense);
    },
    [assignmentId, currentCaseId, onSaveExpense]
  );

  const form = useExpenseEditorForm(undefined, handleSaveExpense);

  return (
    <MyModal
      buttons={[
        { label: 'Skapa', onClick: form.onSaveClick },
        { label: 'Avbryt', onClick: onCancel },
      ]}
      onClose={onCancel}
      title="Ny utgift"
    >
      <ExpenseEditorInputs expenseEditorForm={form} />
    </MyModal>
  );
};

export default NewExpenseModal;
