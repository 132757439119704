import { FC, useEffect, useMemo } from 'react';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';
import AssignmentsList from 'components/AssignmentsList';
import { AssignmentExtendedModel } from 'api';
import { RequestStatus } from 'swaggerhooks/lib';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import ErrorTryAgain from 'components/ErrorTryAgain';
import useSearchedAssignments from './useSearchedAssignments';
import SearchBar from 'pages/GlobalSearch/searchBar/SearchBar';
import styled from 'styled-components';
import BulkOperations from './BulkOperations';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import { selectableFiltersByLabel } from 'components/filterBar/FilterPicker';
import BulkApproveAssignmentsList from './BulkApproveAssignmentsList';
import BulkMessages from './BulkMessages';
import BulkApproval from './BulkApproval';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import { MenuContainer } from 'components/SubMenu';
import TableDropdown from 'components/Table/TableDropdown';
import useAssignmentListColumnSettings from 'components/AssignmentsList/useAssignmentListColumnSettings';
import useActiveColumns, {
  TableIdentifierEnum,
} from 'contexts/basicData/useActiveColumns';

const ValidationMessage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-style: italic;
  font-weight: 600;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const BulkOperationsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  & > * {
    justify-content: flex-start;
  }
`;

interface Props {
  casePathPattern: string;
}

const GlobalSearchPage: FC<Props> = ({ casePathPattern }) => {
  const me = useMe();
  const userIsGoSupport = me?.roles.includes(Roles.GoSupport);
  const navigate = useNavigate();
  const { stateEqualsFilterPreset, setSkip, triggerAssignmentsUpdate } =
    useAssignmentSearch();
  const {
    assignments,
    total,
    skip,
    size,
    status,
    update,
    searchInputsInvalidMessage,
    assignmentsBulkOperations,
    downloadCsv,
  } = useSearchedAssignments();

  const columnSettings = useAssignmentListColumnSettings();
  const [activeColumns] = useActiveColumns(
    TableIdentifierEnum.GlobalAssignmentSearchTable
  );
  const filteredColumnSettings = useMemo(() => {
    return columnSettings.filter((col) => {
      if (
        col.identifier === undefined ||
        activeColumns[col.identifier] !== false
      )
        return true;
      return false;
    });
  }, [activeColumns, columnSettings]);

  const handleAssignmentItemClick = (model: AssignmentExtendedModel) => {
    navigate(generatePath(casePathPattern, { id: String(model.case.caseID) }));
  };

  // Clear selected assignments when changing filter preset
  useEffect(() => {
    assignmentsBulkOperations.clearSelectedAssignments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateEqualsFilterPreset]);

  const renderList = () => {
    if (status === RequestStatus.Error) {
      return <ErrorTryAgain onTryAgain={() => update(true)} />;
    }

    if (searchInputsInvalidMessage) {
      return (
        <ValidationMessage>{searchInputsInvalidMessage}</ValidationMessage>
      );
    }

    if (!assignments) {
      return <TransparentSpinner />;
    }

    const bulkApprovalButton = (
      <BulkApproval
        bulkOperations={assignmentsBulkOperations}
        handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
          handleAssignmentItemClick(model)
        }
      />
    );

    const bulkMessagesButton = userIsGoSupport ? (
      <BulkMessages bulkOperations={assignmentsBulkOperations} />
    ) : null;

    const currentPage =
      skip === undefined || size === undefined
        ? undefined
        : Math.floor(skip / size);

    if (stateEqualsFilterPreset(selectableFiltersByLabel.Slutförda)) {
      return (
        <>
          <BulkOperations
            bulkActions={[bulkMessagesButton, bulkApprovalButton]}
            bulkOperations={assignmentsBulkOperations}
            handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
              handleAssignmentItemClick(model)
            }
          />
          <BulkApproveAssignmentsList
            assignments={assignments ?? []}
            bulkOperations={assignmentsBulkOperations}
            currentPage={currentPage}
            onAssignmentItemClick={handleAssignmentItemClick}
            onGoToPage={(page) => {
              setSkip(page * size!);
              triggerAssignmentsUpdate(Math.random());
            }}
            pageSize={size}
            totalPageCount={Math.ceil(total / size!)}
          />
        </>
      );
    }

    return (
      <>
        <Horizontal>
          {!!assignments.length && userIsGoSupport && (
            <BulkOperationsWrapper>
              <BulkOperations
                bulkActions={[bulkMessagesButton]}
                bulkOperations={assignmentsBulkOperations}
                handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
                  handleAssignmentItemClick(model)
                }
              />
            </BulkOperationsWrapper>
          )}
          <MenuContainer
            style={{
              gap: 5,
              alignItems: 'center',
              position: 'relative',
              justifyContent: 'flex-end',
            }}
          >
            <TableDropdown
              onDownloadCsv={async (fileName: string) => {
                await downloadCsv(filteredColumnSettings, fileName);
              }}
              columnSettings={columnSettings}
              id={TableIdentifierEnum.GlobalAssignmentSearchTable}
            />
          </MenuContainer>
        </Horizontal>
        <AssignmentsList
          tableIdentifier={TableIdentifierEnum.GlobalAssignmentSearchTable}
          assignments={assignments ?? []}
          bulkOperations={assignmentsBulkOperations}
          currentPage={currentPage}
          loading={status === RequestStatus.Fetching}
          onAssignmentItemClick={handleAssignmentItemClick}
          onAssignmentUpdated={() => update(false)}
          onGoToPage={(page) => {
            setSkip(page * size!);
            triggerAssignmentsUpdate(Math.random());
          }}
          pageSize={size}
          showStatusColors
          totalPageCount={Math.ceil(total / size!)}
        />
      </>
    );
  };

  return (
    <>
      <Outlet />
      <SearchBar
        assignments={assignments ?? []}
        size={size}
        skip={skip}
        total={total}
      />
      {renderList()}
    </>
  );
};

export default GlobalSearchPage;
