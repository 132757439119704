import { useCallback, useMemo } from 'react';
import { formatTime } from 'utils/date-helpers';
import { AssignmentTypeEnum, CaseTypeEnum } from 'api/model';
import { useSetupGetBasicData } from 'api/setup/setup';
import { getNameFromEnumNumber } from 'utils/enum-helpers';

const addMillisToDate = (date: Date, millis: number) => {
  return new Date(date.getTime() + millis);
};

type UseResultingStartAndEndTimeProps = {
  caseType?: CaseTypeEnum;
  assignmentType?: AssignmentTypeEnum;
  bookedTo?: Date;
  estimatedDuration?: number;
  estimatedStartTime?: Date;
};

type GetResultingStartAndEndTimeProps = UseResultingStartAndEndTimeProps & {
  timeDistributionDictionary?: { [key: string]: number };
};

export const calculateResultingStartAndEndTime = ({
  bookedTo,
  caseType,
  assignmentType,
  estimatedDuration,
  estimatedStartTime,
  timeDistributionDictionary,
}: GetResultingStartAndEndTimeProps): {
  startTime: Date | null;
  endTime: Date | null;
} => {
  if (!bookedTo || !estimatedDuration)
    return { startTime: null, endTime: null };

  let startTime: Date | null = null;
  let endTime: Date | null = null;

  if (estimatedStartTime) {
    // we already have an estimated start time, so we can calculate the end time
    startTime = new Date(estimatedStartTime.getTime());
    endTime = addMillisToDate(estimatedStartTime, estimatedDuration);
  } else if (timeDistributionDictionary) {
    // we don't have an estimated start time, but we have a time distribution dictionary
    // so we can calculate the start time

    const timeDistributionKey = `${getNameFromEnumNumber(
      CaseTypeEnum,
      caseType!
    )}_${getNameFromEnumNumber(AssignmentTypeEnum, assignmentType!)}`;
    const percentageOfTimeSpentBeforeArrival =
      timeDistributionDictionary[timeDistributionKey];
    if (percentageOfTimeSpentBeforeArrival) {
      // adjust the start time to distribute
      const amountOfTimeSpentBeforeArrival =
        estimatedDuration * percentageOfTimeSpentBeforeArrival;
      startTime = addMillisToDate(bookedTo, -amountOfTimeSpentBeforeArrival);
      endTime = addMillisToDate(startTime, estimatedDuration);
    }
  }

  return { startTime, endTime };
};

const useResultingStartAndEndTime = ({
  caseType,
  assignmentType,
  bookedTo,
  estimatedDuration,
  estimatedStartTime,
}: UseResultingStartAndEndTimeProps) => {
  const basicDataResponse = useSetupGetBasicData();
  // const [state, setState] = useState<UseResultingStartAndEndTimeProps>({
  //   caseType,
  //   assignmentType,
  //   bookedTo,
  //   estimatedDuration,
  //   estimatedStartTime,
  // });

  // useEffect(() => {
  //   setState({
  //     caseType,
  //     assignmentType,
  //     bookedTo,
  //     estimatedDuration,
  //     estimatedStartTime,
  //   });
  // }, [
  //   caseType,
  //   assignmentType,
  //   bookedTo,
  //   estimatedDuration,
  //   estimatedStartTime,
  //   setState,
  // ]);

  const { startTime: resultingStartTime, endTime: resultingEndTime } =
    useMemo(() => {
      return calculateResultingStartAndEndTime({
        caseType,
        assignmentType,
        bookedTo,
        estimatedDuration,
        estimatedStartTime,
        timeDistributionDictionary:
          basicDataResponse.data?.timeDistributionDictionary,
      });
    }, [
      assignmentType,
      basicDataResponse.data?.timeDistributionDictionary,
      bookedTo,
      caseType,
      estimatedDuration,
      estimatedStartTime,
    ]);

  const startAndEndTimeString = useMemo(() => {
    if (!resultingStartTime || !resultingEndTime) return '';
    return `${formatTime(resultingStartTime)} - ${formatTime(
      resultingEndTime
    )}`;
  }, [resultingStartTime, resultingEndTime]);

  const calculateResultingStartAndEndTimeWithoutEstimatedStartTime =
    useCallback(() => {
      return calculateResultingStartAndEndTime({
        caseType,
        assignmentType,
        bookedTo,
        estimatedDuration,
        timeDistributionDictionary:
          basicDataResponse.data?.timeDistributionDictionary,
      });
    }, [
      assignmentType,
      basicDataResponse.data?.timeDistributionDictionary,
      bookedTo,
      caseType,
      estimatedDuration,
    ]);

  return {
    resultingStartTime,
    resultingEndTime,
    startAndEndTimeString,
    calculateResultingStartAndEndTimeWithoutEstimatedStartTime,
  };
};

export default useResultingStartAndEndTime;
