import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { ITranslationLookups } from 'api';
import useUsers from 'contexts/basicData/useUsers';
import styled from 'styled-components';
import Input from 'components/inputs/Input';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { ColumnSetting } from 'components/Table/utils';
import Table from 'components/Table';
import { usePaymentCardFindPaymentCards } from 'api/payment-card/payment-card';
import { PaymentCardModel } from 'api/model';
import useModalStack from 'contexts/modal/useModalStack';
import PaymentCardFormModal from './PaymentCardFormModal';
import Button from 'components/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import BusinessUnitSelect from 'components/inputs/BusinessUnitSelect';
import {
  faBan,
  faCheck,
  faInfoCircle,
  faPlus,
  faSpinner,
  faUpload,
} from '@fortawesome/free-solid-svg-icons';
import PaymentCardMappingModal from './PaymentCardMappingModal';
import Checkbox from 'components/inputs/Checkbox';
import { useLocalStorage } from 'usehooks-ts';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FullSpan = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  white-space: break-spaces;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;
`;

const MyButton = styled(Button)`
  padding: 0 10px;
  height: 100%;
`;

const Right = styled.div`
  margin-left: auto;
  display: flex;
  gap: 10px;
  align-items: center;
  height: 100%;
`;

const PaymentCards = () => {
  const [paymentCards, setPaymentCards] = useState<PaymentCardModel[]>([]);
  const modalStack = useModalStack();
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<number[]>(
    []
  );
  const [search, setSearch] = useState<string>('');
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const users = useUsers();
  const [hideInactive, setHideInactive] = useLocalStorage<boolean>(
    'hide-inactive-payment-cards',
    true
  );

  const findPaymentCards = usePaymentCardFindPaymentCards();

  const fetchPaymentCards = async () => {
    await findPaymentCards.mutateAsync({
      data: {
        search,
        businessUnitIds: selectedBusinessUnits,
      },
    });
  };

  useEffect(() => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    searchTimeoutRef.current = setTimeout(() => {
      fetchPaymentCards();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, selectedBusinessUnits]);

  useEffect(() => {
    if (findPaymentCards.data) {
      const filteredPaymentCards = findPaymentCards.data.filter(
        (paymentCard) => !hideInactive || (hideInactive && paymentCard.isActive)
      );
      setPaymentCards(filteredPaymentCards);
    }
  }, [findPaymentCards.data, hideInactive]);

  const columnSettings: ColumnSetting<PaymentCardModel, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<PaymentCardModel, ITranslationLookups>[] = [
        {
          head: 'Nr',
          render: (item, renderProps, focused, index) => (
            <FullSpan>{index + 1}</FullSpan>
          ),
          width: 10,
        },
        {
          head: (
            <span>
              Aktiv{' '}
              <FontAwesomeIcon
                icon={faInfoCircle}
                title="Inaktiva kort är kort som är exempelvis klippta eller spärrade och inte längre ska användas."
              />
            </span>
          ),
          render: (item) => (
            <FullSpan>
              {item.isActive ? (
                <span>
                  <FontAwesomeIcon icon={faCheck} color="green" />
                </span>
              ) : (
                <span>
                  <FontAwesomeIcon icon={faBan} color="gray" />
                </span>
              )}
            </FullSpan>
          ),
          width: 40,
          sortFunction: (a, b) => (a.isActive ? 1 : 0) - (b.isActive ? 1 : 0),
        },
        {
          head: 'Kortnummer',
          render: (item) => <FullSpan>{item.number}</FullSpan>,
          width: 120,
          sortFunction: (a, b) => a.number.localeCompare(b.number),
        },
        {
          head: 'Namn',
          render: (item) => (
            <FullSpan>
              {item.cardHolderID ? users[item.cardHolderID]?.name : ''}
            </FullSpan>
          ),
          width: 150,
          sortFunction: (a, b) => {
            const nameA = users[a.cardHolderID ?? -1]?.name ?? '';
            const nameB = users[b.cardHolderID ?? -1]?.name ?? '';
            return nameA.localeCompare(nameB);
          },
        },
        {
          head: 'ÅÅMM',
          render: (item) => <FullSpan>{item.expiration}</FullSpan>,
          width: 160,
          sortFunction: (a, b) => a.expiration.localeCompare(b.expiration),
        },
      ];
      return columns;
    }, [users]);

  const handleRowClick = (item: PaymentCardModel) => {
    modalStack.push(
      <PaymentCardFormModal id={item.id} onUpdated={fetchPaymentCards} />
    );
  };
  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Wrapper>
        <Actions>
          <Checkbox
            checked={hideInactive}
            onChange={(e) => setHideInactive(e.currentTarget.checked)}
          >
            Dölj inaktiva
          </Checkbox>
          <MyButton
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => {
              modalStack.push(
                <PaymentCardFormModal onUpdated={fetchPaymentCards} />
              );
            }}
          >
            Lägg till
          </MyButton>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Filtrera på namn, kortnummer eller utgångsdatum"
            value={search}
            width={230}
          />
          <BusinessUnitSelect
            onChange={(units) => {
              setSelectedBusinessUnits(Array.from(units));
            }}
            value={new Set(selectedBusinessUnits)}
          />
          {findPaymentCards.isLoading && (
            <FontAwesomeIcon icon={faSpinner} spin />
          )}
          <Right>
            <Button
              icon={<FontAwesomeIcon icon={faUpload} />}
              onClick={() => {
                modalStack.push(
                  <PaymentCardMappingModal onUpdated={fetchPaymentCards} />
                );
              }}
            >
              Mappa kort
            </Button>
          </Right>
        </Actions>
        {paymentCards !== undefined && paymentCards.length >= 0 ? (
          <Table
            useColumnWidthAsFlexAmount
            columnSettings={columnSettings}
            onRowClick={handleRowClick}
            rows={paymentCards}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Wrapper>
    </Suspense>
  );
};

export default PaymentCards;
