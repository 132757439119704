import { useQueryClient } from '@tanstack/react-query';
import { useBudgetFilters } from '../useBudgetFilters';
import { useInternalDeliveryBudgetGetWeeklyBudgetsOfMonth } from 'api/internal-delivery-budget/internal-delivery-budget';
import { useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverrides } from 'api/internal-delivery-daily-budget-override/internal-delivery-daily-budget-override';
import {
  InternalDeliveryBudgetModel,
  InternalDeliveryDailyBudgetOverrideModel,
} from 'api/model';
import moment from 'moment';

export type DayBudgetTotal = {
  amount: number;
  overrideId?: number;
};

export type DayBusinessUnitGroupBudgetAllocation = {
  amount: number;
  overrideId?: number;
};

const useWeeklyBudgets = () => {
  const queryClient = useQueryClient();
  const {
    state: { selectedDate, startDate, endDate },
  } = useBudgetFilters();
  const getWeeklyBudgets = useInternalDeliveryBudgetGetWeeklyBudgetsOfMonth({
    date: selectedDate,
  });

  const getBudgetOverrides =
    useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverrides({
      from: startDate,
      to: endDate,
    });

  const getOverrideValue = (
    day: Date,
    companyId: number
  ): DayBudgetTotal | null => {
    const dayData = getBudgetOverrides.data?.find(
      (model) =>
        new Date(model.date).getTime() === day.getTime() &&
        model.companyID === companyId
    );

    if (!dayData) {
      return null;
    }

    return {
      amount: dayData?.amount ?? 0,
      overrideId: dayData?.dailyBudgetOverrideID,
    };
  };

  const getOriginalWeekBudgetTotal = (day: Date, companyId: number) => {
    const budget = getWeeklyBudgets.data
      ?.find((c) => c.companyId === companyId)
      ?.weeks.find((w) => w.week === moment(day).isoWeek())?.amount;

    return budget ?? 0;
  };

  const calculateTotalWeekOverride = (
    companyId: number,
    data: InternalDeliveryDailyBudgetOverrideModel[]
  ) => {
    return data.reduce(
      (acc, curr) => {
        if (curr.companyID === companyId) {
          const dayOverride = getOverrideValue(new Date(curr.date), companyId);
          if (dayOverride !== null) {
            return {
              ...acc,
              totalNumber: acc.totalNumber + dayOverride.amount,
              daysWithOverride: acc.daysWithOverride + 1,
            };
          }
        }
        return acc;
      },
      { totalNumber: 0, daysWithOverride: 0 }
    );
  };

  const getDayBudgetTotal = (day: Date, companyId: number): DayBudgetTotal => {
    // Check if there is an override for this day
    const overrideAmount = getOverrideValue(day, companyId);
    if (overrideAmount) {
      return {
        amount: overrideAmount.amount,
        overrideId: overrideAmount.overrideId,
      };
    }

    // Otherwise, determine the budget based on the weekly budget
    let budget = getWeeklyBudgets.data
      ?.find((c) => c.companyId === companyId)
      ?.weeks.find((w) => w.week === moment(day).isoWeek())?.amount;

    // Reduce budget with any overrides for the week
    const totalWeekOverride = calculateTotalWeekOverride(
      companyId,
      getBudgetOverrides.data ?? []
    );

    if (totalWeekOverride && budget) {
      budget -= totalWeekOverride.totalNumber;
    }

    // Spread weekly budget over 5 days (only Monday - Friday. Saturday and Sunday are not included in the budget)
    // if the day is a weekend, set the budget to 0
    if (moment(day).isoWeekday() > 5) {
      budget = 0;
    } else if (budget) {
      budget /= 5 - (totalWeekOverride?.daysWithOverride ?? 0);
    } else {
      budget = 0;
    }

    return {
      amount: Number(budget.toFixed(0)) ?? 0,
    };
  };

  const getWeeklyBudget = (
    day: Date,
    companyId: number
  ): InternalDeliveryBudgetModel | undefined => {
    const budget = getWeeklyBudgets.data
      ?.find((c) => c.companyId === companyId)
      ?.weeks.find((w) => w.week === moment(day).isoWeek());

    return budget;
  };

  // Difference between the original weekly budget and the acutal distributed budget
  const getCalculatedWeeklyDiff = (day: Date, companyId: number) => {
    let calculatedBudgetDiff = 0;
    const originalBudgetTotal = getOriginalWeekBudgetTotal(day, companyId);
    if (originalBudgetTotal) {
      for (
        let date = startDate;
        date < endDate;
        date = moment(date).add(1, 'days').toDate()
      ) {
        calculatedBudgetDiff += getDayBudgetTotal(date, companyId).amount;
      }

      if (calculatedBudgetDiff > originalBudgetTotal) {
        calculatedBudgetDiff -= originalBudgetTotal;
      } else if (calculatedBudgetDiff < originalBudgetTotal) {
        calculatedBudgetDiff = -(originalBudgetTotal - calculatedBudgetDiff);
      } else {
        calculatedBudgetDiff = 0;
      }
    }
    return calculatedBudgetDiff;
  };

  return {
    getDayBudgetTotal,
    getOriginalWeekBudgetTotal,
    getWeeklyBudget,
    getCalculatedWeeklyDiff,
    calls: {
      getWeeklyBudgets,
      getBudgetOverrides,
      refetchAll: async () => {
        await queryClient.invalidateQueries(getWeeklyBudgets.queryKey);
        await queryClient.invalidateQueries(getBudgetOverrides.queryKey);

        // await getWeeklyBudgets.refetch();
        // await getExtendedBudgetOverrides.refetch();
      },
    },
  };
};

export default useWeeklyBudgets;
