import React from 'react';

// eslint-disable-next-line import/prefer-default-export
export function autoFormatNumberInputOnBlur(
  eve: React.FocusEvent<HTMLInputElement>
) {
  const v = eve.currentTarget.value;
  const oneLeadingZero = v.replace(/^0*/, '0');
  const formattedValue = /^0\d/.test(oneLeadingZero)
    ? oneLeadingZero.replace(/^0*/, '')
    : oneLeadingZero;

  const inputElement = eve.currentTarget;

  // set value & trigger onChange() with formatted value
  const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
    window.HTMLInputElement.prototype,
    'value'
  )?.set;
  nativeInputValueSetter?.call(inputElement, formattedValue);
  inputElement.dispatchEvent(new Event('input', { bubbles: true }));
}
