import { FC, useEffect } from 'react';
import {
  ZipCodeLocalAreaClient,
  ZipCodeLocalAreaModel,
  ZipCodeRuleModel,
} from 'api';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';

import styled, { css } from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import MediaQuery from 'constants/MediaQuery';
import { arraySpreadIf } from 'utils/spreading';
import LabelWrap from 'components/inputs/LabelWrap';
import Input from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const GlobalFormWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: 20px;
  padding: 20px;
  ${MediaQuery.mobileL} {
    grid-template-columns: 1fr;
  }
  ${MediaQuery.laptop} {
    grid-template-columns: 300px 1fr;
  }

  ${MediaQuery.laptopL} {
    grid-template-columns: 300px 250px 1fr;
  }
`;

const PostCodeInput = styled(Input)<{ value?: string }>`
  width: 100px;
`;

const FormWrap = styled.div<{ maxWidth?: number; compact?: boolean }>`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: ${({ compact }) => (compact ? '0' : '10px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;

const RuleWrap = styled.div<{ error?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  ${({ error }) =>
    !error &&
    css`
      margin-bottom: 10px;
    `}
`;

const Error = styled.span`
  ${({ theme }) => css`
    color: ${theme.colors.foreground.warning};
    font-size: 0.8em;
    font-style: italic;
    margin-bottom: 10px;
  `}
`;

const MyLabelWrap = styled(LabelWrap)<{ width?: number }>`
  & > *:last-child {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    max-height: 100%;
  }
`;

const MyButton = styled(Button)`
  height: 100%;
  padding-top: 0px;
  padding-bottom: 0px;
`;

const formatPostCode = (value: string) => {
  // remove non-digits and whitespace
  const newValue = value.replace(/\D/g, '').trim();
  // cap to 5 digits in the following format XXX XX
  const cappedValue = newValue.substring(0, 5);
  return cappedValue;
};

interface Props {
  area?: ZipCodeLocalAreaModel;
  onCancel?(): void;
  onSaved?(): void;
  canEdit?: boolean;
  title?: string;
}

const ZipCodeLocalAreaFormModal: FC<Props> = ({
  area,
  onCancel,
  onSaved,
  canEdit,
  title,
}) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm<ZipCodeLocalAreaModel>();

  // Populate form with area data on first render
  useEffect(() => {
    if (area) {
      setValue('id', area.id);
      setValue('name', area.name);
      setValue('zipCodeRules', area.zipCodeRules);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [area]);

  const values = watch();

  const createMode = !area;

  const createCall = useApiCall(
    ZipCodeLocalAreaClient,
    (c, model: ZipCodeLocalAreaModel) => c.createZipCodeLocalArea(model)
  );

  const updateCall = useApiCall(
    ZipCodeLocalAreaClient,
    (c, model: ZipCodeLocalAreaModel) => c.updateZipCodeLocalArea(model)
  );

  const handleCreateClick = handleSubmit(async (data) => {
    const [, error] = await createCall.run(data);

    if (!error) {
      onSaved?.();
    }
  });

  const handleUpdateClick = handleSubmit(async (data) => {
    if (!data) return;

    const [, error] = await updateCall.run(data);

    if (!error) {
      onSaved?.();
    }
  });

  const handleCancelClick = () => {
    onCancel?.();
  };

  const handleAddRuleClick = () => {
    const newRule: ZipCodeRuleModel = ZipCodeRuleModel.fromJS({
      minCode: undefined,
      maxCode: undefined,
    });

    const newRules = values.zipCodeRules || [];

    newRules.push(newRule);

    setValue('zipCodeRules', newRules as ZipCodeRuleModel[]);
  };

  const handleRemoveRuleClick = (index: number) => {
    const newRules = values.zipCodeRules || [];

    newRules.splice(index, 1);

    setValue('zipCodeRules', newRules as ZipCodeRuleModel[]);
  };

  const saveStatus = createMode ? createCall.status : updateCall.status;

  const renderContent = () => {
    switch (saveStatus) {
      case RequestStatus.Idle:
      case RequestStatus.Fetched:
      case RequestStatus.Error:
        return (
          <GlobalFormWrap>
            <FormWrap compact>
              <MyLabelWrap label="Namn" width={270}>
                <Input
                  {...register('name', { required: true })}
                  disabled={!canEdit}
                  placeholder="Namn"
                />
              </MyLabelWrap>
            </FormWrap>
            <FormWrap compact>
              <MyLabelWrap label="Regler" width={270}>
                {values.zipCodeRules?.map((rule, index) => (
                  <>
                    <RuleWrap
                      error={!!errors?.zipCodeRules?.[index]?.minCode}
                      key={`rule_${index}`}
                    >
                      <PostCodeInput
                        key={`${rule.id}_min`}
                        {...register(`zipCodeRules.${index}.minCode`, {
                          required: true,
                          onChange: (e) => {
                            e.target.value = formatPostCode(e.target.value);
                          },
                          validate: (value) => {
                            return Number(value) <
                              Number(values?.zipCodeRules?.[index]?.maxCode)
                              ? true
                              : 'Det första postnumret måste vara mindre än det andra';
                          },
                        })}
                        placeholder="Postnr"
                      />
                      <span>–</span>
                      <PostCodeInput
                        key={`${rule.id}_max`}
                        {...register(`zipCodeRules.${index}.maxCode`, {
                          required: true,
                          validate: (value) => {
                            return Number(value) >
                              Number(values?.zipCodeRules?.[index]?.minCode)
                              ? true
                              : 'Det första postnumret måste vara mindre än det andra';
                          },
                          onChange: (e) => {
                            e.target.value = formatPostCode(e.target.value);
                          },
                        })}
                        placeholder="Postnr"
                        warning={
                          errors?.zipCodeRules?.[index]?.maxCode?.type ===
                          'validate'
                        }
                      />
                      <MyButton
                        onClick={() => {
                          handleRemoveRuleClick(index);
                        }}
                      >
                        <FontAwesomeIcon icon={faTrash} />
                      </MyButton>
                    </RuleWrap>
                    {errors?.zipCodeRules?.[index]?.minCode?.type ===
                      'validate' && (
                      <Error>
                        {errors?.zipCodeRules?.[index]?.minCode?.message}
                      </Error>
                    )}
                  </>
                ))}
              </MyLabelWrap>
              <RuleWrap>
                <Button onClick={handleAddRuleClick}>+ Lägg till regel</Button>
              </RuleWrap>
            </FormWrap>
          </GlobalFormWrap>
        );

      case RequestStatus.Fetching:
      default:
        return <LoadingSpinner>Sparar...</LoadingSpinner>;
    }
  };

  return (
    <Modal
      buttons={
        saveStatus === RequestStatus.Fetching
          ? []
          : [
              ...arraySpreadIf(!!canEdit, {
                label: createMode ? 'Skapa område' : 'Uppdatera område',
                onClick: createMode ? handleCreateClick : handleUpdateClick,
              }),
              {
                label: canEdit ? 'Avbryt' : 'Stäng',
                onClick: handleCancelClick,
              },
            ]
      }
      onClose={handleCancelClick}
      title={
        title !== undefined
          ? title
          : createMode
            ? 'Skapa område'
            : 'Redigera område'
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default ZipCodeLocalAreaFormModal;
