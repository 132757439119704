import { useMemo } from 'react';
import styled from 'styled-components';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits'; // TODO: replace this with a new context?
import { DatePicker, Option } from '../../components';
import AssignmentRequestsFilterPicker from './AssignmentRequestsFilterPicker';
import Roles from 'constants/Roles';
import {
  BarSection,
  BarWrapper,
  CustomInput,
  StyledAuthorizedButton,
  StyledMultiSelect,
} from '../../components/filterBars';
import { AssignmentRequestModel } from 'api/model';
import useModalStack from 'contexts/modal/useModalStack';
import AssignmentRequestModal from './AssignmentRequestModal';
import useAssignmentRequestsSearch from 'contexts/assignmentsFilters/useAssignmentRequestsSearch';
import { useBusinessUnitGetBusinessUnitGroups } from 'api/business-unit/business-unit';
import { useAssignmentRequestsFilters } from 'pages/InternalDeliveryGroup/Requests/components/useAssignmentRequestFilters';
import Checkbox from 'components/inputs/Checkbox';
import AssignmentRequestsFilterSearchInput from './AssignmentRequestsFilterSearchInput';

const MyLabel = styled.label`
  margin-right: 5px;
`;
const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 35px;
`;

const DatePickerContainer = styled.div``;

const RightBarSection = styled(BarSection)`
  margin-left: auto;
`;

interface Props {
  requests: AssignmentRequestModel[];
}

const AssignmentRequestsFilterBar: React.FC<Props> = () => {
  const modalStack = useModalStack();
  const { unitsById } = useBusinessUnits();
  const businessUnitGroups = useBusinessUnitGetBusinessUnitGroups();
  const businessUnitOptions = useMemo((): Option<number>[] => {
    return Array.from(unitsById.values()).map((unit) => ({
      label: unit.name,
      value: unit.id,
    }));
  }, [unitsById]);

  const businessUnitGroupOptions = useMemo((): Option<number>[] => {
    return (
      businessUnitGroups.data?.map((unit) => ({
        label: unit.name,
        value: unit.id,
      })) ?? []
    );
  }, [businessUnitGroups.data]);

  const {
    state: {
      startDate: date,
      visibleBusinessGroups,
      visibleBusinessUnits,
      requireFullAllocation,
    },
    setVisibleBusinessGroups,
    setVisibleBusinessUnits,
    setRequireFullAllocation,
    setStartDate: setDate,
  } = useAssignmentRequestsFilters();

  const { refresh: refreshAssignmentRequests } = useAssignmentRequestsSearch();

  const handleChangeDate = (
    newDate: Date | [Date | null, Date | null] | null
  ) => {
    if (newDate) {
      if (!Array.isArray(newDate)) {
        setDate(newDate);
      }
    }
  };

  return (
    <BarWrapper>
      <BarSection>
        <MyLabel>Visa dag:</MyLabel>
        <DatePickerContainer>
          <DatePicker
            calendarStartDay={1}
            customInput={<CustomInput date={date} />}
            dateFormat="yyyy-MM-dd"
            locale="sv"
            onChange={(d) => handleChangeDate(d)}
            populateHolidays
            selected={date}
          />
        </DatePickerContainer>
      </BarSection>
      <StyledAuthorizedButton
        onClick={() => {
          modalStack.push(
            <AssignmentRequestModal
              onClose={() => {
                modalStack.pop();
                refreshAssignmentRequests();
              }}
              onUpdated={refreshAssignmentRequests}
            />
          );
        }}
        roles={[Roles.Admin]}
      >
        + Skapa ny
      </StyledAuthorizedButton>
      <AssignmentRequestsFilterPicker />
      <AssignmentRequestsFilterSearchInput />

      <RightBarSection>
        <MyLabel>Filtrera på grupp:</MyLabel>
        <StyledMultiSelect
          onChange={(units) => setVisibleBusinessGroups(Array.from(units))}
          options={businessUnitGroupOptions}
          placeholder="Välj grupp"
          value={new Set(visibleBusinessGroups)}
        />
        <MyLabel>Filtrera på enhet:</MyLabel>
        <StyledMultiSelect
          onChange={(units) => setVisibleBusinessUnits(Array.from(units))}
          options={businessUnitOptions}
          placeholder="Välj enhet"
          value={new Set(visibleBusinessUnits)}
        />
        <CheckboxWrapper>
          <Checkbox
            checked={requireFullAllocation}
            onChange={(e) => setRequireFullAllocation(e.currentTarget.checked)}
          >
            Kräv full allokering
          </Checkbox>
        </CheckboxWrapper>
      </RightBarSection>
    </BarWrapper>
  );
};

export default AssignmentRequestsFilterBar;
