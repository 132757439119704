import { WidgetMetadata } from 'components/Dashboard/types';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';

interface DashboardState<T> {
  collapsed: boolean;
  expanded: boolean;
  widgets: WidgetMetadata<T>[];
  daterange: [Date | null, Date | null];
  selectedBusinessUnits: number[];
  searchText: string;
}

interface DashboardControlsProps<T> {
  dashboardState: DashboardState<T>;
  setCollapsedState: (collapse: boolean) => void;
  setExpandedState: (expand: boolean) => void;
  setWidgets: (widgets: WidgetMetadata<T>[]) => void;
  setSelectedDateRange(daterange: [Date | null, Date | null]): void;
  setSelectedBusinessUnits: (businessUnits: number[]) => void;
  setSearchText: (text: string) => void;
}

const getInitialDashboardState = <T,>(): DashboardState<T> => ({
  collapsed: false,
  expanded: false,
  widgets: [],
  daterange: [new Date(), new Date()],
  selectedBusinessUnits: [],
  searchText: '',
});

const DashboardControlsContext = createContext<
  DashboardControlsProps<any> | undefined
>({
  dashboardState: getInitialDashboardState(),
  setCollapsedState: () => {},
  setExpandedState: () => {},
  setWidgets: () => {},
  setSelectedDateRange: () => {},
  setSelectedBusinessUnits: () => {},
  setSearchText: () => {},
});

interface DashboardControlsProviderProps<T> {
  storageKey: string;
  defaultWidgets: WidgetMetadata<T>[];
  children: React.ReactNode;
}

export const DashboardControlsProvider = <T,>({
  storageKey,
  defaultWidgets,
  children,
}: DashboardControlsProviderProps<T>) => {
  const [dashboardState, setDashboardState] = useLocalStorage<
    DashboardState<T>
  >(`${storageKey}.DashboardState`, {
    ...getInitialDashboardState(),
    widgets: defaultWidgets,
  });

  useEffect(() => {
    setDashboardState((prevState) => ({
      ...prevState,
      // Update dashboardState when defaultWidgets changes (ie we add a new widget that the user does not have yet in their local storage).
      // But don't overwrite the user's previous selection of active state for the widgets.
      widgets: defaultWidgets.map((widget) => {
        const userWidget = prevState.widgets.find(
          (w) => w.identifier === widget.identifier
        );
        return {
          ...widget,
          active: userWidget ? userWidget.active : widget.active,
        };
      }),
    }));
  }, [defaultWidgets, setDashboardState]);

  const [daterange, setDateRange] = useState<[Date | null, Date | null]>([
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      0,
      0,
      0,
      0
    ),
    new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date().getDate(),
      23,
      59,
      59,
      999
    ),
  ]);

  const setWidgets = useCallback(
    (widgets: WidgetMetadata<T>[]) => {
      setDashboardState((state) => ({ ...state, widgets }));
    },
    [setDashboardState]
  );

  const setCollapsedState = useCallback(
    (collapse: boolean) => {
      setDashboardState((state) => ({ ...state, collapsed: collapse }));
    },
    [setDashboardState]
  );

  const setExpandedState = useCallback(
    (expand: boolean) => {
      setDashboardState((state) => ({ ...state, expanded: expand }));
    },
    [setDashboardState]
  );

  const setSelectedDateRange = useCallback(
    (range: [Date | null, Date | null]) => {
      const startDate = range[0]
        ? new Date(
            range[0].getFullYear(),
            range[0].getMonth(),
            range[0].getDate(),
            0,
            0,
            0,
            0
          )
        : null;

      const endDate = range[1]
        ? new Date(
            range[1].getFullYear(),
            range[1].getMonth(),
            range[1].getDate(),
            23,
            59,
            59,
            999
          )
        : null;

      setDateRange([startDate, endDate]);
    },
    []
  );

  const setSelectedBusinessUnits = useCallback(
    (businessUnits: number[]) => {
      setDashboardState((state) => ({
        ...state,
        selectedBusinessUnits: Array.from(businessUnits),
      }));
    },
    [setDashboardState]
  );

  const setSearchText = useCallback(
    (text: string) => {
      setDashboardState((state) => ({
        ...state,
        searchText: text,
      }));
    },
    [setDashboardState]
  );

  const providerValue = useMemo(
    (): DashboardControlsProps<T> => ({
      dashboardState: {
        ...dashboardState,
        daterange,
      },
      setCollapsedState,
      setExpandedState,
      setWidgets,
      setSelectedDateRange,
      setSelectedBusinessUnits,
      setSearchText,
    }),
    [
      dashboardState,
      daterange,
      setCollapsedState,
      setExpandedState,
      setWidgets,
      setSelectedDateRange,
      setSelectedBusinessUnits,
      setSearchText,
    ]
  );

  return (
    <DashboardControlsContext.Provider value={providerValue}>
      {children}
    </DashboardControlsContext.Provider>
  );
};

export const useDashboardControls = <T,>(): DashboardControlsProps<T> => {
  const context = useContext(DashboardControlsContext);
  if (!context) {
    throw new Error(
      'useDashboardControls must be used within a DashboardControlsProvider'
    );
  }
  return context as DashboardControlsProps<T>;
};
