import { AssignmentClient, AssignmentModel, PredefinedAction } from 'api';
import {
  useAssignmentRequestsCreateAssignmentRequest,
  useAssignmentRequestsGetClosestCompany,
} from 'api/assignment-requests/assignment-requests';
import { useDrivingPolicyRequestOverrideDrivingPolicy } from 'api/driving-policy/driving-policy';
import {
  AssignmentRequestModel,
  AssignmentRequestReasonEnum,
  AssignmentRequestStatusEnum,
  AssignmentRequestTypeEnum,
  CaseTypeEnum,
} from 'api/model';
import CompanyPicker from 'components/CompanyPicker';
import { useDetailedCaseContext } from 'components/DetailedCase/DetailedCaseProvider';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import KeyValueList from 'components/KeyValueList';
import Modal, { modalContentClass } from 'components/Modal';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import useCompanies from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useApiCall } from 'swaggerhooks/lib';
import {
  getHoursAndMinutesFromMillisecondsString,
  toInputDateTimeString,
} from 'utils/date-helpers';
import assignmentRequestHelper from 'utils/assignment-request-helper';
import Select from 'components/inputs/Select';
import useAssignmentRequestReasonSelectOptions from 'pages/InternalDeliveryGroup/Assignments/components/useAssignmentRequestReasonOptions';

const FIXED_PADDING_TIME_IN_MILLISECONDS = 30 * 60 * 1000;
const ERROR_MESSAGE_SELECT_BOOKING_TIME_FOR_NEW_ASSIGNMENT =
  'Du måste välja vilken tid du vill hämta ut annonsbilen.';
const ERROR_MESSAGE_SELECT_EARLIER_BOOKING_TIME_FOR_NEW_ASSIGNMENT =
  'Det nya uppdraget måste bokas in före det redan befintliga uppdraget.';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    min-width: 500px;
    max-width: 500px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    label,
    input,
    select {
      flex: 1;
      width: auto;
    }
  }
`;

const Bold = styled.div`
  font-weight: 500;
`;

const ExtraInfoInput = styled(AutoSizedTextArea)`
  resize: vertical;
  min-height: 75px;
  width: 100%;
`;

type PredefinedActionModalProps = {
  assignment: AssignmentModel;
  caseType: CaseTypeEnum;
  actionType: PredefinedAction;
  onClose(): void;
};

const PredefinedActionModal = ({
  assignment,
  caseType,
  actionType,
  onClose,
}: PredefinedActionModalProps) => {
  // api
  const createAssignmentRequest =
    useAssignmentRequestsCreateAssignmentRequest();

  const requestOverrideDrivingPolicy =
    useDrivingPolicyRequestOverrideDrivingPolicy();

  const [extraInfo, setExtraInfo] = useState<string>('');
  const [bookedTo, setBookedTo] = useState<string>('');
  const [assingmentRequestReason, setAssignmentRequestReason] =
    useState<AssignmentRequestReasonEnum>(
      assignmentRequestHelper.getAssignmentRequestReason(caseType)
    );
  const [targetAssignmentRequestTime, setTargetAssignmentRequestTime] =
    useState<Date | undefined>(assignment.estimatedStartTime);
  const [
    fixedAssignmentTimeInMilliseconds,
    setFixedAssignmentTimeInMilliseconds,
  ] = useState<number>();
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const [closestCompany, setClosestCompany] = useState<number>();
  const [selectedFromCompanyId, setSelectedFromCompanyId] = useState<number>();
  const [selectedToCompanyId, setSelectedToCompanyId] = useState<number>();

  const { predefinedActionsInfo } = useTranslations();

  const { companies, companiesById } = useCompanies();
  const logistikToCompanyPrices = useMemo(() => {
    return companies.find((c) => c.name === 'Logistik')?.pricesFromHere ?? [];
  }, [companies]);

  const getClosestCompany = useAssignmentRequestsGetClosestCompany();
  const firstLogisticsCompanyId = companies.find(
    (c) => c.isLogisticsCenter && c.name === 'Strängnäs Logistik'
  )?.companyID; // lock to strängnäs logistik for now, until we open up both locations

  const assignmentRequestReasonSelectOptions =
    useAssignmentRequestReasonSelectOptions();

  useEffect(() => {
    // fetch the closest company address when the modal is opened and / or the assignment changes
    const fetchClosestCompanyAddress = async () => {
      try {
        const result = await getClosestCompany.mutateAsync({
          params: {
            caseType,
            FromZip: assignment.fromZip,
            ToZip: assignment.toZip,
          },
        });
        setClosestCompany(result);
      } catch (error) {
        // console.log(error);
      }
    };
    if (actionType === PredefinedAction.REQUEST_INTERNAL_TRANSPORT) {
      fetchClosestCompanyAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseType, actionType, assignment]);

  useEffect(() => {
    // when the closest company changes, update the selected company to the closest company
    setSelectedToCompanyId(closestCompany);
  }, [closestCompany]);

  useEffect(() => {
    if (selectedFromCompanyId === undefined) {
      setSelectedFromCompanyId(firstLogisticsCompanyId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstLogisticsCompanyId]);

  useEffect(() => {
    // when selected company changes, update the bookedTo date based on the fixedAssignmentTime between Logistik and the selected company
    if (selectedToCompanyId) {
      const fromCompany = companies.find(
        (c) => c.companyID === selectedFromCompanyId
      );

      // find fixed time between the selected companies
      const fixedTimeBetweenCompanies =
        fromCompany?.pricesFromHere.find(
          (c) => c.toCompanyId === selectedToCompanyId
        )?.fixedTimeInMillseconds ?? 0;

      const targetTime = new Date(assignment.estimatedStartTime!);
      // remove result 0 FIXED_PADDING_TIME_IN_MILLISECONDS from the estimatedStartTime to get the correct targetTime
      targetTime.setMilliseconds(
        targetTime.getMilliseconds() -
          fixedTimeBetweenCompanies -
          FIXED_PADDING_TIME_IN_MILLISECONDS
      );

      setTargetAssignmentRequestTime(targetTime);
      setFixedAssignmentTimeInMilliseconds(fixedTimeBetweenCompanies);
    } else {
      setTargetAssignmentRequestTime(undefined);
      setFixedAssignmentTimeInMilliseconds(undefined);
    }
  }, [
    assignment.estimatedStartTime,
    logistikToCompanyPrices,
    selectedToCompanyId,
    selectedFromCompanyId,
    companies,
  ]);

  const derivedTimeToGetToLogistik = useMemo(() => {
    const diff =
      assignment.estimatedStartTime!.getTime() -
      (targetAssignmentRequestTime?.getTime() ?? 0) -
      (fixedAssignmentTimeInMilliseconds ?? 0);
    return diff;
  }, [
    assignment.estimatedStartTime,
    fixedAssignmentTimeInMilliseconds,
    targetAssignmentRequestTime,
  ]);

  const { caseResponse } = useDetailedCaseContext();
  const { triggerAssignmentsUpdate } = useAssignmentsFilters();

  const makeApiCall = useApiCall(
    AssignmentClient,
    (c, action: PredefinedAction, bTo: Date, model: AssignmentModel) =>
      c.executePredefinedAction(action, bTo, model)
  );

  const handleExecute = useCallback(
    async (doCreate: boolean) => {
      if (doCreate) {
        if (!bookedTo) {
          setErrorMessage(ERROR_MESSAGE_SELECT_BOOKING_TIME_FOR_NEW_ASSIGNMENT);
          return;
        }

        if (new Date(bookedTo) >= assignment.bookedTo) {
          setErrorMessage(
            ERROR_MESSAGE_SELECT_EARLIER_BOOKING_TIME_FOR_NEW_ASSIGNMENT
          );
          return;
        }
      }

      const [response, error] = await makeApiCall.run(
        actionType,
        new Date(bookedTo!),
        assignment
      );

      if (!response && error) {
        setErrorMessage('Något gick fel.');
        return;
      }

      setErrorMessage(undefined);
      caseResponse.update();
      triggerAssignmentsUpdate(new Date());
      onClose();
    },
    [
      assignment,
      bookedTo,
      caseResponse,
      makeApiCall,
      onClose,
      triggerAssignmentsUpdate,
      actionType,
    ]
  );

  const handleCreateAssignmentRequest = useCallback(async () => {
    if (
      !targetAssignmentRequestTime ||
      selectedToCompanyId === undefined ||
      selectedFromCompanyId === undefined
    ) {
      setErrorMessage(ERROR_MESSAGE_SELECT_BOOKING_TIME_FOR_NEW_ASSIGNMENT);
      return;
    }
    const newAssignmentRequest: AssignmentRequestModel = {
      requestID: -1,
      allocatedAssignments: [],
      requestedForID: assignment.assignedTo?.userID,
      assignmentRequestStatusID: AssignmentRequestStatusEnum.Created,
      assignmentRequestTypeID: AssignmentRequestTypeEnum.Request,
      assignmentRequestReasonID: assingmentRequestReason,
      extraInfo,
      originatingAssignmentID: assignment.assignmentID,
      targetStartTime: targetAssignmentRequestTime,
      targetEndTime: targetAssignmentRequestTime,
      fromCompanyID: selectedFromCompanyId,
      toCompanyID: selectedToCompanyId,
      numberOfCars: 1,
    };

    await createAssignmentRequest.mutateAsync({
      data: newAssignmentRequest,
    });

    setErrorMessage(undefined);
    caseResponse.update();
    triggerAssignmentsUpdate(new Date());
    onClose();
  }, [
    assignment.assignedTo?.userID,
    assignment.assignmentID,
    assingmentRequestReason,
    caseResponse,
    createAssignmentRequest,
    extraInfo,
    onClose,
    selectedFromCompanyId,
    selectedToCompanyId,
    targetAssignmentRequestTime,
    triggerAssignmentsUpdate,
  ]);

  const handleRequestOverrideDrivingPolicy = useCallback(async () => {
    try {
      await requestOverrideDrivingPolicy.mutateAsync({
        params: {
          fieldTesterId: assignment.assignedTo?.userID,
          assignmentId: assignment.assignmentID,
          message: extraInfo,
        },
      });

      setErrorMessage(undefined);
      caseResponse.update();
      triggerAssignmentsUpdate(new Date());
    } catch (error) {
      // console.log(error);
    }
  }, [
    requestOverrideDrivingPolicy,
    assignment.assignedTo?.userID,
    assignment.assignmentID,
    extraInfo,
    caseResponse,
    triggerAssignmentsUpdate,
  ]);

  useEffect(() => {
    const tempBookedTo = new Date(assignment.bookedTo);
    tempBookedTo.setMinutes(assignment.bookedTo.getMinutes() - 1);

    setBookedTo(toInputDateTimeString(tempBookedTo));
  }, [assignment.bookedTo]);

  const getContent = useCallback(() => {
    switch (actionType) {
      case PredefinedAction.ORDER_TO_KUNGSANGEN:
      case PredefinedAction.ORDER_TO_PADEL:
        return (
          <Input
            max={toInputDateTimeString(assignment.bookedTo)}
            onChange={(e) =>
              setBookedTo(
                toInputDateTimeString(new Date(e.currentTarget.value))
              )
            }
            small
            type="datetime-local"
            value={bookedTo}
          />
        );

      case PredefinedAction.REQUEST_OVERRIDE_DRIVING_POLICY: {
        return (
          <LabelWrap label={<Bold>Meddelande:</Bold>}>
            <ExtraInfoInput
              onChange={(e) => {
                setExtraInfo(e.currentTarget.value);
              }}
              placeholder="Lägg in eventuell information till föraren här"
              value={extraInfo}
            />
          </LabelWrap>
        );
      }

      case PredefinedAction.REQUEST_INTERNAL_TRANSPORT:
        return (
          <>
            <LabelWrap label={<Bold>Från:</Bold>}>
              <CompanyPicker
                filter={(company) => !!company.isLogisticsCenter}
                onCompanySelected={(company) =>
                  setSelectedFromCompanyId(company?.companyID ?? undefined)
                }
                selectedCompanyId={selectedFromCompanyId ?? null}
              />
            </LabelWrap>
            <LabelWrap label={<Bold>Till:</Bold>}>
              <CompanyPicker
                filter={(company) => !!company.showInInternalDeliveryOverview}
                onCompanySelected={(company) =>
                  setSelectedToCompanyId(company?.companyID ?? undefined)
                }
                selectedCompanyId={selectedToCompanyId ?? null}
              />
            </LabelWrap>
            <LabelWrap label={<Bold>Anledning:</Bold>}>
              <Select
                value={assingmentRequestReason}
                onSelect={(eve) => {
                  setAssignmentRequestReason(
                    Number(
                      eve.currentTarget.value
                    ) as AssignmentRequestReasonEnum
                  );
                }}
              >
                {assignmentRequestReasonSelectOptions.map((r) => (
                  <option key={r.value} value={r.value}>
                    {r.label}
                  </option>
                ))}
              </Select>
            </LabelWrap>
            {targetAssignmentRequestTime &&
              !!fixedAssignmentTimeInMilliseconds && (
                <>
                  <KeyValueList
                    colonKey
                    rows={[
                      {
                        key: `Fast tid från Logistik till ${
                          companiesById.get(selectedToCompanyId!)?.name ?? ''
                        }`,
                        value: (
                          <div>
                            {getHoursAndMinutesFromMillisecondsString(
                              fixedAssignmentTimeInMilliseconds
                            )}
                          </div>
                        ),
                      },
                    ]}
                  />
                  <LabelWrap
                    label={<Bold>När vill du hämta ut annonsbilen:</Bold>}
                  >
                    <Input
                      max={toInputDateTimeString(
                        assignment.estimatedStartTime!
                      )}
                      onChange={(e) => {
                        const newValue = new Date(e.currentTarget.value);
                        const lastTimeToBeThere = new Date(
                          (assignment.estimatedStartTime?.getTime() ?? 0) -
                            fixedAssignmentTimeInMilliseconds
                        );
                        if (newValue.getTime() < lastTimeToBeThere.getTime()) {
                          setTargetAssignmentRequestTime(
                            new Date(e.currentTarget.value)
                          );
                        }
                      }}
                      small
                      type="datetime-local"
                      value={toInputDateTimeString(targetAssignmentRequestTime)}
                    />
                  </LabelWrap>
                  <KeyValueList
                    colonKey
                    rows={[
                      {
                        key: 'Tid att ta sig till Logistik',
                        value: (
                          <div>
                            {getHoursAndMinutesFromMillisecondsString(
                              derivedTimeToGetToLogistik
                            )}
                          </div>
                        ),
                      },
                    ]}
                  />
                  <LabelWrap
                    label={<Bold>Information till annonsgruppen:</Bold>}
                  >
                    <ExtraInfoInput
                      onChange={(e) => {
                        setExtraInfo(e.currentTarget.value);
                      }}
                      placeholder="Lägg in eventuell information till annonsgruppen här"
                      value={extraInfo}
                    />
                  </LabelWrap>
                </>
              )}
          </>
        );
      default:
        return null;
    }
  }, [
    actionType,
    assignment.bookedTo,
    assignment.estimatedStartTime,
    assignmentRequestReasonSelectOptions,
    assingmentRequestReason,
    bookedTo,
    companiesById,
    derivedTimeToGetToLogistik,
    extraInfo,
    fixedAssignmentTimeInMilliseconds,
    selectedFromCompanyId,
    selectedToCompanyId,
    targetAssignmentRequestTime,
  ]);

  const getButtons = useCallback(() => {
    const buttons = [{ label: 'Avbryt', onClick: onClose }];
    switch (actionType) {
      case PredefinedAction.ORDER_TO_KUNGSANGEN:
      case PredefinedAction.ORDER_TO_PADEL:
        buttons.unshift({
          label: 'Skapa',
          onClick: () => handleExecute(true),
        });
        break;
      case PredefinedAction.REQUEST_INTERNAL_TRANSPORT:
        buttons.unshift({
          label: 'Begär annonsbil',
          onClick: () => handleCreateAssignmentRequest(),
        });
        break;
      case PredefinedAction.CANCEL_INTERNAL_TRANSPORT_REQUEST:
        buttons.unshift({
          label: 'Avbryt',
          onClick: onClose,
        });
        break;
      case PredefinedAction.REQUEST_OVERRIDE_DRIVING_POLICY: {
        buttons.unshift({
          label: 'Begär undantag',
          onClick: () => handleRequestOverrideDrivingPolicy(),
        });
        break;
      }

      default:
        buttons.unshift({
          label: 'Spara',
          onClick: () => handleExecute(false),
        });
        break;
    }
    return buttons;
  }, [
    onClose,
    actionType,
    handleExecute,
    handleCreateAssignmentRequest,
    handleRequestOverrideDrivingPolicy,
  ]);

  return (
    <MyModal buttons={getButtons()} onClose={onClose} title="Bekräfta">
      <div>{predefinedActionsInfo[actionType]}</div>
      {getContent()}
      {errorMessage && <div>{errorMessage}</div>}
    </MyModal>
  );
};

export default PredefinedActionModal;
