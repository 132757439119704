// import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalReceiptModel, PaymentCardModel } from 'api/model';
import { useReceiptFindReceipts } from 'api/receipt/receipt';
import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ExternalReceipt from './ExternalReceipt';
import TextButton from 'components/inputs/TextButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const BatchSize = 10;

interface Props {
  paymentCard: PaymentCardModel | undefined;
}

const ExternalReceipstList: FC<Props> = ({ paymentCard }) => {
  const [receipts, setReceipts] = useState<ExternalReceiptModel[]>([]);
  const [canLoadMore, setCanLoadMore] = useState(true);

  const findReceiptsCall = useReceiptFindReceipts();

  // reset receipts on payment card change
  useEffect(() => {
    const resetReceipts = async () => {
      if (!paymentCard?.number) {
        setReceipts([]);
      } else {
        const result = await findReceiptsCall.mutateAsync({
          params: {
            cardNumber: paymentCard?.number,
            skip: 0,
            take: BatchSize,
          },
        });

        if (result.length < BatchSize) {
          setCanLoadMore(false);
        }

        setReceipts(result);
      }
    };

    resetReceipts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentCard?.number]);

  // load more receipts
  const loadMore = async () => {
    const result = await findReceiptsCall.mutateAsync({
      params: {
        cardNumber: paymentCard?.number,
        skip: receipts.length,
        take: 10,
      },
    });

    setReceipts([...receipts, ...result]);
  };

  if (!paymentCard) {
    return null;
  }

  return (
    <Wrapper>
      {receipts.length === 0 && <div>Inga kvitton hittades</div>}
      {receipts.map((receipt, index) => (
        <ExternalReceipt key={receipt.id} receipt={receipt} index={index} />
      ))}
      {canLoadMore && (
        <TextButton onClick={loadMore}>
          Visa fler{' '}
          {findReceiptsCall.isLoading && (
            <FontAwesomeIcon icon={faSpinner} spin />
          )}
        </TextButton>
      )}
    </Wrapper>
  );
};

export default ExternalReceipstList;
