import {
  AssignmentCancelTypeEnum,
  AssignmentDeviationEnum,
  AssignmentViewModel,
  CaseTypeEnum,
} from 'api';

// eslint-disable-next-line import/prefer-default-export
export function validateMoveToApproved(
  viewModel: AssignmentViewModel
): string[] {
  if (
    [
      AssignmentDeviationEnum.Canceled,
      AssignmentDeviationEnum.Discarded,
    ].includes(viewModel.assignment.assignmentDeviationID)
  ) {
    if (
      viewModel.assignment.assignmentDeviationID ===
        AssignmentDeviationEnum.Canceled &&
      viewModel.assignment.assignmentCancelTypeID !==
        AssignmentCancelTypeEnum.CanceledNotArrived // cancelled not arrived should be checked however
    ) {
      return []; // no checks needed for cancelled and discarded assignments
    }
  }

  const checks: [checkFailed: boolean, message: string][] = [
    [!viewModel.case.company, 'Ange företag för att godkänna uppdraget.'],
    [
      !viewModel.assignment.startTime,
      'Ange starttid för att godkänna uppdraget.',
    ],
    [!viewModel.assignment.endTime, 'Ange sluttid för att godkänna uppdraget.'],
    [
      !viewModel.assignment.assignedTo,
      'Ange fälttestare för att godkänna uppdraget.',
    ],
  ];

  switch (viewModel.case.caseTypeID) {
    case CaseTypeEnum.HomeDelivery: {
      checks.push([
        viewModel.assignment.toCounty === undefined,
        'Ange till vilket län fordonet skall levereras (Till, län) för att godkänna uppdraget.',
      ]);
      break;
    }

    case CaseTypeEnum.Purchase: {
      checks.push([
        viewModel.assignment.fromCounty === undefined,
        'Ange i vilket län inköpet skall ske (Från, län) för att godkänna uppdraget.',
      ]);
      break;
    }

    default: {
      break;
    }
  }

  return checks.filter(([failed]) => failed).map(([, message]) => message);
}
