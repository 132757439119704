import { faCheck, faTrash, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { useCompanyDepartmentGetCompanyDepartments } from 'api/company-department/company-department';
import {
  OverlapDTO,
  TimeReportModel,
  TimeReportStatus,
  UserModel,
  WorkType,
} from 'api/model';
import {
  getTimeReportGetTimeReportsQueryKey,
  useTimeReportCheckForTimeReportOverlap,
  useTimeReportCreateTimeReport,
  useTimeReportGetTimeReportsForDayByUser,
  useTimeReportUpdateTimeReport,
} from 'api/time-report/time-report';
import CompanyPicker from 'components/CompanyPicker';
import Modal from 'components/Modal';
import Checkbox from 'components/inputs/Checkbox';
import DatePicker, { CustomInput } from 'components/inputs/DatePicker';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SearchableSelect, {
  SearchableSelectItem,
  SelectOption,
} from 'components/inputs/SearchableSelect';
import Select from 'components/inputs/Select';
import TextArea from 'components/inputs/TextArea';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import MediaQuery from 'constants/MediaQuery';
import Roles from 'constants/Roles';
import useTranslations from 'contexts/basicData/useTranslations';
import useUsers from 'contexts/basicData/useUsers';
import useModalStack from 'contexts/modal/useModalStack';
import { AuthorizedButton } from 'pages/InternalDeliveryGroup/components';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Form, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { formatTime } from 'utils/date-helpers';
import { showException } from 'utils/exception-helper';

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  ${MediaQuery.mobileL} {
    grid-template-columns: 1fr 1fr;
  }
  min-width: 500px;
  overflow: auto;
`;

const ColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-right: 50px;
  max-width: 250px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 10px;

  // target children of the children
  // (for fontawesome icons)
  > * > * {
    margin-right: 5px;
  }
`;

const generateDefaultValues = (timeReport?: TimeReportModel) => {
  return {
    id: timeReport?.id ?? undefined,
    userID: timeReport?.userID,
    title: timeReport?.title,
    description: timeReport?.description,
    workTypeID: timeReport?.workTypeID ?? WorkType.Other,
    startTime: timeReport?.startTime ?? new Date(),
    endTime: timeReport?.endTime ?? new Date(),
    breakTime: timeReport?.breakTime,
    reportStatusID: timeReport?.reportStatusID,
    statusMessage: timeReport?.statusMessage,
    includeInInvoice: timeReport?.includeInInvoice ?? false,
    invoiceDataID: timeReport?.invoiceDataID ?? undefined,
    invoiceCompanyID: timeReport?.invoiceCompanyID,
    includeInSalaryExport: timeReport?.includeInSalaryExport ?? false,
    companyDepartmentID: timeReport?.companyDepartmentID || 0,
  };
};

interface Props {
  onClose: () => void;
  timeReport?: TimeReportModel;
}

const TimeReportModal: FC<Props> = ({ onClose, timeReport }) => {
  const modalStack = useModalStack();
  const [isEditing, setIsEditing] = useState<boolean>(!timeReport);
  const [overlaps, setOverlaps] = useState<OverlapDTO>();
  const isCreate = !timeReport;
  const translation = useTranslations();
  const users = useUsers();

  const form = useForm<TimeReportModel>({
    defaultValues: generateDefaultValues(timeReport),
  });
  const { errors } = form.formState;

  const queryClient = useQueryClient();
  const [sameDayTimeReports, setSameDayTimeReports] = useState<
    TimeReportModel[]
  >([]);

  const { workTypes } = translation;
  const worktypeOptions = useMemo(() => {
    return Object.entries(workTypes).map(([reason, reasonName]) => ({
      key: reason,
      value: Number(reason),
      label: reasonName,
    }));
  }, [workTypes]);

  // returns the total time for the day in milliseconds
  const totalTimeForDay = useMemo(() => {
    const formStart = form.getValues('startTime');
    const formEnd = form.getValues('endTime');
    const formTime =
      !formStart || !formEnd ? 0 : formEnd.getTime() - formStart.getTime();

    const totalTime = sameDayTimeReports.reduce((acc, report) => {
      if (report.reportStatusID === TimeReportStatus.Removed) return acc;
      if (report.id === form.getValues('id')) return acc;
      const start = report.startTime?.getTime();
      const end = report.endTime?.getTime();
      return acc + (start && end ? end - start : 0);
    }, formTime);

    return totalTime;
  }, [form, sameDayTimeReports]);

  useEffect(() => {
    form.trigger('companyDepartmentID');
    form.trigger('invoiceCompanyID');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch('companyDepartmentID'), form.watch('invoiceCompanyID')]);

  // api calls
  const getCompanyDepartmentsCall = useCompanyDepartmentGetCompanyDepartments();
  const getOverlapsCall = useTimeReportCheckForTimeReportOverlap();
  const getSameDayReports = useTimeReportGetTimeReportsForDayByUser();
  const createReport = useTimeReportCreateTimeReport({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getTimeReportGetTimeReportsQueryKey()
        );
        onClose();
      },
    },
  });
  const updateReport = useTimeReportUpdateTimeReport({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          getTimeReportGetTimeReportsQueryKey()
        );
        // onClose();
      },
    },
  });

  const companyDepartmentOptions = useMemo(() => {
    return (
      getCompanyDepartmentsCall.data?.map((department) => ({
        key: department.id,
        label: department.name,
        value: department.id,
      })) ?? []
    );
  }, [getCompanyDepartmentsCall.data]);

  const end = form.watch('endTime');
  const start = form.watch('startTime');
  const statusID = form.watch('reportStatusID');
  const userID = form.watch('userID');

  useEffect(() => {
    const refreshSameDayReports = async () => {
      if (userID && start) {
        const result = await getSameDayReports.mutateAsync({
          params: {
            userId: userID,
            date: start,
          },
        });
        setSameDayTimeReports(result);
      }
    };

    refreshSameDayReports();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, start]);

  useEffect(() => {
    const refreshOverlaps = async () => {
      if (start && end && getCompanyDepartmentsCall.data !== undefined) {
        const result = await getOverlapsCall.mutateAsync({
          data: form.getValues(), // Cast form.getValues() to TimeReportModel
        });

        setOverlaps(result ?? undefined);
      }
    };

    refreshOverlaps();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userID, start, end, getCompanyDepartmentsCall.data]);

  // remove invoice company if report is not included in invoice anymore
  const watchIncludeInInvoice = form.watch('includeInInvoice');
  useEffect(() => {
    if (!watchIncludeInInvoice) {
      form.setValue('invoiceCompanyID', undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchIncludeInInvoice]);

  const userOptionRender = useCallback(
    (
      key: string,
      option: SelectOption<number | null, UserModel | null>,
      onClick: () => void
    ) => {
      return (
        <SearchableSelectItem key={key} onClick={onClick}>
          <span>{option.label}</span>
        </SearchableSelectItem>
      );
    },
    []
  );

  const worktypeOptionRender = useCallback(
    (
      key: string,
      option: SelectOption<number, string>,
      onClick: () => void
    ) => {
      return (
        <SearchableSelectItem key={key} onClick={onClick}>
          <span>{option.label}</span>
        </SearchableSelectItem>
      );
    },
    []
  );

  const userOptions = useMemo(() => {
    const opts: SelectOption<number | null, UserModel | null>[] = Object.values(
      users
    )
      .filter((usr) => usr.isActive)
      .map((usr) => ({
        label: usr.name,
        value: usr.userID,
        extra: usr,
      }))
      .sort((a, b) => {
        if (a.label < b.label) {
          return -1;
        }
        if (a.label > b.label) {
          return 1;
        }
        return 0;
      });

    return opts;
  }, [users]);

  const handleSubmit = async () => {
    await form.handleSubmit(async (data) => {
      try {
        if (isCreate) {
          await createReport.mutateAsync({
            data,
          });
        } else {
          await updateReport.mutateAsync({
            data,
          });
        }
      } catch (error) {
        showException(error);
      } finally {
        setIsEditing(false);
      }
    })();
  };

  const handleDelete = async () => {
    if (!timeReport) return;
    try {
      await updateReport.mutateAsync({
        data: {
          ...timeReport,
          reportStatusID: TimeReportStatus.Removed,
        },
      });

      modalStack.pop();
    } catch (error) {
      showException(error);
    }
  };

  // listen to changes on form.watch('workTypeID') and handle changes
  useEffect(() => {
    if (form.watch('workTypeID') === WorkType.RentedStaff) {
      form.setValue('includeInInvoice', true); // default to false if work type is RentedStaff
    } else {
      form.setValue('includeInInvoice', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form.watch('workTypeID')]);

  const handleApprove = async () => {
    form.setValue('reportStatusID', TimeReportStatus.Approved);
    handleSubmit();
  };
  const handleDecline = async () => {
    form.setValue('reportStatusID', TimeReportStatus.Declined);
    handleSubmit();
  };

  return (
    <Modal
      onClose={onClose}
      title={`Tidrapport ${timeReport?.invoiceDataID !== undefined ? ' - Fakturerad' : ''}`}
      closeOnOutsideClick={false}
      buttons={
        isEditing
          ? [
              {
                label: isCreate === true ? 'Registrera ny tid' : 'Spara',
                disabled:
                  createReport.isLoading ||
                  updateReport.isLoading ||
                  !form.formState.isValid,
                onClick: () => {
                  handleSubmit();
                },
              },
              {
                label: 'Avbryt',
                disabled: createReport.isLoading || updateReport.isLoading,
                onClick: () => {
                  form.reset(timeReport);
                  setIsEditing(false);
                },
              },
            ]
          : [
              {
                label: 'Redigera',
                disabled: createReport.isLoading || updateReport.isLoading,
                onClick: () => setIsEditing(true),
              },
              {
                label: 'Ta bort',
                icon: <FontAwesomeIcon icon={faTrash} />,
                disabled:
                  createReport.isLoading ||
                  updateReport.isLoading ||
                  timeReport?.invoiceDataID !== null,
                onClick: () => {
                  modalStack.push(
                    <Modal
                      title="Bekräfta borttagning"
                      buttons={[
                        {
                          label: 'Ta bort',
                          onClick: () => {
                            modalStack.pop();
                            handleDelete();
                          },
                        },
                        {
                          label: 'Avbryt',
                          onClick: () => {
                            modalStack.pop();
                          },
                        },
                      ]}
                    >
                      Är du säker på att du vill ta bort tidrapporten?
                    </Modal>
                  );
                },
              },
            ]
      }
    >
      {createReport.isLoading || updateReport.isLoading ? (
        <LoadingSpinner />
      ) : (
        <Form control={form.control}>
          <ContentWrapper>
            <ColumnWrapper>
              {/* {(form.watch('title')?.length ?? 0) > 0 && ( */}
              <LabelWrap label="Benämning">
                <Input
                  {...form.register('title', { required: false })}
                  disabled={!isEditing}
                  placeholder="Ex. 'Lagerarbete'"
                />
              </LabelWrap>
              {/* )} */}
              <LabelWrap
                label="Avdelning"
                errorLabel={errors.companyDepartmentID?.message}
              >
                <Select
                  disabled={!isEditing}
                  {...form.register('companyDepartmentID', {
                    required: {
                      value: true,
                      message: 'Du måste välja en avdelning',
                    },
                    onChange: (e) => {
                      // Convert the value to a number before updating the form state
                      form.setValue(
                        'companyDepartmentID',
                        Number(e.target.value)
                      );
                    },
                  })}
                >
                  {companyDepartmentOptions.map((o) => (
                    <option
                      key={o.key}
                      value={o.value}
                      onSelect={() => {
                        form.setValue('companyDepartmentID', Number(o.value));
                      }}
                    >
                      {o.label}
                    </option>
                  ))}
                </Select>
              </LabelWrap>
              <LabelWrap
                label="Kommentar"
                errorLabel={
                  totalTimeForDay > 8 * 60 * 60 * 1000 &&
                  form.getValues('description').length < 10
                    ? 'Fyll i vid övertid'
                    : errors.description && 'Obligatorisk'
                }
              >
                <TextArea
                  {...form.register('description', {
                    required: totalTimeForDay > 8 * 60 * 60 * 1000, // required if total time for day is more than 8 hours
                  })}
                  disabled={!isEditing}
                  placeholder="Kort beskrivning"
                />
              </LabelWrap>
              <LabelWrap
                label="Anställd"
                errorLabel={errors.userID && 'Obligatorisk'}
              >
                <SearchableSelect
                  {...form.register('userID', {
                    required: true,
                  })}
                  disabled={!isEditing}
                  selectedValue={userID}
                  options={userOptions}
                  optionRender={userOptionRender}
                  onOptionClicked={(user) => {
                    form.setValue('userID', user ?? -1);
                  }}
                  searchFilter={(searchString, options) => {
                    return options.filter((e) => {
                      if (!searchString) return true;
                      return e.label.toLowerCase().includes(searchString);
                    });
                  }}
                />
              </LabelWrap>
              <LabelWrap
                label="Starttid"
                errorLabel={errors.startTime && 'Obligatorisk'}
              >
                <DatePicker
                  {...form.register('startTime', {
                    required: true,
                  })}
                  customInput={
                    <CustomInput
                      date={start ?? new Date()}
                      showTime
                      ref={null}
                    />
                  }
                  dateFormat="yyyy-MM-dd HH:mm"
                  disabled={!isEditing}
                  locale="sv"
                  onChange={(date) => {
                    if (!date) return;
                    form.setValue('startTime', date);
                  }}
                  selected={form.getValues('startTime')}
                  timeInputLabel="Startar"
                  shouldCloseOnSelect
                  showTimeSelect
                  timeCaption="Startar"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                />
              </LabelWrap>
              <LabelWrap
                label="Sluttid"
                errorLabel={errors.endTime && 'Obligatorisk'}
              >
                <DatePicker
                  {...form.register('endTime', {
                    required: true,
                  })}
                  customInput={
                    <CustomInput date={end ?? new Date()} showTime ref={null} />
                  }
                  disabled={!isEditing}
                  locale="sv"
                  onChange={(date) => {
                    if (!date) return;
                    form.setValue('endTime', date);
                  }}
                  selected={form.getValues('endTime')}
                  shouldCloseOnSelect
                  showTimeSelect
                  timeCaption="Slutar"
                  timeFormat="HH:mm"
                  timeIntervals={15}
                />
              </LabelWrap>
              <LabelWrap
                label="Rasttid (minuter)"
                errorLabel={errors.breakTime && errors.breakTime.message}
              >
                <Input
                  {...form.register('breakTime', {
                    valueAsNumber: true,
                    validate: (value) => {
                      if (value && value < 0) {
                        return 'Rasttid kan inte vara negativt';
                      }
                      return true;
                    },
                  })}
                  type="number"
                  disabled={!isEditing}
                  placeholder="Rasttid"
                />
              </LabelWrap>
              <LabelWrap label="Typ av tidregistrering ">
                <SearchableSelect
                  onOptionClicked={(value) => {
                    form.setValue('workTypeID', value as WorkType);
                  }}
                  optionRender={worktypeOptionRender}
                  options={worktypeOptions}
                  searchFilter={(str, ops) => ops}
                  selectedValue={form.watch('workTypeID')}
                />
              </LabelWrap>
            </ColumnWrapper>
            <ColumnWrapper>
              <LabelWrap label="Ska ingå i löneunderlag ">
                <Checkbox
                  {...form.register('includeInSalaryExport')}
                  disabled={!isEditing}
                />
              </LabelWrap>
              <LabelWrap label="Ska faktureras ">
                <Checkbox
                  {...form.register('includeInInvoice')}
                  disabled={!isEditing}
                />
              </LabelWrap>
              {form.watch('includeInInvoice') === true && (
                <LabelWrap
                  label="Företag"
                  errorLabel={errors.invoiceCompanyID?.message}
                >
                  <CompanyPicker
                    disabled={!isEditing}
                    {...form.register('invoiceCompanyID', {
                      required: {
                        value: !!form.watch('includeInInvoice'),
                        message: 'Du måste välja ett företag att fakturera',
                      },
                    })}
                    onChange={(v: number | null) => {
                      form.setValue('invoiceCompanyID', v ?? undefined);
                    }}
                    value={form.watch('invoiceCompanyID') ?? null}
                    selectedCompanyId={form.watch('invoiceCompanyID') ?? null}
                    onCompanySelected={(company) => {
                      form.setValue(
                        'invoiceCompanyID',
                        company?.companyID ?? undefined
                      );
                    }}
                    // filter
                  />
                </LabelWrap>
              )}
            </ColumnWrapper>
            <ColumnWrapper>
              {(overlaps?.timeReports.length ?? 0) > 0 && (
                <LabelWrap errorLabel="Överlappande tidrapporter">
                  <ul>
                    {overlaps?.timeReports.map((overlap) => (
                      <li key={overlap.id}>
                        <p>
                          <i>
                            {`${overlap.title}: (${formatTime(overlap.startTime)} – ${formatTime(overlap.endTime)})`}
                          </i>
                        </p>
                      </li>
                    ))}
                  </ul>
                </LabelWrap>
              )}
              {(overlaps?.assignments.length ?? 0) > 0 && (
                <LabelWrap errorLabel="Överlappande tidrapporter">
                  <ul>
                    {overlaps?.assignments.map((overlap) => (
                      <li key={overlap.assignment.assignmentID}>
                        <p>
                          <i>
                            {`${overlap.case.registrationNumber}: ${formatTime(overlap.assignment.startTime)} – ${formatTime(overlap.assignment.endTime)}`}
                          </i>
                        </p>
                      </li>
                    ))}
                  </ul>
                </LabelWrap>
              )}
              {!isCreate && (
                <>
                  {timeReport?.invoiceDataID === undefined ||
                    (timeReport?.invoiceDataID === null && (
                      <ButtonWrapper
                        style={{
                          paddingTop: 10,
                          display: 'flex',
                          gap: '10px',
                        }}
                      >
                        <AuthorizedButton
                          roles={[Roles.Approval]}
                          onClick={() => {
                            handleApprove();
                          }}
                          positive={statusID === TimeReportStatus.Approved}
                          // disabled={
                          //   me?.companyDepartmentId !==
                          //   timeReport?.companyDepartmentID
                          // }
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          Godkänd
                        </AuthorizedButton>
                        <AuthorizedButton
                          roles={[Roles.Approval]}
                          onClick={() => {
                            handleDecline();
                          }}
                          negative={statusID === TimeReportStatus.Declined}
                          // disabled={
                          //   me?.companyDepartmentId !==
                          //   timeReport?.companyDepartmentID
                          // }
                        >
                          <FontAwesomeIcon icon={faXmark} />
                          Avslagen
                        </AuthorizedButton>
                      </ButtonWrapper>
                    ))}
                  <LabelWrap label="Status">
                    <p>
                      <i>
                        {statusID && translation.timeReportStatuses[statusID]}
                      </i>
                    </p>
                  </LabelWrap>
                  <LabelWrap label="Statusmeddelande">
                    <p style={{ lineHeight: '1.3rem', maxWidth: '200px' }}>
                      <i>{form.getValues('statusMessage')}</i>
                    </p>
                  </LabelWrap>
                </>
              )}
            </ColumnWrapper>
          </ContentWrapper>
        </Form>
      )}
    </Modal>
  );
};

export default TimeReportModal;
