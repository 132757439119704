import { useQueryClient } from '@tanstack/react-query';
import { getInternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryKey } from 'api/internal-delivery-budget/internal-delivery-budget';
import { AssignmentRequestStatusEnum } from 'api/model';
import moment from 'moment';
import { DateFormat } from 'pages/InternalDeliveryGroup/components/filterBars';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface InternalDeliveryGroupBudgetSettingsFiltersState {
  sortedColumnIndex: number;
  sortAscending: boolean;
  selectedDate: Date;
  startDate: Date;
  endDate: Date;
  weekNumbers: number[];
}

interface InternalDeliveryGroupBudgetSettingsFiltersProps {
  state: InternalDeliveryGroupBudgetSettingsFiltersState;
  setState: React.Dispatch<
    React.SetStateAction<InternalDeliveryGroupBudgetSettingsFiltersState>
  >;
  selectedFilterView: DateFormat;
  setSelectedFilterView: React.Dispatch<React.SetStateAction<DateFormat>>;
  selectedStatusView: Set<AssignmentRequestStatusEnum> | null;
  setSelectedStatusView: React.Dispatch<
    React.SetStateAction<Set<AssignmentRequestStatusEnum> | null>
  >;
}

const InternalDeliveryGroupBudgetSettingsFiltersContext =
  createContext<InternalDeliveryGroupBudgetSettingsFiltersProps>({
    state: {
      sortedColumnIndex: 0,
      sortAscending: true,
      selectedDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      weekNumbers: [],
    },
    setState: () => Function,
    selectedFilterView: 'month',
    setSelectedFilterView: () => Function,
    selectedStatusView: null,
    setSelectedStatusView: () => Function,
  });

export const InternalDeliveryGroupBudgetSettingsFiltersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedFilterView, setSelectedFilterView] =
    useState<DateFormat>('month');
  const [selectedStatusView, setSelectedStatusView] =
    useState<Set<AssignmentRequestStatusEnum> | null>(null);
  const queryClient = useQueryClient();

  const [state, setState] =
    useState<InternalDeliveryGroupBudgetSettingsFiltersState>({
      sortedColumnIndex: 0,
      sortAscending: true,
      selectedDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      weekNumbers: [],
    });

  useEffect(() => {
    const weekNrs: number[] = [];
    for (
      let d = state.startDate;
      d <= state.endDate;
      d.setDate(d.getDate() + 1)
    ) {
      const isMonday = moment(d).isoWeekday() === 1;
      const momentWeek = moment(d).isoWeek();

      if (isMonday) {
        weekNrs.push(momentWeek);
      }
    }

    setState((prev) => ({
      ...prev,
      weekNumbers: weekNrs,
    }));
  }, [state.endDate, state.startDate]);

  const updateDateRange = useCallback(
    (newDate: Date) => {
      if (selectedFilterView === 'date') {
        setState((prev) => ({
          ...prev,
          startDate: newDate,
          endDate: newDate,
        }));
      }

      if (selectedFilterView === 'week') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('isoWeek').toDate(),
          endDate: moment(newDate).endOf('isoWeek').toDate(),
        }));
      }

      if (selectedFilterView === 'month') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('month').toDate(),
          endDate: moment(newDate).endOf('month').toDate(),
        }));
      }

      if (selectedFilterView === 'year') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('year').toDate(),
          endDate: moment(newDate).endOf('year').toDate(),
        }));
      }
    },
    [selectedFilterView, setState]
  );

  useEffect(() => {
    updateDateRange(state.selectedDate);
    queryClient.invalidateQueries(
      getInternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryKey()
    );
  }, [queryClient, state.selectedDate, updateDateRange]);

  const providerValue = useMemo(
    (): InternalDeliveryGroupBudgetSettingsFiltersProps => ({
      state,
      setState,
      selectedFilterView,
      setSelectedFilterView,
      selectedStatusView,
      setSelectedStatusView,
    }),
    [selectedFilterView, selectedStatusView, state]
  );

  return (
    <InternalDeliveryGroupBudgetSettingsFiltersContext.Provider
      value={providerValue}
    >
      {children}
    </InternalDeliveryGroupBudgetSettingsFiltersContext.Provider>
  );
};

export const useInternalDeliveryGroupBudgetSettingsFilters =
  (): InternalDeliveryGroupBudgetSettingsFiltersProps => {
    return useContext(InternalDeliveryGroupBudgetSettingsFiltersContext);
  };
