/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  InternalDeliveryOverviewEntry,
  InternalDeliveryOverviewGetInternalDeliveryOverviewExportParams,
  InternalDeliveryOverviewGetInternalDeliveryOverviewParams,
} from '.././model';
import useInternalDeliveryOverviewGetInternalDeliveryOverviewMutator from '.././mutator/custom-instance';
import useInternalDeliveryOverviewGetInternalDeliveryOverviewExportMutator from '.././mutator/custom-instance';

export const useInternalDeliveryOverviewGetInternalDeliveryOverviewHook =
  () => {
    const internalDeliveryOverviewGetInternalDeliveryOverview =
      useInternalDeliveryOverviewGetInternalDeliveryOverviewMutator<
        InternalDeliveryOverviewEntry[]
      >();

    return (
      params?: InternalDeliveryOverviewGetInternalDeliveryOverviewParams,
      signal?: AbortSignal
    ) => {
      return internalDeliveryOverviewGetInternalDeliveryOverview({
        url: `/api/InternalDeliveryOverview/GetInternalDeliveryOverview`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getInternalDeliveryOverviewGetInternalDeliveryOverviewQueryKey = (
  params?: InternalDeliveryOverviewGetInternalDeliveryOverviewParams
) => {
  return [
    `/api/InternalDeliveryOverview/GetInternalDeliveryOverview`,
    ...(params ? [params] : []),
  ] as const;
};

export const useInternalDeliveryOverviewGetInternalDeliveryOverviewQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: InternalDeliveryOverviewGetInternalDeliveryOverviewParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getInternalDeliveryOverviewGetInternalDeliveryOverviewQueryKey(params);

    const internalDeliveryOverviewGetInternalDeliveryOverview =
      useInternalDeliveryOverviewGetInternalDeliveryOverviewHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewHook
          >
        >
      >
    > = ({ signal }) =>
      internalDeliveryOverviewGetInternalDeliveryOverview(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type InternalDeliveryOverviewGetInternalDeliveryOverviewQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewHook
        >
      >
    >
  >;
export type InternalDeliveryOverviewGetInternalDeliveryOverviewQueryError =
  unknown;

export const useInternalDeliveryOverviewGetInternalDeliveryOverview = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewHook
      >
    >
  >,
  TError = unknown,
>(
  params?: InternalDeliveryOverviewGetInternalDeliveryOverviewParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useInternalDeliveryOverviewGetInternalDeliveryOverviewQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook =
  () => {
    const internalDeliveryOverviewGetInternalDeliveryOverviewExport =
      useInternalDeliveryOverviewGetInternalDeliveryOverviewExportMutator<Blob>();

    return (
      params?: InternalDeliveryOverviewGetInternalDeliveryOverviewExportParams
    ) => {
      return internalDeliveryOverviewGetInternalDeliveryOverviewExport({
        url: `/api/InternalDeliveryOverview/GetInternalDeliveryOverviewExport`,
        method: 'POST',
        params,
        responseType: 'blob',
      });
    };
  };

export const useInternalDeliveryOverviewGetInternalDeliveryOverviewExportMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook
          >
        >
      >,
      TError,
      {
        params?: InternalDeliveryOverviewGetInternalDeliveryOverviewExportParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook
        >
      >
    >,
    TError,
    {
      params?: InternalDeliveryOverviewGetInternalDeliveryOverviewExportParams;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const internalDeliveryOverviewGetInternalDeliveryOverviewExport =
      useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook
          >
        >
      >,
      {
        params?: InternalDeliveryOverviewGetInternalDeliveryOverviewExportParams;
      }
    > = (props) => {
      const { params } = props ?? {};

      return internalDeliveryOverviewGetInternalDeliveryOverviewExport(params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type InternalDeliveryOverviewGetInternalDeliveryOverviewExportMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook
        >
      >
    >
  >;

export type InternalDeliveryOverviewGetInternalDeliveryOverviewExportMutationError =
  unknown;

export const useInternalDeliveryOverviewGetInternalDeliveryOverviewExport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook
        >
      >
    >,
    TError,
    {
      params?: InternalDeliveryOverviewGetInternalDeliveryOverviewExportParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useInternalDeliveryOverviewGetInternalDeliveryOverviewExportHook
      >
    >
  >,
  TError,
  { params?: InternalDeliveryOverviewGetInternalDeliveryOverviewExportParams },
  TContext
> => {
  const mutationOptions =
    useInternalDeliveryOverviewGetInternalDeliveryOverviewExportMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
