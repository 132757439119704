import { useCallback, useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import {
  getGoSupportGetUnreadCommentsQueryKey,
  useGoSupportGetUnreadComments,
} from 'api/go-support/go-support';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/inputs/Checkbox';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import { useQueryClient } from '@tanstack/react-query';
import { CaseEventDTO } from 'api/model';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import {
  Centered,
  getTableRowClassName,
  SavingRowAnimationWrapper,
} from '../table-helpers';
import { ITranslationLookups } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';

const TABLE_NAME = 'comments';

interface AssignmentRow extends CaseEventDTO {
  comments: CaseEventDTO[];
}

const CommentsWidget = () => {
  const [comments, setComments] = useState<CaseEventDTO[]>([]);
  const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();
  const translations = useTranslations();

  const queryClient = useQueryClient();
  const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
    useCaseMarkEventsAsReadByAdmin({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            `${getGoSupportGetUnreadCommentsQueryKey()}`,
          ]);
        },
      },
    });

  const getComments = useGoSupportGetUnreadComments(
    {
      startDate: daterange[0] ?? undefined,
      endDate: daterange[1] ?? undefined,
      businessUnitIDs: selectedBusinessUnits,
    },
    {
      query: {
        refetchInterval: UPDATE_INTERVAL,
      },
    }
  );

  useEffect(() => {
    if (getComments.data) {
      setComments(getComments.data);
    } else {
      setComments([] as CaseEventDTO[]);
    }
  }, [getComments.data]);

  const filteredComments = useMemo(() => {
    let filtered = [...comments];

    if (searchText.length > 0) {
      filtered = filtered.length
        ? filtered
            .filter((c) => {
              return (
                c.comment?.toLowerCase().includes(searchText.toLowerCase()) ||
                c.registrationNumber
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                (users[c.createdByID]?.name ?? '')
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            })
            .sort((a, b) => b.created.getTime() - a.created.getTime()) // Latest first
        : [];
    }

    const asRows: AssignmentRow[] = filtered.reduce((acc, c) => {
      const existingRow = acc.find((r) => r.caseID === c.caseID);
      if (existingRow) {
        existingRow.comments.push(c);
      } else {
        acc.push({ ...c, comments: [c] });
      }
      return acc;
    }, [] as AssignmentRow[]);

    return asRows;
  }, [comments, searchText, users]);

  const getAllCommentsAsString = useCallback(
    (commentEvents: CaseEventDTO[]) => {
      return commentEvents
        .map(
          (c) =>
            `${formatDateTime(c.created)}: ${c.comment} (${users[c.createdByID]?.name ?? ''})`
        )
        .join('\n');
    },
    [users]
  );

  const columnSettings: ColumnSetting<AssignmentRow, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<AssignmentRow, ITranslationLookups>[] = [
        {
          head: 'Nr',
          render: (e, t, f, i) => i + 1,
          width: 15,
        },
        {
          head: 'Reg.nr',
          render: (row) => `${row.registrationNumber}`,
          width: 50,
          sortFunction: (a, b) => {
            return a.registrationNumber.localeCompare(b.registrationNumber);
          },
        },
        {
          head: 'Status',
          render: (row) =>
            `${row.assignmentStatus ? translations.assignmentStatuses[row.assignmentStatus] : ''}`,
          width: 50,
          sortFunction: (a, b) => {
            return a.assignmentStatus - b.assignmentStatus;
          },
        },
        {
          head: 'Tid',
          render: (row) => `${formatDateTime(row.created)}`,
          width: 100,
          sortFunction: (a, b) => a.created.getTime() - b.created.getTime(),
        },
        {
          head: 'Användare',
          render: (row) => {
            return (
              <span>
                {users[row.createdByID] ? users[row.createdByID].name : ''}
              </span>
            );
          },
          width: 100,
          sortFunction: (a, b) =>
            (users[a.createdByID]?.name ?? '').localeCompare(
              users[b.createdByID]?.name ?? ''
            ),
        },
        {
          head: 'Telefonnummer',
          render: (row) => {
            return (
              <span>
                {users[row.createdByID]
                  ? users[row.createdByID].phoneNumber
                  : ''}
              </span>
            );
          },
          width: 100,
          sortFunction: (a, b) =>
            (users[a.createdByID]?.phoneNumber ?? '').localeCompare(
              users[b.createdByID]?.phoneNumber ?? ''
            ),
        },
        {
          head: 'Kommentar',
          render: (row) => row.comment,
          width: 300,
          sortFunction: (a, b) => a.comment.localeCompare(b.comment),
        },
        {
          head: ' ',
          render: (row) => (
            <Centered title={getAllCommentsAsString(row.comments)}>
              {row.comments.length > 1 && <b>(+{row.comments.length - 1}) </b>}
            </Centered>
          ),
          width: 30,
        },
        {
          head: ' ',
          render: (row) => (
            <Centered>
              <FontAwesomeIcon
                onClick={() => {
                  window.open(
                    `${Routes.search.index}/${row.caseID}`,
                    row.caseID.toString() ?? '_blank'
                  );
                }}
                icon={faArrowUpRightFromSquare}
                fontSize="18px"
                style={{ cursor: 'pointer' }}
              />
            </Centered>
          ),
          width: 20,
        },
        {
          head: 'Klart',
          render: (row) => (
            <Centered>
              <Checkbox
                disabled={markAsReadStatus === 'loading'}
                onChange={async () => {
                  setOngoingSaves([...ongoingSaves, row.id]);
                  await markEventAsRead({
                    data: row.comments.map((c) => c.id),
                  });
                  setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
                }}
              />
            </Centered>
          ),
          width: 30,
        },
      ];
      return columns;
    }, [
      getAllCommentsAsString,
      markAsReadStatus,
      markEventAsRead,
      ongoingSaves,
      translations.assignmentStatuses,
      users,
    ]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.Comments]}
      count={comments.length}
      isLoading={getComments.isLoading}
      info="Markera som klar för att markera kommentar som läst."
    >
      <SavingRowAnimationWrapper
        tableName={TABLE_NAME}
        savingIds={ongoingSaves}
      >
        <Table
          columnSettings={columnSettings}
          rows={filteredComments}
          rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
          useColumnWidthAsFlexAmount
          renderProps={translations}
        />
      </SavingRowAnimationWrapper>
    </Widget>
  );
};

export default CommentsWidget;
