/* eslint-disable @typescript-eslint/ban-types */

import styled from 'styled-components';
import FocusableCell from './FocusableCell';

import {
  ColumnSetting,
  focusableTableCellClassName,
  makeColumnClassName,
  tableCellClassName,
} from './utils';

export const tableRowClassName = 'table-row';

const Container = styled.div<{ colored?: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  // min-width: 100%;

  &:nth-child(odd) {
    background-color: whitesmoke;
  }

  &:hover > * {
    background: rgba(0, 0, 0, 0.1);
  }
  &:hover > .${focusableTableCellClassName} {
    background: rgba(0, 0, 0, 0.15);
  }
`;

const Cell = styled.div`
  flex-wrap: nowrap;
  white-space: nowrap;

  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 3px;
  cursor: default;
`;

interface Props<
  Row extends Object,
  RenderProps extends Object | undefined = undefined,
> {
  columnSettings: ColumnSetting<Row, RenderProps>[];
  renderProps?: RenderProps;
  row: Row;
  rowIndex: number;
  onClick?(row: Row): void;
  className?: string;
}

const TableRow = <
  Row extends Object,
  RenderProps extends Object | undefined = undefined,
>({
  columnSettings,
  renderProps,
  row,
  rowIndex,
  onClick,
  className,
}: Props<Row, RenderProps>) => {
  const handleClick = () => {
    // // prevent if the click comes from a column that is focusable
    // if (e.target instanceof HTMLElement && e.target.closest('div[tabindex]')) {
    //   return;
    // }
    if (onClick) onClick(row);
  };

  return (
    <Container
      className={`${tableRowClassName} ${className}`}
      key={rowIndex}
      onClick={handleClick}
    >
      {columnSettings.map((setting, i) => {
        if (setting.focusable) {
          return (
            <FocusableCell
              className={`${makeColumnClassName(i)} ${tableCellClassName}`}
              columnSetting={setting}
              key={i}
              renderProps={renderProps}
              row={row}
              rowIndex={i}
            />
          );
        }

        const cellContent = setting.render(row, renderProps!, false, rowIndex);
        return (
          <Cell
            className={`${makeColumnClassName(i)} ${tableCellClassName}`}
            key={i}
            title={typeof cellContent === 'string' ? cellContent : undefined}
          >
            {cellContent}
          </Cell>
        );
      })}
    </Container>
  );
};

export default TableRow;
