import {
  AdvancedAssignmentFilterOption,
  AssignmentDeviationEnum,
  AssignmentStatusEnum,
} from 'api';
import { SortState } from 'components/Table';

export enum AssignmentsFiltersStateActionType {
  SET_VISIBLE_ASSIGNMENT_STATUSES = 'SET_VISIBLE_ASSIGNMENT_STATUSES',
  SET_VISIBLE_ASSIGNMENT_DEVIATIONS = 'SET_VISIBLE_ASSIGNMENT_DEVIATIONS',
  SET_ADVANCED_ASSIGNMENT_FILTERS = 'SET_ADVANCED_ASSIGNMENT_FILTERS',
  SET_VISIBLE_BUSINESS_UNITS = 'SET_VISIBLE_BUSINESS_UNITS',
  SET_VISIBLE_BUSINESS_UNIT_GROUPS = 'SET_VISIBLE_BUSINESS_UNITS_GROUPS',
  SET_DATE = 'SET_DATE',
  SET_SORT_STATE = 'SET_SORT_STATE',
  SET_SEARCH_TEXT = 'SET_SEARCH_TEXT',
  ERROR = 'ERROR',

  TRIGGER_ASSIGNMENTS_UPDATE = 'TRIGGER_ASSIGNMENTS_UPDATE',
  TRIGGER_ADMIN_HAS_READ_CASE = 'TRIGGER_ADMIN_HAS_READ_CASE',
}

export interface AssignmentsFiltersState {
  visibleStatuses: Set<AssignmentStatusEnum> | null;
  visibleDeviations: Set<AssignmentDeviationEnum> | null;
  advancedFilters: Set<AdvancedAssignmentFilterOption> | null;
  visibleBusinessUnits: number[];
  visibleBusinessUnitGroups: number[];
  sortedColumnIndex: number;
  sortAscending: boolean;
  date: Date;
  searchText: string;
  error?: string;

  triggerAssignmentsUpdate: any;
  triggerAdminHasReadCase?: number;
}

export const getInitialAssignmentsFiltersState =
  (): AssignmentsFiltersState => {
    return {
      visibleStatuses: null,
      visibleDeviations: null,
      advancedFilters: null,
      visibleBusinessUnits: [],
      visibleBusinessUnitGroups: [],
      sortedColumnIndex: 1,
      sortAscending: true,
      date: new Date(Date.now()),
      searchText: '',
      error: undefined,

      triggerAssignmentsUpdate: null,
      triggerAdminHasReadCase: undefined,
    };
  };

export type AssignmentsFiltersStateAction = ReturnType<
  (typeof assignmentsFiltersStateActions)[keyof typeof assignmentsFiltersStateActions]
>;

export const assignmentsFiltersStateActions = {
  setVisibleAssignmentStatuses: (statuses: Set<AssignmentStatusEnum> | null) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_VISIBLE_ASSIGNMENT_STATUSES,
      statuses,
    }) as const,
  setVisibleAssignmentDeviations: (
    deviations: Set<AssignmentDeviationEnum> | null
  ) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_VISIBLE_ASSIGNMENT_DEVIATIONS,
      deviations,
    }) as const,
  setAdvancedFilters: (
    advancedFilters: Set<AdvancedAssignmentFilterOption> | null
  ) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_ADVANCED_ASSIGNMENT_FILTERS,
      advancedFilters,
    }) as const,
  setVisibleBusinessUnits: (businessUnits: number[]) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_VISIBLE_BUSINESS_UNITS,
      businessUnits,
    }) as const,
  setVisibleBusinessUnitGroups: (businessUnitGroups: number[]) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_VISIBLE_BUSINESS_UNIT_GROUPS,
      businessUnitGroups,
    }) as const,
  setDate: (date: Date) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_DATE,
      date,
    }) as const,
  setSortingState: (sortingState: SortState) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_SORT_STATE,
      sortingState,
    }) as const,
  setSearchText: (text: string) =>
    ({
      type: AssignmentsFiltersStateActionType.SET_SEARCH_TEXT,
      text,
    }) as const,
  error: (error: string) =>
    ({
      type: AssignmentsFiltersStateActionType.ERROR,
      error,
    }) as const,

  triggerAssignmentsUpdate: (uniqueValue: any) =>
    ({
      type: AssignmentsFiltersStateActionType.TRIGGER_ASSIGNMENTS_UPDATE,
      uniqueValue,
    }) as const,

  triggerAdminHasReadCase: (caseId: number) =>
    ({
      type: AssignmentsFiltersStateActionType.TRIGGER_ADMIN_HAS_READ_CASE,
      caseId,
    }) as const,
};
