import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Wrapper = styled.label`
  display: inline-flex;
  align-items: baseline;
`;

const Input = styled.input`
  width: 0;
  height: 0;
` as FC<React.HTMLProps<HTMLInputElement>>;

const RenderedCheckbox = styled.div<{ padRight?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  cursor: pointer;

  ${({ padRight }) =>
    padRight &&
    css`
      margin-right: 5px;
    `}

  input+ & {
    color: ${({ theme }) => theme.colors.foreground.primary};

    & > * {
      opacity: 0.2;
    }
  }

  input:checked + & {
    background-color: ${({ theme }) => theme.colors.background.button};
    border-color: ${({ theme }) => theme.colors.background.button};
    color: ${({ theme }) => theme.colors.foreground.button};

    & > * {
      opacity: 1;
    }
  }
`;

export interface CheckboxProps extends React.HTMLProps<HTMLInputElement> {}

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>(
  ({ type, children, className, ...inputProps }, ref) => {
    return (
      <Wrapper className={className}>
        <Input {...inputProps} ref={ref} type="checkbox" />
        <RenderedCheckbox padRight={!!children}>
          <FontAwesomeIcon icon={faCheck} />
        </RenderedCheckbox>
        {children}
      </Wrapper>
    );
  }
);

export default Checkbox;
