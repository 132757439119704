import { AverageRatingDto, RatingDataDto, UserModel } from 'api/model';
import { useUserGetUserRatingStats } from 'api/user/user';
import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import UserRatingStats from './UserRatingStats';
import TextButton from 'components/inputs/TextButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import useModalStack from 'contexts/modal/useModalStack';
import ConfirmModal from 'components/Modal/ConfirmModal';
import { formatDateTime } from 'utils/date-helpers';
import UserRatingChart from 'components/Charts/ZakraLineChart';
import { makeDateComparator, makeNumberComparator } from 'utils/sorting';
import useTranslations from 'contexts/basicData/useTranslations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.background.tertiary};
  margin: 1rem;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

const Cell = styled.div<{ width?: number; border?: string }>`
  display: flex;
  ${({ width }) =>
    width !== undefined
      ? css`
          width: ${width}px;
        `
      : css`
          flex: 1;
        `};
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0.5rem;
  border: ${({ theme, border }) =>
    border ?? css`1px solid ${theme.colors.background.tertiary}`};
`;

const CellTitle = styled.div`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
`;

const CellRightLink = styled(TextButton)`
  margin-left: auto;
`;

type RatingDetailsModalProps = {
  ratings: RatingDataDto[];
};

const RatingDetailsModal: React.FC<RatingDetailsModalProps> = ({ ratings }) => {
  const modalStack = useModalStack();

  const handleShowRatingDetails = () => {
    modalStack.push(
      <ConfirmModal
        title="Utvärderingar"
        confirmText="Stäng"
        onConfirm={() => modalStack.pop()}
        content={
          <div>
            {ratings.map((rating) => (
              <Cell key={rating.assignmentId}>
                <strong>{rating.caseId}</strong>
                <br />
                {rating.rating}
                <br />
                {rating.comment}
                <br />
                {rating.timestamp && formatDateTime(rating.timestamp)}
              </Cell>
            ))}
          </div>
        }
      />
    );
  };

  return (
    <CellRightLink onClick={() => handleShowRatingDetails()}>
      Se alla utvärderingar <FontAwesomeIcon icon={faChevronRight} />
    </CellRightLink>
  );
};

type UserRatingStatsWidgetProps = {
  user: UserModel;
};

const UserRatingStatsWidget: React.FC<UserRatingStatsWidgetProps> = ({
  user,
}) => {
  const { ratingStatsBreakdownType } = useTranslations();
  const [expanded, setExpanded] = React.useState<boolean>(false);
  const getUserStatsCall = useUserGetUserRatingStats({
    userId: user.userID,
  });

  const getStatistics = useCallback(() => {
    const stats = getUserStatsCall.data;
    if (!stats) {
      return <div>Laddar...</div>;
    }

    const sortedCustomerRatingStats = stats.customerRatingStats.sort(
      makeNumberComparator((a) => a.breakdownType)
    );
    const sortedTLRatingStats = stats.tlRatingStats.sort(
      makeNumberComparator((a) => a.breakdownType)
    );

    const lineChartData: AverageRatingDto[] =
      stats.avgRatingByMonth.sort(
        makeDateComparator((a) => new Date(a.timestamp))
      ) ?? [];

    return (
      <>
        <Row>
          <Cell width={50} border="none">
            <strong>Kund</strong>
          </Cell>
          {sortedCustomerRatingStats.map((ratingStats) => (
            <Cell key={ratingStats.breakdownType}>
              <CellTitle>
                {ratingStatsBreakdownType[ratingStats.breakdownType]}
              </CellTitle>
              <UserRatingStats
                averageRating={ratingStats.averageRating}
                ratings={ratingStats.ratings}
                expanded={expanded}
              />
              {expanded && <RatingDetailsModal ratings={ratingStats.ratings} />}
            </Cell>
          ))}
        </Row>
        <Row>
          <Cell width={50} border="none">
            <strong>TL</strong>
          </Cell>
          {sortedTLRatingStats.map((ratingStats) => (
            <Cell key={ratingStats.breakdownType}>
              <CellTitle>
                {ratingStatsBreakdownType[ratingStats.breakdownType]}
              </CellTitle>
              <UserRatingStats
                averageRating={ratingStats.averageRating}
                ratings={ratingStats.ratings}
                expanded={expanded}
              />
              {expanded && <RatingDetailsModal ratings={ratingStats.ratings} />}
            </Cell>
          ))}
        </Row>
        {expanded && (
          <Row>
            <Cell>
              <UserRatingChart data={lineChartData} />
            </Cell>
          </Row>
        )}
      </>
    );
  }, [expanded, getUserStatsCall.data, ratingStatsBreakdownType]);

  return (
    <Wrapper>
      <Row>
        <Cell width={50} border="none" />
        <TextButton onClick={() => setExpanded(!expanded)}>
          Betygsstatistik{' '}
          {expanded ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </TextButton>
      </Row>
      {getStatistics()}
    </Wrapper>
  );
};

export default UserRatingStatsWidget;
