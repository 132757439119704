import React from 'react';
import styled from 'styled-components';
import {
  AssignmentRequestStatusEnum,
  AssignmentStatusEnum,
  ExtendedAssignmentRequestModel,
} from 'api/model';
import {
  useAssignmentRequestsDeleteAssignmentRequest,
  useAssignmentRequestsDenyAssignmentRequest,
  useAssignmentRequestsHandOutCarKeysToTransportDriver,
  useAssignmentRequestsTakeBackCarKeysFromTransportDriver,
} from 'api/assignment-requests/assignment-requests';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCancel,
  faKey,
  faTrashAlt,
  faVault,
} from '@fortawesome/free-solid-svg-icons';
import useModalStack from 'contexts/modal/useModalStack';
import useUsers from 'contexts/basicData/useUsers';
import Roles from 'constants/Roles';
import AuthorizedTextButton from 'components/inputs/AuthorizedTextButton';
import useMe from 'contexts/authentication/useMe';
import ConfirmModal from 'components/Modal/ConfirmModal';
import AssignmentRequestCarKeysModal from './AssignmentRequestCarKeysModal';
import ConfirmDenyAssignmentRequestModal from './ConfirmDenyAssignmentRequestModal';

const Actions = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-end;
  min-width: 100px;
  padding-left: 10px;
  position: relative;
`;

const MessageWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

interface Props {
  request?: ExtendedAssignmentRequestModel;
  onActionComplete: (close: boolean) => void;
}

const AssignmentRequestActions: React.FC<Props> = ({
  onActionComplete,
  request,
}) => {
  const users = useUsers();
  const me = useMe();
  const modalStack = useModalStack();
  const { assignmentRequest } = request ?? {};

  const requestedForID = request?.assignmentRequest?.requestedForID ?? -1;
  const numberOfCars = request?.assignmentRequest?.numberOfCars ?? 0;
  const allocatedAssignments =
    request?.assignmentRequest?.allocatedAssignments ?? [];

  // api
  const removeAssignmentRequest =
    useAssignmentRequestsDeleteAssignmentRequest();
  const handOutCarKeysToTransporter =
    useAssignmentRequestsHandOutCarKeysToTransportDriver();
  const takeBackCarKeysFromTransporter =
    useAssignmentRequestsTakeBackCarKeysFromTransportDriver();
  const denyAssignmentRequest = useAssignmentRequestsDenyAssignmentRequest();

  const onRemove = async () => {
    if (!assignmentRequest?.requestID || assignmentRequest?.requestID <= 0) {
      return;
    }

    if (allocatedAssignments.length > 0) {
      // show info modal telling the user that they need to remove the assignments first
      modalStack.push(
        <ConfirmModal
          title="Kan inte ta bort förfrågan"
          content="Förfrågan har allokerade uppdrag. Ta bort uppdragen först."
          onConfirm={() => modalStack.pop()}
          confirmText="OK"
        />
      );
      return;
    }

    modalStack.push(
      <ConfirmModal
        title="Bekräfta ta bort förfrågan"
        content="Är du säker på att du vill ta bort förfrågan?"
        onConfirm={async () => {
          await removeAssignmentRequest.mutateAsync({
            params: {
              id: assignmentRequest?.requestID,
            },
          });
          modalStack.pop();
          onActionComplete(true);
        }}
        onCancel={() => modalStack.pop()}
      />
    );
  };

  const onDeny = async () => {
    if (!assignmentRequest?.requestID || assignmentRequest?.requestID <= 0) {
      return;
    }

    modalStack.push(
      <ConfirmDenyAssignmentRequestModal
        onConfirm={async (message: string) => {
          await denyAssignmentRequest.mutateAsync({
            params: {
              id: assignmentRequest?.requestID,
              eventMessage: message,
            },
          });
          modalStack.pop();
          onActionComplete(true);
        }}
        onCancel={() => modalStack.pop()}
      />
    );
  };

  const onHandOutCarKeys = async () => {
    if (!assignmentRequest?.requestID || assignmentRequest?.requestID <= 0) {
      return;
    }
    modalStack.push(
      <AssignmentRequestCarKeysModal
        title="Bekräfta dela ut nycklar"
        message={
          <MessageWrapper>
            <p>{`${allocatedAssignments.length} av ${numberOfCars} bilar är allokerade.`}</p>
            <p>
              När du delar ut nycklar kommer de allokerade uppdragen att sättas
              som slutförda.
            </p>
            <p>Välj vilka uppdragsbilar som du vill dela ut nycklar till.</p>
          </MessageWrapper>
        }
        assignments={allocatedAssignments.filter(
          (a) =>
            a.assignment.assignmentStatusID === AssignmentStatusEnum.Created ||
            a.assignment.assignmentStatusID === AssignmentStatusEnum.Assigned
        )}
        isLoading={handOutCarKeysToTransporter.isLoading}
        onConfirm={async (assignmentIds) => {
          await handOutCarKeysToTransporter.mutateAsync({
            data: assignmentIds,
            params: {
              assignmentRequestId: assignmentRequest?.requestID,
            },
          });
          modalStack.pop();
          onActionComplete(false);
        }}
        onCancel={() => modalStack.pop()}
      />
    );
  };

  const onTakeBackCarKeys = () => {
    modalStack.push(
      <AssignmentRequestCarKeysModal
        title="Bekräfta ta tillbaka nycklar"
        message={
          <MessageWrapper>
            <p>
              När du tar in nycklar kommer bilen att tas bort från denna
              förfrågan/bokning, och uppdragsstatusen kommer att återställas.
            </p>
            <p>
              Välj vilka uppdragsbilar som du har fått tillbaka nycklar för.
            </p>
          </MessageWrapper>
        }
        assignments={allocatedAssignments}
        onConfirm={async (assignmentIds, reason) => {
          await takeBackCarKeysFromTransporter.mutateAsync({
            data: assignmentIds,
            params: {
              assignmentRequestId: assignmentRequest?.requestID,
              eventMessage: reason,
            },
          });

          modalStack.pop();
          onActionComplete(false);
        }}
        onCancel={() => modalStack.pop()}
      />
    );
  };

  return (
    <Actions>
      {users[requestedForID]?.isTruckTransport &&
        allocatedAssignments.filter(
          (a) =>
            a.assignment.assignmentStatusID === AssignmentStatusEnum.Created ||
            a.assignment.assignmentStatusID === AssignmentStatusEnum.Assigned
        ).length > 0 && (
          <AuthorizedTextButton onClick={onHandOutCarKeys}>
            <FontAwesomeIcon icon={faKey} /> Dela ut nycklar
          </AuthorizedTextButton>
        )}
      {request?.assignmentRequest.assignmentRequestStatusID ===
        AssignmentRequestStatusEnum.Created && (
        <AuthorizedTextButton onClick={onDeny} roles={[Roles.AnnonsAdmin]}>
          <FontAwesomeIcon icon={faCancel} /> Avslå
        </AuthorizedTextButton>
      )}
      {!!allocatedAssignments.filter(
        (a) =>
          a.assignment.assignmentStatusID === AssignmentStatusEnum.Complete ||
          a.assignment.assignmentStatusID === AssignmentStatusEnum.Started ||
          a.assignment.assignmentStatusID === AssignmentStatusEnum.Approved
      ).length && (
        <AuthorizedTextButton onClick={onTakeBackCarKeys}>
          <FontAwesomeIcon icon={faVault} /> Ta tillbaka nycklar
        </AuthorizedTextButton>
      )}
      {request?.assignmentRequest.assignmentRequestStatusID !==
        AssignmentRequestStatusEnum.Removed &&
        me?.userID === request?.assignmentRequest.createdByID && (
          <AuthorizedTextButton onClick={onRemove}>
            <FontAwesomeIcon icon={faTrashAlt} /> Ta bort
          </AuthorizedTextButton>
        )}
    </Actions>
  );
};

export default AssignmentRequestActions;
