import React, { ReactNode, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div<{
  small?: boolean;
}>`
  display: inline-block;
  position: relative;
`;

const DropdownContent = styled.div`
  z-index: 100;
  position: fixed;
  overflow: hidden;
  display: flex;
  flex-direction: column;

  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid #767676;
  box-shadow: ${({ theme }) => theme.colors.shadow.dropdown};
`;

export type DropdownPosition = 'fullwidth' | 'left' | 'right';

interface Props {
  content?: ReactNode;
  onLostFocus?(): void;
  /** Gets called when wrapper gets clicked */
  onClick?(): void;
  /** defaults to 'fullwidth', which is equal to Dropdown's Wrapper width that you can style by passing down className. */
  position?: DropdownPosition;
  className?: string;
  contentClassName?: string;
  small?: boolean;
  children: ReactNode;
}

const Dropdown = ({
  children,
  content,
  onLostFocus,
  onClick,
  position = 'fullwidth',
  className,
  contentClassName,
  small,
}: Props) => {
  const wrapperRef = useRef<HTMLDivElement>();
  const onLostFocusRef = useRef(onLostFocus);
  onLostFocusRef.current = onLostFocus;

  const handleLostFocus = useCallback(() => {
    if (
      onLostFocusRef.current &&
      !wrapperRef.current?.matches(':focus-within')
    ) {
      onLostFocusRef.current();
    }
  }, []);

  useEffect(() => {
    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('focusout', handleLostFocus);
      }
    };
  }, [handleLostFocus]);

  const setRef = (element: HTMLDivElement | null) => {
    if (!element) return;

    wrapperRef.current = element;
    element.addEventListener('focusout', handleLostFocus);
  };

  const getDropdownPositionStyle = (): React.CSSProperties | undefined => {
    if (wrapperRef.current) {
      const wrapperBoundingRect = wrapperRef.current.getBoundingClientRect();
      const dropUpwards =
        wrapperBoundingRect.y + wrapperBoundingRect.height / 2 >
        window.innerHeight * 0.8;

      if (!dropUpwards) {
        const top = wrapperBoundingRect.top + wrapperBoundingRect.height;
        const maxHeight = window.innerHeight - top - 5;
        switch (position) {
          case 'left':
            return {
              top,
              right: window.innerWidth - wrapperBoundingRect.right,
              maxHeight,
            };

          case 'right':
            return {
              top,
              left: wrapperBoundingRect.left + wrapperBoundingRect.width,
              maxHeight,
            };

          case 'fullwidth':
          default:
            return {
              top,
              left: wrapperBoundingRect.left,
              minWidth: wrapperBoundingRect.width,
              maxHeight,
            };
        }
      } else {
        const bottom =
          window.innerHeight -
          wrapperBoundingRect.bottom +
          wrapperBoundingRect.height;
        switch (position) {
          case 'left':
            return {
              flexDirection: 'column-reverse',
              bottom,
              right: window.innerWidth - wrapperBoundingRect.right,
            };

          case 'right':
            return {
              flexDirection: 'column-reverse',
              bottom,
              left: wrapperBoundingRect.left + wrapperBoundingRect.width,
            };

          case 'fullwidth':
          default:
            return {
              flexDirection: 'column-reverse',
              bottom,
              left: wrapperBoundingRect.left,
              minWidth: wrapperBoundingRect.width,
            };
        }
      }
    }

    return undefined;
  };

  return (
    <Wrapper className={className} onClick={onClick} ref={setRef} small={small}>
      {children}
      {content && (
        <DropdownContent
          className={contentClassName}
          onClick={(eve) => eve.stopPropagation()}
          style={getDropdownPositionStyle()}
        >
          {content}
        </DropdownContent>
      )}
    </Wrapper>
  );
};

export default Dropdown;
