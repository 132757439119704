import Table from 'components/Table';
import {
  focusableTableCellClassName,
  tableCellClassName,
} from 'components/Table/utils';
import useTranslations from 'contexts/basicData/useTranslations';
import React, { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import useCompanies from 'contexts/basicData/useCompanies';
import useAssignmentListColumnSettings, {
  ColumnProps as AssignmentListColumnProps,
} from './useAssignmentRequestsListColumnSettings';
import { AssignmentRequestModel } from 'api/model';
import { useAssignmentRequestsFilters } from 'pages/InternalDeliveryGroup/Requests/components/useAssignmentRequestFilters';
// import useQueuedAssignmentUpdates from 'components/AssignmentsList/useQueuedAssignmentUpdates'; // TODO: relevant?

export const DotSpan = styled.span<{ dotColor?: string }>`
  height: 12px;
  width: 12px;
  background-color: ${({ dotColor }) =>
    dotColor ? `#${dotColor}` : 'transparent'};
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
`;

const Center = styled.div`
  text-align: center;
`;

const newDataFromRideClassName = 'new-data-from-ride';
const assigneeIsUnavailableOnDate = 'assignee-is-unavailable-on-date';
const adminHasUnreadCommentsClassName = 'admin-has-unread-comments';
const statusClassNames = {
  Created: 'assignment-created',
  Assigned: 'assignment-assigned',
  Active: 'assignment-active',
  Complete: 'assignment-completed',
  Removed: 'assignment-removed',
  Approved: 'assignment-approved',
  CasePending: 'case-pending',
};

const assignmentIdRowClassName = (assignmentId: number | string): string =>
  `assignment-row-${assignmentId}`;

const TableStylerWrapper = styled.div<{ savingAssignmentIds: string[] }>`
  display: contents;

  ${({ savingAssignmentIds }) =>
    savingAssignmentIds.map(
      (id) => css`
        .${assignmentIdRowClassName(id)} {
          animation: assignmentlist-save-animation 1s infinite;
        }
      `
    )}

  @keyframes assignmentlist-save-animation {
    0% {
      background: #ddeefd;
    }
    50% {
      background: ${({ theme }) => theme.colors.background.primary};
    }
    100% {
      background: #ddeefd;
    }
  }
`;

const MyTable = styled(Table)`
  flex: 1;

  /* when a cell is focused, allow overflow for UserPicker */
  ${`.${tableCellClassName}`}:focus-within {
    overflow: visible;
  }

  ${`.${focusableTableCellClassName}`}:focus-within {
    padding: 0;
  }

  .${statusClassNames.Created},
    .${statusClassNames.Assigned},
    .${statusClassNames.Complete},
    .${statusClassNames.CasePending} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  }

  .${statusClassNames.Created} {
    background-color: rgb(249 237 204);
    font-weight: 500;
  }
  .${statusClassNames.Assigned} {
    background: rgb(255 252 224);
  }
  .${statusClassNames.Active} {
  }
  .${statusClassNames.Complete} {
    background-color: rgb(228 243 222);
    font-weight: 500;
  }
  .${statusClassNames.Approved} {
    //background-color: #d7ffc1;
  }
  .${statusClassNames.Removed} .${tableCellClassName} {
    color: #c01111;
    text-decoration: line-through;
  }
  .${statusClassNames.CasePending} {
    background-color: #e7f4ff;
  }

  .${newDataFromRideClassName},
    .${adminHasUnreadCommentsClassName},
    .${assigneeIsUnavailableOnDate} {
    font-weight: bold;
  }
` as typeof Table;

interface Props {
  onAssignmentItemClick?(model: AssignmentRequestModel): void;
  onRequestUpdated(): Promise<any>;
  requests: AssignmentRequestModel[];
  loading?: boolean;
  // showStatusColors: boolean;
  currentPage?: number;
  onGoToPage?(page: number): void;
  totalPageCount?: number;
  pageSize?: number;
}

const AssignmentRequestsList: React.FC<Props> = ({
  onAssignmentItemClick,
  onRequestUpdated,
  requests,
  loading,
  currentPage,
  onGoToPage,
  totalPageCount,
  pageSize,
}) => {
  const translations = useTranslations();
  const { companies } = useCompanies();
  const {
    state: { sortAscending, sortedColumnIndex },
    setSortState,
    setStartDate,
  } = useAssignmentRequestsFilters();
  // const { enqueueAssignmentUpdate, queues } = useQueuedAssignmentUpdates(
  //   assignments,
  //   onAssignmentUpdated
  // );

  useEffect(() => {
    setStartDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columnSettings = useAssignmentListColumnSettings();
  const renderProps = useMemo(
    (): AssignmentListColumnProps => ({
      translations,
      onUpdateAssignment: () => {
        onRequestUpdated();
      }, // enqueueAssignmentUpdate,
      companies: companies.reduce(
        (acc, curr) => {
          let companyAddress = `${curr.address}${curr.zip}${curr.city}`;
          companyAddress = companyAddress.toLowerCase().replaceAll(' ', '');
          acc[companyAddress] = curr;
          return acc;
        },
        {} as AssignmentListColumnProps['companies']
      ),
    }),
    [companies, onRequestUpdated, translations]
  );

  const initialSortState = useRef({
    sortedColumnIndex,
    ascending: sortAscending,
  });

  // const ongoingSaves = useMemo(() => Object.keys(queues), [queues]);

  return (
    <TableStylerWrapper savingAssignmentIds={[]}>
      {loading ? (
        <Center>Laddar...</Center>
      ) : (
        <MyTable
          columnSettings={columnSettings}
          currentPage={currentPage}
          fluid
          initialSortState={initialSortState.current}
          onGoToPage={onGoToPage}
          onRowClick={onAssignmentItemClick}
          onSortStateChange={setSortState}
          pageSize={pageSize}
          renderProps={renderProps}
          // rowClassName={
          //   showStatusColors ? coloredRowClassNameMaker : rowClassNameMaker
          // }
          rows={requests}
          totalPageCount={totalPageCount}
          useColumnWidthAsFlexAmount={
            // when device width > 768
            window.innerWidth > 768
          }
        />
      )}
      {requests.length === 0 && <Center>Hittade inga uppdrag</Center>}
    </TableStylerWrapper>
  );
};

export default AssignmentRequestsList;
