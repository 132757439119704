import Routes from 'constants/Routes';
import useAuthenticationState from 'contexts/authentication/useAuthentication';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const RequireAuth = ({ children }: { children: React.ReactNode }) => {
  const {
    state: { isAuthenticated },
  } = useAuthenticationState();
  const location = useLocation();

  if (!isAuthenticated)
    return (
      <Navigate replace state={{ from: location }} to={Routes.account.login} />
    );

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default RequireAuth;
