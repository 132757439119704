import { ColumnSetting, tableCellClassName } from 'components/Table/utils';
import { useMemo } from 'react';
import { css } from 'styled-components';
import { CompanyModel, ITranslationLookups } from 'api';
// import useTranslations from 'contexts/basicData/useTranslations';
import { AssignmentEditOperation } from 'components/AssignmentsList/useQueuedAssignmentUpdates'; // TODO: Relevant for this file?
import { formatDate, formatTime } from 'utils/date-helpers';
import useCompanies from 'contexts/basicData/useCompanies';
import { AssignmentRequestModel } from 'api/model';
import useTranslations from 'contexts/basicData/useTranslations';
import useUsers from 'contexts/basicData/useUsers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import {
  makeBooleanComparator,
  makeDateComparator,
  makeStringComparator,
} from 'utils/sorting';

export interface ColumnProps {
  translations: ITranslationLookups;
  onUpdateAssignment(
    assignmentId: number,
    editOperation: AssignmentEditOperation
  ): void;
  companies: { [lookupString: string]: CompanyModel };
}

const useAssignmentRequestsListColumnSettings = (): ColumnSetting<
  AssignmentRequestModel,
  ColumnProps
>[] => {
  const users = useUsers();
  const {
    assignmentRequestStatuses,
    assignmentRequestReasons,
    caseTypes,
    assignmentStatuses,
  } = useTranslations();
  const { companiesById } = useCompanies();
  return useMemo(() => {
    type Column = ColumnSetting<AssignmentRequestModel, ColumnProps>;
    const columns: Column[] = [
      {
        head: 'Nr',
        render: (_avm, _props, _focused, rowIndex) => rowIndex + 1,
        width: 32,
        csvValue: (_avm, rowIndex) => rowIndex + 1,
      },
      {
        head: 'Status',
        csvValue: (model) =>
          assignmentRequestStatuses[model.assignmentRequestStatusID], // TODO: Replace with AdRequestStatus
        render: (model) => {
          return assignmentRequestStatuses[model.assignmentRequestStatusID];
        },
        width: 50,
        sortFunction: makeStringComparator(
          (m) => assignmentRequestStatuses[m.assignmentRequestStatusID] ?? '-'
        ),
      },
      {
        head: 'Anledning',
        csvValue: (model) =>
          assignmentRequestReasons[model.assignmentRequestReasonID] ?? '-',
        render: (model) =>
          assignmentRequestReasons[model.assignmentRequestReasonID] ?? '-',
        width: 100,
        sortFunction: makeStringComparator(
          (model) =>
            assignmentRequestReasons[model.assignmentRequestReasonID] ?? '-'
        ),
      },
      {
        head: 'Uppdragsstatus',
        csvValue: (model) =>
          model.numberOfCars === 1 && model.allocatedAssignments[0]
            ? assignmentStatuses[
                model.allocatedAssignments[0].assignment.assignmentStatusID
              ]
            : '-',
        render: (model) => {
          return model.numberOfCars === 1 && model.allocatedAssignments[0]
            ? assignmentStatuses[
                model.allocatedAssignments[0].assignment.assignmentStatusID
              ]
            : '-';
        },
        width: 80,
        sortFunction: makeStringComparator(
          (model) =>
            assignmentStatuses[
              model.allocatedAssignments[0].assignment.assignmentStatusID
            ] ?? '-'
        ),
      },
      {
        head: 'Fälttestare',
        focusable: false,
        csvValue: (model) => users[model.requestedForID!]?.name ?? '',
        render: (model) => users[model.requestedForID!]?.name ?? '',
        width: 150,
        sortFunction: makeStringComparator(
          (model) => users[model.requestedForID!]?.name ?? ''
        ),
      },
      {
        head: 'Körkortstyp',
        csvValue: (model) =>
          `${!model.requestedForID ? '-' : users[model.requestedForID]?.canDriveManual ? 'Manuell' : 'Automat'}`,
        render: (model) =>
          `${!model.requestedForID ? '-' : users[model.requestedForID]?.canDriveManual ? 'Manuell' : 'Automat'}`,
        width: 100,
        sortFunction: makeBooleanComparator(
          (m) => !!m.requestedFor?.canDriveManual
        ),
      },
      {
        head: 'Från anläggning',
        csvValue: (model) =>
          companiesById.get(model.fromCompanyID)?.name ?? '-',
        render: (model) => companiesById.get(model.fromCompanyID)?.name ?? '-',
        width: 200,
        sortFunction: makeStringComparator(
          (m) => companiesById.get(m.fromCompanyID)?.name ?? '-'
        ),
      },
      {
        // TODO: Complete this column
        csvValue: (model) => companiesById.get(model.toCompanyID)?.name ?? '-',
        head: 'Till anläggning',
        render: (model) => companiesById.get(model.toCompanyID)?.name ?? '-',
        width: 200,
        sortFunction: makeStringComparator(
          (m) => companiesById.get(m.toCompanyID)?.name ?? '-'
        ),
      },
      {
        head: 'Datum',
        csvValue: (model) => formatDate(model.targetStartTime),
        render: (model) => formatDate(model.targetStartTime),
        width: 80,
        sortFunction: (a, b) =>
          (a.targetStartTime ?? 0) > (b.targetStartTime ?? 0) ? 1 : -1,
      },
      {
        head: 'Tid',
        csvValue: (model) => formatTime(model.targetStartTime),
        render: (model) => formatTime(model.targetStartTime),
        width: 50,
        sortFunction: makeDateComparator((m) => m.targetStartTime),
      },
      {
        head: '# bilar',
        csvValue: (model) => model.numberOfCars,
        render: (model) => model.numberOfCars,
        width: 30,
      },
      {
        head: 'Allokering (Lastbil)',
        csvValue: (model) => model.allocatedAssignments.length,
        render: (model) => {
          const isTruckTransport = model.numberOfCars > 1;
          if (!isTruckTransport) {
            return '-';
          }

          const diff = model.numberOfCars - model.allocatedAssignments.length;
          return (
            <>
              <span>{model.allocatedAssignments.length}</span>
              {diff > 0 ? (
                <span
                  title={`${diff} platser kvar att allokera`}
                  style={{
                    marginLeft: '10px',
                    color: 'crimson',
                    fontWeight: 'bold',
                  }}
                >
                  ({diff})
                </span>
              ) : (
                <span
                  title="Fullallokerad transport"
                  style={{
                    marginLeft: '10px',
                  }}
                >
                  <FontAwesomeIcon icon={faCheckCircle} color="green" />
                </span>
              )}
            </>
          );
        },
        width: 80,
      },
      {
        head: 'Regnr',
        // focusable: true,
        csvValue: (model) =>
          model.allocatedAssignments
            .map((m) => m.case.registrationNumber)
            .join(', '),
        render: (model) =>
          model.allocatedAssignments
            .map(
              (a) =>
                `${a.case.registrationNumber} (${caseTypes[a.case.caseTypeID]})`
            )
            .join(', '),
        width: 150,
        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
      },
      {
        // TODO: Check that this column shows the correct value
        head: 'Kommentar',
        focusable: true,
        csvValue: (avm) => avm.extraInfo,
        render: (avm) => avm.extraInfo,
        width: 200,
        sortFunction: makeStringComparator((m) => m.extraInfo),
      },
    ] as Column[];

    return columns.filter((c) => !!c); // remove undefined before returning columns
  }, [
    assignmentRequestReasons,
    assignmentRequestStatuses,
    assignmentStatuses,
    caseTypes,
    companiesById,
    users,
  ]);
};

export default useAssignmentRequestsListColumnSettings;
