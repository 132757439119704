import { TeamGroupModel } from 'api';
import SearchableSelect, {
  SearchableSelectItem,
  SelectOption,
} from 'components/inputs/SearchableSelect';
import useTeamGroups from 'contexts/basicData/useTeamGroups';
import { FC, useMemo } from 'react';
import styled, { css } from 'styled-components';

const StyledSelectItem = styled(SearchableSelectItem)<{ isNoneItem?: boolean }>`
  ${({ isNoneItem }) =>
    isNoneItem &&
    css`
      font-style: italic;
    `}
  cursor: pointer;
`;

function optionRender(
  key: string,
  option: SelectOption<number | null, TeamGroupModel | null>,
  onClick: () => void,
  isSelected: boolean
) {
  return (
    <StyledSelectItem
      isNoneItem={option.value === null}
      key={key}
      onClick={onClick}
      selected={isSelected}
    >
      {option.label}
    </StyledSelectItem>
  );
}

interface Props {
  selectedId: number | null;
  onTeamGroupSelected(teamGroup: TeamGroupModel | null): void;
  disabled?: boolean;
}

const TeamGroupPicker: FC<Props> = ({
  disabled,
  selectedId,
  onTeamGroupSelected,
}) => {
  const { teamGroups } = useTeamGroups();

  const options = useMemo(() => {
    const theOptions: SelectOption<number | null, TeamGroupModel | null>[] =
      teamGroups?.map((teamGroup) => ({
        label: teamGroup.name,
        value: teamGroup.id,
        extra: teamGroup,
      })) ?? [];

    theOptions.unshift({
      label: 'Ingen',
      value: null,
      extra: null,
    });
    return theOptions;
  }, [teamGroups]);

  const handleOptionClicked = (
    _companyId: number | null,
    _selected: boolean,
    option: SelectOption<number | null, TeamGroupModel | null>
  ) => {
    onTeamGroupSelected(option.extra);
  };

  return (
    <SearchableSelect
      // itemsWrapperStyle={itemsWrapperStyle}
      disabled={disabled}
      onOptionClicked={handleOptionClicked}
      optionRender={optionRender}
      options={options}
      searchFilter={(searchString, opts) => {
        const lowerSearchStr = searchString.toLowerCase();
        return opts.filter(
          (opt) =>
            opt.value === null ||
            opt.value === 0 ||
            opt.extra?.name.toLowerCase().includes(lowerSearchStr)
        );
      }}
      selectedValue={selectedId}
    />
  );
};

export default TeamGroupPicker;
