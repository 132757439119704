import { AssignmentRequestEventModel } from 'api/model';
import DefaultEventHeader from 'components/DetailedCase/EventsAndComments/eventComponents/DefaultEventHeader';
import {
  EventSection,
  Message,
} from 'components/DetailedCase/EventsAndComments/eventComponents/components';
import useUsers from 'contexts/basicData/useUsers';
import React, { FC } from 'react';

interface Props {
  event: AssignmentRequestEventModel;
  title: string;
  icon?: React.ReactNode;
}

const AssignmentRequestEvent: FC<Props> = ({ event, title, icon }) => {
  const users = useUsers();

  return (
    <EventSection>
      <DefaultEventHeader
        created={event.created}
        headerText={
          <>
            {icon} {title}
          </>
        }
        modified={event.modified}
        userName={users[event.modifiedByID]?.name ?? '-'}
      />

      <Message>{event.message}</Message>
    </EventSection>
  );
};

export default AssignmentRequestEvent;
