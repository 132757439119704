import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC } from 'react';
import styled from 'styled-components';
import ContextMenu, { ContextMenuProps } from '.';

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  margin-left: 5px;
  width: 23px;
  height: 23px;
  border-radius: 1000px;

  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.background.button};
  font-size: 17px;
  cursor: pointer;
`;

interface Props extends ContextMenuProps {
  className?: string;
}

const EllipsisContextMenu: FC<Props> = ({ options, position, className }) => {
  return (
    <ContextMenu options={options} position={position}>
      <Button className={className}>
        <FontAwesomeIcon icon={faEllipsisV} />
      </Button>
    </ContextMenu>
  );
};

export default EllipsisContextMenu;
