import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ContextMenu from 'components/ContextMenu';
import Button from 'components/inputs/Button';
import { getInitialDateRange } from 'contexts/assignmentSearch/types';
import { isSameDay } from 'date-fns';
import { FC } from 'react';
import styled from 'styled-components';

const TodayButton = styled(Button)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  height: 30px;
  padding: 0 10px;
  margin-left: 5px;
  white-space: nowrap;
`;

interface Props {
  dateRange: [Date | null, Date | null];
  onPresetSelected(dateRange: [Date, Date]): void;
}

interface RangeOption {
  label: string;
  range: [Date, Date];
}

const DateSpanPresetsSelector: FC<Props> = ({
  dateRange,
  onPresetSelected,
}) => {
  const now = new Date();
  const rangeOptions: RangeOption[] = [
    { label: 'Idag', range: getInitialDateRange() },
    {
      label: 'Innevarande månad',
      range: [new Date(now.getFullYear(), now.getMonth(), 1), now],
    },
    {
      label: 'En månad bakåt',
      range: [
        new Date(now.getFullYear(), now.getMonth() - 1, now.getDate()),
        now,
      ],
    },
    {
      label: 'Ett år bakåt',
      range: [
        new Date(now.getFullYear() - 1, now.getMonth(), now.getDate()),
        now,
      ],
    },
  ];

  const currentRange = rangeOptions.find(
    (ro) =>
      dateRange[0] &&
      dateRange[1] &&
      isSameDay(dateRange[0], ro.range[0]) &&
      isSameDay(dateRange[1], ro.range[1])
  );

  return (
    <ContextMenu
      options={rangeOptions.map((ro) => ({
        label: ro.label,
        onClick: () => onPresetSelected(ro.range),
      }))}
      position="left"
    >
      <TodayButton>
        {currentRange ? currentRange.label : 'Frival'}{' '}
        <FontAwesomeIcon icon={faAngleDown} />
      </TodayButton>
    </ContextMenu>
  );
};

export default DateSpanPresetsSelector;
