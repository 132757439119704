// 15 minute intervals from 00:15 to 24:00
const estimatedDurationOptions = Array(24 * 4 + 1) // One extra element for 0 (00:00)
  .fill(0)
  .map((_, i) => i * 15 * 60 * 1000); // Start from 0

// return the nearest value in the estimatedDurationOptions array
const getNearestEstimatedDuration = (duration: number) => {
  const nearest = estimatedDurationOptions.reduce((prev, curr) =>
    Math.abs(curr - duration) < Math.abs(prev - duration) ? curr : prev
  );
  return nearest;
};

export default {
  estimatedDurationOptions,
  getNearestEstimatedDuration,
};
