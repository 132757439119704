import styled from 'styled-components';

const DashboardWidgetsWrapper = styled.div`
  padding: 10px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-flow: row dense;
  gap: 5px;
`;

export default DashboardWidgetsWrapper;
