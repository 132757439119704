/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  CompanyDeleteCompanyParams,
  CompanyDeletePointForCompanyParams,
  CompanyGetCompanyParams,
  CompanyGetPointByAddressParams,
  CompanyModel,
  CompanyModelBody,
  CompanyUpdatePointForCompanyParams,
  Point,
} from '.././model';
import useCompanyGetCompanyMutator from '.././mutator/custom-instance';
import useCompanyGetCompaniesMutator from '.././mutator/custom-instance';
import useCompanyCreateCompanyMutator from '.././mutator/custom-instance';
import useCompanyUpdateCompanyMutator from '.././mutator/custom-instance';
import useCompanyDeleteCompanyMutator from '.././mutator/custom-instance';
import useCompanyGetPointByAddressMutator from '.././mutator/custom-instance';
import useCompanyUpdatePointForCompanyMutator from '.././mutator/custom-instance';
import useCompanyDeletePointForCompanyMutator from '.././mutator/custom-instance';

export const useCompanyGetCompanyHook = () => {
  const companyGetCompany = useCompanyGetCompanyMutator<CompanyModel>();

  return (params?: CompanyGetCompanyParams, signal?: AbortSignal) => {
    return companyGetCompany({
      url: `/api/Company/GetCompany`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getCompanyGetCompanyQueryKey = (
  params?: CompanyGetCompanyParams
) => {
  return [`/api/Company/GetCompany`, ...(params ? [params] : [])] as const;
};

export const useCompanyGetCompanyQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useCompanyGetCompanyHook>>>,
  TError = unknown,
>(
  params?: CompanyGetCompanyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useCompanyGetCompanyHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getCompanyGetCompanyQueryKey(params);

  const companyGetCompany = useCompanyGetCompanyHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetCompanyHook>>>
  > = ({ signal }) => companyGetCompany(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetCompanyHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompanyGetCompanyQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyGetCompanyHook>>>
>;
export type CompanyGetCompanyQueryError = unknown;

export const useCompanyGetCompany = <
  TData = Awaited<ReturnType<ReturnType<typeof useCompanyGetCompanyHook>>>,
  TError = unknown,
>(
  params?: CompanyGetCompanyParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useCompanyGetCompanyHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useCompanyGetCompanyQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useCompanyGetCompaniesHook = () => {
  const companyGetCompanies = useCompanyGetCompaniesMutator<CompanyModel[]>();

  return () => {
    return companyGetCompanies({
      url: `/api/Company/GetCompanies`,
      method: 'POST',
    });
  };
};

export const useCompanyGetCompaniesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetCompaniesHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompanyGetCompaniesHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyGetCompanies = useCompanyGetCompaniesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetCompaniesHook>>>,
    void
  > = () => {
    return companyGetCompanies();
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyGetCompaniesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyGetCompaniesHook>>>
>;

export type CompanyGetCompaniesMutationError = unknown;

export const useCompanyGetCompanies = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetCompaniesHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCompanyGetCompaniesHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useCompanyGetCompaniesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCompanyCreateCompanyHook = () => {
  const companyCreateCompany = useCompanyCreateCompanyMutator<number>();

  return (companyModelBody: CompanyModelBody) => {
    return companyCreateCompany({
      url: `/api/Company/CreateCompany`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: companyModelBody,
    });
  };
};

export const useCompanyCreateCompanyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyCreateCompanyHook>>>,
    TError,
    { data: CompanyModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompanyCreateCompanyHook>>>,
  TError,
  { data: CompanyModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyCreateCompany = useCompanyCreateCompanyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyCreateCompanyHook>>>,
    { data: CompanyModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return companyCreateCompany(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyCreateCompanyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyCreateCompanyHook>>>
>;
export type CompanyCreateCompanyMutationBody = CompanyModelBody;
export type CompanyCreateCompanyMutationError = unknown;

export const useCompanyCreateCompany = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyCreateCompanyHook>>>,
    TError,
    { data: CompanyModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCompanyCreateCompanyHook>>>,
  TError,
  { data: CompanyModelBody },
  TContext
> => {
  const mutationOptions = useCompanyCreateCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCompanyUpdateCompanyHook = () => {
  const companyUpdateCompany = useCompanyUpdateCompanyMutator<void>();

  return (companyModelBody: CompanyModelBody) => {
    return companyUpdateCompany({
      url: `/api/Company/UpdateCompany`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: companyModelBody,
    });
  };
};

export const useCompanyUpdateCompanyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyUpdateCompanyHook>>>,
    TError,
    { data: CompanyModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompanyUpdateCompanyHook>>>,
  TError,
  { data: CompanyModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyUpdateCompany = useCompanyUpdateCompanyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyUpdateCompanyHook>>>,
    { data: CompanyModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return companyUpdateCompany(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyUpdateCompanyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyUpdateCompanyHook>>>
>;
export type CompanyUpdateCompanyMutationBody = CompanyModelBody;
export type CompanyUpdateCompanyMutationError = unknown;

export const useCompanyUpdateCompany = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyUpdateCompanyHook>>>,
    TError,
    { data: CompanyModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCompanyUpdateCompanyHook>>>,
  TError,
  { data: CompanyModelBody },
  TContext
> => {
  const mutationOptions = useCompanyUpdateCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCompanyDeleteCompanyHook = () => {
  const companyDeleteCompany = useCompanyDeleteCompanyMutator<boolean>();

  return (params?: CompanyDeleteCompanyParams) => {
    return companyDeleteCompany({
      url: `/api/Company/DeleteCompany`,
      method: 'DELETE',
      params,
    });
  };
};

export const useCompanyDeleteCompanyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyDeleteCompanyHook>>>,
    TError,
    { params?: CompanyDeleteCompanyParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompanyDeleteCompanyHook>>>,
  TError,
  { params?: CompanyDeleteCompanyParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyDeleteCompany = useCompanyDeleteCompanyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyDeleteCompanyHook>>>,
    { params?: CompanyDeleteCompanyParams }
  > = (props) => {
    const { params } = props ?? {};

    return companyDeleteCompany(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyDeleteCompanyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyDeleteCompanyHook>>>
>;

export type CompanyDeleteCompanyMutationError = unknown;

export const useCompanyDeleteCompany = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyDeleteCompanyHook>>>,
    TError,
    { params?: CompanyDeleteCompanyParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCompanyDeleteCompanyHook>>>,
  TError,
  { params?: CompanyDeleteCompanyParams },
  TContext
> => {
  const mutationOptions = useCompanyDeleteCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCompanyGetPointByAddressHook = () => {
  const companyGetPointByAddress = useCompanyGetPointByAddressMutator<Point>();

  return (params?: CompanyGetPointByAddressParams) => {
    return companyGetPointByAddress({
      url: `/api/Company/GetPointByAddress`,
      method: 'POST',
      params,
    });
  };
};

export const useCompanyGetPointByAddressMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetPointByAddressHook>>>,
    TError,
    { params?: CompanyGetPointByAddressParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompanyGetPointByAddressHook>>>,
  TError,
  { params?: CompanyGetPointByAddressParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyGetPointByAddress = useCompanyGetPointByAddressHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetPointByAddressHook>>>,
    { params?: CompanyGetPointByAddressParams }
  > = (props) => {
    const { params } = props ?? {};

    return companyGetPointByAddress(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyGetPointByAddressMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyGetPointByAddressHook>>>
>;

export type CompanyGetPointByAddressMutationError = unknown;

export const useCompanyGetPointByAddress = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyGetPointByAddressHook>>>,
    TError,
    { params?: CompanyGetPointByAddressParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCompanyGetPointByAddressHook>>>,
  TError,
  { params?: CompanyGetPointByAddressParams },
  TContext
> => {
  const mutationOptions = useCompanyGetPointByAddressMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCompanyUpdatePointForCompanyHook = () => {
  const companyUpdatePointForCompany =
    useCompanyUpdatePointForCompanyMutator<void>();

  return (point: Point, params?: CompanyUpdatePointForCompanyParams) => {
    return companyUpdatePointForCompany({
      url: `/api/Company/UpdatePointForCompany`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: point,
      params,
    });
  };
};

export const useCompanyUpdatePointForCompanyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyUpdatePointForCompanyHook>>>,
    TError,
    { data: Point; params?: CompanyUpdatePointForCompanyParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompanyUpdatePointForCompanyHook>>>,
  TError,
  { data: Point; params?: CompanyUpdatePointForCompanyParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyUpdatePointForCompany = useCompanyUpdatePointForCompanyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyUpdatePointForCompanyHook>>>,
    { data: Point; params?: CompanyUpdatePointForCompanyParams }
  > = (props) => {
    const { data, params } = props ?? {};

    return companyUpdatePointForCompany(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyUpdatePointForCompanyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyUpdatePointForCompanyHook>>>
>;
export type CompanyUpdatePointForCompanyMutationBody = Point;
export type CompanyUpdatePointForCompanyMutationError = unknown;

export const useCompanyUpdatePointForCompany = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyUpdatePointForCompanyHook>>>,
    TError,
    { data: Point; params?: CompanyUpdatePointForCompanyParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCompanyUpdatePointForCompanyHook>>>,
  TError,
  { data: Point; params?: CompanyUpdatePointForCompanyParams },
  TContext
> => {
  const mutationOptions =
    useCompanyUpdatePointForCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCompanyDeletePointForCompanyHook = () => {
  const companyDeletePointForCompany =
    useCompanyDeletePointForCompanyMutator<void>();

  return (params?: CompanyDeletePointForCompanyParams) => {
    return companyDeletePointForCompany({
      url: `/api/Company/DeletePointForCompany`,
      method: 'DELETE',
      params,
    });
  };
};

export const useCompanyDeletePointForCompanyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyDeletePointForCompanyHook>>>,
    TError,
    { params?: CompanyDeletePointForCompanyParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useCompanyDeletePointForCompanyHook>>>,
  TError,
  { params?: CompanyDeletePointForCompanyParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyDeletePointForCompany = useCompanyDeletePointForCompanyHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useCompanyDeletePointForCompanyHook>>>,
    { params?: CompanyDeletePointForCompanyParams }
  > = (props) => {
    const { params } = props ?? {};

    return companyDeletePointForCompany(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyDeletePointForCompanyMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useCompanyDeletePointForCompanyHook>>>
>;

export type CompanyDeletePointForCompanyMutationError = unknown;

export const useCompanyDeletePointForCompany = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useCompanyDeletePointForCompanyHook>>>,
    TError,
    { params?: CompanyDeletePointForCompanyParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useCompanyDeletePointForCompanyHook>>>,
  TError,
  { params?: CompanyDeletePointForCompanyParams },
  TContext
> => {
  const mutationOptions =
    useCompanyDeletePointForCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
