import {
  Dispatch,
  Reducer,
  ReducerAction,
  ReducerState,
  useCallback,
} from 'react';
import { RecoilState, useRecoilState } from 'recoil';

const useRecoilReducer = <R extends Reducer<any, any>>(
  recoilState: RecoilState<ReducerState<R>>,
  reducer: R
): [ReducerState<R>, Dispatch<ReducerAction<R>>] => {
  const [state, setState] = useRecoilState(recoilState);

  const dispatch: Dispatch<ReducerAction<R>> = useCallback(
    (action) => {
      setState((theState) => reducer(theState, action));
    },
    [reducer, setState]
  );

  return [state, dispatch];
};

export default useRecoilReducer;
