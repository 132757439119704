import styled, { css } from 'styled-components';
import { AuthorizedButton, MultiSelect } from '..';
import { forwardRef } from 'react';
import { formatDate } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import useTranslations from 'contexts/basicData/useTranslations';

export const BarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 10px 10px;
  gap: 5px;
`;

export const BarSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  gap: 5px;
`;

export const StyledAuthorizedButton = styled(AuthorizedButton)`
  height: 30px;
  padding: 0 10px;
  margin-right: 10px;
  font: inherit;
  white-space: nowrap;

  & > *:first-child {
    margin-right: 6px;
    font-size: 12px;
  }
`;

export const DatePickerButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 30px;

  color: ${({ theme }) => theme.colors.foreground.tint};
  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  font: inherit;

  div {
    white-space: nowrap;
  }

  span {
    display: block;
    margin-left: auto;
    padding-left: 10px;
    font-size: 0.5em;
    color: ${({ theme }) => theme.colors.foreground.primary};
  }
`;

export type DateFormat = 'date' | 'week' | 'month' | 'year';

export const CustomInput = forwardRef<
  HTMLButtonElement,
  { date: Date; dateFormat?: DateFormat }
>(({ date, dateFormat, ...props }, ref) => {
  const translate = useTranslations();
  return (
    <DatePickerButton ref={ref} type="button" {...props}>
      <>
        <div>
          {dateFormat === 'year'
            ? date.getFullYear()
            : dateFormat === 'month'
              ? `${date.getFullYear()} ${
                  translate.monthNames[moment(date).month() + 1]
                }`
              : dateFormat === 'week'
                ? `${date.getFullYear()} v.${moment(date).isoWeeks()}`
                : formatDate(date)}
        </div>
        <span>
          <FontAwesomeIcon icon={faChevronDown} />
        </span>
      </>
    </DatePickerButton>
  );
});

export const StyledMultiSelect = styled(MultiSelect)`
  padding: 8px;
  ${({ value, theme }) =>
    value.size > 0 &&
    css`
      background: ${theme.colors.background.highlight};
    `}
` as typeof MultiSelect;

export const FilterPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 40px;
  gap: 30px;
`;

export const FilterButton = styled.button<{ selected?: boolean }>`
  padding-bottom: 1px;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.foreground.primary};
  font: inherit;
  text-transform: capitalize;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected &&
    css`
      padding-bottom: 1px;
      border-bottom: 2px solid ${theme.colors.foreground.tint};
      font-weight: bold;
    `}

  &:hover {
    color: ${({ theme, selected }) =>
      selected
        ? theme.colors.foreground.primary
        : theme.colors.foreground.tint};
  }
`;
