import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseExtendedModel } from 'api';
import SectionHeader from 'components/SectionHeader';
import useTranslations from 'contexts/basicData/useTranslations';
import React, { FC } from 'react';
import styled from 'styled-components';
import { useDetailedCaseFormControl } from '../DetailedCaseFormProvider';
import LinkedCase from './LinkedCase';

const Wrapper = styled.div``;

const Scroll = styled.div`
  overflow: auto;
`;

const HeaderIcon = styled(FontAwesomeIcon)`
  font-size: 0.7em;
`;

interface Props {
  extendedCase?: CaseExtendedModel;
}

const LinkedCases: FC<Props> = ({ extendedCase }) => {
  const { editMode } = useDetailedCaseFormControl();
  const translations = useTranslations();

  if (
    editMode ||
    !extendedCase?.linkedCases ||
    extendedCase.linkedCases.length === 0
  ) {
    return null;
  }

  return (
    <Wrapper>
      <SectionHeader>
        <HeaderIcon icon={faLink} /> Kopplade ärenden
      </SectionHeader>
      <Scroll>
        {extendedCase?.linkedCases
          .filter((lc) => lc.caseID !== extendedCase.case.caseID)
          .map((lc) => (
            <LinkedCase
              key={lc.caseID}
              linkedCase={lc}
              translations={translations}
            />
          ))}
      </Scroll>
    </Wrapper>
  );
};

export default LinkedCases;
