/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import type {
  CaseModel,
  ExportDeleteInvoiceDataParams,
  ExportDownloadInvoiceDataFileParams,
  ExportExportAvailabilityDataParams,
  ExportExportHogiaSalaryParams,
  ExportExportInvoiceDataParams,
  ExportExportInvoiceStatisticsParams,
  ExportExportRestingTimesParams,
  ExportExportSalaryParams,
  ExportExportUserRatingDataParams,
  ExportGetClosedNotYetInvoicedCasesParams,
  ExportGetInvoiceDataParams,
  InvoiceDataModel,
} from '.././model';
import useExportExportInvoiceStatisticsMutator from '.././mutator/custom-instance';
import useExportExportInvoiceDataMutator from '.././mutator/custom-instance';
import useExportGetClosedNotYetInvoicedCasesMutator from '.././mutator/custom-instance';
import useExportDownloadInvoiceDataFileMutator from '.././mutator/custom-instance';
import useExportGetInvoiceDataMutator from '.././mutator/custom-instance';
import useExportDeleteInvoiceDataMutator from '.././mutator/custom-instance';
import useExportExportPendingMutator from '.././mutator/custom-instance';
import useExportExportSalaryMutator from '.././mutator/custom-instance';
import useExportExportHogiaSalaryMutator from '.././mutator/custom-instance';
import useExportExportRestingTimesMutator from '.././mutator/custom-instance';
import useExportExportAvailabilityDataMutator from '.././mutator/custom-instance';
import useExportExportUserRatingDataMutator from '.././mutator/custom-instance';

export const useExportExportInvoiceStatisticsHook = () => {
  const exportExportInvoiceStatistics =
    useExportExportInvoiceStatisticsMutator<Blob>();

  return (params?: ExportExportInvoiceStatisticsParams) => {
    return exportExportInvoiceStatistics({
      url: `/api/Export/ExportInvoiceStatistics`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportExportInvoiceStatisticsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useExportExportInvoiceStatisticsHook>>
    >,
    TError,
    { params?: ExportExportInvoiceStatisticsParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceStatisticsHook>>>,
  TError,
  { params?: ExportExportInvoiceStatisticsParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportInvoiceStatistics = useExportExportInvoiceStatisticsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useExportExportInvoiceStatisticsHook>>
    >,
    { params?: ExportExportInvoiceStatisticsParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportExportInvoiceStatistics(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportInvoiceStatisticsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceStatisticsHook>>>
>;

export type ExportExportInvoiceStatisticsMutationError = unknown;

export const useExportExportInvoiceStatistics = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useExportExportInvoiceStatisticsHook>>
    >,
    TError,
    { params?: ExportExportInvoiceStatisticsParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceStatisticsHook>>>,
  TError,
  { params?: ExportExportInvoiceStatisticsParams },
  TContext
> => {
  const mutationOptions =
    useExportExportInvoiceStatisticsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportExportInvoiceDataHook = () => {
  const exportExportInvoiceData = useExportExportInvoiceDataMutator<Blob>();

  return (params?: ExportExportInvoiceDataParams) => {
    return exportExportInvoiceData({
      url: `/api/Export/ExportInvoiceData`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportExportInvoiceDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceDataHook>>>,
    TError,
    { params?: ExportExportInvoiceDataParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceDataHook>>>,
  TError,
  { params?: ExportExportInvoiceDataParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportInvoiceData = useExportExportInvoiceDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceDataHook>>>,
    { params?: ExportExportInvoiceDataParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportExportInvoiceData(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportInvoiceDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceDataHook>>>
>;

export type ExportExportInvoiceDataMutationError = unknown;

export const useExportExportInvoiceData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceDataHook>>>,
    TError,
    { params?: ExportExportInvoiceDataParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportInvoiceDataHook>>>,
  TError,
  { params?: ExportExportInvoiceDataParams },
  TContext
> => {
  const mutationOptions = useExportExportInvoiceDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportGetClosedNotYetInvoicedCasesHook = () => {
  const exportGetClosedNotYetInvoicedCases =
    useExportGetClosedNotYetInvoicedCasesMutator<CaseModel[]>();

  return (params?: ExportGetClosedNotYetInvoicedCasesParams) => {
    return exportGetClosedNotYetInvoicedCases({
      url: `/api/Export/GetClosedNotYetInvoicedCases`,
      method: 'POST',
      params,
    });
  };
};

export const useExportGetClosedNotYetInvoicedCasesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useExportGetClosedNotYetInvoicedCasesHook>>
    >,
    TError,
    { params?: ExportGetClosedNotYetInvoicedCasesParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useExportGetClosedNotYetInvoicedCasesHook>>
  >,
  TError,
  { params?: ExportGetClosedNotYetInvoicedCasesParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportGetClosedNotYetInvoicedCases =
    useExportGetClosedNotYetInvoicedCasesHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useExportGetClosedNotYetInvoicedCasesHook>>
    >,
    { params?: ExportGetClosedNotYetInvoicedCasesParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportGetClosedNotYetInvoicedCases(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportGetClosedNotYetInvoicedCasesMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useExportGetClosedNotYetInvoicedCasesHook>>
  >
>;

export type ExportGetClosedNotYetInvoicedCasesMutationError = unknown;

export const useExportGetClosedNotYetInvoicedCases = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useExportGetClosedNotYetInvoicedCasesHook>>
    >,
    TError,
    { params?: ExportGetClosedNotYetInvoicedCasesParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useExportGetClosedNotYetInvoicedCasesHook>>
  >,
  TError,
  { params?: ExportGetClosedNotYetInvoicedCasesParams },
  TContext
> => {
  const mutationOptions =
    useExportGetClosedNotYetInvoicedCasesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportDownloadInvoiceDataFileHook = () => {
  const exportDownloadInvoiceDataFile =
    useExportDownloadInvoiceDataFileMutator<Blob>();

  return (params?: ExportDownloadInvoiceDataFileParams) => {
    return exportDownloadInvoiceDataFile({
      url: `/api/Export/DownloadInvoiceDataFile`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportDownloadInvoiceDataFileMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useExportDownloadInvoiceDataFileHook>>
    >,
    TError,
    { params?: ExportDownloadInvoiceDataFileParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportDownloadInvoiceDataFileHook>>>,
  TError,
  { params?: ExportDownloadInvoiceDataFileParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportDownloadInvoiceDataFile = useExportDownloadInvoiceDataFileHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useExportDownloadInvoiceDataFileHook>>
    >,
    { params?: ExportDownloadInvoiceDataFileParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportDownloadInvoiceDataFile(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportDownloadInvoiceDataFileMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportDownloadInvoiceDataFileHook>>>
>;

export type ExportDownloadInvoiceDataFileMutationError = unknown;

export const useExportDownloadInvoiceDataFile = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useExportDownloadInvoiceDataFileHook>>
    >,
    TError,
    { params?: ExportDownloadInvoiceDataFileParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportDownloadInvoiceDataFileHook>>>,
  TError,
  { params?: ExportDownloadInvoiceDataFileParams },
  TContext
> => {
  const mutationOptions =
    useExportDownloadInvoiceDataFileMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportGetInvoiceDataHook = () => {
  const exportGetInvoiceData =
    useExportGetInvoiceDataMutator<InvoiceDataModel[]>();

  return (params?: ExportGetInvoiceDataParams) => {
    return exportGetInvoiceData({
      url: `/api/Export/GetInvoiceData`,
      method: 'POST',
      params,
    });
  };
};

export const useExportGetInvoiceDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportGetInvoiceDataHook>>>,
    TError,
    { params?: ExportGetInvoiceDataParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportGetInvoiceDataHook>>>,
  TError,
  { params?: ExportGetInvoiceDataParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportGetInvoiceData = useExportGetInvoiceDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportGetInvoiceDataHook>>>,
    { params?: ExportGetInvoiceDataParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportGetInvoiceData(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportGetInvoiceDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportGetInvoiceDataHook>>>
>;

export type ExportGetInvoiceDataMutationError = unknown;

export const useExportGetInvoiceData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportGetInvoiceDataHook>>>,
    TError,
    { params?: ExportGetInvoiceDataParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportGetInvoiceDataHook>>>,
  TError,
  { params?: ExportGetInvoiceDataParams },
  TContext
> => {
  const mutationOptions = useExportGetInvoiceDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportDeleteInvoiceDataHook = () => {
  const exportDeleteInvoiceData = useExportDeleteInvoiceDataMutator<void>();

  return (params?: ExportDeleteInvoiceDataParams) => {
    return exportDeleteInvoiceData({
      url: `/api/Export/DeleteInvoiceData`,
      method: 'POST',
      params,
    });
  };
};

export const useExportDeleteInvoiceDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportDeleteInvoiceDataHook>>>,
    TError,
    { params?: ExportDeleteInvoiceDataParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportDeleteInvoiceDataHook>>>,
  TError,
  { params?: ExportDeleteInvoiceDataParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportDeleteInvoiceData = useExportDeleteInvoiceDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportDeleteInvoiceDataHook>>>,
    { params?: ExportDeleteInvoiceDataParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportDeleteInvoiceData(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportDeleteInvoiceDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportDeleteInvoiceDataHook>>>
>;

export type ExportDeleteInvoiceDataMutationError = unknown;

export const useExportDeleteInvoiceData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportDeleteInvoiceDataHook>>>,
    TError,
    { params?: ExportDeleteInvoiceDataParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportDeleteInvoiceDataHook>>>,
  TError,
  { params?: ExportDeleteInvoiceDataParams },
  TContext
> => {
  const mutationOptions = useExportDeleteInvoiceDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportExportPendingHook = () => {
  const exportExportPending = useExportExportPendingMutator<Blob>();

  return () => {
    return exportExportPending({
      url: `/api/Export/ExportPending`,
      method: 'POST',
      responseType: 'blob',
    });
  };
};

export const useExportExportPendingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportPendingHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportPendingHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportPending = useExportExportPendingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportExportPendingHook>>>,
    void
  > = () => {
    return exportExportPending();
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportPendingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportPendingHook>>>
>;

export type ExportExportPendingMutationError = unknown;

export const useExportExportPending = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportPendingHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportPendingHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useExportExportPendingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportExportSalaryHook = () => {
  const exportExportSalary = useExportExportSalaryMutator<Blob>();

  return (params?: ExportExportSalaryParams) => {
    return exportExportSalary({
      url: `/api/Export/ExportSalary`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportExportSalaryMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportSalaryHook>>>,
    TError,
    { params?: ExportExportSalaryParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportSalaryHook>>>,
  TError,
  { params?: ExportExportSalaryParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportSalary = useExportExportSalaryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportExportSalaryHook>>>,
    { params?: ExportExportSalaryParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportExportSalary(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportSalaryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportSalaryHook>>>
>;

export type ExportExportSalaryMutationError = unknown;

export const useExportExportSalary = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportSalaryHook>>>,
    TError,
    { params?: ExportExportSalaryParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportSalaryHook>>>,
  TError,
  { params?: ExportExportSalaryParams },
  TContext
> => {
  const mutationOptions = useExportExportSalaryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportExportHogiaSalaryHook = () => {
  const exportExportHogiaSalary = useExportExportHogiaSalaryMutator<Blob>();

  return (params?: ExportExportHogiaSalaryParams) => {
    return exportExportHogiaSalary({
      url: `/api/Export/ExportHogiaSalary`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportExportHogiaSalaryMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportHogiaSalaryHook>>>,
    TError,
    { params?: ExportExportHogiaSalaryParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportHogiaSalaryHook>>>,
  TError,
  { params?: ExportExportHogiaSalaryParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportHogiaSalary = useExportExportHogiaSalaryHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportExportHogiaSalaryHook>>>,
    { params?: ExportExportHogiaSalaryParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportExportHogiaSalary(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportHogiaSalaryMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportHogiaSalaryHook>>>
>;

export type ExportExportHogiaSalaryMutationError = unknown;

export const useExportExportHogiaSalary = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportHogiaSalaryHook>>>,
    TError,
    { params?: ExportExportHogiaSalaryParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportHogiaSalaryHook>>>,
  TError,
  { params?: ExportExportHogiaSalaryParams },
  TContext
> => {
  const mutationOptions = useExportExportHogiaSalaryMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportExportRestingTimesHook = () => {
  const exportExportRestingTimes = useExportExportRestingTimesMutator<Blob>();

  return (params?: ExportExportRestingTimesParams) => {
    return exportExportRestingTimes({
      url: `/api/Export/ExportRestingTimes`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportExportRestingTimesMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportRestingTimesHook>>>,
    TError,
    { params?: ExportExportRestingTimesParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportRestingTimesHook>>>,
  TError,
  { params?: ExportExportRestingTimesParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportRestingTimes = useExportExportRestingTimesHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportExportRestingTimesHook>>>,
    { params?: ExportExportRestingTimesParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportExportRestingTimes(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportRestingTimesMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportRestingTimesHook>>>
>;

export type ExportExportRestingTimesMutationError = unknown;

export const useExportExportRestingTimes = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportRestingTimesHook>>>,
    TError,
    { params?: ExportExportRestingTimesParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportRestingTimesHook>>>,
  TError,
  { params?: ExportExportRestingTimesParams },
  TContext
> => {
  const mutationOptions = useExportExportRestingTimesMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportExportAvailabilityDataHook = () => {
  const exportExportAvailabilityData =
    useExportExportAvailabilityDataMutator<Blob>();

  return (params?: ExportExportAvailabilityDataParams) => {
    return exportExportAvailabilityData({
      url: `/api/Export/ExportAvailabilityData`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportExportAvailabilityDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportAvailabilityDataHook>>>,
    TError,
    { params?: ExportExportAvailabilityDataParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportAvailabilityDataHook>>>,
  TError,
  { params?: ExportExportAvailabilityDataParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportAvailabilityData = useExportExportAvailabilityDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportExportAvailabilityDataHook>>>,
    { params?: ExportExportAvailabilityDataParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportExportAvailabilityData(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportAvailabilityDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportAvailabilityDataHook>>>
>;

export type ExportExportAvailabilityDataMutationError = unknown;

export const useExportExportAvailabilityData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportAvailabilityDataHook>>>,
    TError,
    { params?: ExportExportAvailabilityDataParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportAvailabilityDataHook>>>,
  TError,
  { params?: ExportExportAvailabilityDataParams },
  TContext
> => {
  const mutationOptions =
    useExportExportAvailabilityDataMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useExportExportUserRatingDataHook = () => {
  const exportExportUserRatingData =
    useExportExportUserRatingDataMutator<Blob>();

  return (params?: ExportExportUserRatingDataParams) => {
    return exportExportUserRatingData({
      url: `/api/Export/ExportUserRatingData`,
      method: 'POST',
      params,
      responseType: 'blob',
    });
  };
};

export const useExportExportUserRatingDataMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportUserRatingDataHook>>>,
    TError,
    { params?: ExportExportUserRatingDataParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useExportExportUserRatingDataHook>>>,
  TError,
  { params?: ExportExportUserRatingDataParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const exportExportUserRatingData = useExportExportUserRatingDataHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useExportExportUserRatingDataHook>>>,
    { params?: ExportExportUserRatingDataParams }
  > = (props) => {
    const { params } = props ?? {};

    return exportExportUserRatingData(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type ExportExportUserRatingDataMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useExportExportUserRatingDataHook>>>
>;

export type ExportExportUserRatingDataMutationError = unknown;

export const useExportExportUserRatingData = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useExportExportUserRatingDataHook>>>,
    TError,
    { params?: ExportExportUserRatingDataParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useExportExportUserRatingDataHook>>>,
  TError,
  { params?: ExportExportUserRatingDataParams },
  TContext
> => {
  const mutationOptions = useExportExportUserRatingDataMutationOptions(options);

  return useMutation(mutationOptions);
};
