/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentRequestReasonEnum =
  (typeof AssignmentRequestReasonEnum)[keyof typeof AssignmentRequestReasonEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentRequestReasonEnum = {
  Other: 0,
  HomeDeliveryFromFacility: 1,
  NearbyPurchase: 2,
  NearbyTradeIn: 3,
  NearbyStopover: 4,
  EndOfDay: 5,
} as const;
