/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type ApprovalStatus =
  (typeof ApprovalStatus)[keyof typeof ApprovalStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ApprovalStatus = {
  REQUESTED: 0,
  APPROVED: 100,
  DECLINED: 200,
} as const;
