import { forwardRef, useState } from 'react';

import {
  faChevronDown,
  faPaperPlane,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import { useAssignmentsFilters } from '../../contexts/assignmentsFilters/useAssignmentsFilters';
import { formatDate } from '../../utils/date-helpers';
import FilterPicker from './FilterPicker';
import FilterSearchInput from './FilterSearchInput';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import CreateCaseModal from 'components/CreateCaseModal';
import useCurrentRouteCaseType from 'hooks/useCurrentRouteCaseType';
import DatePicker from 'components/inputs/DatePicker';
import { AssignmentViewModel, CaseTypeEnum } from 'api';
import Roles from 'constants/Roles';
import AssignmentCountWidget from 'components/AssignmentsList/AssignmentCountWidget';
import AssignmentFiltersDropdown from './AssignmentFiltersDropdown';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 10px 10px;
  gap: 5px;
`;

const MyLabel = styled.label`
  margin-right: 5px;
`;

const DatePickerButton = styled.button`
  display: flex;
  align-items: center;
  padding: 0 10px;
  height: 30px;

  color: ${({ theme }) => theme.colors.foreground.tint};
  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  font: inherit;

  div {
    white-space: nowrap;
  }

  span {
    display: block;
    margin-left: auto;
    padding-left: 10px;
    font-size: 0.5em;
    color: ${({ theme }) => theme.colors.foreground.primary};
  }
`;

const DatePickerContainer = styled.div``;

const StyledAuthorizedButton = styled(AuthorizedButton)`
  height: 30px;
  padding: 0 10px;
  margin-right: 10px;
  font: inherit;
  white-space: nowrap;

  & > *:first-child {
    margin-right: 6px;
    font-size: 12px;
  }
`;

const MyFilterSearchInput = styled(FilterSearchInput)`
  margin-left: auto;
`;

// const StyledMultiSelect = styled(MultiSelect)`
//   padding: 8px;
//   ${({ value, theme }) =>
//     value.size > 0 &&
//     css`
//       background: ${theme.colors.background.highlight};
//     `}
// ` as typeof MultiSelect;

const CustomInput = forwardRef<HTMLButtonElement, { date: Date }>(
  ({ date, ...props }, ref) => (
    <DatePickerButton ref={ref} type="button" {...props}>
      <div>{formatDate(date)}</div>
      <span>
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
    </DatePickerButton>
  )
);

interface Props {
  onSendToFieldTestersClick?: () => void;
  assignments: AssignmentViewModel[];
  showSendToFieldTesters?: boolean;
  onUpdate?: () => void;
  showAdvancedFilters?: boolean;
}

const FilterBar: React.FC<Props> = ({
  assignments,
  onSendToFieldTestersClick,
  showSendToFieldTesters = true,
  onUpdate,
  showAdvancedFilters,
}) => {
  // const { unitsById } = useBusinessUnits();
  // const {
  //   advancedAssignmentFilterOptions:
  //     advancedAssignmentFilterOptionsTranslations,
  // } = useTranslations();

  const currentCaseRouteType = useCurrentRouteCaseType();
  // const businessUnitOptions = useMemo((): Option<number>[] => {
  //   return Array.from(unitsById.values()).map((unit) => ({
  //     label: unit.name,
  //     value: unit.id,
  //   }));
  // }, [unitsById]);

  // const advancedAssignmentFilterOptions = useMemo(
  //   (): Option<AdvancedAssignmentFilterOption>[] =>
  //     Object.entries(advancedAssignmentFilterOptionsTranslations ?? {}).map(
  //       ([status, name]) => {
  //         const option = {
  //           label: name as string,
  //           value: parseInt(status, 10) as AdvancedAssignmentFilterOption,
  //         };
  //         return option;
  //       }
  //     ),
  //   [advancedAssignmentFilterOptionsTranslations]
  // );

  const currentRouteCaseType = useCurrentRouteCaseType();

  const {
    state: { date },
    setDate,
  } = useAssignmentsFilters();
  const [showAdd, setShowAdd] = useState<boolean>(false);

  const handleChangeDate = (
    newDate: Date | [Date | null, Date | null] | null
  ) => {
    if (newDate) {
      if (!Array.isArray(newDate)) {
        setDate(newDate);
      }
    }
  };

  const onOpen = () => {
    setShowAdd(true);
  };

  const handleClose = () => {
    setShowAdd(false);
  };

  const handleCaseCreated = () => {
    setShowAdd(false);
    onUpdate?.();
  };

  return (
    <Wrapper>
      <MyLabel>Visa dag:</MyLabel>
      <DatePickerContainer>
        <DatePicker
          calendarStartDay={1}
          customInput={<CustomInput date={date} />}
          dateFormat="yyyy-MM-dd"
          locale="sv"
          onChange={(d) => handleChangeDate(d)}
          populateHolidays
          selected={date}
        />
      </DatePickerContainer>
      <AssignmentCountWidget assignments={assignments} />
      <FilterPicker />
      {showSendToFieldTesters && (
        <StyledAuthorizedButton
          onClick={onSendToFieldTestersClick}
          roles={[Roles.Admin]}
        >
          <FontAwesomeIcon icon={faPaperPlane} />
          Skicka uppdrag till fälttestare
        </StyledAuthorizedButton>
      )}

      {currentCaseRouteType !== CaseTypeEnum.Extra && (
        <StyledAuthorizedButton onClick={onOpen} roles={[Roles.Admin]}>
          <FontAwesomeIcon icon={faPlus} />
          Lägg till
        </StyledAuthorizedButton>
      )}

      <MyFilterSearchInput />
      <AssignmentFiltersDropdown showAdvancedFilters={!!showAdvancedFilters} />
      {showAdd && (
        <CreateCaseModal
          caseType={currentRouteCaseType ?? CaseTypeEnum.Purchase}
          onCaseCreated={handleCaseCreated}
          onClose={handleClose}
        />
      )}
    </Wrapper>
  );
};

export default FilterBar;
