import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import ReactMarkdown from 'react-markdown';
import policyFile from './policy_20230109.md';
import themeSizes from 'constants/Sizes';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
`;

const MarkdownWrapper = styled.div`
  width: 768px;
  line-height: 1.5;

  h1 {
    font-size: ${themeSizes.font.large};
  }

  h2 {
    font-size: ${themeSizes.font.medium};
    margin-top: 20px;
  }
  p {
    padding-bottom: 5px;
  }
`;

const PrivacyPolicyPage: FC = () => {
  const [policy, setPolicy] = useState<string>('');
  useEffect(() => {
    fetch(policyFile)
      .then((response) => response.text())
      .then((text) => {
        setPolicy(text);
      });
  }, []);
  return (
    <Wrapper>
      <MarkdownWrapper>
        <ReactMarkdown>{policy}</ReactMarkdown>
      </MarkdownWrapper>
    </Wrapper>
  );
};

export default PrivacyPolicyPage;
