import {
  AssignmentClient,
  AssignmentDeviationEnum,
  AssignmentStatusEnum,
  CaseStatusEnum,
  IAssignmentViewModel,
  SearchAssignmentsRequest,
} from 'api';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import useUpdateInterval from 'hooks/useUpdateInterval';
import { useCallback, useEffect, useMemo } from 'react';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import { refreshRate } from 'constants/AppConstants';
import useAssignmentsBulkOperations from './useAssignmentsBulkOperations';
import { useAssignmentSearchAllAssignmentsNoPaging } from 'api/assignment/assignment';
import { ColumnSetting } from 'components/Table/utils';
import { ColumnProps } from 'components/AssignmentsList/useAssignmentListColumnSettings';
import { AdvancedAssignmentFilterOption, CaseTypeEnum } from 'api/model';

const useSearchedAssignments = () => {
  const {
    state: {
      advancedFilters,
      caseTypes,
      assignmentStatuses,
      assignmentDeviations,
      caseStatuses,
      businessUnits,
      fromAddress,
      searchText,
      triggerAssignmentsUpdate,
      daterange,
      skip,
      size,
      sortAscending,
      sortedColumnIndex,
    },
  } = useAssignmentSearch();

  const searchAllAssignmentsNoPaging =
    useAssignmentSearchAllAssignmentsNoPaging();

  const assignmentsResponse = useApiCall(
    AssignmentClient,
    (
      c,
      search: string,
      caseTypesList: CaseTypeEnum[],
      assignmentStatusList: AssignmentStatusEnum[],
      assignmentDeviationList: AssignmentDeviationEnum[],
      caseStatusList: CaseStatusEnum[],
      businessUnitList: number[],
      advancedFiltersList: AdvancedAssignmentFilterOption[],
      _fromAddress: string,
      range: [Date | null, Date | null],
      _skip: number,
      _size: number
    ) =>
      c.searchAssignmentWithPaging(
        new SearchAssignmentsRequest({
          searchStr: search,
          forCurrentUser: false,
          caseTypes: caseTypesList,
          assignmentStatuses: assignmentStatusList,
          assignmentDeviations: assignmentDeviationList,
          businessUnits: businessUnitList,
          caseStatuses: caseStatusList,
          advancedSearchOptions: advancedFiltersList,
          fromAddress: _fromAddress,
          startDate: range[0] ?? undefined,
          endDate: range[1] ?? undefined,
          size: _size,
          skip: _skip,
        })
      )
  );

  const searchInputsInvalid = useMemo(
    () =>
      daterange[0] === null || daterange[1] === null
        ? 'Ange från- och till-datum för att söka.'
        : null,
    [daterange]
  );

  const update = useCallback(
    async (clearBeforeUpdating: boolean) => {
      if (searchInputsInvalid) return;

      if (clearBeforeUpdating) {
        assignmentsResponse.setStatus(RequestStatus.Idle);
        assignmentsResponse.setResponse(undefined);
      }
      await assignmentsResponse.run(
        searchText,
        caseTypes,
        assignmentStatuses,
        assignmentDeviations,
        caseStatuses,
        businessUnits,
        advancedFilters,
        fromAddress,
        daterange,
        skip,
        size
      );
    },
    [
      searchInputsInvalid,
      assignmentsResponse,
      searchText,
      caseTypes,
      assignmentStatuses,
      assignmentDeviations,
      caseStatuses,
      businessUnits,
      advancedFilters,
      fromAddress,
      daterange,
      skip,
      size,
    ]
  );

  const downloadCsv = useCallback(
    async (
      columnSettings: ColumnSetting<IAssignmentViewModel, ColumnProps>[],
      fileName: string
    ) => {
      try {
        const result = await searchAllAssignmentsNoPaging.mutateAsync({
          data: {
            searchStr: searchText,
            forCurrentUser: false,
            assignmentStatuses,
            assignmentDeviations,
            caseTypes,
            businessUnits,
            caseStatuses,
            fromAddress,
            startDate: daterange[0] ?? undefined,
            endDate: daterange[1] ?? undefined,
            advancedSearchOptions: advancedFilters,
          },
        });
        // convert to csv and download

        const filteredColumnSettings = columnSettings.filter(
          (c) => !c.excludeFromDownload
        );

        const sortedResult = result.sort((a, b) => {
          const sortFunction = columnSettings[sortedColumnIndex]?.sortFunction;

          const ascending = sortAscending ? 1 : -1;
          if (sortFunction) {
            return (
              sortFunction(
                a as IAssignmentViewModel,
                b as IAssignmentViewModel
              ) * ascending
            );
          }
          return 0;
        });

        const headers = filteredColumnSettings
          .map((c) => c.csvHead ?? c.head)
          .join(',');
        const csv = `${headers}\n${sortedResult
          .map((a) => {
            return filteredColumnSettings.map(
              (c, i) => c.csvValue?.(a as IAssignmentViewModel, i) ?? '-'
            );
          })
          .join('\n')}`;

        const blob = new Blob([csv], {
          type: 'text/csv',
        });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `${fileName}.csv`;
        a.click();
        URL.revokeObjectURL(url);
      } catch (error) {
        // console.error(error);
      }
    },
    [
      advancedFilters,
      assignmentDeviations,
      assignmentStatuses,
      businessUnits,
      caseStatuses,
      caseTypes,
      daterange,
      fromAddress,
      searchAllAssignmentsNoPaging,
      searchText,
      sortAscending,
      sortedColumnIndex,
    ]
  );

  const assignmentsBulkOperations = useAssignmentsBulkOperations(
    assignmentsResponse.response?.result ?? [],
    () => update(false)
  );

  useUpdateInterval(async () => update(false), refreshRate, true);

  useEffect(() => {
    update(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    searchText,
    assignmentStatuses,
    assignmentDeviations,
    caseStatuses,
    businessUnits,
    triggerAssignmentsUpdate,
    daterange,
  ]);

  return useMemo(
    () => ({
      assignments: assignmentsResponse.response?.result,
      total: assignmentsResponse.response?.total ?? 0,
      skip: assignmentsResponse.response?.skip ?? 0,
      size: assignmentsResponse.response?.size ?? 0,

      status: assignmentsResponse.status || searchAllAssignmentsNoPaging.status,
      update,

      searchInputsInvalidMessage: searchInputsInvalid,
      assignmentsBulkOperations,

      downloadCsv,
      downloadingCsv: searchAllAssignmentsNoPaging.status === 'loading',
    }),
    [
      assignmentsBulkOperations,
      assignmentsResponse.response?.result,
      assignmentsResponse.response?.size,
      assignmentsResponse.response?.skip,
      assignmentsResponse.response?.total,
      assignmentsResponse.status,
      downloadCsv,
      searchAllAssignmentsNoPaging.status,
      searchInputsInvalid,
      update,
    ]
  );
};

export default useSearchedAssignments;
