import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseEventType, EventModel } from 'api';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useDetailedCaseContext } from '../DetailedCaseProvider';

interface Props {
  events: EventModel[];
}

const MarkAllCommentsAsReadButton: FC<Props> = ({ events }) => {
  const theme = useTheme();
  const { markEventsAsReadStatus, onMarkEventsAsRead } =
    useDetailedCaseContext();

  const toMarkAsRead = useMemo(
    () =>
      events.filter(
        (e) =>
          (e.caseEventType === CaseEventType.Comment ||
            e.caseEventType === CaseEventType.FlaggedForSpeeding ||
            e.caseEventType === CaseEventType.WorkGearReport ||
            e.caseEventType === CaseEventType.DeviationReport ||
            e.caseEventType === CaseEventType.InternalComment) &&
          !e.readByAdmin
      ),
    [events]
  );

  const markAllAsRead = async () => {
    await onMarkEventsAsRead(toMarkAsRead.map((e) => e.id));
  };

  if (toMarkAsRead.length === 0) return null;

  return (
    <AuthorizedButton
      disabled={markEventsAsReadStatus === 'loading'}
      icon={
        markEventsAsReadStatus === 'loading' ? undefined : (
          <FontAwesomeIcon icon={faCheck} />
        )
      }
      onClick={markAllAsRead}
    >
      {markEventsAsReadStatus === 'loading' ? (
        <TransparentSpinner color={theme.colors.foreground.button} small />
      ) : (
        'Markera alla som lästa'
      )}
    </AuthorizedButton>
  );
};

export default MarkAllCommentsAsReadButton;
