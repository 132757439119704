/* eslint-disable import/prefer-default-export */

import { AbsenceStatus, AvailabilityDto, AvailabilityStatus } from 'api/model';

export const issueWarning = (a: AvailabilityDto): boolean => {
  return (
    a.bookedAssignments > 0 &&
    ((a.absenceStatus !== null && a.absenceStatus !== AbsenceStatus.DECLINED) ||
      a.scheduleStatus !== AvailabilityStatus.AVAILABLE)
  );
};
