import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  AdvancedAssignmentFilterOption,
  AssignmentStatusEnum,
  CaseStatusEnum,
  CaseTypeEnum,
} from 'api/model';
import Dropdown from 'components/Dropdown';
import Button from 'components/inputs/Button';
import LabelWrap from 'components/inputs/LabelWrap';
import MultiSelect, { Option } from 'components/inputs/MultiSelect';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits';
import useTranslations from 'contexts/basicData/useTranslations';
import styled, { css } from 'styled-components';
import { useDevMenuState as useDevSettings } from 'components/DevMenu';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilter } from '@fortawesome/free-solid-svg-icons';
import Input from 'components/inputs/Input';

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
`;

// circular badge
const FilterSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.foreground.button};
  color: ${({ theme }) => theme.colors.background.button};
  font-size: 0.8em;
  font-weight: bold;
`;

const MyButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  height: 30px;
`;

const MyLabelWrap = styled(LabelWrap)<{ width?: number }>`
  height: 100%;
`;

const StyledMultiSelect = styled(MultiSelect)`
  ${({ value, theme }) =>
    value.size > 0 &&
    css`
      background: ${theme.colors.background.highlight};
    `}
` as typeof MultiSelect;

const GlobalSearchFilterDropdown = () => {
  const fromAddressTimeout = useRef<NodeJS.Timeout | null>(null);
  const [devSettings] = useDevSettings();

  const { unitsById } = useBusinessUnits();

  const {
    assignmentStatuses: assStatusTranslations,
    caseStatuses: caseStatusTranslations,
    caseTypes: caseTypeTranslations,
    advancedAssignmentFilterOptions,
  } = useTranslations();
  const {
    state: {
      advancedFilters,
      assignmentStatuses,
      businessUnits,
      caseStatuses,
      caseTypes,
      fromAddress,
    },
    setAssignmentStatuses,
    setBusinessUnits,
    setCaseStatuses,
    setCaseTypes,
    setAdvancedFilters,
    setFromAddress,
    triggerAssignmentsUpdate,
  } = useAssignmentSearch();

  const [filtersOpen, setFiltersOpen] = useState(false);
  const [currentFromAddress, setCurrentFromAddress] = useState(fromAddress);

  const assignmentStatusOptions = useMemo(
    (): Option<AssignmentStatusEnum>[] =>
      Object.entries(assStatusTranslations).map(([status, name]) => {
        const option = {
          label: name as string,
          value: parseInt(status, 10) as AssignmentStatusEnum,
        };
        return option;
      }),
    [assStatusTranslations]
  );

  const businessUnitOptions = useMemo((): Option<number>[] => {
    return Array.from(unitsById.values()).map((unit) => ({
      label: unit.name,
      value: unit.id,
    }));
  }, [unitsById]);

  const caseTypeOptions = useMemo(() => {
    const options: Option<CaseTypeEnum>[] = Object.entries(
      caseTypeTranslations
    ).map(([type, name]) => {
      const option = {
        label: name as string,
        value: parseInt(type, 10) as CaseTypeEnum,
      };
      return option;
    });

    return options;
  }, [caseTypeTranslations]);

  const caseStatusOptions = useMemo(() => {
    const options: Option<CaseStatusEnum>[] = Object.entries(
      caseStatusTranslations
    ).map(([status, name]) => {
      const option = {
        label: name as string,
        value: parseInt(status, 10) as CaseStatusEnum,
      };
      return option;
    });

    if (devSettings.enableCasePendingStatus) return options;

    return options.filter((opt) => opt.value !== CaseStatusEnum.Pending);
  }, [caseStatusTranslations, devSettings.enableCasePendingStatus]);

  const advancedAssignmentSearchOptions = useMemo(() => {
    const supportedFilters = [
      AdvancedAssignmentFilterOption.HasAssignee,
      AdvancedAssignmentFilterOption.FromStrangnasLogistik,
    ] as AdvancedAssignmentFilterOption[];
    const options: Option<AdvancedAssignmentFilterOption>[] = Object.entries(
      advancedAssignmentFilterOptions ?? {}
    )
      .map(([status, name]) => {
        const option = {
          label: name as string,
          value: parseInt(status, 10) as AdvancedAssignmentFilterOption,
        };
        return option;
      })
      .filter((opt) => supportedFilters.includes(opt.value));
    return options;
  }, [advancedAssignmentFilterOptions]);

  // Update fromAddress after 500ms of inactivity
  useEffect(() => {
    if (fromAddressTimeout.current) {
      clearTimeout(fromAddressTimeout.current);
    }
    fromAddressTimeout.current = setTimeout(() => {
      setFromAddress(currentFromAddress);
      triggerAssignmentsUpdate(Math.random());
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFromAddress]);

  const numberOfFilters = useMemo(() => {
    const count =
      assignmentStatuses.length +
      caseStatuses.length +
      businessUnits.length +
      caseTypes.length +
      advancedFilters.length +
      (fromAddress ? 1 : 0);
    return count;
  }, [
    advancedFilters.length,
    assignmentStatuses.length,
    businessUnits.length,
    caseStatuses.length,
    caseTypes.length,
    fromAddress,
  ]);
  return (
    <Dropdown
      onLostFocus={() => setFiltersOpen(false)}
      position="left"
      content={
        filtersOpen && (
          <FilterWrapper>
            <MyLabelWrap label="Ärendetyp">
              <StyledMultiSelect
                onChange={(newVal) => {
                  setCaseTypes(Array.from(newVal));
                  triggerAssignmentsUpdate(Math.random());
                }}
                options={caseTypeOptions}
                value={new Set(caseTypes)}
              />
            </MyLabelWrap>
            <MyLabelWrap label="Ärendestatus">
              <StyledMultiSelect
                onChange={(newVal) => {
                  setCaseStatuses(Array.from(newVal));
                  triggerAssignmentsUpdate(Math.random());
                }}
                options={caseStatusOptions}
                value={new Set(caseStatuses)}
              />
            </MyLabelWrap>
            <MyLabelWrap label="Uppdragsstatus">
              <StyledMultiSelect
                onChange={(newVal) => {
                  setAssignmentStatuses(Array.from(newVal));
                  triggerAssignmentsUpdate(Math.random());
                }}
                options={assignmentStatusOptions}
                value={new Set(assignmentStatuses)}
              />
            </MyLabelWrap>

            <MyLabelWrap label="Enhet">
              <StyledMultiSelect
                onChange={(newVal) => {
                  setBusinessUnits(Array.from(newVal));
                  triggerAssignmentsUpdate(Math.random());
                }}
                options={businessUnitOptions}
                value={new Set(businessUnits)}
              />
            </MyLabelWrap>

            <MyLabelWrap label="Från-adress">
              <Input
                value={currentFromAddress}
                onChange={(e) => {
                  setCurrentFromAddress(e.currentTarget.value);
                }}
              />
            </MyLabelWrap>

            <MyLabelWrap label="Avancerat">
              <StyledMultiSelect
                onChange={(newVal) => {
                  setAdvancedFilters(Array.from(newVal));
                  triggerAssignmentsUpdate(Math.random());
                }}
                options={advancedAssignmentSearchOptions}
                value={new Set(advancedFilters)}
              />
            </MyLabelWrap>

            <MyButton
              disabled={numberOfFilters === 0}
              onClick={() => {
                setCaseStatuses([]);
                setAssignmentStatuses([]);
                setBusinessUnits([]);
                setCaseTypes([]);
                setAdvancedFilters([]);
                setFromAddress('');
              }}
            >
              Rensa
            </MyButton>
          </FilterWrapper>
        )
      }
    >
      <MyButton
        icon={<FontAwesomeIcon icon={faFilter} />}
        onClick={() => setFiltersOpen(!filtersOpen)}
      >
        <span>Filter </span>
        {numberOfFilters > 0 && <FilterSpan>{numberOfFilters}</FilterSpan>}
      </MyButton>
    </Dropdown>
  );
};

export default GlobalSearchFilterDropdown;
