import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import { useGoSupportGetStandbyFTDTOs } from 'api/go-support/go-support';
import { formatDateTime } from 'utils/date-helpers';
import styled from 'styled-components';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { StandbyFTDTO } from 'api';
import { makeStringComparator } from 'utils/sorting';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Routes from 'constants/Routes';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import TextButton from 'components/inputs/TextButton';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import { getTableRowClassName } from '../table-helpers';
import useUsers from 'contexts/basicData/useUsers';

const TABLE_NAME = 'FTs-on-standby';

const RegnrWrapper = styled.div`
  display: flex;
  gap: 5px;
  flex-direction: row;
  flex-wrap: wrap;
`;

const MyTextButton = styled(TextButton)`
  cursor: pointer;
  max-height: 15px;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const FTsOnStandbyWidget = () => {
  const [standbyFTs, setStandbyFTs] = useState<StandbyFTDTO[]>([]);
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();
  const users = useUsers();

  const getStandbyFTs = useGoSupportGetStandbyFTDTOs(
    {
      startDate: daterange[0] ?? undefined,
      endDate: daterange[1] ?? undefined,
      businessUnitIDs: selectedBusinessUnits,
    },
    {
      query: {
        refetchInterval: UPDATE_INTERVAL,
      },
    }
  );

  useEffect(() => {
    if (getStandbyFTs.data) {
      setStandbyFTs(getStandbyFTs.data as StandbyFTDTO[]);
    } else {
      setStandbyFTs([] as StandbyFTDTO[]);
    }
  }, [getStandbyFTs.data]);

  const filteredComments = useMemo(() => {
    if (!searchText.length) {
      return standbyFTs;
    }
    return standbyFTs
      ? standbyFTs.filter((c) => {
          return c.userName?.toLowerCase().includes(searchText.toLowerCase());
        })
      : [];
  }, [standbyFTs, searchText]);

  const columnSettings: ColumnSetting<StandbyFTDTO, any>[] = useMemo(() => {
    const columns: ColumnSetting<StandbyFTDTO, any>[] = [
      {
        head: 'Nr',
        render: (e, t, f, i) => i + 1,
        width: 15,
      },
      {
        head: 'Namn',
        render: (row) => `${row.userName}`,
        width: 50,
        sortFunction: makeStringComparator((row) => row.userName),
      },
      {
        head: 'Telefonnummer',
        render: (row) => {
          return (
            <span>
              {row.userId && users[row.userId]
                ? users[row.userId].phoneNumber
                : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.userId]?.phoneNumber ?? '').localeCompare(
            users[b.userId]?.phoneNumber ?? ''
          ),
      },
      {
        head: 'Tid',
        render: (row) =>
          `${row.standbyStart ? formatDateTime(row.standbyStart) : '-'} – ${row.standbyEnd ? formatDateTime(row.standbyEnd) : '-'}`,
        width: 100,
        sortFunction: makeStringComparator(
          (row) =>
            `${row.standbyStart ? formatDateTime(row.standbyStart) : '-'} – ${row.standbyEnd ? formatDateTime(row.standbyEnd) : '-'}`
        ),
      },
      {
        head: 'Avbokade körningar utan ersättningsuppdrag',
        render: (row) => (
          <RegnrWrapper>
            {row.originatingCases.map((c) => (
              <MyTextButton
                key={c.caseID}
                onClick={() => {
                  window.open(
                    `${Routes.search.index}/${row.originatingCases[0]?.caseID ?? ''}`,
                    row.toString() ?? '_blank'
                  );
                }}
              >
                {c.registrationNumber}
                <FontAwesomeIcon
                  icon={faArrowUpRightFromSquare}
                  fontSize="18px"
                  style={{ cursor: 'pointer' }}
                />
              </MyTextButton>
            ))}
          </RegnrWrapper>
        ),
        width: 100,
      },
    ];
    return columns;
  }, [users]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.FTsOnStandby]}
      count={standbyFTs.length}
      isLoading={getStandbyFTs.isLoading}
      info="Markera som klar för att markera kommentar som läst."
    >
      <Table
        columnSettings={columnSettings}
        rows={filteredComments}
        rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.userId)}
        useColumnWidthAsFlexAmount
      />
    </Widget>
  );
};

export default FTsOnStandbyWidget;
