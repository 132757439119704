/* eslint-disable func-names */
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Input from 'components/inputs/Input';
import Button from 'components/inputs/Button';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock,
  faExternalLink,
  faSave,
} from '@fortawesome/free-solid-svg-icons';
import { useOnClickOutside } from 'usehooks-ts';
import Dropdown from 'components/Dropdown';
import {
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentViewModel,
  IAssignmentViewModel,
  IUserModel,
} from 'api';
import { AssignmentEditOperation } from '../useQueuedAssignmentUpdates';
import EstimatedDurationPicker from 'components/DetailedCase/CaseAssignments/EstimatedDurationPicker';
import { toInputDateTimeString } from 'utils/date-helpers';
import PrioritizedCaseAssignmentUserPicker from 'components/PrioritizedCaseAssignmentUserPicker';
import { fakeSelectClassName } from 'components/inputs/SearchableSelect';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  hardCodedEstimatedDurations,
  userNamesExcemptFromEstimationValidation,
} from 'constants/AppConstants';
import {
  useUserCheckIfOkToAddAssignment,
  useUserGetDrivingPolicyReportForUnPlannedAssignment,
} from 'api/user/user';
import TextButton from 'components/inputs/TextButton';
import TooltipInfoWarning from 'components/TooltipInfoWarning';
import useAssignmentWarningsAndMultiplePurchases from 'hooks/useAssignmentWarningsAndMultiplePurchases';
import { UserModel } from 'api/model';

const ShortDiv = styled.div`
  color: red;
  max-width: 200px;
  white-space: break-spaces;
`;

const StyledUserPicker = styled(PrioritizedCaseAssignmentUserPicker)`
  display: flex;
  height: 100%;

  .${fakeSelectClassName} {
    width: 100%;
    outline: none;
    background-color: ${({ theme }) => theme.colors.background.primary};
    border: 2px solid ${({ theme }) => theme.colors.background.selection};
    border-radius: 0;
    box-shadow: ${({ theme }) => theme.colors.shadow.dropdown};
  }
`;

const editableCellDropdownClassName =
  'combinedUserAndTimeEstimationPickerCellClassName';

const StyledDropdown = styled(Dropdown)`
  &.${editableCellDropdownClassName} {
    display: flex;
    height: 100%;
    width: 100%;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-end;
    gap: 5px;
  }
`;

const CombinedUserAndTimeEstimationPickerCellColumn = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  gap: 5px;
  padding: 5px;
`;

const CombinedUserAndTimeEstimationPickerCellRow = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 5px;
`;

interface CombinedUserAndTimeEstimationPickerCellProps {
  assignmentViewModel: IAssignmentViewModel;
  onUpdateAssignment(
    assignmentId: number,
    editOperation: AssignmentEditOperation
  ): void;
  hideEstimatedDurationAndStartTime?: boolean;
}
export type IUserSubset = Pick<IUserModel, 'userID' | 'name'>;

interface CombinedUserAndTimeEstimationPickerCellForm {
  selectedUser: IUserSubset | null;
  breaksDrivingPolicy?: boolean;
  exceedDrivingPolicyRequestMessage?: string;
  estimatedDuration: number | null;
  estimatedStartTime: Date | null;
  systemEstimatedDurationApprox: number | null;
  systemEstimatedDurationExact: number | null;
  hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime:
    | boolean
    | null;
}

const userSchema = yup.object().shape({
  userID: yup.number().required(),
  name: yup.string().required(),
});

const validationSchema = yup.object().shape({
  selectedUser: userSchema.nullable(),
  estimatedDuration: yup
    .number()
    .nullable()
    .test(
      'is-selectedUser-null',
      'Estimerad uppdragstid är obligatorisk när en användare är vald',
      function (value) {
        const {
          selectedUser,
          hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime,
        } = this.parent;

        if (
          !selectedUser ||
          userNamesExcemptFromEstimationValidation.includes(
            selectedUser.name
          ) ||
          hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime
        ) {
          return true;
        }
        const result = !!value;

        return result;
      }
    ),
  estimatedStartTime: yup
    .date()
    .nullable()
    .test(
      'is-selectedUser-null',
      'Estimerad starttid är obligatorisk när en användare är vald',
      function (value) {
        const {
          selectedUser,
          hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime,
        } = this.parent;
        return hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime ||
          !selectedUser ||
          userNamesExcemptFromEstimationValidation.includes(selectedUser.name)
          ? true
          : !!value;
      }
    ),
  breaksDrivingPolicy: yup.boolean().nullable(),
  systemEstimatedDurationApprox: yup.number().nullable(),
  systemEstimatedDurationExact: yup.number().nullable(),
});

const CombinedUserAndTimeEstimationPickerCell: FC<
  CombinedUserAndTimeEstimationPickerCellProps
> = ({
  assignmentViewModel,
  onUpdateAssignment,
  hideEstimatedDurationAndStartTime,
}) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);

  const {
    multiplePurchasesList,
    updateMultiplePurchasesList,
    isViolatingDrivingPolicy,
  } = useAssignmentWarningsAndMultiplePurchases(
    assignmentViewModel.case.caseTypeID,
    assignmentViewModel.assignment,
    dropdownOpen
  );
  // the first assigned user to any of the multiple purchase siblings (for quick select)
  const multiplePurchasesAssignedUser = useMemo(() => {
    const assignmentWithAssignee = multiplePurchasesList.find(
      (m) => !!m.assignment.assignedTo
    );
    return assignmentWithAssignee?.assignment.assignedTo;
  }, [multiplePurchasesList]);

  const { mutateAsync: checkIfOkToAddAssignmentCall } =
    useUserCheckIfOkToAddAssignment();

  const getDefaultValues =
    useCallback((): CombinedUserAndTimeEstimationPickerCellForm => {
      const defaults: CombinedUserAndTimeEstimationPickerCellForm = {
        selectedUser: assignmentViewModel.assignment.assignedTo ?? null,
        exceedDrivingPolicyRequestMessage: undefined,
        estimatedDuration:
          assignmentViewModel.assignment.estimatedDuration ?? null,
        estimatedStartTime:
          assignmentViewModel.assignment.estimatedStartTime ??
          assignmentViewModel.assignment.startTime ??
          null,
        systemEstimatedDurationApprox:
          assignmentViewModel.assignment.systemEstimatedDurationApprox ?? null,
        systemEstimatedDurationExact:
          assignmentViewModel.assignment.systemEstimatedDurationExact ?? null,
        hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime:
          false,
      };

      // if we have no estimated startTime, check if we have a hard-coded value to pre-populate with
      if (!defaults.estimatedDuration) {
        const key =
          `${assignmentViewModel.assignment.fromCounty?.areaName}-${assignmentViewModel.assignment.toCounty?.areaName}`.toLowerCase();

        const hardcodedValue =
          hardCodedEstimatedDurations[assignmentViewModel.case.caseTypeID]?.[
            key
          ];

        if (hardcodedValue) {
          defaults.estimatedDuration = hardcodedValue;
        }
      }

      return defaults;
    }, [assignmentViewModel]);
  const {
    setValue,
    watch,
    getValues,
    reset,
    trigger: triggerValidation,
    formState: { errors },
  } = useForm<CombinedUserAndTimeEstimationPickerCellForm>({
    defaultValues: getDefaultValues(),
    reValidateMode: 'onChange',
    resolver: yupResolver(validationSchema) as any,
  });

  // highlight if any of the other purchases has estimated duration and estimated starttime set
  useEffect(() => {
    setValue(
      'hasMultiPurchaseAssignmentSiblingsWithEstimatedDurationAndStartTime',
      multiplePurchasesList.some(
        (m) =>
          !!m.assignment.estimatedDuration && !!m.assignment.estimatedStartTime
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [multiplePurchasesList]);

  useEffect(() => {
    // reset form on mount
    reset(getDefaultValues());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpen]);
  const { estimatedDuration, estimatedStartTime, selectedUser } = watch();

  useEffect(() => {
    if (assignmentViewModel.assignment !== undefined && dropdownOpen) {
      updateMultiplePurchasesList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownOpen]);

  const drivingPolicyReportResult =
    useUserGetDrivingPolicyReportForUnPlannedAssignment(
      {
        userId: selectedUser?.userID,
        bookedTo: assignmentViewModel.assignment.bookedTo,
        startOrEstimatedStartTime: estimatedStartTime ?? undefined,
        estimatedDuration: estimatedDuration ?? undefined,
        endOrEstimatedEndTime: undefined,
        excludeAssignmentId: assignmentViewModel.assignment.assignmentID,
      },
      {
        query: {
          enabled:
            dropdownOpen &&
            !!selectedUser &&
            !!selectedUser.name &&
            !userNamesExcemptFromEstimationValidation.includes(
              selectedUser.name ?? ''
            ) &&
            assignmentViewModel.assignment.assignmentStatusID <
              AssignmentStatusEnum.Assigned,
        },
      }
    );

  const resultingEndTime = useMemo(() => {
    if (!estimatedDuration) {
      return null;
    }
    if (assignmentViewModel.assignment.startTime) {
      const newDate = new Date(
        assignmentViewModel.assignment.startTime.getTime() + estimatedDuration
      );
      return newDate;
    }
    if (estimatedStartTime) {
      const newDate = new Date(
        estimatedStartTime.getTime() + estimatedDuration
      );
      return newDate;
    }
    return null;
  }, [
    estimatedDuration,
    estimatedStartTime,
    assignmentViewModel.assignment.startTime,
  ]);

  const selectUserAndEstimatedTotalAndStartTime = async (
    form: CombinedUserAndTimeEstimationPickerCellForm
  ) => {
    onUpdateAssignment(assignmentViewModel.assignment.assignmentID, (avm) => {
      const currentStatus = avm.assignment.assignmentStatusID;
      let newStatus = avm.assignment.assignmentStatusID;

      // Deselcting user changes status to Created
      if (
        !form.selectedUser &&
        (currentStatus === AssignmentStatusEnum.Planned ||
          currentStatus === AssignmentStatusEnum.Assigned)
      ) {
        newStatus = AssignmentStatusEnum.Created;
      }

      // Selecting user changes status to Planned
      if (
        form.selectedUser &&
        (currentStatus === AssignmentStatusEnum.Created ||
          currentStatus === AssignmentStatusEnum.Assigned)
      ) {
        newStatus = AssignmentStatusEnum.Planned;
      }

      const updatedAvm: AssignmentViewModel = AssignmentViewModel.fromJS({
        ...avm,
        assignment: AssignmentModel.fromJS({
          ...avm.assignment,
          assignedTo: form.selectedUser ?? undefined,
          estimatedDuration: form.estimatedDuration,
          estimatedStartTime: form.estimatedStartTime,
          systemEstimatedDurationApprox: form.systemEstimatedDurationApprox,
          systemEstimatedDurationExact: form.systemEstimatedDurationExact,
          assignmentStatusID: newStatus,
        }),
      });

      return updatedAvm;
    });
    setDropdownOpen(false);
  };

  const handleUserPicked = async (user: UserModel | null) => {
    if (assignmentViewModel.assignment.startTime !== undefined) {
      // this assignment has already started, proceeed without warnings
      setValue('selectedUser', user);
      triggerValidation();
    } else {
      setValue('selectedUser', user);
      triggerValidation();
    }

    if (user) {
      const drivingPolicyReport = await checkIfOkToAddAssignmentCall({
        params: {
          userId: user.userID,
          bookedTo: assignmentViewModel.assignment.bookedTo,
          estimatedStartTime:
            estimatedStartTime ?? assignmentViewModel.assignment.bookedTo,
          estimatedDuration: estimatedDuration ?? 60 * 60 * 1000,
        },
      });

      setValue(
        'breaksDrivingPolicy',
        !drivingPolicyReport.isWithinAllowedDrivingHoursForAverageWeek
      );
    }
  };

  const handleLostFocus = useCallback(() => {
    if (!isActive) {
      setDropdownOpen(false);
    }
  }, [isActive]);

  useOnClickOutside(wrapperRef, () => {
    handleLostFocus();
  });

  return (
    <StyledDropdown
      className={editableCellDropdownClassName}
      content={
        dropdownOpen && (
          <CombinedUserAndTimeEstimationPickerCellColumn>
            <CombinedUserAndTimeEstimationPickerCellRow ref={wrapperRef}>
              <CombinedUserAndTimeEstimationPickerCellColumn>
                <span>
                  Fälttestare
                  {!!drivingPolicyReportResult.data &&
                    isViolatingDrivingPolicy(
                      drivingPolicyReportResult.data
                    ) && (
                      <TooltipInfoWarning
                        warnings={drivingPolicyReportResult.data.warnings}
                        showOnlyTooltip
                      />
                    )}
                </span>
                <StyledUserPicker
                  actualEndTime={assignmentViewModel.assignment.endTime?.toString()}
                  actualStartTime={assignmentViewModel.assignment.startTime?.toString()}
                  assignmentId={assignmentViewModel.assignment.assignmentID}
                  assignmentType={
                    assignmentViewModel.assignment.assignmentTypeID
                  }
                  autoFocus
                  bookedTo={assignmentViewModel.assignment.bookedTo.toString()}
                  caseModel={assignmentViewModel.case}
                  dropdownPosition="fullwidth"
                  estimatedDuration={estimatedDuration ?? 0}
                  estimatedStartTime={
                    estimatedStartTime
                      ? toInputDateTimeString(estimatedStartTime)
                      : ''
                  }
                  fromZipCode={assignmentViewModel.assignment.fromZip}
                  onStateChange={(isOpen: boolean) => setIsActive(isOpen)}
                  onUserPicked={handleUserPicked}
                  selectedUserId={selectedUser?.userID ?? null}
                />
                {
                  // if we have no selected user, but already selected one for a related multi-purchase
                  !!multiplePurchasesAssignedUser && !selectedUser && (
                    <Button
                      onClick={async (e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleUserPicked(multiplePurchasesAssignedUser);
                        await selectUserAndEstimatedTotalAndStartTime(
                          getValues()
                        );
                      }}
                      style={{ height: 25, padding: 5 }}
                    >
                      {`Välj: ${multiplePurchasesAssignedUser.name}`}
                    </Button>
                  )
                }

                {!!drivingPolicyReportResult.data &&
                  isViolatingDrivingPolicy(drivingPolicyReportResult.data) && (
                    <ShortDiv>
                      <TooltipInfoWarning
                        warnings={drivingPolicyReportResult.data.warnings}
                        showWarningsOnlyAsErrorMessage
                      />
                    </ShortDiv>
                  )}
              </CombinedUserAndTimeEstimationPickerCellColumn>
              {!hideEstimatedDurationAndStartTime && (
                <>
                  <CombinedUserAndTimeEstimationPickerCellColumn>
                    <span>Estimerad uppdragstid</span>

                    <CombinedUserAndTimeEstimationPickerCellRow>
                      <EstimatedDurationPicker
                        assignment={AssignmentModel.fromJS({
                          ...assignmentViewModel.assignment,
                          estimatedDuration,
                        })}
                        blurOnSelect={false}
                        caseType={assignmentViewModel.case.caseTypeID}
                        disabled={
                          !!assignmentViewModel.assignment.startTime &&
                          !!assignmentViewModel.assignment.endTime
                        }
                        error={!!errors.estimatedDuration}
                        hideSelectAverageCheckbox
                        onChangeEstimatedDuration={(value: number) => {
                          setValue('estimatedStartTime', null);
                          setValue(
                            'estimatedDuration',
                            !Number.isNaN(value) ? value : null
                          );
                          triggerValidation();
                        }}
                        onChangeSystemEstimatedDurationApprox={(
                          value: number
                        ) => {
                          setValue('systemEstimatedDurationApprox', value);
                          triggerValidation();
                        }}
                        onChangeSystemEstimatedDurationExact={(
                          value: number
                        ) => {
                          setValue('systemEstimatedDurationExact', value);
                          triggerValidation();
                        }}
                      />
                    </CombinedUserAndTimeEstimationPickerCellRow>
                  </CombinedUserAndTimeEstimationPickerCellColumn>
                  <CombinedUserAndTimeEstimationPickerCellColumn>
                    <span>Estimerad starttid</span>
                    <CombinedUserAndTimeEstimationPickerCellRow>
                      <Input
                        disabled={!!assignmentViewModel.assignment.startTime}
                        max={toInputDateTimeString(
                          assignmentViewModel.assignment.bookedTo
                        )}
                        onChange={(e) => {
                          setValue(
                            'estimatedStartTime',
                            e.target.value ? new Date(e.target.value) : null
                          );
                          triggerValidation();
                        }}
                        small
                        style={{
                          borderColor: errors.estimatedStartTime ? 'red' : '',
                          opacity: assignmentViewModel.assignment.startTime
                            ? 0.4
                            : 1,
                        }}
                        type="datetime-local"
                        value={
                          estimatedStartTime
                            ? toInputDateTimeString(estimatedStartTime)
                            : ''
                        }
                      />
                    </CombinedUserAndTimeEstimationPickerCellRow>
                  </CombinedUserAndTimeEstimationPickerCellColumn>
                  <CombinedUserAndTimeEstimationPickerCellColumn>
                    <span>Beräknad sluttid</span>
                    <Input
                      disabled
                      small
                      type="datetime-local"
                      value={
                        resultingEndTime
                          ? toInputDateTimeString(resultingEndTime)
                          : ''
                      }
                    />
                  </CombinedUserAndTimeEstimationPickerCellColumn>
                </>
              )}

              <CombinedUserAndTimeEstimationPickerCellColumn>
                <br />
                <Button
                  disabled={
                    !!errors.selectedUser ||
                    !!errors.estimatedDuration ||
                    !!errors.estimatedStartTime
                  }
                  onClick={async (e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    await selectUserAndEstimatedTotalAndStartTime(getValues());
                  }}
                  style={{ height: 25, padding: 5 }}
                >
                  Spara <FontAwesomeIcon icon={faSave} />
                </Button>
              </CombinedUserAndTimeEstimationPickerCellColumn>
            </CombinedUserAndTimeEstimationPickerCellRow>
            {multiplePurchasesList.length > 0 && (
              <CombinedUserAndTimeEstimationPickerCellRow
                style={{ alignItems: 'center' }}
              >
                <div>Flerbilsinköp med:</div>
                {multiplePurchasesList.map((m) => (
                  <TextButton
                    key={m.assignment.assignmentID}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      window.open(
                        `/sok/${m.case.caseID}`,
                        m.case.caseID.toString() ?? '_blank'
                      );
                    }}
                    title={`Flerbilsinköp med ${m.case.registrationNumber}`}
                  >
                    {m.case.registrationNumber}{' '}
                    {!!m.assignment.estimatedDuration && (
                      <FontAwesomeIcon icon={faClock} />
                    )}{' '}
                    <FontAwesomeIcon icon={faExternalLink} />
                  </TextButton>
                ))}
              </CombinedUserAndTimeEstimationPickerCellRow>
            )}
          </CombinedUserAndTimeEstimationPickerCellColumn>
        )
      }
      onClick={() => {
        setDropdownOpen(true);
      }}
      onLostFocus={handleLostFocus}
      position="left"
    >
      {assignmentViewModel.assignment.assignedTo?.name}
    </StyledDropdown>
  );
};

export default CombinedUserAndTimeEstimationPickerCell;
