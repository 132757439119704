import {
  AssignmentClient,
  AssignmentExtendedModel,
  AssignmentViewModel,
} from 'api';
import AssignmentsList from 'components/AssignmentsList';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { refreshRate } from 'constants/AppConstants';
import Routes from 'constants/Routes';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import useUpdateInterval from 'hooks/useUpdateInterval';
import SendCasesToFieldTestersModal from 'pages/Cases/SendCasesToFieldTestersModal';
import { FC, useCallback, useMemo, useState } from 'react';
import {
  generatePath,
  Outlet,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { RequestStatus, useResponse } from 'swaggerhooks/lib';
import assignmentsSearchFunction from 'utils/old_assignmentsSearchFunction';
import PendingAssignmentsFilterBar from './PendingAssignmentsFilterBar';
import useToFromAddressAssignmentFilter from './useToFromAddresAssignmentFilter';
import { assignmentAddressesMatches } from './utils';
import Roles from 'constants/Roles';
import useMe from 'contexts/authentication/useMe';
import useAssignmentsBulkOperations from 'pages/GlobalSearch/useAssignmentsBulkOperations';
import BulkMessages from 'pages/GlobalSearch/BulkMessages';
import BulkOperations from 'pages/GlobalSearch/BulkOperations';

const PendingAssignmentsPage: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const {
    state: {
      visibleStatuses,
      visibleBusinessUnits,
      searchText,
      triggerAssignmentsUpdate,
    },
  } = useAssignmentsFilters();
  const toFromAddressFilter = useToFromAddressAssignmentFilter();
  const me = useMe();
  const userIsGoSupport = me?.roles.includes(Roles.GoSupport);

  const [showSendToFieldTestersModal, setShowSendToFieldTestersModal] =
    useState(false);

  const furtherTransportAssignments = useResponse(
    AssignmentClient,
    (c) => c.getFurtherTransportAssignments(),
    [triggerAssignmentsUpdate]
  );

  useUpdateInterval(
    () => furtherTransportAssignments.update(),
    refreshRate,
    true
  );

  const filteredAssignments = useMemo(
    () =>
      assignmentAddressesMatches(
        assignmentsSearchFunction(
          furtherTransportAssignments.response ?? [],
          searchText,
          visibleStatuses,
          visibleBusinessUnits,
          location.pathname
        ),
        toFromAddressFilter.selectedFromCompany,
        toFromAddressFilter.selectedToCompany
      ),
    [
      furtherTransportAssignments.response,
      location.pathname,
      searchText,
      toFromAddressFilter.selectedFromCompany,
      toFromAddressFilter.selectedToCompany,
      visibleBusinessUnits,
      visibleStatuses,
    ]
  );

  const handleAssignmentUpdated = useCallback(async () => {
    furtherTransportAssignments.update();
  }, [furtherTransportAssignments]);

  const handleAssignmentItemClick = useCallback(
    (model: AssignmentViewModel) => {
      navigate(
        generatePath(Routes.pendingAssignments.case, {
          id: String(model.case.caseID),
        })
      );
    },
    [navigate]
  );

  const assignmentsBulkOperations = useAssignmentsBulkOperations(
    filteredAssignments ?? [],
    () => handleAssignmentUpdated()
  );

  const bulkMessagesButton = userIsGoSupport ? (
    <BulkMessages bulkOperations={assignmentsBulkOperations} />
  ) : null;

  if (!furtherTransportAssignments.response) return <LoadingSpinner />;

  return (
    <>
      <Outlet />
      <PendingAssignmentsFilterBar
        fromToFilter={toFromAddressFilter}
        onSendToFieldTestersClick={() => setShowSendToFieldTestersModal(true)}
      />
      {!!filteredAssignments.length && userIsGoSupport && (
        <BulkOperations
          bulkActions={[bulkMessagesButton]}
          bulkOperations={assignmentsBulkOperations}
          handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
            handleAssignmentItemClick(model)
          }
        />
      )}
      <AssignmentsList
        assignments={filteredAssignments}
        bulkOperations={assignmentsBulkOperations}
        loading={furtherTransportAssignments.status === RequestStatus.Fetching}
        onAssignmentItemClick={handleAssignmentItemClick}
        onAssignmentUpdated={handleAssignmentUpdated}
        showStatusColors={visibleStatuses === null}
      />

      {showSendToFieldTestersModal && (
        <SendCasesToFieldTestersModal
          onAssignmentsSent={() => {
            setShowSendToFieldTestersModal(false);
          }}
          onCancel={() => setShowSendToFieldTestersModal(false)}
        />
      )}
    </>
  );
};

export default PendingAssignmentsPage;
