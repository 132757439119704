const detectLinksAndSplitText = (text: string) => {
  // Improved regex to capture URLs more accurately, even when followed by non-space characters like punctuation
  const urlPattern =
    /(\bhttps?:\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gi;
  const parts = [];
  let match;

  // Use regex exec method to find all matches and push surrounding text and urls to parts array
  let lastIndex = 0;
  do {
    match = urlPattern.exec(text);
    if (match !== null) {
      // Push preceding text if it exists
      if (lastIndex < match.index) {
        parts.push({
          type: 'text',
          content: text.substring(lastIndex, match.index),
          key: `text-${lastIndex}`,
        });
      }
      // Push URL
      parts.push({
        type: 'link',
        content: match[0],
        key: `link-${match.index}`,
      });
      lastIndex = match.index + match[0].length;
    }
  } while (match !== null);
  // Push remaining text after the last URL
  if (lastIndex < text.length) {
    parts.push({
      type: 'text',
      content: text.substring(lastIndex),
      key: `text-${lastIndex}`,
    });
  }

  return parts;
};

export default {
  detectLinksAndSplitText,
};
