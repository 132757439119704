import {
  ITranslationLookups,
  ZipCodeLocalAreaClient,
  ZipCodeLocalAreaModel,
} from 'api';

import Button from 'components/inputs/Button';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import Table from 'components/Table';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import { FC } from 'react';
import styled from 'styled-components';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks/lib';
import ZipCodeLocalAreaFormModal from './ZipCodeLocalAreaFormModal';
import ZipCodeRule from './ZipCodeRule';

const Wrapper = styled.div``;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

const ZipCodeAreasPage: FC = () => {
  const modalStack = useModalStack();
  const translations = useTranslations();

  const zipCodeAreas = useResponse(ZipCodeLocalAreaClient, (client) =>
    client.getZipCodeLocalAreas()
  );

  const syncWithEmployeesCall = useApiCall(ZipCodeLocalAreaClient, (client) =>
    client.syncZipCodeLocalAreasWithEmployees()
  );

  const columnSettings: ColumnSetting<
    ZipCodeLocalAreaModel,
    ITranslationLookups
  >[] = [
    {
      head: 'Namn',
      render: (z) => z.name,
      width: 150,
      sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
    },
    {
      head: 'Regler',
      render: (z) =>
        z.zipCodeRules.map((r) => <ZipCodeRule key={r.id} rule={r} />),
      width: 150,
    },
  ];
  const closeModalAndRefresh = () => {
    modalStack.pop();
    zipCodeAreas.update();
  };

  const renderContent = () => {
    switch (zipCodeAreas.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetched:
      case RequestStatus.Error: {
        return (
          <MyTable
            columnSettings={columnSettings}
            onRowClick={(r) => {
              modalStack.push(
                <ZipCodeLocalAreaFormModal
                  area={r}
                  canEdit
                  onCancel={closeModalAndRefresh}
                  onSaved={closeModalAndRefresh}
                />
              );
            }}
            renderProps={translations}
            rows={zipCodeAreas.response ?? []}
            useColumnWidthAsFlexAmount
          />
        );
      }
      case RequestStatus.Fetching:
      default:
        return <LoadingSpinner>Hämtar...</LoadingSpinner>;
    }
  };

  const handleAddArea = () => {
    modalStack.push(
      <ZipCodeLocalAreaFormModal
        canEdit
        onCancel={closeModalAndRefresh}
        onSaved={closeModalAndRefresh}
      />
    );
  };

  const handleSyncWithEmployees = () => {
    syncWithEmployeesCall.run();
  };

  return (
    <Wrapper>
      <Actions>
        <Button
          disabled={zipCodeAreas.status === RequestStatus.Fetching}
          onClick={handleAddArea}
        >
          + Nytt Område
        </Button>
        <Button
          disabled={syncWithEmployeesCall.status === RequestStatus.Fetching}
          onClick={handleSyncWithEmployees}
        >
          {syncWithEmployeesCall.status === RequestStatus.Fetching ? (
            <TransparentSpinner small />
          ) : (
            <span>Synka med personallistan</span>
          )}
        </Button>
      </Actions>

      {renderContent()}
    </Wrapper>
  );
};

export default ZipCodeAreasPage;
