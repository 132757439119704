import {
  FilterButton,
  FilterPickerContainer,
} from '../../components/filterBars';
import {
  selectableAssignmentRequestsFiltersByLabel,
  useAssignmentRequestsFilters,
} from 'pages/InternalDeliveryGroup/Requests/components/useAssignmentRequestFilters';

export const selectableFiltersByLabel = {} as const;

const AssignmentRequestsFilterPicker: React.FC = () => {
  const { selectedStatusView, setSelectedStatusView } =
    useAssignmentRequestsFilters();

  return (
    <FilterPickerContainer>
      {Object.entries(selectableAssignmentRequestsFiltersByLabel).map(
        ([label, filterSetting]) => (
          <FilterButton
            key={label}
            onClick={() => {
              setSelectedStatusView(filterSetting.filterEnum);
            }}
            selected={selectedStatusView === filterSetting.filterEnum}
            type="button"
          >
            <span>{label}</span>
          </FilterButton>
        )
      )}
    </FilterPickerContainer>
  );
};

export default AssignmentRequestsFilterPicker;
