/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  AppliesToWeek,
  DayOfWeek,
  DefaultScheduleDto,
  DefaultWorkDayDto,
} from 'api';
import Button from 'components/inputs/Button';
import DatePicker from 'components/inputs/DatePicker';
import Input from 'components/inputs/Input';
import Select from 'components/inputs/Select';
import useTranslations from 'contexts/basicData/useTranslations';
import {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';
import { formatTime } from 'utils/date-helpers';
import {
  ScheduleErrors,
  weekdays,
} from '../../components/Availability/helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { DeletableDefaultScheduleDto } from './UserFormModal';

const DatePickerClassName = 'date_picker';
const FullWidthClassName = 'full-width';

const DatePickerWrapperStyles = createGlobalStyle`
    .${DatePickerClassName}.${FullWidthClassName} {
        width: auto !important;
        display: block;

    }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  gap: 3px;
  align-items: center;
  margin-bottom: 3px;
`;

const AlignTopRow = styled(Row)`
  align-items: flex-start;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: flex-start;
  gap: 3px;
`;

const Label = styled.div`
  display: block;
  width: 100px;
`;

const InputButton = styled.button<{
  errorFound?: boolean;
}>`
  width: auto !important;
  min-width: 100%;
  height: 35px;
  text-align: center;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-right: 4px;
  border: ${({ errorFound }) => (errorFound ? '1px solid red' : 'none')};
  border-radius: 3px;
  flex: 2;
`;

const ResetButton = styled.div<{ disabled?: boolean }>`
  display: block;
  position: absolute;
  text-align: center;
  cursor: pointer;
  height: 16px;
  width: 16px;
  right: 3px;
  top: 3px;
  color: ${({ theme }) => theme.colors.background.tertiary};

  ${({ disabled }) =>
    !disabled &&
    css`
      &:hover {
        right: 3px;
        top: 2px;
        border: 1px solid ${({ theme }) => theme.colors.border.light};
        border-radius: 3px;
        color: ${({ theme }) => theme.colors.background.button};
      }
    `}
`;

const CustomInput = forwardRef((props: any, ref) => (
  <InputButton {...props} ref={ref} type="button">
    {props.value}
  </InputButton>
));

type WeeklyScheduleProps = {
  week: DeletableDefaultScheduleDto;
  canEdit?: boolean;
  index: number;
  errors: ScheduleErrors | undefined;
  setWeek: (newWeek: DeletableDefaultScheduleDto) => void;
};

const WeeklySchedule: FC<WeeklyScheduleProps> = ({
  canEdit,
  week,
  index,
  errors,
  setWeek,
}) => {
  const [appliesToWeekNumbers, setAppliesToWeekNumbers] = useState<
    number[] | undefined
  >(week.appliesToWeekNumbers);
  const [appliesToWeeks, setAppliesToWeeks] = useState<
    AppliesToWeek | undefined
  >(
    week.appliesToWeekNumbers === undefined
      ? undefined
      : week.appliesToWeekNumbers.every((e) => Number(e) % 2 === 1)
        ? AppliesToWeek.ODD_WEEKS
        : AppliesToWeek.EVEN_WEEKS
  );

  const { scheduleAppliesToWeeks } = useTranslations();
  const inputRef = useRef(null);

  const { watch, setValue } = useForm<DefaultScheduleDto>({
    defaultValues: {
      appliesToWeekNumbers: week.appliesToWeekNumbers,
      schedule: week.schedule,
    },
  });

  const scheduleWatch = watch('schedule');
  const appliesToWeekNumbersWatch = watch('appliesToWeekNumbers');
  // update parent when any value changes
  useEffect(() => {
    const newWeek = {
      ...week,
      appliesToWeekNumbers: appliesToWeekNumbersWatch,
      schedule: scheduleWatch,
    } as DefaultScheduleDto;
    setWeek(newWeek);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliesToWeekNumbersWatch, scheduleWatch]);

  const handleAppliesToWeekChange = (appliesToWeek: AppliesToWeek) => {
    setAppliesToWeeks(appliesToWeek);
    switch (appliesToWeek) {
      case AppliesToWeek.EVEN_WEEKS: {
        const evenWeekNumbers: number[] = [];
        for (let i = 1; i < 54; i += 1) {
          if (i % 2 === 0) {
            evenWeekNumbers.push(i);
          }
        }

        setWeek({
          ...week,
          appliesToWeekNumbers: evenWeekNumbers,
        } as DefaultScheduleDto);
        setAppliesToWeekNumbers(evenWeekNumbers);
        break;
      }

      case AppliesToWeek.ODD_WEEKS: {
        const oddWeekNumbers: number[] = [];
        for (let i = 1; i < 54; i += 1) {
          if (i % 2 === 1) {
            oddWeekNumbers.push(i);
          }
        }

        setWeek({
          ...week,
          appliesToWeekNumbers: oddWeekNumbers,
        } as DefaultScheduleDto);
        setAppliesToWeekNumbers(oddWeekNumbers);
        break;
      }

      default: {
        break;
      }
    }
  };

  const renderPickers = () => {
    const jsx: JSX.Element[] = [];
    for (let i = 0; i < 7; i += 1) {
      jsx.push(
        <Row key={i}>
          <DatePickerWrapperStyles />
          <Label>{weekdays[i].label}</Label>
          <div style={{ flex: 2, position: 'relative' }}>
            <DatePicker
              customInput={
                <CustomInput
                  errorFound={errors?.errors?.some(
                    (e) => e.day === weekdays[i].dayOfWeek && e.missingStart
                  )}
                  inputRef={inputRef}
                />
              }
              dateFormat="HH:mm"
              disabled={!canEdit || week.deleted}
              locale="sv"
              maxTime={
                watch(`schedule.${i}.end`) ?? new Date(0, 0, 0, 23, 59, 0)
              }
              minTime={new Date(0, 0, 0, 0, 0, 0)}
              onChange={(date) =>
                setValue(`schedule.${i}.start`, date ?? undefined)
              }
              selected={watch(`schedule.${i}.start`)}
              shouldCloseOnSelect
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Från"
              timeFormat="HH:mm"
              timeIntervals={15}
              wrapperClassName={`${DatePickerClassName} ${FullWidthClassName}`}
            />
            {watch(`schedule.${i}.start`) && (
              <ResetButton
                onClick={() => setValue(`schedule.${i}.start`, undefined)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ alignSelf: 'center' }}
                />
              </ResetButton>
            )}
          </div>
          <div style={{ flex: 2, position: 'relative' }}>
            <DatePicker
              customInput={
                <CustomInput
                  errorFound={errors?.errors?.some(
                    (e) => e.day === weekdays[i].dayOfWeek && e.missingEnd
                  )}
                  inputRef={inputRef}
                />
              }
              dateFormat="HH:mm"
              disabled={!canEdit || week.deleted}
              injectTimes={[new Date(0, 0, 0, 23, 59, 0)]}
              locale="sv"
              maxTime={new Date(0, 0, 0, 23, 59, 0)}
              minTime={
                new Date(
                  0,
                  0,
                  0,
                  watch(`schedule.${i}.start`)?.getHours() ?? 0,
                  (watch(`schedule.${i}.start`)?.getMinutes() ?? 0) + 1,
                  0
                )
              }
              onChange={(date) =>
                setValue(`schedule.${i}.end`, date ?? undefined)
              }
              selected={watch(`schedule.${i}.end`)}
              shouldCloseOnSelect
              showTimeSelect
              showTimeSelectOnly
              timeCaption="Till"
              timeFormat="HH:mm"
              timeIntervals={15}
              wrapperClassName={`${DatePickerClassName} ${FullWidthClassName}`}
            />
            {watch(`schedule.${i}.end`) && (
              <ResetButton
                onClick={() => setValue(`schedule.${i}.end`, undefined)}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  style={{ alignSelf: 'center' }}
                />
              </ResetButton>
            )}
          </div>
          <div>
            <Input
              disabled={!canEdit || week.deleted}
              onChange={(e) => {
                setValue(`schedule.${i}.comment`, e.currentTarget.value);
              }}
              style={{ flex: 3 }}
              value={watch(`schedule.${i}.comment`)}
            />
          </div>
        </Row>
      );
    }

    return jsx;
  };

  return (
    <Container>
      {renderPickers()}
      <Row>
        {index !== 0 && (
          <Select
            disabled={!canEdit || week.deleted}
            onChange={(e) =>
              handleAppliesToWeekChange(Number(e.currentTarget.value))
            }
            value={appliesToWeeks}
          >
            {Object.keys(AppliesToWeek)
              .filter((e) => !Number.isNaN(Number(e)))
              .map((key) => (
                <option key={key} value={Number(key)}>
                  {scheduleAppliesToWeeks[key as keyof typeof AppliesToWeek]}
                </option>
              ))}
          </Select>
        )}
      </Row>
    </Container>
  );
};

export default WeeklySchedule;
