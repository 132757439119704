import { FC } from 'react';

interface Props {
  name: string;
  address: string;
  city: string;
  zipCode: string;
  county?: string;
}

const FormattedAddress: FC<Props> = ({
  name,
  address,
  city,
  zipCode,
  county,
}) => (
  <>
    <b>{name}</b>, {address}, {zipCode} {city}
    {county && ` - ${county}`}
  </>
);

export default FormattedAddress;
