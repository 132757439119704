import { useAssignmentRequestsGetAssignmentRequest } from 'api/assignment-requests/assignment-requests';
import AssignmentRequestModal from './AssignmentRequestModal';
import { useNavigate, useParams } from 'react-router-dom';
import useAssignmentRequestsSearch from 'contexts/assignmentsFilters/useAssignmentRequestsSearch';
import Modal from 'components/Modal';
import Routes from 'constants/Routes';
import { useQueryClient } from '@tanstack/react-query';
import LoadingSpinner from 'components/spinners/LoadingSpinner';

interface Props {
  navigatesBackTo?: string;
}
const AssignmentRequestPage: React.FC<Props> = ({ navigatesBackTo }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const params = useParams<{ id: string }>();
  const assignmentRequestId = Number(params.id);
  const { refresh: refreshAssignmentSearch } = useAssignmentRequestsSearch();
  const assignmentRequest = useAssignmentRequestsGetAssignmentRequest(
    {
      id: assignmentRequestId,
    },
    {
      query: {
        enabled: !!assignmentRequestId,
      },
    }
  );
  const renderBody = () => {
    switch (assignmentRequest.status) {
      case 'success':
        return (
          <AssignmentRequestModal
            request={assignmentRequest.data}
            onClose={() => {
              if (navigatesBackTo) {
                navigate(navigatesBackTo);
              }
            }}
            onUpdated={async () => {
              await queryClient.resetQueries(assignmentRequest.queryKey);
              await assignmentRequest.refetch();
              await refreshAssignmentSearch();
            }}
          />
        );

      case 'loading': {
        return <LoadingSpinner />;
      }

      case 'error':
        return (
          <Modal
            closeOnOutsideClick={false}
            onClose={() =>
              navigate(
                Routes.internalDeliveryGroup.requests as unknown as string
              )
            }
            title="Annons-förfrågan"
          >
            Något gick fel.
          </Modal>
        );

      default:
        return null;
    }
  };

  return renderBody();
};

export default AssignmentRequestPage;
