export const makeGoogleMapsComputeRoutesApiCall = async (
  locations: google.maps.LatLng[]
): Promise<ComputeRoutesResponse> => {
  if (!locations || locations.length < 2) {
    return {
      success: false,
    };
  }

  const waypoints: {
    location: { latLng: { latitude: number; longitude: number } };
  }[] = [];
  locations.forEach((location, index) => {
    if (!(index === 0 || index === locations.length - 1)) {
      waypoints.push({
        location: {
          latLng: {
            latitude: location.lat(),
            longitude: location.lng(),
          },
        },
      });
    }
  });

  const body = {
    origin: {
      location: {
        latLng: {
          latitude: locations[0].lat(),
          longitude: locations[0].lng(),
        },
      },
    },
    destination: {
      location: {
        latLng: {
          latitude: locations[locations.length - 1].lat(),
          longitude: locations[locations.length - 1].lng(),
        },
      },
    },
    intermediates: waypoints,
    travelMode: 'DRIVE',
    routingPreference: 'TRAFFIC_AWARE',
    routeModifiers: {
      avoidTolls: false,
      avoidHighways: false,
      avoidFerries: false,
    },
    languageCode: 'sv-SE',
    units: 'METRIC',
  };

  const response = await fetch(
    'https://routes.googleapis.com/directions/v2:computeRoutes',
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-Goog-Api-Key': 'AIzaSyCBUtx73pL8IJgyM7ELhPSBFYvxjHZnuTY',
        'X-Goog-FieldMask': 'routes.duration,routes.polyline.encodedPolyline',
      },
      body: JSON.stringify(body),
    }
  );

  if (!response.ok) {
    // console.log('api call to google maps failed');
  }

  const data = await response.json();
  const { encodedPolyline } = data.routes[0].polyline;

  return {
    success: true,
    encodedPolyline,
  };
};

export type ComputeRoutesResponse = {
  success: boolean;
  encodedPolyline?: string;
};
