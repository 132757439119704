import { AssignmentDeviationEnum, AssignmentStatusEnum } from 'api';
import {
  FilterButton,
  FilterPickerContainer,
  selectableFiltersByLabel,
} from 'components/filterBar/FilterPicker';
import { getInitialDateRange } from 'contexts/assignmentSearch/types';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import { FC, useCallback } from 'react';

const SearchFilterPicker: FC = () => {
  const {
    state: { assignmentStatuses, caseStatuses },
    setAssignmentStatuses,
    setAssignmentDeviations,
    setCaseStatuses,
    setDateRange,
    stateEqualsFilterPreset,
  } = useAssignmentSearch();

  const handleFilterPicked = useCallback(
    (
      statuses: Set<AssignmentStatusEnum>,
      deviations: Set<AssignmentDeviationEnum>
    ) => {
      if (stateEqualsFilterPreset(selectableFiltersByLabel.Slutförda)) {
        setDateRange(getInitialDateRange());
      }

      if (statuses === selectableFiltersByLabel.Slutförda.statuses) {
        const now = new Date();
        const oneMonthAgo = new Date(
          now.getFullYear(),
          now.getMonth() - 1,
          now.getDate()
        );
        setDateRange([oneMonthAgo, now]);
      }

      setAssignmentStatuses(Array.from(statuses));
      setAssignmentDeviations(Array.from(deviations));
      setCaseStatuses([]);
    },
    [
      setAssignmentDeviations,
      setAssignmentStatuses,
      setCaseStatuses,
      setDateRange,
      stateEqualsFilterPreset,
    ]
  );

  return (
    <FilterPickerContainer>
      <FilterButton
        onClick={() => handleFilterPicked(new Set(), new Set())}
        selected={assignmentStatuses.length === 0 && caseStatuses.length === 0}
      >
        Alla
      </FilterButton>

      {Object.entries(selectableFiltersByLabel).map(
        ([label, { statuses, deviations }]) => (
          <FilterButton
            key={label}
            onClick={() => handleFilterPicked(statuses, deviations)}
            selected={stateEqualsFilterPreset({ statuses, deviations })}
            type="button"
          >
            {label}
          </FilterButton>
        )
      )}
    </FilterPickerContainer>
  );
};

export default SearchFilterPicker;
