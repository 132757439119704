import {
  faArrowUp,
  faCarSide,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea } from 'api/assignment/assignment';
import {
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  AssignmentViewModel,
  CaseModel,
  DrivingPolicyReportDto,
} from 'api/model';
import { useSetupGetBasicData } from 'api/setup/setup';
import TextButton from 'components/inputs/TextButton';
import Modal from 'components/Modal';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useCompanies from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { openGoogleMapsDirections } from 'utils/address-helper';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';
import { showException } from 'utils/exception-helper';
import { makeNumberComparator, makeStringComparator } from 'utils/sorting';
import AssignmentActionDropdown from './AssignmentActionDropdown';
import { useUserGetDrivingPolicyReportForUnPlannedAssignmentHook } from 'api/user/user';
import Checkbox from 'components/inputs/Checkbox';

const ModalTextButton = styled(TextButton)`
  text-align: left;
  padding: 0px;
`;

const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

const Center = styled.div`
  display: flex;
  justify-content: center;
`;

const Span = styled.span`
  white-space: wrap;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const InvalidRowClassName = 'invalid-row';

const TableStylerWrapper = styled.div`
  display: contents;

  .${InvalidRowClassName} {
    color: ${({ theme }) => theme.colors.foreground.error};
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

type AssignmentViewModelWithDrivingPolicy = {
  drivingPolicy?: DrivingPolicyReportDto;
  assignment: AssignmentModel;
  case: CaseModel;
};

const getAssignmentPriority = (assignment: AssignmentViewModel) => {
  // assignmenttype  Normal or WithTest is Prio 1
  // assignmenttype FurtherTransport is Prio 2

  if (
    (
      [
        AssignmentTypeEnum.Normal,
        AssignmentTypeEnum.WithTest,
      ] as AssignmentTypeEnum[]
    ).includes(assignment.assignment.assignmentTypeID)
  ) {
    return 1;
  }

  if (
    assignment.assignment.assignmentTypeID ===
    AssignmentTypeEnum.FurtherTransport
  ) {
    return 2;
  }

  return 3;
};

const getAssignmentPriorityIcon = (prio: number): React.ReactNode => {
  switch (prio) {
    case 1:
      return (
        <IconWrapper>
          <FontAwesomeIcon color="red" icon={faArrowUp} />
        </IconWrapper>
      );

    default:
      return <Center>-</Center>;
  }
};

type ReturnAssignmentFinderProps = {
  assignmentModel: AssignmentModel;
  caseModel: CaseModel;
  drivingPolicyReport?: DrivingPolicyReportDto;
};

type ReturnAssignmentFinderModalProps = ReturnAssignmentFinderProps & {
  handleClose: () => void;
};

const ReturnAssignmentFinderModal: React.FC<
  ReturnAssignmentFinderModalProps
> = ({ assignmentModel, caseModel, drivingPolicyReport, handleClose }) => {
  const { companiesByAddrDict } = useCompanies();
  const basicdata = useSetupGetBasicData();
  const consecutiveHoursOfRestLast24Hours =
    basicdata.data?.consecutiveHoursOfRestLast24Hours ?? 0;
  const workHoursToday = drivingPolicyReport?.workHoursToday ?? 0;
  const { caseTypes, assignmentTypes } = useTranslations();
  const getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall =
    useAssignmentGetUnassignedPurchasesAndFurtherTransportAssignmentsByArea();

  const [assignmentsWithDrivingPolicy, setAssignmentsWithDrivingPolicy] =
    useState<AssignmentViewModelWithDrivingPolicy[]>([]);
  const [showOnlyAssignable, setShowOnlyAssignable] = useState<boolean>(true);

  const getDrivingPolicyForUplannedAssignmentCall =
    useUserGetDrivingPolicyReportForUnPlannedAssignmentHook();

  const searchAssignments = useCallback(async () => {
    try {
      await getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.mutateAsync(
        {
          params: {
            zipCode: assignmentModel.toZip,
            zipCodeAreaId: assignmentModel.toCounty?.id,
            approximateStartTime: assignmentModel.endTime,
          },
        }
      );
    } catch (error) {
      showException(error);
    }
  }, [
    assignmentModel.endTime,
    assignmentModel.toCounty?.id,
    assignmentModel.toZip,
    getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall,
  ]);

  useEffect(() => {
    searchAssignments();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showOnlyAssignable]);

  useEffect(() => {
    const fetchDrivingPolicy = async () => {
      // for each assignment, get the driving policy async
      const result = await Promise.all(
        getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.data?.map(
          async (a) => {
            if (
              !a.assignment.estimatedDuration ||
              !a.assignment.bookedTo ||
              !a.assignment.estimatedStartTime
            ) {
              return {
                ...a,
              };
            }

            // as these are stopovers on their way home, we can base the times on the end time of our current assignment
            const usedStartTime = new Date(
              assignmentModel.estimatedStartTime!.getTime() +
                assignmentModel.estimatedDuration!
            );
            const estimatedDuration = a.assignment.estimatedDuration!;
            const usedEndTime = new Date(
              usedStartTime.getTime() + estimatedDuration
            );
            const drivingPolicy =
              await getDrivingPolicyForUplannedAssignmentCall({
                userId: assignmentModel.assignedTo!.userID!,
                bookedTo: assignmentModel.bookedTo,
                startOrEstimatedStartTime: usedStartTime,
                endOrEstimatedEndTime: usedEndTime,
                estimatedDuration,
              });

            return {
              ...a,
              drivingPolicy,
            };
          }
        ) ?? []
      );
      setAssignmentsWithDrivingPolicy(result);
    };

    if (getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.data) {
      fetchDrivingPolicy();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.data]);

  const goToCase = useCallback((caseID: number) => {
    // open the case in a new tab with the caseID
    window.open(`/sok/${caseID}`, caseID.toString());
  }, []);

  const getWorkTimeIncludingNewAssignment = useCallback(
    (assignment: AssignmentModel) => {
      // get the current estimated duration in hours (default to 0)
      if (!assignment.estimatedDuration) {
        return undefined;
      }
      const estimatedDuration = assignment.estimatedDuration!;
      const alreadyUsedTime = workHoursToday * 60 * 60 * 1000;

      return estimatedDuration + alreadyUsedTime;
    },
    [workHoursToday]
  );

  const getTimeLeftIncludingNewAssignment = useCallback(
    (assignment: AssignmentModel) => {
      // get the current estimated duration in hours (default to 0)
      if (!assignment.estimatedDuration) {
        return undefined;
      }
      const estimatedDuration = assignment.estimatedDuration!;
      const totalTimeAllowedToday =
        (24 - consecutiveHoursOfRestLast24Hours) * 60 * 60 * 1000;
      const alreadyUsedTime = workHoursToday * 60 * 60 * 1000;

      const timeLeftOfDay =
        totalTimeAllowedToday - estimatedDuration - alreadyUsedTime;

      return timeLeftOfDay;
    },
    [consecutiveHoursOfRestLast24Hours, workHoursToday]
  );

  const orderedByDistanceAndFilteredByDuration = useMemo(() => {
    // sort by zipcode proximity to assignment.toZip
    try {
      const startZip = Number(assignmentModel.toZip.replace(/\D/g, ''));

      const filteredData = showOnlyAssignable
        ? [...assignmentsWithDrivingPolicy].filter((a) => {
            if (!a.assignment.estimatedDuration || !a.drivingPolicy?.canAdd) {
              return false;
            }
            return true;
          })
        : [...assignmentsWithDrivingPolicy];

      const orderedData = filteredData.sort((a, b) => {
        // const durationA = a.assignment.estimatedDuration ?? 0;
        // const durationB = b.assignment.estimatedDuration ?? 0;
        const zipA = Number(a.assignment.fromZip.replace(/\D/g, ''));
        const zipB = Number(b.assignment.fromZip.replace(/\D/g, ''));
        return Math.abs(zipA - startZip) - Math.abs(zipB - startZip);
      });
      return orderedData ?? [];
    } catch (error) {
      return [];
    }
  }, [assignmentModel.toZip, assignmentsWithDrivingPolicy, showOnlyAssignable]);

  const columnSettings = useMemo((): ColumnSetting<
    AssignmentViewModelWithDrivingPolicy,
    any
  >[] => {
    return [
      {
        head: 'Dubblett?',
        render: (a) => {
          const isDuplicate =
            orderedByDistanceAndFilteredByDuration?.filter(
              (x) => x.case.caseID === a.case.caseID
            ).length > 1;
          return isDuplicate ? 'Ja' : 'Nej';
        },
        width: 100,
      },
      {
        head: 'Prio',
        render: (a) => getAssignmentPriorityIcon(getAssignmentPriority(a)),
        width: 50,
        sortFunction: makeNumberComparator((a) => getAssignmentPriority(a)),
      },
      {
        head: 'Regnr',
        render: (a) => a.case.registrationNumber,
        width: 100,
        sortFunction: makeStringComparator((a) => a.case.registrationNumber),
      },
      {
        head: 'Estimerad tid',
        render: (a) =>
          a.assignment.estimatedDuration
            ? getHoursAndMinutesFromMillisecondsString(
                a.assignment.estimatedDuration
              )
            : '–',
        width: 100,
        sortFunction: makeNumberComparator(
          (a) => a.assignment.estimatedDuration ?? 0
        ),
      },
      {
        head: 'Total tid inkl uppdraget',
        render: (a) => {
          const workTime = getWorkTimeIncludingNewAssignment(a.assignment);
          return workTime
            ? getHoursAndMinutesFromMillisecondsString(workTime)
            : '–';
        },
        width: 200,
        sortFunction: makeNumberComparator(
          (a) => getTimeLeftIncludingNewAssignment(a.assignment) ?? 0
        ),
      },
      {
        head: 'Tid kvar inkl uppdraget',
        render: (a) => {
          const timeLeft = getTimeLeftIncludingNewAssignment(a.assignment);
          return timeLeft
            ? getHoursAndMinutesFromMillisecondsString(timeLeft)
            : '-';
        },
        width: 200,
        sortFunction: makeNumberComparator(
          (a) => getTimeLeftIncludingNewAssignment(a.assignment) ?? 0
        ),
      },
      {
        head: 'Ärendetyp',
        render: (a) => caseTypes[a.case.caseTypeID],
        width: 100,
        sortFunction: makeStringComparator(
          (a) => caseTypes[a.case.caseTypeID] ?? ''
        ),
      },
      {
        head: 'Uppdragstyp',
        render: (a) => assignmentTypes[a.assignment.assignmentTypeID],
        width: 120,
        sortFunction: makeStringComparator(
          (a) => assignmentTypes[a.assignment.assignmentTypeID] ?? ''
        ),
      },
      {
        head: 'Från',
        render: (a) => {
          const addressKey =
            `${a.assignment.fromAddress}${a.assignment.fromZip}${a.assignment.fromCity}`
              .toLowerCase()
              .replaceAll(' ', '');
          const addressString =
            companiesByAddrDict.get(addressKey)?.name ??
            `${a.assignment.fromAddress}, ${a.assignment.fromZip}, ${a.assignment.fromCity}`;
          return <Span>{addressString}</Span>;
        },
        width: 200,
        sortFunction: makeStringComparator((a) => a.assignment.fromAddress),
      },
      {
        head: 'Bryter körtidspolicy',
        render: (a) => {
          if (!a.drivingPolicy) {
            return '-';
          }
          return (
            <>
              <div
                style={{
                  color: a.drivingPolicy.canAdd ? 'green' : 'red',
                }}
              >
                {a.drivingPolicy.canAdd ? 'Nej' : 'Ja'}
              </div>
              {!a.drivingPolicy.hasConsecutiveHoursOfRestLast24Hours && (
                <span>24h</span>
              )}
              {!a.drivingPolicy.hasConsecutiveHoursOfRestThisWeek && (
                <span>Vecka</span>
              )}
            </>
          );
        },
        width: 200,
      },
      {
        head: '',
        render: (a) => (
          <TextButton
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              openGoogleMapsDirections({
                fromAddress: assignmentModel.toAddress,
                fromCity: assignmentModel.toCity,
                fromZip: assignmentModel.toZip,
                toAddress: a.assignment.fromAddress,
                toCity: a.assignment.fromCity,
                toZip: a.assignment.fromZip,
              });
            }}
          >
            <img
              alt="Google maps icon"
              height={15}
              src="/images/googlemapsiconsmall.png"
              width={15}
            />
          </TextButton>
        ),
        width: 40,
      },
      {
        head: '',
        render: (a) => (
          <TextButton onClick={() => goToCase(a.case.caseID)}>
            <FontAwesomeIcon icon={faExternalLinkAlt} />
          </TextButton>
        ),
        width: 40,
      },
      {
        head: '',
        render: (a) => (
          <AssignmentActionDropdown
            currentAssignment={{
              assignment: assignmentModel,
              case: caseModel,
            }}
            selectedAssignment={{
              assignment: a.assignment,
              case: a.case,
            }}
            onUpdated={() => {
              searchAssignments();
            }}
          />
        ),
        width: 40,
      },
    ];
  }, [
    assignmentModel,
    assignmentTypes,
    caseModel,
    caseTypes,
    companiesByAddrDict,
    getTimeLeftIncludingNewAssignment,
    getWorkTimeIncludingNewAssignment,
    goToCase,
    orderedByDistanceAndFilteredByDuration,
    searchAssignments,
  ]);

  return (
    <Modal
      buttons={[
        {
          label: 'Stäng',
          onClick: handleClose,
        },
      ]}
      closeOnOutsideClick={false}
      onClose={handleClose}
      title="Välj returuppdrag"
    >
      <ContentWrapper>
        {getUnassignedPurchasesAndFurtherTransportAssignmentsByAreaCall.isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <Header>
              Möjliga returuppdrag från Zäkra Hub inom länet för{' '}
              {caseModel.registrationNumber}
              <Checkbox
                style={{ marginLeft: 'auto' }}
                checked={showOnlyAssignable}
                onChange={(e) => setShowOnlyAssignable(e.currentTarget.checked)}
              >
                Visa endast uppdrag som kan utföras utan ändringar
              </Checkbox>
            </Header>
            {orderedByDistanceAndFilteredByDuration?.length === 0 ? (
              <span>Inga returuppdrag hittades</span>
            ) : (
              <TableStylerWrapper>
                <Table
                  rows={orderedByDistanceAndFilteredByDuration}
                  // onRowClick={(row) => goToCase(row.case.caseID)}
                  columnSettings={columnSettings}
                  rowClassName={(row) => {
                    let rowClassName = '';
                    if (row.assignment.estimatedDuration === null) {
                      rowClassName = InvalidRowClassName;
                    }
                    const isWithinTime =
                      (getTimeLeftIncludingNewAssignment(row.assignment) ?? 0) >
                      0;
                    rowClassName = isWithinTime ? '' : InvalidRowClassName;

                    return `${rowClassName} ${row.assignment.assignmentID}`;
                  }}
                  initialSortState={{
                    ascending: true,
                    sortedColumnIndex: 0,
                  }}
                />
              </TableStylerWrapper>
            )}
          </>
        )}
      </ContentWrapper>
    </Modal>
  );
};

const ReturnAssignmentFinder: React.FC<ReturnAssignmentFinderProps> = (
  props
) => {
  const modalStack = useModalStack();
  const { assignmentModel } = props;
  if (assignmentModel.assignmentStatusID > AssignmentStatusEnum.Accepted) {
    return null; // no need to show the modal if the assignment is already accepted
  }

  return (
    <ModalTextButton
      onClick={() => {
        modalStack.push(
          <ReturnAssignmentFinderModal
            {...props}
            handleClose={() => modalStack.pop()}
          />
        );
      }}
    >
      Hitta returuppdrag <FontAwesomeIcon icon={faCarSide} flip="horizontal" />
    </ModalTextButton>
  );
};

export default ReturnAssignmentFinder;
