/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type TyreTypeEnum = (typeof TyreTypeEnum)[keyof typeof TyreTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const TyreTypeEnum = {
  Summer: 1,
  Winter: 2,
} as const;
