import { faCamera, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { formatDateTime } from 'utils/date-helpers';
import GenericFileViewerModal from '../EventAttachmentFileViewer';
import { CaseAssignmentPosition } from 'api/model';
import MapOpener from 'components/MapOpener';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
`;

const LeftContent = styled.div<{
  denied?: boolean;
}>`
  flex: 1;
  color: ${({ theme }) => theme.colors.foreground.tint};
  font-weight: 500;
  word-break: break-word;
  white-space: pre-wrap;
  text-decoration: ${({ denied }) => (denied ? 'line-through' : '')};
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 5px;
`;

const DateText = styled.div`
  font-style: italic;
  white-space: nowrap;
`;

const UserName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;

  svg {
    font-size: 12px;
  }
`;

const AssignmentId = styled.div``;

const File = styled.button`
  margin: 0;
  padding: 0;

  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.foreground.tint};
  font: inherit;
  font-weight: 500;
  text-align: start;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  assignmentId?: number;
  headerText: React.ReactNode;
  httpTitle?: string;
  created?: Date;
  modified?: Date;
  userName?: React.ReactNode;
  attachmentFileGuid?: string;
  attachmentFileEnding?: string;
  attachmentModalTitle?: string;
  denied?: boolean;
  position?: CaseAssignmentPosition;
}

const DefaultEventHeader: FC<Props> = ({
  assignmentId,
  attachmentFileEnding,
  attachmentFileGuid,
  attachmentModalTitle,
  headerText,
  httpTitle,
  created,
  modified,
  userName,
  denied,
  position,
}) => {
  const [showAttachment, setShowAttachment] = useState<boolean>(false);

  return (
    <Wrapper>
      <LeftContent denied={denied} title={httpTitle}>
        {headerText}
      </LeftContent>
      <RightContent>
        <DateText
          title={[
            created && `Skapad: ${formatDateTime(created, true)}`,
            modified && `Ändrad: ${formatDateTime(modified, true)}`,
          ].join('\n')}
        >
          {modified ? formatDateTime(modified) : '-'}
          {position && <MapOpener position={position} />}
        </DateText>
        {userName && (
          <UserName>
            <FontAwesomeIcon icon={faUser} /> {userName}
          </UserName>
        )}
        {assignmentId && <AssignmentId>Uppdrag {assignmentId}</AssignmentId>}
        {attachmentFileGuid && attachmentFileEnding && (
          <File onClick={() => setShowAttachment(true)}>
            Visa bild <FontAwesomeIcon icon={faCamera} />
          </File>
        )}
        {showAttachment && attachmentFileEnding && attachmentFileGuid && (
          <GenericFileViewerModal
            fileEnding={attachmentFileEnding}
            fileGuid={attachmentFileGuid}
            onClose={() => setShowAttachment(false)}
            title={attachmentModalTitle}
          />
        )}
      </RightContent>
    </Wrapper>
  );
};

export default DefaultEventHeader;
