import {
  faCancel,
  faFileExcel,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExportClient, FileResponse, InvoiceDataModel } from 'api';
import DatePicker, { DateRangeInput } from 'components/inputs/DatePicker';
import LabelWrap from 'components/inputs/LabelWrap';
import TextButton from 'components/inputs/TextButton';
import Modal from 'components/Modal';
import SectionHeader from 'components/SectionHeader';
import Table from 'components/Table';
import useModalStack from 'contexts/modal/useModalStack';
import DateSpanPresetsSelector from 'pages/GlobalSearch/searchBar/DateSpanPresetsSelector';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { useApiCall, useResponse } from 'swaggerhooks/lib';
import { formatDate } from 'utils/date-helpers';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
`;

const Section = styled.section`
  width: 100%;
  max-width: 1000px;
  margin-bottom: 100px;
`;

const SectionContent = styled.div`
  padding: 10px 0;
`;

function downloadFileResponse(fileResponse: FileResponse) {
  const url = URL.createObjectURL(fileResponse.data);

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileResponse.fileName
    ? window.decodeURI(fileResponse.fileName)
    : 'Export.xls';
  anchor.click();
}

const DatePickerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const ExportHistory: FC = () => {
  const modalStack = useModalStack();

  const now = new Date();
  const currentYearStart = new Date(now.getFullYear(), 0, 1);
  const tomorrow = new Date();
  tomorrow.setDate(now.getDate() + 1);
  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([
    currentYearStart,
    tomorrow,
  ]);

  const invoiceHistory = useResponse(
    ExportClient,
    (client) =>
      client.getInvoiceData(
        daterange[0] ?? undefined,
        daterange[1] ?? undefined
      ),
    [daterange]
  );

  const getInvoiceDataCall = useApiCall(ExportClient, (client, id: string) =>
    client.downloadInvoiceDataFile(id)
  );
  const deleteInvoiceDataCall = useApiCall(ExportClient, (client, id: number) =>
    client.deleteInvoiceData(id)
  );

  const handleDelete = async (invoiceData: InvoiceDataModel) => {
    await deleteInvoiceDataCall.run(invoiceData.id);
    invoiceHistory.update();
  };

  const handleDownload = async (invoiceData: InvoiceDataModel) => {
    const [result, error] = await getInvoiceDataCall.run(
      invoiceData.linkToExcel
    );

    if (result && !error) {
      downloadFileResponse(result);
    }
  };

  return (
    <Wrapper>
      <Section>
        <SectionHeader>Faktureringshistorik</SectionHeader>

        <SectionContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <DatePickerWrap>
            <LabelWrap label="Datumspann">
              <DatePicker
                calendarStartDay={1}
                customInput={<DateRangeInput daterange={daterange} />}
                dateFormat="yyyy-MM-dd"
                endDate={daterange[1]}
                locale="sv"
                onChange={setDaterange}
                selectsRange
                startDate={daterange[0]}
              />
            </LabelWrap>
            <DateSpanPresetsSelector
              dateRange={daterange}
              onPresetSelected={setDaterange}
            />
          </DatePickerWrap>
        </SectionContent>
        <SectionContent>
          <Table
            columnSettings={[
              {
                head: 'Faktureringsdatum',
                render: (row) => formatDate(row.invoiceDate),
                width: 200,
              },
              {
                head: 'Antal ärenden',
                render: (row) => row.cases,
                width: 200,
              },
              {
                head: '',
                width: 100,
                render: (row) => (
                  <TextButton onClick={() => handleDownload(row)}>
                    <FontAwesomeIcon icon={faFileExcel} />
                    <span> Ladda ner</span>
                  </TextButton>
                ),
              },
              {
                head: '',
                width: 80,
                render: (row) => (
                  <TextButton
                    onClick={() =>
                      modalStack.push(
                        <Modal
                          buttons={[
                            {
                              label: 'Ta bort underlaget',
                              onClick: () => {
                                handleDelete(row);
                                modalStack.pop();
                              },
                            },
                            {
                              icon: <FontAwesomeIcon icon={faCancel} />,
                              label: 'Avbryt',
                              onClick: () => modalStack.pop(),
                            },
                          ]}
                          title="Ta bort faktureringsunderlag och återställ ärenden"
                        >
                          Är du säker på att du vill ta bort underlaget från{' '}
                          {formatDate(row.invoiceDate)}? Alla ärenden som ingår
                          i underlaget kommer att markeras som ej fakturerade.
                        </Modal>
                      )
                    }
                  >
                    <FontAwesomeIcon icon={faTrash} />
                    <span> Ta bort</span>
                  </TextButton>
                ),
              },
            ]}
            initialSortState={{ sortedColumnIndex: -1, ascending: false }}
            renderProps={InvoiceDataModel}
            rows={invoiceHistory.response ?? []}
          />
        </SectionContent>
      </Section>
    </Wrapper>
  );
};

export default ExportHistory;
