import { AssignmentViewModel } from 'api';
import Table from 'components/Table';
import useTranslations from 'contexts/basicData/useTranslations';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import useAssignmentsBulkOperations from '../useAssignmentsBulkOperations';
import useColumnSettings, { ColumnRenderProps } from './useColumnSettings';

const MyTable = styled(Table)`
  flex: 1;
` as typeof Table;

interface Props {
  assignments: AssignmentViewModel[];
  onAssignmentItemClick(model: AssignmentViewModel): void;
  bulkOperations: ReturnType<typeof useAssignmentsBulkOperations>;
  currentPage?: number;
  onGoToPage?(page: number): void;
  totalPageCount?: number;
  pageSize?: number;
}

const BulkApproveAssignmentsList: FC<Props> = ({
  assignments,
  onAssignmentItemClick,
  bulkOperations,
  currentPage,
  onGoToPage,
  totalPageCount,
  pageSize,
}) => {
  const translations = useTranslations();
  const columnSettings = useColumnSettings();

  const renderProps = useMemo(
    (): ColumnRenderProps => ({
      onSelectClick: (avm) => {
        bulkOperations.toggleSelectAssignment(avm.assignment.assignmentID);
      },
      selectedAssignmentIds: bulkOperations.selectedAssignmentIds,
      translations,
    }),
    [bulkOperations, translations]
  );
  const initialSortState = {
    sortedColumnIndex: 2,
    ascending: true,
  };

  return (
    <MyTable
      columnSettings={columnSettings}
      currentPage={currentPage}
      initialSortState={initialSortState}
      onGoToPage={onGoToPage}
      onRowClick={onAssignmentItemClick}
      pageSize={pageSize}
      renderProps={renderProps}
      rows={assignments}
      totalPageCount={totalPageCount}
      useColumnWidthAsFlexAmount
    />
  );
};

export default BulkApproveAssignmentsList;
