import SubMenu, { SubMenuTitle } from 'components/SubMenu';
import Routes from 'constants/Routes';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import useAbsenceRequests from './useUnhandledCount';
import { arraySpreadIf } from 'utils/spreading';
import useMe from 'contexts/authentication/useMe';
import expenseUtils from 'components/DetailedCase/Expenses/expenseUtils';
// import useBetaFeatures from 'contexts/basicData/useBetaFeatures';

const Wrapper = styled.div``;

const EmployeesPage: FC = () => {
  const me = useMe();
  const { unhandledAbsenceRequestsCount, unhandledDrivingPolicyWarningsCount } =
    useAbsenceRequests();
  // const [betaFeatures] = useBetaFeatures();
  return (
    <Wrapper>
      {/* {betaFeatures?.showBetaFeatures && ( */}
      <SubMenu
        items={[
          {
            label: <SubMenuTitle>Personal</SubMenuTitle>,
            path: Routes.employees.index,
          },
          ...arraySpreadIf(!!expenseUtils.allowCircleKSensitiveInfo(me), {
            label: <SubMenuTitle>Betalkort</SubMenuTitle>,
            path: Routes.employees.paymentCards,
          }),
          {
            label: <SubMenuTitle>Tidrapportering</SubMenuTitle>,
            path: Routes.employees.timeReports,
          },
          {
            label: <SubMenuTitle>Grupper</SubMenuTitle>,
            path: Routes.employees.teams,
          },
          {
            label: <SubMenuTitle>Tillgänglighet</SubMenuTitle>,
            path: Routes.employees.availability,
          },
          {
            label: (
              <SubMenuTitle>
                Att hantera{' '}
                {unhandledAbsenceRequestsCount > 0
                  ? `(${unhandledAbsenceRequestsCount})`
                  : ''}
              </SubMenuTitle>
            ),
            path: Routes.employees.absenceRequests,
          },
          {
            label: (
              <SubMenuTitle>
                Varningar{' '}
                {unhandledDrivingPolicyWarningsCount > 0
                  ? `(${unhandledDrivingPolicyWarningsCount})`
                  : ''}
              </SubMenuTitle>
            ),
            path: Routes.employees.drivingPolicyWarnings,
          },
          {
            label: <SubMenuTitle>Körtidspolicy</SubMenuTitle>,
            path: Routes.employees.drivingPolicy,
          },
        ]}
      />
      {/* )} */}
      <Outlet />
    </Wrapper>
  );
};

export default EmployeesPage;
