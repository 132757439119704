import styled, { css } from 'styled-components';
import { inputStyle } from './Input';

const Select = styled.select<{
  small?: boolean;
  width?: string | number;
  error?: boolean;
}>`
  ${inputStyle}
  ${({ error, theme }) =>
    error &&
    css`
      border-color: ${theme.colors.foreground.error};
    `}
    ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
      background-color: ${({ theme }) => theme.colors.background.tertiary};
    `}
`;

export default Select;
