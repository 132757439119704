import { SetupClient } from 'api';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { RequestStatus, useResponse } from 'swaggerhooks/lib';

const speedLimitAlertsAtom = atom<{ [key: number]: number }>({
  key: 'speedlimitalerts',
  default: selector({
    key: 'speedlimitalertsSelector',
    get: async (): Promise<{
      [key: number]: number;
    }> => {
      const url = `/api/Setup/GetSpeedLimitAlerts`.replace(/[?&]$/, '');

      const options: RequestInit = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        return {};
      }

      return (await response.json()) satisfies { [key: number]: number };
    },
  }),
});

/** OBS: Use this once in the app to preload state values */
export const useSpeedLimitAlertsInitialization = () => {
  const [, setState] = useRecoilState(speedLimitAlertsAtom);

  const response = useResponse(SetupClient, async (c) => {
    const r = await c.getSpeedLimitAlerts();
    setState(r);
    return r;
  });

  return response.status === RequestStatus.Fetching;
};

const useSpeedLimitAlerts = () => {
  return useRecoilValue(speedLimitAlertsAtom);
};

export default useSpeedLimitAlerts;
