/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  BusinessUnitGetBusinessUnitGroupParams,
  BusinessUnitGetBusinessUnitParams,
  BusinessUnitGroupModel,
  BusinessUnitGroupModelBody,
  BusinessUnitModel,
  BusinessUnitModelBody,
} from '.././model';
import useBusinessUnitGetBusinessUnitMutator from '.././mutator/custom-instance';
import useBusinessUnitGetBusinessUnitGroupMutator from '.././mutator/custom-instance';
import useBusinessUnitGetBusinessUnitsMutator from '.././mutator/custom-instance';
import useBusinessUnitGetBusinessUnitGroupsMutator from '.././mutator/custom-instance';
import useBusinessUnitCreateBusinessUnitMutator from '.././mutator/custom-instance';
import useBusinessUnitCreateBusinessUnitGroupMutator from '.././mutator/custom-instance';
import useBusinessUnitUpdateBusinessUnitMutator from '.././mutator/custom-instance';
import useBusinessUnitUpdateBusinessUnitGroupMutator from '.././mutator/custom-instance';

export const useBusinessUnitGetBusinessUnitHook = () => {
  const businessUnitGetBusinessUnit =
    useBusinessUnitGetBusinessUnitMutator<BusinessUnitModel>();

  return (params?: BusinessUnitGetBusinessUnitParams, signal?: AbortSignal) => {
    return businessUnitGetBusinessUnit({
      url: `/api/BusinessUnit/GetBusinessUnit`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getBusinessUnitGetBusinessUnitQueryKey = (
  params?: BusinessUnitGetBusinessUnitParams
) => {
  return [
    `/api/BusinessUnit/GetBusinessUnit`,
    ...(params ? [params] : []),
  ] as const;
};

export const useBusinessUnitGetBusinessUnitQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitHook>>
  >,
  TError = unknown,
>(
  params?: BusinessUnitGetBusinessUnitParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBusinessUnitGetBusinessUnitQueryKey(params);

  const businessUnitGetBusinessUnit = useBusinessUnitGetBusinessUnitHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitHook>>>
  > = ({ signal }) => businessUnitGetBusinessUnit(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BusinessUnitGetBusinessUnitQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitHook>>>
>;
export type BusinessUnitGetBusinessUnitQueryError = unknown;

export const useBusinessUnitGetBusinessUnit = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitHook>>
  >,
  TError = unknown,
>(
  params?: BusinessUnitGetBusinessUnitParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useBusinessUnitGetBusinessUnitQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useBusinessUnitGetBusinessUnitGroupHook = () => {
  const businessUnitGetBusinessUnitGroup =
    useBusinessUnitGetBusinessUnitGroupMutator<BusinessUnitGroupModel>();

  return (
    params?: BusinessUnitGetBusinessUnitGroupParams,
    signal?: AbortSignal
  ) => {
    return businessUnitGetBusinessUnitGroup({
      url: `/api/BusinessUnit/GetBusinessUnitGroup`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getBusinessUnitGetBusinessUnitGroupQueryKey = (
  params?: BusinessUnitGetBusinessUnitGroupParams
) => {
  return [
    `/api/BusinessUnit/GetBusinessUnitGroup`,
    ...(params ? [params] : []),
  ] as const;
};

export const useBusinessUnitGetBusinessUnitGroupQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupHook>>
  >,
  TError = unknown,
>(
  params?: BusinessUnitGetBusinessUnitGroupParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getBusinessUnitGetBusinessUnitGroupQueryKey(params);

  const businessUnitGetBusinessUnitGroup =
    useBusinessUnitGetBusinessUnitGroupHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupHook>>
    >
  > = ({ signal }) => businessUnitGetBusinessUnitGroup(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BusinessUnitGetBusinessUnitGroupQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupHook>>
  >
>;
export type BusinessUnitGetBusinessUnitGroupQueryError = unknown;

export const useBusinessUnitGetBusinessUnitGroup = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupHook>>
  >,
  TError = unknown,
>(
  params?: BusinessUnitGetBusinessUnitGroupParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useBusinessUnitGetBusinessUnitGroupQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useBusinessUnitGetBusinessUnitsHook = () => {
  const businessUnitGetBusinessUnits =
    useBusinessUnitGetBusinessUnitsMutator<BusinessUnitModel[]>();

  return () => {
    return businessUnitGetBusinessUnits({
      url: `/api/BusinessUnit/GetBusinessUnits`,
      method: 'POST',
    });
  };
};

export const useBusinessUnitGetBusinessUnitsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitsHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitsHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const businessUnitGetBusinessUnits = useBusinessUnitGetBusinessUnitsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitsHook>>>,
    void
  > = () => {
    return businessUnitGetBusinessUnits();
  };

  return { mutationFn, ...mutationOptions };
};

export type BusinessUnitGetBusinessUnitsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitsHook>>>
>;

export type BusinessUnitGetBusinessUnitsMutationError = unknown;

export const useBusinessUnitGetBusinessUnits = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitsHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitsHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useBusinessUnitGetBusinessUnitsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useBusinessUnitGetBusinessUnitGroupsHook = () => {
  const businessUnitGetBusinessUnitGroups =
    useBusinessUnitGetBusinessUnitGroupsMutator<BusinessUnitGroupModel[]>();

  return (signal?: AbortSignal) => {
    return businessUnitGetBusinessUnitGroups({
      url: `/api/BusinessUnit/GetBusinessUnitGroups`,
      method: 'GET',
      signal,
    });
  };
};

export const getBusinessUnitGetBusinessUnitGroupsQueryKey = () => {
  return [`/api/BusinessUnit/GetBusinessUnitGroups`] as const;
};

export const useBusinessUnitGetBusinessUnitGroupsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupsHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getBusinessUnitGetBusinessUnitGroupsQueryKey();

  const businessUnitGetBusinessUnitGroups =
    useBusinessUnitGetBusinessUnitGroupsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupsHook>>
    >
  > = ({ signal }) => businessUnitGetBusinessUnitGroups(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type BusinessUnitGetBusinessUnitGroupsQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupsHook>>
  >
>;
export type BusinessUnitGetBusinessUnitGroupsQueryError = unknown;

export const useBusinessUnitGetBusinessUnitGroups = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitGetBusinessUnitGroupsHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useBusinessUnitGetBusinessUnitGroupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useBusinessUnitCreateBusinessUnitHook = () => {
  const businessUnitCreateBusinessUnit =
    useBusinessUnitCreateBusinessUnitMutator<number>();

  return (businessUnitModelBody: BusinessUnitModelBody) => {
    return businessUnitCreateBusinessUnit({
      url: `/api/BusinessUnit/CreateBusinessUnit`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: businessUnitModelBody,
    });
  };
};

export const useBusinessUnitCreateBusinessUnitMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitHook>>
    >,
    TError,
    { data: BusinessUnitModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitHook>>>,
  TError,
  { data: BusinessUnitModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const businessUnitCreateBusinessUnit =
    useBusinessUnitCreateBusinessUnitHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitHook>>
    >,
    { data: BusinessUnitModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return businessUnitCreateBusinessUnit(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BusinessUnitCreateBusinessUnitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitHook>>>
>;
export type BusinessUnitCreateBusinessUnitMutationBody = BusinessUnitModelBody;
export type BusinessUnitCreateBusinessUnitMutationError = unknown;

export const useBusinessUnitCreateBusinessUnit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitHook>>
    >,
    TError,
    { data: BusinessUnitModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitHook>>>,
  TError,
  { data: BusinessUnitModelBody },
  TContext
> => {
  const mutationOptions =
    useBusinessUnitCreateBusinessUnitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useBusinessUnitCreateBusinessUnitGroupHook = () => {
  const businessUnitCreateBusinessUnitGroup =
    useBusinessUnitCreateBusinessUnitGroupMutator<number>();

  return (businessUnitGroupModelBody: BusinessUnitGroupModelBody) => {
    return businessUnitCreateBusinessUnitGroup({
      url: `/api/BusinessUnit/CreateBusinessUnitGroup`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: businessUnitGroupModelBody,
    });
  };
};

export const useBusinessUnitCreateBusinessUnitGroupMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitGroupHook>>
    >,
    TError,
    { data: BusinessUnitGroupModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitGroupHook>>
  >,
  TError,
  { data: BusinessUnitGroupModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const businessUnitCreateBusinessUnitGroup =
    useBusinessUnitCreateBusinessUnitGroupHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitGroupHook>>
    >,
    { data: BusinessUnitGroupModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return businessUnitCreateBusinessUnitGroup(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BusinessUnitCreateBusinessUnitGroupMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitGroupHook>>
  >
>;
export type BusinessUnitCreateBusinessUnitGroupMutationBody =
  BusinessUnitGroupModelBody;
export type BusinessUnitCreateBusinessUnitGroupMutationError = unknown;

export const useBusinessUnitCreateBusinessUnitGroup = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitGroupHook>>
    >,
    TError,
    { data: BusinessUnitGroupModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitCreateBusinessUnitGroupHook>>
  >,
  TError,
  { data: BusinessUnitGroupModelBody },
  TContext
> => {
  const mutationOptions =
    useBusinessUnitCreateBusinessUnitGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useBusinessUnitUpdateBusinessUnitHook = () => {
  const businessUnitUpdateBusinessUnit =
    useBusinessUnitUpdateBusinessUnitMutator<void>();

  return (businessUnitModelBody: BusinessUnitModelBody) => {
    return businessUnitUpdateBusinessUnit({
      url: `/api/BusinessUnit/UpdateBusinessUnit`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: businessUnitModelBody,
    });
  };
};

export const useBusinessUnitUpdateBusinessUnitMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitHook>>
    >,
    TError,
    { data: BusinessUnitModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitHook>>>,
  TError,
  { data: BusinessUnitModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const businessUnitUpdateBusinessUnit =
    useBusinessUnitUpdateBusinessUnitHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitHook>>
    >,
    { data: BusinessUnitModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return businessUnitUpdateBusinessUnit(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BusinessUnitUpdateBusinessUnitMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitHook>>>
>;
export type BusinessUnitUpdateBusinessUnitMutationBody = BusinessUnitModelBody;
export type BusinessUnitUpdateBusinessUnitMutationError = unknown;

export const useBusinessUnitUpdateBusinessUnit = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitHook>>
    >,
    TError,
    { data: BusinessUnitModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitHook>>>,
  TError,
  { data: BusinessUnitModelBody },
  TContext
> => {
  const mutationOptions =
    useBusinessUnitUpdateBusinessUnitMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useBusinessUnitUpdateBusinessUnitGroupHook = () => {
  const businessUnitUpdateBusinessUnitGroup =
    useBusinessUnitUpdateBusinessUnitGroupMutator<void>();

  return (businessUnitGroupModelBody: BusinessUnitGroupModelBody) => {
    return businessUnitUpdateBusinessUnitGroup({
      url: `/api/BusinessUnit/UpdateBusinessUnitGroup`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: businessUnitGroupModelBody,
    });
  };
};

export const useBusinessUnitUpdateBusinessUnitGroupMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitGroupHook>>
    >,
    TError,
    { data: BusinessUnitGroupModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitGroupHook>>
  >,
  TError,
  { data: BusinessUnitGroupModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const businessUnitUpdateBusinessUnitGroup =
    useBusinessUnitUpdateBusinessUnitGroupHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitGroupHook>>
    >,
    { data: BusinessUnitGroupModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return businessUnitUpdateBusinessUnitGroup(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type BusinessUnitUpdateBusinessUnitGroupMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitGroupHook>>
  >
>;
export type BusinessUnitUpdateBusinessUnitGroupMutationBody =
  BusinessUnitGroupModelBody;
export type BusinessUnitUpdateBusinessUnitGroupMutationError = unknown;

export const useBusinessUnitUpdateBusinessUnitGroup = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitGroupHook>>
    >,
    TError,
    { data: BusinessUnitGroupModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useBusinessUnitUpdateBusinessUnitGroupHook>>
  >,
  TError,
  { data: BusinessUnitGroupModelBody },
  TContext
> => {
  const mutationOptions =
    useBusinessUnitUpdateBusinessUnitGroupMutationOptions(options);

  return useMutation(mutationOptions);
};
