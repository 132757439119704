import { DefaultScheduleDto, UserModel } from 'api';
import useForm, { BasicFormState } from 'hooks/useForm';
import {
  convertToDateOfBirthString,
  formatDate,
  formatTime,
} from 'utils/date-helpers';

export enum UserFormField {
  name,
  employmentID,
  email,
  phoneNumber,
  emailConfirmed,
  roles,
  isActive,
  isAvailableForDriving,
  autoApproveAssignment,
  autoFetchSpeedLimitRate,
  showInInvoicing,
  dateOfBirth,
  city,
  postalCode,
  countyId,
  locationSpec,
  canDriveManual,
  isFullTime,
  certifiedToCallCustomers,
  canTestTrucks,
  canTestPremiumCars,
  canDoHomeDelivery,
  canDriveElectric,
  canDriveTrucks,
  canDriveTrailers,
  canDrivePremium,
  canDoLongDrives,
  otherInfo,
  employmentStatusID,
  zipCodeLocalAreaID,
  businessUnitID,
  isAllowedToChangeAvailability,
  teamID,
  isTruckTransport,
  excludeFromInvoicing,
  excludeFromSalaryReport,
  greenPlate,
  isBetaTester,
  shouldSeeTimeReportSectionInApp,
  companyDepartmentID,
  isAllowedToOverrideAssigneeWarnings,
}

const getInitialUserFormValues = (
  user?: UserModel
): BasicFormState<UserFormField> => {
  if (user) {
    return {
      [UserFormField.name]: user.name,
      [UserFormField.employmentID]: user.employmentID?.toString() ?? '',
      [UserFormField.email]: user.email,
      [UserFormField.phoneNumber]: user.phoneNumber,
      [UserFormField.roles]: user.roles.join(','),
      [UserFormField.isActive]: user.isActive ? 'true' : 'false',
      [UserFormField.isAvailableForDriving]: user.isAvailableForDriving
        ? 'true'
        : 'false',
      [UserFormField.emailConfirmed]: user.emailConfirmed ? 'true' : 'false',
      [UserFormField.autoApproveAssignment]: user.autoApproveAssignment
        ? 'true'
        : 'false',
      [UserFormField.showInInvoicing]: user.showInInvoicing ? 'true' : 'false',
      [UserFormField.dateOfBirth]: user.dateOfBirth
        ? convertToDateOfBirthString(formatDate(user.dateOfBirth))
        : '',
      [UserFormField.city]: user.city,
      [UserFormField.postalCode]: user.postalCode,
      [UserFormField.countyId]: user.countyId?.toString() ?? '',
      [UserFormField.locationSpec]: user.locationSpec,
      [UserFormField.isFullTime]: user.isFullTime ? 'true' : 'false',
      [UserFormField.canDriveManual]: user.canDriveManual ? 'true' : 'false',
      [UserFormField.canDrivePremium]: user.canDrivePremium ? 'true' : 'false',
      [UserFormField.canDoLongDrives]: user.canDoLongDrives ? 'true' : 'false',
      [UserFormField.canTestTrucks]: user.canTestTrucks ? 'true' : 'false',
      [UserFormField.canTestPremiumCars]: user.canTestPremiumCars
        ? 'true'
        : 'false',
      [UserFormField.canDoHomeDelivery]: user.canDoHomeDelivery
        ? 'true'
        : 'false',
      [UserFormField.canDriveElectric]: user.canDriveElectric
        ? 'true'
        : 'false',
      [UserFormField.canDriveTrucks]: user.canDriveTrucks ? 'true' : 'false',
      [UserFormField.canDriveTrailers]: user.canDriveTrailers
        ? 'true'
        : 'false',
      [UserFormField.otherInfo]: user.otherInfo,
      [UserFormField.employmentStatusID]:
        user.employmentStatusID?.toString() ?? '0',
      [UserFormField.autoFetchSpeedLimitRate]:
        user.autoFetchSpeedLimitRate.toString() ?? '1.0',
      [UserFormField.zipCodeLocalAreaID]:
        user.zipCodeLocalArea?.id?.toString() ?? '',
      [UserFormField.businessUnitID]: user.businessUnitID?.toString() ?? '',
      [UserFormField.isAllowedToChangeAvailability]:
        user.isAllowedToChangeAvailability ? 'true' : 'false',
      [UserFormField.teamID]: user.teamID?.toString() ?? '',
      [UserFormField.certifiedToCallCustomers]: user.certifiedToCallCustomers
        ? 'true'
        : 'false',
      [UserFormField.isTruckTransport]: user.isTruckTransport
        ? 'true'
        : 'false',
      [UserFormField.excludeFromInvoicing]: user.excludeFromInvoicing
        ? 'true'
        : 'false',
      [UserFormField.excludeFromSalaryReport]: user.excludeFromSalaryReport
        ? 'true'
        : 'false',
      [UserFormField.greenPlate]: user.greenPlate ?? '',
      [UserFormField.isBetaTester]: user.isBetaTester ? 'true' : 'false',
      [UserFormField.shouldSeeTimeReportSectionInApp]:
        user.shouldSeeTimeReportSectionInApp ? 'true' : 'false',
      [UserFormField.companyDepartmentID]:
        user.companyDepartmentId?.toString() ?? '',
      [UserFormField.isAllowedToOverrideAssigneeWarnings]:
        user.isAllowedToOverrideAssigneeWarnings ? 'true' : 'false',
    };
  }

  return {
    [UserFormField.name]: '',
    [UserFormField.employmentID]: '',
    [UserFormField.email]: '',
    [UserFormField.phoneNumber]: '',
    [UserFormField.roles]: '',
    [UserFormField.isActive]: 'true',
    [UserFormField.isAvailableForDriving]: 'true',
    [UserFormField.emailConfirmed]: 'true',
    [UserFormField.autoApproveAssignment]: 'false',
    [UserFormField.showInInvoicing]: 'false',
    [UserFormField.dateOfBirth]: '',
    [UserFormField.isFullTime]: 'false',
    [UserFormField.autoFetchSpeedLimitRate]: '1.0',
    [UserFormField.city]: '',
    [UserFormField.postalCode]: '',
    [UserFormField.countyId]: '',
    [UserFormField.locationSpec]: '',
    [UserFormField.canDriveManual]: 'false',
    [UserFormField.canDrivePremium]: 'false',
    [UserFormField.canDoLongDrives]: 'false',
    [UserFormField.canTestTrucks]: 'false',
    [UserFormField.canTestPremiumCars]: 'false',
    [UserFormField.canDoHomeDelivery]: 'false',
    [UserFormField.canDriveElectric]: 'false',
    [UserFormField.canDriveTrucks]: 'false',
    [UserFormField.canDriveTrailers]: 'false',
    [UserFormField.otherInfo]: '',
    [UserFormField.employmentStatusID]: '0',
    [UserFormField.zipCodeLocalAreaID]: '',
    [UserFormField.businessUnitID]: '',
    [UserFormField.isAllowedToChangeAvailability]: 'false',
    [UserFormField.teamID]: '',
    [UserFormField.certifiedToCallCustomers]: 'false',
    [UserFormField.isTruckTransport]: 'false',
    [UserFormField.excludeFromInvoicing]: 'false',
    [UserFormField.excludeFromSalaryReport]: 'false',
    [UserFormField.greenPlate]: '',
    [UserFormField.isBetaTester]: 'false',
    [UserFormField.shouldSeeTimeReportSectionInApp]: 'false',
    [UserFormField.companyDepartmentID]: '',
    [UserFormField.isAllowedToOverrideAssigneeWarnings]: 'false',
  };
};

export const cleanWeekSchedule = (
  schedules: DefaultScheduleDto[]
): DefaultScheduleDto[] => {
  // Remove empty days and change 8:00 AM formatting to 08:00 on all days start and end strings
  // Also add isBaseSchedule property to first schedule
  const cleanedSchedules: DefaultScheduleDto[] = schedules.map(
    (schedule, index) => {
      return {
        ...schedule,
        isBaseSchedule: index === 0,
        schedule: schedule.schedule.map((dayDto) => {
          return {
            ...dayDto,
            start: dayDto.start || undefined,
            end: dayDto.end || undefined,
            startString: formatTime(dayDto.start)?.trim() || undefined,
            endString: formatTime(dayDto.end)?.trim() || undefined,
          };
        }),
      } as DefaultScheduleDto;
    }
  );

  return cleanedSchedules;
};

// converts YYYYMMDD or YYYY-MM-DD to Date
const convertDateOfBirthStringToDate = (dateOfBirthString: string): Date => {
  const dateOfBirthStringWithoutHyphens = dateOfBirthString.replace(/-/g, '');
  const year = dateOfBirthStringWithoutHyphens.substring(0, 4);
  const month = dateOfBirthStringWithoutHyphens.substring(4, 6);
  const day = dateOfBirthStringWithoutHyphens.substring(6, 8);
  return new Date(Number(year), Number(month) - 1, Number(day));
};

export const userFormToUserModel = (
  userForm: BasicFormState<UserFormField>,
  base?: UserModel
): UserModel => {
  return {
    userID: base?.userID ?? -1,
    name: userForm[UserFormField.name],
    employmentID: userForm[UserFormField.employmentID],
    email: userForm[UserFormField.email],
    phoneNumber: userForm[UserFormField.phoneNumber],
    roles: userForm[UserFormField.roles]
      .split(',')
      .filter((v) => v)
      .map((v) => Number(v)),
    isActive: userForm[UserFormField.isActive] === 'true',
    isAvailableForDriving:
      userForm[UserFormField.isAvailableForDriving] === 'true',
    emailConfirmed: userForm[UserFormField.emailConfirmed] === 'true',
    autoApproveAssignment:
      userForm[UserFormField.autoApproveAssignment] === 'true',
    showInInvoicing: userForm[UserFormField.showInInvoicing] === 'true',
    dateOfBirth:
      userForm[UserFormField.dateOfBirth] !== ''
        ? convertDateOfBirthStringToDate(userForm[UserFormField.dateOfBirth])
        : undefined,
    city: userForm[UserFormField.city],
    postalCode: userForm[UserFormField.postalCode],
    countyId: Number(userForm[UserFormField.countyId]),
    locationSpec: userForm[UserFormField.locationSpec],
    isFullTime: userForm[UserFormField.isFullTime] === 'true',
    canDriveManual: userForm[UserFormField.canDriveManual] === 'true',
    canDrivePremium: userForm[UserFormField.canDrivePremium] === 'true',
    canTestTrucks: userForm[UserFormField.canTestTrucks] === 'true',
    canTestPremiumCars: userForm[UserFormField.canTestPremiumCars] === 'true',
    canDoLongDrives: userForm[UserFormField.canDoLongDrives] === 'true',
    canDoHomeDelivery: userForm[UserFormField.canDoHomeDelivery] === 'true',
    canDriveElectric: userForm[UserFormField.canDriveElectric] === 'true',
    canDriveTrucks: userForm[UserFormField.canDriveTrucks] === 'true',
    canDriveTrailers: userForm[UserFormField.canDriveTrailers] === 'true',
    otherInfo: userForm[UserFormField.otherInfo],
    employmentStatusID: Number(userForm[UserFormField.employmentStatusID]),
    autoFetchSpeedLimitRate: Number(
      userForm[UserFormField.autoFetchSpeedLimitRate]
    ),
    zipCodeLocalAreaID:
      userForm[UserFormField.zipCodeLocalAreaID] !== ''
        ? Number(userForm[UserFormField.zipCodeLocalAreaID])
        : undefined,

    businessUnitID:
      userForm[UserFormField.businessUnitID] !== ''
        ? Number(userForm[UserFormField.businessUnitID])
        : undefined,
    isAllowedToChangeAvailability:
      userForm[UserFormField.isAllowedToChangeAvailability] === 'true',
    teamID:
      userForm[UserFormField.teamID] !== ''
        ? Number(userForm[UserFormField.teamID])
        : undefined,
    certifiedToCallCustomers:
      userForm[UserFormField.certifiedToCallCustomers] === 'true',
    isTruckTransport: userForm[UserFormField.isTruckTransport] === 'true',
    excludeFromInvoicing:
      userForm[UserFormField.excludeFromInvoicing] === 'true',
    excludeFromSalaryReport:
      userForm[UserFormField.excludeFromSalaryReport] === 'true',
    greenPlate:
      userForm[UserFormField.greenPlate] !== ''
        ? userForm[UserFormField.greenPlate]
        : undefined,
    isBetaTester: userForm[UserFormField.isBetaTester] === 'true',
    shouldSeeTimeReportSectionInApp:
      userForm[UserFormField.shouldSeeTimeReportSectionInApp] === 'true',
    companyDepartmentId:
      userForm[UserFormField.companyDepartmentID] !== ''
        ? Number(userForm[UserFormField.companyDepartmentID])
        : undefined,
    isAllowedToOverrideAssigneeWarnings:
      userForm[UserFormField.isAllowedToOverrideAssigneeWarnings] === 'true',
  } as UserModel;
};

const useUserForm = (user?: UserModel) => {
  return useForm<UserFormField>(getInitialUserFormValues(user));
};

export default useUserForm;
