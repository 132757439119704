import React, { FC } from 'react';

import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

import AuthorizedButton from '../inputs/AuthorizedButton';
import useKeyPress from 'hooks/useKeyPress';

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Overlay = styled.div<{ bgColor?: string }>`
  position: fixed;
  inset: 0;
  display: flex;
  align-items: center;
  z-index: 10;

  justify-content: center;

  background: ${({ theme, bgColor }) =>
    bgColor ?? theme.colors.background.opacityOverlay};
  animation: ${fadeIn} 0.2s;
`;

const ModalBody = styled.div<{ fullscreen?: boolean }>`
  display: flex;
  flex-direction: column;
  ${({ fullscreen }) => !fullscreen && 'max-height: 90%;'}
  overflow: auto;
  background: ${({ theme }) => theme.colors.background.primary};
`;

const ModalContent = styled.div`
  padding: ${({ theme }) => theme.sizes.modal.defaultPadding}px;
`;

const ModalTitleRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ModalTitle = styled.h1`
  flex: 1;
  margin: ${({ theme }) => theme.sizes.modal.defaultPadding}px;
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.sizes.font.large};
`;

const ModalTitleCloseButton = styled.button`
  padding: 5px;
  margin: ${({ theme }) => theme.sizes.modal.defaultPadding}px;
  margin-bottom: 0;
  border: 0;
  font-size: 25px;
  background: transparent;
  cursor: pointer;
`;

export const ModalButtons = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding: ${({ theme }) => theme.sizes.modal.defaultPadding}px;
  padding-top: 0;

  & > *:not(:first-child) {
    margin-right: 5px;
  }
`;

export const modalTitleClass = 'modal-title';
export const modalTitleCloseButtonClass = 'modal-title-close-button';
export const modalContentClass = 'modal-body';
export const modalButtons = 'modal-buttons';

export interface ModalButton {
  icon?: React.ReactNode;
  label: React.ReactNode;
  onClick?(): void;
  disabled?: boolean;
  color?: string;
  style?: React.CSSProperties;
  roles?: number[];
  loading?: boolean;
}

interface Props {
  title?: string;
  buttons?: ModalButton[];
  onClose?(): void;
  className?: string;
  closeOnOutsideClick?: boolean;
  children: React.ReactNode;
  bgColor?: string;
  fullscreen?: boolean;
}

const Modal: FC<Props> = ({
  title,
  buttons,
  onClose,
  children,
  className,
  closeOnOutsideClick = true,
  bgColor,
  fullscreen,
}) => {
  useKeyPress(() => {
    if (onClose) onClose();
  }, ['Escape']);
  return (
    <Overlay
      className={className}
      bgColor={bgColor}
      onClick={() => {
        if (closeOnOutsideClick && onClose) onClose();
      }}
    >
      <ModalBody
        fullscreen={fullscreen}
        onClick={(eve) => eve.stopPropagation()}
      >
        <ModalTitleRow>
          {title && (
            <ModalTitle className={modalTitleClass}>{title}</ModalTitle>
          )}
          {onClose && (
            <ModalTitleCloseButton
              className={modalTitleCloseButtonClass}
              onClick={onClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </ModalTitleCloseButton>
          )}
        </ModalTitleRow>

        <ModalContent className={modalContentClass}>{children}</ModalContent>

        {buttons && buttons.length > 0 && (
          <ModalButtons className={modalButtons}>
            {buttons.map((button) => (
              <AuthorizedButton
                disabled={button.disabled}
                icon={button.icon}
                key={`modal_${button.label}`}
                onClick={button.onClick}
                roles={button.roles}
                style={button.style}
                loading={button.loading}
              >
                {button.label}
              </AuthorizedButton>
            ))}
          </ModalButtons>
        )}
      </ModalBody>
    </Overlay>
  );
};

export default Modal;
