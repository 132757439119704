import { faClock, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SetupClient } from 'api';
import Modal from 'components/Modal';
import { pollersErrorModalId } from 'constants/AppConstants';
import FrontendBuildCommit from 'constants/FrontendBuildCommit';
import ApiErrorModalIds from 'contexts/ApiCallConfiguration/ApiErrorModalIds';
import useModalStack from 'contexts/modal/useModalStack';
import { useEffect } from 'react';
import { atom, useRecoilState } from 'recoil';
import { useApiCall } from 'swaggerhooks/lib';
import useUpdateInterval from './useUpdateInterval';

interface Versions {
  frontendVersion: string;
  apiVersion: string;
}

const versionsAtom = atom<Versions>({
  key: 'versionsAtom',
  default: {
    frontendVersion: '',
    apiVersion: '',
  },
});

const frontendUpdateReminderModalID = 'FrontendUpdateReminderModal';

const useFrontendVersionPoll = () => {
  const [, setVersions] = useRecoilState(versionsAtom);
  const modalStack = useModalStack();
  const getApiVersionCall = useApiCall(
    SetupClient,
    (c) => c.getApiVersion(),
    undefined,
    { errorModalId: pollersErrorModalId }
  );

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('Frontend build commit: ', FrontendBuildCommit);
  }, []);

  useUpdateInterval(
    async () => {
      const [apiVersion, err] = await getApiVersionCall.run();

      if (!err && apiVersion) {
        setVersions({
          apiVersion,
          frontendVersion: FrontendBuildCommit,
        });

        modalStack.pop(pollersErrorModalId);
        modalStack.pop(ApiErrorModalIds.ConnectionError);

        if (FrontendBuildCommit !== apiVersion) {
          modalStack.push(
            <Modal
              buttons={[
                {
                  icon: <FontAwesomeIcon icon={faDownload} />,
                  label: 'Uppdatera nu',
                  onClick: () => {
                    modalStack.pop(frontendUpdateReminderModalID);
                    window.location.reload();
                  },
                },
                {
                  icon: <FontAwesomeIcon icon={faClock} />,
                  label: 'Uppdatera senare',
                  onClick: () => modalStack.pop(frontendUpdateReminderModalID),
                },
              ]}
              title="Ny uppdatering!"
            >
              Hej! Nu finns det en ny uppdatering. Se till att spara eventuella
              ändringar och ladda sedan om sidan för att uppdatera.
            </Modal>,
            frontendUpdateReminderModalID
          );
        }
      }
    },
    10000,
    true
  );
};

export default useFrontendVersionPoll;
