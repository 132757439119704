import {
  faArrowRight,
  faArrowRotateRight,
  faCarSide,
  faPause,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentModel } from 'api';
import AddressInput from 'components/AddressInput';
import FormattedAddress from 'components/FormattedAddress';
import Input from 'components/inputs/Input';
import Slider from 'components/inputs/Slider';
import TextButton from 'components/inputs/TextButton';
import KeyValueList from 'components/KeyValueList';
import Modal, { modalContentClass } from 'components/Modal';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { IAddress } from 'utils/address';
import { openGoogleMapsDirections } from 'utils/address-helper';
import {
  formatDateTime,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import usePlannedStopOverForm from './usePlannedStopOverForm';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
  }
`;

const SubHeader = styled.h3`
  margin: 0;
  padding: 0;
  margin-top: 20px;
  font-size: ${({ theme }) => theme.sizes.font.medium};
`;

const EditableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 20px;
`;

const NoEditsWrapper = styled(EditableWrapper)`
  ${({ theme }) => css`
    background-color: ${theme.colors.background.secondary};
  `}
`;

const SliderIcon = styled(FontAwesomeIcon)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  border-radius: 50%;
  padding: 1px;
`;

const Warning = styled.span`
  color: ${({ theme }) => theme.colors.foreground.warning};
  font-size: 0.9em;
`;

const DurationWrapper = styled.div<{ warning?: boolean }>`
  display: flex;
  align-items: center;
  gap: 10px;
  ${({ warning }) =>
    warning &&
    css`
      color: ${({ theme }) => theme.colors.foreground.error};
    `}
`;

interface Props {
  originalAssignment: AssignmentModel;
  onAddressPicked(
    stopoverAddress: IAddress,
    currentAssignmentEstimatedDuration: number,
    newAssignmentEstimatedDuration: number,
    newAssignmentEstimatedStartTime: Date
  ): void;
  onCancel(): void;
}

const PlannedStopOverModal: FC<Props> = ({
  originalAssignment,
  onAddressPicked,
  onCancel,
}) => {
  const {
    stopOverAddress,
    setStopOverAddressValue,
    estimatedDurationOptions,
    currentAssignmentEstimatedDurationId,
    newAssignmentEstimatedDurationId,
    estimatedStartTimeOfNewAssignment,
    endsTooLateOrTooEarly,
    disableCreation,
    originalStartTime,
    originalEndTime,
    updatedCurrentAssignmentEndTimeBasedOnNewDurations,
    updatedNewAssignmentEndTimeBasedOnNewDurations,
    fixedAssignmentTimeFromStartToStopover,
    fixedAssignmentTimeFromStopoverToEnd,
    hasFixedAssignmentTime,
    handleCurrentAssignmentEstimatedDurationChange,
    handleNewAssignmentEstimatedDurationChange,
    handleEstimatedStartTimeChange,
    adjustEstimatedStartTimeToNextDay,
    handleOk,
  } = usePlannedStopOverForm({ originalAssignment, onAddressPicked });

  return (
    <MyModal
      buttons={[
        {
          icon: <FontAwesomeIcon icon={faPause} />,
          label: 'Skapa planerad mellanlandning',
          disabled: disableCreation,
          onClick: handleOk,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title="Skapa planerad mellanlandning"
    >
      <p>
        När du mellanlandar det här uppdraget så skapas ett nytt uppdrag för
        vidaretransport till den ursprungliga destinationen.
      </p>
      <NoEditsWrapper>
        <KeyValueList
          title="Originaluppdrag"
          colonKey
          rows={[
            {
              key: 'Från',
              value: (
                <FormattedAddress
                  address={originalAssignment.fromAddress}
                  city={originalAssignment.fromCity}
                  county={originalAssignment.fromCounty?.areaName}
                  name={originalAssignment.fromName}
                  zipCode={originalAssignment.fromZip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid',
              value: (
                <DurationWrapper>
                  <span>{formatDateTime(originalStartTime)}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {originalAssignment.estimatedDuration
                      ? getHoursAndMinutesFromMillisecondsString(
                          originalAssignment.estimatedDuration
                        )
                      : '-'}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>{formatDateTime(originalEndTime)}</span>

                  <TextButton
                    onClick={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      openGoogleMapsDirections({
                        fromAddress: originalAssignment.fromAddress,
                        fromCity: originalAssignment.fromCity,
                        fromZip: originalAssignment.fromZip,
                        toAddress: originalAssignment.toAddress,
                        toCity: originalAssignment.toCity,
                        toZip: originalAssignment.toZip,
                      });
                    }}
                  >
                    <img
                      alt="Google maps icon"
                      height={15}
                      src="/images/googlemapsiconsmall.png"
                      width={15}
                    />
                  </TextButton>
                </DurationWrapper>
              ),
            },
            {
              key: 'Till',
              value: (
                <FormattedAddress
                  address={originalAssignment.toAddress}
                  city={originalAssignment.toCity}
                  county={originalAssignment.toCounty?.areaName}
                  name={originalAssignment.toName}
                  zipCode={originalAssignment.toZip}
                />
              ),
            },
          ]}
        />
      </NoEditsWrapper>
      <EditableWrapper>
        <KeyValueList
          title="Planerad mellanlandning"
          colonKey
          rows={[
            {
              key: 'Start',
              value: (
                <FormattedAddress
                  address={originalAssignment.fromAddress}
                  city={originalAssignment.fromCity}
                  county={originalAssignment.fromCounty?.areaName}
                  name={originalAssignment.fromName}
                  zipCode={originalAssignment.fromZip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid 1',
              value: (
                <Slider
                  disabled={hasFixedAssignmentTime}
                  options={estimatedDurationOptions}
                  onChange={handleCurrentAssignmentEstimatedDurationChange}
                  value={currentAssignmentEstimatedDurationId}
                  sliderIcon={<SliderIcon icon={faCarSide} />}
                />
              ),
            },
            {
              key: 'Uppdrag 1',
              value: (
                <DurationWrapper>
                  <span>
                    {formatDateTime(originalAssignment.estimatedStartTime)}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {fixedAssignmentTimeFromStartToStopover
                      ? `${getHoursAndMinutesFromMillisecondsString(fixedAssignmentTimeFromStartToStopover)} (fast tid)`
                      : estimatedDurationOptions[
                            currentAssignmentEstimatedDurationId
                          ]
                        ? estimatedDurationOptions[
                            currentAssignmentEstimatedDurationId
                          ].label
                        : '-'}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {formatDateTime(
                      updatedCurrentAssignmentEndTimeBasedOnNewDurations
                    )}
                  </span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Mellanlandning',
              value: (
                <AddressInput
                  address={stopOverAddress.address}
                  city={stopOverAddress.city}
                  countyId={stopOverAddress.county?.id ?? null}
                  name={stopOverAddress.name}
                  zip={stopOverAddress.zip}
                  onAddressChanged={(newAddress) => {
                    setStopOverAddressValue('address', newAddress);
                  }}
                  onCityChanged={(newCity) => {
                    setStopOverAddressValue('city', newCity);
                  }}
                  onCountyChanged={(newCounty) => {
                    setStopOverAddressValue('county', newCounty ?? undefined);
                  }}
                  onNameChanged={(newName) => {
                    setStopOverAddressValue('name', newName);
                  }}
                  onZipChanged={(newZip) => {
                    setStopOverAddressValue('zip', newZip);
                  }}
                  onCompanyPicked={(company, newCounty) => {
                    setStopOverAddressValue('county', newCounty);
                    setStopOverAddressValue('city', company.city);
                    setStopOverAddressValue('name', company.name);
                    setStopOverAddressValue('address', company.address);
                    setStopOverAddressValue('zip', company.zip);
                  }}
                />
              ),
            },
            {
              key: 'Est. starttid',
              value: (
                <DurationWrapper>
                  <Input
                    error={endsTooLateOrTooEarly}
                    type="datetime-local"
                    value={formatDateTime(estimatedStartTimeOfNewAssignment)}
                    onChange={(e) => {
                      const date = new Date(e.target.value);
                      handleEstimatedStartTimeChange(date);
                    }}
                  />
                  {endsTooLateOrTooEarly && (
                    <>
                      <Warning>
                        Mellanlandningen börjar/slutar utanför arbetspasset.
                      </Warning>
                      <TextButton
                        style={{
                          fontSize: '0.9em',
                          padding: 0,
                          gap: 5,
                          display: 'flex',
                        }}
                        onClick={adjustEstimatedStartTimeToNextDay}
                      >
                        <span>Flytta till nästa dag</span>
                        <FontAwesomeIcon icon={faArrowRotateRight} />
                      </TextButton>
                    </>
                  )}
                </DurationWrapper>
              ),
            },
            {
              key: 'Est. uppdragstid 2',
              value: (
                <Slider
                  disabled={hasFixedAssignmentTime}
                  options={estimatedDurationOptions}
                  onChange={handleNewAssignmentEstimatedDurationChange}
                  value={newAssignmentEstimatedDurationId}
                  reverseDisplay
                  sliderIcon={<SliderIcon icon={faCarSide} />}
                />
              ),
            },
            {
              key: 'Uppdrag 2',
              value: (
                <DurationWrapper warning={endsTooLateOrTooEarly}>
                  <span>
                    {formatDateTime(estimatedStartTimeOfNewAssignment)}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {fixedAssignmentTimeFromStopoverToEnd
                      ? `${getHoursAndMinutesFromMillisecondsString(fixedAssignmentTimeFromStopoverToEnd)} (fast tid)`
                      : estimatedDurationOptions[
                            newAssignmentEstimatedDurationId
                          ]
                        ? estimatedDurationOptions[
                            newAssignmentEstimatedDurationId
                          ].label
                        : '-'}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {formatDateTime(
                      updatedNewAssignmentEndTimeBasedOnNewDurations
                    )}
                  </span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Slut',
              value: (
                <FormattedAddress
                  address={originalAssignment.toAddress}
                  city={originalAssignment.toCity}
                  county={originalAssignment.toCounty?.areaName}
                  name={originalAssignment.toName}
                  zipCode={originalAssignment.toZip}
                />
              ),
            },
          ]}
        />

        {/* <SubHeader>Välj mellanlandningsadress på kartan</SubHeader>
      <PickAddressMap
        fromAddress={{
          name: originalAssignment.toName,
          address: originalAssignment.toAddress,
          zip: originalAssignment.toZip,
          city: originalAssignment.toCity,
          county: originalAssignment.toCounty,
        }}
        originalToAddress={{
          name,
          address,
          zip,
          city,
          county: county ?? undefined,
        }}
        onAddressPicked={(newToAddress) => {
          console.log(newToAddress);
        }}
        onCancel={() => {
          console.log('cancel');
        }}
      /> */}
      </EditableWrapper>

      <NoEditsWrapper>
        <SubHeader style={{ margin: 0 }}>
          Ny uppdelning för vidaretransport
        </SubHeader>
        <KeyValueList
          title="Uppdrag 1"
          colonKey
          rows={[
            {
              key: 'Från',
              value: (
                <FormattedAddress
                  address={originalAssignment.fromAddress}
                  city={originalAssignment.fromCity}
                  county={originalAssignment.fromCounty?.areaName}
                  name={originalAssignment.fromName}
                  zipCode={originalAssignment.fromZip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid',
              value: (
                <DurationWrapper>
                  <span>{formatDateTime(originalStartTime)}</span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {
                      estimatedDurationOptions[
                        currentAssignmentEstimatedDurationId
                      ].label
                    }
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {formatDateTime(
                      updatedCurrentAssignmentEndTimeBasedOnNewDurations
                    )}
                  </span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Till',
              value: !stopOverAddress.address ? (
                <Warning>Ange mellanlandningsadress</Warning>
              ) : (
                <FormattedAddress
                  address={stopOverAddress.address}
                  city={stopOverAddress.city}
                  county={stopOverAddress.county?.areaName ?? undefined}
                  name={stopOverAddress.name}
                  zipCode={stopOverAddress.zip}
                />
              ),
            },
          ]}
        />

        <KeyValueList
          title="Uppdrag 2"
          colonKey
          rows={[
            {
              key: 'Från',
              value: !stopOverAddress.address ? (
                <Warning>Ange mellanlandningsadress</Warning>
              ) : (
                <FormattedAddress
                  address={stopOverAddress.address}
                  city={stopOverAddress.city}
                  county={stopOverAddress.county?.areaName ?? undefined}
                  name={stopOverAddress.name}
                  zipCode={stopOverAddress.zip}
                />
              ),
            },
            {
              key: 'Est. uppdragstid',
              value: (
                <DurationWrapper>
                  <span>
                    {formatDateTime(estimatedStartTimeOfNewAssignment)}
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>
                    {
                      estimatedDurationOptions[newAssignmentEstimatedDurationId]
                        .label
                    }
                  </span>
                  <FontAwesomeIcon icon={faArrowRight} />
                  <span>{formatDateTime(originalEndTime)}</span>
                </DurationWrapper>
              ),
            },
            {
              key: 'Till',
              value: (
                <FormattedAddress
                  address={originalAssignment.toAddress}
                  city={originalAssignment.toCity}
                  county={originalAssignment.toCounty?.areaName}
                  name={originalAssignment.toName}
                  zipCode={originalAssignment.toZip}
                />
              ),
            },
          ]}
        />
      </NoEditsWrapper>
    </MyModal>
  );
};

export default PlannedStopOverModal;
