// eslint-disable-next-line import/prefer-default-export
export function booleanCompare(a: boolean, b: boolean) {
  return a === b ? 0 : a && !b ? 1 : -1;
}

export function alphabeticCompare(valueA?: string, valueB?: string) {
  const valueAUpperCase = valueA?.toUpperCase() ?? '';
  const valueBUpperCase = valueB?.toUpperCase() ?? '';

  return valueAUpperCase < valueBUpperCase
    ? -1
    : valueAUpperCase > valueBUpperCase
    ? 1
    : 0;
}

export function makeNumberComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => number
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue - bValue;
  };
}

export function makeStringComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => string
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue > bValue ? 1 : aValue < bValue ? -1 : 0;
  };
}

export function makeBooleanComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => boolean
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue && !bValue ? 1 : bValue && !aValue ? -1 : 0;
  };
}

export function makeDateComparator<O, R extends any[]>(
  propertyPicker: (obj: O, ...rest: R) => Date
) {
  return (a: O, b: O, ...rest: R) => {
    const aValue = propertyPicker(a, ...rest);
    const bValue = propertyPicker(b, ...rest);
    return aValue.getTime() - bValue.getTime();
  };
}
