import React from 'react';
import { faCog, faDownload } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown from 'components/Dropdown';
import Button from 'components/inputs/Button';
import styled from 'styled-components';
import { ColumnSetting, TableColumnEnum } from './utils';
import Checkbox from 'components/inputs/Checkbox';
import useActiveColumns, {
  TableIdentifierEnum,
} from 'contexts/basicData/useActiveColumns';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import useTranslations from 'contexts/basicData/useTranslations';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits';
import useOutsideClick from 'hooks/useOutsideClick';

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 10px;
`;

const MyButton = styled(Button)`
  width: 100%;
  white-space: nowrap;
`;

type Props<T extends object, U extends object | undefined = undefined> = {
  id: TableIdentifierEnum;
  onDownloadCsv: (fileName: string) => void;
  columnSettings: ColumnSetting<T, U>[];
};

const TableDropdown = <
  T extends object,
  U extends object | undefined = undefined,
>({
  id,
  onDownloadCsv,
  columnSettings,
}: Props<T, U>) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { unitsById } = useBusinessUnits();
  const dropdownRef = React.useRef<HTMLDivElement | null>(null);
  useOutsideClick(dropdownRef, () => {
    if (isOpen) setIsOpen(false);
  });
  const {
    state: {
      advancedFilters,
      caseTypes,
      assignmentStatuses,
      assignmentDeviations,
      caseStatuses,
      businessUnits,
      searchText,
      daterange,
    },
  } = useAssignmentSearch();

  const {
    advancedAssignmentFilterOptions,
    assignmentStatuses: assignmentStatusTranslations,
    caseTypes: caseTypeTranslations,
    assignmentDeviations: assignmentDeviationTranslations,
    caseStatuses: caseStatusTranslations,
  } = useTranslations();

  const [activeColumns, setActiveColumns] = useActiveColumns(id);

  const handleChangeSelectedColumn = (
    identifier: TableColumnEnum,
    checked: boolean
  ) => {
    setActiveColumns({
      ...activeColumns,
      [identifier]: checked,
    });
  };

  const handleDownloadClick = () => {
    // resolve the file name based on the filters
    let fileName = 'uppdrag';
    if (searchText) {
      fileName += `-${searchText}`;
    }
    if (caseTypes.length > 0) {
      fileName += `-${caseTypes.map((x) => caseTypeTranslations[x]).join('-')}`;
    }
    if (assignmentStatuses.length > 0) {
      fileName += `-${assignmentStatuses.map((x) => assignmentStatusTranslations[x]).join('-')}`;
    }
    if (assignmentDeviations.length > 0) {
      fileName += `-${assignmentDeviations.map((x) => assignmentDeviationTranslations[x]).join('-')}`;
    }
    if (caseStatuses.length > 0) {
      fileName += `-${caseStatuses.map((x) => caseStatusTranslations[x]).join('-')}`;
    }
    if (businessUnits.length > 0) {
      fileName += `-${businessUnits.map((x) => unitsById.get(x)?.name).join('-')}`;
    }
    if (advancedFilters.length > 0) {
      fileName += `-${advancedFilters.map((x) => advancedAssignmentFilterOptions[x]).join('-')}`;
    }
    if (daterange[0]) {
      fileName += `-${daterange[0].toISOString().split('T')[0]}`;
    }
    if (daterange[1]) {
      fileName += `-${daterange[1].toISOString().split('T')[0]}`;
    }

    onDownloadCsv(fileName);
  };

  return (
    <Dropdown
      position="left"
      content={
        isOpen && (
          <ContentWrapper ref={dropdownRef}>
            <MyButton
              onClick={handleDownloadClick}
              icon={<FontAwesomeIcon icon={faDownload} />}
            >
              Ladda ner CSV
            </MyButton>
            <h3>Visa kolumn</h3>
            <Checkbox
              checked={Object.values(activeColumns).every((v) => v)}
              onChange={(e) => {
                setActiveColumns(
                  Object.fromEntries(
                    columnSettings
                      .filter((c) => c.identifier !== undefined)
                      .map((c) => [c.identifier, e.currentTarget.checked])
                  )
                );
              }}
            >
              Alla
            </Checkbox>
            {columnSettings
              .filter((c) => c.identifier !== undefined)
              .map((c, i) => (
                <Checkbox
                  key={c.head?.toString() ?? i}
                  checked={activeColumns[c.identifier ?? i]}
                  disabled={
                    Object.values(activeColumns).filter((v) => v).length ===
                      1 && activeColumns[c.identifier ?? i]
                  }
                  onChange={(e) => {
                    // if only one column is selected, don't allow to unselect it
                    if (c.identifier === undefined) return;
                    handleChangeSelectedColumn(
                      c.identifier,
                      e.currentTarget.checked
                    );
                  }}
                >
                  {c.csvHead ?? c.head}
                </Checkbox>
              ))}
          </ContentWrapper>
        )
      }
    >
      <Button
        onClick={(e) => {
          e.stopPropagation();
          setIsOpen((p) => !p);
        }}
        icon={<FontAwesomeIcon icon={faCog} />}
        iconPlacement="right"
      >
        Tabell
      </Button>
    </Dropdown>
  );
};

export default TableDropdown;
