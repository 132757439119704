import {
  faHourglass1,
  faHourglassEnd,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { DrivingPolicyReportDto } from 'api/model';
import { useSetupGetBasicData } from 'api/setup/setup';
import React from 'react';
import styled, { useTheme } from 'styled-components';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';

const Span = styled.span<{ warning: boolean }>`
  color: ${({ theme, warning }) =>
    warning ? theme.colors.foreground.error : theme.colors.foreground.primary};
  display: inline-flex;
  gap: 0.5rem;
`;

type Props = {
  drivinPolicyReport?: DrivingPolicyReportDto;
};

const WorkHoursSpan: React.FC<Props> = ({ drivinPolicyReport }) => {
  const theme = useTheme();
  const workHoursToday = drivinPolicyReport?.workHoursToday ?? 0;
  const registrationNumbers = drivinPolicyReport?.scheduledAssignmentsToday.map(
    (x) => x.caseRegistrationNumber
  );

  const basicData = useSetupGetBasicData();

  if (workHoursToday === 0) {
    return null; // in order to not confuse the user
  }

  const consecutiveHoursOfRestLast24Hours =
    basicData.data?.consecutiveHoursOfRestLast24Hours ?? 11;
  const maxWorkHoursPerDay = 24 - consecutiveHoursOfRestLast24Hours;

  return (
    <Span warning={workHoursToday > maxWorkHoursPerDay}>
      <span
        title={`Arbetad tid: ${getHoursAndMinutesFromMillisecondsString(
          workHoursToday * 1000 * 60 * 60
        )} (${registrationNumbers})`}
      >
        <FontAwesomeIcon
          icon={faHourglass1}
          color={theme.colors.foreground.tint}
        />{' '}
        {getHoursAndMinutesFromMillisecondsString(
          workHoursToday * 1000 * 60 * 60
        )}
      </span>
      <span
        title={`Tid kvar att boka: ${getHoursAndMinutesFromMillisecondsString(
          (maxWorkHoursPerDay - workHoursToday || 0) * 1000 * 60 * 60
        )}`}
      >
        <FontAwesomeIcon
          icon={faHourglassEnd}
          color={theme.colors.foreground.tint}
        />{' '}
        {getHoursAndMinutesFromMillisecondsString(
          (maxWorkHoursPerDay - workHoursToday || 0) * 1000 * 60 * 60
        )}
      </span>
    </Span>
  );
};

export default WorkHoursSpan;
