/* eslint-disable @typescript-eslint/ban-types */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { ColumnSetting, focusableTableCellClassName } from './utils';

const Cell = styled.div`
  flex-wrap: nowrap;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 3px;
  cursor: text;

  &:hover {
    box-shadow: 0 0 0 1px inset
      ${({ theme }) => theme.colors.background.selection};
  }
  &:focus-within {
    outline: none;
    background-color: ${({ theme }) => theme.colors.background.primary};
    box-shadow: 0 0 0 2px inset
      ${({ theme }) => theme.colors.background.selection};
  }
`;

interface Props<
  Row extends Object,
  RenderProps extends Object | undefined = undefined,
> {
  columnSetting: ColumnSetting<Row, RenderProps>;
  renderProps?: RenderProps;
  row: Row;
  rowIndex: number;
  className?: string;
}

const FocusableCell = <
  Row extends Object,
  RenderProps extends Object | undefined = undefined,
>({
  columnSetting,
  renderProps,
  row,
  rowIndex,
  className,
}: Props<Row, RenderProps>) => {
  const [focused, setFocused] = useState(false);
  const wrapperRef = useRef<HTMLDivElement>();

  const handleLostFocus = useCallback(() => {
    if (!wrapperRef.current?.matches(':focus-within')) {
      setFocused(false);
    }
  }, []);

  useEffect(() => {
    return () => {
      if (wrapperRef.current) {
        wrapperRef.current.removeEventListener('focusout', handleLostFocus);
      }
    };
  }, [handleLostFocus]);

  const setRef = (element: HTMLDivElement | null) => {
    if (!element) return;

    wrapperRef.current = element;
    element.addEventListener('focusout', handleLostFocus);
  };

  const cellContent = columnSetting.render(
    row,
    renderProps!,
    focused,
    rowIndex
  );
  const title = typeof cellContent === 'string' ? cellContent : undefined;

  return (
    <Cell
      className={`${className} ${focusableTableCellClassName}`}
      onClick={(eve) => eve.stopPropagation()}
      onFocus={() => setFocused(true)}
      ref={setRef}
      tabIndex={0}
      title={title}
    >
      {cellContent}
    </Cell>
  );
};

export default FocusableCell;
