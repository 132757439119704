import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalReceiptModel } from 'api/model';
import TextButton from 'components/inputs/TextButton';
import useTranslations from 'contexts/basicData/useTranslations';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { formatDateTime } from 'utils/date-helpers';

const Wrapper = styled.div<{ isEven: boolean }>`
  display: flex;
  flex-direction: row;
  margin-right: 5px;
  padding: 5px;
  ${({ isEven, theme }) =>
    `background-color: ${isEven ? theme.colors.background.secondary : theme.colors.background.primary};`};
`;

const LeftContent = styled.div<{
  denied?: boolean;
}>`
  flex: 1;
  color: ${({ theme }) => theme.colors.foreground.tint};
  font-weight: 500;
  word-break: break-word;
  white-space: pre-wrap;
  text-decoration: ${({ denied }) => (denied ? 'line-through' : '')};
`;

const RightContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 5px;
`;

const DateText = styled.div`
  font-style: italic;
  white-space: nowrap;
`;

const ItemRows = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 5px;
  text-align: right;
`;

const ItemSpan = styled.span`
  display: flex;
  flex-direction: row;
  gap: 5px;
  text-align: right;
`;

const Total = styled(ItemSpan)`
  font-weight: 500;
`;

interface Props {
  receipt: ExternalReceiptModel;
  index: number;
}

const ExternalReceipt: FC<Props> = ({ receipt, index }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { externalReceiptProviders } = useTranslations();
  return (
    <Wrapper isEven={index % 2 === 0}>
      <LeftContent title={externalReceiptProviders[receipt.provider]}>
        {externalReceiptProviders[receipt.provider]}
      </LeftContent>
      <RightContent>
        <DateText title={formatDateTime(receipt.receiptDate)}>
          {formatDateTime(receipt.receiptDate)}{' '}
          <TextButton onClick={() => setIsOpen(!isOpen)}>
            <FontAwesomeIcon icon={isOpen ? faChevronDown : faChevronRight} />
          </TextButton>
        </DateText>
        {!isOpen ? (
          <ItemSpan>
            {`${receipt.lineItems.map((i) => i.itemName).join(', ')}`}
          </ItemSpan>
        ) : (
          <ItemRows>
            {receipt.lineItems.map((i) => (
              <>
                <span>{i.itemName}</span>
                <span>{i.itemTotal} kr</span>
              </>
            ))}
          </ItemRows>
        )}
        <Total>
          {`${receipt.lineItems.reduce((acc, curr) => acc + curr.itemTotal, 0)} kr`}
        </Total>
      </RightContent>
    </Wrapper>
  );
};

export default ExternalReceipt;
