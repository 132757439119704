import { useState } from 'react';
import { HolidayModel, HolidaysClient, ITranslationLookups } from 'api';
import styled from 'styled-components';
import { useApiCall, useResponse } from 'swaggerhooks/lib';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import Button from 'components/inputs/Button';
import { formatDate, formatYear } from 'utils/date-helpers';
import LabelWrap from 'components/inputs/LabelWrap';
import { inputStyle } from 'components/inputs/Input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faFileCsv,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import DatePicker from 'components/inputs/DatePicker';
import useModalStack from 'contexts/modal/useModalStack';
import AddHolidaysModal from './AddHolidaysModal';
import Modal from 'components/Modal';
import BulkAddHolidaysModal from './BulkAddHolidaysModal';

const Wrapper = styled.div`
  padding: 20px;
`;

const NoWrap = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  height: 50px;
  padding: 10px 0px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const DatePickerButton = styled.button`
  ${inputStyle}
  display: flex;
  align-items: center;
  width: auto;
  padding: 7px 10px;
  color: ${({ theme }) => theme.colors.foreground.tint};
  cursor: pointer;

  div {
    white-space: nowrap;
  }

  span {
    display: block;
    margin-left: auto;
    padding: 7px 0px 7px 10px;
    font-size: 0.9em;
    font-weight: 500;
    color: ${({ theme }) => theme.colors.foreground.tint};
  }
`;

const DatePickerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const now = new Date();
const yearStart = new Date(now.getFullYear(), 0, 1);

const HolidaysPage = () => {
  const modalStack = useModalStack();

  const [year, setYear] = useState<Date>(yearStart);

  const holidays = useResponse(
    HolidaysClient,
    (c) =>
      c.getHolidays(
        new Date(year.getFullYear(), 0, 1),
        new Date(year.getFullYear(), 11, 31)
      ),
    [year]
  );

  const deleteHolidayCall = useApiCall(HolidaysClient, (c, m: HolidayModel) =>
    c.deleteHoliday(m.id)
  );

  const handleConfirmDelete = async (model: HolidayModel) => {
    if (!model) return;

    const [, error] = await deleteHolidayCall.run(model);

    if (!error) {
      holidays.update();
    }
  };

  const handleDeleteClick = (model: HolidayModel) => {
    modalStack.push(
      <Modal
        buttons={[
          {
            label: 'Ta bort',
            onClick: () => {
              handleConfirmDelete(model);
              modalStack.pop();
            },
          },
          {
            label: 'Avbryt',
            onClick: () => modalStack.pop(),
          },
        ]}
        title="Ta bort helgdag"
      >
        <div>Är du säker på att du vill ta bort {formatDate(model.date)}?</div>
      </Modal>
    );
  };

  const columnSettings: ColumnSetting<HolidayModel, ITranslationLookups>[] = [
    {
      head: 'Datum',
      render: (m) => formatDate(m.date),
      width: 150,
      sortFunction: (a, b) =>
        a.date === b.date ? 0 : a.date < b.date ? -1 : 1,
    },
    {
      head: 'Kommentar',
      render: (m) => m.comment,
      sortFunction: (a, b) =>
        a.comment.localeCompare(b.comment, undefined, { sensitivity: 'base' }),
      width: 150,
    },
    {
      head: '',
      render: (m) => (
        <IconWrapper>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDeleteClick(m);
            }}
          />
        </IconWrapper>
      ),
      width: 10,
    },
  ];

  return (
    <Wrapper>
      <Actions>
        <DatePickerWrap>
          <LabelWrap label="År">
            <DatePicker
              calendarStartDay={1}
              customInput={
                <DatePickerButton>
                  <FontAwesomeIcon icon={faCalendarAlt} />
                  <span>{formatYear(year)}</span>
                </DatePickerButton>
              }
              dateFormat="yyyy"
              locale="sv"
              onChange={(d) => {
                if (d) {
                  setYear(d);
                }
              }}
              selected={year}
              showYearPicker
            />
          </LabelWrap>
        </DatePickerWrap>
        <Button
          onClick={() => {
            modalStack.push(
              <AddHolidaysModal
                onCancel={() => modalStack.pop()}
                onSaved={() => {
                  modalStack.pop();
                  holidays.update();
                }}
              />
            );
          }}
        >
          <NoWrap>+ Lägg till dag</NoWrap>
        </Button>
        <Button
          onClick={() => {
            modalStack.push(
              <BulkAddHolidaysModal
                onCancel={() => modalStack.pop()}
                onSaved={() => {
                  modalStack.pop();
                  holidays.update();
                }}
              />
            );
          }}
        >
          <NoWrap style={{ gap: 5 }}>
            <span>Ladda upp csv</span>
            <FontAwesomeIcon icon={faFileCsv} />
          </NoWrap>
        </Button>
      </Actions>
      <Table
        columnSettings={columnSettings}
        onRowClick={(m) => {
          modalStack.push(
            <AddHolidaysModal
              holiday={m}
              onCancel={() => modalStack.pop()}
              onSaved={() => {
                modalStack.pop();
                holidays.update();
              }}
            />
          );
        }}
        rows={holidays.response ?? []}
        useColumnWidthAsFlexAmount
      />
    </Wrapper>
  );
};

export default HolidaysPage;
