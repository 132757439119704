/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type CaseTypeEnum = (typeof CaseTypeEnum)[keyof typeof CaseTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const CaseTypeEnum = {
  Purchase: 1,
  HomeDelivery: 2,
  InternalDelivery: 3,
  Extra: 4,
  TradeIn: 5,
  FaciliyExchange: 6,
  InternalDeliverySales: 7,
  FacilityToFacility: 8,
  ExtraCostExternalWorkshopOut: 9,
  ExtraCostExternalWorkshopReturn: 10,
  RMOther: 11,
  Swamp: 12,
} as const;
