import styled, { css } from 'styled-components';

export interface InputStyleProps {
  small?: boolean;
  width?: number | string;
  error?: boolean;
  warning?: boolean;
  disabled?: boolean;
}
export const inputStyle = css<InputStyleProps>`
  ${({ theme, small, width, error, warning }) => css`
    padding: ${small ? '1px 5px' : '5px 10px'};
    width: ${typeof width === 'string' ? width : `${width ?? 180}px`};

    background-color: ${theme.colors.background.primary};
    border: 1px solid ${theme.colors.border.primary};
    border-radius: 3px;
    font: inherit;
    color: ${warning
      ? theme.colors.foreground.warning
      : error
      ? theme.colors.foreground.error
      : theme.colors.foreground.primary};
  `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;

const Input = styled.input<InputStyleProps>`
  ${inputStyle}
`;

export const CombineInputs = styled.div`
  display: flex;
  height: 100%;
  flex-direction: row;
  input:not(:first-child),
  select:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0;
  }
  input:not(:last-child),
  select:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  flex-wrap: wrap;
`;

export default Input;
