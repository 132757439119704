import Table from 'components/Table';
import { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  Control,
  useFieldArray,
  UseFormRegister,
  useWatch,
} from 'react-hook-form';
import { CompanyForm } from './companyFormConverters';
import CompanyPicker from 'components/CompanyPicker';
import useCompanies from 'contexts/basicData/useCompanies';
import Button from 'components/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';
import { tableCellClassName } from 'components/Table/utils';
import { fakeSelectClassName } from 'components/inputs/SearchableSelect';
import { CompanyModel } from 'api';
import { autoFormatNumberInputOnBlur } from 'utils/input';

const ItemsWrapperStyle = css`
  overflow-y: auto;
  max-height: 300px;
`;

const MyTable = styled(Table)`
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
` as typeof Table;

const resetCellPaddingCss = css`
  &.${tableCellClassName} {
    padding: 0;
  }
`;

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 500px;
`;

const MyCompanyPicker = styled(CompanyPicker)`
  height: 100%;
  .${fakeSelectClassName} {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 100%;
  }
`;

const InputCell = styled.input`
  margin: 0;
  padding: 0px 3px;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;

  outline: none;
  background-color: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 0;
  font: inherit;

  &:focus {
    border-color: ${({ theme }) => theme.colors.background.selection};
  }
`;

const ButtonCell = styled.button`
  margin: 0;
  padding: 5px;

  font: inherit;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  &:focus {
    border-color: ${({ theme }) => theme.colors.background.selection};
  }
`;

const ButtonsWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  padding: 10px 0;
`;

interface Props {
  register: UseFormRegister<CompanyForm>;
  control: Control<CompanyForm, any>;
  name: 'pricesFromHere' | 'pricesToHere';
  companyId: number;
}

const CompanyTransportInput: FC<Props> = ({
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  register,
  control,
  name,
  companyId,
}) => {
  const [selectedCompany, setSelectedCompany] = useState<CompanyModel | null>(
    null
  );
  const { companiesById } = useCompanies();
  const { fields, append, remove } = useFieldArray({
    control,
    name,
  });
  const nameFieldValue = useWatch({ control, name: 'name' });

  const pricesAreFromHere = name === 'pricesFromHere';

  const fieldsWithIndex = useMemo(
    () => fields.map((f, i) => ({ initialField: f, index: i })),
    [fields]
  );

  const companyFilter = useCallback(
    (company: CompanyModel): boolean => {
      if (company.companyID === companyId) return false;
      return pricesAreFromHere
        ? !fields.find((f) => f.toCompanyId === company.companyID)
        : !fields.find((f) => f.fromCompanyId === company.companyID);
    },
    [companyId, fields, pricesAreFromHere]
  );

  return (
    <Wrapper>
      {fieldsWithIndex.length > 0 && (
        <MyTable
          columnSettings={[
            {
              head: 'Från',
              render: ({ initialField }) =>
                pricesAreFromHere
                  ? nameFieldValue
                  : companiesById.get(initialField.fromCompanyId)?.name,
              width: 200,
            },
            {
              head: 'Till',
              render: ({ initialField }) =>
                !pricesAreFromHere
                  ? nameFieldValue
                  : companiesById.get(initialField.toCompanyId)?.name,
              width: 200,
            },
            {
              head: 'Fasta timmar',
              css: resetCellPaddingCss,
              render: ({ index }) => (
                <InputCell
                  type="number"
                  key={index}
                  // {...register(`${name}.${index}.fixedTimeInMillseconds`, {
                  //   onBlur: autoFormatNumberInputOnBlur,
                  // })}
                  {...register(`${name}.${index}.fixedTimeInHours`, {
                    onBlur: autoFormatNumberInputOnBlur,
                  })}
                />
              ),
              width: 100,
            },
            {
              head: '',
              css: css`
                ${resetCellPaddingCss}
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
              `,
              render: ({ index }) => (
                <ButtonCell onClick={() => remove(index)}>
                  <FontAwesomeIcon icon={faTrash} />
                </ButtonCell>
              ),
              width: 35,
            },
          ]}
          initialSortState={{ sortedColumnIndex: -1, ascending: false }}
          rows={fieldsWithIndex}
        />
      )}

      <ButtonsWrap>
        {pricesAreFromHere ? 'Till' : 'Från'}:{' '}
        <MyCompanyPicker
          filter={companyFilter}
          itemsWrapperStyle={ItemsWrapperStyle}
          nullValueLabel="Välj en adress"
          onCompanySelected={setSelectedCompany}
          selectedCompanyId={selectedCompany?.companyID ?? null}
        />
        <Button
          disabled={selectedCompany === null}
          icon={<FontAwesomeIcon icon={faPlus} />}
          onClick={() => {
            if (!selectedCompany) return;

            setSelectedCompany(null);
            append({
              fixedTimeInHours: 0,
              fromCompanyId: pricesAreFromHere
                ? companyId
                : selectedCompany.companyID,
              toCompanyId: !pricesAreFromHere
                ? companyId
                : selectedCompany.companyID,
            });
          }}
        >
          Lägg till fast pris
        </Button>
      </ButtonsWrap>
    </Wrapper>
  );
};

export default CompanyTransportInput;
