import { AssignmentTypeEnum, AvailabilityDto, CaseModel, UserModel } from 'api';
import useUsers, { useRefreshUsersCall } from 'contexts/basicData/useUsers';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import {
  getUserPropsIconArray,
  userPropsArray,
} from 'pages/Employees/filterableUserFields';
import SearchableSelect, {
  SearchableSelectItem,
  SelectOption,
} from './inputs/SearchableSelect';
import UserModalLink from './UserModalLink';
import TextButton from './inputs/TextButton';
import AvailabilityFormModal from 'pages/Employees/Availability/AvailabilityFormModal';
import useModalStack from 'contexts/modal/useModalStack';
import { Badge } from 'pages/Employees/Availability';
import { DropdownPosition } from './Dropdown';
import { useAssignmentSortFieldTestersForNewAssignment } from 'api/assignment/assignment';
import { AssigneeWarning } from 'api/model';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDateTime } from 'utils/date-helpers';
import { faCalendarTimes } from '@fortawesome/free-regular-svg-icons';
import { userNamesExcemptFromEstimationValidation } from 'constants/AppConstants';
import TooltipInfoWarning from './TooltipInfoWarning';
import useMe from 'contexts/authentication/useMe';

export const PrioritizedUserPickerClassName = 'PrioritizedUserPicker';

const StyledSearchableSelect = styled(SearchableSelect)`
  .${PrioritizedUserPickerClassName} {
    /* Your styles for PrioritizedUserPicker within StyledSearchableSelect go here */
    max-height: 300px !important;
  }
` as typeof SearchableSelect;

const ItemsWrapperStyle = css`
  display: grid;
  grid-template-columns: max-content 1fr max-content max-content max-content;
  grid-auto-flow: row;
  .grid-row:hover ~ .grid-row {
    background-color: yellow;
  }
`;

const EmojiWrapper = styled.div<{ amount: number }>`
  ${({ amount }) => css`
    display: grid;
    grid-template-columns: repeat(${amount}, 1fr);
    grid-auto-flow: row;
  `}
`;

const StyledSelectItemRow = styled.div<{
  selected?: boolean;
  selectable?: boolean;
}>`
  display: contents; /* to allow hover on the whole row (acts like a div but inherits the grid properties) */
  ${({ selected, theme }) =>
    selected &&
    css`
      & > button {
        background: ${theme.colors.background.selection};
        color: ${theme.colors.foreground.selection};
      }
    `}
  &:hover > button {
    background-color: ${({ theme }) => theme.colors.background.selection};
  }

  ${({ selectable }) =>
    selectable &&
    css`
      cursor: pointer;
      & > button {
        cursor: pointer;
      }
    `}
`;

const StyledSelectItem = styled(SearchableSelectItem)<{
  isNoneItem?: boolean;
  isAvailable: boolean;
  isFiller?: boolean;
}>`
  width: 100%;

  ${({ isNoneItem }) =>
    isNoneItem &&
    css`
      font-style: italic;
    `}
  ${({ isFiller }) =>
    isFiller &&
    css`
      font-style: normal !important;
      font-weight: bold;
    `}

  ${({ isAvailable }) =>
    !isAvailable
      ? `
    font-style: italic;
    color: grey!important;
  `
      : `
    font-style: normal;
    color: black!important;`}
  

  ${({ isAvailable, theme }) =>
    css`
      background-color: ${theme.colors.background.selection}!important
      color: ${
        isAvailable
          ? theme.colors.foreground.primary
          : theme.colors.foreground.secondary
      }`};
`;

const StyledUserModalLink = styled(UserModalLink)`
  margin-left: 0.5rem !important;
`;

interface ExtendedUserModel {
  isIllicit?: boolean;
  isAvailable: boolean;
  isFiller?: boolean;
  user?: UserModel;
  availability?: AvailabilityDto;
  lastDerivedEstimatedEndTime?: Date;
  warnings?: AssigneeWarning[];
}

interface PrioritizedUserPickerProps {
  autoFocus?: boolean;
  caseModel: CaseModel;
  disabled?: boolean;
  dropdownPosition?: DropdownPosition;
  selectedUserId: number | null;
  bookedTo?: string;
  estimatedStartTime: string;
  actualStartTime?: string;
  actualEndTime?: string;
  estimatedDuration: number;
  assignmentType: AssignmentTypeEnum;
  onUserPicked(
    userModel: UserModel | null,
    breaksDrivingPolicy?: boolean
  ): void;
  fromZipCode?: string;
  onStateChange?(isOpen: boolean): void;
  assignmentId: number;
  requireExistingAssignmentToSelectFT?: boolean;
}

const PrioritizedCaseAssignmentUserPicker: FC<PrioritizedUserPickerProps> = ({
  autoFocus,
  caseModel,
  dropdownPosition,
  disabled,
  selectedUserId,
  bookedTo,
  estimatedStartTime,
  estimatedDuration,
  actualStartTime,
  actualEndTime,
  assignmentType,
  fromZipCode,
  onUserPicked,
  onStateChange,
  assignmentId,
  requireExistingAssignmentToSelectFT = true,
}) => {
  const me = useMe();

  const AlwaysUnallowedAssigneeWarnings: AssigneeWarning[] = useMemo(() => {
    const warnings: AssigneeWarning[] = [
      AssigneeWarning.Overlapping,
      AssigneeWarning.CannotDriveManual,
      AssigneeWarning.MissingDriverLicenseBE,
      AssigneeWarning.MissingDriverLicenseC,
    ];
    if (!me?.isAllowedToOverrideAssigneeWarnings) {
      warnings.push(
        ...[
          AssigneeWarning.IsNotAvailable,
          AssigneeWarning.NotConsecutiveHoursOfRestThisWeek,
          AssigneeWarning.NotConsecutiveHoursOfRestLast24Hours,
          AssigneeWarning.NotConsecutiveHoursOfRestLast7Days,
          AssigneeWarning.NotWithinAllowedDrivingHours,
        ]
      );
    }
    return warnings;
  }, [me?.isAllowedToOverrideAssigneeWarnings]);

  const AllowedAssigneeWarningsOnlyIfInThePast: AssigneeWarning[] =
    useMemo(() => {
      const warnings: AssigneeWarning[] = [
        AssigneeWarning.IsOnlyAvailableForFurtherTransport,
      ];
      if (me?.isAllowedToOverrideAssigneeWarnings) {
        warnings.push(AssigneeWarning.IsNotAvailable);
      }
      return warnings;
    }, [me?.isAllowedToOverrideAssigneeWarnings]);

  // const [betaFeatures] = useBetaFeatures();
  const isPastAssignment = useMemo(() => {
    if (!actualEndTime) {
      return false;
    }
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return new Date(actualEndTime) < yesterday;
  }, [actualEndTime]);
  const modalStack = useModalStack();
  const refreshUsersCall = useRefreshUsersCall();

  const [pickerIsOpen, setPickerIsOpen] = useState(false);
  useEffect(() => {
    if (onStateChange) {
      onStateChange(pickerIsOpen);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerIsOpen]);
  const [options, setOptions] = useState<
    SelectOption<number | null, ExtendedUserModel | null>[]
  >([]);
  // const { state: caseState } = useDetailedCaseDataForm();
  const users = useUsers();

  useEffect(() => {
    if (Object.keys(users).length === 0) {
      refreshUsersCall.run();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const {
    caseTypeID: caseType,
    vehicleGearBox,
    isElectric,
    isPremiumCar,
    isTrailer,
    isTruck,
  } = caseModel;

  const {
    data: filteredUsersResponse,
    refetch,
    isRefetching,
  } = useAssignmentSortFieldTestersForNewAssignment(
    {
      assignmentId: assignmentId === -1 ? undefined : assignmentId,
      bookedTo: bookedTo ? new Date(bookedTo) : undefined,
      estimatedStartTime: estimatedStartTime
        ? new Date(estimatedStartTime)
        : undefined,
      actualStartTime: actualStartTime ? new Date(actualStartTime) : undefined,
      actualEndTime: actualEndTime ? new Date(actualEndTime) : undefined,
      estimatedDuration: Number.isNaN(estimatedDuration)
        ? 0
        : estimatedDuration,
      caseType,
      assignmentType,
      vehicleGearBox: vehicleGearBox === true,
      isElectric: isElectric === true,
      isPremiumCar: isPremiumCar === true,
      isTrailer: isTrailer === true,
      isTruck: isTruck === true,
      startZipCode: fromZipCode,
    },
    {
      query: {
        enabled: pickerIsOpen && !!bookedTo,
        staleTime: 0,
      },
    }
  );

  // get filtered users from backend when the picker opens
  useEffect(() => {
    if (pickerIsOpen) {
      refetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pickerIsOpen]);

  const isSystemUser = (user: UserModel) => {
    return userNamesExcemptFromEstimationValidation.includes(user.name);
  };

  // update options when settings change
  const updateOptions = useCallback(() => {
    const et = Object.values(users).find((u) => u.name === 'ET');
    const nt = Object.values(users).find((u) => u.name === 'NT');
    const autologik = Object.values(users).find((u) => u.name === 'Autologik');
    const nackaTransport = Object.values(users).find(
      (u) => u.name === 'Nacka Transport'
    );
    const kontoret = Object.values(users).find((u) => u.name === 'Kontoret');

    const opts: SelectOption<number | null, ExtendedUserModel | null>[] = [];
    if (et) {
      opts.push({
        label: et.name,
        value: et.userID,
        extra: {
          isAvailable: true,
          user: { ...et, isActive: true } as UserModel,
        },
      });
    }

    if (nt) {
      opts.push({
        label: nt.name,
        value: nt.userID,
        extra: {
          isAvailable: true,
          user: { ...nt, isActive: true } as UserModel,
        },
      });
    }

    if (autologik) {
      opts.push({
        label: autologik.name,
        value: autologik.userID,
        extra: {
          isAvailable: true,
          user: { ...autologik, isActive: true } as UserModel,
        },
      });
    }

    if (nackaTransport) {
      opts.push({
        label: nackaTransport.name,
        value: nackaTransport.userID,
        extra: {
          isAvailable: true,
          user: { ...nackaTransport, isActive: true } as UserModel,
        },
      });
    }

    if (kontoret) {
      opts.push({
        label: kontoret.name,
        value: kontoret.userID,
        extra: {
          isAvailable: true,
          user: { ...kontoret, isActive: true } as UserModel,
        },
      });
    }

    if (!filteredUsersResponse) {
      // only populate options from the useUsers hook if we don't have a filteredUsersResponse
      Object.values(users).forEach((usr) => {
        if (userNamesExcemptFromEstimationValidation.includes(usr.name)) {
          opts.push({
            label: usr.name,
            value: usr.userID,
            extra: {
              isAvailable: true,
              user: usr,
            },
          });
        }
      });
    } else {
      // populate options from the filteredUsersResponse
      opts.push({
        label: `Rekommenderade fälttestare (${filteredUsersResponse.sortedAvailableEmployees.length} st)`,
        value: -1,
        extra: {
          isFiller: true,
        } as ExtendedUserModel,
      });

      filteredUsersResponse.sortedAvailableEmployees
        .filter(
          (e) =>
            e.fullName !== 'ET' &&
            e.fullName !== 'NT' &&
            e.fullName !== 'Autologik' &&
            e.fullName !== 'Nacka Transport'
        )
        .forEach((esr) => {
          const usr = users[esr.id];
          if (!usr) {
            return;
          }
          opts.push({
            label: usr.name,
            description: (
              <EmojiWrapper amount={Object.keys(userPropsArray).length}>
                {getUserPropsIconArray(usr).map((e) => (
                  <div aria-label={e.title} key={e.title} title={e.title}>
                    {e.icon}
                  </div>
                ))}
              </EmojiWrapper>
            ),
            value: usr.userID,
            extra: {
              isAvailable: true,
              user: {
                ...usr,
              },
              availability: esr.schedule[0],
              warnings: esr.warnings,
              lastDerivedEstimatedEndTime: esr.lastDerivedEstimatedEndTime,
            } as ExtendedUserModel,
          });
        });

      opts.push({
        label: `Fälttestare med fel symboler (${filteredUsersResponse.employeesWithoutCorrectFlags.length} st)`,
        value: -2,
        extra: {
          isFiller: true,
        } as ExtendedUserModel,
      });

      filteredUsersResponse.employeesWithoutCorrectFlags
        .filter(
          (e) =>
            e.fullName !== 'ET' &&
            e.fullName !== 'NT' &&
            e.fullName !== 'Autologik' &&
            e.fullName !== 'Nacka Transport'
        )
        .forEach((esr) => {
          const usr = users[esr.id];
          if (!usr) {
            return;
          }

          opts.push({
            label: usr.name,
            description: (
              <EmojiWrapper amount={Object.keys(userPropsArray).length}>
                {getUserPropsIconArray(usr).map((e) => (
                  <div aria-label={e.title} key={e.title} title={e.title}>
                    {e.icon}
                  </div>
                ))}
              </EmojiWrapper>
            ),
            value: usr.userID,
            extra: {
              isAvailable: false,
              user: {
                ...usr,
              },
              availability: esr.schedule[0],
              warnings: esr.warnings,
              lastDerivedEstimatedEndTime: esr.lastDerivedEstimatedEndTime,
            } as ExtendedUserModel,
          });
        });

      opts.push({
        label: `Ovalbara fälttestare (${filteredUsersResponse.illicitToDriveEmployees.length} st)`,
        value: -3,
        extra: {
          isFiller: true,
        } as ExtendedUserModel,
      });

      filteredUsersResponse.illicitToDriveEmployees
        .filter(
          (e) =>
            e.fullName !== 'ET' &&
            e.fullName !== 'NT' &&
            e.fullName !== 'Autologik' &&
            e.fullName !== 'Nacka Transport'
        )
        .forEach((esr) => {
          const usr = users[esr.id];
          if (!usr) {
            return;
          }

          opts.push({
            label: usr.name,
            description: (
              <EmojiWrapper amount={Object.keys(userPropsArray).length}>
                {getUserPropsIconArray(usr).map((e) => (
                  <div aria-label={e.title} key={e.title} title={e.title}>
                    {e.icon}
                  </div>
                ))}
              </EmojiWrapper>
            ),
            value: usr.userID,
            extra: {
              warnings: esr.warnings,
              isAvailable: false,
              user: {
                ...usr,
              },
              availability: esr.schedule[0],
              lastDerivedEstimatedEndTime: esr.lastDerivedEstimatedEndTime,
            } as ExtendedUserModel,
          });
        });
    }

    opts.unshift({
      label: bookedTo ? 'Ingen vald' : 'Välj bokat till',
      value: null,
      extra: null,
    });
    setOptions(opts);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredUsersResponse]);

  useEffect(() => {
    if (!isRefetching && filteredUsersResponse) {
      updateOptions();
    }
  }, [isRefetching, filteredUsersResponse, updateOptions]);

  const optionRender = useCallback(
    (
      key: string,
      option: SelectOption<number | null, ExtendedUserModel | null>,
      onClick: () => void,
      isSelected: boolean
    ) => {
      return (
        <StyledSelectItemRow
          key={`${key}_row`}
          selectable={
            !!(
              option.value === null ||
              (option.extra?.user && option.extra?.isAvailable)
            )
          }
          selected={isSelected}
        >
          <StyledSelectItem
            isAvailable={option.extra?.isAvailable ?? false}
            isFiller={option.extra?.isFiller}
            isNoneItem={option.value === null}
            key={`${key}_label`}
            onClick={onClick}
          >
            <span>
              {option.extra?.warnings && option.extra.warnings.length > 0 && (
                <TooltipInfoWarning
                  warnings={option.extra.warnings}
                  showOnlyTooltip
                  left={20}
                  top={-10}
                />
              )}
              {option.label}
              {option.extra?.user && !isSystemUser(option.extra.user) && (
                <span
                  style={{
                    float: 'right',
                  }}
                >
                  {option.extra?.lastDerivedEstimatedEndTime ? (
                    <span title="Senast bokad till">
                      <FontAwesomeIcon
                        icon={faCalendarTimes}
                        size="sm"
                        style={{ marginRight: 5, marginLeft: 10 }}
                      />
                      <span
                        style={{
                          display: 'inline-block',
                          minWidth: 50,
                          alignSelf: 'right',
                        }}
                      >
                        {formatDateTime(
                          new Date(option.extra.lastDerivedEstimatedEndTime)
                        )}
                      </span>
                    </span>
                  ) : (
                    ' '
                  )}
                </span>
              )}
            </span>
          </StyledSelectItem>
          <StyledSelectItem
            isAvailable={option.extra?.isAvailable ?? false}
            key={`${key}_description`}
            onClick={onClick}
          >
            {option.description}
          </StyledSelectItem>
          <StyledSelectItem
            isAvailable={option.extra?.isAvailable ?? false}
            key={`${key}_city`}
            onClick={onClick}
          >
            {`${option.extra?.user?.city ?? ''} ${
              option.extra?.user?.zipCodeLocalArea
                ? `(${option.extra.user?.zipCodeLocalArea.name})`
                : ''
            }`}
          </StyledSelectItem>
          <StyledSelectItem
            isAvailable={option.extra?.isAvailable ?? false}
            key={`${key}_link`}
          >
            {option.extra?.user ? (
              <TextButton
                onClick={() => {
                  modalStack.push(
                    <AvailabilityFormModal
                      availability={option.extra.availability!}
                      name={option.extra.user?.name ?? ''}
                      onClose={() => modalStack.pop()}
                      onSaved={() => modalStack.pop()}
                      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                      userId={option.extra?.user?.userID ?? -1}
                      viewOnly
                    />
                  );
                }}
              >
                <Badge>
                  {option.extra?.availability?.bookedAssignments ?? 0}
                </Badge>
              </TextButton>
            ) : (
              <div />
            )}
          </StyledSelectItem>
          <StyledSelectItem
            isAvailable={option.extra?.isAvailable ?? false}
            key={`${key}_avail_link`}
          >
            {option.extra?.user && !isSystemUser(option.extra?.user) ? (
              <StyledUserModalLink userId={option.extra.user.userID} />
            ) : (
              <div />
            )}
          </StyledSelectItem>
        </StyledSelectItemRow>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [modalStack]
  );

  const handleItemClick = (
    _userId: number | null,
    _: boolean,
    option: SelectOption<number | null, ExtendedUserModel | null>
  ) => {
    if (option.extra?.user) {
      if (
        // Allow picking if the only unallowed warning is IsOnlyAvailableForFurtherTransport and the assignment type is FurtherTransport
        (assignmentType === AssignmentTypeEnum.FurtherTransport &&
          option.extra.warnings?.every(
            (e) => e === AssigneeWarning.IsOnlyAvailableForFurtherTransport
          )) ||
        // or if the the assignment was in the past and it only has warnings that are allowed if the assignment is in the past
        (isPastAssignment &&
          option.extra.warnings?.every((e) =>
            AllowedAssigneeWarningsOnlyIfInThePast.includes(e)
          )) ||
        // Otherwise, allow picking only if there are no unallowed warnings...
        !option.extra.warnings?.some((e) =>
          // assignmentType !== AssignmentTypeEnum.FurtherTransport &&
          AlwaysUnallowedAssigneeWarnings.includes(e)
        )
      ) {
        onUserPicked(option.extra.user);
      }
    } else if (option.value === null) {
      onUserPicked(null);
    }
  };

  if (assignmentId === -1 && requireExistingAssignmentToSelectFT) {
    return (
      <div>Du behöver skapa uppdraget innan du kan välja fälttestare.</div>
    );
  }
  if (!bookedTo) {
    return <div>Välj bokat till för att välja fälttestare.</div>;
  }

  return (
    <StyledSearchableSelect
      autoFocus={autoFocus}
      contentClassName={PrioritizedUserPickerClassName}
      disabled={disabled}
      dropdownPosition={dropdownPosition}
      itemsWrapperStyle={ItemsWrapperStyle}
      loading={isRefetching}
      maxItems={100}
      onOpenChanged={(isOpen) => {
        setPickerIsOpen(isOpen);
      }}
      onOptionClicked={handleItemClick}
      optionRender={optionRender}
      options={options}
      searchFilter={(searchString, opts) => {
        const lowerNameFilter = searchString.toLowerCase();
        return opts.filter((opt) => {
          if (opt.value === null) {
            return true;
          }
          if (opt.value < 0) {
            return true;
          }
          return (
            (opt.extra?.user?.isActive &&
              (opt.extra?.user?.name?.toLowerCase().includes(lowerNameFilter) ||
                opt.extra?.user?.city
                  ?.toLowerCase()
                  .includes(lowerNameFilter) ||
                opt.extra?.user?.zipCodeLocalArea?.name
                  .toLowerCase()
                  .includes(lowerNameFilter) ||
                getUserPropsIconArray(opt.extra.user).some(
                  (e) =>
                    e.enabled &&
                    e.title?.toLowerCase().includes(lowerNameFilter)
                ))) ||
            false
          );
        });
        // return opts;
      }}
      selectedValue={selectedUserId}
      showNonOptionSelectedName={
        selectedUserId ? users[selectedUserId]?.name : undefined
      }
    />
  );
};

export default PrioritizedCaseAssignmentUserPicker;
