import expenseUtils from 'components/DetailedCase/Expenses/expenseUtils';
import SubMenu from 'components/SubMenu';
import Roles from 'constants/Roles';
import Routes from 'constants/Routes';
import useMe from 'contexts/authentication/useMe';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';
import { arraySpreadIf } from 'utils/spreading';

const Wrapper = styled.div``;

const ExcelExportsPages: FC = () => {
  const me = useMe();

  const protectedRole = me?.roles.includes(Roles.SuperAdmin);

  return (
    <Wrapper>
      <SubMenu
        items={[
          ...arraySpreadIf(!!protectedRole, {
            label: 'Exportera',
            path: Routes.excelExports.exports,
          }),
          ...arraySpreadIf(expenseUtils.allowCircleKSensitiveInfo(me), {
            label: 'Kvitton',
            path: Routes.excelExports.receipts,
          }),
        ]}
      />
      <Outlet />
    </Wrapper>
  );
};

export default ExcelExportsPages;
