import { CompanyModel } from 'api';
import SearchableSelect, {
  SearchableSelectItem,
  SelectOption,
} from 'components/inputs/SearchableSelect';
import useCompanies from 'contexts/basicData/useCompanies';
import { FC, useMemo } from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';
import { DropdownPosition } from './Dropdown';

export const StyledCompanySelectItem = styled(SearchableSelectItem)<{
  isNoneItem?: boolean;
}>`
  ${({ isNoneItem }) =>
    isNoneItem &&
    css`
      font-style: italic;
    `}
  .$
`;

function optionRender(
  key: string,
  option: SelectOption<number | null, CompanyModel | null>,
  onClick: () => void,
  isSelected: boolean
) {
  return (
    <StyledCompanySelectItem
      isNoneItem={option.value === null}
      key={key}
      onClick={onClick}
      selected={isSelected}
    >
      {option.label}
    </StyledCompanySelectItem>
  );
}

interface Props {
  selectedCompanyId: number | null;
  value?: number | null;
  onChange?(value: number | null): void;
  onCompanySelected(company: CompanyModel | null): void;
  filter?(company: CompanyModel): boolean;
  hideInput?: boolean;
  notNullable?: boolean;
  nullValueLabel?: string;
  dropdownPosition?: DropdownPosition;
  itemsWrapperStyle?: FlattenSimpleInterpolation;
  disabled?: boolean;
  renderItem?: (
    key: string,
    option: SelectOption<number | null, CompanyModel | null>,
    onClick: () => void,
    isSelected: boolean
  ) => React.ReactNode;
}

const CompanyPicker: FC<Props> = ({
  selectedCompanyId,
  onCompanySelected,
  value,
  onChange,
  filter,
  hideInput,
  disabled,
  itemsWrapperStyle,
  notNullable,
  nullValueLabel,
  dropdownPosition,
  renderItem,
}) => {
  const { companies } = useCompanies();

  const selectedCompany = value || selectedCompanyId;

  const handleOptionClicked = (
    _companyId: number | null,
    _selected: boolean,
    option: SelectOption<number | null, CompanyModel | null>
  ) => {
    onCompanySelected(option.extra);
    onChange?.(option.value);
  };

  const options = useMemo(() => {
    const theOptions: SelectOption<number | null, CompanyModel | null>[] =
      companies
        .filter((company) => (filter ? filter(company) : true))
        .map((company) => ({
          label: company.name,
          value: company.companyID,
          extra: company,
        }));

    if (!notNullable) {
      theOptions.unshift({
        label: nullValueLabel ?? 'Inget',
        value: null,
        extra: null,
      });
    }

    return theOptions;
  }, [companies, filter, notNullable, nullValueLabel]);

  return (
    <SearchableSelect
      dropdownPosition={dropdownPosition}
      hideInput={hideInput}
      disabled={disabled}
      itemsWrapperStyle={itemsWrapperStyle}
      onOptionClicked={handleOptionClicked}
      optionRender={renderItem || optionRender}
      options={options}
      searchFilter={(searchString, opts) => {
        const lowerSearchStr = searchString.toLowerCase();
        return opts.filter(
          (opt) =>
            // Only show active companies, the 'Inget' option, and all company names that include the search string
            opt.value === null ||
            (opt.extra?.active &&
              opt.extra?.name.toLowerCase().includes(lowerSearchStr))
        );
      }}
      selectedValue={selectedCompany}
    />
  );
};

export default CompanyPicker;
