import { CompanyModel, ITranslationLookups } from 'api';
import Button from 'components/inputs/Button';
import Checkbox from 'components/inputs/Checkbox';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Table from 'components/Table';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import useCompanies, {
  useRefreshCompaniesCall,
} from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RequestStatus } from 'swaggerhooks/lib';
import { useLocalStorage } from 'usehooks-ts';
import CompanyFormModal from './CompanyFormModal';
import ColorSelector from 'components/ColorSelector';
import Input from 'components/inputs/Input';

const Wrapper = styled.div`
  h1 {
    padding: 10px;
  }
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const Title = styled.h1`
  flex: 1;
  font-size: ${({ theme }) => theme.sizes.font.medium};
  font-weight: 500;
`;

const MyCheckbox = styled(Checkbox)`
  padding-right: 10px;
  display: flex;
  align-items: center;
`;

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

const columnSettings: ColumnSetting<CompanyModel, ITranslationLookups>[] = [
  {
    head: 'Nr',
    render: (c, props, focused, index) => index + 1,
    width: 20,
  },
  {
    head: 'Färg',
    render: (c) => (c.color ? <ColorSelector color={c.color} label="" /> : ''),
    width: 25,
    sortFunction: (a, b) => a.color?.localeCompare(b.color ?? '') ?? 0,
  },
  {
    head: 'Kortnamn',
    render: (c) => c.shortName,
    width: 80,
    sortFunction: (a, b) => (a.shortName > b.shortName ? 1 : -1),
  },
  {
    head: 'Namn',
    render: (c) => c.name,
    width: 150,
    sortFunction: (a, b) => (a.name > b.name ? 1 : -1),
  },
  {
    head: 'Address',
    render: (c) => c.address,
    width: 150,
    sortFunction: (a, b) => (a.address > b.address ? 1 : -1),
  },
  {
    head: 'Stad',
    render: (c) => c.city,
    width: 150,
    sortFunction: (a, b) => (a.city > b.city ? 1 : -1),
  },
  {
    head: 'Postkod',
    render: (c) => c.zip,
    width: 150,
    sortFunction: (a, b) => (a.zip > b.zip ? 1 : -1),
  },
  {
    head: 'Riddermark',
    render: (c) => (c.isRiddermark ? 'Ja' : 'Nej'),
    width: 150,
    sortFunction: (a, b) => (a.isRiddermark && !b.isRiddermark ? 1 : -1),
  },
  {
    head: 'Ride-ID',
    render: (c) => c.rideMappingID,
    width: 150,
    sortFunction: (a, b) =>
      (a.rideMappingID ?? 0) > (b.rideMappingID ?? 0) ? 1 : -1,
  },
  {
    head: 'Autogodkänn uppdrag',
    render: (c) => (c.autoApproveAssignment ? 'Ja' : 'Nej'),
    width: 100,
    sortFunction: (a, b) =>
      a.autoApproveAssignment === b.autoApproveAssignment
        ? 0
        : a.autoApproveAssignment
          ? -1
          : 1,
  },
  {
    head: 'Aktiv',
    render: (c) => (c.active ? 'Ja' : 'Nej'),
    width: 40,
    sortFunction: (a, b) => (a.active === b.active ? 0 : a.active ? -1 : 1),
  },
];

const CompaniesPage: FC = () => {
  const translations = useTranslations();
  const [search, setSearch] = useState<string>('');
  const { allCompanies: companies } = useCompanies();
  const refreshCompaniesCall = useRefreshCompaniesCall();
  const [showAddCompany, setShowAddCompany] = useState(false);
  const [editCompany, setEditCompany] = useState<CompanyModel | null>(null);
  const [hideInactive, setHideInactive] = useLocalStorage<boolean>(
    'hide-inactive-users',
    true
  );

  const handleCompanyUpdated = useCallback(() => {
    setShowAddCompany(false);
    setEditCompany(null);
    refreshCompaniesCall.run();
  }, [refreshCompaniesCall]);

  // if editCompany is set and the company list is refreshed, repopulate the editCompany
  useEffect(() => {
    if (editCompany) {
      const newEditCompany = companies.find(
        (c) => c.companyID === editCompany.companyID
      );
      if (newEditCompany) {
        setEditCompany(newEditCompany);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [companies]);

  const filteredCompanies = useMemo(() => {
    let result = [...companies];
    if (hideInactive) {
      result = result.filter((c) => c.active);
    }
    if (search.length > 0) {
      result = result.filter(
        (c) =>
          c.name.toLowerCase().includes(search.toLowerCase()) ||
          c.shortName.toLowerCase().includes(search.toLowerCase()) ||
          c.address.toLowerCase().includes(search.toLowerCase()) ||
          c.city.toLowerCase().includes(search.toLowerCase()) ||
          c.zip.toLowerCase().includes(search.toLowerCase())
      );
    }
    return result;
  }, [companies, hideInactive, search]);

  const renderContent = () => {
    switch (refreshCompaniesCall.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetched:
      case RequestStatus.Error: {
        if (companies.length > 0) {
          return (
            <MyTable
              columnSettings={columnSettings}
              onRowClick={(c) => setEditCompany(c)}
              renderProps={translations}
              rows={filteredCompanies}
              useColumnWidthAsFlexAmount
            />
          );
        }
        return null;
      }
      case RequestStatus.Fetching:
      default:
        return <LoadingSpinner>Hämtar...</LoadingSpinner>;
    }
  };

  return (
    <Wrapper>
      <Title>Adresser</Title>
      <Actions>
        <Input
          placeholder="Sök"
          value={search}
          onChange={(eve) => setSearch(eve.currentTarget.value)}
        />
        <MyCheckbox
          checked={hideInactive}
          onChange={(event) => setHideInactive(event.currentTarget.checked)}
        >
          Dölj inaktiva
        </MyCheckbox>
        <Button
          disabled={refreshCompaniesCall.status === RequestStatus.Fetching}
          onClick={() => setShowAddCompany(true)}
        >
          + Ny Adress
        </Button>
      </Actions>
      {showAddCompany && (
        <CompanyFormModal
          onCancel={() => setShowAddCompany(false)}
          onSaved={handleCompanyUpdated}
        />
      )}
      {editCompany && (
        <CompanyFormModal
          companyId={editCompany.companyID}
          onCancel={() => setEditCompany(null)}
          onSaved={handleCompanyUpdated}
        />
      )}
      {renderContent()}
    </Wrapper>
  );
};

export default CompaniesPage;
