/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentRequestTypeEnum =
  (typeof AssignmentRequestTypeEnum)[keyof typeof AssignmentRequestTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentRequestTypeEnum = {
  Request: 0,
  Booking: 1,
} as const;
