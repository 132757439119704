import { AssignmentExtendedModel } from 'api';
import Modal from 'components/Modal';
import useTranslations from 'contexts/basicData/useTranslations';
import { MissingExpenses } from 'pages/GlobalSearch/useAssignmentsBulkOperations';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MissingExpenseContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 10px 6px;
  margin-top: 6px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.opacityOverlay};
  }
`;

const Title = styled.div``;

const SubTitle = styled.div`
  font-weight: bold;
`;

const MissingExpense = styled.div`
  line-height: ${({ theme }) => theme.sizes.font.medium};
  padding-left: 6px;
`;

type MissingExpenseApprovalModalProps = {
  missingExpenses: MissingExpenses[];
  numSelected: number;
  handleClose?(): void;
  handleConfirm?(): void;
  handleAssignmentItemClick?(model: AssignmentExtendedModel): void;
};

const MissingExpenseApprovalModal = ({
  missingExpenses,
  numSelected,
  handleClose,
  handleConfirm,
  handleAssignmentItemClick,
}: MissingExpenseApprovalModalProps) => {
  const { expenseTypes, caseTypes } = useTranslations();

  return (
    <Modal
      buttons={[
        {
          label:
            numSelected > 1
              ? `Godkänn ${numSelected} uppdrag`
              : 'Godkänn uppdraget',
          onClick: handleConfirm,
        },
        {
          label: 'Avbryt',
          onClick: () => handleClose?.(),
        },
      ]}
      onClose={() => handleClose?.()}
      title="Godkänn"
    >
      <Container>
        <Title>
          {numSelected > 1
            ? `${
                missingExpenses.filter((me) => me.missingExpenses.length > 0)
                  .length
              } uppdrag `
            : 'Uppdraget '}
          saknar förväntade utgifter!
        </Title>
        {missingExpenses.map(
          (me) =>
            me.missingExpenses.length > 0 && (
              <MissingExpenseContainer
                key={`${me.assignment.assignment.assignmentID}_${me.missingExpenses}`}
                onClick={() => {
                  handleClose?.();
                  handleAssignmentItemClick?.(me.assignment);
                }}
              >
                <SubTitle>
                  {me.assignment.assignment.bookedTo.toLocaleDateString()}
                  {' - '}
                  {caseTypes[me.caseType]}
                  {' - '}
                  {me.assignment.assignment.assignedTo?.name}
                </SubTitle>
                {me.missingExpenses.map((e) => (
                  <MissingExpense
                    key={`${me.assignment.assignment.assignmentID}_${e}`}
                  >
                    {expenseTypes[e]}
                  </MissingExpense>
                ))}
              </MissingExpenseContainer>
            )
        )}
      </Container>
    </Modal>
  );
};

export default MissingExpenseApprovalModal;
