import Button from 'components/inputs/Button';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Checkbox from 'components/inputs/Checkbox';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  textarea {
    width: 100%;
    max-height: 250px;
    min-width: 100%;
    max-width: 100%;
    min-height: 3em;
    resize: none;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
`;

const Left = styled.div`
  display: inline-flex;
  margin-left: 0;
  gap: 6px;
  align-items: center;

  span {
    margin-bottom: 3px;
    margin-right: 6px;
  }
`;

const Right = styled.div`
  margin-left: auto;
`;

interface CommentInputProps {
  onAddComment(comment: string, internalComment: boolean): void;
  loading: boolean;
  showInternalCommentOption?: boolean;
}

const CommentInput: React.FC<CommentInputProps> = ({
  onAddComment,
  loading,
  showInternalCommentOption,
}) => {
  const theme = useTheme();
  const [commentInput, setCommentInput] = useState('');
  const [internal, setInternal] = useState(false);

  const handleCreateCommentClick = async () => {
    if (!commentInput) return;
    onAddComment(commentInput, internal);
  };

  return (
    <Wrapper>
      <AutoSizedTextArea
        disabled={loading}
        onChange={(e) => setCommentInput(e.currentTarget.value)}
        placeholder="Skriv en kommentar..."
        value={commentInput}
        onKeyDown={(eve) => {
          // if cmd + enter, trigger handleCreateCommentClick
          if (eve.ctrlKey && eve.key === 'Enter') {
            handleCreateCommentClick();
          }
        }}
      />
      <Row>
        <Left>
          {showInternalCommentOption && (
            <Checkbox
              checked={internal}
              onChange={() => setInternal(!internal)}
            >
              Intern kommentar
            </Checkbox>
          )}
        </Left>
        <Right>
          <Button
            disabled={loading}
            onClick={handleCreateCommentClick}
            type="button"
          >
            {loading ? (
              <TransparentSpinner
                color={theme.colors.foreground.button}
                small
              />
            ) : (
              'Skicka'
            )}
          </Button>
        </Right>
      </Row>
    </Wrapper>
  );
};

export default CommentInput;
