import AssignmentRequestsList from '../components/AssignmentRequestsList/AssignmentRequestsList';
import AssignmentRequestsFilterBar from '../Assignments/components/AssignmentRequestsFilterBar';
import { Outlet, useNavigate } from 'react-router-dom';
import useAssignmentRequestsSearch from 'contexts/assignmentsFilters/useAssignmentRequestsSearch';
import Routes from 'constants/Routes';

const AssignmentRequests = () => {
  const navigate = useNavigate();

  const { assignmentRequests, isLoading, refresh } =
    useAssignmentRequestsSearch();

  return (
    <>
      <AssignmentRequestsFilterBar requests={assignmentRequests} />
      <AssignmentRequestsList
        loading={isLoading}
        requests={assignmentRequests}
        onAssignmentItemClick={(model) => {
          navigate(
            `${Routes.internalDeliveryGroup.requests.index}/${model.requestID}`
          );
        }}
        onRequestUpdated={async () => {
          refresh();
        }}
        // showStatusColors={false}
      />
      <Outlet />
    </>
  );
};

export default AssignmentRequests;
