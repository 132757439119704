import { CaseEventType, EventModel } from 'api';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import Button from 'components/inputs/Button';
import Checkbox from 'components/inputs/Checkbox';
import LabelWrap from 'components/inputs/LabelWrap';
import { FC, useCallback, useState } from 'react';
import styled from 'styled-components';
import useEventEditorForm from '../useEventEditorForm';
import { EventSection } from './components';

const MyEventSection = styled(EventSection)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 5px;

  textarea {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
`;

const ButtonRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const Row = styled.div`
  display: inline-flex;
  flex-direction: row;
  gap: 6px;

  span {
    margin-left: auto;
  }
`;

interface Props {
  event: EventModel;
  onCancel?(): void;
  onSave?(
    caseId: number,
    message: string,
    eventId?: number,
    internal?: boolean,
    readByAdmin?: boolean
  ): void;
}

const CommentEditor: FC<Props> = ({ event, onSave, onCancel }) => {
  const { state, textarea } = useEventEditorForm(event);
  const [internal, setInternal] = useState(
    event.caseEventType === CaseEventType.InternalComment
  );

  const handleSave = useCallback(() => {
    onSave?.(event.caseID, state.message, event.id, internal);
  }, [onSave, event.caseID, event.id, state.message, internal]);

  return (
    <MyEventSection>
      <LabelWrap label="Kommentar">
        <AutoSizedTextArea
          onChange={textarea('message')}
          value={state.message}
        />
      </LabelWrap>

      <Row>
        <span>Intern kommentar</span>
        <Checkbox checked={internal} onChange={() => setInternal(!internal)} />
      </Row>
      <ButtonRow>
        <Button onClick={handleSave}>Spara</Button>
        <Button onClick={onCancel}>Avbryt</Button>
      </ButtonRow>
    </MyEventSection>
  );
};

export default CommentEditor;
