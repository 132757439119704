import { useUserGetUnhandledCount } from 'api/user/user';
import useAuthenticationState from 'contexts/authentication/useAuthentication';
import { useEffect, useMemo } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';

const UPDATE_INTERVAL = 30 * 1000;

const unhandledAvailabilityRequestsCountAtom = atom<number>({
  key: 'unhandledAvailabilityRequestsCountAtom',
  default: 0,
});

const unhandledDrivingPolicyWarningsCountAtom = atom<number>({
  key: 'unhandledDrivingPolicyWarningsCountAtom',
  default: 0,
});

export const useUnhandledAvailabilityRequestsCountPoll = () => {
  const setUnhandledAvailabilityRequestsCount = useSetRecoilState(
    unhandledAvailabilityRequestsCountAtom
  );
  const setUnhandledDrivingPolicyWarningsCount = useSetRecoilState(
    unhandledDrivingPolicyWarningsCountAtom
  );

  const {
    state: { isAuthenticated },
  } = useAuthenticationState();

  const getUpdatesCountCall = useUserGetUnhandledCount({
    query: {
      refetchInterval: UPDATE_INTERVAL,
      enabled: isAuthenticated,
    },
  });

  useEffect(() => {
    setUnhandledAvailabilityRequestsCount(
      getUpdatesCountCall.data?.unhandledActivityRequestsCount ?? 0
    );
    setUnhandledDrivingPolicyWarningsCount(
      getUpdatesCountCall.data?.unhandledDrivingPolicyWarningsCount ?? 0
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getUpdatesCountCall.data]);
};

const useAbsenceRequests = () => {
  const {
    state: { isAuthenticated },
  } = useAuthenticationState();

  const [unhandledAbsenceRequestsCount, setUnhandledAbsenceRequestsCount] =
    useRecoilState(unhandledAvailabilityRequestsCountAtom);

  const [
    unhandledDrivingPolicyWarningsCount,
    setUnhandledDrivingPolicyWarningsCount,
  ] = useRecoilState(unhandledDrivingPolicyWarningsCountAtom);

  const { data: unhandledAbsenceRequestsCountResult, refetch } =
    useUserGetUnhandledCount({
      query: {
        staleTime: UPDATE_INTERVAL,
        enabled: isAuthenticated,
      },
    });

  useEffect(() => {
    if (
      !Number.isNaN(
        unhandledAbsenceRequestsCountResult?.unhandledActivityRequestsCount
      ) &&
      unhandledAbsenceRequestsCountResult !== null
    ) {
      setUnhandledAbsenceRequestsCount(
        unhandledAbsenceRequestsCountResult?.unhandledActivityRequestsCount ?? 0
      );
    }
    if (
      !Number.isNaN(
        unhandledAbsenceRequestsCountResult?.unhandledDrivingPolicyWarningsCount
      ) &&
      unhandledAbsenceRequestsCountResult !== null
    ) {
      setUnhandledDrivingPolicyWarningsCount(
        unhandledAbsenceRequestsCountResult?.unhandledDrivingPolicyWarningsCount ??
          0
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unhandledAbsenceRequestsCountResult]);

  return useMemo(
    () => ({
      unhandledAbsenceRequestsCount,
      unhandledDrivingPolicyWarningsCount,
      unhandledTotalCount:
        unhandledAbsenceRequestsCount + unhandledDrivingPolicyWarningsCount,
      refetchAbsenceRequests: refetch,
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [unhandledAbsenceRequestsCount]
  );
};

export default useAbsenceRequests;
