import { amber } from 'constants/Colors';
import React, { PropsWithChildren } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 2px;
  display: flex;
  align-items: center;
  flex-direction: row;
  gap: 5px;
`;

const Light = styled.div<{ status: 'good' | 'warning' | 'error' }>`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${({ status, theme }) => {
    switch (status) {
      case 'warning':
        return amber;
      case 'error':
        return theme.colors.foreground.error;
      case 'good':
      default:
        return theme.colors.foreground.good;
    }
  }};
  color: ${({ color }) => (color === 'green' ? 'black' : 'white')};
`;

type LightBulbProps = PropsWithChildren & {
  status: 'good' | 'warning' | 'error';
};

const LightBulb: React.FC<LightBulbProps> = ({ status, children }) => {
  return (
    <Wrapper>
      <Light status={status} />
      {children}
    </Wrapper>
  );
};

export default LightBulb;
