import {
  faBell,
  faComment,
  faEdit,
  faTrash,
  faUserLock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseEventType, EventModel } from 'api';
import { ContextMenuOption } from 'components/ContextMenu';
import EllipsisContextMenu from 'components/ContextMenu/EllipsisContextMenu';
import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import { FC, useMemo } from 'react';
import styled from 'styled-components';
import { EventSection } from './components';
import DefaultEventHeader from './DefaultEventHeader';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import RichMessage from 'components/RichMessage';

const parentHoverButton = 'parentHoverButton';
const MyEventSection = styled(EventSection)`
  &:hover .${parentHoverButton}, ${`.${parentHoverButton}`}:focus-within {
    background: ${({ theme }) => theme.colors.background.button};
    color: ${({ theme }) => theme.colors.foreground.button};
  }
`;

const Horiz = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-top: 5px;

  & > *:last-child {
    align-self: flex-end;
  }
`;

const NotifyIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.foreground.newMessage};
`;

interface Props {
  event: EventModel;
  onEditClick?(): void;
  onDeleteClick?(): void;
}

const Comment: FC<Props> = ({ event, onEditClick, onDeleteClick }) => {
  const modalStack = useModalStack();
  const me = useMe();

  const menuOptions = useMemo((): ContextMenuOption[] => {
    const opts: ContextMenuOption[] = [];

    if (onEditClick)
      opts.push({
        icon: <FontAwesomeIcon icon={faEdit} />,
        label: 'Redigera',
        onClick: onEditClick,
      });

    if (onDeleteClick)
      opts.push({
        icon: <FontAwesomeIcon icon={faTrash} />,
        label: 'Ta bort',
        onClick: () =>
          modalStack.push(
            <Modal
              buttons={[
                {
                  icon: <FontAwesomeIcon icon={faTrash} />,
                  label: 'Ta bort',
                  onClick: () => {
                    modalStack.pop();
                    onDeleteClick();
                  },
                },
                { label: 'Avbryt', onClick: () => modalStack.pop() },
              ]}
              onClose={() => modalStack.pop()}
              title="Ta bort kommentar?"
            >
              Är du säker på att du vill ta bort kommentaren?
            </Modal>
          ),
      });

    return opts;
  }, [modalStack, onDeleteClick, onEditClick]);

  return (
    <MyEventSection>
      <DefaultEventHeader
        created={event.created}
        headerText={
          <>
            {event.caseEventType === CaseEventType.Comment ? (
              <>
                <FontAwesomeIcon icon={faComment} /> Kommentar
              </>
            ) : (
              <>
                <FontAwesomeIcon icon={faUserLock} /> Intern kommentar
              </>
            )}
            {!event.readByAdmin && <NotifyIcon icon={faBell} title="Oläst" />}
          </>
        }
        modified={event.modified}
        userName={event.modifiedBy}
      />

      <Horiz>
        <RichMessage text={event.message} />
        {me?.roles.includes(Roles.Admin) && (
          <EllipsisContextMenu
            className={parentHoverButton}
            options={menuOptions}
            position="right"
          />
        )}
      </Horiz>
    </MyEventSection>
  );
};

export default Comment;
