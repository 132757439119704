import { FC } from 'react';
import { Outlet } from 'react-router-dom';

import { RenderModalStack } from 'contexts/modal/useModalStack';

import NavBar from './navbar/NavBar';
import DevMenu from './DevMenu';
import { Toaster } from 'react-hot-toast';

const AppLayout: FC = () => {
  return (
    <>
      <NavBar />

      <Outlet />
      <RenderModalStack />
      <Toaster position="bottom-right" />
      <DevMenu />
    </>
  );
};

export default AppLayout;
