import { atom, DefaultValue, useRecoilState } from 'recoil';

type BetaFeaturesState = {
  showBetaFeatures?: boolean;
  showCustomTableHead?: boolean;
};

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: BetaFeaturesState | DefaultValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

const betaFeaturesAtom = atom<BetaFeaturesState | null>({
  key: 'betaAtom',
  default: null, // default state
  effects_UNSTABLE: [localStorageEffect('betaAtom')],
});

export const useBetaFeatures = (): [
  BetaFeaturesState | null,
  (newValue: BetaFeaturesState | null) => void
] => {
  return useRecoilState(betaFeaturesAtom);
};

export default useBetaFeatures;
