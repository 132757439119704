import {
  ITeamModel,
  ITranslationLookups,
  TeamModel,
  TeamsClient,
  UserModel,
} from 'api';
import Modal from 'components/Modal';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import TextArea from 'components/inputs/TextArea';
import useModalStack from 'contexts/modal/useModalStack';
import { FC, Suspense, useEffect, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';
import { useApiCall } from 'swaggerhooks/lib';
import TeamGroupPicker from './TeamGroupPicker';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import TeamGroupFormModal from './TeamGroupFormModal';
import { useRefreshTeamGroupsCall } from 'contexts/basicData/useTeamGroups';
import useUsers from 'contexts/basicData/useUsers';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import Roles from 'constants/Roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import UserPicker from 'components/UserPicker';
import useMe from 'contexts/authentication/useMe';

const Container = styled.div`
  min-width: 400px;
`;

const StyledButton = styled(AuthorizedButton)`
  height: 25px;
  padding: 0 10px;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
`;

const MyLabelWrap = styled(LabelWrap)<{ width?: number }>`
  margin-bottom: 10px;
  & > *:last-child {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
  }
`;

const Title = styled.div`
  font-size: ${({ theme }) => theme.sizes.font.medium};
  padding: 10px 0;
`;
const getDefaultValues = (team?: TeamModel): ITeamModel => {
  if (team) {
    return {
      id: team.id,
      name: team.name,
      description: team.description,
      timestamp: team.timestamp,
      teamGroupId: team.teamGroupId,
      teamMemberIds: team.teamMemberIds,
    } as ITeamModel;
  }

  return {
    id: 0,
    name: '',
    description: '',
    teams: [],
    timestamp: '',
    teamGroupId: 0,
    teamMemberIds: [],
  } as ITeamModel;
};

interface Props {
  team?: TeamModel;
  onCreated?: () => void;
  onUpdated?: () => void;
}

const TeamFormModal: FC<Props> = ({ onCreated, onUpdated, team }) => {
  const me = useMe();
  const canEdit = me?.roles.includes(Roles.Admin);
  const usersById = useUsers();
  const refreshTeamGroupsCall = useRefreshTeamGroupsCall();
  const modalStack = useModalStack();

  const createTeamCall = useApiCall(TeamsClient, (c, t: TeamModel) =>
    c.createTeam(t)
  );

  const updateTeamCall = useApiCall(TeamsClient, (c, t: TeamModel) =>
    c.updateTeam(t)
  );

  const { watch, setValue, handleSubmit, reset } = useForm<ITeamModel>({
    defaultValues: getDefaultValues(team),
  });

  useEffect(() => {
    reset(getDefaultValues(team));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [team]);

  const handleCreateClick = handleSubmit(async (data) => {
    const [, error] = await createTeamCall.run(data as TeamModel);
    if (!error) {
      onCreated?.();
      modalStack.pop();
    }
  });

  const handleUpdateClick = handleSubmit(async (data) => {
    await updateTeamCall.run(data as TeamModel);
    onUpdated?.();
    modalStack.pop();
  });

  const handleCreateNewTeamGroupClick = () => {
    modalStack.push(
      <TeamGroupFormModal
        onCreated={() => {
          refreshTeamGroupsCall.run();
        }}
      />
    );
  };

  const teamMembers = useMemo(() => {
    const memberList: UserModel[] =
      watch('teamMemberIds')?.map((id: number) => usersById[id]) ?? [];
    return memberList;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [usersById, watch('teamMemberIds')]);

  const teamMemberColumns: ColumnSetting<UserModel, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<UserModel, ITranslationLookups>[] = [
        {
          head: 'Namn',
          render: (user: UserModel) => user.name,
          width: 200,
          sortFunction: (a, b) => a?.name?.localeCompare(b?.name),
        },
        {
          head: 'Roll',
          render: (user: UserModel) =>
            user?.roles?.includes(Roles.GruppLedare) ? 'Gruppledare' : 'Medlem',
          width: 200,
          sortFunction: (a, b) =>
            a?.roles?.includes(Roles.GruppLedare)
              ? -1
              : b?.roles?.includes(Roles.GruppLedare)
              ? 1
              : 0,
        },
      ];
      if (canEdit) {
        columns.push({
          css: css`
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
          `,
          head: '',
          render: (user: UserModel) => (
            <FontAwesomeIcon
              icon={faTrash}
              onClick={() => {
                // remove user from team
                const teamMemberIds = watch('teamMemberIds') ?? [];
                const index = teamMemberIds.indexOf(user.userID);
                if (index > -1) {
                  teamMemberIds.splice(index, 1);
                }
                setValue('teamMemberIds', teamMemberIds);
              }}
            />
          ),
          width: 50,
        });
      }
      return columns;
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('teamMemberIds'), canEdit]);

  return (
    <Suspense fallback="Laddar...">
      <Modal
        buttons={[
          {
            label: 'Avbryt',
            onClick: () => modalStack.pop(),
          },
          {
            label: 'Spara',
            roles: [Roles.Admin],
            onClick: () => {
              if (team?.id) {
                handleUpdateClick();
              } else {
                handleCreateClick();
              }
            },
          },
        ]}
        onClose={() => modalStack.pop()}
        title={team?.id ? 'Redigera grupp' : 'Skapa grupp'}
      >
        <Container>
          <MyLabelWrap label="Namn">
            <Input
              onChange={(e) => setValue('name', e.currentTarget.value)}
              placeholder="Namn"
              value={watch('name') ?? ''}
            />
          </MyLabelWrap>
          <MyLabelWrap label="Beskrivning">
            <TextArea
              onChange={(e) => setValue('description', e.currentTarget.value)}
              placeholder="Beskrivning"
              value={watch('description')}
            />
          </MyLabelWrap>
          <MyLabelWrap label="Avdelning">
            <Horizontal>
              <TeamGroupPicker
                disabled={!canEdit}
                onTeamGroupSelected={(teamGroup) => {
                  setValue('teamGroupId', teamGroup?.id ?? undefined);
                }}
                selectedId={watch('teamGroupId') || null}
              />
              <StyledButton onClick={handleCreateNewTeamGroupClick}>
                + Skapa ny avdelning
              </StyledButton>
            </Horizontal>
          </MyLabelWrap>
          <Title>Medlemmar</Title>
          <Table
            columnSettings={teamMemberColumns}
            fluid
            rows={teamMembers}
            useColumnWidthAsFlexAmount
          />
          {canEdit && (
            <UserPicker
              disabled={!canEdit}
              noneOption="Lägg till medlem"
              onUserPicked={(userModel: UserModel | null) => {
                if (!userModel) return;
                setValue(
                  'teamMemberIds',
                  Array.from(
                    new Set<number>([
                      ...watch('teamMemberIds'),
                      userModel.userID,
                    ])
                  )
                );
              }}
              selectedUserId={null}
            />
          )}
        </Container>
      </Modal>
    </Suspense>
  );
};

export default TeamFormModal;
