import { DayOfWeek, DefaultScheduleDto } from 'api';

// default start ane end times for a day (06:00 – 23:59)
const defaultStart: Date = new Date();
defaultStart.setHours(6);
defaultStart.setMinutes(0);
defaultStart.setSeconds(0);
defaultStart.setMilliseconds(0);
const defaultEnd: Date = new Date();
defaultEnd.setHours(23);
defaultEnd.setMinutes(59);
defaultEnd.setSeconds(0);
defaultEnd.setMilliseconds(0);

export const getEmptyDefaultScheduleDto = (
  isBaseSchedule = false
): DefaultScheduleDto => {
  const oddWeekNumbers: number[] = [];
  for (let i = 1; i < 54; i += 1) {
    if (i % 2 === 1) {
      oddWeekNumbers.push(i);
    }
  }

  return {
    schedule: [
      {
        day: DayOfWeek.Monday,
        start: defaultStart,
        end: defaultEnd,
        comment: '',
      },
      {
        day: DayOfWeek.Tuesday,
        start: defaultStart,
        end: defaultEnd,
        comment: '',
      },
      {
        day: DayOfWeek.Wednesday,
        start: defaultStart,
        end: defaultEnd,
        comment: '',
      },
      {
        day: DayOfWeek.Thursday,
        start: defaultStart,
        end: defaultEnd,
        comment: '',
      },
      {
        day: DayOfWeek.Friday,
        start: defaultStart,
        end: defaultEnd,
        comment: '',
      },
      {
        day: DayOfWeek.Saturday,
        start: null,
        end: null,
        comment: '',
      },
      {
        day: DayOfWeek.Sunday,
        start: null,
        end: null,
        comment: '',
      },
    ],
    comment: '',
    appliesToWeekNumbers: isBaseSchedule ? undefined : oddWeekNumbers,
    isBaseSchedule,
  } as unknown as DefaultScheduleDto;
};

export const weekdays = [
  { dayOfWeek: DayOfWeek.Monday, label: 'Måndagar' },
  { dayOfWeek: DayOfWeek.Tuesday, label: 'Tisdagar' },
  { dayOfWeek: DayOfWeek.Wednesday, label: 'Onsdagar' },
  { dayOfWeek: DayOfWeek.Thursday, label: 'Torsdagar' },
  { dayOfWeek: DayOfWeek.Friday, label: 'Fredagar' },
  { dayOfWeek: DayOfWeek.Saturday, label: 'Lördagar' },
  { dayOfWeek: DayOfWeek.Sunday, label: 'Söndagar' },
];

export type ScheduleErrors = {
  index: number;
  errors: ScheduleError[];
};

export type ScheduleError = {
  day: DayOfWeek;
  missingStart: boolean;
  missingEnd: boolean;
};

export const validateSchedule = (defaultWeekSchedule: DefaultScheduleDto[]) => {
  const errors = defaultWeekSchedule.reduce((acc, cur, index) => {
    const e = cur.schedule.reduce((a, c) => {
      if (c.start && !c.end) {
        return [...a, { day: c.day, missingEnd: true, missingStart: false }];
      }

      if (c.end && !c.start) {
        return [...a, { day: c.day, missingEnd: false, missingStart: true }];
      }

      return a;
    }, [] as ScheduleError[]);

    return [...acc, { index, errors: e }];
  }, [] as ScheduleErrors[]);

  if (errors.some((e) => e.errors.length > 0)) {
    return errors;
  }

  return null;
};
