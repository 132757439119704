/* eslint-disable @typescript-eslint/ban-types */

import { css } from 'styled-components';

export interface ColumnSetting<
  Row extends Object,
  RenderProps extends Object | undefined,
> {
  identifier?: TableColumnEnum;
  head: React.ReactNode;
  csvHead?: string;
  sortFunction?: (a: Row, b: Row) => number;
  width: number;
  focusable?: boolean;
  blurOnLostFocus?: boolean;
  render(
    row: Row,
    renderProps: RenderProps,
    focused: boolean,
    rowIndex: number
  ): React.ReactNode;
  css?: ReturnType<typeof css>;
  csvValue?(row: Row, rowIndex: number): string;
  hideByDefault?: boolean;
  excludeFromDownload?: boolean;
}

export enum TableColumnEnum {
  Select,
  RowNumber,
  CaseID,
  CaseHasMultipleAssignments,
  CaseRegistrationNumber,
  CaseLinkedCases,
  CaseGearBox,
  CaseMileage,
  Company,
  Created,
  EstimatedDuration,
  AssignmentStartTime,
  AssignmentEndTime,
  AssignmentStatus,
  CaseStatus,
  Assignee,
  AssigneeUnavailable,
  AssignmentWarnings,
  AssignmentBookedTo,
  AssignmentDeadlineReady,
  AssignmentUnreadComments,
  AssignmentEstimatedDuration,
  AssignmentEstimatedStartTime,
  AssignmentFromAddress,
  AssignmentFromCounty,
  AssignmentToName,
  AssignmentToAddress,
  AssignmentToCounty,
  AssignmentUpdatesFromRide,
  AssignmentInfoFromRide,
  AssignmentStandardizedComments,
  AssignmentBreakTime,
  AssignmentWorkedTime,
  CaseType,
  VehicleMileage,
}

export const tableHeadClassName = 'table-head';
export const tableCellClassName = 'table-cell';
export const focusableTableCellClassName = 'table-cell-focusable';

export function makeColumnClassName(index: number) {
  return `column-${index}`;
}
