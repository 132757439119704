/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { forwardRef, useMemo, useState } from 'react';
import {
  AverageAssignmentDurationModel,
  AverageAssignmentDurationsClient,
  ITranslationLookups,
} from 'api';
import styled from 'styled-components';
import { RequestStatus, useApiCall, useResponse } from 'swaggerhooks/lib';
import Table from 'components/Table';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import useTranslations from 'contexts/basicData/useTranslations';
import Button from 'components/inputs/Button';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import {
  formatDateTime,
  formatMonth,
  getHoursAndMinutesFromMillisecondsString,
} from 'utils/date-helpers';
import TooltipInfo from 'components/TooltipInfo';
import DatePicker, { DatePickerButton } from 'components/inputs/DatePicker';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Wrapper = styled.div`
  padding: 20px;
`;

const DatePickerContainer = styled.div``;

const CustomInput = forwardRef<HTMLButtonElement, { date: Date }>(
  ({ date, ...props }, ref) => (
    <DatePickerButton ref={ref} type="button" {...props}>
      <div>{formatMonth(date)}</div>
      <span>
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
    </DatePickerButton>
  )
);

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

const NoWrap = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px 0px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const renderMonthContent = (
  month: Date,
  shortMonth: string,
  longMonth: string
) => {
  const tooltipText = `Tooltip for month: ${longMonth}`;
  return <span title={tooltipText}>{shortMonth}</span>;
};

const AssignmentDurationsPage = () => {
  const { assignmentTypes, caseTypes } = useTranslations();
  const [selectedMonth, setSelectedMonth] = useState<Date>(new Date());
  // const [search, setSearch] = useState('');
  // eslint-disable-next-line @typescript-eslint/no-unused-vars

  const averageDurations = useResponse(
    AverageAssignmentDurationsClient,
    (c) => c.getAverageAssignmentDurations(formatMonth(selectedMonth)),
    [selectedMonth]
  );

  const updateAverageDurationsCall = useApiCall(
    AverageAssignmentDurationsClient,
    (c, _month: Date) => c.updateAverageAssignmentDurations(formatMonth(_month))
  );

  const columnSettings: ColumnSetting<
    AverageAssignmentDurationModel,
    ITranslationLookups
  >[] = [
    {
      head: 'Ärendetyp',
      render: (m) => caseTypes[m.caseTypeID],
      width: 150,
      sortFunction: (a, b) =>
        caseTypes[a.caseTypeID]?.localeCompare(caseTypes[b.caseTypeID] ?? '') ??
        0,
    },
    {
      head: 'Uppdragstyp',
      render: (m) => assignmentTypes[m.assignmentTypeID],
      sortFunction: (a, b) =>
        assignmentTypes[a.assignmentTypeID]?.localeCompare(
          assignmentTypes[b.assignmentTypeID] ?? ''
        ) ?? 0,
      width: 150,
    },
    {
      head: 'Från län',
      render: (m) => `${m.fromCounty} `,
      sortFunction: (a, b) => a.fromCounty.localeCompare(b.fromCounty),
      width: 150,
    },
    {
      head: 'Från postnr',
      render: (m) => m.fromZipCode,
      sortFunction: (a, b) => a.fromZipCode.localeCompare(b.fromZipCode),
      width: 100,
    },
    {
      head: 'Till län',
      render: (m) => m.toCounty,
      sortFunction: (a, b) => a.toCounty.localeCompare(b.toCounty),
      width: 150,
    },
    {
      head: 'Till postnr',
      render: (m) => m.toZipCode,
      sortFunction: (a, b) => a.toZipCode.localeCompare(b.toZipCode),
      width: 100,
    },
    {
      head: 'Underlag',
      render: (m) => m.sampleSize,
      sortFunction: (a, b) => (a.sampleSize ?? 0) - (b.sampleSize ?? 0),
      width: 150,
    },
    {
      head: 'Genomsnittlig tid',
      render: (m) =>
        getHoursAndMinutesFromMillisecondsString(m.averageDuration * 1000 * 60),
      sortFunction: (a, b) => a.averageDuration - b.averageDuration,
      width: 150,
    },
    {
      head: 'Lägsta tid',
      render: (m) =>
        getHoursAndMinutesFromMillisecondsString(m.minDuration * 1000 * 60),
      sortFunction: (a, b) => a.minDuration - b.minDuration,
      width: 120,
    },
    {
      head: 'Högsta tid',
      render: (m) =>
        getHoursAndMinutesFromMillisecondsString(m.maxDuration * 1000 * 60),
      sortFunction: (a, b) => a.maxDuration - b.maxDuration,
      width: 120,
    },
    {
      head: 'Senast uppdaterad',
      render: (m) => formatDateTime(m.created),
      sortFunction: (a, b) =>
        a.created.getMilliseconds() - b.created.getMilliseconds(),
      width: 150,
    },
  ];

  const handleUpdateListClick = async () => {
    await updateAverageDurationsCall.run(selectedMonth);
    averageDurations.update();
  };

  const lastUpdatedDate = useMemo(() => {
    let lastUpdated: Date | undefined;
    averageDurations.response?.forEach((d) => {
      if (lastUpdated === undefined || d.created > lastUpdated) {
        lastUpdated = d.created;
      }
    });
    return lastUpdated;
  }, [averageDurations.response]);

  const handleChangeDate = (
    newDate: Date | [Date | null, Date | null] | null
  ) => {
    if (newDate) {
      if (!Array.isArray(newDate)) {
        setSelectedMonth(newDate);
      }
    }
  };

  return (
    <Wrapper>
      <Actions>
        <DatePickerContainer>
          <DatePicker
            // customInput={<CustomInput date={selectedMonth} />}
            customInput={<CustomInput date={selectedMonth} />}
            dateFormat="yyyy-MM"
            onChange={(date) => {
              if (date) {
                setSelectedMonth(date);
              }
            }}
            selected={selectedMonth}
            showMonthYearPicker
          />
        </DatePickerContainer>
        <Button
          disabled={[
            updateAverageDurationsCall.status,
            averageDurations.status,
          ].includes(RequestStatus.Fetching)}
          onClick={handleUpdateListClick}
        >
          <NoWrap>
            Räkna om tider
            {updateAverageDurationsCall.status === RequestStatus.Fetching && (
              <TransparentSpinner small />
            )}
          </NoWrap>
        </Button>
        Senast uppdaterad:{' '}
        {lastUpdatedDate ? formatDateTime(lastUpdatedDate) : 'aldrig'}
        <TooltipInfo
          info={
            <div style={{ minWidth: 200 }}>
              Dessa värden kommer att användas för att föreslå estimerad
              uppdragstid i framtiden.
            </div>
          }
        />
      </Actions>
      <MyTable
        columnSettings={columnSettings}
        rows={averageDurations.response ?? []}
        useColumnWidthAsFlexAmount
      />
    </Wrapper>
  );
};

export default AssignmentDurationsPage;
