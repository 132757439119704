import { CompanyModel, CompanyTransportPriceModel } from 'api/model';
import {
  CompanyTransportPriceForm,
  companyTransportPriceFormToCompanyTransportModel,
  createCompanyTransportPriceForm,
} from './companyTransportPriceFormConverters';

export type CompanyForm = Omit<
  CompanyModel,
  'companyID' | 'init' | 'toJSON' | 'pricesFromHere' | 'pricesToHere'
> & {
  pricesFromHere: CompanyTransportPriceForm[];
  pricesToHere: CompanyTransportPriceForm[];
};

export const createCompanyForm = (company?: CompanyModel): CompanyForm => {
  if (company) {
    return {
      active: !!company.active,
      name: company.name,
      shortName: company.shortName,
      address: company.address,
      city: company.city,
      zip: company.zip,
      isRiddermark: company.isRiddermark,
      rideMappingID: company.rideMappingID,
      autoApproveAssignment: company.autoApproveAssignment,
      showInApp: company.showInApp,
      pricesFromHere: company.pricesFromHere.map((price) =>
        createCompanyTransportPriceForm(price)
      ),
      pricesToHere: company.pricesToHere.map((price) =>
        createCompanyTransportPriceForm(price)
      ),
      color: company.color,
      showInInternalDeliveryOverview: company.showInInternalDeliveryOverview,
      isLogisticsCenter: company.isLogisticsCenter,
      isExternalWorkshop: company.isExternalWorkshop,
      latitude: company.latitude,
      longitude: company.longitude,
    };
  }

  return {
    active: true,
    name: '',
    shortName: '',
    address: '',
    city: '',
    zip: '',
    isRiddermark: false,
    rideMappingID: undefined,
    autoApproveAssignment: false,
    pricesFromHere: [],
    pricesToHere: [],
    color: '',
    showInApp: false,
    showInInternalDeliveryOverview: false,
    isLogisticsCenter: false,
    isExternalWorkshop: false,
    latitude: undefined,
    longitude: undefined,
  };
};

export const companyFormToCompanyModel = (
  companyForm: CompanyForm,
  base?: CompanyModel
): CompanyModel => {
  const existingPricesFromHereByToCompanyId = new Map<
    number,
    CompanyTransportPriceModel
  >(base?.pricesFromHere.map((price) => [price.toCompanyId, price]));

  const existingPricesToHereByFromCompanyId = new Map<
    number,
    CompanyTransportPriceModel
  >(base?.pricesToHere.map((price) => [price.fromCompanyId, price]));

  return {
    active: companyForm.active,
    companyID: base?.companyID ?? -1,
    name: companyForm.name,
    shortName: companyForm.shortName,
    address: companyForm.address,
    city: companyForm.city,
    zip: companyForm.zip,
    isRiddermark:
      typeof companyForm.isRiddermark === 'string'
        ? companyForm.isRiddermark === 'true'
        : companyForm.isRiddermark,
    // had to fall back to undefined below as empty output of react-hook-form is, as turns out, not undefined, but an empty string
    rideMappingID: companyForm.rideMappingID || undefined,
    autoApproveAssignment: companyForm.autoApproveAssignment,
    pricesFromHere: companyForm.pricesFromHere.map((price) =>
      companyTransportPriceFormToCompanyTransportModel(
        price,
        existingPricesFromHereByToCompanyId.get(price.toCompanyId)
      )
    ),
    pricesToHere: companyForm.pricesToHere.map((price) =>
      companyTransportPriceFormToCompanyTransportModel(
        price,
        existingPricesToHereByFromCompanyId.get(price.fromCompanyId)
      )
    ),
    showInApp: companyForm.showInApp,
    showInInternalDeliveryOverview: companyForm.showInInternalDeliveryOverview,
    color: companyForm.color,
    isLogisticsCenter: companyForm.isLogisticsCenter,
    isExternalWorkshop: companyForm.isExternalWorkshop,
    latitude: companyForm.latitude,
    longitude: companyForm.longitude,
  } as CompanyModel;
};
