import { Suspense, useCallback, useMemo, useState } from 'react';
import { ITranslationLookups } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';
import useUsers from 'contexts/basicData/useUsers';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faWarning } from '@fortawesome/free-solid-svg-icons';
import styled from 'styled-components';
import Input from 'components/inputs/Input';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import useModalStack from 'contexts/modal/useModalStack';
import { formatDateTime, formatEstimatedStartAndEnd } from 'utils/date-helpers';
import DetailedCaseProvider from 'components/DetailedCase/DetailedCaseProvider';
import { CaseModal } from 'pages/Case';
import DetailedCaseFormProvider from 'components/DetailedCase/DetailedCaseFormProvider';
import { ColumnSetting } from 'components/Table/utils';
import Table from 'components/Table';
import { useDrivingPolicyGetAssignmentsInNeedOfHandling } from 'api/driving-policy/driving-policy';
import {
  AssignmentInNeedOfHandlingDto,
  InNeedOfHandlingTypeEnum,
} from 'api/model';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const FullSpan = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  white-space: break-spaces;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const CommentsWrapper = styled.div`
  cursor: pointer;
`;

const DrivingPolicyWarningsPage = () => {
  const [search, setSearch] = useState<string>('');

  const { caseTypes } = useTranslations();
  const modalStack = useModalStack();
  const users = useUsers();
  const me = useMe();

  const { data: assignmentsInNeedOfHandling, isLoading } =
    useDrivingPolicyGetAssignmentsInNeedOfHandling({
      query: {
        staleTime: 1000 * 30, // 30 seconds
      },
    });

  const handleRowClick = useCallback(
    (a: AssignmentInNeedOfHandlingDto) => {
      if (me?.roles.includes(Roles.Admin)) {
        modalStack.push(
          <DetailedCaseProvider caseId={a.assignment.caseID}>
            <DetailedCaseFormProvider>
              <CaseModal
                onClose={() => modalStack.pop()}
                assignmentIdForMarkingAsHandled={a.assignment.assignmentID}
              />
            </DetailedCaseFormProvider>
          </DetailedCaseProvider>
        );
      }
    },
    [me?.roles, modalStack]
  );

  const columnSettings: ColumnSetting<
    AssignmentInNeedOfHandlingDto,
    ITranslationLookups
  >[] = useMemo(() => {
    const columns: ColumnSetting<
      AssignmentInNeedOfHandlingDto,
      ITranslationLookups
    >[] = [
      {
        head: 'Namn',
        render: (item) => (
          <FullSpan onClick={() => handleRowClick(item)}>
            {users[item.fieldTesterID ?? -1]?.name ?? ''}
          </FullSpan>
        ),
        width: 150,
        sortFunction: (a, b) => {
          const nameA = users[a.fieldTesterID ?? -1]?.name ?? '';
          const nameB = users[b.fieldTesterID ?? -1]?.name ?? '';
          return nameA.localeCompare(nameB);
        },
      },
      {
        head: 'Datum',
        render: (item) => (
          <FullSpan onClick={() => handleRowClick(item)}>
            {item.created ? formatDateTime(new Date(item.created)) : ''}
          </FullSpan>
        ),
        width: 120,
        sortFunction: (a, b) =>
          (a.created?.getTime() ?? 0) - (b.created?.getTime() ?? 0),
      },
      {
        head: 'Anledning',
        render: (item) => (
          <Horizontal onClick={() => handleRowClick(item)}>
            <FontAwesomeIcon color="red" icon={faWarning} />
            <FullSpan>
              {item.reasons.includes(
                InNeedOfHandlingTypeEnum.HAS_NOT_BEEN_STARTED_IN_TIME ||
                  InNeedOfHandlingTypeEnum.EXPECTED_TO_START_LATE
              )
                ? 'Försenad'
                : 'Körtidspolicy'}
            </FullSpan>
          </Horizontal>
        ),
        width: 160,
      },
      {
        head: 'Kommentar',
        render: (item) => (
          <CommentsWrapper onClick={() => handleRowClick(item)}>
            <strong>Uppdrag</strong>
            <br />
            {item !== undefined
              ? `${item.assignmentCase.case.registrationNumber} (${
                  caseTypes[item.assignmentCase.case?.caseTypeID ?? -1]
                }) ${item.assignment?.fromAddress} – ${
                  item.assignment?.toAddress
                }`
              : ''}
            <br />
            {item.assignment
              ? `${formatEstimatedStartAndEnd(item.assignment)}`
              : ''}
            <br />
            <strong>Problem</strong>
            <ul>
              {item.reasons.includes(
                InNeedOfHandlingTypeEnum.BREAKS_HAS_CONSECUTIVE_HOURS_OF_REST_LAST_24_HOURS
              ) ? (
                <li>För lite vila senaste 24 timmarna</li>
              ) : (
                ''
              )}
              {item.reasons.includes(
                InNeedOfHandlingTypeEnum.BREAKS_HAS_CONSECUTIVE_HOURS_OF_REST_THIS_WEEK
              ) ? (
                <li>För lite vila innevarande vecka</li>
              ) : (
                ''
              )}
              {item.reasons.includes(
                InNeedOfHandlingTypeEnum.BREAKS_HAS_CONSECUTIVE_HOURS_OF_REST_LAST_7_DAYS
              ) ? (
                <li>För lite vila senaste 7 dagarna</li>
              ) : (
                ''
              )}
              {item.reasons.includes(
                InNeedOfHandlingTypeEnum.BREAKS_IS_WITHIN_ALLOWED_DRIVING_HOURS
              ) ? (
                <li>Överskrider tillåten mängd körtid</li>
              ) : (
                ''
              )}
              {item.reasons.includes(
                InNeedOfHandlingTypeEnum.HAS_NOT_BEEN_STARTED_IN_TIME
              ) ? (
                <li>Uppdraget har inte startats i tid</li>
              ) : (
                ''
              )}
              {item.reasons.includes(
                InNeedOfHandlingTypeEnum.EXPECTED_TO_START_LATE
              ) ? (
                <li>Fälttestaren förväntas bli sen</li>
              ) : (
                ''
              )}
            </ul>
          </CommentsWrapper>
        ),
        width: 500,
      },
    ];
    return columns;
  }, [caseTypes, handleRowClick, users]);

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <Wrapper>
        <Actions>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Filtrera på namn eller anledning"
            value={search}
            width={230}
          />
          {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        </Actions>

        {assignmentsInNeedOfHandling ? (
          <Table
            columnSettings={columnSettings}
            // onRowClick={handleRowClick}
            rows={assignmentsInNeedOfHandling}
          />
        ) : (
          <LoadingSpinner />
        )}
      </Wrapper>
    </Suspense>
  );
};

export default DrivingPolicyWarningsPage;
