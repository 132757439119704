import {
  DateFormat,
  FilterButton,
  FilterPickerContainer,
} from '../../components/filterBars';

interface Props {
  selected: string;
  onSelect: (selected: DateFormat) => void;
}

const AdFilterPicker: React.FC<Props> = ({ selected, onSelect }) => {
  return (
    <FilterPickerContainer>
      <FilterButton
        onClick={() => onSelect('date')}
        selected={selected === 'date'}
      >
        Dag
      </FilterButton>
      <FilterButton
        onClick={() => onSelect('week')}
        selected={selected === 'week'}
      >
        Vecka
      </FilterButton>
      {/* <FilterButton
        onClick={() => onSelect('month')}
        selected={selected === 'month'}
      >
        Månad
      </FilterButton>
      <FilterButton
        onClick={() => onSelect('year')}
        selected={selected === 'year'}
      >
        År
      </FilterButton> */}
    </FilterPickerContainer>
  );
};

export default AdFilterPicker;
