import { AssignmentViewModel } from 'api';
import { FC, useCallback, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import SearchableSelect, {
  fakeSelectClassName,
  SearchableSelectItem,
  SelectOption,
} from './inputs/SearchableSelect';
import useTranslations from 'contexts/basicData/useTranslations';
import { formatDateTime } from 'utils/date-helpers';

const StyledSearchableSelect = styled(SearchableSelect)`
  .${fakeSelectClassName} {
    margin: 2px 2px 2px 0;
    padding: 0.5rem 0.5rem 0.5rem 0.5rem;
  }
` as typeof SearchableSelect;

export const StyledAssignmentSelectItem = styled(SearchableSelectItem)<{
  isNoneItem?: boolean;
  isHovering?: boolean;
}>`
  width: 100%;
  ${({ isNoneItem }) =>
    isNoneItem &&
    css`
      font-style: italic;
    `}
  ${({ isHovering, theme }) =>
    isHovering &&
    `
      background-color: ${theme.colors.background.selection}!important;
      color: ${theme.colors.foreground.selection}!important;
    `}

    border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary}};
`;

interface Props {
  disabled?: boolean;
  assignments: AssignmentViewModel[];
  selectedId: number | null;
  onSelected(userModel: AssignmentViewModel | null): void;
  autoFocus?: boolean;
  className?: string;
  noSelectionLabel?: string;
  overrideOptionRender?: (
    key: string,
    option: SelectOption<number | null, AssignmentViewModel | null>,
    onClick: () => void,
    isSelected: boolean
  ) => JSX.Element;
  onSearch?: (searchString: string) => void;
}

const AssignmentPicker: FC<Props> = ({
  disabled,
  selectedId,
  onSelected,
  assignments,
  autoFocus,
  className,
  noSelectionLabel,
  overrideOptionRender,
  onSearch,
}) => {
  const translations = useTranslations();
  const [hovering, setHovering] = useState<string>();

  const optionRender = useCallback(
    (
      key: string,
      option: SelectOption<number | null, AssignmentViewModel | null>,
      onClick: () => void,
      isSelected: boolean
    ) => {
      return (
        <StyledAssignmentSelectItem
          isHovering={hovering === key}
          isNoneItem={option.value === null}
          key={`${key}_label`}
          onClick={onClick}
          onMouseOut={() => setHovering(undefined)}
          onMouseOver={() => setHovering(key)}
          selected={isSelected}
        >
          <div>{option.label}</div>
          {option.description}
        </StyledAssignmentSelectItem>
      );
    },
    [hovering]
  );

  const handleItemClick = (
    _userId: number | null,
    _: boolean,
    option: SelectOption<number | null, AssignmentViewModel | null>
  ) => {
    onSelected(option.extra);
  };

  const options = useMemo(() => {
    const opts: SelectOption<number | null, AssignmentViewModel | null>[] =
      assignments
        .map((model) => ({
          label: `${model.case.registrationNumber} | ${
            translations.caseTypes[model.case.caseTypeID]
          } | ${formatDateTime(model.assignment.bookedTo)}`,
          description: `${
            model.assignment.fromName || model.assignment.fromAddress
          } – ${model.assignment.toName || model.assignment.toAddress}`,
          value: model.assignment.assignmentID,
          extra: model,
        }))
        .sort((a, b) => {
          if (a.extra.assignment.bookedTo < b.extra.assignment.bookedTo) {
            return -1;
          }
          if (a.extra.assignment.bookedTo > b.extra.assignment.bookedTo) {
            return 1;
          }
          return 0;
        });

    opts.unshift({
      label: noSelectionLabel || 'Inget valt',
      value: null,
      extra: null,
    });
    return opts;
  }, [assignments, noSelectionLabel, translations.caseTypes]);

  return (
    <StyledSearchableSelect
      onSearch={onSearch}
      disabled={disabled}
      autoFocus={autoFocus}
      className={`${className}`}
      onOptionClicked={handleItemClick}
      optionRender={overrideOptionRender ?? optionRender}
      options={options}
      searchFilter={(searchString, opts) => {
        const lowerNameFilter = searchString.toLowerCase();
        return opts.filter(
          (opt) =>
            opt.value === null ||
            opt.extra?.case.registrationNumber
              .toLowerCase()
              .includes(lowerNameFilter) ||
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            translations.caseTypes[opt.extra!.case.caseTypeID]
              ?.toLowerCase()
              .includes(lowerNameFilter) ||
            opt.extra?.assignment.assignedTo?.name
              .toLowerCase()
              .includes(lowerNameFilter)
        );
      }}
      selectedValue={selectedId}
    />
  );
};

export default AssignmentPicker;
