const toHoursAndMinutesString = (decimalTime: number) => {
  let total = decimalTime * 60 * 60;
  const hours = Math.floor(total / (60 * 60));
  total -= hours * 60 * 60;
  const minutes = Math.floor(total / 60);
  total -= minutes * 60;

  return `${hours}h ${minutes}m`;
};

export const formatHoursAndMinutesString = (str: string) => {
  return str
    .padStart(str.length + 1, '^')
    .replace(' och 0minuter', '')
    .replace('^1timmar', '^1h')
    .replace(' 1minuter', ' 1m')
    .replace('^0timmar och', '^')
    .replace('^', '');
};

export default toHoursAndMinutesString;
