/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation } from '@tanstack/react-query';
import type {
  MutationFunction,
  UseMutationOptions,
  UseMutationResult,
} from '@tanstack/react-query';
import type {
  AuthenticationForgotPasswordEmailBody,
  AuthenticationResendConfirmationEmailParams,
  LoginRequest,
  LoginResponse,
  ProblemDetails,
  RefreshTokenLoginRequest,
  ResetPasswordRequest,
} from '.././model';
import useAuthenticationLoginMutator from '.././mutator/custom-instance';
import useAuthenticationValidateRefreshTokenMutator from '.././mutator/custom-instance';
import useAuthenticationSignoutMutator from '.././mutator/custom-instance';
import useAuthenticationForgotPasswordMutator from '.././mutator/custom-instance';
import useAuthenticationResetPasswordMutator from '.././mutator/custom-instance';
import useAuthenticationResendConfirmationEmailMutator from '.././mutator/custom-instance';
import useAuthenticationTestAuthenticationMutator from '.././mutator/custom-instance';
import useAuthenticationTestAdminRoleMutator from '.././mutator/custom-instance';
import useAuthenticationTestUserRoleMutator from '.././mutator/custom-instance';

export const useAuthenticationLoginHook = () => {
  const authenticationLogin = useAuthenticationLoginMutator<LoginResponse>();

  return (loginRequest: LoginRequest) => {
    return authenticationLogin({
      url: `/api/Authentication/Login`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: loginRequest,
    });
  };
};

export const useAuthenticationLoginMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationLoginHook>>>,
    TError,
    { data: LoginRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationLoginHook>>>,
  TError,
  { data: LoginRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationLogin = useAuthenticationLoginHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationLoginHook>>>,
    { data: LoginRequest }
  > = (props) => {
    const { data } = props ?? {};

    return authenticationLogin(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationLoginMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationLoginHook>>>
>;
export type AuthenticationLoginMutationBody = LoginRequest;
export type AuthenticationLoginMutationError = unknown;

export const useAuthenticationLogin = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationLoginHook>>>,
    TError,
    { data: LoginRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationLoginHook>>>,
  TError,
  { data: LoginRequest },
  TContext
> => {
  const mutationOptions = useAuthenticationLoginMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationValidateRefreshTokenHook = () => {
  const authenticationValidateRefreshToken =
    useAuthenticationValidateRefreshTokenMutator<LoginResponse>();

  return (refreshTokenLoginRequest: RefreshTokenLoginRequest) => {
    return authenticationValidateRefreshToken({
      url: `/api/Authentication/ValidateRefreshToken`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: refreshTokenLoginRequest,
    });
  };
};

export const useAuthenticationValidateRefreshTokenMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthenticationValidateRefreshTokenHook>>
    >,
    TError,
    { data: RefreshTokenLoginRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationValidateRefreshTokenHook>>
  >,
  TError,
  { data: RefreshTokenLoginRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationValidateRefreshToken =
    useAuthenticationValidateRefreshTokenHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAuthenticationValidateRefreshTokenHook>>
    >,
    { data: RefreshTokenLoginRequest }
  > = (props) => {
    const { data } = props ?? {};

    return authenticationValidateRefreshToken(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationValidateRefreshTokenMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationValidateRefreshTokenHook>>
  >
>;
export type AuthenticationValidateRefreshTokenMutationBody =
  RefreshTokenLoginRequest;
export type AuthenticationValidateRefreshTokenMutationError = unknown;

export const useAuthenticationValidateRefreshToken = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthenticationValidateRefreshTokenHook>>
    >,
    TError,
    { data: RefreshTokenLoginRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationValidateRefreshTokenHook>>
  >,
  TError,
  { data: RefreshTokenLoginRequest },
  TContext
> => {
  const mutationOptions =
    useAuthenticationValidateRefreshTokenMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationSignoutHook = () => {
  const authenticationSignout = useAuthenticationSignoutMutator<void>();

  return () => {
    return authenticationSignout({
      url: `/api/Authentication/Signout`,
      method: 'POST',
    });
  };
};

export const useAuthenticationSignoutMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationSignoutHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationSignoutHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationSignout = useAuthenticationSignoutHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationSignoutHook>>>,
    void
  > = () => {
    return authenticationSignout();
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationSignoutMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationSignoutHook>>>
>;

export type AuthenticationSignoutMutationError = unknown;

export const useAuthenticationSignout = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationSignoutHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationSignoutHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useAuthenticationSignoutMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationForgotPasswordHook = () => {
  const authenticationForgotPassword =
    useAuthenticationForgotPasswordMutator<void>();

  return (
    authenticationForgotPasswordEmailBody: AuthenticationForgotPasswordEmailBody
  ) => {
    return authenticationForgotPassword({
      url: `/api/Authentication/ForgotPassword`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: authenticationForgotPasswordEmailBody,
    });
  };
};

export const useAuthenticationForgotPasswordMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationForgotPasswordHook>>>,
    TError,
    { data: AuthenticationForgotPasswordEmailBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationForgotPasswordHook>>>,
  TError,
  { data: AuthenticationForgotPasswordEmailBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationForgotPassword = useAuthenticationForgotPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationForgotPasswordHook>>>,
    { data: AuthenticationForgotPasswordEmailBody }
  > = (props) => {
    const { data } = props ?? {};

    return authenticationForgotPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationForgotPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationForgotPasswordHook>>>
>;
export type AuthenticationForgotPasswordMutationBody =
  AuthenticationForgotPasswordEmailBody;
export type AuthenticationForgotPasswordMutationError = ProblemDetails;

export const useAuthenticationForgotPassword = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationForgotPasswordHook>>>,
    TError,
    { data: AuthenticationForgotPasswordEmailBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationForgotPasswordHook>>>,
  TError,
  { data: AuthenticationForgotPasswordEmailBody },
  TContext
> => {
  const mutationOptions =
    useAuthenticationForgotPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationResetPasswordHook = () => {
  const authenticationResetPassword =
    useAuthenticationResetPasswordMutator<void>();

  return (resetPasswordRequest: ResetPasswordRequest) => {
    return authenticationResetPassword({
      url: `/api/Authentication/ResetPassword`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: resetPasswordRequest,
    });
  };
};

export const useAuthenticationResetPasswordMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationResetPasswordHook>>>,
    TError,
    { data: ResetPasswordRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationResetPasswordHook>>>,
  TError,
  { data: ResetPasswordRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationResetPassword = useAuthenticationResetPasswordHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationResetPasswordHook>>>,
    { data: ResetPasswordRequest }
  > = (props) => {
    const { data } = props ?? {};

    return authenticationResetPassword(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationResetPasswordMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationResetPasswordHook>>>
>;
export type AuthenticationResetPasswordMutationBody = ResetPasswordRequest;
export type AuthenticationResetPasswordMutationError = ProblemDetails;

export const useAuthenticationResetPassword = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationResetPasswordHook>>>,
    TError,
    { data: ResetPasswordRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationResetPasswordHook>>>,
  TError,
  { data: ResetPasswordRequest },
  TContext
> => {
  const mutationOptions =
    useAuthenticationResetPasswordMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationResendConfirmationEmailHook = () => {
  const authenticationResendConfirmationEmail =
    useAuthenticationResendConfirmationEmailMutator<string>();

  return (params?: AuthenticationResendConfirmationEmailParams) => {
    return authenticationResendConfirmationEmail({
      url: `/api/Authentication/ResendConfirmationEmail`,
      method: 'POST',
      params,
    });
  };
};

export const useAuthenticationResendConfirmationEmailMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAuthenticationResendConfirmationEmailHook>
      >
    >,
    TError,
    { params?: AuthenticationResendConfirmationEmailParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationResendConfirmationEmailHook>>
  >,
  TError,
  { params?: AuthenticationResendConfirmationEmailParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationResendConfirmationEmail =
    useAuthenticationResendConfirmationEmailHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAuthenticationResendConfirmationEmailHook>
      >
    >,
    { params?: AuthenticationResendConfirmationEmailParams }
  > = (props) => {
    const { params } = props ?? {};

    return authenticationResendConfirmationEmail(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationResendConfirmationEmailMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationResendConfirmationEmailHook>>
  >
>;

export type AuthenticationResendConfirmationEmailMutationError = ProblemDetails;

export const useAuthenticationResendConfirmationEmail = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAuthenticationResendConfirmationEmailHook>
      >
    >,
    TError,
    { params?: AuthenticationResendConfirmationEmailParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationResendConfirmationEmailHook>>
  >,
  TError,
  { params?: AuthenticationResendConfirmationEmailParams },
  TContext
> => {
  const mutationOptions =
    useAuthenticationResendConfirmationEmailMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationTestAuthenticationHook = () => {
  const authenticationTestAuthentication =
    useAuthenticationTestAuthenticationMutator<boolean>();

  return () => {
    return authenticationTestAuthentication({
      url: `/api/Authentication/TestAuthentication`,
      method: 'POST',
    });
  };
};

export const useAuthenticationTestAuthenticationMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthenticationTestAuthenticationHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationTestAuthenticationHook>>
  >,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationTestAuthentication =
    useAuthenticationTestAuthenticationHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAuthenticationTestAuthenticationHook>>
    >,
    void
  > = () => {
    return authenticationTestAuthentication();
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationTestAuthenticationMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationTestAuthenticationHook>>
  >
>;

export type AuthenticationTestAuthenticationMutationError = ProblemDetails;

export const useAuthenticationTestAuthentication = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAuthenticationTestAuthenticationHook>>
    >,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAuthenticationTestAuthenticationHook>>
  >,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useAuthenticationTestAuthenticationMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationTestAdminRoleHook = () => {
  const authenticationTestAdminRole =
    useAuthenticationTestAdminRoleMutator<boolean>();

  return () => {
    return authenticationTestAdminRole({
      url: `/api/Authentication/TestAdminRole`,
      method: 'POST',
    });
  };
};

export const useAuthenticationTestAdminRoleMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationTestAdminRoleHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationTestAdminRoleHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationTestAdminRole = useAuthenticationTestAdminRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationTestAdminRoleHook>>>,
    void
  > = () => {
    return authenticationTestAdminRole();
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationTestAdminRoleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationTestAdminRoleHook>>>
>;

export type AuthenticationTestAdminRoleMutationError = ProblemDetails;

export const useAuthenticationTestAdminRole = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationTestAdminRoleHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationTestAdminRoleHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions =
    useAuthenticationTestAdminRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAuthenticationTestUserRoleHook = () => {
  const authenticationTestUserRole =
    useAuthenticationTestUserRoleMutator<boolean>();

  return () => {
    return authenticationTestUserRole({
      url: `/api/Authentication/TestUserRole`,
      method: 'POST',
    });
  };
};

export const useAuthenticationTestUserRoleMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationTestUserRoleHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationTestUserRoleHook>>>,
  TError,
  void,
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const authenticationTestUserRole = useAuthenticationTestUserRoleHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationTestUserRoleHook>>>,
    void
  > = () => {
    return authenticationTestUserRole();
  };

  return { mutationFn, ...mutationOptions };
};

export type AuthenticationTestUserRoleMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationTestUserRoleHook>>>
>;

export type AuthenticationTestUserRoleMutationError = ProblemDetails;

export const useAuthenticationTestUserRole = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useAuthenticationTestUserRoleHook>>>,
    TError,
    void,
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useAuthenticationTestUserRoleHook>>>,
  TError,
  void,
  TContext
> => {
  const mutationOptions = useAuthenticationTestUserRoleMutationOptions(options);

  return useMutation(mutationOptions);
};
