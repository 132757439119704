import { TeamGroupModel, TeamsClient } from 'api';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks/lib';

const teamGroupsAtom = atom<{
  teamGroups: TeamGroupModel[];
  teamGroupsById: Map<number, TeamGroupModel>;
}>({
  key: 'teamGroupsLookup',
  default: selector({
    key: 'teamGroupsLookupSelector',
    get: async (): Promise<{
      teamGroups: TeamGroupModel[];
      teamGroupsById: Map<number, TeamGroupModel>;
    }> => {
      const url = `/api/Teams/GetTeamGroups`.replace(/[?&]$/, '');

      const options: RequestInit = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        return { teamGroups: [], teamGroupsById: new Map() };
      }

      const deserialized: TeamGroupModel[] = await response.json();

      return {
        teamGroups: deserialized,
        teamGroupsById: new Map(deserialized.map((e) => [e.id, e])),
      };
    },
  }),
});

export const useRefreshTeamGroupsCall = () => {
  const [, setState] = useRecoilState(teamGroupsAtom);

  const apiCall = useApiCall(TeamsClient, async (c) => {
    const result = await c.getTeamGroups();
    setState({
      teamGroups: result,
      teamGroupsById: new Map(result.map((e) => [e.id, e])),
    });
    return result;
  });

  return apiCall;
};

const useTeamGroups = () => {
  return useRecoilValue(teamGroupsAtom);
};

export default useTeamGroups;
