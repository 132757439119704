import React, { useEffect } from 'react';

// used to identify clicks outside a specific component, e.g. to trigger the closing of a modal
const useOutsideClick = (
  ref: React.MutableRefObject<HTMLElement | undefined | null>,
  callback: () => void
) => {
  const handleClick = (e: MouseEvent) => {
    if (
      ref.current &&
      e.target instanceof Node &&
      !ref.current.contains(e.target)
    ) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

export default useOutsideClick;
