import { BusinessUnitClient, BusinessUnitModel } from 'api';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks/lib';

export const businessUnitsAtom = atom<{
  units: BusinessUnitModel[];
  unitsById: Map<number, BusinessUnitModel>;
}>({
  key: 'businessUnitsLookup',
  default: selector({
    key: 'businessUnitsLookupSelector',
    get: async (): Promise<{
      units: BusinessUnitModel[];
      unitsById: Map<number, BusinessUnitModel>;
    }> => {
      const url = `/api/BusinessUnit/GetBusinessUnits`.replace(/[?&]$/, '');

      const options: RequestInit = {
        method: 'POST',
        headers: {
          Accept: 'application/json',
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        return { units: [], unitsById: new Map() };
      }

      const deserialized: BusinessUnitModel[] = await response.json();

      return {
        units: deserialized,
        unitsById: new Map(deserialized.map((e) => [e.id, e])),
      };
    },
  }),
});

export const useRefreshBusinessUnitsCall = () => {
  const [, setState] = useRecoilState(businessUnitsAtom);

  const apiCall = useApiCall(BusinessUnitClient, async (c) => {
    const result = await c.getBusinessUnits();
    setState({
      units: result,
      unitsById: new Map(result.map((unit) => [unit.id, unit])),
    });
    return result;
  });

  return apiCall;
};

const useBusinessUnits = () => {
  return useRecoilValue(businessUnitsAtom);
};

export default useBusinessUnits;
