import { FC } from 'react';
import { ZipCodeRuleModel } from 'api';

import styled, { css } from 'styled-components';

const Wrapper = styled.span`
  gap: 10px;
  padding: 1px 4px;
  border-radius: 5px;
  margin-right: 5px;
  ${({ theme }) => css`
    background-color: ${theme.colors.background.highlight};
    color: ${theme.colors.foreground.tint};
  `}
`;

/**
 * Adds a padding between the first three and last two digits of a post code.
 * @param code
 */
const formatPostCode = (postCode?: number) => {
  if (!postCode) {
    return '';
  }

  const code = postCode.toString();
  return `${code.slice(0, 3)} ${code.slice(3)}`;
};

interface Props {
  rule: ZipCodeRuleModel;
}

const ZipCodeRule: FC<Props> = ({ rule }) => (
  <Wrapper>{`${formatPostCode(rule.minCode)} – ${formatPostCode(
    rule.maxCode
  )}`}</Wrapper>
);

export default ZipCodeRule;
