import styled from 'styled-components';
import { AssignmentRequestEventModel } from 'api/model';
import AssignmentRequestEvent from './AssignmentRequestEvent';
import useTranslations from 'contexts/basicData/useTranslations';
import { useEffect, useRef } from 'react';
import CommentInput from 'components/DetailedCase/EventsAndComments/CommentInput';
import { useAssignmentRequestsAddOrUpdateComment } from 'api/assignment-requests/assignment-requests';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 500px;
  overflow-y: auto;
`;

interface Props {
  assignmentRequestId?: number;
  events: AssignmentRequestEventModel[];
  onUpdate: () => void;
}

const AssignmentRequestEvents: React.FC<Props> = ({
  events,
  assignmentRequestId,
  onUpdate,
}) => {
  const { assignmentRequestEventTypes } = useTranslations();

  const addOrUpdateCommentCall = useAssignmentRequestsAddOrUpdateComment();

  // automatically scroll to bottom when new events are added
  const scrollRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    scrollRef.current?.scrollTo({
      top: scrollRef.current.scrollHeight,
      behavior: 'auto',
    });
  }, [events.length]);

  return (
    <>
      <Wrapper ref={scrollRef}>
        {events.map((event) => (
          <AssignmentRequestEvent
            key={event.id}
            event={event}
            title={
              assignmentRequestEventTypes[event.assignmentRequestEventType]!
            }
          />
        ))}
      </Wrapper>
      {assignmentRequestId !== undefined && (
        <CommentInput
          loading={addOrUpdateCommentCall.status === 'loading'}
          onAddComment={async (message, internalComment) => {
            await addOrUpdateCommentCall.mutateAsync({
              params: {
                assignmentRequestId,
                message,
                internalComment,
              },
            });
            onUpdate();
          }}
        />
      )}
    </>
  );
};

export default AssignmentRequestEvents;
