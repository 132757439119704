import {
  useCompanyDepartmentCreateCompanyDepartment,
  useCompanyDepartmentGetCompanyDepartment,
  useCompanyDepartmentUpdateCompanyDepartment,
} from 'api/company-department/company-department';
import { CompanyDepartmentModel } from 'api/model';
import Checkbox from 'components/inputs/Checkbox';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import TextArea from 'components/inputs/TextArea';
import Modal from 'components/Modal';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { showException } from 'utils/exception-helper';

const MyInput = styled(Input)`
  width: 100%;
`;

const MyTextArea = styled(TextArea)`
  width: 100%;
`;

const getDefaultValues = (
  companyDepartment: CompanyDepartmentModel | undefined
): CompanyDepartmentModel => {
  return {
    id: companyDepartment?.id,
    name: companyDepartment?.name ?? '',
    description: companyDepartment?.description ?? '',
    isActive: companyDepartment?.isActive ?? true,
  } as CompanyDepartmentModel;
};

type CompanyDepartmentModalProps = {
  id?: number;
  onCancel(): void;
  onSaved(): void;
};

const CompanyDepartmentModal = ({
  id,
  onCancel,
  onSaved,
}: CompanyDepartmentModalProps) => {
  const getCompanyDepartmentCall = useCompanyDepartmentGetCompanyDepartment(
    {
      departmentId: id,
    },
    {
      query: {
        enabled: id !== undefined,
      },
    }
  );

  const createCompanyDepartmentCall =
    useCompanyDepartmentCreateCompanyDepartment();
  const updateCompanyDepartmentCall =
    useCompanyDepartmentUpdateCompanyDepartment();

  const companyDepartmentForm = useForm<CompanyDepartmentModel>({
    defaultValues: getDefaultValues(getCompanyDepartmentCall.data),
  });
  // reset the form when the data changes
  useEffect(() => {
    companyDepartmentForm.reset(
      getDefaultValues(getCompanyDepartmentCall.data)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCompanyDepartmentCall.data]);

  const createOrUpdate = async (data: CompanyDepartmentModel) => {
    try {
      if (id !== undefined) {
        await updateCompanyDepartmentCall.mutateAsync({ data });
      } else {
        await createCompanyDepartmentCall.mutateAsync({ data });
      }
      onSaved();
    } catch (error) {
      showException(error);
    }
  };

  return (
    <Modal
      buttons={[
        {
          label: 'Lägg till',
          onClick: companyDepartmentForm.handleSubmit(createOrUpdate),
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title={id ? 'Redigera avdelning' : 'Lägg till avdelning'}
    >
      <LabelWrap
        label="Avdelningsnamn"
        errorLabel={companyDepartmentForm.formState.errors.name?.message}
      >
        <MyInput
          {...companyDepartmentForm.register('name', {
            required: {
              value: true,
              message: 'Du måste ange ett avdelningsnamn',
            },
          })}
          spellCheck={false}
        />
      </LabelWrap>
      <LabelWrap
        label="Beskrivning"
        errorLabel={companyDepartmentForm.formState.errors.description?.message}
      >
        <MyTextArea
          {...companyDepartmentForm.register('description', {
            required: {
              value: true,
              message: 'Du måste ange en beskrivning',
            },
          })}
        />
      </LabelWrap>
      <LabelWrap>
        <Checkbox {...companyDepartmentForm.register('isActive')}>
          Aktiv
        </Checkbox>
      </LabelWrap>
    </Modal>
  );
};

export default CompanyDepartmentModal;
