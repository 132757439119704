import Button from 'components/inputs/Button';
import Table from 'components/Table';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import useModalStack from 'contexts/modal/useModalStack';
import styled from 'styled-components';
import useTranslations from 'contexts/basicData/useTranslations';
import { useCompanyDepartmentGetCompanyDepartments } from 'api/company-department/company-department';
import { makeStringComparator } from 'utils/sorting';
import { useQueryClient } from '@tanstack/react-query';
import { CompanyDepartmentModel, TranslationLookups } from 'api/model';
import CompanyDepartmentModal from './CompanyDepartmentModal';

const Wrapper = styled.div``;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

const CompanyDepartmentsSettingsPage = () => {
  const translations = useTranslations();
  const queryClient = useQueryClient();
  const companyDepartmentsCall = useCompanyDepartmentGetCompanyDepartments();
  const modalStack = useModalStack();

  const refreshCompanyDepartments = async () => {
    await queryClient.invalidateQueries(companyDepartmentsCall.queryKey);
    await companyDepartmentsCall.refetch();
  };

  const columnSettings: ColumnSetting<
    CompanyDepartmentModel,
    TranslationLookups
  >[] = [
    {
      head: 'Nr',
      render: (z, rp, focused, index) => index + 1,
      width: 10,
    },
    {
      head: 'Avdelningsnamn',
      render: (z) => z.name,
      width: 150,
      sortFunction: makeStringComparator((z) => z.name),
    },
    {
      head: 'Beskrivning',
      render: (z) => z.description,
      width: 150,
    },
  ];

  return (
    <Wrapper>
      <Actions>
        <Button
          onClick={() =>
            modalStack.push(
              <CompanyDepartmentModal
                onCancel={() => modalStack.pop()}
                onSaved={() => {
                  modalStack.pop();
                  refreshCompanyDepartments();
                }}
              />
            )
          }
        >
          + Ny avdelning
        </Button>
      </Actions>

      <MyTable
        columnSettings={columnSettings}
        onRowClick={(r) => {
          modalStack.push(
            <CompanyDepartmentModal
              onCancel={() => modalStack.pop()}
              onSaved={() => {
                modalStack.pop();
                refreshCompanyDepartments();
              }}
              id={r.id}
            />
          );
        }}
        renderProps={translations as TranslationLookups}
        rows={companyDepartmentsCall.data ?? []}
        useColumnWidthAsFlexAmount
      />
    </Wrapper>
  );
};

export default CompanyDepartmentsSettingsPage;
