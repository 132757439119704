import { ExpenseModel, FileClient } from 'api';
import { emptyGuid } from 'constants/AppConstants';
import useForm from 'hooks/useForm';
import { useCallback, useMemo, useState } from 'react';
import { useApiCall } from 'swaggerhooks/lib';

function expenseModelToExpenseForm(expense: ExpenseModel | undefined) {
  if (expense) {
    return {
      expenseType: String(expense.expenseType),
      paymentType: String(expense.paymentType),
      message: expense.message,
      value: String(expense.value),
    };
  }
  return {
    expenseType: '6',
    paymentType: '1',
    message: '',
    value: '0',
  };
}

export function expenseFormToExpenseModel(
  base: ExpenseModel | undefined,
  expenseForm: ReturnType<typeof expenseModelToExpenseForm>
) {
  return new ExpenseModel({
    expenseType: Number(expenseForm.expenseType),
    paymentType: Number(expenseForm.paymentType),
    message: expenseForm.message,
    value: Number(expenseForm.value),

    id: base?.id ?? 0,
    assignmentID: base?.assignmentID ?? -1,
    fileGuid: base?.fileGuid ?? emptyGuid,
    fileEnding: base?.fileEnding ?? '',
    created: base?.created ?? new Date(),
    createdByID: base?.createdByID ?? -1,
    createdBy: base?.createdBy ?? '',
    modified: base?.modified ?? new Date(),
    modifiedByID: base?.modifiedByID ?? -1,
    modifiedBy: base?.modifiedBy ?? '',
  });
}

const useExpenseEditorForm = (
  expense: ExpenseModel | undefined,
  onSaveExpense: (expense: ExpenseModel) => void
) => {
  const form = useForm(expenseModelToExpenseForm(expense));
  // if undefined: dont change image, if null: clear image, if File: update image.
  const [selectedFile, setSelectedFile] = useState<File | null | undefined>(
    undefined
  );

  const uploadFileCall = useApiCall(
    FileClient,
    (c, file: File, fileName: string, fileEnding: string) =>
      c.uploadFile({ data: file, fileName }, fileEnding)
  );

  const { state } = form;

  const handleSaveClick = useCallback(async () => {
    const updatedModel = expenseFormToExpenseModel(expense, state);

    if (selectedFile) {
      const fileName = selectedFile.name;
      const fileEnding = fileName.match(/\.(\w*)$/)?.[1] ?? '';

      const [fileGuid, error] = await uploadFileCall.run(
        selectedFile,
        fileName,
        fileEnding
      );

      if (fileGuid && !error) {
        updatedModel.fileGuid = fileGuid;
        updatedModel.fileEnding = fileEnding;
      }
    } else if (selectedFile === null) {
      updatedModel.fileGuid = emptyGuid;
      updatedModel.fileEnding = '';
    }

    onSaveExpense(updatedModel);
  }, [expense, onSaveExpense, selectedFile, state, uploadFileCall]);

  return useMemo(
    () => ({
      form,
      onSaveClick: handleSaveClick,
      selectedFile,
      setSelectedFile,
    }),
    [form, handleSaveClick, selectedFile]
  );
};

export default useExpenseEditorForm;
