import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import { CaseEventDTO } from 'api/model';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { useQueryClient } from '@tanstack/react-query';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import {
  getGoSupportGetUnhandledFlaggedForSpeedingQueryKey,
  useGoSupportGetUnhandledFlaggedForSpeeding,
} from 'api/go-support/go-support';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Routes from 'constants/Routes';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/inputs/Checkbox';
import useUsers from 'contexts/basicData/useUsers';
import {
  Centered,
  getTableRowClassName,
  SavingRowAnimationWrapper,
} from '../table-helpers';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import useTranslations from 'contexts/basicData/useTranslations';

const TABLE_NAME = 'flagged-for-speeding';

const FlaggedForSpeedingWidget = () => {
  const [flaggedForSpeedingEvents, setFlaggedForSpeedingEvents] = useState<
    CaseEventDTO[]
  >([]);
  const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const translations = useTranslations();

  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();

  const queryClient = useQueryClient();
  const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
    useCaseMarkEventsAsReadByAdmin({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            `${getGoSupportGetUnhandledFlaggedForSpeedingQueryKey()}`,
          ]);
        },
      },
    });

  const getFlaggedForSpeeding = useGoSupportGetUnhandledFlaggedForSpeeding(
    {
      startDate: daterange[0] ?? undefined,
      endDate: daterange[1] ?? undefined,
      businessUnitIDs: selectedBusinessUnits,
    },
    {
      query: {
        refetchInterval: UPDATE_INTERVAL,
      },
    }
  );

  useEffect(() => {
    if (getFlaggedForSpeeding.data) {
      setFlaggedForSpeedingEvents(getFlaggedForSpeeding.data);
    } else {
      setFlaggedForSpeedingEvents([] as CaseEventDTO[]);
    }
  }, [getFlaggedForSpeeding.data]);

  const filteredEditedBookedTos = useMemo(() => {
    if (!searchText.length) {
      return flaggedForSpeedingEvents;
    }
    return flaggedForSpeedingEvents
      ? flaggedForSpeedingEvents.filter((edits) => {
          return (
            edits.registrationNumber
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (users[edits.createdByID]?.name ?? '')
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        })
      : [];
  }, [flaggedForSpeedingEvents, searchText, users]);

  const columnSettings: ColumnSetting<CaseEventDTO, any>[] = useMemo(() => {
    const columns: ColumnSetting<CaseEventDTO, any>[] = [
      {
        head: 'Nr',
        render: (e, t, f, i) => i + 1,
        width: 15,
      },
      {
        head: 'Reg.nr',
        render: (row) => `${row.registrationNumber}`,
        width: 50,
        sortFunction: (a, b) => {
          return a.registrationNumber.localeCompare(b.registrationNumber);
        },
      },
      {
        head: 'Status',
        render: (row) =>
          `${row.assignmentStatus ? translations.assignmentStatuses[row.assignmentStatus] : ''}`,
        width: 50,
        sortFunction: (a, b) => {
          return a.assignmentStatus - b.assignmentStatus;
        },
      },
      {
        head: 'Tid',
        render: (row) => `${formatDateTime(row.created)}`,
        width: 100,
        sortFunction: (a, b) => a.created.getTime() - b.created.getTime(),
      },
      {
        head: 'Fälttestare',
        render: (row) => {
          return (
            <span>
              {row.assignedToID && users[row.assignedToID]
                ? users[row.assignedToID].name
                : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          String(
            (a.assignedToID && users[a.assignedToID]?.name) ?? ''
          ).localeCompare(
            String((b.assignedToID && users[b.assignedToID]?.name) ?? '')
          ),
      },
      {
        head: 'Telefonnummer',
        render: (row) => {
          return (
            <span>
              {row.assignedToID && users[row.assignedToID]
                ? users[row.assignedToID].phoneNumber
                : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) => {
          const phoneA = a.assignedToID
            ? (users[a.assignedToID]?.phoneNumber ?? '')
            : '';
          const phoneB = b.assignedToID
            ? (users[b.assignedToID]?.phoneNumber ?? '')
            : '';
          return phoneA.localeCompare(phoneB);
        },
      },
      {
        head: 'Kommentar',
        render: (row) => row.comment,
        width: 300,
        sortFunction: (a, b) => a.comment.localeCompare(b.comment),
      },
      {
        head: ' ',
        render: (row) => (
          <Centered>
            <FontAwesomeIcon
              onClick={() => {
                window.open(
                  `${Routes.search.index}/${row.caseID}`,
                  row.caseID.toString() ?? '_blank'
                );
              }}
              icon={faArrowUpRightFromSquare}
              fontSize="18px"
              style={{ cursor: 'pointer' }}
            />
          </Centered>
        ),
        width: 20,
      },
      {
        head: 'Klart',
        render: (row) => (
          <Centered>
            <Checkbox
              disabled={markAsReadStatus === 'loading'}
              onChange={async () => {
                setOngoingSaves([...ongoingSaves, row.id]);
                await markEventAsRead({ data: [row.id] });
                setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
              }}
            />
          </Centered>
        ),
        width: 30,
      },
    ];
    return columns;
  }, [
    markAsReadStatus,
    markEventAsRead,
    ongoingSaves,
    translations.assignmentStatuses,
    users,
  ]);

  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.FlaggedForSpeeding]}
      count={flaggedForSpeedingEvents.length}
      isLoading={getFlaggedForSpeeding.isLoading}
      info="Markera som klar för att markera som läst."
    >
      <SavingRowAnimationWrapper
        tableName={TABLE_NAME}
        savingIds={ongoingSaves}
      >
        <Table
          columnSettings={columnSettings}
          rows={filteredEditedBookedTos}
          rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
          useColumnWidthAsFlexAmount
        />
      </SavingRowAnimationWrapper>
    </Widget>
  );
};

export default FlaggedForSpeedingWidget;
