import useAuthenticationState from 'contexts/authentication/useAuthentication';
import styled, { css } from 'styled-components';
import useMe from 'contexts/authentication/useMe';
import { useLocalStorage } from 'usehooks-ts';
import { NavBarState, NavBarStateKey } from 'constants/AppConstants';

const SubNavBarContainer = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-end;
    height: 100%;
    margin-right: 0;
    margin-left: auto;
    margin-bottom: auto;
    margin-top: 0;
    gap: 30px;

    .sub-nav-link {
      border: none;
      background-color: transparent;
      color: ${theme.colors.foreground.primary};
      font-size: ${theme.sizes.font.small}px;
      text-decoration: none;

      &:hover {
        color: ${theme.colors.foreground.tint};
      }
    }
  `}
`;

const SubNavButton = styled.button`
  border: none;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.foreground.primary};
  font: inherit;
  font-size: ${({ theme }) => theme.sizes.font.small}px;
  text-decoration: none;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.foreground.tint};
  }
`;

const NoWrap = styled.div`
  white-space: nowrap;
`;

const RightHandNavBar: React.FC = () => {
  const me = useMe();

  const [navbarState, setNavBarState] = useLocalStorage<NavBarState>(
    NavBarStateKey,
    NavBarState.Horizontal
  );

  const {
    state: { isAuthenticated },
    signout,
  } = useAuthenticationState();

  return (
    <SubNavBarContainer>
      {isAuthenticated && (
        <>
          {/* <NavLink className="sub-nav-link" to={Routes.account.profile}>
            Profil
          </NavLink> */}
          <SubNavButton
            onClick={() =>
              setNavBarState((p) =>
                p === NavBarState.Horizontal
                  ? NavBarState.Vertical
                  : NavBarState.Horizontal
              )
            }
          >
            <NoWrap>
              {navbarState === NavBarState.Horizontal
                ? 'Expanderad'
                : 'Kompakt'}{' '}
              meny
            </NoWrap>
          </SubNavButton>
          <SubNavButton onClick={signout}>
            <NoWrap>Logga ut </NoWrap>
            <NoWrap>{me?.name}</NoWrap>
          </SubNavButton>
        </>
      )}
    </SubNavBarContainer>
  );
};

export default RightHandNavBar;
