import { AuthenticationClient } from 'api';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  gap: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 6px;
`;

const P = styled.p`
  height: 20px;
  color: red;
`;

const Forgot: React.FC = () => {
  const [email, setEmail] = useState('');
  const [noEmailMessage, setNoEmailMessage] = useState('');

  const navigate = useNavigate();

  const forgotPasswordCall = useApiCall(
    AuthenticationClient,
    (c) => c.forgotPassword(email),
    [email]
  );

  const handleSubmit = useCallback(async () => {
    if (email === '' || email.indexOf('@') === -1) {
      setNoEmailMessage('Fyll i din epost adress.');
    } else {
      forgotPasswordCall.run();
    }
  }, [email, forgotPasswordCall]);

  const handleEnterPress = (key: string) => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  const resubmit = () => {
    handleSubmit();
  };

  useEffect(() => {
    if (noEmailMessage !== '') {
      setNoEmailMessage('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  switch (forgotPasswordCall.status) {
    case RequestStatus.Idle:
      return (
        <Container>
          <h1>Glömt lösenordet</h1>
          <p>
            Fyll i din epost-adress så kommer du att få ett
            återställningsmeddelande skickat till din epost.
          </p>
          <P>{noEmailMessage}</P>
          <Input
            onChange={(e) => setEmail(e.currentTarget.value)}
            onKeyPress={(e) => handleEnterPress(e.key)}
            placeholder="epostadress"
            type="text"
            value={email}
            width="100%"
          />
          <Button
            onClick={() => handleSubmit()}
            onKeyPress={(e) => handleEnterPress(e.key)}
            type="button"
          >
            Skicka
          </Button>
          <Button onClick={() => navigate(-1)} style={{ width: '120px' }}>
            Tillbaka
          </Button>
        </Container>
      );

    case RequestStatus.Fetching:
      return <LoadingSpinner />;

    case RequestStatus.Fetched:
      return (
        <Container>
          <p>Följ instruktionerna i epost-meddelandet.</p>
        </Container>
      );

    case RequestStatus.Error:
    default:
      return (
        <Container>
          <h1>Glömt lösenord</h1>
          <p>Misslyckades, dubbelkolla epostadressen.</p>
          <ButtonContainer>
            <Button
              onClick={() => resubmit()}
              style={{ width: '50%' }}
              type="button"
            >
              Försök igen
            </Button>
            <Button
              onClick={() => forgotPasswordCall.setStatus(RequestStatus.Idle)}
              style={{ width: '50%' }}
              type="button"
            >
              Tillbaka
            </Button>
          </ButtonContainer>
        </Container>
      );
  }
};

export default Forgot;
