export interface ThemeSizes {
  font: {
    large: string;
    medium: string;
    small: string;
  };
  radius: {
    input: number;
    gap: number;
  };
  modal: {
    defaultPadding: number;
  };
}

const themeSizes: ThemeSizes = {
  font: {
    large: '30px',
    medium: '20px',
    small: '15px',
  },
  radius: {
    input: 3,
    gap: 10,
  },
  modal: {
    defaultPadding: 20,
  },
};

export default themeSizes;
