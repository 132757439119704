import styled from 'styled-components';

export const EventSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
`;

export const Message = styled.span`
  padding-top: 5px;
  white-space: pre-wrap;
  word-break: break-word;
`;
