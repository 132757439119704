import { CaseTypeEnum } from 'api';
import Routes from 'constants/Routes';
import { useLocation } from 'react-router-dom';

const useCurrentRouteCaseType = () => {
  const location = useLocation();

  if (location.pathname.startsWith(Routes.extrajobs.index)) {
    return CaseTypeEnum.Extra;
  }
  if (location.pathname.startsWith(Routes.homeDeliveries.index)) {
    return CaseTypeEnum.HomeDelivery;
  }
  if (location.pathname.startsWith(Routes.internalDeliverySales.index)) {
    return CaseTypeEnum.InternalDeliverySales;
  }
  if (location.pathname.startsWith(Routes.internalDeliveryGroup.index)) {
    return CaseTypeEnum.InternalDelivery;
  }
  if (location.pathname.startsWith(Routes.internalDeliveries.index)) {
    return CaseTypeEnum.InternalDelivery;
  }
  if (location.pathname.startsWith(Routes.facilityExchange.index)) {
    return CaseTypeEnum.FaciliyExchange;
  }
  if (location.pathname.startsWith(Routes.purchases.index)) {
    return CaseTypeEnum.Purchase;
  }
  if (location.pathname.startsWith(Routes.rmOther.index)) {
    return CaseTypeEnum.RMOther;
  }
  if (location.pathname.startsWith(Routes.swamp.index)) {
    return CaseTypeEnum.Swamp;
  }
  if (location.pathname.startsWith(Routes.extraCostExternalWorkshopOut.index)) {
    return CaseTypeEnum.ExtraCostExternalWorkshopOut;
  }
  if (
    location.pathname.startsWith(Routes.extraCostExternalWorkshopReturn.index)
  ) {
    return CaseTypeEnum.ExtraCostExternalWorkshopReturn;
  }
  if (location.pathname.startsWith(Routes.facilityToFacility.index)) {
    return CaseTypeEnum.FacilityToFacility;
  }

  return null;
};

export const useCurrentRouteCaseRoute = () => {
  const currentRouteCaseType = useCurrentRouteCaseType();

  const location = useLocation();

  switch (currentRouteCaseType) {
    case CaseTypeEnum.Purchase:
      return Routes.purchases.case;
    case CaseTypeEnum.Extra:
      return Routes.extrajobs.case;
    case CaseTypeEnum.HomeDelivery:
      return Routes.homeDeliveries.case;
    case CaseTypeEnum.InternalDelivery:
      return Routes.internalDeliveries.case;
    case CaseTypeEnum.FaciliyExchange:
      return Routes.facilityExchange.case;
    case CaseTypeEnum.InternalDeliverySales:
      return Routes.internalDeliverySales.case;
    case CaseTypeEnum.RMOther:
      return Routes.rmOther.case;
    case CaseTypeEnum.Swamp:
      return Routes.swamp.case;
    case CaseTypeEnum.ExtraCostExternalWorkshopOut:
      return Routes.extraCostExternalWorkshopOut.case;
    case CaseTypeEnum.ExtraCostExternalWorkshopReturn:
      return Routes.extraCostExternalWorkshopReturn.case;
    case CaseTypeEnum.FacilityToFacility:
      return Routes.facilityToFacility.case;

    default:
      if (location.pathname.startsWith(Routes.pendingAssignments.index)) {
        return Routes.pendingAssignments.case;
      }
      if (location.pathname.startsWith(Routes.search.index)) {
        return Routes.search.case;
      }
      return Routes.purchases.case;
  }
};

export const getRouteForCaseType = (caseType: CaseTypeEnum) => {
  switch (caseType) {
    case CaseTypeEnum.Purchase:
      return Routes.purchases.index;
    case CaseTypeEnum.Extra:
      return Routes.extrajobs.index;
    case CaseTypeEnum.HomeDelivery:
      return Routes.homeDeliveries.index;
    case CaseTypeEnum.InternalDelivery:
      return Routes.internalDeliveries.index;
    case CaseTypeEnum.FaciliyExchange:
      return Routes.facilityExchange.index;
    case CaseTypeEnum.InternalDeliverySales:
      return Routes.internalDeliverySales.index;
    case CaseTypeEnum.RMOther:
      return Routes.rmOther.index;
    case CaseTypeEnum.Swamp:
      return Routes.swamp.index;
    case CaseTypeEnum.ExtraCostExternalWorkshopOut:
      return Routes.extraCostExternalWorkshopOut.index;
    case CaseTypeEnum.ExtraCostExternalWorkshopReturn:
      return Routes.extraCostExternalWorkshopReturn.index;
    case CaseTypeEnum.FacilityToFacility:
      return Routes.facilityToFacility.index;
    default:
      return Routes.purchases.index;
  }
};

export default useCurrentRouteCaseType;
