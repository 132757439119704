import { CaseClient, CaseExtendedModel } from 'api';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import { useCallback, useMemo } from 'react';
import { useApiCall } from 'swaggerhooks/lib';

const useEventActions = (
  currentCase: CaseExtendedModel | undefined,
  currentCaseWasUpdated: () => Promise<any>
) => {
  const saveEventCall = useApiCall(
    CaseClient,
    (
      c,
      caseId: number,
      message: string,
      eventId?: number,
      internal?: boolean,
      readByAdmin?: boolean,
      assignmentId?: number
    ) =>
      c.addOrUpdateComment(
        caseId,
        message,
        eventId,
        internal,
        readByAdmin,
        assignmentId
      )
  );

  const {mutateAsync: markEventsAsRead, status: markEventsAsReadStatus} = useCaseMarkEventsAsReadByAdmin();
  const markEventsAsReadCall = useCallback(async (eventIds: number[]) => {
    await markEventsAsRead({data: eventIds});
  }, [markEventsAsRead]);
  
  const deleteEventCall = useApiCall(CaseClient, (c, eventId: number) =>
    c.deleteComment(eventId)
  );

  const handleSaveEvent = useCallback(
    async (
      caseId: number,
      message: string,
      eventId?: number,
      internal?: boolean,
      readByAdmin?: boolean,
      assignmentId?: number
    ) => {
      const [, error] = await saveEventCall.run(
        caseId,
        message,
        eventId,
        internal,
        readByAdmin,
        assignmentId
      );

      if (!error) {
        await currentCaseWasUpdated();
      }
    },
    [currentCaseWasUpdated, saveEventCall]
  );

  const handleMarkEventsAsRead = useCallback(
    async (eventIds: number[]) => {
      await markEventsAsReadCall(eventIds);
      await currentCaseWasUpdated();
    },
    [currentCaseWasUpdated, markEventsAsReadCall]
  );

  const handleDeleteEvent = useCallback(
    async (eventId: number) => {
      const [, error] = await deleteEventCall.run(eventId);

      if (!error) {
        await currentCaseWasUpdated();
      }
    },
    [currentCaseWasUpdated, deleteEventCall]
  );

  return useMemo(
    () => ({
      onSaveEvent: handleSaveEvent,
      onMarkEventsAsRead: handleMarkEventsAsRead,
      onDeleteEvent: handleDeleteEvent,
      saveEventStatus: saveEventCall.status,
      markEventsAsReadStatus,
      deleteEventStatus: deleteEventCall.status,
    }),
    [
      handleSaveEvent,
      handleMarkEventsAsRead,
      handleDeleteEvent,
      saveEventCall.status,
      markEventsAsReadStatus,
      deleteEventCall.status,
    ]
  );
};

export default useEventActions;
