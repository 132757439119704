import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

const Box = styled.div<{ checked?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;

  color: ${({ theme }) => theme.colors.foreground.primary};

  & > * {
    opacity: 0.2;
  }

  ${({ checked }) =>
    checked &&
    css`
      background-color: ${({ theme }) => theme.colors.background.button};
      border-color: ${({ theme }) => theme.colors.background.button};
      color: ${({ theme }) => theme.colors.foreground.button};

      & > * {
        opacity: 1;
      }
    `}
`;

interface Props {
  checked?: boolean;
  onClick?(eve: React.MouseEvent<HTMLDivElement>): void;
  className?: string;
}

const FakeCheckbox: FC<Props> = ({ checked, onClick, className }) => {
  return (
    <Box checked={checked} className={className} onClick={onClick}>
      <FontAwesomeIcon icon={faCheck} />
    </Box>
  );
};

export default FakeCheckbox;
