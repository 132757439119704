import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Dropdown, { DropdownPosition } from 'components/Dropdown';
import { inputStyle } from 'components/inputs/Input';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css, FlattenSimpleInterpolation } from 'styled-components';

const Absolute = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
`;

const FakeSelect = styled.button<{ hideInput?: boolean; disabled?: boolean }>`
  ${inputStyle}
  text-align: start;
  display: flex;
  flex-direction: row;
  padding: 3px 5px;
  ${({ hideInput }) =>
    hideInput &&
    css`
      display: none;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.4;
    `}
`;
export const fakeSelectClassName = 'searchable-select-fake-select';

const SelectedValue = styled.div`
  flex: 1;
`;

const Items = styled.div<{ wrapperStyle?: FlattenSimpleInterpolation }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 150px; /* buffer for giving space for tooltips */
  overflow: auto;

  &:hover > * {
    background: ${({ theme }) => theme.colors.background.primary};
    color: ${({ theme }) => theme.colors.foreground.primary};
  }
  ${({ wrapperStyle }) => wrapperStyle};
`;

const SearchInput = styled.input`
  padding: 3px 5px;
  border: none;
  border: 0px solid ${({ theme }) => theme.colors.border.primary};
  border-top-width: 1px;
  border-bottom-width: 1px;
  font: inherit;

  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px ${({ theme }) => theme.colors.background.selection}
      inset;
  }
`;

export const SearchableSelectItem = styled.button<{ selected?: boolean }>`
  padding: 3px 5px;
  margin: 0;

  border: none;
  background: transparent;
  font: inherit;
  text-align: start;
  white-space: nowrap;

  &:hover {
    background: ${({ theme }) => theme.colors.background.selection};
    color: ${({ theme }) => theme.colors.foreground.selection};
  }

  ${({ selected, theme }) =>
    selected &&
    css`
      background: ${theme.colors.background.selection};
      color: ${theme.colors.foreground.selection};
    `}
`;

export type SelectOption<V, E extends any | void = void> = E extends void
  ? {
      label: string | ReactNode;
      description?: ReactNode;
      value: V;
    }
  : {
      label: string;
      description?: ReactNode;
      value: V;
      extra: E;
    };

type Props<V, OptionExtra extends any | void> = {
  /** Provide a Set to select multiple items */
  selectedValue: V | Set<V>;
  options: SelectOption<V, OptionExtra>[];
  optionRender?(
    key: string,
    option: SelectOption<V>,
    onClick: () => void,
    isSelected: boolean
  ): ReactNode;
  onOptionClicked: (
    value: V,
    isSelected: boolean,
    option: SelectOption<V, OptionExtra>
  ) => void;
  onOpenChanged?: (open: boolean) => void;
  searchFilter(
    searchString: string,
    options: SelectOption<V, OptionExtra>[]
  ): SelectOption<V, OptionExtra>[];

  dropdownPosition?: DropdownPosition;
  autoFocus?: boolean;
  className?: string;
  contentClassName?: string;
  hideInput?: boolean;
  itemsWrapperStyle?: FlattenSimpleInterpolation;
  loading?: boolean;
  maxItems?: number;
  disabled?: boolean;
  blurOnSelect?: boolean;
  showNonOptionSelectedName?: string;
  onSearch?(searchString: string): void;
};

const SearchableSelect = <V, OptionExtra extends any | void = void>({
  loading,
  onOpenChanged,
  onOptionClicked: onValueClicked,
  options,
  optionRender,
  selectedValue,
  searchFilter,
  dropdownPosition,
  contentClassName,
  autoFocus,
  className,
  hideInput,
  itemsWrapperStyle,
  maxItems,
  disabled,
  blurOnSelect = true,
  showNonOptionSelectedName,
  onSearch,
}: Props<V, OptionExtra>) => {
  const itemsWrapperRef = useRef<HTMLDivElement>(null);
  const [currentMaxItems, setCurrentMaxItems] = useState(maxItems || 100);
  const [searchString, setSearchString] = useState('');
  const [open, setOpen] = useState(false);
  const selectedValueAsSet = useMemo(
    () =>
      selectedValue instanceof Set ? selectedValue : new Set([selectedValue]),
    [selectedValue]
  );

  const setIsOpen = (newOpen: boolean) => {
    if (!newOpen) {
      setSearchString('');
    }
    setOpen(newOpen);
  };

  useEffect(() => {
    onSearch?.(searchString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchString]);

  useEffect(() => {
    onOpenChanged?.(open);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleItemClick = (option: SelectOption<V, OptionExtra>) => {
    onValueClicked(option.value, selectedValueAsSet.has(option.value), option);
    setIsOpen(false);
  };

  // update the max items to show when the user scrolls to the bottom (lazy loading for snappier UI)
  const checkScrollPosition = useCallback(() => {
    if (itemsWrapperRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = itemsWrapperRef.current;
      const isAtBottom =
        scrollTop + clientHeight + 10 /** buffer */ >= scrollHeight;

      if (isAtBottom && currentMaxItems < options.length) {
        setCurrentMaxItems((prev) => prev + 50);
      }
    }
  }, [currentMaxItems, options.length]);

  useEffect(() => {
    if (open) {
      const div = itemsWrapperRef.current;
      if (div) {
        // listen to scroll and resize events to check if we need to load more items
        div.addEventListener('scroll', checkScrollPosition);
      }

      const keyUpListener = (eve: KeyboardEvent) => {
        if (eve.key === 'Escape' && eve.target instanceof HTMLElement) {
          eve.target.blur();
        }
      };

      window.addEventListener('keyup', keyUpListener);

      return () => {
        window.removeEventListener('keyup', keyUpListener);

        if (div) {
          div.removeEventListener('scroll', checkScrollPosition);
        }
      };
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const makeSelectPreviewValue = () => {
    return options
      .filter((opt) => selectedValueAsSet.has(opt.value))
      .filter((opt) => opt.label !== 'Fälttestare med fel symboler')
      .map((opt, index) =>
        typeof opt.label === 'string' ? (
          <span key={opt.label}>
            {opt.label}
            {options.length > 0 &&
              index > 0 &&
              index < options.length - 1 &&
              ', '}
          </span>
        ) : (
          opt.label
        )
      );
  };

  const filteredOptions = useMemo(
    () => searchFilter(searchString, options),
    [options, searchFilter, searchString]
  );

  return (
    <Dropdown
      className={className}
      content={
        open &&
        !disabled && (
          <>
            <SearchInput
              autoFocus
              onChange={(eve) => {
                setSearchString(eve.target.value);
              }}
              placeholder={loading ? 'Laddar...' : 'Sök...'}
              value={searchString}
            />

            <Items ref={itemsWrapperRef} wrapperStyle={itemsWrapperStyle}>
              {loading && (
                <Absolute>
                  <LoadingSpinner />
                </Absolute>
              )}
              {optionRender
                ? filteredOptions.map((option, i) => {
                    if (currentMaxItems && i >= currentMaxItems) return null;
                    return optionRender(
                      String(i),
                      option,
                      () => handleItemClick(option),
                      selectedValueAsSet.has(option.value)
                    );
                  })
                : filteredOptions.map((option, i) => {
                    if (currentMaxItems && i >= currentMaxItems) return null;
                    return (
                      <SearchableSelectItem
                        key={option.label?.toString() ?? i}
                        onClick={() => handleItemClick(option)}
                        selected={selectedValueAsSet.has(option.value)}
                      >
                        {option.label}
                      </SearchableSelectItem>
                    );
                  })}
            </Items>
          </>
        )
      }
      contentClassName={contentClassName}
      onLostFocus={() => {
        if (blurOnSelect) {
          setIsOpen(false);
        }
      }}
      position={dropdownPosition}
    >
      <FakeSelect
        autoFocus={autoFocus}
        className={fakeSelectClassName}
        disabled={disabled}
        hideInput={hideInput}
        onClick={() => setIsOpen(!open)}
      >
        <SelectedValue>
          {showNonOptionSelectedName ?? makeSelectPreviewValue()}
        </SelectedValue>
        <FontAwesomeIcon icon={faAngleDown} />
      </FakeSelect>
    </Dropdown>
  );
};

export default SearchableSelect;
