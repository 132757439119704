import styled, { css } from 'styled-components';

import { AssignmentDeviationEnum, AssignmentStatusEnum } from 'api';
import { useAssignmentsFilters } from '../../contexts/assignmentsFilters/useAssignmentsFilters';

export const FilterPickerContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin: 0 40px;
  gap: 30px;
`;

export const FilterButton = styled.button<{ selected?: boolean }>`
  padding-bottom: 1px;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.foreground.primary};
  font: inherit;
  text-transform: capitalize;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected &&
    css`
      padding-bottom: 1px;
      border-bottom: 2px solid ${theme.colors.foreground.tint};
      font-weight: bold;
    `}

  &:hover {
    color: ${({ theme, selected }) =>
      selected
        ? theme.colors.foreground.primary
        : theme.colors.foreground.tint};
  }
`;

export const selectableFiltersByLabel = {
  Skapade: {
    statuses: new Set([
      AssignmentStatusEnum.Created,
      AssignmentStatusEnum.Planned,
    ]),
    deviations: new Set([AssignmentDeviationEnum.None]),
  },

  Bokade: {
    statuses: new Set([
      AssignmentStatusEnum.Planned,
      AssignmentStatusEnum.Assigned,
      AssignmentStatusEnum.Accepted,
    ]),
    deviations: new Set([AssignmentDeviationEnum.None]),
  },

  Aktiva: {
    statuses: new Set([
      AssignmentStatusEnum.Started,
      AssignmentStatusEnum.Arrived,
      AssignmentStatusEnum.TestComplete,
      AssignmentStatusEnum.Returning,
      AssignmentStatusEnum.AbortedNotFinished,
      AssignmentStatusEnum.CanceledNotFinished,
    ]),
    deviations: new Set([AssignmentDeviationEnum.None]),
  },

  Slutförda: {
    statuses: new Set([
      AssignmentStatusEnum.Complete,
      AssignmentStatusEnum.Aborted,
      AssignmentStatusEnum.Canceled,
    ]),
    deviations: new Set([
      AssignmentDeviationEnum.Aborted,
      AssignmentDeviationEnum.Canceled,
      AssignmentDeviationEnum.None,
    ]),
  },

  Godkända: {
    statuses: new Set([AssignmentStatusEnum.Approved]),
    deviations: new Set([
      AssignmentDeviationEnum.Aborted,
      AssignmentDeviationEnum.Canceled,
      AssignmentDeviationEnum.None,
    ]),
  },
} as const;

const FilterPicker: React.FC = () => {
  const {
    state: { visibleStatuses, visibleDeviations },
    setVisibleStatuses,
    setVisibleDeviations,
  } = useAssignmentsFilters();

  return (
    <FilterPickerContainer>
      <FilterButton
        onClick={() => setVisibleStatuses(null)}
        selected={visibleStatuses === null}
      >
        Alla
      </FilterButton>

      {Object.entries(selectableFiltersByLabel).map(
        ([label, { statuses, deviations }]) => (
          <FilterButton
            key={label}
            onClick={() => {
              setVisibleStatuses(statuses);
              setVisibleDeviations(deviations);
            }}
            selected={
              visibleStatuses === statuses && visibleDeviations === deviations
            }
            type="button"
          >
            {label}
          </FilterButton>
        )
      )}
    </FilterPickerContainer>
  );
};

export default FilterPicker;
