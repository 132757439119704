/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentDeviationEnum =
  (typeof AssignmentDeviationEnum)[keyof typeof AssignmentDeviationEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentDeviationEnum = {
  None: 0,
  Canceled: 1,
  Aborted: 2,
  Discarded: 3,
} as const;
