/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  HolidayModel,
  HolidayModelBody,
  HolidaysDeleteHolidayParams,
  HolidaysGetHolidayParams,
  HolidaysGetHolidaysParams,
} from '.././model';
import useHolidaysGetHolidaysMutator from '.././mutator/custom-instance';
import useHolidaysGetHolidayMutator from '.././mutator/custom-instance';
import useHolidaysCreateHolidayMutator from '.././mutator/custom-instance';
import useHolidaysUpdateHolidayMutator from '.././mutator/custom-instance';
import useHolidaysDeleteHolidayMutator from '.././mutator/custom-instance';
import useHolidaysBulkAddHolidaysMutator from '.././mutator/custom-instance';

export const useHolidaysGetHolidaysHook = () => {
  const holidaysGetHolidays = useHolidaysGetHolidaysMutator<HolidayModel[]>();

  return (params?: HolidaysGetHolidaysParams) => {
    return holidaysGetHolidays({
      url: `/api/Holidays/GetHolidays`,
      method: 'POST',
      params,
    });
  };
};

export const useHolidaysGetHolidaysMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidaysHook>>>,
    TError,
    { params?: HolidaysGetHolidaysParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidaysHook>>>,
  TError,
  { params?: HolidaysGetHolidaysParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const holidaysGetHolidays = useHolidaysGetHolidaysHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidaysHook>>>,
    { params?: HolidaysGetHolidaysParams }
  > = (props) => {
    const { params } = props ?? {};

    return holidaysGetHolidays(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type HolidaysGetHolidaysMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidaysHook>>>
>;

export type HolidaysGetHolidaysMutationError = unknown;

export const useHolidaysGetHolidays = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidaysHook>>>,
    TError,
    { params?: HolidaysGetHolidaysParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidaysHook>>>,
  TError,
  { params?: HolidaysGetHolidaysParams },
  TContext
> => {
  const mutationOptions = useHolidaysGetHolidaysMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useHolidaysGetHolidayHook = () => {
  const holidaysGetHoliday = useHolidaysGetHolidayMutator<HolidayModel>();

  return (params?: HolidaysGetHolidayParams, signal?: AbortSignal) => {
    return holidaysGetHoliday({
      url: `/api/Holidays/GetHoliday`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getHolidaysGetHolidayQueryKey = (
  params?: HolidaysGetHolidayParams
) => {
  return [`/api/Holidays/GetHoliday`, ...(params ? [params] : [])] as const;
};

export const useHolidaysGetHolidayQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidayHook>>>,
  TError = unknown,
>(
  params?: HolidaysGetHolidayParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidayHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getHolidaysGetHolidayQueryKey(params);

  const holidaysGetHoliday = useHolidaysGetHolidayHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidayHook>>>
  > = ({ signal }) => holidaysGetHoliday(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidayHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type HolidaysGetHolidayQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidayHook>>>
>;
export type HolidaysGetHolidayQueryError = unknown;

export const useHolidaysGetHoliday = <
  TData = Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidayHook>>>,
  TError = unknown,
>(
  params?: HolidaysGetHolidayParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useHolidaysGetHolidayHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useHolidaysGetHolidayQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useHolidaysCreateHolidayHook = () => {
  const holidaysCreateHoliday = useHolidaysCreateHolidayMutator<HolidayModel>();

  return (holidayModelBody: HolidayModelBody) => {
    return holidaysCreateHoliday({
      url: `/api/Holidays/CreateHoliday`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: holidayModelBody,
    });
  };
};

export const useHolidaysCreateHolidayMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysCreateHolidayHook>>>,
    TError,
    { data: HolidayModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useHolidaysCreateHolidayHook>>>,
  TError,
  { data: HolidayModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const holidaysCreateHoliday = useHolidaysCreateHolidayHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useHolidaysCreateHolidayHook>>>,
    { data: HolidayModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return holidaysCreateHoliday(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type HolidaysCreateHolidayMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useHolidaysCreateHolidayHook>>>
>;
export type HolidaysCreateHolidayMutationBody = HolidayModelBody;
export type HolidaysCreateHolidayMutationError = unknown;

export const useHolidaysCreateHoliday = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysCreateHolidayHook>>>,
    TError,
    { data: HolidayModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useHolidaysCreateHolidayHook>>>,
  TError,
  { data: HolidayModelBody },
  TContext
> => {
  const mutationOptions = useHolidaysCreateHolidayMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useHolidaysUpdateHolidayHook = () => {
  const holidaysUpdateHoliday = useHolidaysUpdateHolidayMutator<HolidayModel>();

  return (holidayModelBody: HolidayModelBody) => {
    return holidaysUpdateHoliday({
      url: `/api/Holidays/UpdateHoliday`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: holidayModelBody,
    });
  };
};

export const useHolidaysUpdateHolidayMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysUpdateHolidayHook>>>,
    TError,
    { data: HolidayModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useHolidaysUpdateHolidayHook>>>,
  TError,
  { data: HolidayModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const holidaysUpdateHoliday = useHolidaysUpdateHolidayHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useHolidaysUpdateHolidayHook>>>,
    { data: HolidayModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return holidaysUpdateHoliday(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type HolidaysUpdateHolidayMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useHolidaysUpdateHolidayHook>>>
>;
export type HolidaysUpdateHolidayMutationBody = HolidayModelBody;
export type HolidaysUpdateHolidayMutationError = unknown;

export const useHolidaysUpdateHoliday = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysUpdateHolidayHook>>>,
    TError,
    { data: HolidayModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useHolidaysUpdateHolidayHook>>>,
  TError,
  { data: HolidayModelBody },
  TContext
> => {
  const mutationOptions = useHolidaysUpdateHolidayMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useHolidaysDeleteHolidayHook = () => {
  const holidaysDeleteHoliday = useHolidaysDeleteHolidayMutator<void>();

  return (params?: HolidaysDeleteHolidayParams) => {
    return holidaysDeleteHoliday({
      url: `/api/Holidays/DeleteHoliday`,
      method: 'DELETE',
      params,
    });
  };
};

export const useHolidaysDeleteHolidayMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysDeleteHolidayHook>>>,
    TError,
    { params?: HolidaysDeleteHolidayParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useHolidaysDeleteHolidayHook>>>,
  TError,
  { params?: HolidaysDeleteHolidayParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const holidaysDeleteHoliday = useHolidaysDeleteHolidayHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useHolidaysDeleteHolidayHook>>>,
    { params?: HolidaysDeleteHolidayParams }
  > = (props) => {
    const { params } = props ?? {};

    return holidaysDeleteHoliday(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type HolidaysDeleteHolidayMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useHolidaysDeleteHolidayHook>>>
>;

export type HolidaysDeleteHolidayMutationError = unknown;

export const useHolidaysDeleteHoliday = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysDeleteHolidayHook>>>,
    TError,
    { params?: HolidaysDeleteHolidayParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useHolidaysDeleteHolidayHook>>>,
  TError,
  { params?: HolidaysDeleteHolidayParams },
  TContext
> => {
  const mutationOptions = useHolidaysDeleteHolidayMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useHolidaysBulkAddHolidaysHook = () => {
  const holidaysBulkAddHolidays = useHolidaysBulkAddHolidaysMutator<void>();

  return (holidayModel: HolidayModel[]) => {
    return holidaysBulkAddHolidays({
      url: `/api/Holidays/BulkAddHolidays`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: holidayModel,
    });
  };
};

export const useHolidaysBulkAddHolidaysMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysBulkAddHolidaysHook>>>,
    TError,
    { data: HolidayModel[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useHolidaysBulkAddHolidaysHook>>>,
  TError,
  { data: HolidayModel[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const holidaysBulkAddHolidays = useHolidaysBulkAddHolidaysHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useHolidaysBulkAddHolidaysHook>>>,
    { data: HolidayModel[] }
  > = (props) => {
    const { data } = props ?? {};

    return holidaysBulkAddHolidays(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type HolidaysBulkAddHolidaysMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useHolidaysBulkAddHolidaysHook>>>
>;
export type HolidaysBulkAddHolidaysMutationBody = HolidayModel[];
export type HolidaysBulkAddHolidaysMutationError = unknown;

export const useHolidaysBulkAddHolidays = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useHolidaysBulkAddHolidaysHook>>>,
    TError,
    { data: HolidayModel[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useHolidaysBulkAddHolidaysHook>>>,
  TError,
  { data: HolidayModel[] },
  TContext
> => {
  const mutationOptions = useHolidaysBulkAddHolidaysMutationOptions(options);

  return useMutation(mutationOptions);
};
