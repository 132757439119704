import {
  AssignmentClient,
  AssignmentExtendedModel,
  AssignmentViewModel,
  CaseTypeEnum,
  ExpenseType,
} from 'api';
import { useCaseAddOrUpdateComment } from 'api/case/case';
import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import { useCallback, useMemo, useState } from 'react';
import { useApiCall } from 'swaggerhooks/lib';
import { validateMoveToApproved } from 'utils/assignmentStatusValidation';

export type MissingExpenses = {
  assignment: AssignmentExtendedModel;
  caseType: CaseTypeEnum;
  missingExpenses: ExpenseType[];
};

const useAssignmentsBulkOperations = (
  selectableAssignments: AssignmentViewModel[],
  onAssignmentsUpdated: () => void
) => {
  const modalstack = useModalStack();
  // get the extended assignments (including expected expenses, etc) for the selected assignments
  const getExtendedAssignmentCall = useApiCall(AssignmentClient, (c, ids) =>
    c.getExtendedAssignments(ids)
  );
  const approveCall = useApiCall(
    AssignmentClient,
    (c, avms: AssignmentViewModel[]) => c.approveMultipleAssignments(avms)
  );
  const sendMessage = useCaseAddOrUpdateComment();
  const [selectedAssignmentIds, setSelectedAssignmentIds] = useState(
    new Set<number>()
  );

  const selectedAssignments = useMemo((): Record<
    number,
    AssignmentViewModel
  > => {
    const result: Record<number, AssignmentViewModel> = {};
    selectableAssignments.forEach((a) => {
      if (selectedAssignmentIds.has(a.assignment.assignmentID)) {
        result[a.assignment.assignmentID] = a;
      }
    });
    return result;
  }, [selectedAssignmentIds, selectableAssignments]);

  const selectedApprovableAssignments = useMemo(
    () =>
      selectableAssignments
        .filter((avm) => selectedAssignmentIds.has(avm.assignment.assignmentID))
        .filter((a) => validateMoveToApproved(a).length === 0),
    [selectableAssignments, selectedAssignmentIds]
  );

  const toggleSelectAssignment = useCallback((assignmentId: number) => {
    setSelectedAssignmentIds((ids) => {
      const newIds = new Set(ids);
      if (newIds.has(assignmentId)) {
        newIds.delete(assignmentId);
      } else {
        newIds.add(assignmentId);
      }

      return newIds;
    });
  }, []);

  const selectAllAssignments = useCallback(() => {
    const newSelectedAssignmentIds = new Set(
      selectableAssignments
        .filter((a) => validateMoveToApproved(a).length === 0)
        .map((a) => a.assignment.assignmentID)
    );
    setSelectedAssignmentIds(newSelectedAssignmentIds);
  }, [selectableAssignments]);

  const selectAllApprovableAssignments = useCallback(() => {
    const newSelectedAssignmentIds = new Set(
      selectableAssignments.map((a) => a.assignment.assignmentID)
    );
    setSelectedAssignmentIds(newSelectedAssignmentIds);
  }, [selectableAssignments]);

  const approveSelectedAssignments = useCallback(async () => {
    const toApprove = selectedApprovableAssignments;
    const [result, error] = await approveCall.run(toApprove);

    if (result?.length) {
      const modalId = modalstack.push(
        <Modal
          buttons={[{ label: 'Ok', onClick: () => modalstack.pop(modalId) }]}
          onClose={() => modalstack.pop(modalId)}
          title="Fel"
        >
          Det gick inte att godkänna {result.length} av {toApprove.length}{' '}
          uppdrag. Försök igen.
        </Modal>
      );
    } else if (!error) {
      setSelectedAssignmentIds(new Set());
    }
    onAssignmentsUpdated();
  }, [
    approveCall,
    modalstack,
    onAssignmentsUpdated,
    selectedApprovableAssignments,
  ]);

  const messageSelectedAssignments = useCallback(
    async (message: string) => {
      const assignmentsToMessage = selectableAssignments.filter((a) =>
        selectedAssignmentIds.has(a.assignment.assignmentID)
      );

      await assignmentsToMessage.forEach((assignment) =>
        sendMessage.mutateAsync(
          {
            params: {
              caseId: assignment.case.caseID,
              message,
              readByAdmin: true,
            },
          },
          {
            onError(error, variables) {
              const modalId = modalstack.push(
                <Modal
                  buttons={[
                    { label: 'Ok', onClick: () => modalstack.pop(modalId) },
                  ]}
                  onClose={() => modalstack.pop(modalId)}
                  title="Fel"
                >
                  Något gick fel med att skicka meddelande till uppdrag{' '}
                  {variables.params?.caseId}. Försök igen.
                </Modal>
              );
            },
          }
        )
      );

      setSelectedAssignmentIds(new Set());
    },
    [modalstack, selectableAssignments, selectedAssignmentIds, sendMessage]
  );

  const checkExpectedExpenses = useCallback(async () => {
    // first, fetch the extended assignments
    const [extendedAssignmentsList, error] =
      await getExtendedAssignmentCall.run(Array.from(selectedAssignmentIds));
    if (error || !extendedAssignmentsList) {
      return [];
    }
    // then, check for missing expenses
    // const response = selectableAssignments
    //   .filter((sa) => selectedAssignmentIds.has(sa.assignment.assignmentID))
    const response = extendedAssignmentsList.reduce((acc, cur) => {
      const missingExpenses = cur.assignment.expectedExpenses.reduce((a, c) => {
        if (
          cur.expenses.some(
            (e) => e.expenseType === c.type && e.approvedBy !== null
          )
        ) {
          return a;
        }

        return [...a, c.type];
      }, [] as ExpenseType[]);

      return [
        ...acc,
        {
          missingExpenses,
          assignment: cur,
          caseType: cur.case.caseTypeID,
        } as MissingExpenses,
      ];
    }, [] as MissingExpenses[]);

    return response;
  }, [getExtendedAssignmentCall, selectedAssignmentIds]);

  return useMemo(
    () => ({
      clearSelectedAssignments: () => setSelectedAssignmentIds(new Set()),
      selectedAssignmentIds,
      selectedAssignments,
      toggleSelectAssignment,
      selectAllAssignments,
      selectedApprovableAssignments,
      selectAllApprovableAssignments,
      approveSelectedAssignments,
      approveStatus: approveCall.status,
      messageSelectedAssignments,
      sendMessageStatus: sendMessage.status,
      checkExpectedExpenses,
    }),
    [
      selectedAssignmentIds,
      selectedAssignments,
      toggleSelectAssignment,
      selectAllAssignments,
      selectedApprovableAssignments,
      selectAllApprovableAssignments,
      approveSelectedAssignments,
      approveCall.status,
      messageSelectedAssignments,
      sendMessage.status,
      checkExpectedExpenses,
    ]
  );
};

export default useAssignmentsBulkOperations;
