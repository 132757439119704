const hasRoleOverlap = (
  expectedRoles: number[],
  actualRoles: number[] | undefined = []
): boolean => {
  return expectedRoles.some((role) => actualRoles.includes(role));
};

export default {
  hasRoleOverlap,
};
