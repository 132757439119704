import React from 'react';
import styled from 'styled-components';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';

const ProgressContainer = styled.div`
  width: 100%; // Make the container fill the full width
  height: 100%; // Make the container fill the full height
  background: ${({ theme }) =>
    theme.colors.background.tertiary}; // Background for the unfilled part
`;

const Progress = styled.div<{ widthPercent: number }>`
  width: ${({ widthPercent }) => widthPercent}%;
  background: ${({ widthPercent, theme }) =>
    widthPercent === 100
      ? theme.colors.foreground.error
      : widthPercent > 70
        ? theme.colors.foreground.warning // You can use your theme color here
        : theme.colors.foreground.good};
  height: 100%; // Make the progress bar fill the container's height
  display: flex;
  align-items: center;
  padding: 0px 5px;
  color: ${({ theme, widthPercent }) =>
    widthPercent === 100
      ? theme.colors.foreground.button
      : theme.colors.foreground.primary};
`;

interface Props {
  value: number;
  maxValue: number;
}

const DurationProgressBar: React.FC<Props> = ({ value, maxValue }) => {
  return (
    <ProgressContainer>
      <Progress widthPercent={(value / maxValue) * 100}>
        {getHoursAndMinutesFromMillisecondsString(value)}
      </Progress>
    </ProgressContainer>
  );
};

export default DurationProgressBar;
