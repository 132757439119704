import TransparentSpinner from 'components/spinners/TransparentSpinner';
import React, { FC } from 'react';
import styled, { css, useTheme } from 'styled-components';

export const buttonStyle = css<{ negative?: boolean; positive?: boolean }>`
  margin: 0;
  padding: 10px 20px;

  border: 0;
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  background: ${({ theme, negative, positive }) => {
    if (negative) return theme.colors.background.negative;
    if (positive) return theme.colors.background.positive;
    return theme.colors.background.button;
  }};
  color: ${({ theme }) => theme.colors.foreground.button};
  font: inherit;
  font-weight: 500;
  cursor: pointer;

  &:disabled {
    background: ${({ theme }) => theme.colors.background.tertiary};
  }
  &:active {
    opacity: 0.5;
  }
`;

const StyledButton = styled.button`
  ${buttonStyle}
` as FC<React.HTMLProps<HTMLButtonElement>>;

const IconWrapper = styled.span<{ placement?: 'left' | 'right' }>`
  ${({ placement }) =>
    placement === 'left' ? 'margin-right: 5px;' : 'margin-left: 5px;'}
`;

export interface ButtonProps extends React.HTMLProps<HTMLButtonElement> {
  icon?: React.ReactNode;
  iconPlacement?: 'left' | 'right';
  loading?: boolean;
  negative?: boolean;
  positive?: boolean;
}
const Button = ({
  icon,
  iconPlacement = 'left',
  loading,
  children,
  ...buttonProps
}: ButtonProps) => {
  const theme = useTheme();
  return (
    <StyledButton {...buttonProps}>
      {loading ? (
        <TransparentSpinner
          color={
            buttonProps.negative || buttonProps.positive
              ? theme.colors.foreground.secondary
              : undefined
          }
          small
        />
      ) : (
        <>
          {icon && iconPlacement === 'left' && (
            <IconWrapper placement={iconPlacement}>{icon}</IconWrapper>
          )}
          {children}
          {icon && iconPlacement === 'right' && (
            <IconWrapper placement={iconPlacement}>{icon}</IconWrapper>
          )}
        </>
      )}
    </StyledButton>
  );
};

export default Button;
