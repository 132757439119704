/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AverageAssignmentDurationModel,
  AverageAssignmentDurationsGetAverageAssignmentDurationParams,
  AverageAssignmentDurationsGetAverageAssignmentDurationsParams,
  AverageAssignmentDurationsUpdateAverageAssignmentDurationsParams,
  EstimatedAssignmentDurationDto,
} from '.././model';
import useAverageAssignmentDurationsGetAverageAssignmentDurationsMutator from '.././mutator/custom-instance';
import useAverageAssignmentDurationsGetAverageAssignmentDurationMutator from '.././mutator/custom-instance';
import useAverageAssignmentDurationsUpdateAverageAssignmentDurationsMutator from '.././mutator/custom-instance';

export const useAverageAssignmentDurationsGetAverageAssignmentDurationsHook =
  () => {
    const averageAssignmentDurationsGetAverageAssignmentDurations =
      useAverageAssignmentDurationsGetAverageAssignmentDurationsMutator<
        AverageAssignmentDurationModel[]
      >();

    return (
      params?: AverageAssignmentDurationsGetAverageAssignmentDurationsParams,
      signal?: AbortSignal
    ) => {
      return averageAssignmentDurationsGetAverageAssignmentDurations({
        url: `/api/AverageAssignmentDurations/GetAverageAssignmentDurations`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getAverageAssignmentDurationsGetAverageAssignmentDurationsQueryKey =
  (params?: AverageAssignmentDurationsGetAverageAssignmentDurationsParams) => {
    return [
      `/api/AverageAssignmentDurations/GetAverageAssignmentDurations`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useAverageAssignmentDurationsGetAverageAssignmentDurationsQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAverageAssignmentDurationsGetAverageAssignmentDurationsHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AverageAssignmentDurationsGetAverageAssignmentDurationsParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAverageAssignmentDurationsGetAverageAssignmentDurationsHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getAverageAssignmentDurationsGetAverageAssignmentDurationsQueryKey(
        params
      );

    const averageAssignmentDurationsGetAverageAssignmentDurations =
      useAverageAssignmentDurationsGetAverageAssignmentDurationsHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsGetAverageAssignmentDurationsHook
          >
        >
      >
    > = ({ signal }) =>
      averageAssignmentDurationsGetAverageAssignmentDurations(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsGetAverageAssignmentDurationsHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type AverageAssignmentDurationsGetAverageAssignmentDurationsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAverageAssignmentDurationsGetAverageAssignmentDurationsHook
        >
      >
    >
  >;
export type AverageAssignmentDurationsGetAverageAssignmentDurationsQueryError =
  unknown;

export const useAverageAssignmentDurationsGetAverageAssignmentDurations = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useAverageAssignmentDurationsGetAverageAssignmentDurationsHook
      >
    >
  >,
  TError = unknown,
>(
  params?: AverageAssignmentDurationsGetAverageAssignmentDurationsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsGetAverageAssignmentDurationsHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAverageAssignmentDurationsGetAverageAssignmentDurationsQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAverageAssignmentDurationsGetAverageAssignmentDurationHook =
  () => {
    const averageAssignmentDurationsGetAverageAssignmentDuration =
      useAverageAssignmentDurationsGetAverageAssignmentDurationMutator<EstimatedAssignmentDurationDto>();

    return (
      params?: AverageAssignmentDurationsGetAverageAssignmentDurationParams,
      signal?: AbortSignal
    ) => {
      return averageAssignmentDurationsGetAverageAssignmentDuration({
        url: `/api/AverageAssignmentDurations/GetAverageAssignmentDuration`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getAverageAssignmentDurationsGetAverageAssignmentDurationQueryKey =
  (params?: AverageAssignmentDurationsGetAverageAssignmentDurationParams) => {
    return [
      `/api/AverageAssignmentDurations/GetAverageAssignmentDuration`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useAverageAssignmentDurationsGetAverageAssignmentDurationQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAverageAssignmentDurationsGetAverageAssignmentDurationHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AverageAssignmentDurationsGetAverageAssignmentDurationParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAverageAssignmentDurationsGetAverageAssignmentDurationHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getAverageAssignmentDurationsGetAverageAssignmentDurationQueryKey(params);

    const averageAssignmentDurationsGetAverageAssignmentDuration =
      useAverageAssignmentDurationsGetAverageAssignmentDurationHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsGetAverageAssignmentDurationHook
          >
        >
      >
    > = ({ signal }) =>
      averageAssignmentDurationsGetAverageAssignmentDuration(params, signal);

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsGetAverageAssignmentDurationHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type AverageAssignmentDurationsGetAverageAssignmentDurationQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAverageAssignmentDurationsGetAverageAssignmentDurationHook
        >
      >
    >
  >;
export type AverageAssignmentDurationsGetAverageAssignmentDurationQueryError =
  unknown;

export const useAverageAssignmentDurationsGetAverageAssignmentDuration = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useAverageAssignmentDurationsGetAverageAssignmentDurationHook
      >
    >
  >,
  TError = unknown,
>(
  params?: AverageAssignmentDurationsGetAverageAssignmentDurationParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsGetAverageAssignmentDurationHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAverageAssignmentDurationsGetAverageAssignmentDurationQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook =
  () => {
    const averageAssignmentDurationsUpdateAverageAssignmentDurations =
      useAverageAssignmentDurationsUpdateAverageAssignmentDurationsMutator<void>();

    return (
      params?: AverageAssignmentDurationsUpdateAverageAssignmentDurationsParams
    ) => {
      return averageAssignmentDurationsUpdateAverageAssignmentDurations({
        url: `/api/AverageAssignmentDurations/UpdateAverageAssignmentDurations`,
        method: 'POST',
        params,
      });
    };
  };

export const useAverageAssignmentDurationsUpdateAverageAssignmentDurationsMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook
          >
        >
      >,
      TError,
      {
        params?: AverageAssignmentDurationsUpdateAverageAssignmentDurationsParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook
        >
      >
    >,
    TError,
    {
      params?: AverageAssignmentDurationsUpdateAverageAssignmentDurationsParams;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const averageAssignmentDurationsUpdateAverageAssignmentDurations =
      useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook
          >
        >
      >,
      {
        params?: AverageAssignmentDurationsUpdateAverageAssignmentDurationsParams;
      }
    > = (props) => {
      const { params } = props ?? {};

      return averageAssignmentDurationsUpdateAverageAssignmentDurations(params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AverageAssignmentDurationsUpdateAverageAssignmentDurationsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook
        >
      >
    >
  >;

export type AverageAssignmentDurationsUpdateAverageAssignmentDurationsMutationError =
  unknown;

export const useAverageAssignmentDurationsUpdateAverageAssignmentDurations = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook
        >
      >
    >,
    TError,
    {
      params?: AverageAssignmentDurationsUpdateAverageAssignmentDurationsParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useAverageAssignmentDurationsUpdateAverageAssignmentDurationsHook
      >
    >
  >,
  TError,
  { params?: AverageAssignmentDurationsUpdateAverageAssignmentDurationsParams },
  TContext
> => {
  const mutationOptions =
    useAverageAssignmentDurationsUpdateAverageAssignmentDurationsMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
