import { CaseEventType, CaseExtendedModel, ExpenseType } from "api";
import { MissingExpenses } from "pages/GlobalSearch/useAssignmentsBulkOperations";

const validateExpectedExpenses = (c: CaseExtendedModel, assignmentId: number) => {
  const assignment = c.case.assignmentList.find(e => e.assignmentID === assignmentId);
  if (!assignment)
    throw new Error("Unable to find the assignment to validate expected expenses for");

  const missingExpenses = assignment.expectedExpenses.reduce(
    (acc, cur) => {
      if (
        c.expenses.filter(e => e.assignmentID === assignmentId).some(
          (e) => e.expenseType === cur.type && e.approvedBy !== null
        )
      ) {
        return acc;
      }

      return [...acc, cur.type];
    },
    [] as ExpenseType[]
  );

  return {
    assignment: {
      assignment,
      expenses: c.expenses.filter(e => e.assignmentID === assignmentId) ?? [],
      comments: c.events.filter(e => e.assignmentID === assignmentId &&
        (e.caseEventType === CaseEventType.Comment ||
          e.caseEventType === CaseEventType.RideComment ||
          e.caseEventType === CaseEventType.InternalComment)) ?? [],
    },
    caseType: c.case.caseTypeID,
    missingExpenses
  } as MissingExpenses
}

export default validateExpectedExpenses;