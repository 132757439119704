import { CaseModel } from 'api';
import { atom, useRecoilState, useRecoilValue } from 'recoil';

const caseCopyAtom = atom<CaseModel | null>({
  key: 'case-copy',
  default: null,
});

export const useCopyCaseState = () => {
  return useRecoilState(caseCopyAtom);
};

export const useCopyCaseValue = () => {
  return useRecoilValue(caseCopyAtom);
};
