import { FC, useEffect } from 'react';
import { BusinessUnitClient, BusinessUnitModel, IBusinessUnitModel } from 'api';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { arraySpreadIf } from 'utils/spreading';
import LabelWrap from 'components/inputs/LabelWrap';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import Checkbox from 'components/inputs/Checkbox';
import { useBusinessUnitGetBusinessUnitGroups } from 'api/business-unit/business-unit';
import Select from 'components/inputs/Select';

const FormWrap = styled.div<{ maxWidth?: number; compact?: boolean }>`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: ${({ compact }) => (compact ? '0' : '10px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;

const MyLabelWrap = styled(LabelWrap)<{ width?: number }>`
  & > *:last-child {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    max-height: 100%;
  }
`;

interface Props {
  unit?: IBusinessUnitModel;
  onCancel?(): void;
  onSaved?(): void;
  canEdit?: boolean;
  title?: string;
}

const BusinessUnitFormModal: FC<Props> = ({
  unit,
  onCancel,
  onSaved,
  canEdit,
  title,
}) => {
  const { register, handleSubmit, setValue } = useForm<BusinessUnitModel>();

  const businessUnitGroups = useBusinessUnitGetBusinessUnitGroups();

  // Populate form with area data on first render
  useEffect(() => {
    if (unit) {
      setValue('id', unit.id);
      setValue('name', unit.name);
      setValue('description', unit.description);
      setValue('isActive', unit.isActive);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [unit]);

  const createMode = !unit;

  const createCall = useApiCall(
    BusinessUnitClient,
    (c, model: BusinessUnitModel) => c.createBusinessUnit(model)
  );

  const updateCall = useApiCall(
    BusinessUnitClient,
    (c, model: BusinessUnitModel) => c.updateBusinessUnit(model)
  );

  const handleCreateClick = handleSubmit(async (data) => {
    const [, error] = await createCall.run(data);

    if (!error) {
      onSaved?.();
    }
  });

  const handleUpdateClick = handleSubmit(async (data) => {
    if (!data) return;

    const [, error] = await updateCall.run(data);

    if (!error) {
      onSaved?.();
    }
  });

  const handleCancelClick = () => {
    onCancel?.();
  };

  const saveStatus = createMode ? createCall.status : updateCall.status;

  const renderContent = () => {
    switch (saveStatus) {
      case RequestStatus.Idle:
      case RequestStatus.Fetched:
      case RequestStatus.Error:
        return (
          <FormWrap compact>
            <MyLabelWrap label="Namn" width={270}>
              <Input
                {...register('name', { required: true })}
                disabled={!canEdit}
                placeholder="Namn"
              />
            </MyLabelWrap>
            <MyLabelWrap label="Beskrivning" width={270}>
              <TextArea
                {...register('description', { required: true })}
                disabled={!canEdit}
                placeholder="Beskrivning"
              />
            </MyLabelWrap>
            <MyLabelWrap>
              <Checkbox {...register('isActive', { required: true })}>
                Aktiv
              </Checkbox>
            </MyLabelWrap>
            <MyLabelWrap>
              <Select
                {...register('businessUnitGroupID', { required: true })}
                disabled={!canEdit}
              >
                <option value="">-- Välj enhetsgrupp --</option>
                {businessUnitGroups.data?.map((group) => (
                  <option key={group.id} value={group.id}>
                    {group.name}
                  </option>
                ))}
              </Select>
            </MyLabelWrap>
          </FormWrap>
        );

      case RequestStatus.Fetching:
      default:
        return <LoadingSpinner>Sparar...</LoadingSpinner>;
    }
  };

  return (
    <Modal
      buttons={
        saveStatus === RequestStatus.Fetching
          ? []
          : [
              ...arraySpreadIf(!!canEdit, {
                label: createMode ? 'Skapa enhet' : 'Uppdatera enhet',
                onClick: createMode ? handleCreateClick : handleUpdateClick,
              }),
              {
                label: canEdit ? 'Avbryt' : 'Stäng',
                onClick: handleCancelClick,
              },
            ]
      }
      onClose={handleCancelClick}
      title={
        title !== undefined
          ? title
          : createMode
            ? 'Skapa enhet'
            : 'Redigera enhet'
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default BusinessUnitFormModal;
