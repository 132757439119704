import React, { useState } from 'react';
import Modal from './Modal';
import { AssignmentModel, AssignmentViewModel } from 'api';
import RatingWidget from './RatingWidget';

interface Props {
  model: AssignmentViewModel;
  onClose(): void;
  onRatedAssignment(model: AssignmentViewModel): void;
}

const AssignmentRatingModal: React.FC<Props> = ({
  model,
  onClose,
  onRatedAssignment,
}) => {
  const [updatedModel, setUpdatedModel] = useState<AssignmentViewModel>(model);

  return (
    <Modal
      buttons={[
        {
          label: 'Ok',
          onClick: () => {
            onRatedAssignment(updatedModel);
            onClose();
          },
        },
        {
          label: 'Avbryt',
          onClick: onClose,
        },
      ]}
      onClose={() => onClose}
      title="Utvärdering Fälttestare"
    >
      <RatingWidget
        title={`Vilket betyg ger du ${model.assignment.assignedTo?.name} för uppdraget?`}
        initialRating={updatedModel.assignment.assignedToRating}
        onRatingChanged={async (rating, message) => {
          setUpdatedModel(
            new AssignmentViewModel({
              ...updatedModel,
              assignment: new AssignmentModel({
                ...updatedModel.assignment,
                assignedToRating: rating,
                assignedToRatingReason: message,
              }),
            })
          );
        }}
      />
    </Modal>
  );
};

export default AssignmentRatingModal;
