import React from 'react';
import urlHelpers from 'utils/url-helpers';
import { Message } from './DetailedCase/EventsAndComments/eventComponents/components';
import styled from 'styled-components';

const MyMessage = styled(Message)`
  flex: 1;
  padding-top: 0;
`;

interface Props {
  text: string;
}
const RichMessage: React.FC<Props> = ({ text }) => {
  const contentParts = urlHelpers.detectLinksAndSplitText(text);

  return (
    <MyMessage>
      {contentParts.map((part) =>
        part.type === 'link' ? (
          <a
            key={part.key}
            href={part.content}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: 'blue' }}
          >
            {part.content}
          </a>
        ) : (
          <span key={part.key}>{part.content}</span>
        )
      )}
    </MyMessage>
  );
};

export default RichMessage;
