import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

interface IsIdleContext {
  isIdle: boolean;
  resetTimer: () => void;
}

const IdleContext = createContext<IsIdleContext>({
  isIdle: false,
  resetTimer: () => {},
});

export const useIdle = () => {
  const context = useContext(IdleContext);
  if (!context) {
    throw new Error('useIdle must be used within an IdleProvider');
  }
  return context;
};

interface IdleProviderProps {
  milliseconds: number;
  children: React.ReactNode;
}

export const IdleProvider: React.FC<IdleProviderProps> = ({
  children,
  milliseconds,
}) => {
  const [isIdle, setIsIdle] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const resetTimer = useCallback(() => {
    setIsIdle(false);
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => setIsIdle(true), milliseconds);
  }, [milliseconds]);

  useEffect(() => {
    timeoutRef.current = setTimeout(() => setIsIdle(true), milliseconds);

    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keydown', resetTimer);
    window.addEventListener('scroll', resetTimer);
    window.addEventListener('touchstart', resetTimer);

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keydown', resetTimer);
      window.removeEventListener('scroll', resetTimer);
      window.removeEventListener('touchstart', resetTimer);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [milliseconds]);

  const value = useMemo(() => ({ isIdle, resetTimer }), [isIdle, resetTimer]);

  return <IdleContext.Provider value={value}>{children}</IdleContext.Provider>;
};
