/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/no-unused-prop-types */
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useInternalDeliveryBudgetUpdateYearlyBudget } from 'api/internal-delivery-budget/internal-delivery-budget';
import {
  InternalDeliveryBudgetModel,
  InternalDeliveryOverviewEntry,
} from 'api/model';
import Input from 'components/inputs/Input';
import moment from 'moment';
import React, { useState } from 'react';
import styled from 'styled-components';
import OverrideDailyInternalDeliveryBudgetInput from './OverrideDailyInternalDeliveryBudgetInput';
import useWeeklyBudgets from './useWeeklyBudgets';
import { Grid } from './styles';

// input with transparent background and border
const StyledInput = styled(Input)`
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 0;
  margin: 0;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  text-align: center;

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondary};
    border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  }
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;

const StyledSpan = styled.span`
  padding: 0;
  margin: 0;
  cursor: pointer;
  padding: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-weight: 600;
  text-align: center;
  color: red;
`;

interface WeeklyBudgetCellProps {
  rowEntry: InternalDeliveryOverviewEntry;
  value?: InternalDeliveryBudgetModel;
  onUpdate: () => void;
  isEditing?: boolean;
  date: Date;
}
export const WeeklyBudgetCell: React.FC<WeeklyBudgetCellProps> = ({
  value,
  onUpdate,
  rowEntry,
  isEditing,
  date,
}) => {
  const updateBudget = useInternalDeliveryBudgetUpdateYearlyBudget();

  const [currentValue, setCurrentValue] = useState<number>(value?.amount ?? 0);

  const handleUpdate = async () => {
    if (value) {
      // update existing budget
      await updateBudget.mutateAsync({
        data: [
          {
            companyId: rowEntry.companyId,
            weeks: [
              {
                ...value,
                amount: currentValue,
              },
            ],
          },
        ],
      });
    } else {
      // create new budget
      await updateBudget.mutateAsync({
        data: [
          {
            companyId: rowEntry.companyId,
            weeks: [
              {
                companyID: rowEntry.companyId,
                budgetID: 0,
                week: moment(date).isoWeek(),
                year: moment(date).year(),
                amount: currentValue,
              },
            ],
          },
        ],
      });
    }
    onUpdate();
  };

  return isEditing ? (
    <>
      <StyledInput
        type="number"
        value={currentValue}
        onChange={(e) => setCurrentValue(parseInt(e.target.value, 10))}
        onBlur={() => handleUpdate()}
        onKeyDown={(eve) => {
          // save immediately on enter
          if (eve.key === 'Enter') {
            handleUpdate();
          }
        }}
      />
      {updateBudget.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
    </>
  ) : (
    <StyledSpan>{value?.amount ?? 0}</StyledSpan>
  );
};

interface DailyBudgetOverrideCellProps {
  rowEntry: InternalDeliveryOverviewEntry;
  focused?: boolean;
  value: number;
  onUpdate: () => void;
  date: Date;
  onMouseOver: () => void;
  onlyTotals?: boolean;
}

export const DailyBudgetOverrideCell: React.FC<
  DailyBudgetOverrideCellProps
> = ({ rowEntry, focused, date, onMouseOver, onUpdate, onlyTotals }) => {
  const { getDayBudgetTotal } = useWeeklyBudgets();

  let totalUnassigned: number = 0;
  let totalAssigned: number = 0;
  let totalInProgress: number = 0;
  rowEntry.groupEntries
    .find((entryDate) => entryDate.date.getDate() === date.getDate())
    ?.data.forEach((entryData) => {
      totalUnassigned += entryData.unassigned;
      totalAssigned += entryData.assigned;
      totalInProgress += entryData.inProgress;
    });

  const dayBudgetTotal = getDayBudgetTotal(date, rowEntry.companyId);

  const renderRows = () => {
    const groupEntry = rowEntry.groupEntries.find((entryDate) => {
      return entryDate.date.getTime() === date.getTime();
    });

    const dataEntries = groupEntry?.data.map((entryData, index, array) => {
      return (
        <>
          <span title="Otilldelade">{entryData.unassigned}</span>
          <span title="Planerade" style={{ color: 'orange' }}>
            {entryData.assigned}
          </span>
          <span title="Utdelade" style={{ color: 'green' }}>
            {entryData.inProgress}
          </span>
        </>
      );
    });

    return dataEntries;
  };

  return (
    <div
      key={`date_${date.getDate()}_${
        date.getMonth() + 1
      }_${date.getFullYear()}_${
        rowEntry.groupEntries[0].data[0].businessUnitGroupId
      }
                }`}
      style={{
        paddingLeft: '6px',
        paddingRight: '6px',
      }}
    >
      <Grid onMouseOver={onMouseOver} columns={3}>
        {!onlyTotals && renderRows()}
        <BoldSpan title="Otilldelade">{totalUnassigned}</BoldSpan>
        <BoldSpan title="Planerade" style={{ color: 'orange' }}>
          {totalAssigned}
        </BoldSpan>
        <BoldSpan title="Utdelade" style={{ color: 'green' }}>
          {totalInProgress}
        </BoldSpan>
      </Grid>
      <hr />
      <Grid
        columns={3}
        style={{
          fontWeight: 'bold',
        }}
      >
        <span />
        <span />
        <BoldSpan>
          <OverrideDailyInternalDeliveryBudgetInput
            companyId={rowEntry.companyId}
            date={date}
            value={dayBudgetTotal.amount ?? 0}
            overrideId={dayBudgetTotal.overrideId}
            onUpdate={onUpdate}
            isFocused={focused}
          />
        </BoldSpan>
      </Grid>
    </div>
  );
};
