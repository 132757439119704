import { FC, useCallback, useEffect, useMemo } from 'react';
import styled, { css } from 'styled-components';
// import { SearchableSelectItem } from './inputs/SearchableSelect';
import { formatDateTime } from 'utils/date-helpers';
import { AssignmentStatusEnum, AssignmentViewModel } from 'api/model';
import MultiSelect, { Option } from './inputs/MultiSelect';
import TextButton from './inputs/TextButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLink, faSpinner } from '@fortawesome/free-solid-svg-icons';
import useTranslations from 'contexts/basicData/useTranslations';

export const StyledMultiSelectAssignmentItem = styled.div<{
  isNoneItem?: boolean;
  isHovering?: boolean;
}>`
  padding: 0px 4px 0px 4px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: space-between;
  white-space: nowrap;
  width: 100%;
  cursor: pointer;
  ${({ isNoneItem }) =>
    isNoneItem &&
    css`
      font-style: italic;
    `}
  ${({ isHovering, theme }) =>
    isHovering
      ? `
      background-color: ${theme.colors.background.selection}!important;
      color: ${theme.colors.foreground.selection}!important;
    `
      : `
      background-color: ${theme.colors.background.tertiary}!important;
      color: ${theme.colors.foreground.secondary}!important;
    `}
    border-radius: ${({ theme }) => theme.sizes.radius.input}px;
`;

const StyledMultiSelect = styled(MultiSelect<number>)`
  width: 100%;
  padding: 2px;
  gap: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
`;

export const CaseLinkButton: FC<{ caseID: number; assignmentID: number }> = ({
  assignmentID,
  caseID,
}) => {
  return (
    <TextButton
      onClick={() => {
        window.open(
          `/sok/${caseID}?assignment=${assignmentID}`,
          caseID.toString()
        );
      }}
    >
      <FontAwesomeIcon icon={faExternalLink} />
    </TextButton>
  );
};

interface Props {
  disabled?: boolean;
  assignments: AssignmentViewModel[];
  selected: AssignmentViewModel[];
  onSelected(AssignmentViewModel: AssignmentViewModel[]): void;
  maxSelected?: number;
  disabledIds?: number[];
  renderLabel?: (item: Option<number> | undefined) => JSX.Element;
}

const MultiSelectAssignmentPicker: FC<Props> = ({
  disabled,
  selected,
  onSelected,
  assignments,
  maxSelected = 1,
  disabledIds = [],
  renderLabel,
}) => {
  const { assignmentStatuses, tyreTypes } = useTranslations();
  const optionRender = useCallback(
    (item: Option<number> | undefined) => {
      const model = assignments.find(
        (a) => a.assignment.assignmentID === item?.value
      );

      return (
        <StyledMultiSelectAssignmentItem
          key={model?.assignment.assignmentID ?? item?.label}
        >
          {model === undefined ? (
            <FontAwesomeIcon icon={faSpinner} spin />
          ) : (
            <>
              <div>{`${model?.case?.registrationNumber} (${assignmentStatuses[model?.assignment?.assignmentStatusID as AssignmentStatusEnum]})`}</div>
              {model?.case?.caseID !== undefined && (
                <CaseLinkButton
                  assignmentID={model.assignment.assignmentID}
                  caseID={model.case.caseID}
                />
              )}
            </>
          )}
        </StyledMultiSelectAssignmentItem>
      );
    },
    [assignmentStatuses, assignments]
  );

  const handleChange = (selection: Set<number>): void => {
    if (selection.size > maxSelected) {
      // replace with the new value if maxSelected is 1
      if (maxSelected === 1) {
        const newSelection = Array.from(selection).filter(
          (s) => !selected.some((a) => a.assignment.assignmentID === s)
        );
        const selectedRequests = assignments.filter((a) =>
          newSelection.includes(a.assignment.assignmentID)
        );
        onSelected(selectedRequests);
      }
      return;
    }
    const selectedRequests = assignments.filter((a) =>
      selection.has(a.assignment.assignmentID)
    );
    onSelected(selectedRequests);
  };

  // cap the selected requests to maxSelected if it's less than the current selected
  useEffect(() => {
    if (selected.length > maxSelected) {
      onSelected(selected.slice(0, maxSelected));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [maxSelected]);

  const options: Option<number>[] = useMemo(
    () =>
      assignments
        .sort((a, b) => {
          if (a.assignment.bookedTo < b.assignment.bookedTo) {
            return -1;
          }
          if (a.assignment.bookedTo > b.assignment.bookedTo) {
            return 1;
          }
          return 0;
        })
        .map((model) => ({
          label: `${model.case.registrationNumber} (${formatDateTime(model.assignment.bookedTo)}, ${model.assignment.fromCity} – ${model.assignment.toCity}) ${model.case.vehicleTyreType !== undefined && model.case.vehicleTyreType !== null ? `, ${tyreTypes[model.case.vehicleTyreType as number]}` : ''}`,
          description: `${
            model.assignment.fromName || model.assignment.fromAddress
          } – ${model.assignment.toName || model.assignment.toAddress}`,
          value: model.assignment.assignmentID,
          disabled:
            disabledIds?.findIndex((x) => x === model.assignment.assignmentID) >
            -1,
        })),
    [assignments, disabledIds, tyreTypes]
  );

  return (
    <StyledMultiSelect
      placeholder="Välj uppdrag"
      disabled={disabled}
      options={options}
      onChange={handleChange}
      value={new Set(selected.map((a) => a.assignment.assignmentID))}
      renderSelected={optionRender}
      searchFn={(search, option) => {
        return option.label.toLowerCase().includes(search.toLowerCase());
      }}
      labelRender={renderLabel}
    />
  );
};

export default MultiSelectAssignmentPicker;
