import { FC, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Table from 'components/Table';
import {
  CaseAssignmentEventModel,
  CaseEventType,
  ITranslationLookups,
} from 'api';
import Button from 'components/inputs/Button';
import styled, { useTheme } from 'styled-components';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import useTranslations from 'contexts/basicData/useTranslations';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import Checkbox from 'components/inputs/Checkbox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCheck,
  faClock,
  faHome,
  faQuestion,
  faSpinner,
  faTaxi,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import Input from 'components/inputs/Input';
import { formatAddress, IAddress } from 'utils/address';
import {
  getCaseGetCaseAssignmentRequestsQueryKey,
  useCaseApproveCaseRequest,
  useCaseDenyCaseRequest,
  useCaseGetCaseAssignmentRequests,
} from 'api/case/case';
import { useQueryClient } from '@tanstack/react-query';
import useMe from 'contexts/authentication/useMe';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const FullSpan = styled.div`
  display: flex;
  flex: 1;
  cursor: pointer;
  white-space: break-spaces;
`;

const WrappingCell = styled.div`
  white-space: normal;
`;

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

const now = new Date();
now.setHours(0, 0, 0, 0);

type SelectableCaseAssignmentEvent = CaseAssignmentEventModel & {
  selected: boolean;
  isUpdating?: boolean;
};

const CaseAssignmentRequests: FC = () => {
  const me = useMe();
  const [search, setSearch] = useState<string>('');
  const [isBatchUpdating, setIsBatchUpdating] = useState<boolean>(false);
  const [requests, setRequests] = useState<SelectableCaseAssignmentEvent[]>([]);
  const theme = useTheme();
  const navigate = useNavigate();
  const { caseTypes, caseEventTypes } = useTranslations();
  const users = useUsers();
  const queryClient = useQueryClient();

  const assignmentRequests = useCaseGetCaseAssignmentRequests({
    query: {
      refetchInterval: 10000,
      refetchOnMount: true,
    },
  });

  const declineRequestCall = useCaseDenyCaseRequest({
    mutation: {
      onSuccess: () => {
        assignmentRequests.refetch();
        queryClient.invalidateQueries(
          getCaseGetCaseAssignmentRequestsQueryKey()
        );
      },
    },
  });

  const approveRequestCall = useCaseApproveCaseRequest({
    mutation: {
      onSuccess: () => {
        assignmentRequests.refetch();
        queryClient.invalidateQueries(
          getCaseGetCaseAssignmentRequestsQueryKey()
        );
      },
    },
  });

  // matches on name and absenceStatus
  const filteredRequests = useMemo(() => {
    return requests.filter((x) => {
      if (search === '') {
        return true;
      }
      return (
        users[x.event.createdByID]?.name
          .toLowerCase()
          .includes(search.toLowerCase()) ||
        caseEventTypes[x.event.caseEventType ?? -1]
          ?.toLowerCase()
          .includes(search.toLowerCase())
      );
    });
  }, [requests, search, users, caseEventTypes]);

  useEffect(() => {
    if (assignmentRequests.data) {
      setRequests((prev) => {
        const newRequests = assignmentRequests.data.map((a) => {
          return {
            ...a,
            selected:
              prev.find((a2) => a2.event.id === a.event.id)?.selected ?? false,
          } as SelectableCaseAssignmentEvent;
        });
        return newRequests;
      });
    }
  }, [assignmentRequests.data]);

  const amountChecked = useMemo(
    () => requests.reduce((prev, curr) => prev + (curr.selected ? 1 : 0), 0),
    [requests]
  );

  const handleBatchUpdate = async (approve: boolean) => {
    setIsBatchUpdating(true);
    let errors = 0;
    // update each selected and shown availability in sequence, in order for the updates to be visible in the table as they run
    await Promise.all(
      filteredRequests
        .filter((a) => a.selected)
        .map(async (a) => {
          // set updating = true
          setRequests((prev) => {
            return prev.map((a2) => {
              if (a2.event.id === a.event.id) {
                return {
                  ...a2,
                  isUpdating: true,
                } as SelectableCaseAssignmentEvent;
              }
              return a2;
            });
          });

          if (approve) {
            await approveRequestCall.mutateAsync({
              params: { eventId: a.event.id },
            });
          } else {
            await declineRequestCall.mutateAsync({
              params: { eventId: a.event.id },
            });
          }

          if (declineRequestCall.error || approveRequestCall.error) {
            errors += 1;
          }
          // update with result
          setRequests((prev) => {
            return prev.map((a2) => {
              if (a2.event.id === a.event.id) {
                return {
                  ...a2,
                  isUpdating: false,
                } as SelectableCaseAssignmentEvent;
              }
              return a2;
            });
          });
        })
    );
    setIsBatchUpdating(false);
    if (errors === 0) {
      // reset the now empty search
      setSearch('');
    }
  };

  const handleRowClick = useCallback(
    (a: SelectableCaseAssignmentEvent) => {
      navigate(`/sok/${a.case.caseID}`);
    },
    [navigate]
  );

  const getCaseEventTypeIcon = useCallback((caseEventType: CaseEventType) => {
    switch (caseEventType) {
      case CaseEventType.TaxiRequest:
        return faTaxi;
      case CaseEventType.BringVehicleHomeRequest:
        return faHome;
      case CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy:
        return faClock;
      default:
        return faQuestion;
    }
  }, []);

  const getFieldTester = useCallback(
    (data: string) => {
      try {
        const parsedData = JSON.parse(data) as { UserID: number };
        if (parsedData.UserID !== undefined) {
          return (
            <>
              <strong>Fälttestare</strong>
              <br />
              {users[parsedData.UserID]?.name}
            </>
          );
        }
      } catch (error) {
        return null;
      }
      return null;
    },
    [users]
  );

  const preventApproving = useCallback(
    (e: CaseEventType) => {
      return (
        e === CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy &&
        !me?.isAllowedToOverrideAssigneeWarnings
      );
    },
    [me?.isAllowedToOverrideAssigneeWarnings]
  );

  const columnSettings: ColumnSetting<
    SelectableCaseAssignmentEvent,
    ITranslationLookups
  >[] = useMemo(
    () => [
      {
        head: 'Nr',
        render: (a, _props, _focused, rowIndex) => (
          <FullSpan onClick={() => handleRowClick(a)}>{rowIndex + 1}</FullSpan>
        ),
        width: 50,
      },
      {
        head: (
          <Checkbox
            checked={amountChecked === filteredRequests.length}
            onChange={(e) => {
              // update all
              const checked = !!e.currentTarget?.checked;
              setRequests((prev) => {
                return prev.map((a) => {
                  const prevent = preventApproving(a.event.caseEventType); // don't allow to override assignee warnings
                  return {
                    ...a,
                    selected: !prevent ? checked : false,
                  } as SelectableCaseAssignmentEvent;
                });
              });
            }}
          />
        ),
        render: (a) => (
          <Checkbox
            checked={a.selected}
            disabled={
              // disable if not allowed to override assignee warnings and this is a driving policy request
              preventApproving(a.event.caseEventType)
            }
            onChange={(e) => {
              // update selected
              const checked = !!e.currentTarget?.checked;
              setRequests((prev) => {
                return prev.map((a2) => {
                  if (a2.event.id === a.event.id) {
                    return {
                      ...a2,
                      selected: checked,
                    } as SelectableCaseAssignmentEvent;
                  }
                  return a2;
                });
              });
            }}
          />
        ),
        width: 40,
      },
      {
        head: 'Namn',
        render: (a) => (
          <FullSpan onClick={() => handleRowClick(a)}>
            {users[a.event.createdByID]?.name}
          </FullSpan>
        ),
        width: 150,
        sortFunction: (a, b) =>
          users[a.event.createdByID]?.name > users[b.event.createdByID]?.name
            ? 1
            : -1,
      },
      {
        head: 'Inkommet',
        render: (a) => (
          <FullSpan onClick={() => handleRowClick(a)}>
            {formatDateTime(a.event.created)}
          </FullSpan>
        ),
        width: 120,
        sortFunction: (a, b) => (a.event.created! > b.event.created! ? 1 : -1),
      },

      {
        head: 'Uppdrag',
        render: (model) => (
          <WrappingCell>
            {`${model.case?.registrationNumber} (${
              caseTypes[model.case?.caseTypeID]
            })`}
            <br />
            {`${model.assignment?.fromAddress} – ${model.assignment?.toAddress}`}
          </WrappingCell>
        ),
        width: 200,
      },
      {
        head: 'Anledning',
        render: (a) => (
          <Horizontal onClick={() => handleRowClick(a)}>
            <FontAwesomeIcon
              color={theme.colors.foreground.tint}
              icon={getCaseEventTypeIcon(a.event.caseEventType!)}
            />
            <FullSpan>{caseEventTypes[a.event.caseEventType]}</FullSpan>
          </Horizontal>
        ),
        width: 160,
        sortFunction: (a, b) =>
          caseEventTypes[a.event.caseEventType]!.localeCompare(
            caseEventTypes[b.event.caseEventType]!
          ),
      },
      {
        head: 'Meddelande',
        render: (a) => (
          <WrappingCell onClick={() => handleRowClick(a)}>
            {a.event.message}
          </WrappingCell>
        ),
        width: 350,
      },
      {
        head: 'Övrigt',
        render: (a) => (
          <WrappingCell onClick={() => handleRowClick(a)}>
            {a.event.caseEventType === CaseEventType.BringVehicleHomeRequest &&
              a.event.data && (
                <>
                  <strong>Adress</strong>
                  <br />
                  {formatAddress(JSON.parse(a.event.data) as IAddress)}
                </>
              )}
            {a.event.caseEventType ===
              CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy &&
              a.event.data &&
              getFieldTester(a.event.data)}
          </WrappingCell>
        ),
        width: 350,
      },
      // {
      //   head: 'Status',
      //   render: (a) => (
      //     <WrappingCell onClick={() => handleRowClick(a)}>
      //       {a.event.approvalStatus !== undefined
      //         ? approvalStatuses[a.event.approvalStatus]
      //         : '–'}
      //     </WrappingCell>
      //   ),
      //   sortFunction: (a, b) =>
      //     approvalStatuses[a.event.approvalStatus!]?.localeCompare(
      //       approvalStatuses[b.event.approvalStatus!] ?? ''
      //     ) ?? 0,
      //   width: 350,
      // },
    ],
    [
      amountChecked,
      caseEventTypes,
      caseTypes,
      filteredRequests.length,
      getCaseEventTypeIcon,
      getFieldTester,
      handleRowClick,
      preventApproving,
      theme.colors.foreground.tint,
      users,
    ]
  );

  const renderContent = useCallback(() => {
    if (approveRequestCall.isLoading || declineRequestCall.isLoading) {
      return <LoadingSpinner />;
    }

    const rows = filteredRequests;
    return (
      <MyTable
        columnSettings={columnSettings}
        rows={rows}
        useColumnWidthAsFlexAmount
      />
    );
  }, [
    approveRequestCall.isLoading,
    columnSettings,
    declineRequestCall.isLoading,
    filteredRequests,
  ]);

  return (
    <Suspense fallback="Laddar...">
      <Container>
        <Actions>
          <Button
            disabled={
              isBatchUpdating ||
              assignmentRequests.isLoading ||
              amountChecked === 0
            }
            icon={<FontAwesomeIcon icon={faTimes} />}
            onClick={() => {
              handleBatchUpdate(false);
            }}
          >
            Avslå
          </Button>
          <Button
            disabled={
              isBatchUpdating ||
              assignmentRequests.isLoading ||
              amountChecked === 0
            }
            icon={<FontAwesomeIcon icon={faCheck} />}
            onClick={() => {
              handleBatchUpdate(true);
            }}
          >
            Bevilja
          </Button>
          <Input
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Filtrera på namn eller anledning"
            value={search}
            width={230}
          />
          {assignmentRequests.isLoading && (
            <FontAwesomeIcon icon={faSpinner} spin />
          )}
        </Actions>
        {renderContent()}
      </Container>
    </Suspense>
  );
};

export default CaseAssignmentRequests;
