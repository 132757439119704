/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AssignmentRequestFilterModel,
  AssignmentRequestModel,
  AssignmentRequestModelBody,
  AssignmentRequestsAddOrUpdateCommentParams,
  AssignmentRequestsCountDto,
  AssignmentRequestsDeleteAssignmentRequestParams,
  AssignmentRequestsDeleteCommentParams,
  AssignmentRequestsDenyAssignmentRequestParams,
  AssignmentRequestsGetAssignmentRequestParams,
  AssignmentRequestsGetClosestCompanyParams,
  AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Five,
  AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Four,
  AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200One,
  AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Three,
  AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Two,
  AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationParams,
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
  AssignmentRequestsHandOutCarKeysToTransportDriverParams,
  AssignmentRequestsTakeBackCarKeysFromTransportDriverParams,
  AssignmentRequestsUpdateAssignmentRequestParams,
  AssignmentViewModel,
  ExtendedAssignmentRequestModel,
} from '.././model';
import useAssignmentRequestsCreateAssignmentRequestMutator from '.././mutator/custom-instance';
import useAssignmentRequestsGetAssignmentRequestsMutator from '.././mutator/custom-instance';
import useAssignmentRequestsGetAssignmentRequestMutator from '.././mutator/custom-instance';
import useAssignmentRequestsHandOutCarKeysToTransportDriverMutator from '.././mutator/custom-instance';
import useAssignmentRequestsTakeBackCarKeysFromTransportDriverMutator from '.././mutator/custom-instance';
import useAssignmentRequestsDeleteAssignmentRequestMutator from '.././mutator/custom-instance';
import useAssignmentRequestsUpdateAssignmentRequestMutator from '.././mutator/custom-instance';
import useAssignmentRequestsDenyAssignmentRequestMutator from '.././mutator/custom-instance';
import useAssignmentRequestsGetClosestCompanyMutator from '.././mutator/custom-instance';
import useAssignmentRequestsGetApplicableAssignmentsMutator from '.././mutator/custom-instance';
import useAssignmentRequestsGetMyActiveAssignmentRequestsMutator from '.././mutator/custom-instance';
import useAssignmentRequestsGetCountsMutator from '.././mutator/custom-instance';
import useAssignmentRequestsAddOrUpdateCommentMutator from '.././mutator/custom-instance';
import useAssignmentRequestsDeleteCommentMutator from '.././mutator/custom-instance';
import useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationMutator from '.././mutator/custom-instance';

export const useAssignmentRequestsCreateAssignmentRequestHook = () => {
  const assignmentRequestsCreateAssignmentRequest =
    useAssignmentRequestsCreateAssignmentRequestMutator<void>();

  return (assignmentRequestModelBody: AssignmentRequestModelBody) => {
    return assignmentRequestsCreateAssignmentRequest({
      url: `/api/AssignmentRequests/CreateAssignmentRequest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestModelBody,
    });
  };
};

export const useAssignmentRequestsCreateAssignmentRequestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsCreateAssignmentRequestHook>
      >
    >,
    TError,
    { data: AssignmentRequestModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsCreateAssignmentRequestHook>
    >
  >,
  TError,
  { data: AssignmentRequestModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsCreateAssignmentRequest =
    useAssignmentRequestsCreateAssignmentRequestHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsCreateAssignmentRequestHook>
      >
    >,
    { data: AssignmentRequestModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentRequestsCreateAssignmentRequest(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsCreateAssignmentRequestMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsCreateAssignmentRequestHook>
      >
    >
  >;
export type AssignmentRequestsCreateAssignmentRequestMutationBody =
  AssignmentRequestModelBody;
export type AssignmentRequestsCreateAssignmentRequestMutationError = unknown;

export const useAssignmentRequestsCreateAssignmentRequest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsCreateAssignmentRequestHook>
      >
    >,
    TError,
    { data: AssignmentRequestModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsCreateAssignmentRequestHook>
    >
  >,
  TError,
  { data: AssignmentRequestModelBody },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsCreateAssignmentRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsGetAssignmentRequestsHook = () => {
  const assignmentRequestsGetAssignmentRequests =
    useAssignmentRequestsGetAssignmentRequestsMutator<
      AssignmentRequestModel[]
    >();

  return (assignmentRequestFilterModel: AssignmentRequestFilterModel) => {
    return assignmentRequestsGetAssignmentRequests({
      url: `/api/AssignmentRequests/GetAssignmentRequests`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestFilterModel,
    });
  };
};

export const useAssignmentRequestsGetAssignmentRequestsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetAssignmentRequestsHook>
      >
    >,
    TError,
    { data: AssignmentRequestFilterModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsGetAssignmentRequestsHook>
    >
  >,
  TError,
  { data: AssignmentRequestFilterModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsGetAssignmentRequests =
    useAssignmentRequestsGetAssignmentRequestsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetAssignmentRequestsHook>
      >
    >,
    { data: AssignmentRequestFilterModel }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentRequestsGetAssignmentRequests(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsGetAssignmentRequestsMutationResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsGetAssignmentRequestsHook>
    >
  >
>;
export type AssignmentRequestsGetAssignmentRequestsMutationBody =
  AssignmentRequestFilterModel;
export type AssignmentRequestsGetAssignmentRequestsMutationError = unknown;

export const useAssignmentRequestsGetAssignmentRequests = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetAssignmentRequestsHook>
      >
    >,
    TError,
    { data: AssignmentRequestFilterModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsGetAssignmentRequestsHook>
    >
  >,
  TError,
  { data: AssignmentRequestFilterModel },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsGetAssignmentRequestsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsGetAssignmentRequestHook = () => {
  const assignmentRequestsGetAssignmentRequest =
    useAssignmentRequestsGetAssignmentRequestMutator<ExtendedAssignmentRequestModel>();

  return (
    params?: AssignmentRequestsGetAssignmentRequestParams,
    signal?: AbortSignal
  ) => {
    return assignmentRequestsGetAssignmentRequest({
      url: `/api/AssignmentRequests/GetAssignmentRequest`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getAssignmentRequestsGetAssignmentRequestQueryKey = (
  params?: AssignmentRequestsGetAssignmentRequestParams
) => {
  return [
    `/api/AssignmentRequests/GetAssignmentRequest`,
    ...(params ? [params] : []),
  ] as const;
};

export const useAssignmentRequestsGetAssignmentRequestQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetAssignmentRequestHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentRequestsGetAssignmentRequestParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentRequestsGetAssignmentRequestHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentRequestsGetAssignmentRequestQueryKey(params);

  const assignmentRequestsGetAssignmentRequest =
    useAssignmentRequestsGetAssignmentRequestHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetAssignmentRequestHook>
      >
    >
  > = ({ signal }) => assignmentRequestsGetAssignmentRequest(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetAssignmentRequestHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentRequestsGetAssignmentRequestQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetAssignmentRequestHook>>
  >
>;
export type AssignmentRequestsGetAssignmentRequestQueryError = unknown;

export const useAssignmentRequestsGetAssignmentRequest = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetAssignmentRequestHook>>
  >,
  TError = unknown,
>(
  params?: AssignmentRequestsGetAssignmentRequestParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useAssignmentRequestsGetAssignmentRequestHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentRequestsGetAssignmentRequestQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentRequestsHandOutCarKeysToTransportDriverHook = () => {
  const assignmentRequestsHandOutCarKeysToTransportDriver =
    useAssignmentRequestsHandOutCarKeysToTransportDriverMutator<boolean>();

  return (
    assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
    params?: AssignmentRequestsHandOutCarKeysToTransportDriverParams
  ) => {
    return assignmentRequestsHandOutCarKeysToTransportDriver({
      url: `/api/AssignmentRequests/HandOutCarKeysToTransportDriver`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
      params,
    });
  };
};

export const useAssignmentRequestsHandOutCarKeysToTransportDriverMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentRequestsHandOutCarKeysToTransportDriverHook
          >
        >
      >,
      TError,
      {
        data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
        params?: AssignmentRequestsHandOutCarKeysToTransportDriverParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsHandOutCarKeysToTransportDriverHook
        >
      >
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
      params?: AssignmentRequestsHandOutCarKeysToTransportDriverParams;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentRequestsHandOutCarKeysToTransportDriver =
      useAssignmentRequestsHandOutCarKeysToTransportDriverHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentRequestsHandOutCarKeysToTransportDriverHook
          >
        >
      >,
      {
        data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
        params?: AssignmentRequestsHandOutCarKeysToTransportDriverParams;
      }
    > = (props) => {
      const { data, params } = props ?? {};

      return assignmentRequestsHandOutCarKeysToTransportDriver(data, params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentRequestsHandOutCarKeysToTransportDriverMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsHandOutCarKeysToTransportDriverHook
        >
      >
    >
  >;
export type AssignmentRequestsHandOutCarKeysToTransportDriverMutationBody =
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
export type AssignmentRequestsHandOutCarKeysToTransportDriverMutationError =
  unknown;

export const useAssignmentRequestsHandOutCarKeysToTransportDriver = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsHandOutCarKeysToTransportDriverHook
        >
      >
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
      params?: AssignmentRequestsHandOutCarKeysToTransportDriverParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentRequestsHandOutCarKeysToTransportDriverHook
      >
    >
  >,
  TError,
  {
    data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    params?: AssignmentRequestsHandOutCarKeysToTransportDriverParams;
  },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsHandOutCarKeysToTransportDriverMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook =
  () => {
    const assignmentRequestsTakeBackCarKeysFromTransportDriver =
      useAssignmentRequestsTakeBackCarKeysFromTransportDriverMutator<boolean>();

    return (
      assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
      params?: AssignmentRequestsTakeBackCarKeysFromTransportDriverParams
    ) => {
      return assignmentRequestsTakeBackCarKeysFromTransportDriver({
        url: `/api/AssignmentRequests/TakeBackCarKeysFromTransportDriver`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: assignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody,
        params,
      });
    };
  };

export const useAssignmentRequestsTakeBackCarKeysFromTransportDriverMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook
          >
        >
      >,
      TError,
      {
        data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
        params?: AssignmentRequestsTakeBackCarKeysFromTransportDriverParams;
      },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook
        >
      >
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
      params?: AssignmentRequestsTakeBackCarKeysFromTransportDriverParams;
    },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const assignmentRequestsTakeBackCarKeysFromTransportDriver =
      useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook
          >
        >
      >,
      {
        data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
        params?: AssignmentRequestsTakeBackCarKeysFromTransportDriverParams;
      }
    > = (props) => {
      const { data, params } = props ?? {};

      return assignmentRequestsTakeBackCarKeysFromTransportDriver(data, params);
    };

    return { mutationFn, ...mutationOptions };
  };

export type AssignmentRequestsTakeBackCarKeysFromTransportDriverMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook
        >
      >
    >
  >;
export type AssignmentRequestsTakeBackCarKeysFromTransportDriverMutationBody =
  AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
export type AssignmentRequestsTakeBackCarKeysFromTransportDriverMutationError =
  unknown;

export const useAssignmentRequestsTakeBackCarKeysFromTransportDriver = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook
        >
      >
    >,
    TError,
    {
      data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
      params?: AssignmentRequestsTakeBackCarKeysFromTransportDriverParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useAssignmentRequestsTakeBackCarKeysFromTransportDriverHook
      >
    >
  >,
  TError,
  {
    data: AssignmentRequestsHandOutCarKeysToTransportDriverAssignmentidsBody;
    params?: AssignmentRequestsTakeBackCarKeysFromTransportDriverParams;
  },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsTakeBackCarKeysFromTransportDriverMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsDeleteAssignmentRequestHook = () => {
  const assignmentRequestsDeleteAssignmentRequest =
    useAssignmentRequestsDeleteAssignmentRequestMutator<void>();

  return (params?: AssignmentRequestsDeleteAssignmentRequestParams) => {
    return assignmentRequestsDeleteAssignmentRequest({
      url: `/api/AssignmentRequests/DeleteAssignmentRequest`,
      method: 'DELETE',
      params,
    });
  };
};

export const useAssignmentRequestsDeleteAssignmentRequestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsDeleteAssignmentRequestHook>
      >
    >,
    TError,
    { params?: AssignmentRequestsDeleteAssignmentRequestParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsDeleteAssignmentRequestHook>
    >
  >,
  TError,
  { params?: AssignmentRequestsDeleteAssignmentRequestParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsDeleteAssignmentRequest =
    useAssignmentRequestsDeleteAssignmentRequestHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsDeleteAssignmentRequestHook>
      >
    >,
    { params?: AssignmentRequestsDeleteAssignmentRequestParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentRequestsDeleteAssignmentRequest(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsDeleteAssignmentRequestMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsDeleteAssignmentRequestHook>
      >
    >
  >;

export type AssignmentRequestsDeleteAssignmentRequestMutationError = unknown;

export const useAssignmentRequestsDeleteAssignmentRequest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsDeleteAssignmentRequestHook>
      >
    >,
    TError,
    { params?: AssignmentRequestsDeleteAssignmentRequestParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsDeleteAssignmentRequestHook>
    >
  >,
  TError,
  { params?: AssignmentRequestsDeleteAssignmentRequestParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsDeleteAssignmentRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsUpdateAssignmentRequestHook = () => {
  const assignmentRequestsUpdateAssignmentRequest =
    useAssignmentRequestsUpdateAssignmentRequestMutator<void>();

  return (
    assignmentRequestModelBody: AssignmentRequestModelBody,
    params?: AssignmentRequestsUpdateAssignmentRequestParams
  ) => {
    return assignmentRequestsUpdateAssignmentRequest({
      url: `/api/AssignmentRequests/UpdateAssignmentRequest`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestModelBody,
      params,
    });
  };
};

export const useAssignmentRequestsUpdateAssignmentRequestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsUpdateAssignmentRequestHook>
      >
    >,
    TError,
    {
      data: AssignmentRequestModelBody;
      params?: AssignmentRequestsUpdateAssignmentRequestParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsUpdateAssignmentRequestHook>
    >
  >,
  TError,
  {
    data: AssignmentRequestModelBody;
    params?: AssignmentRequestsUpdateAssignmentRequestParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsUpdateAssignmentRequest =
    useAssignmentRequestsUpdateAssignmentRequestHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsUpdateAssignmentRequestHook>
      >
    >,
    {
      data: AssignmentRequestModelBody;
      params?: AssignmentRequestsUpdateAssignmentRequestParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return assignmentRequestsUpdateAssignmentRequest(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsUpdateAssignmentRequestMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsUpdateAssignmentRequestHook>
      >
    >
  >;
export type AssignmentRequestsUpdateAssignmentRequestMutationBody =
  AssignmentRequestModelBody;
export type AssignmentRequestsUpdateAssignmentRequestMutationError = unknown;

export const useAssignmentRequestsUpdateAssignmentRequest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsUpdateAssignmentRequestHook>
      >
    >,
    TError,
    {
      data: AssignmentRequestModelBody;
      params?: AssignmentRequestsUpdateAssignmentRequestParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsUpdateAssignmentRequestHook>
    >
  >,
  TError,
  {
    data: AssignmentRequestModelBody;
    params?: AssignmentRequestsUpdateAssignmentRequestParams;
  },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsUpdateAssignmentRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsDenyAssignmentRequestHook = () => {
  const assignmentRequestsDenyAssignmentRequest =
    useAssignmentRequestsDenyAssignmentRequestMutator<void>();

  return (params?: AssignmentRequestsDenyAssignmentRequestParams) => {
    return assignmentRequestsDenyAssignmentRequest({
      url: `/api/AssignmentRequests/DenyAssignmentRequest`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentRequestsDenyAssignmentRequestMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsDenyAssignmentRequestHook>
      >
    >,
    TError,
    { params?: AssignmentRequestsDenyAssignmentRequestParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsDenyAssignmentRequestHook>
    >
  >,
  TError,
  { params?: AssignmentRequestsDenyAssignmentRequestParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsDenyAssignmentRequest =
    useAssignmentRequestsDenyAssignmentRequestHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsDenyAssignmentRequestHook>
      >
    >,
    { params?: AssignmentRequestsDenyAssignmentRequestParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentRequestsDenyAssignmentRequest(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsDenyAssignmentRequestMutationResult = NonNullable<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsDenyAssignmentRequestHook>
    >
  >
>;

export type AssignmentRequestsDenyAssignmentRequestMutationError = unknown;

export const useAssignmentRequestsDenyAssignmentRequest = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsDenyAssignmentRequestHook>
      >
    >,
    TError,
    { params?: AssignmentRequestsDenyAssignmentRequestParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsDenyAssignmentRequestHook>
    >
  >,
  TError,
  { params?: AssignmentRequestsDenyAssignmentRequestParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsDenyAssignmentRequestMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsGetClosestCompanyHook = () => {
  const assignmentRequestsGetClosestCompany =
    useAssignmentRequestsGetClosestCompanyMutator<number>();

  return (params?: AssignmentRequestsGetClosestCompanyParams) => {
    return assignmentRequestsGetClosestCompany({
      url: `/api/AssignmentRequests/GetClosestCompany`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentRequestsGetClosestCompanyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsGetClosestCompanyHook>>
    >,
    TError,
    { params?: AssignmentRequestsGetClosestCompanyParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetClosestCompanyHook>>
  >,
  TError,
  { params?: AssignmentRequestsGetClosestCompanyParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsGetClosestCompany =
    useAssignmentRequestsGetClosestCompanyHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsGetClosestCompanyHook>>
    >,
    { params?: AssignmentRequestsGetClosestCompanyParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentRequestsGetClosestCompany(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsGetClosestCompanyMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetClosestCompanyHook>>
  >
>;

export type AssignmentRequestsGetClosestCompanyMutationError = unknown;

export const useAssignmentRequestsGetClosestCompany = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsGetClosestCompanyHook>>
    >,
    TError,
    { params?: AssignmentRequestsGetClosestCompanyParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetClosestCompanyHook>>
  >,
  TError,
  { params?: AssignmentRequestsGetClosestCompanyParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsGetClosestCompanyMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsGetApplicableAssignmentsHook = () => {
  const assignmentRequestsGetApplicableAssignments =
    useAssignmentRequestsGetApplicableAssignmentsMutator<
      AssignmentViewModel[]
    >();

  return (assignmentRequestModelBody: AssignmentRequestModelBody) => {
    return assignmentRequestsGetApplicableAssignments({
      url: `/api/AssignmentRequests/GetApplicableAssignments`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: assignmentRequestModelBody,
    });
  };
};

export const useAssignmentRequestsGetApplicableAssignmentsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetApplicableAssignmentsHook>
      >
    >,
    TError,
    { data: AssignmentRequestModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsGetApplicableAssignmentsHook>
    >
  >,
  TError,
  { data: AssignmentRequestModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsGetApplicableAssignments =
    useAssignmentRequestsGetApplicableAssignmentsHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetApplicableAssignmentsHook>
      >
    >,
    { data: AssignmentRequestModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return assignmentRequestsGetApplicableAssignments(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsGetApplicableAssignmentsMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetApplicableAssignmentsHook>
      >
    >
  >;
export type AssignmentRequestsGetApplicableAssignmentsMutationBody =
  AssignmentRequestModelBody;
export type AssignmentRequestsGetApplicableAssignmentsMutationError = unknown;

export const useAssignmentRequestsGetApplicableAssignments = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useAssignmentRequestsGetApplicableAssignmentsHook>
      >
    >,
    TError,
    { data: AssignmentRequestModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsGetApplicableAssignmentsHook>
    >
  >,
  TError,
  { data: AssignmentRequestModelBody },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsGetApplicableAssignmentsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsGetMyActiveAssignmentRequestsHook = () => {
  const assignmentRequestsGetMyActiveAssignmentRequests =
    useAssignmentRequestsGetMyActiveAssignmentRequestsMutator<
      AssignmentRequestModel[]
    >();

  return (signal?: AbortSignal) => {
    return assignmentRequestsGetMyActiveAssignmentRequests({
      url: `/api/AssignmentRequests/GetMyActiveAssignmentRequests`,
      method: 'GET',
      signal,
    });
  };
};

export const getAssignmentRequestsGetMyActiveAssignmentRequestsQueryKey =
  () => {
    return [`/api/AssignmentRequests/GetMyActiveAssignmentRequests`] as const;
  };

export const useAssignmentRequestsGetMyActiveAssignmentRequestsQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsGetMyActiveAssignmentRequestsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetMyActiveAssignmentRequestsHook
        >
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getAssignmentRequestsGetMyActiveAssignmentRequestsQueryKey();

  const assignmentRequestsGetMyActiveAssignmentRequests =
    useAssignmentRequestsGetMyActiveAssignmentRequestsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetMyActiveAssignmentRequestsHook
        >
      >
    >
  > = ({ signal }) => assignmentRequestsGetMyActiveAssignmentRequests(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetMyActiveAssignmentRequestsHook
        >
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentRequestsGetMyActiveAssignmentRequestsQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetMyActiveAssignmentRequestsHook
        >
      >
    >
  >;
export type AssignmentRequestsGetMyActiveAssignmentRequestsQueryError = unknown;

export const useAssignmentRequestsGetMyActiveAssignmentRequests = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useAssignmentRequestsGetMyActiveAssignmentRequestsHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetMyActiveAssignmentRequestsHook
        >
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useAssignmentRequestsGetMyActiveAssignmentRequestsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentRequestsGetCountsHook = () => {
  const assignmentRequestsGetCounts =
    useAssignmentRequestsGetCountsMutator<AssignmentRequestsCountDto>();

  return (signal?: AbortSignal) => {
    return assignmentRequestsGetCounts({
      url: `/api/AssignmentRequests/GetCounts`,
      method: 'GET',
      signal,
    });
  };
};

export const getAssignmentRequestsGetCountsQueryKey = () => {
  return [`/api/AssignmentRequests/GetCounts`] as const;
};

export const useAssignmentRequestsGetCountsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetCountsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentRequestsGetCountsHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getAssignmentRequestsGetCountsQueryKey();

  const assignmentRequestsGetCounts = useAssignmentRequestsGetCountsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useAssignmentRequestsGetCountsHook>>>
  > = ({ signal }) => assignmentRequestsGetCounts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentRequestsGetCountsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type AssignmentRequestsGetCountsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentRequestsGetCountsHook>>>
>;
export type AssignmentRequestsGetCountsQueryError = unknown;

export const useAssignmentRequestsGetCounts = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsGetCountsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useAssignmentRequestsGetCountsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useAssignmentRequestsGetCountsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useAssignmentRequestsAddOrUpdateCommentHook = () => {
  const assignmentRequestsAddOrUpdateComment =
    useAssignmentRequestsAddOrUpdateCommentMutator<void>();

  return (params?: AssignmentRequestsAddOrUpdateCommentParams) => {
    return assignmentRequestsAddOrUpdateComment({
      url: `/api/AssignmentRequests/AddOrUpdateComment`,
      method: 'POST',
      params,
    });
  };
};

export const useAssignmentRequestsAddOrUpdateCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsAddOrUpdateCommentHook>>
    >,
    TError,
    { params?: AssignmentRequestsAddOrUpdateCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsAddOrUpdateCommentHook>>
  >,
  TError,
  { params?: AssignmentRequestsAddOrUpdateCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsAddOrUpdateComment =
    useAssignmentRequestsAddOrUpdateCommentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsAddOrUpdateCommentHook>>
    >,
    { params?: AssignmentRequestsAddOrUpdateCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentRequestsAddOrUpdateComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsAddOrUpdateCommentMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsAddOrUpdateCommentHook>>
  >
>;

export type AssignmentRequestsAddOrUpdateCommentMutationError = unknown;

export const useAssignmentRequestsAddOrUpdateComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsAddOrUpdateCommentHook>>
    >,
    TError,
    { params?: AssignmentRequestsAddOrUpdateCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsAddOrUpdateCommentHook>>
  >,
  TError,
  { params?: AssignmentRequestsAddOrUpdateCommentParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsAddOrUpdateCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsDeleteCommentHook = () => {
  const assignmentRequestsDeleteComment =
    useAssignmentRequestsDeleteCommentMutator<void>();

  return (params?: AssignmentRequestsDeleteCommentParams) => {
    return assignmentRequestsDeleteComment({
      url: `/api/AssignmentRequests/DeleteComment`,
      method: 'DELETE',
      params,
    });
  };
};

export const useAssignmentRequestsDeleteCommentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsDeleteCommentHook>>
    >,
    TError,
    { params?: AssignmentRequestsDeleteCommentParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsDeleteCommentHook>>
  >,
  TError,
  { params?: AssignmentRequestsDeleteCommentParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const assignmentRequestsDeleteComment =
    useAssignmentRequestsDeleteCommentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsDeleteCommentHook>>
    >,
    { params?: AssignmentRequestsDeleteCommentParams }
  > = (props) => {
    const { params } = props ?? {};

    return assignmentRequestsDeleteComment(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type AssignmentRequestsDeleteCommentMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useAssignmentRequestsDeleteCommentHook>>>
>;

export type AssignmentRequestsDeleteCommentMutationError = unknown;

export const useAssignmentRequestsDeleteComment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useAssignmentRequestsDeleteCommentHook>>
    >,
    TError,
    { params?: AssignmentRequestsDeleteCommentParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useAssignmentRequestsDeleteCommentHook>>
  >,
  TError,
  { params?: AssignmentRequestsDeleteCommentParams },
  TContext
> => {
  const mutationOptions =
    useAssignmentRequestsDeleteCommentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook =
  () => {
    const assignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation =
      useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationMutator<
        | AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200One
        | AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Two
        | AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Three
        | AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Four
        | AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation200Five
      >();

    return (
      params?: AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationParams,
      signal?: AbortSignal
    ) => {
      return assignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation(
        {
          url: `/api/AssignmentRequests/GetNumberOfAvailableAssignmentsPerToCompanyLocation`,
          method: 'GET',
          params,
          signal,
        }
      );
    };
  };

export const getAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationQueryKey =
  (
    params?: AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationParams
  ) => {
    return [
      `/api/AssignmentRequests/GetNumberOfAvailableAssignmentsPerToCompanyLocation`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationQueryKey(
        params
      );

    const assignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation =
      useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook
          >
        >
      >
    > = ({ signal }) =>
      assignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation(
        params,
        signal
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook
        >
      >
    >
  >;
export type AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationQueryError =
  unknown;

export const useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocation =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: AssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
    const queryOptions =
      useAssignmentRequestsGetNumberOfAvailableAssignmentsPerToCompanyLocationQueryOptions(
        params,
        options
      );

    const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
      queryKey: QueryKey;
    };

    query.queryKey = queryOptions.queryKey;

    return query;
  };
