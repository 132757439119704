/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssigneeWarning =
  (typeof AssigneeWarning)[keyof typeof AssigneeWarning];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssigneeWarning = {
  None: 0,
  NotConsecutiveHoursOfRestLast24Hours: 1,
  NotConsecutiveHoursOfRestLast7Days: 2,
  NotWithinAllowedDrivingHours: 3,
  CannotDriveManual: 4,
  MissingDriverLicenseC: 5,
  MissingDriverLicenseBE: 6,
  IsFullyBooked: 7,
  IsNotAvailable: 8,
  Overlapping: 9,
  IsOnlyAvailableForFurtherTransport: 10,
  NotConsecutiveHoursOfRestThisWeek: 11,
} as const;
