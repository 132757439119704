/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable import/no-cycle */
import { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { useLocalStorage } from 'usehooks-ts';
import { useAvailabilityRangeOptions } from './useAvailabilityRange';
import {
  useUserGetEmployeeAvailabilities,
  useUserGetEmployeeAvailabilitiesForDates,
} from 'api/user/user';
import { getStartAndEndOfWeek } from 'utils/date-helpers';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;
`;

const Box = styled.div`
  border: 1px solid black;
  border-radius: 3px;
  height: 22px;
  margin: 2px;
  & > div {
    position: relative;
    height: 20px;
    display: flex;
    flex: 1;
  }
`;

export const Badge = styled.div<{ background?: string }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 14px;
  height: 14px;
  border-radius: 2px;
  border-width: 0.5px;
  border-style: solid;
  border-color: ${({ theme }) => theme.colors.foreground.primary};
  background-color: ${({ theme, background }) =>
    background || theme.colors.background.button};
  color: ${({ theme }) => theme.colors.foreground.button};
  text-align: center;
  padding: 1px;
  font-size: 0.7em;
`;

const WeeklyAvailability = styled.div`
  display: flex;
  gap: 10px;

  margin-left: auto;
`;

const WeeklyAvailabilityDay = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  cursor: default;
  min-width: 40px;
  align-items: center;
`;

const WeekDayBox = styled(Box)<{
  colorCode?: string;
}>`
  border: none;
  background-color: ${({ colorCode }) => colorCode || 'transparent'};
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
  justify-content: center;
`;

const now = new Date();
now.setHours(0, 0, 0, 0);

const MobileAvail = () => {
  const { currentRange: currentRangeOption } = useAvailabilityRangeOptions();
  const [dateRange, setDateRange] = useState<[Date, Date] | undefined>(
    currentRangeOption?.getDefaultRange
  );

  const theme = useTheme();

  const [showOnlyAvailable, setShowOnlyAvailable] = useLocalStorage<boolean>(
    'availability.showOnlyAvailable',
    false
  );
  useEffect(() => {
    setShowOnlyAvailable(!!currentRangeOption?.showShowOnlyAvailableOption);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentRangeOption]);
  const [search, setSearch] = useState<string>('');

  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);

  const employeeAvailabilityCall = useUserGetEmployeeAvailabilities();
  const weeklyAvailabilitiesCall = useUserGetEmployeeAvailabilitiesForDates();
  const weeklyAvailabilitiesCallTimeout = useRef<NodeJS.Timeout | null>(null);

  const currentWeekNumber = useMemo(() => {
    const currentDate = dateRange?.[0] ?? now;
    const onejan = new Date(now.getFullYear(), 0, 1);
    return Math.ceil(
      ((currentDate.getTime() - onejan.getTime()) / 86400000 +
        onejan.getDay() +
        1) /
        7
    );
  }, [dateRange]);

  const weekdays = ['Mån', 'Tis', 'Ons', 'Tors', 'Fre', 'Lör', 'Sön'];

  // Only fetch once
  useEffect(() => {
    const fetchWeeklyAvailabilities = async () => {
      if (weeklyAvailabilitiesCallTimeout.current) {
        clearTimeout(weeklyAvailabilitiesCallTimeout.current);
      }

      weeklyAvailabilitiesCallTimeout.current = setTimeout(() => {
        const [startOfWeek, endOfWeek] = getStartAndEndOfWeek(now);
        weeklyAvailabilitiesCall.mutateAsync({
          params: {
            start: startOfWeek,
            end: endOfWeek,
          },
        });
      }, 500);
    };
    fetchWeeklyAvailabilities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentWeekNumber]);

  const getWeelyAmountStatusColor = (day: number, amount: number) => {
    switch (day) {
      case 0: // Monday
        if (amount <= 140) {
          return theme.colors.foreground.error;
        }
        if (amount <= 169) {
          return theme.colors.foreground.warning;
        }
        if (amount >= 170) {
          return theme.colors.foreground.good;
        }
        break;

      case 1: // Tuesday
      case 2: // Wednesday
      case 3: // Thursday
      case 4: // Friday
        if (amount <= 160) {
          return theme.colors.foreground.error;
        }
        if (amount <= 209) {
          return theme.colors.foreground.warning;
        }
        if (amount >= 210) {
          return theme.colors.foreground.good;
        }
        break;

      case 5: // Saturday
      case 6: // Sunday
        if (amount <= 120) {
          return theme.colors.foreground.error;
        }
        if (amount <= 139) {
          return theme.colors.foreground.warning;
        }
        if (amount >= 140) {
          return theme.colors.foreground.good;
        }
        break;

      default:
        return '';
    }
    return '';
  };

  return (
    <Suspense fallback="Laddar...">
      <Container>
        <Actions>
          <WeeklyAvailability>
            {weeklyAvailabilitiesCall.isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
            {weeklyAvailabilitiesCall.data &&
              weeklyAvailabilitiesCall.data.availableEmployees.map(
                (amountAvailable, index) => (
                  <WeeklyAvailabilityDay
                    key={index}
                    title={`Antal tillgängliga innevarande vecka på ${weekdays[index]}dag.`}
                  >
                    <div>{weekdays[index]}</div>
                    <WeekDayBox
                      colorCode={getWeelyAmountStatusColor(
                        index,
                        amountAvailable
                      )}
                    >
                      {amountAvailable}
                    </WeekDayBox>
                  </WeeklyAvailabilityDay>
                )
              )}
          </WeeklyAvailability>
        </Actions>
      </Container>
    </Suspense>
  );
};

export default MobileAvail;
