import { EventModel } from 'api';
import React, { FC } from 'react';
import { EventSection, Message } from './components';
import DefaultEventHeader from './DefaultEventHeader';

interface Props {
  event: EventModel;
  title: string;
  icon?: React.ReactNode;
}

const TitledEvent: FC<Props> = ({ event, title, icon }) => {
  return (
    <EventSection>
      <DefaultEventHeader
        position={event.position}
        created={event.created}
        headerText={
          <>
            {icon} {title}
          </>
        }
        modified={event.modified}
        userName={event.modifiedBy}
      />

      <Message>{event.message}</Message>
    </EventSection>
  );
};

export default TitledEvent;
