import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { UserModel } from 'api';
import { ExternalReceiptProvider } from 'api/model';
import {
  usePaymentCardAddCardOwner,
  usePaymentCardGetUserCards,
  usePaymentCardRemoveCardOwner,
} from 'api/payment-card/payment-card';
import SectionHeader from 'components/SectionHeader';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SearchableSelect from 'components/inputs/SearchableSelect';
import TextButton from 'components/inputs/TextButton';
import Roles from 'constants/Roles';
import useMe from 'contexts/authentication/useMe';
import useTranslations from 'contexts/basicData/useTranslations';
import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import styled, { css } from 'styled-components';

const Wrapper = styled.div`
  width: 50%;
`;
const CardRow = styled.div<{ isActiveCard?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${(props) =>
    !props.isActiveCard &&
    css`
      color: ${({ theme }) => theme.colors.border.primary};
      font-style: italic;
    `}
`;

const CardListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  margin-top: 10px;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
`;

interface Props {
  user: UserModel;
}

const UserPaymentCardForm: FC<Props> = ({ user }) => {
  const queryClient = useQueryClient();
  const [showForm, setShowForm] = useState(false);
  const { handleSubmit, register, formState, reset } = useForm({
    defaultValues: {
      cardNumber: '',
      expirationDate: '',
      externalReceiptProvider: ExternalReceiptProvider.CircleK,
    },
  });
  const translations = useTranslations();
  const me = useMe();
  const isCircleKRole = me?.roles.includes(Roles.CircleK);

  const cards = usePaymentCardGetUserCards({
    cardOwnerId: user.userID,
  });

  const addUserCardMutation = usePaymentCardAddCardOwner({
    mutation: {
      onSuccess: async () => {
        await queryClient.invalidateQueries(cards.queryKey);
        await cards.refetch();
        setShowForm(false);
      },
      onError: () => {
        // console.log('Error adding card.');
      },
    },
  });

  const removeUserCardMutation = usePaymentCardRemoveCardOwner({
    mutation: {
      onSuccess: () => {
        cards.refetch();
      },
      onError: () => {
        // console.log('Error removing card.');
      },
    },
  });

  const onSubmit = async (data: any) => {
    try {
      await addUserCardMutation.mutateAsync({
        data: {
          cardOwnerUserId: user.userID,
          cardNumber: data.cardNumber,
          cardExpiration: data.expirationDate,
          provider: ExternalReceiptProvider.CircleK,
        },
      });

      reset();
    } catch (error) {
      // console.log('Error adding card.', error);
    }
  };

  return (
    <Wrapper>
      <SectionHeader>Betalkort</SectionHeader>
      {cards.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      {cards.data && (
        <CardListWrapper>
          {cards.data
            .sort((a, _b) => (a.isActive ? -1 : 1))
            .map((card) => (
              <CardRow key={card.cardNumber} isActiveCard={card.isActive}>
                <p>
                  {card.cardNumber} ({card.cardExpiration.substring(0, 2)}/
                  {card.cardExpiration.substring(2, 4)}) -{' '}
                  {translations.externalReceiptProviders[card.provider]}
                  {` ${!card.isActive ? ' (Inaktivt kort)' : ''}`}
                </p>
                {isCircleKRole && (
                  <TextButton
                    disabled={
                      addUserCardMutation.isLoading ||
                      removeUserCardMutation.isLoading
                    }
                    onClick={() => {
                      removeUserCardMutation.mutate({
                        data: {
                          cardOwnerUserId: user.userID,
                          cardNumber: card.cardNumber,
                        },
                      });
                    }}
                  >
                    Ta bort
                  </TextButton>
                )}
              </CardRow>
            ))}
        </CardListWrapper>
      )}
      {!showForm && isCircleKRole && (
        <Button onClick={() => setShowForm(!showForm)}>
          + Lägg till betalkort
        </Button>
      )}
      {addUserCardMutation.isLoading && <p>Lägger till kort...</p>}
      {showForm && !addUserCardMutation.isLoading && (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputWrapper>
            <LabelWrap
              label="Kortnummer"
              errorLabel={
                formState.errors.cardNumber &&
                formState.errors.cardNumber?.message?.toString()
              }
            >
              <Input
                {...register('cardNumber', {
                  required: 'Kortnummer är obligatoriskt',
                  pattern: {
                    value: /^[0-9]{17}$/,
                    message:
                      'Inkorrekt input, använd formatet 12345678901234567 (17 siffror)',
                  },
                })}
              />
            </LabelWrap>

            <LabelWrap
              label="Utgångdatum (YYMM)"
              errorLabel={
                formState.errors.expirationDate &&
                formState.errors.expirationDate?.message?.toString()
              }
            >
              <Input
                {...register('expirationDate', {
                  required: false,
                  pattern: {
                    value: /^[0-9]{4}$/,
                    message: 'Inkorrekt input, använd formatet YYMM',
                  },
                })}
              />
            </LabelWrap>

            <LabelWrap label="Samarbetspartner">
              <SearchableSelect
                disabled
                {...register('externalReceiptProvider')}
                options={[
                  { label: 'Ingen', value: ExternalReceiptProvider.None },
                  {
                    label: 'Circle K',
                    value: ExternalReceiptProvider.CircleK,
                    description: 'Circle K',
                  },
                ]}
                onOptionClicked={(_option: ExternalReceiptProvider) => {
                  // console.log(option);
                }}
                selectedValue={ExternalReceiptProvider.CircleK}
                searchFilter={(searchString, opts) => {
                  const lowerNameFilter = searchString.toLowerCase();
                  return opts.filter((opt) =>
                    opt.description
                      ?.toString()
                      .toLowerCase()
                      .includes(lowerNameFilter)
                  );
                }}
              />
            </LabelWrap>
          </InputWrapper>

          <Button
            onClick={() => {
              setShowForm(!showForm);
              reset();
            }}
          >
            Avbryt
          </Button>
          <Button type="submit" style={{ marginLeft: '5px' }}>
            Spara
          </Button>
        </form>
      )}
    </Wrapper>
  );
};

export default UserPaymentCardForm;
