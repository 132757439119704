/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentStatusEnum =
  (typeof AssignmentStatusEnum)[keyof typeof AssignmentStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentStatusEnum = {
  Created: 100,
  Planned: 110,
  Assigned: 200,
  Accepted: 300,
  Started: 400,
  Inspected: 450,
  Arrived: 500,
  TestComplete: 600,
  Returning: 700,
  Complete: 800,
  Approved: 900,
  CanceledNotFinished: 950,
  Canceled: 1000,
  AbortedNotFinished: 1050,
  Aborted: 1100,
  Discarded: 1200,
} as const;
