import { faCar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseModel, ITranslationLookups } from 'api';
import { useCurrentRouteCaseRoute } from 'hooks/useCurrentRouteCaseType';
import { FC } from 'react';
import { generatePath, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { formatDateTime } from 'utils/date-helpers';

const Link = styled(NavLink)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0;
  padding: 10px 5px;

  border: none;
  background: transparent;
  font: inherit;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.foreground.tint};

  b {
    font-weight: 600;
  }

  svg {
    font-size: 0.8em;
    margin-bottom: 0.2em;
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }
`;

const Assignment = styled.span`
  margin-left: 5px;
`;

interface Props {
  linkedCase: CaseModel;
  translations: ITranslationLookups;
}

const LinkedCase: FC<Props> = ({ linkedCase, translations }) => {
  const currentRouteCaseRoute = useCurrentRouteCaseRoute();

  const slicedAssignmentList = linkedCase.assignmentList.slice(0, 4);

  return (
    <Link
      to={generatePath(currentRouteCaseRoute, {
        id: String(linkedCase.caseID),
      })}
    >
      <span>
        <b>{linkedCase.registrationNumber}</b> -{' '}
        {translations.caseTypes[linkedCase.caseTypeID]}
      </span>

      <span>
        {slicedAssignmentList.map((a) => (
          <Assignment key={a.assignmentID}>
            <FontAwesomeIcon icon={faCar} /> {formatDateTime(a.bookedTo)}{' '}
          </Assignment>
        ))}

        {slicedAssignmentList.length < linkedCase.assignmentList.length &&
          '...'}
      </span>
    </Link>
  );
};

export default LinkedCase;
