import React, { PropsWithChildren } from 'react';
import Modal from '.';
import styled from 'styled-components';
import { arraySpreadIf } from 'utils/spreading';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

interface Props extends PropsWithChildren {
  title?: string;
  content?: string | React.ReactNode;
  disabled?: boolean;
  confirmText?: string;
  onConfirm(): void;
  cancelText?: string;
  onCancel?(): void;
}

const ConfirmModal: React.FC<Props> = ({
  title,
  content,
  disabled,
  onConfirm,
  onCancel,
  confirmText,
  cancelText,
  children,
}) => {
  return (
    <Modal
      buttons={[
        {
          label: confirmText ?? 'Bekräfta',
          disabled,
          onClick: onConfirm,
        },
        ...arraySpreadIf(!!onCancel, {
          label: cancelText ?? 'Avbryt',
          disabled,
          onClick: onCancel,
        }),
      ]}
      closeOnOutsideClick={false}
      onClose={onCancel}
      title={title}
    >
      <Wrapper>
        {content}
        {children}
      </Wrapper>
    </Modal>
  );
};

export default ConfirmModal;
