/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  FindPaymentCardsRequest,
  PaymentCardGetPaymentCardParams,
  PaymentCardGetUserCardsParams,
  PaymentCardModel,
  PaymentCardModelBody,
  UserCardDTO,
  UserCardMappingDTO,
} from '.././model';
import usePaymentCardGetPaymentCardsMutator from '.././mutator/custom-instance';
import usePaymentCardFindPaymentCardsMutator from '.././mutator/custom-instance';
import usePaymentCardGetPaymentCardMutator from '.././mutator/custom-instance';
import usePaymentCardCreatePaymentCardMutator from '.././mutator/custom-instance';
import usePaymentCardUpdatePaymentCardMutator from '.././mutator/custom-instance';
import usePaymentCardInactivatePaymentCardMutator from '.././mutator/custom-instance';
import usePaymentCardAddCardOwnerMutator from '.././mutator/custom-instance';
import usePaymentCardRemoveCardOwnerMutator from '.././mutator/custom-instance';
import usePaymentCardGetUserCardsMutator from '.././mutator/custom-instance';
import usePaymentCardUploadCardMappingMutator from '.././mutator/custom-instance';

export const usePaymentCardGetPaymentCardsHook = () => {
  const paymentCardGetPaymentCards =
    usePaymentCardGetPaymentCardsMutator<PaymentCardModel[]>();

  return (signal?: AbortSignal) => {
    return paymentCardGetPaymentCards({
      url: `/api/PaymentCard/GetPaymentCards`,
      method: 'GET',
      signal,
    });
  };
};

export const getPaymentCardGetPaymentCardsQueryKey = () => {
  return [`/api/PaymentCard/GetPaymentCards`] as const;
};

export const usePaymentCardGetPaymentCardsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardsHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPaymentCardGetPaymentCardsQueryKey();

  const paymentCardGetPaymentCards = usePaymentCardGetPaymentCardsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardsHook>>>
  > = ({ signal }) => paymentCardGetPaymentCards(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PaymentCardGetPaymentCardsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardsHook>>>
>;
export type PaymentCardGetPaymentCardsQueryError = unknown;

export const usePaymentCardGetPaymentCards = <
  TData = Awaited<
    ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = usePaymentCardGetPaymentCardsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePaymentCardFindPaymentCardsHook = () => {
  const paymentCardFindPaymentCards =
    usePaymentCardFindPaymentCardsMutator<PaymentCardModel[]>();

  return (findPaymentCardsRequest: FindPaymentCardsRequest) => {
    return paymentCardFindPaymentCards({
      url: `/api/PaymentCard/FindPaymentCards`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: findPaymentCardsRequest,
    });
  };
};

export const usePaymentCardFindPaymentCardsMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardFindPaymentCardsHook>>>,
    TError,
    { data: FindPaymentCardsRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardFindPaymentCardsHook>>>,
  TError,
  { data: FindPaymentCardsRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const paymentCardFindPaymentCards = usePaymentCardFindPaymentCardsHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardFindPaymentCardsHook>>>,
    { data: FindPaymentCardsRequest }
  > = (props) => {
    const { data } = props ?? {};

    return paymentCardFindPaymentCards(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentCardFindPaymentCardsMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardFindPaymentCardsHook>>>
>;
export type PaymentCardFindPaymentCardsMutationBody = FindPaymentCardsRequest;
export type PaymentCardFindPaymentCardsMutationError = unknown;

export const usePaymentCardFindPaymentCards = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardFindPaymentCardsHook>>>,
    TError,
    { data: FindPaymentCardsRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardFindPaymentCardsHook>>>,
  TError,
  { data: FindPaymentCardsRequest },
  TContext
> => {
  const mutationOptions =
    usePaymentCardFindPaymentCardsMutationOptions(options);

  return useMutation(mutationOptions);
};
export const usePaymentCardGetPaymentCardHook = () => {
  const paymentCardGetPaymentCard =
    usePaymentCardGetPaymentCardMutator<PaymentCardModel>();

  return (params?: PaymentCardGetPaymentCardParams, signal?: AbortSignal) => {
    return paymentCardGetPaymentCard({
      url: `/api/PaymentCard/GetPaymentCard`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getPaymentCardGetPaymentCardQueryKey = (
  params?: PaymentCardGetPaymentCardParams
) => {
  return [
    `/api/PaymentCard/GetPaymentCard`,
    ...(params ? [params] : []),
  ] as const;
};

export const usePaymentCardGetPaymentCardQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardHook>>
  >,
  TError = unknown,
>(
  params?: PaymentCardGetPaymentCardParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPaymentCardGetPaymentCardQueryKey(params);

  const paymentCardGetPaymentCard = usePaymentCardGetPaymentCardHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardHook>>>
  > = ({ signal }) => paymentCardGetPaymentCard(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PaymentCardGetPaymentCardQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardHook>>>
>;
export type PaymentCardGetPaymentCardQueryError = unknown;

export const usePaymentCardGetPaymentCard = <
  TData = Awaited<
    ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardHook>>
  >,
  TError = unknown,
>(
  params?: PaymentCardGetPaymentCardParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof usePaymentCardGetPaymentCardHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = usePaymentCardGetPaymentCardQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePaymentCardCreatePaymentCardHook = () => {
  const paymentCardCreatePaymentCard =
    usePaymentCardCreatePaymentCardMutator<void>();

  return (paymentCardModelBody: PaymentCardModelBody) => {
    return paymentCardCreatePaymentCard({
      url: `/api/PaymentCard/CreatePaymentCard`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: paymentCardModelBody,
    });
  };
};

export const usePaymentCardCreatePaymentCardMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardCreatePaymentCardHook>>>,
    TError,
    { data: PaymentCardModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardCreatePaymentCardHook>>>,
  TError,
  { data: PaymentCardModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const paymentCardCreatePaymentCard = usePaymentCardCreatePaymentCardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardCreatePaymentCardHook>>>,
    { data: PaymentCardModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return paymentCardCreatePaymentCard(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentCardCreatePaymentCardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardCreatePaymentCardHook>>>
>;
export type PaymentCardCreatePaymentCardMutationBody = PaymentCardModelBody;
export type PaymentCardCreatePaymentCardMutationError = unknown;

export const usePaymentCardCreatePaymentCard = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardCreatePaymentCardHook>>>,
    TError,
    { data: PaymentCardModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardCreatePaymentCardHook>>>,
  TError,
  { data: PaymentCardModelBody },
  TContext
> => {
  const mutationOptions =
    usePaymentCardCreatePaymentCardMutationOptions(options);

  return useMutation(mutationOptions);
};
export const usePaymentCardUpdatePaymentCardHook = () => {
  const paymentCardUpdatePaymentCard =
    usePaymentCardUpdatePaymentCardMutator<void>();

  return (paymentCardModelBody: PaymentCardModelBody) => {
    return paymentCardUpdatePaymentCard({
      url: `/api/PaymentCard/UpdatePaymentCard`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: paymentCardModelBody,
    });
  };
};

export const usePaymentCardUpdatePaymentCardMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardUpdatePaymentCardHook>>>,
    TError,
    { data: PaymentCardModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardUpdatePaymentCardHook>>>,
  TError,
  { data: PaymentCardModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const paymentCardUpdatePaymentCard = usePaymentCardUpdatePaymentCardHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardUpdatePaymentCardHook>>>,
    { data: PaymentCardModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return paymentCardUpdatePaymentCard(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentCardUpdatePaymentCardMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardUpdatePaymentCardHook>>>
>;
export type PaymentCardUpdatePaymentCardMutationBody = PaymentCardModelBody;
export type PaymentCardUpdatePaymentCardMutationError = unknown;

export const usePaymentCardUpdatePaymentCard = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardUpdatePaymentCardHook>>>,
    TError,
    { data: PaymentCardModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardUpdatePaymentCardHook>>>,
  TError,
  { data: PaymentCardModelBody },
  TContext
> => {
  const mutationOptions =
    usePaymentCardUpdatePaymentCardMutationOptions(options);

  return useMutation(mutationOptions);
};
export const usePaymentCardInactivatePaymentCardHook = () => {
  const paymentCardInactivatePaymentCard =
    usePaymentCardInactivatePaymentCardMutator<void>();

  return (paymentCardModelBody: PaymentCardModelBody) => {
    return paymentCardInactivatePaymentCard({
      url: `/api/PaymentCard/InactivatePaymentCard`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: paymentCardModelBody,
    });
  };
};

export const usePaymentCardInactivatePaymentCardMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof usePaymentCardInactivatePaymentCardHook>>
    >,
    TError,
    { data: PaymentCardModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof usePaymentCardInactivatePaymentCardHook>>
  >,
  TError,
  { data: PaymentCardModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const paymentCardInactivatePaymentCard =
    usePaymentCardInactivatePaymentCardHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof usePaymentCardInactivatePaymentCardHook>>
    >,
    { data: PaymentCardModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return paymentCardInactivatePaymentCard(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentCardInactivatePaymentCardMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof usePaymentCardInactivatePaymentCardHook>>
  >
>;
export type PaymentCardInactivatePaymentCardMutationBody = PaymentCardModelBody;
export type PaymentCardInactivatePaymentCardMutationError = unknown;

export const usePaymentCardInactivatePaymentCard = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof usePaymentCardInactivatePaymentCardHook>>
    >,
    TError,
    { data: PaymentCardModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof usePaymentCardInactivatePaymentCardHook>>
  >,
  TError,
  { data: PaymentCardModelBody },
  TContext
> => {
  const mutationOptions =
    usePaymentCardInactivatePaymentCardMutationOptions(options);

  return useMutation(mutationOptions);
};
export const usePaymentCardAddCardOwnerHook = () => {
  const paymentCardAddCardOwner = usePaymentCardAddCardOwnerMutator<Blob>();

  return (userCardDTO: UserCardDTO) => {
    return paymentCardAddCardOwner({
      url: `/api/PaymentCard/AddCardOwner`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userCardDTO,
      responseType: 'blob',
    });
  };
};

export const usePaymentCardAddCardOwnerMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardAddCardOwnerHook>>>,
    TError,
    { data: UserCardDTO },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardAddCardOwnerHook>>>,
  TError,
  { data: UserCardDTO },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const paymentCardAddCardOwner = usePaymentCardAddCardOwnerHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardAddCardOwnerHook>>>,
    { data: UserCardDTO }
  > = (props) => {
    const { data } = props ?? {};

    return paymentCardAddCardOwner(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentCardAddCardOwnerMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardAddCardOwnerHook>>>
>;
export type PaymentCardAddCardOwnerMutationBody = UserCardDTO;
export type PaymentCardAddCardOwnerMutationError = unknown;

export const usePaymentCardAddCardOwner = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardAddCardOwnerHook>>>,
    TError,
    { data: UserCardDTO },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardAddCardOwnerHook>>>,
  TError,
  { data: UserCardDTO },
  TContext
> => {
  const mutationOptions = usePaymentCardAddCardOwnerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const usePaymentCardRemoveCardOwnerHook = () => {
  const paymentCardRemoveCardOwner =
    usePaymentCardRemoveCardOwnerMutator<Blob>();

  return (userCardMappingDTO: UserCardMappingDTO) => {
    return paymentCardRemoveCardOwner({
      url: `/api/PaymentCard/RemoveCardOwner`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userCardMappingDTO,
      responseType: 'blob',
    });
  };
};

export const usePaymentCardRemoveCardOwnerMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardRemoveCardOwnerHook>>>,
    TError,
    { data: UserCardMappingDTO },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardRemoveCardOwnerHook>>>,
  TError,
  { data: UserCardMappingDTO },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const paymentCardRemoveCardOwner = usePaymentCardRemoveCardOwnerHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardRemoveCardOwnerHook>>>,
    { data: UserCardMappingDTO }
  > = (props) => {
    const { data } = props ?? {};

    return paymentCardRemoveCardOwner(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentCardRemoveCardOwnerMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardRemoveCardOwnerHook>>>
>;
export type PaymentCardRemoveCardOwnerMutationBody = UserCardMappingDTO;
export type PaymentCardRemoveCardOwnerMutationError = unknown;

export const usePaymentCardRemoveCardOwner = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardRemoveCardOwnerHook>>>,
    TError,
    { data: UserCardMappingDTO },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardRemoveCardOwnerHook>>>,
  TError,
  { data: UserCardMappingDTO },
  TContext
> => {
  const mutationOptions = usePaymentCardRemoveCardOwnerMutationOptions(options);

  return useMutation(mutationOptions);
};
export const usePaymentCardGetUserCardsHook = () => {
  const paymentCardGetUserCards =
    usePaymentCardGetUserCardsMutator<UserCardDTO[]>();

  return (params?: PaymentCardGetUserCardsParams, signal?: AbortSignal) => {
    return paymentCardGetUserCards({
      url: `/api/PaymentCard/GetUserCards`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getPaymentCardGetUserCardsQueryKey = (
  params?: PaymentCardGetUserCardsParams
) => {
  return [
    `/api/PaymentCard/GetUserCards`,
    ...(params ? [params] : []),
  ] as const;
};

export const usePaymentCardGetUserCardsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof usePaymentCardGetUserCardsHook>>
  >,
  TError = unknown,
>(
  params?: PaymentCardGetUserCardsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof usePaymentCardGetUserCardsHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getPaymentCardGetUserCardsQueryKey(params);

  const paymentCardGetUserCards = usePaymentCardGetUserCardsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetUserCardsHook>>>
  > = ({ signal }) => paymentCardGetUserCards(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardGetUserCardsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type PaymentCardGetUserCardsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardGetUserCardsHook>>>
>;
export type PaymentCardGetUserCardsQueryError = unknown;

export const usePaymentCardGetUserCards = <
  TData = Awaited<
    ReturnType<ReturnType<typeof usePaymentCardGetUserCardsHook>>
  >,
  TError = unknown,
>(
  params?: PaymentCardGetUserCardsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof usePaymentCardGetUserCardsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = usePaymentCardGetUserCardsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const usePaymentCardUploadCardMappingHook = () => {
  const paymentCardUploadCardMapping =
    usePaymentCardUploadCardMappingMutator<void>();

  return (userCardMappingDTO: UserCardMappingDTO[]) => {
    return paymentCardUploadCardMapping({
      url: `/api/PaymentCard/UploadCardMapping`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: userCardMappingDTO,
    });
  };
};

export const usePaymentCardUploadCardMappingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardUploadCardMappingHook>>>,
    TError,
    { data: UserCardMappingDTO[] },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardUploadCardMappingHook>>>,
  TError,
  { data: UserCardMappingDTO[] },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const paymentCardUploadCardMapping = usePaymentCardUploadCardMappingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardUploadCardMappingHook>>>,
    { data: UserCardMappingDTO[] }
  > = (props) => {
    const { data } = props ?? {};

    return paymentCardUploadCardMapping(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type PaymentCardUploadCardMappingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardUploadCardMappingHook>>>
>;
export type PaymentCardUploadCardMappingMutationBody = UserCardMappingDTO[];
export type PaymentCardUploadCardMappingMutationError = unknown;

export const usePaymentCardUploadCardMapping = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof usePaymentCardUploadCardMappingHook>>>,
    TError,
    { data: UserCardMappingDTO[] },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof usePaymentCardUploadCardMappingHook>>>,
  TError,
  { data: UserCardMappingDTO[] },
  TContext
> => {
  const mutationOptions =
    usePaymentCardUploadCardMappingMutationOptions(options);

  return useMutation(mutationOptions);
};
