/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useQuery } from '@tanstack/react-query';
import type {
  QueryFunction,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  BasicData,
  SetupGetSpeedLimitAlerts200Five,
  SetupGetSpeedLimitAlerts200Four,
  SetupGetSpeedLimitAlerts200One,
  SetupGetSpeedLimitAlerts200Three,
  SetupGetSpeedLimitAlerts200Two,
  SetupGetTimeDistributionDictionary200Five,
  SetupGetTimeDistributionDictionary200Four,
  SetupGetTimeDistributionDictionary200One,
  SetupGetTimeDistributionDictionary200Three,
  SetupGetTimeDistributionDictionary200Two,
  TranslationLookups,
} from '.././model';
import useSetupGetTranslationLookupsMutator from '.././mutator/custom-instance';
import useSetupGetBasicDataMutator from '.././mutator/custom-instance';
import useSetupGetApiVersionMutator from '.././mutator/custom-instance';
import useSetupGetSpeedLimitAlertsMutator from '.././mutator/custom-instance';
import useSetupGetTimeDistributionDictionaryMutator from '.././mutator/custom-instance';

export const useSetupGetTranslationLookupsHook = () => {
  const setupGetTranslationLookups =
    useSetupGetTranslationLookupsMutator<TranslationLookups>();

  return (signal?: AbortSignal) => {
    return setupGetTranslationLookups({
      url: `/api/Setup/GetTranslationLookups`,
      method: 'GET',
      signal,
    });
  };
};

export const getSetupGetTranslationLookupsQueryKey = () => {
  return [`/api/Setup/GetTranslationLookups`] as const;
};

export const useSetupGetTranslationLookupsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSetupGetTranslationLookupsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetTranslationLookupsHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSetupGetTranslationLookupsQueryKey();

  const setupGetTranslationLookups = useSetupGetTranslationLookupsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSetupGetTranslationLookupsHook>>>
  > = ({ signal }) => setupGetTranslationLookups(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetTranslationLookupsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SetupGetTranslationLookupsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetupGetTranslationLookupsHook>>>
>;
export type SetupGetTranslationLookupsQueryError = unknown;

export const useSetupGetTranslationLookups = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSetupGetTranslationLookupsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetTranslationLookupsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSetupGetTranslationLookupsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useSetupGetBasicDataHook = () => {
  const setupGetBasicData = useSetupGetBasicDataMutator<BasicData>();

  return (signal?: AbortSignal) => {
    return setupGetBasicData({
      url: `/api/Setup/GetBasicData`,
      method: 'GET',
      signal,
    });
  };
};

export const getSetupGetBasicDataQueryKey = () => {
  return [`/api/Setup/GetBasicData`] as const;
};

export const useSetupGetBasicDataQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useSetupGetBasicDataHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetBasicDataHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSetupGetBasicDataQueryKey();

  const setupGetBasicData = useSetupGetBasicDataHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSetupGetBasicDataHook>>>
  > = ({ signal }) => setupGetBasicData(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetBasicDataHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SetupGetBasicDataQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetupGetBasicDataHook>>>
>;
export type SetupGetBasicDataQueryError = unknown;

export const useSetupGetBasicData = <
  TData = Awaited<ReturnType<ReturnType<typeof useSetupGetBasicDataHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetBasicDataHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSetupGetBasicDataQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useSetupGetApiVersionHook = () => {
  const setupGetApiVersion = useSetupGetApiVersionMutator<string>();

  return (signal?: AbortSignal) => {
    return setupGetApiVersion({
      url: `/api/Setup/GetApiVersion`,
      method: 'GET',
      signal,
    });
  };
};

export const getSetupGetApiVersionQueryKey = () => {
  return [`/api/Setup/GetApiVersion`] as const;
};

export const useSetupGetApiVersionQueryOptions = <
  TData = Awaited<ReturnType<ReturnType<typeof useSetupGetApiVersionHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetApiVersionHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey = queryOptions?.queryKey ?? getSetupGetApiVersionQueryKey();

  const setupGetApiVersion = useSetupGetApiVersionHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSetupGetApiVersionHook>>>
  > = ({ signal }) => setupGetApiVersion(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetApiVersionHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SetupGetApiVersionQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetupGetApiVersionHook>>>
>;
export type SetupGetApiVersionQueryError = unknown;

export const useSetupGetApiVersion = <
  TData = Awaited<ReturnType<ReturnType<typeof useSetupGetApiVersionHook>>>,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetApiVersionHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSetupGetApiVersionQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useSetupGetSpeedLimitAlertsHook = () => {
  const setupGetSpeedLimitAlerts = useSetupGetSpeedLimitAlertsMutator<
    | SetupGetSpeedLimitAlerts200One
    | SetupGetSpeedLimitAlerts200Two
    | SetupGetSpeedLimitAlerts200Three
    | SetupGetSpeedLimitAlerts200Four
    | SetupGetSpeedLimitAlerts200Five
  >();

  return (signal?: AbortSignal) => {
    return setupGetSpeedLimitAlerts({
      url: `/api/Setup/GetSpeedLimitAlerts`,
      method: 'GET',
      signal,
    });
  };
};

export const getSetupGetSpeedLimitAlertsQueryKey = () => {
  return [`/api/Setup/GetSpeedLimitAlerts`] as const;
};

export const useSetupGetSpeedLimitAlertsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSetupGetSpeedLimitAlertsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetSpeedLimitAlertsHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSetupGetSpeedLimitAlertsQueryKey();

  const setupGetSpeedLimitAlerts = useSetupGetSpeedLimitAlertsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useSetupGetSpeedLimitAlertsHook>>>
  > = ({ signal }) => setupGetSpeedLimitAlerts(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetSpeedLimitAlertsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SetupGetSpeedLimitAlertsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useSetupGetSpeedLimitAlertsHook>>>
>;
export type SetupGetSpeedLimitAlertsQueryError = unknown;

export const useSetupGetSpeedLimitAlerts = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSetupGetSpeedLimitAlertsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useSetupGetSpeedLimitAlertsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useSetupGetSpeedLimitAlertsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useSetupGetTimeDistributionDictionaryHook = () => {
  const setupGetTimeDistributionDictionary =
    useSetupGetTimeDistributionDictionaryMutator<
      | SetupGetTimeDistributionDictionary200One
      | SetupGetTimeDistributionDictionary200Two
      | SetupGetTimeDistributionDictionary200Three
      | SetupGetTimeDistributionDictionary200Four
      | SetupGetTimeDistributionDictionary200Five
    >();

  return (signal?: AbortSignal) => {
    return setupGetTimeDistributionDictionary({
      url: `/api/Setup/GetTimeDistributionDictionary`,
      method: 'GET',
      signal,
    });
  };
};

export const getSetupGetTimeDistributionDictionaryQueryKey = () => {
  return [`/api/Setup/GetTimeDistributionDictionary`] as const;
};

export const useSetupGetTimeDistributionDictionaryQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSetupGetTimeDistributionDictionaryHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useSetupGetTimeDistributionDictionaryHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getSetupGetTimeDistributionDictionaryQueryKey();

  const setupGetTimeDistributionDictionary =
    useSetupGetTimeDistributionDictionaryHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useSetupGetTimeDistributionDictionaryHook>>
    >
  > = ({ signal }) => setupGetTimeDistributionDictionary(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useSetupGetTimeDistributionDictionaryHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type SetupGetTimeDistributionDictionaryQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useSetupGetTimeDistributionDictionaryHook>>
  >
>;
export type SetupGetTimeDistributionDictionaryQueryError = unknown;

export const useSetupGetTimeDistributionDictionary = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useSetupGetTimeDistributionDictionaryHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useSetupGetTimeDistributionDictionaryHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useSetupGetTimeDistributionDictionaryQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
