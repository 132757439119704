import useRecoilReducer from 'hooks/useRecoilReducer';
import { useCallback, useEffect, useMemo } from 'react';
import { atom } from 'recoil';

import { useApiCall } from 'swaggerhooks/lib/useApiCall';

import { AuthenticationClient } from 'api';
import authenticationStateReducer from './authenticationStateReducer';
import {
  AuthenticationState,
  authenticationStateActions,
  getInitialAuthenticationState,
} from './types';
import {
  storeLoginToken,
  storeRefreshToken,
  storeUserId,
} from './authLocalStorage';
import { AXIOS_INSTANCE } from 'api/mutator/custom-instance';
import { AxiosError, AxiosHeaders } from 'axios';
import { useAuthenticationLogin } from 'api/authentication/authentication';
import toast from 'react-hot-toast';

export const authenticationStateAtom = atom({
  key: 'authenticationState',
  default: getInitialAuthenticationState(),
});

interface AuthenticationContextProps {
  state: AuthenticationState;
  login: (username: string, password: string) => void;
  signout: () => void;
  refreshTokens: (token: string, refreshToken: string, userId: number) => void;
}

const useAuthenticationState = (): AuthenticationContextProps => {
  const [state, dispatch] = useRecoilReducer(
    authenticationStateAtom,
    authenticationStateReducer
  );

  const loginApiCall = useAuthenticationLogin();
  // const loginApiCall = useApiCall(
  //   AuthenticationClient,
  //   (c, request: ILoginRequest) => c.login(request as LoginRequest)
  // );

  const signoutApiCall = useApiCall(AuthenticationClient, (c) => c.signout());

  const login = useCallback(async (username: string, password: string) => {
    try {
      const response = await loginApiCall.mutateAsync({
        data: {
          username,
          password,
          fromNative: false,
        },
      });

      if (response.token && response?.refreshToken) {
        storeLoginToken(response.token ?? null);
        storeRefreshToken(response.refreshToken);
        storeUserId(response.userId);

        dispatch(
          authenticationStateActions.login(
            response.token ?? '',
            response.refreshToken,
            response.userId
          )
        );
      }
    } catch (error) {
      storeLoginToken(null);
      storeRefreshToken(null);
      storeUserId(null);

      const errorInfo = (error as AxiosError).response?.data as any;

      dispatch(authenticationStateActions.error(errorInfo));
      toast.error(errorInfo.message);
    }

    //  else {
    //   storeLoginToken(null);
    //   storeRefreshToken(null);
    //   storeUserId(null);

    //   dispatch(authenticationStateActions.signout());
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signout = useCallback(() => {
    signoutApiCall.run();
    storeLoginToken(null);
    storeRefreshToken(null);
    storeUserId(null);

    dispatch(authenticationStateActions.signout());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshTokens = useCallback(
    (token: string, refreshToken: string, userId: number) => {
      storeLoginToken(token);
      storeRefreshToken(refreshToken);
      storeUserId(userId);

      dispatch(
        authenticationStateActions.tokenRefreshed(token, refreshToken, userId)
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    const interceptorId = AXIOS_INSTANCE.interceptors.request.use((config) => ({
      ...config,
      headers: state.token
        ? ({
            ...config.headers,
            Authorization: `Bearer ${state.token}`,
          } as unknown as AxiosHeaders)
        : config.headers,
    }));

    return () => {
      AXIOS_INSTANCE.interceptors.request.eject(interceptorId);
    };
  }, [state.token]);

  return useMemo(
    () => ({ state, login, signout, refreshTokens }),
    [state, login, signout, refreshTokens]
  );
};

export default useAuthenticationState;
