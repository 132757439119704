import {
  AssignmentDeviationEnum,
  AssignmentStatusEnum,
  CaseStatusEnum,
} from 'api';
import { AdvancedAssignmentFilterOption, CaseTypeEnum } from 'api/model';
import { SortState } from 'components/Table';

export enum AssignmentSearchStateActionType {
  SET_VISIBLE_ASSIGNMENT_STATUSES = 'SET_VISIBLE_ASSIGNMENT_STATUSES',
  SET_VISIBLE_ASSIGNMENT_DEVIATIONS = 'SET_VISIBLE_ASSIGNMENT_DEVIATIONS',
  SET_VISIBLE_BUSINESS_UNITS = 'SET_VISIBLE_BUSINESS_UNITS',
  SET_SORT_STATE = 'SET_SORT_STATE',
  SET_SEARCH_TEXT = 'SET_SEARCH_TEXT',
  SET_DATE_RANGE = 'SET_DATE_RANGE',
  ERROR = 'ERROR',

  TRIGGER_ASSIGNMENTS_UPDATE = 'TRIGGER_ASSIGNMENTS_UPDATE',
}

export interface AssignmentSearchState {
  caseTypes: CaseTypeEnum[];
  advancedFilters: AdvancedAssignmentFilterOption[];
  assignmentStatuses: AssignmentStatusEnum[];
  assignmentDeviations: AssignmentDeviationEnum[];
  businessUnits: number[];
  caseStatuses: CaseStatusEnum[];
  sortedColumnIndex: number;
  sortAscending: boolean;
  daterange: [Date | null, Date | null];
  searchText: string;
  fromAddress: string;
  error?: string;
  skip: number;
  size: number;

  triggerAssignmentsUpdate: any;
}

export const getInitialDateRange = (): [Date, Date] => {
  const now = new Date();
  const startOfYesterday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() - 1,
    0,
    0,
    0,
    0
  );
  const endOfTomorrow = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate() + 1,
    23,
    59,
    59,
    999
  );

  return [startOfYesterday, endOfTomorrow];
};

export const getInitialAssignmentSearchState = (): AssignmentSearchState => {
  return {
    caseTypes: [],
    advancedFilters: [],
    assignmentStatuses: [],
    assignmentDeviations: [],
    businessUnits: [],
    caseStatuses: [],
    sortedColumnIndex: 1,
    sortAscending: true,
    daterange: getInitialDateRange(),
    searchText: '',
    fromAddress: '',
    error: undefined,
    size: 500,
    skip: 0,

    triggerAssignmentsUpdate: null,
  };
};

export type AssignmentSearchStateAction = ReturnType<
  (typeof assignmentSearchStateActions)[keyof typeof assignmentSearchStateActions]
>;

export const assignmentSearchStateActions = {
  setVisibleAssignmentStatuses: (statuses: Set<AssignmentStatusEnum> | null) =>
    ({
      type: AssignmentSearchStateActionType.SET_VISIBLE_ASSIGNMENT_STATUSES,
      statuses,
    }) as const,
  setVisibleAssignmentDeviations: (
    deviations: Set<AssignmentDeviationEnum> | null
  ) =>
    ({
      type: AssignmentSearchStateActionType.SET_VISIBLE_ASSIGNMENT_DEVIATIONS,
      deviations,
    }) as const,
  setVisibleBusinessUnits: (units: Set<number> | null) =>
    ({
      type: AssignmentSearchStateActionType.SET_VISIBLE_BUSINESS_UNITS,
      units,
    }) as const,
  setSortingState: (sortingState: SortState) =>
    ({
      type: AssignmentSearchStateActionType.SET_SORT_STATE,
      sortingState,
    }) as const,
  setSearchText: (text: string) =>
    ({
      type: AssignmentSearchStateActionType.SET_SEARCH_TEXT,
      text,
    }) as const,

  setDaterange: (daterange: Date[]) =>
    ({
      type: AssignmentSearchStateActionType.SET_DATE_RANGE,
      daterange,
    }) as const,
  error: (error: string) =>
    ({
      type: AssignmentSearchStateActionType.ERROR,
      error,
    }) as const,

  triggerAssignmentsUpdate: (uniqueValue: any) =>
    ({
      type: AssignmentSearchStateActionType.TRIGGER_ASSIGNMENTS_UPDATE,
      uniqueValue,
    }) as const,
};
