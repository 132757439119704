import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { AssignmentDTO } from 'api/model';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import {
  getGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryKey,
  useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrow,
} from 'api/go-support/go-support';
import {
  Centered,
  getTableRowClassName,
  SavingRowAnimationWrapper,
} from '../table-helpers';
import { useQueryClient } from '@tanstack/react-query';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import Checkbox from 'components/inputs/Checkbox';

const TABLE_NAME = 'not-accepted-tomorrows-assignments';

const NotAcceptedTomorrowsAssignmentsWidget = () => {
  const [assignments, setAssignments] = useState<AssignmentDTO[]>([]);
  const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();

  const queryClient = useQueryClient();
  const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
    useCaseMarkEventsAsReadByAdmin({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            `${getGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrowQueryKey()}`,
          ]);
        },
      },
    });

  const getAssignments =
    useGoSupportGetAssignmentsNotYetAcceptedByFTForTomorrow(
      {
        startDate: daterange[0] ?? undefined,
        endDate: daterange[1] ?? undefined,
        businessUnitIDs: selectedBusinessUnits,
      },
      {
        query: {
          refetchInterval: UPDATE_INTERVAL,
        },
      }
    );

  useEffect(() => {
    if (getAssignments.data) {
      setAssignments(getAssignments.data);
    } else {
      setAssignments([] as AssignmentDTO[]);
    }
  }, [getAssignments.data]);

  const filteredAssignments = useMemo(() => {
    if (!searchText.length) {
      return assignments;
    }
    return assignments
      ? assignments.filter((assignment) => {
          return (
            assignment.registrationNumber
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (users[assignment.assignedToID]?.name ?? '')
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        })
      : [];
  }, [assignments, searchText, users]);

  const columnSettings: ColumnSetting<AssignmentDTO, any>[] = useMemo(() => {
    const columns: ColumnSetting<AssignmentDTO, any>[] = [
      {
        head: 'Nr',
        render: (e, t, f, i) => i + 1,
        width: 15,
      },
      {
        head: 'Reg.nr',
        render: (row) => `${row.registrationNumber}`,
        width: 50,
        sortFunction: (a, b) => {
          return a.registrationNumber.localeCompare(b.registrationNumber);
        },
      },
      {
        head: 'Bokat till',
        render: (row) => `${formatDateTime(row.bookedTo)}`,
        width: 100,
        sortFunction: (a, b) => a.bookedTo.getTime() - b.bookedTo.getTime(),
      },
      {
        head: 'Estimerad starttid',
        render: (row) => `${formatDateTime(row.estimatedStartTime)}`,
        width: 100,
        // sort by startTime which can be undefined
        sortFunction: (a, b) => {
          if (a.estimatedStartTime && b.estimatedStartTime) {
            return (
              a.estimatedStartTime.getTime() - b.estimatedStartTime.getTime()
            );
          }
          return 0;
        },
      },
      {
        head: 'Fälttestare',
        render: (row) => {
          return (
            <span>
              {users[row.assignedToID] ? users[row.assignedToID].name : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.assignedToID]?.name ?? '').localeCompare(
            users[b.assignedToID]?.name ?? ''
          ),
      },
      {
        head: 'Telefonnummer',
        render: (row) => {
          return (
            <span>
              {users[row.assignedToID]
                ? users[row.assignedToID].phoneNumber
                : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.assignedToID]?.phoneNumber ?? '').localeCompare(
            users[b.assignedToID]?.phoneNumber ?? ''
          ),
      },
      {
        head: ' ',
        render: (row) => (
          <Centered>
            <FontAwesomeIcon
              onClick={() => {
                window.open(
                  `${Routes.search.index}/${row.caseID}`,
                  row.caseID.toString() ?? '_blank'
                );
              }}
              icon={faArrowUpRightFromSquare}
              fontSize="18px"
              style={{ cursor: 'pointer' }}
            />
          </Centered>
        ),
        width: 20,
      },
      {
        head: 'Klart',
        render: (row) => (
          <Centered>
            <Checkbox
              disabled={markAsReadStatus === 'loading'}
              onChange={async () => {
                setOngoingSaves([...ongoingSaves, row.id]);
                await markEventAsRead({ data: [row.id] });
                setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
              }}
            />
          </Centered>
        ),
        width: 30,
      },
    ];
    return columns;
  }, [markAsReadStatus, markEventAsRead, ongoingSaves, users]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.NotAcceptedTomorrowsAssignments]}
      count={assignments.length}
      isLoading={getAssignments.isLoading}
      info="Fälttestaren har ännu inte accepterat morgondagens uppdrag. Denna lista fylls med data först efter 21.00"
    >
      <SavingRowAnimationWrapper
        tableName={TABLE_NAME}
        savingIds={ongoingSaves}
      >
        <Table
          columnSettings={columnSettings}
          rows={filteredAssignments}
          rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
          useColumnWidthAsFlexAmount
        />
      </SavingRowAnimationWrapper>
    </Widget>
  );
};

export default NotAcceptedTomorrowsAssignmentsWidget;
