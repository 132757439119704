import {
  AssignmentClient,
  AssignmentDeviationEnum,
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentViewModel,
  CaseEventType,
  CaseExtendedModel,
  CaseModel,
  CaseStatusEnum,
  CaseTypeEnum,
  StopoverAddress,
  StopOverRequest,
  ZipCodeArea,
} from 'api';
import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import { useCallback, useMemo } from 'react';
import CancelCaseAssignmentModal from '../CancelCaseAssignmentModal';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import { validateMoveToApproved } from 'utils/assignmentStatusValidation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import StopOverModal from '../StopOverModal';
import validateExpectedExpenses from 'utils/validateExpectedExpenses';
import MissingExpenseApprovalModal from '../MissingExpenseApprovalModal';
import { getTimeFromNowInMillis } from 'utils/date-helpers';
import {
  useAssignmentSetAssignmentToPlannedStopOver,
  useAssignmentUpdateAssignment,
} from 'api/assignment/assignment';
import { AssignmentViewModelBody } from 'api/model';
import AssignmentRatingModal from 'components/AssignmentRatingModal';
import { useCaseCloseCase } from 'api/case/case';
import PlannedStopOverModal from '../PlannedStopOverModal';

function getEventTypeAndMessageForAssignmentStatus(
  assignmenStatus: AssignmentStatusEnum
): [eventType: CaseEventType, message: string] {
  switch (assignmenStatus) {
    case AssignmentStatusEnum.Approved:
      return [CaseEventType.AssignmentApproved, 'Uppdrag godkänt'];
    case AssignmentStatusEnum.Canceled:
      return [CaseEventType.AssignmentDeleted, 'Uppdrag avbokat'];
    case AssignmentStatusEnum.Aborted:
      return [CaseEventType.AssignmentDeleted, 'Uppdrag bomkörning'];
    case AssignmentStatusEnum.Discarded:
      return [CaseEventType.AssignmentDeleted, 'Uppdrag borttaget'];
    default:
      return [CaseEventType.AssignmentEdited, 'Uppdrag redigerades'];
  }
}

const useCaseAssignmentActions = (
  currentCase: CaseExtendedModel | undefined,
  currentCaseWasUpdated: () => Promise<any>
) => {
  const modalStack = useModalStack();

  const updateCaseAssignmentCall = useAssignmentUpdateAssignment();

  const closeCaseCall = useCaseCloseCase();

  const stopoverAssignmentCall = useApiCall(
    AssignmentClient,
    (client, stopoverRequest: StopOverRequest) =>
      client.setAssignmentToStopOver(stopoverRequest)
  );

  const plannedStopoverAssignmentCall =
    useAssignmentSetAssignmentToPlannedStopOver();

  const makeAssignmentViewModel = useCallback(
    (assignmentId: number): AssignmentViewModel | undefined => {
      const caseModel = currentCase?.case;
      const assignmentModel = currentCase?.case.assignmentList.find(
        (a) => a.assignmentID === assignmentId
      );
      if (!caseModel || !assignmentModel) return undefined;

      return new AssignmentViewModel({
        case: caseModel,
        assignment: assignmentModel,
      });
    },
    [currentCase?.case]
  );

  const handleCancelCaseAssignment = useCallback(
    async (assignmentId: number) => {
      const avm = makeAssignmentViewModel(assignmentId);
      if (!avm || !currentCase) return;

      const modalId = modalStack.push(
        <CancelCaseAssignmentModal
          assignmentViewModel={avm}
          onCloseClick={() => modalStack.pop(modalId)}
          onOkClick={async (
            assignmentStatus,
            assignmendDeviation,
            cancelType,
            closeCase,
            clearEstimatedAndFixedAssignmentTimes,
            endTime,
            comment
          ) => {
            modalStack.pop(modalId);

            const updatedAVM = new AssignmentViewModel({
              ...avm,
              assignment: new AssignmentModel({
                ...avm.assignment,
                assignmentDeviationID: assignmendDeviation,
                assignmentStatusID: assignmentStatus,
                assignmentCancelTypeID: cancelType,
                endTime: endTime ?? avm.assignment.endTime,
                fixedAssignmentTime: clearEstimatedAndFixedAssignmentTimes
                  ? undefined
                  : avm.assignment.fixedAssignmentTime,
                // we need to free up the time after this assignment for booking if it hasn't already ended
                estimatedDuration:
                  clearEstimatedAndFixedAssignmentTimes &&
                  !avm.assignment.endTime
                    ? getTimeFromNowInMillis(avm.assignment.startTime)
                    : avm.assignment.estimatedDuration,
              }),
              case: new CaseModel({
                ...avm.case,
                caseStatusID: closeCase
                  ? CaseStatusEnum.Closed
                  : avm.case.caseStatusID,
              }),
            });

            const [eventType, defaultMessage] =
              getEventTypeAndMessageForAssignmentStatus(assignmentStatus);

            await updateCaseAssignmentCall.mutateAsync({
              data: {
                ...updatedAVM,
              } as AssignmentViewModelBody,
              params: {
                eventType,
                eventMessage: comment || defaultMessage,
              },
            });
            await currentCaseWasUpdated();
          }}
        />
      );
    },
    [
      makeAssignmentViewModel,
      currentCase,
      modalStack,
      updateCaseAssignmentCall,
      currentCaseWasUpdated,
    ]
  );

  const handleCaseAssignmentStopOver = useCallback(
    async (assignmentId: number) => {
      const avm = makeAssignmentViewModel(assignmentId);
      if (!avm || !currentCase) return;

      const modalId = modalStack.push(
        <StopOverModal
          onAddressPicked={async (name, address, zip, city, county) => {
            modalStack.pop(modalId);

            await stopoverAssignmentCall.run(
              new StopOverRequest({
                model: avm,
                stopOverAddress: new StopoverAddress({
                  name,
                  address,
                  zip,
                  city,
                  county: (county as ZipCodeArea) ?? undefined,
                }),
              })
            );

            await currentCaseWasUpdated();
          }}
          onCancel={() => modalStack.pop(modalId)}
          originalAssignment={avm.assignment}
        />
      );
    },
    [
      currentCase,
      currentCaseWasUpdated,
      makeAssignmentViewModel,
      modalStack,
      stopoverAssignmentCall,
    ]
  );

  const handlePlannedCaseAssignmentStopOver = useCallback(
    async (assignmentId: number) => {
      const avm = makeAssignmentViewModel(assignmentId);
      if (!avm || !currentCase) return;

      const modalId = modalStack.push(
        <PlannedStopOverModal
          onAddressPicked={async (
            { name, address, zip, city, county },
            currentAssignmentEstimatedDuration,
            newAssignmentEstimatedDuration,
            newAssignmentEstimatedStartTime
          ) => {
            modalStack.pop(modalId);

            await plannedStopoverAssignmentCall.mutateAsync({
              data: {
                model: avm,
                stopOverAddress: new StopoverAddress({
                  name,
                  address,
                  zip,
                  city,
                  county: (county as ZipCodeArea) ?? undefined,
                }),
                currentAssignmentEstimatedDuration,
                newAssignmentEstimatedDuration,
                newAssignmentEstimatedStartTime,
              },
            });

            await currentCaseWasUpdated();
          }}
          onCancel={() => modalStack.pop(modalId)}
          originalAssignment={avm.assignment}
        />
      );
    },
    [
      currentCase,
      currentCaseWasUpdated,
      makeAssignmentViewModel,
      modalStack,
      plannedStopoverAssignmentCall,
    ]
  );

  const handleDiscardCaseAssignment = useCallback(
    async (assignmentId: number) => {
      const avm = makeAssignmentViewModel(assignmentId);
      if (!avm || !currentCase) return;

      const modalId = modalStack.push(
        <Modal
          buttons={[
            {
              label: 'Ta bort',
              icon: <FontAwesomeIcon icon={faTrash} />,
              onClick: async () => {
                modalStack.pop(modalId);

                const updatedAVM = new AssignmentViewModel({
                  ...avm,
                  assignment: new AssignmentModel({
                    ...avm.assignment,
                    assignmentStatusID: AssignmentStatusEnum.Discarded,
                    assignmentDeviationID: AssignmentDeviationEnum.Discarded,
                  }),
                });

                const [eventType, defaultMessage] =
                  getEventTypeAndMessageForAssignmentStatus(
                    AssignmentStatusEnum.Discarded
                  );

                await updateCaseAssignmentCall.mutateAsync({
                  data: updatedAVM as AssignmentViewModelBody,
                  params: {
                    eventType,
                    eventMessage: defaultMessage,
                  },
                });
                await currentCaseWasUpdated();
              },
            },
            { label: 'Avbryt', onClick: () => modalStack.pop(modalId) },
          ]}
          onClose={() => modalStack.pop(modalId)}
          title="Ta bort uppdrag?"
        >
          Är du säker på att du vill ta bort uppdraget?
        </Modal>
      );
    },
    [
      currentCase,
      currentCaseWasUpdated,
      makeAssignmentViewModel,
      modalStack,
      updateCaseAssignmentCall,
    ]
  );

  const approveCaseAssignment = useCallback(
    async (assignment: AssignmentViewModel) => {
      const updatedAVM = new AssignmentViewModel({
        ...assignment,
        assignment: new AssignmentModel({
          ...assignment.assignment,
          assignmentStatusID: AssignmentStatusEnum.Approved,
        }),
      });

      const [eventType, defaultMessage] =
        getEventTypeAndMessageForAssignmentStatus(
          updatedAVM.assignment.assignmentStatusID
        );

      await updateCaseAssignmentCall.mutateAsync({
        data: {
          ...updatedAVM,
        } as AssignmentViewModelBody,
        params: {
          eventType,
          eventMessage: defaultMessage,
        },
      });
      await currentCaseWasUpdated();
      modalStack.pop();
    },
    [currentCaseWasUpdated, modalStack, updateCaseAssignmentCall]
  );

  const rateAssigneeAndApproveCaseAssignment = useCallback(
    async (model: AssignmentViewModel) => {
      if (
        model.assignment.assignedToRating === null &&
        [
          CaseTypeEnum.HomeDelivery,
          CaseTypeEnum.TradeIn,
          CaseTypeEnum.Purchase,
          CaseTypeEnum.Extra,
        ].includes(model.case.caseTypeID) &&
        model.assignment.assignmentStatusID === AssignmentStatusEnum.Complete
      ) {
        // collect the rating
        modalStack.push(
          <AssignmentRatingModal
            onClose={() => modalStack.pop()}
            onRatedAssignment={async (updatedModel) => {
              approveCaseAssignment(updatedModel);
            }}
            model={model}
          />
        );
      } else {
        // approve the assignment
        await approveCaseAssignment(model);
      }
    },
    [approveCaseAssignment, modalStack]
  );

  const handleApproveCaseAssignment = useCallback(
    async (assignmentId: number) => {
      const viewModel = makeAssignmentViewModel(assignmentId);

      if (!viewModel) return;

      const errors = validateMoveToApproved(viewModel);

      if (errors.length > 0) {
        modalStack.push(
          <Modal
            buttons={[{ label: 'Ok', onClick: () => modalStack.pop() }]}
            onClose={() => modalStack.pop()}
            title="Fel"
          >
            Innan du kan godkänna uppdraget måste du:
            <br />
            <br />
            <ul>
              {errors.map((err) => (
                <li key={err}> - {err}</li>
              ))}
            </ul>
          </Modal>
        );
      } else {
        if (!currentCase) return;

        const missingExpenses = validateExpectedExpenses(
          currentCase,
          assignmentId
        );

        if (missingExpenses.missingExpenses.length > 0) {
          modalStack.push(
            <MissingExpenseApprovalModal
              handleClose={() => modalStack.pop()}
              handleConfirm={async () => {
                // await approveCaseAssignment(viewModel)
                modalStack.pop();
                await rateAssigneeAndApproveCaseAssignment(viewModel);
              }}
              missingExpenses={[missingExpenses]}
              numSelected={1}
            />
          );
        } else {
          // approveCaseAssignment(viewModel);
          modalStack.pop();
          rateAssigneeAndApproveCaseAssignment(viewModel);
        }
      }
    },
    [
      makeAssignmentViewModel,
      modalStack,
      currentCase,
      rateAssigneeAndApproveCaseAssignment,
    ]
  );

  return useMemo(
    () => ({
      onCaseAssignmentCancel: handleCancelCaseAssignment,
      onCaseAssignmentStopOver: handleCaseAssignmentStopOver,
      onPlannedCaseAssignmentStopOver: handlePlannedCaseAssignmentStopOver,
      onCaseAssignmentDiscard: handleDiscardCaseAssignment,
      onCaseAssignmentApprove: handleApproveCaseAssignment,
      isUpdatingAssignmentStatus:
        stopoverAssignmentCall.status === RequestStatus.Fetching ||
        closeCaseCall.status === 'loading',
    }),
    [
      closeCaseCall.status,
      handleApproveCaseAssignment,
      handleCancelCaseAssignment,
      handleCaseAssignmentStopOver,
      handleDiscardCaseAssignment,
      handlePlannedCaseAssignmentStopOver,
      stopoverAssignmentCall.status,
    ]
  );
};

export default useCaseAssignmentActions;
