import { faSpinner, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from '@tanstack/react-query';
import { getInternalDeliveryBudgetGetYearlyBudgetsQueryKey } from 'api/internal-delivery-budget/internal-delivery-budget';
import {
  getInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryKey,
  useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverride,
  useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverride,
} from 'api/internal-delivery-daily-budget-override/internal-delivery-daily-budget-override';
import Input from 'components/inputs/Input';
import TextButton from 'components/inputs/TextButton';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Loader = styled.div`
  grid-column-end: 3;
`;

const MyInput = styled(Input)`
  padding: 0;
  padding-left: 5px;
  margin: 0;
  text-align: center;
`;

interface Props {
  value: number;
  date: Date;
  companyId: number;
  overrideId?: number;
  onUpdate?: () => void;
  isFocused?: boolean;
}

const OverrideDailyInternalDeliveryBudgetInput: FC<Props> = ({
  value,
  date,
  companyId,
  overrideId,
  onUpdate,
  isFocused,
}) => {
  const [cellValue, setCellValue] = useState(value);
  const [isUpdatingCell, setIsUpdatingCell] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const updateOverride =
    useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverride();
  const deleteOverride =
    useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverride();
  const queryClient = useQueryClient();

  const handleChangeValue = useCallback((val: number) => {
    if (val !== value) {
      updateOverride.mutate(
        {
          data: {
            dailyBudgetOverrideID: overrideId ?? -1,
            companyID: companyId,
            date,
            amount: val,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(
              getInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryKey()
            );
            queryClient.invalidateQueries(
              getInternalDeliveryBudgetGetYearlyBudgetsQueryKey()
            );
          },
        }
      );
      onUpdate?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDeleteOverride = useCallback(() => {
    deleteOverride.mutate(
      {
        data: {
          dailyBudgetOverrideID: overrideId ?? -1,
          companyID: companyId,
          date,
          amount: 0,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(
            getInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryKey()
          );
          queryClient.invalidateQueries(
            getInternalDeliveryBudgetGetYearlyBudgetsQueryKey()
          );
        },
      }
    );
    onUpdate?.();
  }, [companyId, date, deleteOverride, onUpdate, overrideId, queryClient]);

  useEffect(() => {
    if (isFocused) {
      setIsUpdatingCell(true);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      timeoutRef.current = setTimeout(() => handleChangeValue(cellValue), 1000);
      setIsUpdatingCell(false);
    }
  }, [cellValue, handleChangeValue, isFocused, isUpdatingCell]);

  return (
    <Wrapper>
      <Loader>
        {updateOverride.isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
      </Loader>
      {isFocused && !isUpdatingCell ? (
        <>
          <MyInput
            type="number"
            disabled={updateOverride.isLoading}
            value={cellValue}
            onChange={(eve) => {
              const newNum = Number(eve.target.value);
              if (!Number.isNaN(newNum) && newNum < 0) {
                setCellValue(0);
              } else {
                setCellValue(newNum);
              }
            }}
            onKeyDown={(eve) => {
              // save immediately on enter
              if (eve.key === 'Enter') {
                handleChangeValue(cellValue);
              }
            }}
            style={{ width: '50px', height: '20px' }}
          />
          {overrideId && (
            <TextButton
              onClick={handleDeleteOverride}
              disabled={deleteOverride.isLoading}
            >
              <FontAwesomeIcon icon={faTrashAlt} />
            </TextButton>
          )}
        </>
      ) : (
        <span
          title="Budget"
          style={{ color: overrideId !== undefined ? 'crimson' : 'black' }}
        >
          {value ?? ''}
        </span>
      )}
    </Wrapper>
  );
};

export default OverrideDailyInternalDeliveryBudgetInput;
