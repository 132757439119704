import { AssignmentRequestFilterEnum } from 'api/model';
import { SortState } from 'components/Table';
import moment from 'moment';
import { DateFormat } from 'pages/InternalDeliveryGroup/components/filterBars';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalStorage } from 'usehooks-ts';

export interface FilterSetting {
  filterEnum: AssignmentRequestFilterEnum;
  showCounts?: boolean;
}

export const selectableAssignmentRequestsFiltersByLabel: Record<
  string,
  FilterSetting
> = {
  Alla: {
    filterEnum: AssignmentRequestFilterEnum.All,
  },

  Otilldelade: {
    filterEnum: AssignmentRequestFilterEnum.Unassigned,
    showCounts: true,
  },

  Planerade: {
    filterEnum: AssignmentRequestFilterEnum.Assigned,
  },

  Utdelade: {
    filterEnum: AssignmentRequestFilterEnum.CheckedOut,
  },

  Avslagna: {
    filterEnum: AssignmentRequestFilterEnum.Denied,
  },
} as const;

export interface AssignmentRequestsFiltersState {
  sortedColumnIndex: number;
  sortAscending: boolean;
  selectedDate: Date;
  startDate: Date;
  endDate: Date;
  filterEnum: AssignmentRequestFilterEnum;
  visibleBusinessGroups: number[];
  visibleBusinessUnits: number[];
  requireFullAllocation: boolean;
  searchText: string;
}

interface AssignmentRequestsFiltersProps {
  state: AssignmentRequestsFiltersState;
  setState: React.Dispatch<
    React.SetStateAction<AssignmentRequestsFiltersState>
  >;
  selectedFilterView: DateFormat;
  setSelectedFilterView: React.Dispatch<React.SetStateAction<DateFormat>>;
  selectedStatusView: AssignmentRequestFilterEnum;
  setSelectedStatusView: React.Dispatch<
    React.SetStateAction<AssignmentRequestFilterEnum>
  >;
  setVisibleBusinessGroups: (businessGroups: number[]) => void;
  setVisibleBusinessUnits: (businessUnits: number[]) => void;
  setRequireFullAllocation: (requireFullAllocation: boolean) => void;
  setStartDate: (date: Date) => void;
  setSearchText: (value: string) => void;
  setSortState: (value: SortState) => void;
}

const AssignmentRequestsFiltersContext =
  createContext<AssignmentRequestsFiltersProps>({
    state: {
      sortedColumnIndex: 6,
      sortAscending: true,
      selectedDate: new Date(),
      startDate: new Date(),
      endDate: new Date(),
      filterEnum: AssignmentRequestFilterEnum.All,
      visibleBusinessGroups: [],
      visibleBusinessUnits: [],
      requireFullAllocation: false,
      searchText: '',
    },
    setState: () => Function,
    selectedFilterView: 'date',
    setSelectedFilterView: () => Function,
    selectedStatusView: AssignmentRequestFilterEnum.All,
    setSelectedStatusView: () => Function,
    setVisibleBusinessGroups: () => Function,
    setVisibleBusinessUnits: () => Function,
    setStartDate: () => Function,
    setRequireFullAllocation: () => Function,
    setSearchText: () => Function,
    setSortState: () => Function,
  });

export const AssignmentRequestsFiltersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedFilterView, setSelectedFilterView] =
    useState<DateFormat>('date');
  const [selectedStatusView, setSelectedStatusView] =
    useState<AssignmentRequestFilterEnum>(AssignmentRequestFilterEnum.All);

  const [requireFullAllocation, setRequireFullAllocation] = useLocalStorage(
    'requireFullAllocation',
    false
  );

  const [state, setState] = useState<AssignmentRequestsFiltersState>({
    sortedColumnIndex: 6,
    sortAscending: true,
    selectedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    filterEnum: AssignmentRequestFilterEnum.All,
    visibleBusinessGroups: [],
    visibleBusinessUnits: [],
    requireFullAllocation,
    searchText: '',
  });

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      requireFullAllocation,
    }));
  }, [requireFullAllocation]);

  const updateDateRange = useCallback(
    (newDate: Date) => {
      if (selectedFilterView === 'date') {
        setState((prev) => ({
          ...prev,
          startDate: newDate,
          endDate: newDate,
        }));
      }

      if (selectedFilterView === 'week') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('isoWeek').toDate(),
          endDate: moment(newDate).endOf('isoWeek').toDate(),
        }));
      }

      if (selectedFilterView === 'month') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('month').toDate(),
          endDate: moment(newDate).endOf('month').toDate(),
        }));
      }

      if (selectedFilterView === 'year') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('year').toDate(),
          endDate: moment(newDate).endOf('year').toDate(),
        }));
      }
    },
    [selectedFilterView, setState]
  );

  const setVisibleBusinessGroups = useCallback(
    (businessGroups: number[]) => {
      setState((prev) => ({
        ...prev,
        visibleBusinessGroups: businessGroups,
      }));
    },
    [setState]
  );

  const setVisibleBusinessUnits = useCallback(
    (businessUnits: number[]) => {
      setState((prev) => ({
        ...prev,
        visibleBusinessUnits: businessUnits,
      }));
    },
    [setState]
  );

  const setStartDate = useCallback(
    (date: Date) => {
      setState((prev) => ({
        ...prev,
        startDate: date,
      }));
    },
    [setState]
  );

  const setSearchText = useCallback(
    (value: string) => {
      setState((prev) => ({
        ...prev,
        searchText: value,
      }));
    },
    [setState]
  );

  const setSortState = useCallback(
    (value: SortState) => {
      setState((prev) => ({
        ...prev,
        sortAscending: value.ascending,
        sortColumn: value.sortedColumnIndex,
      }));
    },
    [setState]
  );

  useEffect(() => {
    updateDateRange(state.selectedDate);
  }, [state.selectedDate, updateDateRange]);

  const providerValue = useMemo(
    (): AssignmentRequestsFiltersProps => ({
      state,
      setState,
      selectedFilterView,
      setSelectedFilterView,
      selectedStatusView,
      setSelectedStatusView,
      setVisibleBusinessGroups,
      setVisibleBusinessUnits,
      setRequireFullAllocation,
      setStartDate,
      setSearchText,
      setSortState,
    }),
    [
      selectedFilterView,
      selectedStatusView,
      setRequireFullAllocation,
      setSearchText,
      setSortState,
      setStartDate,
      setVisibleBusinessGroups,
      setVisibleBusinessUnits,
      state,
    ]
  );

  return (
    <AssignmentRequestsFiltersContext.Provider value={providerValue}>
      {children}
    </AssignmentRequestsFiltersContext.Provider>
  );
};

export const useAssignmentRequestsFilters =
  (): AssignmentRequestsFiltersProps => {
    return useContext(AssignmentRequestsFiltersContext);
  };
