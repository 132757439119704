/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  InternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesParams,
  InternalDeliveryDailyBudgetOverrideModel,
  InternalDeliveryDailyBudgetOverrideModelBody,
} from '.././model';
import useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesMutator from '.././mutator/custom-instance';
import useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideMutator from '.././mutator/custom-instance';
import useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideMutator from '.././mutator/custom-instance';
import useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideMutator from '.././mutator/custom-instance';

export const useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook =
  () => {
    const internalDeliveryDailyBudgetOverrideGetDailyBudgetOverrides =
      useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesMutator<
        InternalDeliveryDailyBudgetOverrideModel[]
      >();

    return (
      params?: InternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesParams,
      signal?: AbortSignal
    ) => {
      return internalDeliveryDailyBudgetOverrideGetDailyBudgetOverrides({
        url: `/api/InternalDeliveryDailyBudgetOverride/GetDailyBudgetOverrides`,
        method: 'GET',
        params,
        signal,
      });
    };
  };

export const getInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryKey =
  (
    params?: InternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesParams
  ) => {
    return [
      `/api/InternalDeliveryDailyBudgetOverride/GetDailyBudgetOverrides`,
      ...(params ? [params] : []),
    ] as const;
  };

export const useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryOptions =
  <
    TData = Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook
        >
      >
    >,
    TError = unknown,
  >(
    params?: InternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesParams,
    options?: {
      query?: UseQueryOptions<
        Awaited<
          ReturnType<
            ReturnType<
              typeof useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook
            >
          >
        >,
        TError,
        TData
      >;
    }
  ) => {
    const { query: queryOptions } = options ?? {};

    const queryKey =
      queryOptions?.queryKey ??
      getInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryKey(
        params
      );

    const internalDeliveryDailyBudgetOverrideGetDailyBudgetOverrides =
      useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook();

    const queryFn: QueryFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook
          >
        >
      >
    > = ({ signal }) =>
      internalDeliveryDailyBudgetOverrideGetDailyBudgetOverrides(
        params,
        signal
      );

    return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook
          >
        >
      >,
      TError,
      TData
    > & { queryKey: QueryKey };
  };

export type InternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook
        >
      >
    >
  >;
export type InternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryError =
  unknown;

export const useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverrides = <
  TData = Awaited<
    ReturnType<
      ReturnType<
        typeof useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook
      >
    >
  >,
  TError = unknown,
>(
  params?: InternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useInternalDeliveryDailyBudgetOverrideGetDailyBudgetOverridesQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook =
  () => {
    const internalDeliveryDailyBudgetOverrideCreateDailyBudgetOverride =
      useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideMutator<number>();

    return (
      internalDeliveryDailyBudgetOverrideModelBody: InternalDeliveryDailyBudgetOverrideModelBody
    ) => {
      return internalDeliveryDailyBudgetOverrideCreateDailyBudgetOverride({
        url: `/api/InternalDeliveryDailyBudgetOverride/CreateDailyBudgetOverride`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        data: internalDeliveryDailyBudgetOverrideModelBody,
      });
    };
  };

export const useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook
          >
        >
      >,
      TError,
      { data: InternalDeliveryDailyBudgetOverrideModelBody },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook
        >
      >
    >,
    TError,
    { data: InternalDeliveryDailyBudgetOverrideModelBody },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const internalDeliveryDailyBudgetOverrideCreateDailyBudgetOverride =
      useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook
          >
        >
      >,
      { data: InternalDeliveryDailyBudgetOverrideModelBody }
    > = (props) => {
      const { data } = props ?? {};

      return internalDeliveryDailyBudgetOverrideCreateDailyBudgetOverride(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type InternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook
        >
      >
    >
  >;
export type InternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideMutationBody =
  InternalDeliveryDailyBudgetOverrideModelBody;
export type InternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideMutationError =
  unknown;

export const useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverride = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook
        >
      >
    >,
    TError,
    { data: InternalDeliveryDailyBudgetOverrideModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideHook
      >
    >
  >,
  TError,
  { data: InternalDeliveryDailyBudgetOverrideModelBody },
  TContext
> => {
  const mutationOptions =
    useInternalDeliveryDailyBudgetOverrideCreateDailyBudgetOverrideMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook =
  () => {
    const internalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverride =
      useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideMutator<void>();

    return (
      internalDeliveryDailyBudgetOverrideModelBody: InternalDeliveryDailyBudgetOverrideModelBody
    ) => {
      return internalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverride({
        url: `/api/InternalDeliveryDailyBudgetOverride/UpdateDailyBudgetOverride`,
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        data: internalDeliveryDailyBudgetOverrideModelBody,
      });
    };
  };

export const useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook
          >
        >
      >,
      TError,
      { data: InternalDeliveryDailyBudgetOverrideModelBody },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook
        >
      >
    >,
    TError,
    { data: InternalDeliveryDailyBudgetOverrideModelBody },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const internalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverride =
      useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook
          >
        >
      >,
      { data: InternalDeliveryDailyBudgetOverrideModelBody }
    > = (props) => {
      const { data } = props ?? {};

      return internalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverride(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type InternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook
        >
      >
    >
  >;
export type InternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideMutationBody =
  InternalDeliveryDailyBudgetOverrideModelBody;
export type InternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideMutationError =
  unknown;

export const useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverride = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook
        >
      >
    >,
    TError,
    { data: InternalDeliveryDailyBudgetOverrideModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideHook
      >
    >
  >,
  TError,
  { data: InternalDeliveryDailyBudgetOverrideModelBody },
  TContext
> => {
  const mutationOptions =
    useInternalDeliveryDailyBudgetOverrideUpdateDailyBudgetOverrideMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
export const useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook =
  () => {
    const internalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverride =
      useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideMutator<void>();

    return (
      internalDeliveryDailyBudgetOverrideModelBody: InternalDeliveryDailyBudgetOverrideModelBody
    ) => {
      return internalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverride({
        url: `/api/InternalDeliveryDailyBudgetOverride/DeleteDailyBudgetOverride`,
        method: 'DELETE',
        headers: { 'Content-Type': 'application/json' },
        data: internalDeliveryDailyBudgetOverrideModelBody,
      });
    };
  };

export const useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideMutationOptions =
  <TError = unknown, TContext = unknown>(options?: {
    mutation?: UseMutationOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook
          >
        >
      >,
      TError,
      { data: InternalDeliveryDailyBudgetOverrideModelBody },
      TContext
    >;
  }): UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook
        >
      >
    >,
    TError,
    { data: InternalDeliveryDailyBudgetOverrideModelBody },
    TContext
  > => {
    const { mutation: mutationOptions } = options ?? {};

    const internalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverride =
      useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook();

    const mutationFn: MutationFunction<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook
          >
        >
      >,
      { data: InternalDeliveryDailyBudgetOverrideModelBody }
    > = (props) => {
      const { data } = props ?? {};

      return internalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverride(data);
    };

    return { mutationFn, ...mutationOptions };
  };

export type InternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook
        >
      >
    >
  >;
export type InternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideMutationBody =
  InternalDeliveryDailyBudgetOverrideModelBody;
export type InternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideMutationError =
  unknown;

export const useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverride = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook
        >
      >
    >,
    TError,
    { data: InternalDeliveryDailyBudgetOverrideModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<
        typeof useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideHook
      >
    >
  >,
  TError,
  { data: InternalDeliveryDailyBudgetOverrideModelBody },
  TContext
> => {
  const mutationOptions =
    useInternalDeliveryDailyBudgetOverrideDeleteDailyBudgetOverrideMutationOptions(
      options
    );

  return useMutation(mutationOptions);
};
