import SectionHeader from 'components/SectionHeader';
import useTranslations from 'contexts/basicData/useTranslations';
import styled from 'styled-components';
import { CaseExtendedModel, EventModel } from 'api';
import CommentInput from './CommentInput';
import Event from './Event';
import EmptyMessage from '../EmptyMessage';
import { useState } from 'react';
import { useDetailedCaseContext } from '../DetailedCaseProvider';
import { RequestStatus } from 'swaggerhooks/lib';
import useFilteredEvents, {
  commentEventFilter,
  rideUpdatesEventFilter,
} from './useFilteredEvents';
import EventsFilterPicker from './EventsFilterPicker';
import Button from 'components/inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { faComments } from '@fortawesome/free-regular-svg-icons';
import { useDetailedCaseFormControl } from '../DetailedCaseFormProvider';
import MarkAllCommentsAsReadButton from './MarkAllCommentsAsReadButton';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 2;
`;

const EventsScroll = styled.div`
  display: flex;
  flex-direction: column-reverse;
  overflow: auto;
`;

const BottomActions = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 0;
`;

const SectionHeaderIcon = styled(FontAwesomeIcon)`
  font-size: 0.8em;
  margin-bottom: 2px;
  margin-right: 2px;
`;

interface EventsAndCommentsProps {
  theCase?: CaseExtendedModel;
  events: EventModel[];
  className?: string;
}

const EventsAndComments: React.FC<EventsAndCommentsProps> = ({
  theCase,
  events,
  className,
}) => {
  const { onSaveEvent, onDeleteEvent, caseResponse, saveEventStatus } =
    useDetailedCaseContext();
  const { startEdit, editMode } = useDetailedCaseFormControl();
  const [editingEventId, setEditingEventId] = useState<number | null>(null);
  const translations = useTranslations();
  const {
    events: filteredEvents,
    selectedFilter,
    setSelectedFilter,
  } = useFilteredEvents(events);

  return (
    <Wrapper className={className}>
      <SectionHeader>
        <SectionHeaderIcon icon={faComments} /> Händelser &amp; kommentarer
      </SectionHeader>

      <EventsFilterPicker
        allEvents={events}
        onSelectFilter={setSelectedFilter}
        selectedFilter={selectedFilter}
      />

      <EventsScroll>
        {filteredEvents.map((event) => (
          <Event
            editMode={editingEventId === event.id}
            event={event}
            key={event.id}
            onCancelSave={() => setEditingEventId(null)}
            onDeleteClick={() => onDeleteEvent(event.id)}
            onEditClick={() => setEditingEventId(event.id)}
            onSave={onSaveEvent}
            onUpdate={() => {
              caseResponse.update();
            }}
            translations={translations}
          />
        ))}
        {!filteredEvents.length && (
          <EmptyMessage>
            Inga{' '}
            {!selectedFilter
              ? 'händelser eller kommentarer'
              : selectedFilter.name.toLowerCase()}
          </EmptyMessage>
        )}
      </EventsScroll>

      {theCase?.case !== undefined && (
        <BottomActions>
          {selectedFilter === rideUpdatesEventFilter &&
            theCase.case.newDataFromRide &&
            !editMode && (
              <Button onClick={() => startEdit(theCase)}>
                Fyll i uppdateringar <FontAwesomeIcon icon={faArrowRight} />
              </Button>
            )}

          {[null, commentEventFilter].includes(selectedFilter) && (
            <>
              <MarkAllCommentsAsReadButton events={filteredEvents} />
              <CommentInput
                loading={saveEventStatus === RequestStatus.Fetching}
                showInternalCommentOption
                onAddComment={(message, internalComment) =>
                  onSaveEvent(
                    theCase.case.caseID,
                    message,
                    undefined,
                    internalComment,
                    true
                  )
                }
              />
            </>
          )}
        </BottomActions>
      )}
    </Wrapper>
  );
};

export default EventsAndComments;
