import styled from 'styled-components';

export const CellNumberDivision = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;
  padding: 2px;
  justify-content: center;
`;

export const Grid = styled.div<{ columns: number; align?: 'left' }>`
  display: inline-grid;
  grid-template-columns: repeat(${({ columns }) => columns}, 1fr);
  grid-auto-rows: 20px;
  width: 100%;
  height: 100%;
  justify-items: ${({ align }) => (align === 'left' ? 'flex-start' : 'center')};
`;

export const TitleDate = styled.div<{
  flex: number;
  highlighted: boolean;
  isHoliday?: boolean;
}>`
  display: flex;
  flex: ${({ flex }) => flex};
  font-weight: bold;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: ${({ highlighted, theme }) =>
    highlighted
      ? theme.colors.foreground.tint
      : theme.colors.foreground.button};
  gap: 3px;

  ${({ isHoliday }) => isHoliday && 'color: red;'}

  & > span.weekday {
    font-size: 0.8em;
  }
  & > span.weekday {
    font-weight: normal;
  }
`;

// export const Button = styled.button`
//   background-color: transparent;
//   border: none;
//   padding: 0;
//   margin: 0;
//   cursor: pointer;
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   height: 100%;
//   width: 100%;

//   &:hover {
//     background-color: ${({ theme }) => theme.colors.background.secondary};
//     border-radius: ${({ theme }) => theme.sizes.radius.input}px;
//   }
// `;
