import useKeyPress from 'hooks/useKeyPress';
import React, { FC, useCallback, useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';

const modalStackAtom = atom<Map<string, React.ReactChild>>({
  key: 'ModalStack',
  default: new Map(),
});

let modalIdCounter = 0;

interface ModalStack {
  readonly stack: Map<string, React.ReactChild>;
  /**
   * Push a component to the modal stack, preferrably a <Modal />.
   * Returns a new modal id if none was provided.
   * Pushing a modal with an existing modaId will replace any current modals with the new modal.
   */
  push: (child: React.ReactChild, modalId?: string) => string;
  /**
   * Pop the topmost modal from the stack, if 'modalId' is provided, the corresponding modal will be removed from the stack.
   */
  pop: (modalId?: string) => void;
}

const useModalStack = (): ModalStack => {
  const [stack, setStack] = useRecoilState(modalStackAtom);

  const push = useCallback(
    (component: React.ReactChild, modalId?: string) => {
      if (modalId === undefined || modalId === null) {
        modalIdCounter += 1;
      }

      const newModalId = modalId ?? String(modalIdCounter);

      setStack((currentStack) => {
        const newStack = new Map(currentStack);
        newStack.set(newModalId, component);
        return newStack;
      });
      return newModalId;
    },
    [setStack]
  );

  const pop = useCallback(
    (modalId?: string) => {
      setStack((theStack) => {
        const newStack = new Map(theStack);

        let modalWasPopped = false;
        if (modalId === undefined) {
          const lastId = Array.from(newStack.keys())[newStack.size - 1];

          modalWasPopped = newStack.delete(lastId);
        } else {
          modalWasPopped = newStack.delete(modalId);
        }

        return modalWasPopped ? newStack : theStack;
      });
    },
    [setStack]
  );

  // Pop the topmost modal when the escape key is pressed.
  useKeyPress(() => {
    pop();
  }, ['Escape']);

  return useMemo(() => ({ stack, push, pop }), [stack, push, pop]);
};

export const RenderModalStack: FC = () => {
  const { stack } = useModalStack();
  // eslint-disable-next-line react/jsx-no-useless-fragment
  const stackKeys = Array.from(stack.keys());
  return <>{stack.get(stackKeys[stackKeys.length - 1])}</>;
};

export default useModalStack;
