import SectionHeader from 'components/SectionHeader';
import styled from 'styled-components';
import { ExpenseModel } from 'api';
import ExpenseViewer from './Expense';
import EmptyMessage from '../EmptyMessage';
import { useState } from 'react';
import Modal from 'components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCoins, faTrash } from '@fortawesome/free-solid-svg-icons';
import ExpenseEditor from './ExpenseEditor';
import { useDetailedCaseContext } from '../DetailedCaseProvider';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  flex: 1;
`;

const SectionHeaderIcon = styled(FontAwesomeIcon)`
  font-size: 0.8em;
  margin-bottom: 2px;
  margin-right: 2px;
`;

const EventsScroll = styled.div`
  flex: 1;
  overflow: auto;
`;

interface EventsAndCommentsProps {
  expenses?: ExpenseModel[];
  className?: string;
  updateCase(): void;
}

const Expenses: React.FC<EventsAndCommentsProps> = ({
  expenses,
  className,
}) => {
  const { onSaveExpense, onDeleteExpense } = useDetailedCaseContext();
  const [editExpense, setEditExpense] = useState<number | null>(null);
  const [confirmDeleteExpense, setConfirmDeleteExpense] =
    useState<ExpenseModel | null>(null);

  return (
    <Wrapper className={className}>
      <SectionHeader>
        <SectionHeaderIcon icon={faCoins} /> Utgifter
      </SectionHeader>

      <EventsScroll>
        {expenses?.map((expense) =>
          editExpense === expense.id ? (
            <ExpenseEditor
              expense={expense as ExpenseModel}
              key={expense.id}
              onCancel={() => setEditExpense(null)}
              onSaveExpense={onSaveExpense}
            />
          ) : (
            <ExpenseViewer
              expense={expense as ExpenseModel}
              key={expense.id}
              onDeleteExpense={setConfirmDeleteExpense}
              onEditExpense={setEditExpense}
            />
          )
        )}
        {!expenses?.length && <EmptyMessage>Inga utgifter</EmptyMessage>}
      </EventsScroll>

      {confirmDeleteExpense && (
        <Modal
          buttons={[
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              label: 'Ta bort utgift',
              onClick: () => {
                onDeleteExpense(confirmDeleteExpense);
                setConfirmDeleteExpense(null);
              },
            },
            { label: 'Avbryt', onClick: () => setConfirmDeleteExpense(null) },
          ]}
          onClose={() => setConfirmDeleteExpense(null)}
          title="Ta bort utgift?"
        >
          Är du säker på att du vill ta bort utgift från{' '}
          {confirmDeleteExpense.modifiedBy} på {confirmDeleteExpense.value}kr?
          {confirmDeleteExpense.externalReceiptLineItemID !== null && (
            <span>
              <br />
              Det länkade kvittot kommer att kunna kopplas till en annan utgift.
            </span>
          )}
        </Modal>
      )}
    </Wrapper>
  );
};

export default Expenses;
