import { FC, useCallback, useEffect, useRef } from 'react';

import { useLocation, useNavigate, useParams } from 'react-router-dom';

import DetailedCase from 'components/DetailedCase';
import Modal, { ModalButton } from 'components/Modal';
import { RequestStatus } from 'swaggerhooks/lib';
import { CaseStatusEnum } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import useTranslations from 'contexts/basicData/useTranslations';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { useDetailedCaseFormControl } from 'components/DetailedCase/DetailedCaseFormProvider';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import DetailedCaseProvider, {
  useDetailedCaseContext,
} from 'components/DetailedCase/DetailedCaseProvider';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import useModalStack from 'contexts/modal/useModalStack';
import Roles from 'constants/Roles';
// import TextArea from 'components/inputs/TextArea';
// import { useForm } from 'react-hook-form';
// import { formatDateTime } from 'utils/date-helpers';
// import styled from 'styled-components';

// const CaseDescription = styled.div`
//   display: flex;
//   flex-direction: column;
//   gap: 5px;
//   padding-top: 3px;
//   padding-bottom: 10px;
//   border-top: 1px solid ${({ theme }) => theme.colors.border.primary};
// `;

interface ConfirmModifyingInvoicedCaseModalProps {
  onConfirm(): void;
  onCancel(): void;
}
const ConfirmModifyingInvoicedCaseModal: FC<
  ConfirmModifyingInvoicedCaseModalProps
> = ({ onConfirm, onCancel }) => {
  return (
    <Modal
      buttons={[
        {
          label: 'Redigera ändå',
          onClick: onConfirm,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      title="Uppdraget är redan fakturerat"
    >
      Är du säker på att du vill redigera ärendet?
    </Modal>
  );
};

type LinkedTradeInWillAutoUpdateProps = {
  onConfirm(): void;
};
const LinkedTradeInWillAutoUpdate: FC<LinkedTradeInWillAutoUpdateProps> = ({
  onConfirm,
}) => {
  const modalStack = useModalStack();
  const handleClose = () => {
    modalStack.pop();
  };
  return (
    <Modal
      buttons={[
        {
          label: 'Avbryt',
          onClick: handleClose,
        },
        {
          label: 'Okej',
          onClick: onConfirm,
        },
      ]}
      onClose={handleClose}
      title="Inbytet kommer att auto-uppdateras."
    >
      Det kopplade inbytet kommer att uppdateras med samma fälttestare som detta
      uppdrag.
    </Modal>
  );
};

interface CaseModalProps {
  navigatesBackTo?: string;
  onClose?(): void;
  assignmentIdForMarkingAsHandled?: number;
}

export const CaseModal: FC<CaseModalProps> = ({
  navigatesBackTo,
  onClose,
  assignmentIdForMarkingAsHandled,
}) => {
  const modalStack = useModalStack();
  const location = useLocation();
  const { triggerAssignmentsUpdate } = useAssignmentsFilters();
  const { triggerAssignmentsUpdate: triggerSearchUpdate } =
    useAssignmentSearch();
  const {
    editMode,
    // getNewOrUpdatedCase,
    save,
    checkForCommentsNeeded,
    checkIfConnectedTradeInWillAutoUpdate: checkForConnectedTradeIn,
    startEdit,
    cancelEdit,
    updateCaseStatus,
    hasErrors,
    // sendCommentToFieldTester,
  } = useDetailedCaseFormControl();
  const previousEditMode = useRef<boolean>(editMode);
  const {
    caseResponse,
    isUpdatingAssignmentStatus,
    onCloseCase,
    closeCaseStatus,
    saveExpenseStatus,
    deleteExpenseStatus,
  } = useDetailedCaseContext();
  const navigate = useNavigate();
  const { caseTypes } = useTranslations();
  // const { control: commentsForm, reset } = useForm();
  const initialOpen = useRef(true);

  useEffect(() => {
    // If location (useLocation) includes the isFromInNeedOfHandling parameter
    // do this only when case is opened
    if (
      initialOpen.current &&
      location.state?.isFromInNeedOfHandling &&
      caseResponse.response
    ) {
      initialOpen.current = false;
      startEdit(caseResponse.response);
    }
  }, [caseResponse.response, location, startEdit]);

  // Track the start and end of editing a case
  useEffect(() => {
    previousEditMode.current = editMode;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editMode]);

  // const handleSubmitComments = commentsForm.handleSubmit(async (data) => {
  //   const commentsNeeded = checkForCommentsNeeded();

  //   if (Object.keys(commentsNeeded).length > 0) {
  //     // Send comments to field tester
  //     if (commentsNeeded) {
  //       Object.keys(commentsNeeded).forEach(async (key) => {
  //         await sendCommentToFieldTester(
  //           caseResponse.response!.case.caseID,
  //           `${commentsNeeded[Number(key)].join(
  //             ', '
  //           )} har ändrats. Anledning: ${data[key]}`,
  //           Number(key)
  //         );
  //       });
  //     }

  //     reset();

  //     modalStack.pop();

  //     await save();
  //     await caseResponse.update();
  //     triggerAssignmentsUpdate(new Date());
  //     triggerSearchUpdate(new Date());
  //   }
  // });

  const handleClose = useCallback(() => {
    if (navigatesBackTo) {
      navigate(navigatesBackTo);
    } else if (onClose) {
      onClose();
    }
  }, [navigate, navigatesBackTo, onClose]);

  const handleSaveClick = useCallback(async () => {
    // check if we are updating a homedelivery case with a tradein (and hence will auto-update the assignee if relevant)
    const connectedTradeInWillAutoUpdate = await checkForConnectedTradeIn();
    if (connectedTradeInWillAutoUpdate) {
      modalStack.push(
        <LinkedTradeInWillAutoUpdate
          onConfirm={async () => {
            modalStack.pop();
            await save();
            await caseResponse.update();
            triggerAssignmentsUpdate(new Date());
            triggerSearchUpdate(new Date());
          }}
        />
      );
    } else {
      // fånga ev uppdateringskommentarer som ska ut till fälttestaren
      const commentsNeeded = checkForCommentsNeeded();
      if (Object.keys(commentsNeeded).length > 0) {
        // JF 2023-12-07: utkommenterat pga orsakade concurrency-problem ("En nyare version av det här objektet finns redan sparat. Avbryt och försök igen.")
        // modalStack.push(
        //   <Modal
        //     buttons={[
        //       {
        //         label: 'Spara',
        //         onClick: async () => {
        //           handleSubmitComments();
        //         },
        //       },
        //       {
        //         label: 'Avbryt',
        //         onClick: () => {
        //           reset();
        //           modalStack.pop();
        //         },
        //       },
        //     ]}
        //     onClose={() => {
        //       reset();
        //       modalStack.pop();
        //     }}
        //     title="Kommentarer behövs"
        //   >
        //     <form onSubmit={handleSubmitComments}>
        //       <span>
        //         Följande uppdateringar kräver kommentar till fälttestaren:
        //       </span>
        //       {Object.keys(commentsNeeded).map((key) => (
        //         <div key={key}>
        //           <p style={{ padding: 15, paddingLeft: 0 }}>
        //             <i>{commentsNeeded[Number(key)].join(', ')}</i>
        //           </p>
        //           <CaseDescription>
        //             <p>
        //               {`Uppdrag: ${formatDateTime(
        //                 getNewOrUpdatedCase().caseModel.assignmentList.find(
        //                   (assignment) =>
        //                     assignment.assignmentID === Number(key)
        //                 )?.startTime
        //               )} - ${formatDateTime(
        //                 getNewOrUpdatedCase().caseModel.assignmentList.find(
        //                   (assignment) =>
        //                     assignment.assignmentID === Number(key)
        //                 )?.endTime
        //               )}`}
        //             </p>
        //             <p>
        //               {`Fälttestare: ${getNewOrUpdatedCase().caseModel.assignmentList.find(
        //                 (assignment) => assignment.assignmentID === Number(key)
        //               )?.assignedTo?.name}`}
        //             </p>
        //           </CaseDescription>
        //           <TextArea
        //             placeholder="Anledning"
        //             style={{
        //               minHeight: '80px',
        //             }}
        //             width={400}
        //             {...commentsForm.register(key, {
        //               required: true,
        //             })}
        //           />
        //         </div>
        //       ))}
        //     </form>
        //   </Modal>
        // );
        // return;
      }
      // proceed immediately
      await save();
      await caseResponse.update();
      triggerAssignmentsUpdate(new Date());
      triggerSearchUpdate(new Date());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseResponse.update, save]);

  const caseIsBeingUpdated =
    [updateCaseStatus, saveExpenseStatus, deleteExpenseStatus].includes(
      RequestStatus.Fetching
    ) ||
    isUpdatingAssignmentStatus ||
    closeCaseStatus === 'loading';

  const getButtons = (): ModalButton[] | undefined => {
    if (caseResponse.status === RequestStatus.Fetched && !caseIsBeingUpdated) {
      if (editMode || !caseResponse.response) {
        return [
          {
            icon: <FontAwesomeIcon icon={faSave} />,
            label: 'Spara',
            disabled: hasErrors,
            onClick: () => {
              handleSaveClick();
            },
            roles: [Roles.Admin],
          },
          {
            label: 'Avbryt',
            onClick: () => cancelEdit(),
          },
        ];
      }

      return [
        {
          icon: <FontAwesomeIcon icon={faEdit} />,
          label: 'Redigera',
          onClick: () => {
            if (Number.isInteger(caseResponse.response?.case.invoiceDataID)) {
              // this case has already been invoiced. show a warning;
              modalStack.push(
                <ConfirmModifyingInvoicedCaseModal
                  onCancel={() => modalStack.pop()}
                  onConfirm={() => {
                    modalStack.pop();
                    startEdit(caseResponse.response!);
                  }}
                />
              );
            } else {
              startEdit(caseResponse.response!);
            }
          },
          roles: [Roles.Admin],
        },
        ...(caseResponse.response.case.caseStatusID !== CaseStatusEnum.Closed
          ? [
              {
                icon: <FontAwesomeIcon icon={faTrash} />,
                label: 'Stäng ärende',
                onClick: () => onCloseCase(caseResponse.response!.case.caseID),
                roles: [Roles.Admin],
              },
            ]
          : []),
      ];
    }
    return undefined;
  };

  const renderBody = () => {
    switch (caseResponse.status) {
      case RequestStatus.Idle:
      case RequestStatus.Fetching:
        return <LoadingSpinner>Hämtar uppdrag...</LoadingSpinner>;

      case RequestStatus.Fetched:
        if (caseResponse.response !== undefined) {
          if (caseIsBeingUpdated) {
            return <LoadingSpinner>Sparar uppdrag...</LoadingSpinner>;
          }

          return (
            <DetailedCase
              autoFocusAssignmentID={location.state?.assignmentId}
              buttons={getButtons()}
              assignmentIdForMarkingAsHandled={assignmentIdForMarkingAsHandled}
            />
          );
        }
        return null;

      case RequestStatus.Error:
      default:
        return <ErrorTryAgain onTryAgain={() => caseResponse.update()} />;
    }
  };

  return (
    <Modal
      closeOnOutsideClick={false}
      onClose={handleClose}
      title={`${
        (caseResponse.response &&
          caseTypes[caseResponse.response.case.caseTypeID]) ||
        'Uppdrag'
      }${
        Number.isInteger(caseResponse.response?.case.invoiceDataID)
          ? ' – FAKTURERAT'
          : ''
      }`}
    >
      {renderBody()}
    </Modal>
  );
};

const CasePage: FC<CaseModalProps> = (props) => {
  const params = useParams<{ id: string }>();
  const caseId = Number(params.id);

  return (
    <DetailedCaseProvider caseId={Number.isNaN(caseId) ? undefined : caseId}>
      <CaseModal {...props} />
    </DetailedCaseProvider>
  );
};

export default CasePage;
