import React from 'react';
import TextArea from './TextArea';

function textAreaAdjust(e: HTMLTextAreaElement | null) {
  if (e) {
    e.style.height = '1px';
    e.style.height = `${e.scrollHeight + 10}px`;
  }
}

type Props = React.HTMLProps<HTMLTextAreaElement>;
const AutoSizedTextArea: React.FC<Props> = (props) => (
  <TextArea
    {...props}
    as={undefined}
    onKeyUp={(eve) => {
      if (eve.target instanceof HTMLTextAreaElement) textAreaAdjust(eve.target);
    }}
    ref={textAreaAdjust}
  />
);

export default AutoSizedTextArea;
