import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AdvancedAssignmentFilterOption } from 'api/model';
import Dropdown from 'components/Dropdown';
import Button from 'components/inputs/Button';
import MultiSelect, { Option } from 'components/inputs/MultiSelect';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits';
import useTranslations from 'contexts/basicData/useTranslations';
// import { StyledMultiSelect } from 'pages/InternalDeliveryGroup/components/filterBars';
import React, { useMemo } from 'react';
import styled, { css } from 'styled-components';

const FilterWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 10px;
  padding: 10px;
  background: ${({ theme }) => theme.colors.background.primary};
  border: 1px solid ${({ theme }) => theme.colors.border.light};
`;

// circular badge
const FilterSpan = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.foreground.button};
  color: ${({ theme }) => theme.colors.background.button};
  font-size: 0.8em;
  font-weight: bold;
`;

const MyButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  white-space: nowrap;
  height: 32px;
`;

const StyledMultiSelect = styled(MultiSelect)`
  padding: 8px;
  ${({ value, theme }) =>
    value.size > 0 &&
    css`
      background: ${theme.colors.background.highlight};
    `}
` as typeof MultiSelect;

type Props = {
  showAdvancedFilters: boolean;
};

const AssignmentFiltersDropdown: React.FC<Props> = ({
  showAdvancedFilters,
}) => {
  const { unitsById } = useBusinessUnits();
  const {
    advancedAssignmentFilterOptions:
      advancedAssignmentFilterOptionsTranslations,
  } = useTranslations();
  const {
    state: { visibleBusinessUnits, advancedFilters },
    setVisibleBusinessUnits,
    setAdvancedFilters,
  } = useAssignmentsFilters();

  const businessUnitOptions = useMemo((): Option<number>[] => {
    return Array.from(unitsById.values()).map((unit) => ({
      label: unit.name,
      value: unit.id,
    }));
  }, [unitsById]);

  const advancedAssignmentFilterOptions = useMemo(
    (): Option<AdvancedAssignmentFilterOption>[] =>
      Object.entries(advancedAssignmentFilterOptionsTranslations ?? {}).map(
        ([status, name]) => {
          const option = {
            label: name as string,
            value: parseInt(status, 10) as AdvancedAssignmentFilterOption,
          };
          return option;
        }
      ),
    [advancedAssignmentFilterOptionsTranslations]
  );

  const numberOfFilters = useMemo(() => {
    const count = visibleBusinessUnits.length + (advancedFilters?.size ?? 0);
    return count;
  }, [advancedFilters?.size, visibleBusinessUnits.length]);

  const [filtersOpen, setFiltersOpen] = React.useState(false);
  return (
    <Dropdown
      onLostFocus={() => setFiltersOpen(false)}
      position="left"
      content={
        filtersOpen && (
          <FilterWrapper>
            <StyledMultiSelect
              onChange={(units) => setVisibleBusinessUnits(Array.from(units))}
              options={businessUnitOptions}
              placeholder="Välj enhet"
              value={new Set(visibleBusinessUnits)}
            />
            {showAdvancedFilters && (
              <StyledMultiSelect
                onChange={(newValue) => setAdvancedFilters(newValue)}
                options={advancedAssignmentFilterOptions}
                placeholder="Avancerat"
                position="left"
                value={new Set(advancedFilters)}
              />
            )}
            <MyButton
              disabled={numberOfFilters === 0}
              onClick={() => {
                setVisibleBusinessUnits([]);
                setAdvancedFilters(new Set());
              }}
            >
              Rensa
            </MyButton>
          </FilterWrapper>
        )
      }
    >
      <MyButton
        icon={<FontAwesomeIcon icon={faFilter} />}
        onClick={() => setFiltersOpen(!filtersOpen)}
      >
        <span>Filter </span>
        {numberOfFilters > 0 && <FilterSpan>{numberOfFilters}</FilterSpan>}
      </MyButton>
    </Dropdown>
  );
};
export default AssignmentFiltersDropdown;
