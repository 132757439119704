import { FC, useEffect } from 'react';
import { BusinessUnitGroupModel } from 'api';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';

import styled from 'styled-components';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { arraySpreadIf } from 'utils/spreading';
import LabelWrap from 'components/inputs/LabelWrap';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import {
  useBusinessUnitCreateBusinessUnitGroup,
  useBusinessUnitUpdateBusinessUnitGroup,
} from 'api/business-unit/business-unit';
import Checkbox from 'components/inputs/Checkbox';

const FormWrap = styled.div<{ maxWidth?: number; compact?: boolean }>`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  gap: ${({ compact }) => (compact ? '0' : '10px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;

const MyLabelWrap = styled(LabelWrap)<{ width?: number }>`
  & > *:last-child {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
    max-height: 100%;
  }
`;

interface Props {
  group?: BusinessUnitGroupModel;
  onCancel?(): void;
  onSaved?(): void;
  canEdit?: boolean;
  title?: string;
}

const BusinessUnitGroupFormModal: FC<Props> = ({
  group,
  onCancel,
  onSaved,
  canEdit,
  title,
}) => {
  const { register, handleSubmit, setValue } =
    useForm<BusinessUnitGroupModel>();

  // Populate form with area data on first render
  useEffect(() => {
    if (group) {
      setValue('id', group.id);
      setValue('name', group.name);
      setValue('description', group.description);
      setValue('isTruckTransport', group.isTruckTransport);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const createMode = !group;

  const createCall = useBusinessUnitCreateBusinessUnitGroup({
    mutation: {
      onSuccess: () => {
        onSaved?.();
      },
    },
  });

  const updateCall = useBusinessUnitUpdateBusinessUnitGroup({
    mutation: {
      onSuccess: () => {
        onSaved?.();
      },
    },
  });

  const handleCreateClick = handleSubmit(async (data) => {
    createCall.mutate({ data });
  });

  const handleUpdateClick = handleSubmit(async (data) => {
    if (!data) return;

    updateCall.mutate({ data });
  });

  const handleCancelClick = () => {
    onCancel?.();
  };

  const saveStatus = createMode ? createCall.status : updateCall.status;

  const renderContent = () => {
    switch (saveStatus) {
      case 'idle':
      case 'success':
      case 'error':
        return (
          <FormWrap compact>
            <MyLabelWrap label="Namn" width={270}>
              <Input
                {...register('name', { required: true })}
                disabled={!canEdit}
                placeholder="Namn"
              />
            </MyLabelWrap>
            <MyLabelWrap label="Beskrivning" width={270}>
              <TextArea
                {...register('description', { required: true })}
                disabled={!canEdit}
                placeholder="Beskrivning"
              />
            </MyLabelWrap>

            <MyLabelWrap label="Endast lastbilskörningar" width={270}>
              <Checkbox
                {...register('isTruckTransport', { required: true })}
                disabled={!canEdit}
              >
                Endast lastbilskörningar
              </Checkbox>
            </MyLabelWrap>
          </FormWrap>
        );

      case 'loading':
      default:
        return <LoadingSpinner>Sparar...</LoadingSpinner>;
    }
  };

  return (
    <Modal
      buttons={
        saveStatus === 'loading'
          ? []
          : [
              ...arraySpreadIf(!!canEdit, {
                label: createMode ? 'Skapa grupp' : 'Uppdatera grupp',
                onClick: createMode ? handleCreateClick : handleUpdateClick,
              }),
              {
                label: canEdit ? 'Avbryt' : 'Stäng',
                onClick: handleCancelClick,
              },
            ]
      }
      onClose={handleCancelClick}
      title={
        title !== undefined
          ? title
          : createMode
            ? 'Skapa enhet'
            : 'Redigera enhet'
      }
    >
      {renderContent()}
    </Modal>
  );
};

export default BusinessUnitGroupFormModal;
