import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentClient, DetailedStandardizedCommentDto } from 'api';
import Button from 'components/inputs/Button';
import Modal from 'components/Modal';
import Table from 'components/Table';
import { ColumnSetting, makeColumnClassName } from 'components/Table/utils';
import useModalStack from 'contexts/modal/useModalStack';
import useStandardizedComments, {
  useRefreshStandardizedCommentsCall,
} from 'contexts/standardizedComments/useStandardizedComments';
import styled from 'styled-components';
import { useApiCall } from 'swaggerhooks/lib';
import AddStandardizedCommentModal from './AddStandardizedCommentModal';
import useTranslations from 'contexts/basicData/useTranslations';

const Wrapper = styled.div``;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;

  button {
    padding: 0 10px;
    height: 100%;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const MyTable = styled(Table)`
  .${makeColumnClassName(0)} {
    padding-left: 10px;
  }
` as typeof Table;

interface Test {
  blah: string;
}

const StandardizedCommentsSettingsPage = () => {
  const translations = useTranslations();
  const standardizedComments = useStandardizedComments();
  const refreshComments = useRefreshStandardizedCommentsCall();
  const modalStack = useModalStack();

  const deleteApiCall = useApiCall(
    AssignmentClient,
    (c, standardizedCommentId: number) =>
      c.deleteStandardizedComment(standardizedCommentId)
  );

  const handleConfirmDelete = async (standardizedCommentId: number) => {
    await deleteApiCall.run(standardizedCommentId);
    refreshComments();
  };

  const handleDeleteClick = async (standardizedCommentId: number) => {
    modalStack.push(
      <Modal
        buttons={[
          {
            label: 'Ta bort',
            onClick: () => {
              handleConfirmDelete(standardizedCommentId);
              modalStack.pop();
            },
          },
          {
            label: 'Avbryt',
            onClick: () => modalStack.pop(),
          },
        ]}
        title="Ta bort kommentar"
      >
        <div>Är du säker på att du vill ta bort kommentaren?</div>
      </Modal>
    );
  };

  const columnSettings: ColumnSetting<DetailedStandardizedCommentDto, Test>[] =
    [
      {
        head: 'Visningsnamn',
        render: (z) => z.displayName,
        width: 10,
        sortFunction: (a, b) => (a.displayName > b.displayName ? 1 : -1),
      },
      {
        head: 'Kommentar',
        render: (z) => z.comment,
        width: 10,
      },
      {
        head: 'Länkad utgiftstyp',
        render: (z) =>
          z.linkedExpenseType
            ? translations.expenseTypes[z.linkedExpenseType]
            : '',
        width: 10,
      },
      {
        head: '',
        render: (m) => (
          <IconWrapper>
            <FontAwesomeIcon
              icon={faTrash}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                handleDeleteClick(m.id);
              }}
            />
          </IconWrapper>
        ),
        width: 10,
      },
      {
        head: '',
        render: () => <div style={{ width: 10 }} />,
        width: 10,
      },
    ];

  return (
    <Wrapper>
      <Actions>
        <Button
          onClick={() =>
            modalStack.push(
              <AddStandardizedCommentModal
                onCancel={() => modalStack.pop()}
                onSaved={() => {
                  modalStack.pop();
                  refreshComments();
                }}
              />
            )
          }
        >
          + Ny kommentar
        </Button>
      </Actions>

      <MyTable
        columnSettings={columnSettings}
        // onRowClick={(r) => {
        //   console.log('r: ', r);
        // }}
        renderProps={{ blah: '' }}
        rows={standardizedComments}
        useColumnWidthAsFlexAmount
      />
    </Wrapper>
  );
};

export default StandardizedCommentsSettingsPage;
