import {
  AssignmentModel,
  AssignmentTypeEnum,
  CaseClient,
  CaseEventType,
  CaseExtendedModel,
  CaseModel,
  CaseTypeEnum,
} from 'api';
import useCaseAssignmentForms, {
  assignmentFormToAssignment,
} from './useCaseAssignmentForms';
import useCaseDataForm, {
  caseDataFormToCaseModel,
  initializeCaseDataForm,
} from './useCaseDataForm';
import {
  createContext,
  Suspense,
  useCallback,
  useContext,
  useMemo,
  useRef,
  useState,
} from 'react';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import useUsers from 'contexts/basicData/useUsers';
import useTranslations from 'contexts/basicData/useTranslations';
import useCompanies from 'contexts/basicData/useCompanies';
import { useCopyCaseValue } from 'contexts/basicData/useCopyCase';
import { useCaseGetCasesByRideOrderId } from 'api/case/case';

const CaseDataFormContext = createContext<
  ReturnType<typeof useCaseDataForm> | undefined
>(undefined);

const CaseAssignmentFormContext = createContext<
  ReturnType<typeof useCaseAssignmentForms> | undefined
>(undefined);

interface Controls {
  save: () => Promise<[caseId: number | null, error: null | unknown]>;
  startEdit(c: CaseExtendedModel | null, caseType?: CaseTypeEnum): void;
  checkForOverlaps(): Promise<CaseModel[]>;
  checkForCommentsNeeded: () => Record<number, string[]>;
  checkIfConnectedTradeInWillAutoUpdate(): Promise<boolean | null>;
  cancelEdit(): void;
  copy(): void;
  editMode: boolean;
  getNewOrUpdatedCase: () => {
    // Exposed for the comments needed modal form, in order to present updated case info
    caseModel: CaseModel;
  };
  hasErrors: boolean;
  createCaseStatus: RequestStatus;
  updateCaseStatus: RequestStatus;
  sendCommentToFieldTester: (
    caseModel: number,
    comment: string,
    assignmentId: number
  ) => Promise<unknown>;
}
const ControlsContext = createContext<Controls | undefined>(undefined);

const DetailedCaseFormProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { zipCodeAreas } = useTranslations();
  const usersById = useUsers();
  const { companies } = useCompanies();
  const [controlsState, setControlsState] = useState({
    /** if null, create a new case */
    caseBeingEdited: null as CaseExtendedModel | null,
    editMode: false,
  });
  const caseDataForm = useCaseDataForm();
  const caseAssignmentForms = useCaseAssignmentForms();
  const caseCopy = useCopyCaseValue();

  const { run: createCaseCall, status: createCaseStatus } = useApiCall(
    CaseClient,
    (c, caseModel: CaseModel) => c.createCase(caseModel)
  );
  const { run: updateCaseCall, status: updateCaseStatus } = useApiCall(
    CaseClient,
    (c, caseModel: CaseModel) => {
      return c.updateCase(
        caseModel,
        CaseEventType.CaseEdited,
        'Ärende redigerades'
      );
    }
  );

  const { mutateAsync: getCasesByRideOrderId } = useCaseGetCasesByRideOrderId();

  const { run: checkForOverlapsCall } = useApiCall(
    CaseClient,
    (c, caseModel: CaseModel) => {
      return c.getCaseOverlaps(caseModel);
    }
  );

  const { run: sendCommentToFieldTester } = useApiCall(
    CaseClient,
    (c, caseModelID: number, comment: string, assignmentId: number) => {
      return c.addOrUpdateComment(
        caseModelID,
        comment,
        null,
        false,
        null,
        assignmentId
      );
    }
  );

  const caseDataFormRef = useRef(caseDataForm);
  caseDataFormRef.current = caseDataForm;
  const caseAssignmentFormsRef = useRef(caseAssignmentForms);
  caseAssignmentFormsRef.current = caseAssignmentForms;

  const handleStartEdit = useCallback(
    (caseExtended: CaseExtendedModel | null, caseType?: CaseTypeEnum) => {
      caseAssignmentFormsRef.current.cancelAllEdits();

      // Initialize case form
      caseDataFormRef.current.setMultiple(
        initializeCaseDataForm(caseExtended?.case, caseType)
      );

      if (!caseExtended) {
        // start a new assignment if the case is also new
        caseAssignmentFormsRef.current.startEdit(
          null,
          caseType === CaseTypeEnum.Purchase
            ? AssignmentTypeEnum.WithTest
            : undefined
        );
      } else {
        // Open all assignments for edit
        caseExtended.case.assignmentList.forEach((assignment) =>
          caseAssignmentFormsRef.current.startEdit(assignment)
        );
      }

      setControlsState({
        caseBeingEdited: caseExtended,
        editMode: true,
      });
    },
    []
  );

  const handleCancelEdit = useCallback(() => {
    caseAssignmentForms.cancelAllEdits();
    setControlsState({ editMode: false, caseBeingEdited: null });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseAssignmentForms.cancelAllEdits]);

  /**
   * Returns the current version of the case as edited by the user
   */
  const getNewOrUpdatedCase = useCallback((): {
    caseModel: CaseModel;
    fieldsInNeedOfExtraInfo: Record<number, string[]>;
  } => {
    const newOrUpdatedCase = caseDataFormToCaseModel(
      controlsState.caseBeingEdited?.case,
      caseDataFormRef.current.state,
      companies
    );
    const fieldsInNeedOfExtraInfo: Record<number, string[]> = {};
    // Update assignments inside the case
    Object.entries(caseAssignmentFormsRef.current.state).forEach(
      ([assignmentIdStr, assignmentForm]) => {
        const assignmentId = Number(assignmentIdStr);
        const caseAssignmentIndex = newOrUpdatedCase.assignmentList.findIndex(
          (a) => a.assignmentID === assignmentId
        );
        const caseAssignment = newOrUpdatedCase.assignmentList[
          caseAssignmentIndex
        ] as AssignmentModel | undefined;

        const updatedOrNewAssignment = assignmentFormToAssignment(
          caseAssignment,
          assignmentForm,
          usersById,
          zipCodeAreas,
          newOrUpdatedCase.caseTypeID
        );

        if (caseAssignmentIndex > -1) {
          // found in list, replace it
          newOrUpdatedCase.assignmentList[caseAssignmentIndex] =
            updatedOrNewAssignment;

          // Check if we need to send a comment to the field tester
          if (
            updatedOrNewAssignment.startTime?.getTime() !==
            caseAssignment?.startTime?.getTime()
          ) {
            if (!fieldsInNeedOfExtraInfo[assignmentId]) {
              fieldsInNeedOfExtraInfo[assignmentId] = ['Starttid'];
            } else {
              fieldsInNeedOfExtraInfo[assignmentId].push('Starttid');
            }
          }
          if (
            updatedOrNewAssignment.endTime?.getTime() !==
            caseAssignment?.endTime?.getTime()
          ) {
            if (!fieldsInNeedOfExtraInfo[assignmentId]) {
              fieldsInNeedOfExtraInfo[assignmentId] = ['Sluttid'];
            } else {
              fieldsInNeedOfExtraInfo[assignmentId].push('Sluttid');
            }
          }
        } else if (updatedOrNewAssignment.assignmentID === -1) {
          // not found in list, append it if it's a new assignment (id === -1)
          newOrUpdatedCase.assignmentList.push(updatedOrNewAssignment);
        }
      }
    );

    return {
      caseModel: newOrUpdatedCase,
      fieldsInNeedOfExtraInfo,
    };
  }, [companies, controlsState.caseBeingEdited?.case, usersById, zipCodeAreas]);

  /**
   * Check for overlapping assignments
   */
  const checkForOverlaps = useCallback(async (): Promise<CaseModel[]> => {
    const { caseModel: newOrUpdatedCase } = getNewOrUpdatedCase();

    const [overlappingCases] = await checkForOverlapsCall(newOrUpdatedCase);
    return overlappingCases ?? [];
  }, [checkForOverlapsCall, getNewOrUpdatedCase]);

  /**
   * Check for comments needed
   */
  const checkForCommentsNeeded = useCallback(() => {
    const { fieldsInNeedOfExtraInfo } = getNewOrUpdatedCase();

    return fieldsInNeedOfExtraInfo;
  }, [getNewOrUpdatedCase]);

  /**
   * Saves the case
   */
  const handleSave = useCallback(async (): Promise<
    [caseId: number | null, error: null | unknown]
  > => {
    const { caseModel: newOrUpdatedCase } = getNewOrUpdatedCase();

    let caseId: number | null;
    let error: null | unknown;

    if (
      controlsState.caseBeingEdited &&
      controlsState.caseBeingEdited?.case.caseID !== -1
    ) {
      const [, e] = await updateCaseCall(newOrUpdatedCase);
      //
      error = e;
      caseId = error ? null : controlsState.caseBeingEdited?.case.caseID ?? -1;
    } else {
      [caseId, error] = await createCaseCall(newOrUpdatedCase);
    }

    if (!error) {
      setControlsState((s) => ({ ...s, editMode: false }));
      caseAssignmentFormsRef.current.cancelAllEdits();
    }

    return [caseId, error];
  }, [
    controlsState.caseBeingEdited,
    createCaseCall,
    updateCaseCall,
    getNewOrUpdatedCase,
  ]);

  const handleCopy = useCallback(() => {
    if (caseCopy) {
      caseAssignmentFormsRef.current.cancelAllEdits();

      // Initialize case form
      caseDataFormRef.current.setMultiple(
        initializeCaseDataForm(caseCopy, caseCopy.caseTypeID)
      );

      caseAssignmentFormsRef.current.startEdit(
        caseCopy.assignmentList[0],
        caseCopy.caseTypeID === CaseTypeEnum.Purchase
          ? AssignmentTypeEnum.WithTest
          : undefined
      );

      setControlsState({
        caseBeingEdited: CaseExtendedModel.fromJS({ case: caseCopy }),
        editMode: true,
      });
    }
  }, [caseCopy]);

  /**
   * Check for overlapping assignments
   */
  const checkIfConnectedTradeInWillAutoUpdate =
    useCallback(async (): Promise<boolean> => {
      const { caseModel: newOrUpdatedCase } = getNewOrUpdatedCase();
      // if the current case type is not HomeDelivery or has more than one assignment (makes it difficult to auto-assign),
      // or has no linked cases we don't need to check for connected trade in
      if (
        newOrUpdatedCase.caseTypeID !== CaseTypeEnum.HomeDelivery ||
        !newOrUpdatedCase.hasLinkedCases ||
        newOrUpdatedCase.assignmentList.length > 1
      ) {
        return false;
      }

      // if the current case type is HomeDelivery, we need to check for connected trade in
      const connectedCases = await getCasesByRideOrderId({
        params: {
          RideOrderId: newOrUpdatedCase.rideOrderID,
        },
      });

      const connectedTradeIn = connectedCases?.find(
        (c) => c.caseTypeID === CaseTypeEnum.TradeIn
      );

      // return true if the connected trade in has only one assignment, with a different assignee than the current case
      if (
        connectedTradeIn?.assignmentList.length === 1 &&
        connectedTradeIn.assignmentList[0].assignedTo?.userID !==
          newOrUpdatedCase.assignmentList[0].assignedTo?.userID
      ) {
        return true;
      }
      return false;
    }, [getCasesByRideOrderId, getNewOrUpdatedCase]);

  const hasErrors = useMemo(() => {
    const caseDataFormHasErrors = !!Object.values(caseDataForm.validation).find(
      (validation) => !!validation.error
    );

    const caseAssignmentFormsHasErrors = !!Object.values(
      caseAssignmentForms.validations
    ).find((vs) => Object.values(vs).find((v) => !!v.error));

    return caseDataFormHasErrors || caseAssignmentFormsHasErrors;
  }, [caseAssignmentForms.validations, caseDataForm.validation]);

  const controlsContextValue = useMemo(
    (): Controls => ({
      save: handleSave,
      checkForOverlaps,
      checkForCommentsNeeded,
      checkIfConnectedTradeInWillAutoUpdate,
      startEdit: handleStartEdit,
      cancelEdit: handleCancelEdit,
      copy: handleCopy,
      editMode: controlsState.editMode,
      getNewOrUpdatedCase,
      hasErrors,
      createCaseStatus,
      updateCaseStatus,
      sendCommentToFieldTester,
    }),
    [
      handleSave,
      checkForOverlaps,
      checkForCommentsNeeded,
      checkIfConnectedTradeInWillAutoUpdate,
      handleStartEdit,
      handleCancelEdit,
      handleCopy,
      controlsState.editMode,
      getNewOrUpdatedCase,
      hasErrors,
      createCaseStatus,
      updateCaseStatus,
      sendCommentToFieldTester,
    ]
  );

  return (
    <Suspense fallback="Laddar...">
      <ControlsContext.Provider value={controlsContextValue}>
        <CaseDataFormContext.Provider value={caseDataForm}>
          <CaseAssignmentFormContext.Provider value={caseAssignmentForms}>
            {children}
          </CaseAssignmentFormContext.Provider>
        </CaseDataFormContext.Provider>
      </ControlsContext.Provider>
    </Suspense>
  );
};

export const useDetailedCaseDataForm = () => {
  const value = useContext(CaseDataFormContext);
  if (!value) {
    throw Error(
      "Can't useDetailedCaseDataForm() outside of DetailedCaseFormProvider"
    );
  }
  return value;
};

export const useDetailedCaseAssignmentForm = () => {
  const value = useContext(CaseAssignmentFormContext);
  if (!value) {
    throw Error(
      "Can't useDetailedCaseAssignmentForm() outside of DetailedCaseFormProvider"
    );
  }
  return value;
};

export const useDetailedCaseFormControl = () => {
  const value = useContext(ControlsContext);
  if (!value) {
    throw Error(
      "Can't useDetailedCaseFormControl() outside of DetailedCaseFormProvider"
    );
  }
  return value;
};

export default DetailedCaseFormProvider;
