// expense utils
import { ExpenseModel, UserModel } from 'api/model';
import Roles from 'constants/Roles';

const allowCircleKSensitiveInfo = (me: UserModel | null): boolean => {
  const isSuperAdmin = me?.roles.includes(Roles.SuperAdmin);
  const isCircleK = me?.roles.includes(Roles.CircleK);
  const otherAllowedUserIds = [10, 21, 55, 60, 115, 475, 802, 1237];
  const result = !!(
    isSuperAdmin ||
    isCircleK ||
    (me?.userID && otherAllowedUserIds.includes(me?.userID))
  );

  return result;
};

const filterCircleKExpense = (
  expenses: ExpenseModel[],
  me: UserModel | null
) => {
  if (allowCircleKSensitiveInfo(me)) {
    return expenses;
  }
  return expenses?.filter(
    (expense) => expense.externalReceiptLineItemID === null
  );
};

export default {
  filterCircleKExpense,
  allowCircleKSensitiveInfo,
};
