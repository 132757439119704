/* eslint-disable @typescript-eslint/ban-types */
import { useCallback, useMemo, useRef, useState } from 'react';
import styled, { css } from 'styled-components';
import TableHead from './TableHead';
import TableRow from './TableRow';
import { ColumnSetting, makeColumnClassName } from './utils';
import Button from 'components/inputs/Button';

interface TableWrapperProps {
  columnSettings: ColumnSetting<any, any>[];
  columnWidthAsFlexAmount?: boolean;
  fluid?: boolean;
}

const TableWrapper = styled.div<TableWrapperProps>`
  display: flex;
  flex-direction: column;
  // overflow: auto;
  width: 100%;
  ${({ columnSettings, columnWidthAsFlexAmount }) => {
    const totalColumnWidth = columnSettings.reduce(
      (acc, setting) => acc + setting.width,
      0
    );

    return css`
      // I couldn't get the rows to be as wide as all of its cells in css,
      // so I set the width 'manually' here.
      & > * {
        ${!columnWidthAsFlexAmount ? `width: ${totalColumnWidth}px;` : ''}
      }

      ${columnWidthAsFlexAmount
        ? columnSettings.map(
            (cs, i) => css`
              .${makeColumnClassName(i)} {
                flex: ${cs.width};
                border-right: 1px solid
                  ${({ theme }) => theme.colors.border.light};

                ${cs.css}
              }
            `
          )
        : columnSettings.map(
            (cs, i) => css`
              .${makeColumnClassName(i)} {
                flex-basis: ${(cs.width / totalColumnWidth) * 100}%;
                box-sizing: border-box;
                min-width: ${cs.width}px;
                width: ${cs.width}px;
                border-right: 1px solid
                  ${({ theme }) => theme.colors.border.light};

                ${cs.css}
              }
            `
          )}
    `;
  }};
`;

const Pagination = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
`;

const PaginationButton = styled(Button)`
  margin: 0 5px;
`;

const PageButton = styled(PaginationButton)<{ selected: boolean }>`
  ${({ selected, theme }) => css`
    background-color: ${selected
      ? theme.colors.background.primary
      : 'transparent'};
    color: ${theme.colors.foreground.primary};
    border: 1px solid ${theme.colors.border.light};
    font-weight: ${selected ? 'bold' : 'normal'};
  `}
`;

export interface SortState {
  ascending: boolean;
  sortedColumnIndex: number;
}

interface Props<Row extends Object, RenderProps extends Object> {
  columnSettings: ColumnSetting<Row, RenderProps>[];
  renderProps?: RenderProps;
  rows: Row[];
  initialSortState?: SortState;
  useColumnWidthAsFlexAmount?: boolean;
  onRowClick?(row: Row): void;
  onSortStateChange?(sortState: SortState): void;
  /** creates the className for the row, also used for row component key */
  rowClassName?: (row: Row) => string;
  className?: string;
  fluid?: boolean;
  currentPage?: number;
  totalPageCount?: number;
  pageSize?: number;
  onGoToPage?(pageNumber: number): void;
  showCustomTableHead?: boolean;
}

const Table = <Row extends Object, RenderProps extends Object>({
  columnSettings,
  renderProps,
  rows,
  initialSortState,
  fluid,
  useColumnWidthAsFlexAmount,
  onRowClick,
  onSortStateChange,
  rowClassName,
  className,
  currentPage = 0,
  pageSize = 0,
  totalPageCount = 0,
  onGoToPage,
  showCustomTableHead,
}: Props<Row, RenderProps>) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [sortState, setSortState] = useState<SortState>(
    initialSortState ?? {
      ascending: true,
      sortedColumnIndex: 0,
    }
  );
  const onSortStateChangeRef = useRef(onSortStateChange);
  onSortStateChangeRef.current = onSortStateChange;

  const handleToggleSortedColumn = useCallback((columnIndex: number) => {
    setSortState((s) => {
      let newState = { ...s, sortedColumnIndex: columnIndex };

      if (columnIndex === s.sortedColumnIndex) {
        newState = { ...s, ascending: !s.ascending };
      }

      onSortStateChangeRef.current?.(newState);
      return newState;
    });
  }, []);

  const sortedRows = useMemo(() => {
    const sortFunction =
      columnSettings[sortState.sortedColumnIndex]?.sortFunction;
    const ascending = sortState.ascending ? 1 : -1;
    if (sortFunction) {
      const theSortedRows = [...rows].sort(
        (a, b) => sortFunction(a, b) * ascending
      );
      return theSortedRows;
    }
    return rows;
  }, [columnSettings, rows, sortState.ascending, sortState.sortedColumnIndex]);
  const maxPages = 10;
  const startPage = Math.max(
    Math.min(currentPage - 4, totalPageCount - maxPages),
    0
  );
  const endPage = Math.min(startPage + maxPages, totalPageCount ?? 0);

  const getPageButtons = useCallback(() => {
    return Array.from({ length: endPage - startPage }, (_, i) => {
      const page = startPage + i;
      return (
        <PageButton
          key={page}
          onClick={() => onGoToPage?.(page)}
          selected={currentPage === page}
        >
          {page + 1}
        </PageButton>
      );
    });
  }, [currentPage, endPage, onGoToPage, startPage]);

  const firstPageVisible = startPage === 0;
  const lastPageVisible = endPage === (totalPageCount ?? 0) - 1;

  return (
    <TableWrapper
      className={className}
      columnSettings={columnSettings}
      columnWidthAsFlexAmount={useColumnWidthAsFlexAmount}
    >
      <TableHead
        columnSettings={columnSettings}
        fluid={fluid}
        rows={sortedRows}
        sortAscending={sortState.ascending}
        sortedColumnIndex={sortState.sortedColumnIndex}
        toggleSort={handleToggleSortedColumn}
        showCustomTableHead={showCustomTableHead}
      />
      {sortedRows.map((row, i) => (
        <TableRow<Row, RenderProps>
          className={rowClassName?.(row)}
          columnSettings={columnSettings}
          key={rowClassName ? rowClassName(row) : i}
          onClick={onRowClick}
          renderProps={renderProps}
          row={row}
          rowIndex={i + currentPage * pageSize}
        />
      ))}
      {currentPage !== undefined && onGoToPage !== undefined && (
        <Pagination>
          {firstPageVisible ? (
            <PaginationButton
              disabled={currentPage === 0}
              onClick={() => onGoToPage(Math.max(currentPage - 1, 0))}
            >
              {'<'}
            </PaginationButton>
          ) : (
            <PaginationButton
              disabled={currentPage === 0}
              onClick={() => {
                // go back to first page
                onGoToPage(0);
              }}
            >
              {'<<'}
            </PaginationButton>
          )}
          {getPageButtons()}
          {lastPageVisible ? (
            <PaginationButton
              disabled={currentPage === (totalPageCount ?? 0) - 1}
              onClick={() =>
                onGoToPage(Math.min(currentPage + 1, totalPageCount ?? 1))
              }
            >
              {'>'}
            </PaginationButton>
          ) : (
            <PaginationButton
              disabled={currentPage === (totalPageCount ?? 0) - 1}
              onClick={() => {
                // go to last page
                onGoToPage((totalPageCount ?? 1) - 1);
              }}
            >
              {'>>'}
            </PaginationButton>
          )}
        </Pagination>
      )}
    </TableWrapper>
  );
};

export default Table;
