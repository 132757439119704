import styled from 'styled-components';

const SectionHeader = styled.h2`
  margin: 0;
  padding: 0;
  padding-bottom: 10px;

  font: inherit;
  font-size: ${({ theme }) => theme.sizes.font.medium};
  font-weight: 700;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};
`;

export default SectionHeader;
