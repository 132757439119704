/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type ExpenseType = (typeof ExpenseType)[keyof typeof ExpenseType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExpenseType = {
  SJTicket: 0,
  SLTicket: 1,
  BusTicket: 2,
  Gas: 3,
  Food: 4,
  Hotel: 5,
  CarWash: 6,
  Other: 7,
  Taxi: 8,
  ParkingTicket: 9,
  Claims: 10,
  TireChange: 11,
  WindscreenWasher_Fluid: 12,
  DirectBooking: 13,
  Workshop: 15,
  Towing: 16,
  CollectTires: 17,
  FlightTicket: 18,
  AdBlue: 19,
  Oil: 20,
  GiftCard: 21,
} as const;
