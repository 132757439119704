import {
  faCar,
  faEllipsisV,
  faFileCsv,
  faInfoCircle,
  faLink,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExternalReceiptProvider, ReceiptLineItemDTO } from 'api/model';
import Button from 'components/inputs/Button';
import DatePicker, { DateRangeInput } from 'components/inputs/DatePicker';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SearchableSelect from 'components/inputs/SearchableSelect';
import TextButton from 'components/inputs/TextButton';
import SectionHeader from 'components/SectionHeader';
import Table from 'components/Table';
import { ColumnProps } from 'components/Table/TableHead';
import { ColumnSetting } from 'components/Table/utils';
import Routes from 'constants/Routes';
import useModalStack from 'contexts/modal/useModalStack';
import DateSpanPresetsSelector from 'pages/GlobalSearch/searchBar/DateSpanPresetsSelector';
import {
  FC,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { formatDateTime } from 'utils/date-helpers';
import ConnectExternalReceiptModal from './ConnectExternalReceiptModal';
import useTranslations from 'contexts/basicData/useTranslations';
import BusinessUnitSelect from 'components/inputs/BusinessUnitSelect';
import UploadCsvModal from './UploadCsvModal';
import {
  useReceiptAttemptBulkConnectExternalLineItemsToExpenses,
  useReceiptFindReceiptLineItems,
  useReceiptGetLastReceiptDate,
} from 'api/receipt/receipt';
import { alphabeticCompare, makeStringComparator } from 'utils/sorting';
import Checkbox from 'components/inputs/Checkbox';
import { useLocalStorage } from 'usehooks-ts';
import Dropdown from 'components/Dropdown';
import InvalidateExternalReceiptModal from './InvalidateExternalReceiptModal';
import useUsers from 'contexts/basicData/useUsers';
import LightBulb from './LightBulb';
import { useQueryClient } from '@tanstack/react-query';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 10px;
`;

const MyButton = styled(Button)`
  display: flex;
  align-items: center;
  gap: 5px;
  height: 30px;
`;

const NoWrap = styled.div`
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  gap: 5px;
`;

const Section = styled.section`
  width: 100%;
  margin-bottom: 100px;
`;

const SectionContent = styled.div`
  padding: 10px 0;
`;

const LeftContentWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  gap: 5px;
`;

const InvalidRowClassName = 'invalid-row';

const TableStylerWrapper = styled.div`
  display: contents;

  .${InvalidRowClassName} {
    color: ${({ theme }) => theme.colors.foreground.error};
  }
`;

type RowButtonProps = {
  row: ReceiptLineItemDTO;
  onUpdate: () => void;
};
const ConnectButton: React.FC<RowButtonProps> = ({ row, onUpdate }) => {
  const users = useUsers();
  const navigate = useNavigate();
  const modalStack = useModalStack();

  if (row.lineItem.isInvalid) {
    return (
      <div
        style={{
          whiteSpace: 'wrap',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <span>Ogiltig kvittorad</span>
        <span>Anledning: {row.lineItem.invalidReason}</span>
        <span>
          Ogiltigmarkerat av {users[row.lineItem.invalidatedBy!]?.name ?? '-'}
        </span>
      </div>
    );
  }

  return row.linkedCaseId !== null ? (
    <TextButton
      onClick={() => {
        navigate(`${Routes.search.index}/${row.linkedCaseId}`);
      }}
    >
      <FontAwesomeIcon icon={faCar} />
      <span> Kopplat uppdrag</span>
    </TextButton>
  ) : (
    <TextButton
      onClick={() => {
        modalStack.push(
          <ConnectExternalReceiptModal lineItem={row} onUpdate={onUpdate} />
        );
      }}
    >
      <FontAwesomeIcon icon={faLink} />
      <span> Koppla till uppdrag</span>
    </TextButton>
  );
};

const ActionButton: React.FC<RowButtonProps> = ({ row, onUpdate }) => {
  const [isOpen, setIsOpen] = useState(false);
  const modalStack = useModalStack();

  return (
    <Dropdown
      content={
        isOpen ? (
          <TextButton
            onClick={() => {
              modalStack.push(
                <InvalidateExternalReceiptModal
                  lineItem={row}
                  onUpdate={onUpdate}
                />
              );
            }}
          >
            {row.lineItem.isInvalid
              ? 'Ångra ogiltighet'
              : 'Markera som ogiltig'}
          </TextButton>
        ) : null
      }
      position="left"
      onLostFocus={() => setIsOpen(false)}
    >
      <TextButton
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          width: '20px',
        }}
        onClick={() => setIsOpen(!isOpen)}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </TextButton>
    </Dropdown>
  );
};

const RECEIPT_PAGE_SIZE = 500;

interface SelectableReceiptLineItemDTO extends ReceiptLineItemDTO {
  selected: boolean;
}

const ReceiptsList: FC = () => {
  const modalStack = useModalStack();
  const queryClient = useQueryClient();
  // get the lineItem from the url query
  const searchTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [search, setSearch] = useState<string>('');
  const [skip, setSkip] = useState(0);
  const [hideInvalidReceipts, setHideInvalidReceipts] = useLocalStorage(
    'hideInvalidReceipts',
    true
  );
  const [hideLinkedReceipts, setHideLinkedReceipts] = useLocalStorage(
    'hideLinkedReceipts',
    false
  );

  const { externalReceiptProviders } = useTranslations();

  const [selectedProvider, setSelectedProvider] =
    useState<ExternalReceiptProvider>(ExternalReceiptProvider.CircleK);
  const [selectedBusinessUnits, setSelectedBusinessUnits] = useState<number[]>(
    []
  );

  const now = new Date();
  const thirtyDaysBack = new Date();
  thirtyDaysBack.setDate(now.getDate() - 30);
  const tomorrow = new Date();
  tomorrow.setDate(now.getDate() + 1);

  const [daterange, setDaterange] = useState<[Date | null, Date | null]>([
    thirtyDaysBack,
    tomorrow,
  ]);

  const [lineItems, setLineItems] = useState<SelectableReceiptLineItemDTO[]>(
    []
  );
  const allChecked = useMemo(
    () => lineItems.every((item) => item.selected),
    [lineItems]
  );
  const someChecked = useMemo(
    () => lineItems.some((item) => item.selected),
    [lineItems]
  );
  const amountChecked = useMemo(
    () => lineItems.filter((item) => item.selected).length,
    [lineItems]
  );

  const getLastInboundReceiptDateCall = useReceiptGetLastReceiptDate();

  const refreshLastInboundReceiptDate = useCallback(async () => {
    await queryClient.invalidateQueries(getLastInboundReceiptDateCall.queryKey);
    await getLastInboundReceiptDateCall.refetch();
  }, [getLastInboundReceiptDateCall, queryClient]);

  const findReceiptsCall = useReceiptFindReceiptLineItems();
  const attemptBulkConnectExternalLineItemsToExpensesCall =
    useReceiptAttemptBulkConnectExternalLineItemsToExpenses();

  const fetchReceipts = useCallback(async () => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }

    refreshLastInboundReceiptDate();

    searchTimeoutRef.current = setTimeout(async () => {
      const result = await findReceiptsCall.mutateAsync({
        data: {
          search,
          from: daterange[0]!,
          to: daterange[1]!,
          providers: [selectedProvider],
          businessUnits: selectedBusinessUnits,
          hideLinkedReceipts,
          hideInvalidReceipts,
          take: RECEIPT_PAGE_SIZE,
          skip,
        },
      });
      setLineItems(result.items.map((item) => ({ ...item, selected: false })));
    }, 500);
  }, [
    refreshLastInboundReceiptDate,
    findReceiptsCall,
    search,
    daterange,
    selectedProvider,
    selectedBusinessUnits,
    hideLinkedReceipts,
    hideInvalidReceipts,
    skip,
  ]);

  useEffect(() => {
    if (daterange[0] && daterange[1]) {
      fetchReceipts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    search,
    daterange,
    selectedProvider,
    selectedBusinessUnits,
    hideLinkedReceipts,
    hideInvalidReceipts,
    skip,
  ]);

  const lightbulbStatus = useMemo(() => {
    if (getLastInboundReceiptDateCall.data) {
      const lastInboundReceiptDate = new Date(
        getLastInboundReceiptDateCall.data
      );
      const timestamp = new Date();

      const diff = timestamp.getTime() - lastInboundReceiptDate.getTime();
      const diffInHours = diff / 1000 / 60 / 60;

      if (diffInHours < 1) {
        return 'good';
      }
      if (diffInHours < 2) {
        return 'warning';
      }
      return 'error';
    }
    return 'error';
  }, [getLastInboundReceiptDateCall.data]);

  const columnSettings = useMemo(
    (): ColumnSetting<SelectableReceiptLineItemDTO, ColumnProps>[] => [
      {
        // render global checkbox
        head: (
          <Checkbox
            checked={allChecked}
            onChange={(eve) => {
              setLineItems(
                lineItems.map((item) => ({
                  ...item,
                  selected: eve.currentTarget.checked,
                }))
              );
            }}
          />
        ),
        // render checkbox
        render: (a) => (
          <Checkbox
            checked={a.selected}
            disabled={a.linkedCaseId !== null}
            onChange={(eve) => {
              setLineItems(
                lineItems.map((item) =>
                  item === a
                    ? { ...item, selected: eve.currentTarget.checked }
                    : item
                )
              );
            }}
          />
        ),
        width: 15,
      },
      {
        head: 'Nr',
        render: (a, _props, _focused, rowIndex) => rowIndex + 1,
        width: 20,
      },
      {
        head: 'Transaktions-ID',
        render: (row) => row.receipt.transactionID,
        sortFunction: makeStringComparator(
          (row) => row.receipt.transactionID ?? ''
        ),
        width: 80,
      },
      {
        head: 'Partner',
        render: (row) => externalReceiptProviders[row.receipt.provider] ?? '-',
        width: 50,
        sortFunction: (a, b) =>
          alphabeticCompare(
            externalReceiptProviders[a.receipt.provider],
            externalReceiptProviders[b.receipt.provider]
          ),
      },
      {
        head: (
          <span>
            Tidpunkt{' '}
            <FontAwesomeIcon
              icon={faInfoCircle}
              title="Obs! Tidpunkten kan variera +/- ett par timmar från den
                  faktiska inköpstiden."
            />
          </span>
        ),
        render: (row) => formatDateTime(row.receipt.receiptDate),
        width: 100,
        sortFunction: (a, b) =>
          a.receipt.receiptDate.getTime() - b.receipt.receiptDate.getTime(),
      },
      {
        head: 'Tillhörande kvitto',
        render: (row) => row.receipt.receiptNumber,
        width: 150,
        sortFunction: (a, b) =>
          alphabeticCompare(a.receipt.receiptNumber, b.receipt.receiptNumber),
      },
      {
        head: 'Kortinnehavare',
        render: (row) =>
          row.cardOwnerName ? row.cardOwnerName : row.cardNumber,
        width: 150,
        sortFunction: (a, b) =>
          alphabeticCompare(
            a.cardOwnerName ?? a.cardNumber,
            b.cardOwnerName ?? b.cardNumber
          ),
      },
      {
        head: 'Inköpsställe',
        render: (row) => row.receipt.store,
        width: 150,
        sortFunction: (a, b) =>
          alphabeticCompare(a.receipt.store, b.receipt.store),
      },
      {
        head: 'Produkt',
        render: (row) => row.lineItem.itemName ?? '-',
        width: 100,
        sortFunction: (a, b) =>
          alphabeticCompare(a.lineItem.itemName, b.lineItem.itemName),
      },
      {
        head: 'Antal',
        render: (row) =>
          `${row.lineItem.itemQuantity}${row.lineItem.itemUnit}` ?? '-',
        width: 50,
        sortFunction: (a, b) =>
          a.lineItem.itemQuantity - b.lineItem.itemQuantity,
      },
      {
        head: 'Summa',
        render: (row) => `${row.lineItem.itemTotal}kr` ?? '-',
        width: 90,
        sortFunction: (a, b) => a.lineItem.itemTotal - b.lineItem.itemTotal,
      },
      {
        head: 'System',
        render: (row) => (
          <div style={{ whiteSpace: 'wrap' }}>
            {row.lineItem.systemNote ?? ''}
          </div>
        ),
        width: 150,
        sortFunction: (a, b) =>
          alphabeticCompare(a.lineItem.systemNote, b.lineItem.systemNote),
      },
      {
        head: '',
        width: 150,
        render: (row) => <ConnectButton row={row} onUpdate={fetchReceipts} />,
        sortFunction: (a, b) => {
          const aHasLinkedCase = a.linkedCaseId !== null;
          const bHasLinkedCase = b.linkedCaseId !== null;

          if (aHasLinkedCase && !bHasLinkedCase) {
            return 1;
          }
          if (!aHasLinkedCase && bHasLinkedCase) {
            return -1;
          }
          return 0;
        },
      },
      {
        head: '',
        width: 20,
        render: (row) => <ActionButton row={row} onUpdate={fetchReceipts} />,
        sortFunction: (a, b) => {
          const aHasLinkedCase = a.linkedCaseId !== null;
          const bHasLinkedCase = b.linkedCaseId !== null;

          if (aHasLinkedCase && !bHasLinkedCase) {
            return 1;
          }
          if (!aHasLinkedCase && bHasLinkedCase) {
            return -1;
          }
          return 0;
        },
      },
    ],
    [allChecked, lineItems, externalReceiptProviders, fetchReceipts]
  );

  const currentPage = Math.floor(skip / RECEIPT_PAGE_SIZE);
  const totalPageCount = Math.ceil(
    (findReceiptsCall.data?.totalCount ?? 0) / RECEIPT_PAGE_SIZE
  );

  return (
    <Suspense>
      <Wrapper>
        <Section>
          <SectionHeader>Kvitton</SectionHeader>

          <SectionContent
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <LeftContentWrap>
              <LabelWrap label="Datumspann">
                <DatePicker
                  calendarStartDay={1}
                  customInput={<DateRangeInput daterange={daterange} />}
                  dateFormat="yyyy-MM-dd"
                  endDate={daterange[1]}
                  locale="sv"
                  onChange={setDaterange}
                  selectsRange
                  startDate={daterange[0]}
                />
              </LabelWrap>
              <DateSpanPresetsSelector
                dateRange={daterange}
                onPresetSelected={setDaterange}
              />
              <Input
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Filtrera på transaktions-id, kvittonummer, produkt, kortnummer, kortinnehavare, eller inköpsställe"
                value={search}
                width={230}
              />
              <BusinessUnitSelect
                onChange={(units) => {
                  setSelectedBusinessUnits(Array.from(units));
                }}
                value={new Set(selectedBusinessUnits)}
              />

              <MyButton
                onClick={() => {
                  modalStack.push(
                    <UploadCsvModal
                      onCancel={() => modalStack.pop()}
                      onSaved={() => {
                        modalStack.pop();
                        fetchReceipts();
                      }}
                    />
                  );
                }}
              >
                <NoWrap>
                  <span>Ladda upp csv</span>
                  <FontAwesomeIcon icon={faFileCsv} />
                </NoWrap>
              </MyButton>
              <Checkbox
                checked={hideLinkedReceipts}
                onChange={(e) => {
                  setHideLinkedReceipts(e.currentTarget.checked);
                }}
              >
                Dölj kopplade
              </Checkbox>
              <Checkbox
                checked={hideInvalidReceipts}
                onChange={(e) => {
                  setHideInvalidReceipts(e.currentTarget.checked);
                }}
              >
                Dölj ogiltiga
              </Checkbox>
            </LeftContentWrap>

            <span
              style={{
                display: 'flex',
                flexDirection: 'row',
                gap: 10,
              }}
            >
              <LabelWrap label="Partner">
                <SearchableSelect
                  options={[
                    { label: 'Ingen', value: ExternalReceiptProvider.None },
                    {
                      label: 'Circle K',
                      value: ExternalReceiptProvider.CircleK,
                      description: 'Circle K',
                    },
                  ]}
                  onOptionClicked={(option: ExternalReceiptProvider) => {
                    setSelectedProvider(() => option);
                  }}
                  selectedValue={selectedProvider}
                  searchFilter={(searchString, opts) => {
                    const lowerNameFilter = searchString.toLowerCase();
                    return opts.filter((opt) =>
                      opt.description
                        ?.toString()
                        .toLowerCase()
                        .includes(lowerNameFilter)
                    );
                  }}
                />
              </LabelWrap>
              <LabelWrap label="Senast inkomna kvittot">
                <LightBulb status={lightbulbStatus}>
                  {getLastInboundReceiptDateCall.data &&
                    formatDateTime(
                      new Date(getLastInboundReceiptDateCall.data)
                    )}
                </LightBulb>
              </LabelWrap>
            </span>
          </SectionContent>
          {someChecked && (
            <Button
              onClick={async () => {
                await attemptBulkConnectExternalLineItemsToExpensesCall.mutateAsync(
                  {
                    data: lineItems
                      .filter((item) => item.selected)
                      .map((item) => item.lineItem.id),
                  }
                );
              }}
              disabled={
                attemptBulkConnectExternalLineItemsToExpensesCall.isLoading
              }
            >
              <NoWrap>
                <span>Auto-koppla {amountChecked} rader till uppdrag</span>
                <FontAwesomeIcon
                  icon={
                    attemptBulkConnectExternalLineItemsToExpensesCall.isLoading
                      ? faSpinner
                      : faFileCsv
                  }
                  spin={
                    attemptBulkConnectExternalLineItemsToExpensesCall.isLoading
                  }
                />
              </NoWrap>
            </Button>
          )}
          <SectionContent>
            {findReceiptsCall.isLoading && (
              <FontAwesomeIcon icon={faSpinner} spin />
            )}
            {findReceiptsCall.isError && (
              <div>Error: {findReceiptsCall.isError}</div>
            )}

            {findReceiptsCall.isSuccess && (
              <TableStylerWrapper>
                <Table
                  columnSettings={columnSettings}
                  initialSortState={{ sortedColumnIndex: -1, ascending: false }}
                  rows={lineItems}
                  fluid
                  useColumnWidthAsFlexAmount
                  rowClassName={(row) =>
                    row.lineItem.isInvalid ? InvalidRowClassName : ''
                  }
                  pageSize={RECEIPT_PAGE_SIZE}
                  totalPageCount={totalPageCount}
                  currentPage={currentPage}
                  onGoToPage={(page) => {
                    setSkip(page * RECEIPT_PAGE_SIZE);
                  }}
                />
              </TableStylerWrapper>
            )}
          </SectionContent>
        </Section>
      </Wrapper>
    </Suspense>
  );
};

export default ReceiptsList;
