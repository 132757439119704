/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AvailabilityStatus =
  (typeof AvailabilityStatus)[keyof typeof AvailabilityStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AvailabilityStatus = {
  NEEDS_ATTENTION: 0,
  UNAVAILABLE: 1,
  AVAILABLE: 2,
  UNSPECIFIED: 3,
  PENDING_SPECIFICATION: 4,
} as const;
