import { FC, PropsWithChildren, useMemo } from 'react';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import TextButton, { TextButtonProps } from './TextButton';

interface Props extends PropsWithChildren, TextButtonProps {
  roles?: number[]; // if specified, any of the roles must be present
  combinedRoles?: number[]; // if specified, all of the roles must be present
}
const AuthorizedTextButton: FC<Props> = ({
  roles = [Roles.Admin],
  combinedRoles = [],
  ...buttonProps
}: Props) => {
  const me = useMe();
  // check if me.roles overlaps with roles
  const isAuthorized = useMemo(() => {
    if (combinedRoles.length > 0) {
      return combinedRoles.every((role) => me?.roles.includes(role));
    }
    return me?.roles.some((role) => roles.includes(role));
  }, [combinedRoles, me?.roles, roles]);

  if (!isAuthorized) return null;
  return <TextButton {...buttonProps} />;
};

export default AuthorizedTextButton;
