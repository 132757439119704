import { CompanyModel } from 'api';
import { useMemo, useState } from 'react';

const useToFromAddressAssignmentFilter = () => {
  const [selectedFromCompany, setSelectedFromCompany] =
    useState<CompanyModel | null>(null);
  const [selectedToCompany, setSelectedToCompany] =
    useState<CompanyModel | null>(null);

  return useMemo(() => {
    return {
      selectedFromCompany,
      setSelectedFromCompany,
      selectedToCompany,
      setSelectedToCompany,
    };
  }, [selectedFromCompany, selectedToCompany]);
};

export default useToFromAddressAssignmentFilter;
