import Table from 'components/Table';
import useBudgetSettingsColumnSettings from './useBudgetSettingsColumnSettings';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { useInternalDeliveryGroupBudgetSettingsFilters } from '../useInternalDeliveryGroupBudgetSettingsFilters';
import { useInternalDeliveryBudgetGetWeeklyBudgetsOfMonth } from 'api/internal-delivery-budget/internal-delivery-budget';
import { useEffect } from 'react';
// import { useEffect } from 'react';
// import { useState } from 'react';
// import { useBudgetSettingsTableValues } from './useBudgetSettingsTableValues';

const InternalDeliveryGroupBudgetSettingsTable = () => {
  // const [hasLoadedInitialData, setHasLoadedInitialData] = useState(false);
  // const tableValues = useBudgetSettingsTableValues();
  const { state: filterState } =
    useInternalDeliveryGroupBudgetSettingsFilters();
  // const getBudgets = useInternalDeliveryBudgetGetYearlyBudgets(
  const getBudgets = useInternalDeliveryBudgetGetWeeklyBudgetsOfMonth(
    {
      date: filterState.selectedDate,
    }
    // filterState.weekNumbers,
    // {
    //   query: {
    //     enabled: false,
    //   },
    // }
  );

  // console.log({ DATE: filterState.selectedDate, DATA: getBudgets.data });

  const columnSettings = useBudgetSettingsColumnSettings(getBudgets);
  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterState.selectedDate]);
  // useEffect(() => {
  //   if (!hasLoadedInitialData) {
  //     getBudgets.refetch();
  //     setHasLoadedInitialData(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (filterState.selectedDate) {
  //     getBudgets.refetch();
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [filterState.selectedDate]);

  // useEffect(() => {
  //   if (getBudgets.isSuccess) {
  //     tableValues.handleLoadTableData(getBudgets.data);
  //   }
  //   return () => {
  //     tableValues.resetTable();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [getBudgets.data]);

  return (
    <>
      {getBudgets.isLoading && <LoadingSpinner />}
      {getBudgets.isSuccess && (
        <Table
          columnSettings={columnSettings}
          rows={getBudgets.data}
          fluid
          useColumnWidthAsFlexAmount
        />
      )}
    </>
  );
};

export default InternalDeliveryGroupBudgetSettingsTable;
