import styled from 'styled-components';
import TransparentSpinner from './TransparentSpinner';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  & > *:first-child {
    margin-bottom: 10px;
  }
`;

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const LoadingSpinner = ({ children, className }: Props) => {
  return (
    <Wrapper className={className}>
      <TransparentSpinner />
      {children}
    </Wrapper>
  );
};

export default LoadingSpinner;
