/* eslint-disable @typescript-eslint/no-unused-vars */
import { IPosition } from 'api';
import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import {
  CustomMarker,
  MapIcons,
  useGoogleMapsContext,
} from './GoogleMapsContext';

const MapContainer = styled.div<{ height: number }>`
  height: ${({ height }) => (height === -1 ? '100%' : `${height}px`)};
  width: 100%;
  user-select: none;
`;

interface MapProps {
  initialCenter: { latitude: number; longitude: number };
  zoom: number;
  containerHeight: number;
  addCenterMarker?: boolean;
  zoomControl?: boolean;
  positions?: google.maps.LatLng[];
}

const Map: React.FC<MapProps> = ({
  initialCenter,
  zoom,
  containerHeight,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addCenterMarker,
  zoomControl,
  positions,
}) => {
  const { scriptLoaded, createMap, fitBounds } = useGoogleMapsContext();

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scriptLoaded && ref.current) {
      createMap(
        ref.current,
        zoom,
        new google.maps.LatLng(initialCenter.latitude, initialCenter.longitude),
        zoomControl
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, ref, createMap, zoom]);

  useEffect(() => {
    fitBounds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialCenter]);

  return scriptLoaded ? (
    <MapContainer height={containerHeight} ref={ref} />
  ) : (
    <h1>Laddar...</h1>
  );
};

export default React.memo(Map);
