/* eslint-disable @typescript-eslint/no-unused-vars */
import { ColumnSetting } from 'components/Table/utils';
import { useCallback, useMemo, useState } from 'react';
import { ITranslationLookups } from 'api';
import { getDayName } from 'utils/date-helpers';
import styled, { css, useTheme } from 'styled-components';
import { useHolidaysGetHolidays } from 'api/holidays/holidays';
import { useBudgetFilters } from '../useBudgetFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock, faLockOpen, faTag } from '@fortawesome/free-solid-svg-icons';
import { InternalDeliveryOverviewEntry } from 'api/model';
import useCompanies from 'contexts/basicData/useCompanies';
import { useBusinessUnitGetBusinessUnitGroups } from 'api/business-unit/business-unit';
import useWeeklyBudgets from './useWeeklyBudgets';
import {
  DailyBudgetOverrideCell,
  WeeklyBudgetCell,
} from './EditableBudgetColumnCells';
import TextButton from 'components/inputs/TextButton';
import { CellNumberDivision, Grid, TitleDate } from './styles';
import { arraySpreadIf } from 'utils/spreading';

const HeadRowCss = css`
  align-items: center;
  display: flex;
`;

const BoldSpan = styled.span`
  font-weight: bold;
`;
export interface InternalDeliveryOverviewColumnProps {
  translations: ITranslationLookups;
  startDate: Date;
  endDate: Date;
  onUpdateRow: () => void;
}

interface Props {
  onlyTotals?: boolean;
}

const useInternalDeliveryOverviewColumnSettings = ({
  onlyTotals,
}: Props): ColumnSetting<
  InternalDeliveryOverviewEntry,
  InternalDeliveryOverviewColumnProps
>[] => {
  const [editWeeklyBudget, setEditWeeklyBudget] = useState<boolean>(false);
  const {
    state: { selectedDate, startDate, endDate },
    selectedFilterView,
  } = useBudgetFilters();
  const theme = useTheme();
  const groups = useBusinessUnitGetBusinessUnitGroups();
  const { companiesById } = useCompanies();
  const {
    getOriginalWeekBudgetTotal,
    getCalculatedWeeklyDiff,
    getWeeklyBudget,
  } = useWeeklyBudgets();

  const holidays = useHolidaysGetHolidays();
  const [highlightedDate, setHighlightedDate] = useState<number>();

  const isHoliday = useCallback(
    (checkDate: Date) => {
      // return true if date is a holiday or a sunday
      return (
        holidays.data?.find((h) => h.date.getDate() === checkDate.getDate()) !==
          undefined || checkDate.getDay() === 0
      );
    },
    [holidays]
  );

  return useMemo(() => {
    type Column = ColumnSetting<
      InternalDeliveryOverviewEntry,
      InternalDeliveryOverviewColumnProps
    >;
    const columns: Column[] = [
      {
        csvValue: (rowEntry) =>
          companiesById.get(rowEntry.companyId)?.name ?? '',
        css: HeadRowCss,
        head: 'Anläggning',
        render: (rowEntry, _props, _focused, rowIndex) => (
          <div style={{ display: 'flex', gap: '5px' }}>
            <FontAwesomeIcon
              icon={faTag}
              stroke="black"
              strokeWidth={15}
              color={rowEntry.companyColor ?? theme.colors.background.primary}
              style={{ transform: 'rotateY(180deg)' }}
            />
            {companiesById.get(rowEntry.companyId)?.name ?? ''}
          </div>
        ),
        width: 200,
      },
      ...arraySpreadIf(selectedFilterView === 'week', {
        css: HeadRowCss,
        head: (
          <div style={{ position: 'relative', width: '100%', height: '100%' }}>
            Vecko-
            <br />
            budget
            <TextButton
              style={{ position: 'absolute', top: 0, right: 0 }}
              onClick={() => setEditWeeklyBudget((p) => !p)}
            >
              <FontAwesomeIcon icon={editWeeklyBudget ? faLockOpen : faLock} />
            </TextButton>
          </div>
        ),
        render: (_rowEntry, { onUpdateRow }, _focused) => {
          const originalBudget = getWeeklyBudget(
            selectedDate,
            _rowEntry.companyId
          );
          return (
            <WeeklyBudgetCell
              isEditing={editWeeklyBudget}
              rowEntry={_rowEntry}
              value={originalBudget}
              onUpdate={() => onUpdateRow()}
              date={startDate}
            />
          );
        },
        focusable: editWeeklyBudget,
        width: 50,
      } as Column),
      {
        // business unit group column
        csvValue: (rowEntry) =>
          companiesById.get(rowEntry.companyId)?.name ?? '',
        css: HeadRowCss,
        head: 'Enhet',
        render: (rowEntry, _props, _focused) => (
          <Grid columns={1} align="left">
            {/* <CellNumberDivision> */}
            {!onlyTotals &&
              rowEntry.groupEntries[0].data.map((unitGroupData) => {
                return (
                  <p key={`_group_${unitGroupData.businessUnitGroupId}`}>
                    {groups.data?.find(
                      (g) => g.id === unitGroupData.businessUnitGroupId
                    )?.name ?? ''}
                  </p>
                );
              })}
            {/* {
                // if the week contains a day with a negative business unit group id, show it as övriga
                rowEntry.groupEntries.some((x) =>
                  x.data.some(
                    (y) =>
                      y.businessUnitGroupId < 0 &&
                      y.assigned + y.inProgress + y.unassigned > 0
                  )
                ) && <p>Övriga</p>
              } */}
            {/* </CellNumberDivision> */}
            <CellNumberDivision style={{ fontWeight: 'bold' }}>
              Totalfördelning
            </CellNumberDivision>
            <CellNumberDivision style={{ fontWeight: 'bold' }}>
              Dagsbudget
            </CellNumberDivision>
          </Grid>
        ),
        width: 150,
      },
    ] as Column[];

    for (
      let date = startDate;
      date <= endDate;
      date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1)
    ) {
      columns.push({
        focusable: true,
        head: (
          <div style={{ position: 'relative' }}>
            <div style={{ position: 'relative' }}>
              <TitleDate
                flex={1}
                highlighted={date.getDate() === highlightedDate}
                isHoliday={isHoliday(date)}
                key={`title_${date}`}
              >
                <span className="weekday">{getDayName(date)}</span>
                <span className="date">
                  {`${date.getDate()}/${date.getMonth() + 1}`}
                </span>
              </TitleDate>
            </div>
          </div>
        ),
        render: (rowEntry, { onUpdateRow }, focused) => {
          return (
            <DailyBudgetOverrideCell
              rowEntry={rowEntry}
              value={0}
              onUpdate={() => {
                onUpdateRow();
              }}
              date={date}
              focused={focused}
              onlyTotals={onlyTotals}
              onMouseOver={() => setHighlightedDate(date.getDate())}
            />
          );
        },
        width: 70,
      });
    }

    /** **
     *
     * TOTALS COLUMN
     *
     */
    columns.push({
      // csvValue:
      css: HeadRowCss,
      head: 'Totalt',
      render: (rowEntry, _props, _focused) => {
        let distributionTotalUnassigned: number = 0;
        let distributionTotalAssigned: number = 0;
        let distributionTotalInProgress: number = 0;

        rowEntry.groupEntries.forEach((entryDate) => {
          entryDate.data.forEach((entryData) => {
            distributionTotalUnassigned += entryData.unassigned;
            distributionTotalAssigned += entryData.assigned;
            distributionTotalInProgress += entryData.inProgress;
          });
        });

        const unitTotals: {
          company: number;
          unassigned: number;
          assigned: number;
          inProgress: number;
        }[] = [];

        rowEntry.groupEntries.forEach((entryDate) => {
          entryDate.data.forEach((entryData) => {
            const companyData = unitTotals.find(
              (u) => u.company === entryData.businessUnitGroupId
            );
            if (
              companyData &&
              companyData.company === entryData.businessUnitGroupId
            ) {
              companyData.company = entryData.businessUnitGroupId;
              companyData.unassigned += entryData.unassigned;
              companyData.assigned += entryData.assigned;
              companyData.inProgress += entryData.inProgress;
            } else {
              unitTotals.push({
                company: entryData.businessUnitGroupId,
                unassigned: entryData.unassigned,
                assigned: entryData.assigned,
                inProgress: entryData.inProgress,
              });
            }
          });
        });

        const originalBudgetTotal = getOriginalWeekBudgetTotal(
          selectedDate,
          rowEntry.companyId
        );

        return (
          <div
            style={{
              paddingLeft: '6px',
              paddingRight: '6px',
              width: '150px',
            }}
          >
            <Grid columns={4}>
              {!onlyTotals &&
                unitTotals.map((unitTotal) => {
                  return (
                    <>
                      <span title="Otilldelade">{unitTotal.unassigned}</span>
                      <span title="Planerade" style={{ color: 'orange' }}>
                        {unitTotal.assigned}
                      </span>
                      <span title="Utdelade" style={{ color: 'green' }}>
                        {unitTotal.inProgress}
                      </span>
                      <span />
                    </>
                  );
                })}
              <BoldSpan title="Otilldelade">
                {distributionTotalUnassigned}
              </BoldSpan>
              <BoldSpan title="Planerade" style={{ color: 'orange' }}>
                {distributionTotalAssigned}
              </BoldSpan>
              <BoldSpan title="Utdelade" style={{ color: 'green' }}>
                {distributionTotalInProgress}
              </BoldSpan>
              <BoldSpan
                title="Uppnått av budget"
                style={{
                  color:
                    originalBudgetTotal === 0
                      ? 'crimson'
                      : distributionTotalInProgress /
                            (originalBudgetTotal || 1) >=
                          1
                        ? 'green'
                        : theme.colors.foreground.primary,
                }}
              >
                {originalBudgetTotal === 0
                  ? 'N/A'
                  : `${Math.round((distributionTotalInProgress / (originalBudgetTotal || 1)) * 100)}%`}
              </BoldSpan>
            </Grid>
            <hr />

            <Grid columns={4}>
              <span />
              <span />
              <BoldSpan
                title="Totalt"
                style={{
                  color: 'crimson',
                }}
              >
                {`${originalBudgetTotal}`}
              </BoldSpan>
              <span />
            </Grid>
          </div>
        );
      },
      width: 150,
    });

    return columns.filter((c) => !!c); // remove undefined before returning columns
  }, [
    companiesById,
    editWeeklyBudget,
    endDate,
    getOriginalWeekBudgetTotal,
    getWeeklyBudget,
    groups.data,
    highlightedDate,
    isHoliday,
    onlyTotals,
    selectedDate,
    selectedFilterView,
    startDate,
    theme.colors.background.primary,
    theme.colors.foreground.primary,
  ]);
};

export default useInternalDeliveryOverviewColumnSettings;
