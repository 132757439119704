import { FC } from 'react';
import Modal from 'components/Modal';
import { pdfjs } from 'react-pdf';
import { GenericFileViewer } from 'components/Files/FileViewer';
import styled from 'styled-components';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

interface Props {
  title?: string;
  fileEnding: string;
  fileGuid: string;
  maxHeight?: number;
  onClose(): void;
  description?: string;
}

const GenericFileViewerModal: FC<Props> = ({
  title,
  fileEnding,
  fileGuid,
  onClose,
  maxHeight,
  description,
}) => {
  return (
    <Modal
      buttons={[{ label: 'Stäng', onClick: onClose }]}
      onClose={onClose}
      title={title}
    >
      <Wrapper>
        <GenericFileViewer
          browsable
          fileEnding={fileEnding}
          fileGuid={fileGuid}
          maxHeight={maxHeight}
        />
        {description && <pre>{description}</pre>}
      </Wrapper>
    </Modal>
  );
};

export default GenericFileViewerModal;
