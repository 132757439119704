/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GlobalSettingModel,
  GlobalSettingsGetSettings200Five,
  GlobalSettingsGetSettings200Four,
  GlobalSettingsGetSettings200One,
  GlobalSettingsGetSettings200Three,
  GlobalSettingsGetSettings200Two,
} from '.././model';
import useGlobalSettingsUpdateSettingMutator from '.././mutator/custom-instance';
import useGlobalSettingsGetSettingsMutator from '.././mutator/custom-instance';

export const useGlobalSettingsUpdateSettingHook = () => {
  const globalSettingsUpdateSetting =
    useGlobalSettingsUpdateSettingMutator<void>();

  return (globalSettingModel: GlobalSettingModel) => {
    return globalSettingsUpdateSetting({
      url: `/api/GlobalSettings/UpdateSetting`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: globalSettingModel,
    });
  };
};

export const useGlobalSettingsUpdateSettingMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGlobalSettingsUpdateSettingHook>>>,
    TError,
    { data: GlobalSettingModel },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useGlobalSettingsUpdateSettingHook>>>,
  TError,
  { data: GlobalSettingModel },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const globalSettingsUpdateSetting = useGlobalSettingsUpdateSettingHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useGlobalSettingsUpdateSettingHook>>>,
    { data: GlobalSettingModel }
  > = (props) => {
    const { data } = props ?? {};

    return globalSettingsUpdateSetting(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type GlobalSettingsUpdateSettingMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGlobalSettingsUpdateSettingHook>>>
>;
export type GlobalSettingsUpdateSettingMutationBody = GlobalSettingModel;
export type GlobalSettingsUpdateSettingMutationError = unknown;

export const useGlobalSettingsUpdateSetting = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useGlobalSettingsUpdateSettingHook>>>,
    TError,
    { data: GlobalSettingModel },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useGlobalSettingsUpdateSettingHook>>>,
  TError,
  { data: GlobalSettingModel },
  TContext
> => {
  const mutationOptions =
    useGlobalSettingsUpdateSettingMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useGlobalSettingsGetSettingsHook = () => {
  const globalSettingsGetSettings = useGlobalSettingsGetSettingsMutator<
    | GlobalSettingsGetSettings200One
    | GlobalSettingsGetSettings200Two
    | GlobalSettingsGetSettings200Three
    | GlobalSettingsGetSettings200Four
    | GlobalSettingsGetSettings200Five
  >();

  return (signal?: AbortSignal) => {
    return globalSettingsGetSettings({
      url: `/api/GlobalSettings/GetSettings`,
      method: 'GET',
      signal,
    });
  };
};

export const getGlobalSettingsGetSettingsQueryKey = () => {
  return [`/api/GlobalSettings/GetSettings`] as const;
};

export const useGlobalSettingsGetSettingsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGlobalSettingsGetSettingsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGlobalSettingsGetSettingsHook>>>,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getGlobalSettingsGetSettingsQueryKey();

  const globalSettingsGetSettings = useGlobalSettingsGetSettingsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useGlobalSettingsGetSettingsHook>>>
  > = ({ signal }) => globalSettingsGetSettings(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGlobalSettingsGetSettingsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type GlobalSettingsGetSettingsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useGlobalSettingsGetSettingsHook>>>
>;
export type GlobalSettingsGetSettingsQueryError = unknown;

export const useGlobalSettingsGetSettings = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useGlobalSettingsGetSettingsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useGlobalSettingsGetSettingsHook>>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useGlobalSettingsGetSettingsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
