import { CompanyTransportPriceModel } from 'api/model';

export type CompanyTransportPriceForm = Pick<
  CompanyTransportPriceModel,
  'fromCompanyId' | 'toCompanyId'
> & { fixedTimeInHours: number };

export const hourInMilliseconds = 1000 * 60 * 60;
export const createCompanyTransportPriceForm = (
  transportPrice: CompanyTransportPriceModel
): CompanyTransportPriceForm => {
  return {
    fromCompanyId: transportPrice.fromCompanyId,
    toCompanyId: transportPrice.toCompanyId,
    fixedTimeInHours:
      Math.round(
        transportPrice.fixedTimeInMillseconds / (hourInMilliseconds / 100)
      ) / 100,
  };
};

export const companyTransportPriceFormToCompanyTransportModel = (
  form: CompanyTransportPriceForm,
  base?: CompanyTransportPriceModel
) =>
  ({
    fromCompanyId: form.fromCompanyId,
    toCompanyId: form.toCompanyId,
    fixedTimeInMillseconds: Math.floor(
      form.fixedTimeInHours * hourInMilliseconds
    ),

    created: base?.created ?? new Date(),
    createdByID: base?.createdByID ?? -1,
    modified: base?.modified ?? new Date(),
    modifiedByID: base?.modifiedByID ?? -1,
  }) as CompanyTransportPriceModel;
