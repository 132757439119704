/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AdvancedAssignmentFilterOption =
  (typeof AdvancedAssignmentFilterOption)[keyof typeof AdvancedAssignmentFilterOption];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AdvancedAssignmentFilterOption = {
  ShowOnlyCompletedAssignments: 1,
  OnlyIncludeBookedHomeDeliveriesToCustomer: 2,
  ExcludeFurtherTransport: 3,
  HasAssignee: 4,
  FromStrangnasLogistik: 5,
} as const;
