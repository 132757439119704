import { AssignmentClient } from 'api';
import Input from 'components/inputs/Input';
import TextArea from 'components/inputs/TextArea';
import Modal from 'components/Modal';
import { useCallback, useState } from 'react';
import { useApiCall } from 'swaggerhooks/lib';

type AddStandardizedCommentModalProps = {
  onCancel(): void;
  onSaved(): void;
};

const AddStandardizedCommentModal = ({
  onCancel,
  onSaved,
}: AddStandardizedCommentModalProps) => {
  const [displayName, setDisplayName] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState<string[] | undefined>();

  const createApiCall = useApiCall(
    AssignmentClient,
    (c, displayName_: string, comment_: string) =>
      c.createStandardizedComment(displayName_, comment_)
  );

  const handleCreateClick = useCallback(async () => {
    const tErrors: string[] = [];

    if (displayName.length > 20) {
      tErrors.push('Visningsnamnet får inte vara längre än 20 tecken!');
    }

    if (displayName.length === 0) {
      tErrors.push('Du måste ange att visningsnamn!');
    }

    if (comment.length < 20) {
      tErrors.push('Kommentaren behöver vara 20 tecken eller mer!');
    }

    if (tErrors.length > 0) {
      setErrors(tErrors);
      return;
    }

    await createApiCall.run(displayName, comment);
    onSaved();
  }, [displayName, comment, createApiCall, onSaved]);

  const handleChangeDisplayName = (newDisplayName: string) => {
    if (
      newDisplayName.length > 20 &&
      errors?.includes('Visningsnamnet får inte vara längre än 20 tecken!')
    ) {
      setErrors(
        errors.reduce((acc, cur) => {
          if (cur === 'Visningsnamnet får inte vara längre än 20 tecken!') {
            return acc;
          }

          return [...acc, cur];
        }, [] as string[])
      );
    }

    if (
      newDisplayName.length > 0 &&
      errors?.includes('Du måste ange att visningsnamn!')
    ) {
      setErrors(
        errors.reduce((acc, cur) => {
          if (cur === 'Du måste ange att visningsnamn!') {
            return acc;
          }

          return [...acc, cur];
        }, [] as string[])
      );
    }

    setDisplayName(newDisplayName);
  };

  const handleChangeComment = (newComment: string) => {
    if (
      newComment.length >= 20 &&
      errors?.includes('Kommentaren behöver vara 20 tecken eller mer!')
    ) {
      setErrors(
        errors.reduce((acc, cur) => {
          if (cur === 'Kommentaren behöver vara 20 tecken eller mer!') {
            return acc;
          }

          return [...acc, cur];
        }, [] as string[])
      );
    }

    setComment(newComment);
  };

  return (
    <Modal
      buttons={[
        {
          label: 'Lägg till',
          onClick: handleCreateClick,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title="Lägg till en ny kommentar"
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: '6px',
        }}
      >
        <p>Visningsnamn</p>
        <Input
          onChange={(e) => handleChangeDisplayName(e.currentTarget.value)}
          spellCheck={false}
          style={{
            border:
              errors?.includes(
                'Visningsnamnet får inte vara längre än 20 tecken!'
              ) || errors?.includes('Du måste ange att visningsnamn!')
                ? '1px solid red'
                : '',
          }}
          value={displayName}
        />

        <p>Kommentar</p>
        <TextArea
          onChange={(e) => handleChangeComment(e.currentTarget.value)}
          spellCheck={false}
          style={{
            width: 450,
            height: 120,
            border: errors?.includes(
              'Kommentaren behöver vara 20 tecken eller mer!'
            )
              ? '1px solid red'
              : '',
          }}
          value={comment}
        />
      </div>
      {errors && (
        <div style={{ marginTop: '6px', color: 'red' }}>
          {errors.map((e, i) => (
            <p key={`add_standardized_comment_error_${i}`}>{e}</p>
          ))}
        </div>
      )}
    </Modal>
  );
};

export default AddStandardizedCommentModal;
