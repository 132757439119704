import {
  AuthenticationState,
  AuthenticationStateAction,
  AuthenticationStateActionType,
} from './types';

const authenticationStateReducer = (
  state: AuthenticationState,
  action: AuthenticationStateAction
): AuthenticationState => {
  switch (action.type) {
    case AuthenticationStateActionType.LOGIN: {
      return {
        isAuthenticated: true,
        token: action.token,
        refreshToken: action.refreshToken,
        userId: action.userId,
        error: undefined,
      };
    }

    case AuthenticationStateActionType.TOKEN_REFRESHED: {
      return {
        ...state,
        isAuthenticated: true,
        token: action.token,
        refreshToken: action.refreshToken,
        error: undefined,
      };
    }

    case AuthenticationStateActionType.SIGNOUT: {
      return {
        ...state,
        isAuthenticated: false,
        token: undefined,
        refreshToken: undefined,
        userId: undefined,
        error: undefined,
      };
    }

    case AuthenticationStateActionType.ERROR: {
      return {
        ...state,
        isAuthenticated: false,
        token: undefined,
        refreshToken: undefined,
        userId: undefined,
        error: action.error,
      };
    }

    default: {
      return state;
    }
  }
};

export default authenticationStateReducer;
