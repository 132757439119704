import { useState } from 'react';

import { faPaperPlane, faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import AuthorizedButton from 'components/inputs/AuthorizedButton';
import { generatePath, useNavigate } from 'react-router-dom';
import CreateCaseModal from 'components/CreateCaseModal';
import { useCurrentRouteCaseRoute } from 'hooks/useCurrentRouteCaseType';
import { CaseTypeEnum } from 'api';
import FilterPicker from 'components/filterBar/FilterPicker';
import FilterSearchInput from 'components/filterBar/FilterSearchInput';
import CompanyPicker from 'components/CompanyPicker';
import { fakeSelectClassName } from 'components/inputs/SearchableSelect';
import useToFromAddressAssignmentFilter from './useToFromAddresAssignmentFilter';
import Roles from 'constants/Roles';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin: 10px 10px;
`;

const MyAuthorizedButton = styled(AuthorizedButton)`
  height: 30px;
  padding: 0 10px;
  margin-right: 10px;
  font: inherit;
  white-space: nowrap;

  & > *:first-child {
    margin-right: 6px;
    font-size: 12px;
  }
`;

const SearchInputs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  margin-left: auto;
`;

const MyFilterSearchInput = styled(FilterSearchInput)`
  margin-left: 20px;
`;

const MyCompanyPicker = styled(CompanyPicker)`
  .${fakeSelectClassName} {
    height: 34px;
    align-items: center;
  }
`;

interface Props {
  onSendToFieldTestersClick(): void;
  fromToFilter: ReturnType<typeof useToFromAddressAssignmentFilter>;
}

const PendingAssignmentsFilterBar: React.FC<Props> = ({
  onSendToFieldTestersClick,
  fromToFilter,
}) => {
  const currentRouteCaseRoute = useCurrentRouteCaseRoute();
  const navigate = useNavigate();
  const [showAdd, setShowAdd] = useState<boolean>(false);

  const handleCaseCreated = (caseId: number) => {
    setShowAdd(false);
    navigate(generatePath(currentRouteCaseRoute, { id: String(caseId) }));
  };

  return (
    <Wrapper>
      <FilterPicker />

      <MyAuthorizedButton
        icon={<FontAwesomeIcon icon={faPaperPlane} />}
        onClick={onSendToFieldTestersClick}
        roles={[Roles.Admin]}
      >
        Skicka uppdrag till fälttestare
      </MyAuthorizedButton>

      <MyAuthorizedButton
        icon={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => {
          setShowAdd(true);
        }}
        roles={[Roles.Admin]}
      >
        Lägg till
      </MyAuthorizedButton>

      <SearchInputs>
        <span>
          Från:{' '}
          <MyCompanyPicker
            nullValueLabel="Alla"
            onCompanySelected={fromToFilter.setSelectedFromCompany}
            selectedCompanyId={
              fromToFilter.selectedFromCompany?.companyID ?? null
            }
          />
        </span>
        <span>
          Till:{' '}
          <MyCompanyPicker
            nullValueLabel="Alla"
            onCompanySelected={fromToFilter.setSelectedToCompany}
            selectedCompanyId={
              fromToFilter.selectedToCompany?.companyID ?? null
            }
          />
        </span>
        <MyFilterSearchInput />
      </SearchInputs>

      {showAdd && (
        <CreateCaseModal
          caseType={CaseTypeEnum.InternalDelivery}
          onCaseCreated={handleCaseCreated}
          onClose={() => {
            setShowAdd(false);
          }}
        />
      )}
    </Wrapper>
  );
};

export default PendingAssignmentsFilterBar;
