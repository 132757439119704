import { faCheck, faExternalLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentExtendedModel } from 'api';
// import MissingExpenseApprovalModal from 'components/DetailedCase/MissingExpenseApprovalModal';
import Modal from 'components/Modal';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import React, { FC, useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { RequestStatus } from 'swaggerhooks/lib';
import useAssignmentsBulkOperations from './useAssignmentsBulkOperations';
import { useCaseGetAssignmentOverlapsFromIds } from 'api/case/case';
import { AssignmentViewModel } from 'api/model';
import TextButton from 'components/inputs/TextButton';
import { useNavigate } from 'react-router-dom';
import { formatTime } from 'utils/date-helpers';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import Roles from 'constants/Roles';

const Wrapper = styled.div``;

interface Props {
  bulkOperations: ReturnType<typeof useAssignmentsBulkOperations>;
  handleAssignmentItemClick?(model: AssignmentExtendedModel): void;
}

const BulkApproval: FC<Props> = ({
  bulkOperations,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleAssignmentItemClick,
}) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [overlapsDict, setOverlapsDict] = useState<
    Record<string, AssignmentViewModel[]>
  >({});

  const overlapsCall = useCaseGetAssignmentOverlapsFromIds();

  useEffect(() => {
    const updateOverlapsDict = async () => {
      if (bulkOperations.selectedAssignmentIds.size > 0) {
        const result = await overlapsCall.mutateAsync({
          data: Array.from(bulkOperations.selectedAssignmentIds),
        });

        setOverlapsDict(result);
      } else {
        setOverlapsDict({});
      }
    };
    updateOverlapsDict();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bulkOperations.selectedAssignmentIds]);

  // const [missingExpenses, setMissingExpenses] = useState<MissingExpenses[]>([]);

  // useEffect(() => {
  //   const checkExpectedExpenses = async () => {
  //     setMissingExpenses(await bulkOperations.checkExpectedExpenses());
  //   };
  //   checkExpectedExpenses();
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [bulkOperations.selectedAssignmentIds, showApproveModal]);

  return (
    <Wrapper>
      <AuthorizedButton
        roles={[Roles.Ekonomi]}
        disabled={
          bulkOperations.selectedApprovableAssignments.length === 0 ||
          bulkOperations.approveStatus === RequestStatus.Fetching
        }
        onClick={() => setShowApproveModal(true)}
      >
        {bulkOperations.approveStatus === RequestStatus.Fetching ? (
          <TransparentSpinner color={theme.colors.foreground.button} small />
        ) : (
          `Godkänn ${bulkOperations.selectedApprovableAssignments.length || ''} uppdrag`
        )}
      </AuthorizedButton>

      {
        showApproveModal && (
          // (missingExpenses.length === 0 ? (
          <Modal
            buttons={[
              {
                label: 'Avbryt',
                onClick: () => setShowApproveModal(false),
              },
              {
                icon: <FontAwesomeIcon icon={faCheck} />,
                label: `Godkänn ${bulkOperations.selectedApprovableAssignments.length} uppdrag`,
                disabled: Object.keys(overlapsDict).length > 0,
                onClick: () => {
                  bulkOperations.approveSelectedAssignments();
                  setShowApproveModal(false);
                },
              },
            ]}
            onClose={() => setShowApproveModal(false)}
            title={`Godkänn ${bulkOperations.selectedApprovableAssignments.length} uppdrag?`}
          >
            {Object.keys(overlapsDict).length > 0 ? (
              <>
                <p>Det finns överlappande uppdrag:</p>
                <ul>
                  {Object.entries(overlapsDict).map(([key, assignments]) =>
                    assignments.map((assignment) => (
                      <li key={key}>
                        <TextButton
                          onClick={() =>
                            navigate(
                              `/sok/${bulkOperations.selectedAssignments[Number(key)].case.caseID}`
                            )
                          }
                        >
                          {`${bulkOperations.selectedAssignments[Number(key)].case.registrationNumber} #${bulkOperations.selectedAssignments[Number(key)].assignment.assignmentID} `}
                          <FontAwesomeIcon icon={faExternalLink} />
                        </TextButton>
                        {bulkOperations.selectedAssignments[Number(key)]
                          .assignment.startTime &&
                          bulkOperations.selectedAssignments[Number(key)]
                            .assignment.endTime &&
                          `(${formatTime(bulkOperations.selectedAssignments[Number(key)].assignment.startTime)} – ${formatTime(bulkOperations.selectedAssignments[Number(key)].assignment.endTime)}) `}
                        &nbsp;och&nbsp;
                        <TextButton
                          onClick={() =>
                            navigate(`/sok/${assignment.case.caseID}`)
                          }
                        >
                          {`${assignment.case.registrationNumber} #${assignment.assignment.assignmentID} `}
                          <FontAwesomeIcon icon={faExternalLink} />
                        </TextButton>
                        {assignment.assignment.startTime &&
                          assignment.assignment.endTime &&
                          `(${formatTime(assignment.assignment.startTime)} - ${formatTime(assignment.assignment.endTime)})`}
                      </li>
                    ))
                  )}
                </ul>
              </>
            ) : (
              <p>Är du säker på att du vill godkänna markerade uppdrag?</p>
            )}
          </Modal>
        )
        // ) : (
        //   <MissingExpenseApprovalModal
        //     handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
        //       handleAssignmentItemClick?.(model)
        //     }
        //     handleClose={() => setShowApproveModal(false)}
        //     handleConfirm={() => {
        //       bulkOperations.approveSelectedAssignments();
        //       setShowApproveModal(false);
        //     }}
        //     missingExpenses={missingExpenses}
        //     numSelected={bulkOperations.selectedAssignmentIds.size}
        //   />
        // ))
      }
    </Wrapper>
  );
};

export default BulkApproval;
