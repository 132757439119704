import styled, { keyframes } from 'styled-components';

const spinnerKeyframes = keyframes`
  0% { opacity: 1; }
  100% { opacity: 0; }
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: transparent;
`;

const Spinner = styled.div<{ size: number; color?: string }>`
  display: inline-block;
  position: relative;
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  div {
    animation-name: ${spinnerKeyframes};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    transform-origin: ${({ size }) => size / 2}px ${({ size }) => size / 2}px;
  }
  div::after {
    content: ' ';
    display: block;
    position: absolute;
    top: ${({ size }) => (size / 80) * 3}px;
    left: ${({ size }) => (size / 80) * 37}px;
    width: ${({ size }) => (size / 80) * 6}px;
    height: ${({ size }) => (size / 80) * 18}px;
    border-radius: 20%;
    background: ${({ theme, color }) => color ?? theme.colors.foreground.tint};
  }
  div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: -0s;
  }
`;

interface Props {
  small?: boolean;
  color?: string;
  /** if true, the spinner will inherit the current text color. */
  currentColor?: boolean;
}

const TransparentSpinner: React.FC<Props> = ({
  small,
  color,
  currentColor,
}) => {
  return (
    <Container>
      <Spinner
        color={currentColor ? 'currentColor' : color}
        size={small ? 15 : 80}
      >
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
        <div />
      </Spinner>
    </Container>
  );
};

export default TransparentSpinner;
