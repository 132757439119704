import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { useMemo } from 'react';
import { adjustDateToNoon } from 'utils/date-helpers';

export enum AvailabilityRangeOptionType {
  Day = 'day',
  Week = 'week',
  Month = 'month',
  CurrentMonth = 'currentMonth',
  FortNight = 'fortnight',
}

export interface AvailabilityRangeOption {
  type: AvailabilityRangeOptionType;
  label: string;
  showShowOnlyAvailableOption?: boolean;
  forceManualSearch?: boolean;
  getDefaultRange: () => [Date, Date];
  nextFn: (dateRange: [Date, Date]) => [Date, Date] | undefined;
  prevFn: (dateRange: [Date, Date]) => [Date, Date] | undefined;
}

export const availabilityRangeOptionsState = atom<AvailabilityRangeOption[]>({
  key: 'availabilityRangeOptionsState',
  default: [
    {
      type: AvailabilityRangeOptionType.Day,
      label: 'Dag',
      getDefaultRange: () => {
        // return tomorrow - tomorrow
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        return [
          adjustDateToNoon(tomorrow) as Date,
          adjustDateToNoon(tomorrow) as Date,
        ];
      },
      nextFn: ([start]) => {
        // adjust start and end date to next day
        if (!start) return undefined;
        const nextDay = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + 1
        );
        return [
          adjustDateToNoon(nextDay) as Date,
          adjustDateToNoon(nextDay) as Date,
        ];
      },
      prevFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to previous day
        const prevDay = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() - 1
        );
        return [
          adjustDateToNoon(prevDay) as Date,
          adjustDateToNoon(prevDay) as Date,
        ];
      },
      showShowOnlyAvailableOption: true,
    },
    {
      type: AvailabilityRangeOptionType.Week,
      label: 'Vecka',
      getDefaultRange: () => {
        // return first previous monday - next sunday
        const today = new Date();
        const monday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - today.getDay() + 1
        );
        const sunday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + (7 - today.getDay())
        );
        return [
          adjustDateToNoon(monday) as Date,
          adjustDateToNoon(sunday) as Date,
        ];
      },
      nextFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to next week
        const nextWeekStart = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + 7
        );
        const nextWeekEnd = new Date(
          nextWeekStart.getFullYear(),
          nextWeekStart.getMonth(),
          nextWeekStart.getDate() + 6
        );
        return [
          adjustDateToNoon(nextWeekStart) as Date,
          adjustDateToNoon(nextWeekEnd) as Date,
        ];
      },
      prevFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to previous week
        const prevWeekStart = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() - 7
        );
        const prevWeekEnd = new Date(
          prevWeekStart.getFullYear(),
          prevWeekStart.getMonth(),
          prevWeekStart.getDate() + 7
        );
        return [
          adjustDateToNoon(prevWeekStart) as Date,
          adjustDateToNoon(prevWeekEnd) as Date,
        ];
      },
      forceManualSearch: true,
    },
    {
      type: AvailabilityRangeOptionType.FortNight,
      label: 'Fjorton dagar',
      getDefaultRange: () => {
        // return today + 14 days
        const today = adjustDateToNoon(new Date()) as Date;
        const nextFortNight = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 13
        );
        return [
          adjustDateToNoon(today) as Date,
          adjustDateToNoon(nextFortNight) as Date,
        ];
      },
      nextFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to next fortnight
        const nextFortNightStart = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + 14
        );
        const nextFortNightEnd = new Date(
          nextFortNightStart.getFullYear(),
          nextFortNightStart.getMonth(),
          nextFortNightStart.getDate() + 13
        );
        return [
          adjustDateToNoon(nextFortNightStart) as Date,
          adjustDateToNoon(nextFortNightEnd) as Date,
        ];
      },
      prevFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to previous fortnight
        const prevFortNightStart = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() - 14
        );
        const prevFortNightEnd = new Date(
          prevFortNightStart.getFullYear(),
          prevFortNightStart.getMonth(),
          prevFortNightStart.getDate() + 13
        );
        return [
          adjustDateToNoon(prevFortNightStart) as Date,
          adjustDateToNoon(prevFortNightEnd) as Date,
        ];
      },
      forceManualSearch: true,
    },
    {
      type: AvailabilityRangeOptionType.Month,
      label: 'Månad',
      getDefaultRange: () => {
        // return first previous monday + 30 days
        const today = adjustDateToNoon(new Date()) as Date;
        const next30Days = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() + 30
        );
        return [
          adjustDateToNoon(today) as Date,
          adjustDateToNoon(next30Days) as Date,
        ];
      },
      nextFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to next fortnight
        const next30DaystStart = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() + 30
        );
        const next30DaystEnd = new Date(
          next30DaystStart.getFullYear(),
          next30DaystStart.getMonth(),
          next30DaystStart.getDate() + 30
        );
        return [
          adjustDateToNoon(next30DaystStart) as Date,
          adjustDateToNoon(next30DaystEnd) as Date,
        ];
      },
      prevFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to previous fortnight
        const prev30DaysStart = new Date(
          start.getFullYear(),
          start.getMonth(),
          start.getDate() - 30
        );
        const prev30DaysEnd = new Date(
          prev30DaysStart.getFullYear(),
          prev30DaysStart.getMonth(),
          prev30DaysStart.getDate() + 30
        );
        return [
          adjustDateToNoon(prev30DaysStart) as Date,
          adjustDateToNoon(prev30DaysEnd) as Date,
        ];
      },
      forceManualSearch: true,
    },
    {
      type: AvailabilityRangeOptionType.CurrentMonth,
      label: 'Innevarande månad',
      getDefaultRange: () => {
        // return first day of current month to last day of current month
        const today = adjustDateToNoon(new Date()) as Date;
        const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth());
        const lastDayOfMonth = new Date(
          today.getFullYear(),
          today.getMonth() + 1,
          0
        );

        return [
          adjustDateToNoon(firstDayOfMonth) as Date,
          adjustDateToNoon(lastDayOfMonth) as Date,
        ];
      },
      nextFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to next month
        const nextMonthStart = new Date(
          start.getFullYear(),
          start.getMonth() + 1,
          1
        );
        const nextMonthEnd = new Date(
          nextMonthStart.getFullYear(),
          nextMonthStart.getMonth() + 1,
          0
        );
        return [
          adjustDateToNoon(nextMonthStart) as Date,
          adjustDateToNoon(nextMonthEnd) as Date,
        ];
      },
      prevFn: ([start]) => {
        if (!start) return undefined;
        // adjust start and end date to previous month
        const prevMonthStart = new Date(
          start.getFullYear(),
          start.getMonth() - 1,
          1
        );
        const prevMonthEnd = new Date(
          prevMonthStart.getFullYear(),
          prevMonthStart.getMonth() + 1,
          0
        );

        return [
          adjustDateToNoon(prevMonthStart) as Date,
          adjustDateToNoon(prevMonthEnd) as Date,
        ];
      },
      forceManualSearch: true,
    },
  ],
});
export const currentAvailabilityRangeOptionState = atom({
  key: 'currentAvailabilityRangeOptionState',
  default: AvailabilityRangeOptionType.Day,
});

export const currentAvailabilityRangeState = selector({
  key: 'currentAvailabilityRangeState',
  get: ({ get }) => {
    const currentOption = get(currentAvailabilityRangeOptionState);
    const rangeOptions = get(availabilityRangeOptionsState);
    return rangeOptions.find((ro) => ro.type === currentOption);
  },
});

export const useAvailabilityRangeOptions = () => {
  const [currentOption, setCurrentOption] = useRecoilState(
    currentAvailabilityRangeOptionState
  );

  const rangeOptions = useRecoilValue(availabilityRangeOptionsState);

  const currentRange = useMemo(() => {
    return rangeOptions.find((ro) => ro.type === currentOption);
  }, [currentOption, rangeOptions]);

  return { rangeOptions, currentRange, setCurrentOption };
};
