import { AssignmentViewModel, CompanyModel } from 'api';

function addressMatches(
  company: CompanyModel,
  name: string,
  address: string,
  zip: string,
  city: string
) {
  return (
    name === company.name &&
    address === company.address &&
    zip === company.zip &&
    city === company.city
  );
}

// eslint-disable-next-line import/prefer-default-export
export function assignmentAddressesMatches(
  assignments: AssignmentViewModel[],
  fromAddress?: CompanyModel | null,
  toAddress?: CompanyModel | null
) {
  const filteredFrom = fromAddress
    ? assignments.filter((a) =>
        addressMatches(
          fromAddress,
          a.assignment.fromName,
          a.assignment.fromAddress,
          a.assignment.fromZip,
          a.assignment.fromCity
        )
      )
    : assignments;

  const filteredTo = toAddress
    ? filteredFrom.filter((a) =>
        addressMatches(
          toAddress,
          a.assignment.toName,
          a.assignment.toAddress,
          a.assignment.toZip,
          a.assignment.toCity
        )
      )
    : filteredFrom;

  return filteredTo;
}
