import { useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal';
import CSVReader from 'react-csv-reader';
import {
  BulkImportFromExternalProviderDto,
  ExternalReceiptProvider,
} from 'api/model';
import moment from 'moment';
import { useReceiptBulkImportFromExternalProvider } from 'api/receipt/receipt';
import Table from 'components/Table';
import { formatDateTime } from 'utils/date-helpers';
import {
  makeDateComparator,
  makeNumberComparator,
  makeStringComparator,
} from 'utils/sorting';
import LoadingSpinner from 'components/spinners/LoadingSpinner';

const FormWrap = styled.div<{ maxWidth?: number; compact?: boolean }>`
  margin-top: 10px;
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ compact }) => (compact ? '0' : '10px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;

type Props = {
  onCancel: () => void;
  onSaved: () => void;
};
const UploadCsvModal: React.FC<Props> = ({ onCancel, onSaved }) => {
  const [file, setFile] = useState<BulkImportFromExternalProviderDto[]>([]);
  const [uploadMessage, setUploadMessage] = useState<string>('');

  const handleFilePicked = (data: any) => {
    const headers = data[0];
    const dateIndex = headers.indexOf('Date');
    const transactionIdIndex = headers.indexOf('Transaction ID');
    const cardIndex = headers.indexOf('Card');
    const stationIndex = headers.indexOf('Station');
    const receiptIndex = headers.indexOf('Receipt');
    const productIndex = headers.indexOf('Product');
    const volumeIndex = headers.indexOf('Volume');
    const totalIndex = headers.indexOf('Total');
    const unitIndex = headers.indexOf('Unit');
    const formattedData = data.slice(1).map((row: any) => {
      return {
        date: moment(row[dateIndex], 'DD.MM.YYYY HH:mm').toDate(),
        // skipping partnerName
        // skipping cardGroupType
        transactionId: row[transactionIdIndex],
        card: row[cardIndex],
        // skipping cardTextLine2
        station: row[stationIndex],
        receipt: row[receiptIndex],
        // skipping country
        product: row[productIndex],
        volume: row[volumeIndex],
        total: row[totalIndex],
        unit: row[unitIndex],
        // skipping status
      };
    });
    setFile(formattedData);
  };

  const uploadCall = useReceiptBulkImportFromExternalProvider();

  const handleConfirmUpload = async () => {
    if (!file.length) {
      return;
    }

    // split the file into chunks of 50
    const data = [...file];
    const chunkSize = 50;
    const chunkedFile = [];
    for (let i = 0; i < data.length; i += chunkSize) {
      chunkedFile.push(data.slice(i, i + chunkSize));
    }

    // Upload each chunk sequentially
    let i = 0;
    for (const chunk of chunkedFile) {
      i += 1;
      const percentageOfTotal = (i / chunkedFile.length) * 100;
      setUploadMessage(`Laddar upp (${percentageOfTotal.toFixed(0)}%)`);
      // eslint-disable-next-line no-await-in-loop
      await uploadCall.mutateAsync({
        data: chunk, // Upload the current chunk
        params: {
          provider: ExternalReceiptProvider.CircleK,
        },
      });
    }

    setUploadMessage('');

    onSaved();
  };

  return (
    <Modal
      buttons={[
        {
          label: '+ Ladda upp',
          onClick: handleConfirmUpload,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title="Ladda upp CSV-fil"
    >
      {uploadCall.isLoading ? (
        <LoadingSpinner>
          <span>{uploadMessage}</span>
        </LoadingSpinner>
      ) : (
        <>
          <p>Välj en CSV-fil från Circle Ks kundportal att ladda upp.</p>
          <FormWrap>
            <CSVReader
              cssClass="csv-input"
              inputId="csv-file-input"
              inputName="csv-file-input"
              onError={(_err) => {
                // console.log('error', err);
              }}
              onFileLoaded={handleFilePicked}
              parserOptions={{}}
            />
            {file.length > 0 && (
              <Table
                rows={file}
                columnSettings={[
                  {
                    head: 'Nr',
                    render: (_, props, focused, index) => index + 1,
                    width: 50,
                  },
                  {
                    head: 'Transaktion',
                    render: (row) => row.transactionId,
                    width: 150,
                    sortFunction: makeStringComparator(
                      (row) => row.transactionId
                    ),
                  },
                  {
                    head: 'Datum',
                    render: (row) => formatDateTime(row.date),
                    width: 150,
                    sortFunction: makeDateComparator((row) => row.date),
                  },
                  {
                    head: 'Kortnummer',
                    render: (row) => row.card,
                    width: 150,
                    sortFunction: makeStringComparator((row) => row.card),
                  },
                  {
                    head: 'Station',
                    render: (row) => row.station,
                    width: 150,
                    sortFunction: makeStringComparator((row) => row.station),
                  },
                  {
                    head: 'Kvitto',
                    render: (row) => row.receipt,
                    width: 150,
                    sortFunction: makeStringComparator(
                      (row) => row.receipt ?? ''
                    ),
                  },
                  {
                    head: 'Produkt',
                    render: (row) => row.product,
                    width: 150,
                    sortFunction: makeStringComparator((row) => row.product),
                  },
                  {
                    head: 'Volym',
                    render: (row) => row.volume,
                    width: 150,
                    sortFunction: makeNumberComparator((row) => row.volume),
                  },
                  {
                    head: 'Totalt',
                    render: (row) => row.total,
                    width: 150,
                    sortFunction: makeNumberComparator((row) => row.total),
                  },
                  {
                    head: 'Enhet',
                    render: (row) => row.unit,
                    width: 150,
                    sortFunction: makeStringComparator((row) => row.unit),
                  },
                ]}
              />
            )}
          </FormWrap>
        </>
      )}
    </Modal>
  );
};

export default UploadCsvModal;
