import ConfirmModal from 'components/Modal/ConfirmModal';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import React, { useState } from 'react';
import styled from 'styled-components';

const StyledAutoSizedTextArea = styled(AutoSizedTextArea)`
  margin-top: 10px;
  min-height: 100px;
  width: 100%;
`;

interface Props {
  onCancel: () => void;
  onConfirm: (message: string) => void;
}

const ConfirmDenyAssignmentRequestModal: React.FC<Props> = ({
  onConfirm,
  onCancel,
}) => {
  const [message, setMessage] = useState<string>('');

  return (
    <ConfirmModal
      title="Bekräfta avslå förfrågan"
      onCancel={onCancel}
      content="Är du säker på att du vill avslå förfrågan?"
      onConfirm={() => onConfirm(message)}
    >
      <StyledAutoSizedTextArea
        placeholder="Ange anledning"
        value={message}
        onChange={(eve) => setMessage(eve.currentTarget.value)}
      />
    </ConfirmModal>
  );
};

export default ConfirmDenyAssignmentRequestModal;
