import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentExtendedModel } from 'api';
import Button from 'components/inputs/Button';
import React, { FC } from 'react';
import styled from 'styled-components';
import useAssignmentsBulkOperations from './useAssignmentsBulkOperations';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 10px;
  gap: 20px;
`;
const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 10px;
  gap: 20px;
`;

interface Props {
  bulkActions: React.ReactNode[];
  bulkOperations: ReturnType<typeof useAssignmentsBulkOperations>;
  handleAssignmentItemClick?(model: AssignmentExtendedModel): void;
}

const BulkOperations: FC<Props> = ({
  bulkActions,
  bulkOperations,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  handleAssignmentItemClick,
}) => {
  return (
    <Wrapper>
      <Left>
        <Button
          icon={<FontAwesomeIcon icon={faSquareCheck} />}
          onClick={() => bulkOperations.selectAllApprovableAssignments()}
        >
          Välj alla
        </Button>
        {bulkOperations.selectedAssignmentIds.size > 0 && (
          <Button onClick={() => bulkOperations.clearSelectedAssignments()}>
            Rensa val
          </Button>
        )}
      </Left>

      <Right>
        {bulkActions.map((action, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={`action-${index}`}>{action}</div>
        ))}
      </Right>
    </Wrapper>
  );
};

export default BulkOperations;
