import { useUserGetAssignedTimesForUser } from 'api/user/user';
import { useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  position: absolute;
  top: 180px;
  right: 20px;
  padding: 20px;
  background: rgb(166, 166, 166, 0.8);
  border: 3px solid darkgrey;
  border-radius: 12px;
  z-index: 100;
`;

const ButtonDiv = styled.div`
  display: flex;
  position: absolute;
  top: 6px;
  right: 6px;

  .btn {
    font-size: 18px;
    outline: none;
    border: none;
    background: transparent;

    &:hover {
      font-weight: bold;
    }
  }
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
`;

const ToggleButton = styled.button`
  margin-left: 20px;
`;

const AssignedTimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  padding-bottom: 3px;
`;

const Span = styled.div`
  display: flex;
  min-width: 180px;
  flex-wrap: nowrap;
`;

export const showAssignedTimesAtom = atom<boolean>({
  key: 'ShowAssignedTimesState',
  default: false,
});

const AssignedTimesDebug = () => {
  const [polling, setPolling] = useState(true);
  const [ats, set] = useRecoilState(showAssignedTimesAtom);
  const { data: assignedTimes, refetch } = useUserGetAssignedTimesForUser({
    query: {
      enabled: false,
    },
  });

  useEffect(() => {
    let id: NodeJS.Timeout | undefined;
    if (ats) {
      if (polling) {
        id = setInterval(() => refetch(), 1000);
      } else {
        clearInterval(id);
      }
    }

    return () => {
      if (id) {
        clearInterval(id);
      }
    };
  }, [ats, refetch, polling]);

  return (
    <Container>
      <h1>AssignedTimes</h1>
      <ButtonDiv>
        <button className="btn" onClick={() => set(false)} type="button">
          x
        </button>
      </ButtonDiv>
      <ButtonRow>
        <span>
          Polling: <strong>{polling ? 'På' : 'Av'}</strong>
        </span>
        <ToggleButton onClick={() => setPolling(!polling)} type="button">
          Toggle polling
        </ToggleButton>
      </ButtonRow>
      <AssignedTimeContainer>
        <Span style={{ fontWeight: 'bold' }}>start</Span>
        <Span style={{ fontWeight: 'bold' }}>end</Span>
      </AssignedTimeContainer>
      {assignedTimes &&
        assignedTimes.length > 0 &&
        assignedTimes?.map((at) => (
          <AssignedTimeContainer
            key={`${
              at.assignmentId
            }_${at.start.toString()}_${at.end.toString()}`}
          >
            <Span>{at.start.toString().replace('+00:00', '')}</Span>
            <Span>{at.end.toString().replace('+00:00', '')}</Span>
          </AssignedTimeContainer>
        ))}
      {assignedTimes && assignedTimes.length === 0 && <span>Inga</span>}
    </Container>
  );
};

export default AssignedTimesDebug;
