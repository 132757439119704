import { AssignmentStatusEnum, AssignmentViewModel } from 'api';
import Routes from 'constants/Routes';
import { matchPath } from 'react-router-dom';

function addressIncludes(
  address: string,
  zip: string,
  city: string,
  searchText: string
) {
  return (
    address.toLowerCase().includes(searchText) ||
    zip.toLowerCase().includes(searchText) ||
    city.toLowerCase().includes(searchText)
  );
}

function assignmentsSearchFunction(
  assignments: AssignmentViewModel[],
  searchText: string,
  visibleStatuses: Set<AssignmentStatusEnum> | null,
  visibleBusinessUnits: number[],
  currentRoute: string
) {
  const lowerSearchText = searchText.toLowerCase();

  const isHomeDeliveriesRoute =
    !!matchPath(Routes.homeDeliveries.index, currentRoute) ||
    !!matchPath(Routes.homeDeliveries.case, currentRoute);

  const isPurchasesRoute =
    !!matchPath(Routes.purchases.index, currentRoute) ||
    !!matchPath(Routes.purchases.case, currentRoute);

  const isPendingAssignmentsRoute =
    !!matchPath(Routes.pendingAssignments.index, currentRoute) ||
    !!matchPath(Routes.pendingAssignments.case, currentRoute);

  return assignments
    .filter(
      (avm) =>
        avm.assignment.assignmentStatusID !== AssignmentStatusEnum.Discarded
    )
    .filter((avm) =>
      visibleStatuses
        ? visibleStatuses.has(avm.assignment.assignmentStatusID)
        : true
    )
    .filter((avm) =>
      visibleBusinessUnits.length > 0
        ? avm.assignment.assignedTo?.businessUnitID != null &&
          visibleBusinessUnits.includes(
            avm.assignment.assignedTo.businessUnitID
          )
        : true
    )
    .filter(
      (avm) =>
        avm.case.registrationNumber.toLowerCase().startsWith(lowerSearchText) ||
        avm.assignment.fromName.toLowerCase().includes(lowerSearchText) ||
        avm.assignment.toName.toLowerCase().includes(lowerSearchText) ||
        avm.case.bookerName.toLowerCase().includes(lowerSearchText) ||
        avm.case.customerName.toLowerCase().includes(lowerSearchText) ||
        (avm.assignment.assignedTo != null &&
          avm.assignment.assignedTo.name
            .toLowerCase()
            .includes(lowerSearchText)) ||
        ((isPurchasesRoute || isPendingAssignmentsRoute) &&
          avm.assignment.fromCounty?.areaName
            .toLowerCase()
            .includes(lowerSearchText)) ||
        (isHomeDeliveriesRoute &&
          avm.assignment.toCounty?.areaName
            .toLowerCase()
            .includes(lowerSearchText)) ||
        addressIncludes(
          avm.assignment.fromAddress,
          avm.assignment.fromZip,
          avm.assignment.fromCity,
          lowerSearchText
        ) ||
        addressIncludes(
          avm.assignment.toAddress,
          avm.assignment.toZip,
          avm.assignment.toCity,
          lowerSearchText
        )
    );
}

export default assignmentsSearchFunction;
