/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type InNeedOfHandlingTypeEnum =
  (typeof InNeedOfHandlingTypeEnum)[keyof typeof InNeedOfHandlingTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const InNeedOfHandlingTypeEnum = {
  NONE: 0,
  DRIVING_POLICY: 100,
  HAS_NOT_BEEN_STARTED_IN_TIME: 200,
  EXPECTED_TO_START_LATE: 300,
  BREAKS_IS_WITHIN_ALLOWED_DRIVING_HOURS: 400,
  BREAKS_HAS_CONSECUTIVE_HOURS_OF_REST_LAST_24_HOURS: 500,
  BREAKS_HAS_CONSECUTIVE_HOURS_OF_REST_LAST_7_DAYS: 600,
  BREAKS_HAS_CONSECUTIVE_HOURS_OF_REST_THIS_WEEK: 700,
} as const;
