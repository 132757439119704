import { AssignmentClient, AssignmentViewModel } from 'api';
import useAuthenticationState from 'contexts/authentication/useAuthentication';
import useUpdateInterval from 'hooks/useUpdateInterval';
import { useCallback, useEffect, useMemo } from 'react';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';

const UPDATE_INTERVAL = 10 * 1000;

const rideUpdatesAtom = atom<AssignmentViewModel[] | null>({
  key: 'rideUpdatesAtom',
  default: null,
});

const rideUpdatesCountAtom = atom<number>({
  key: 'rideUpdatesCountAtom',
  default: 0,
});

export const useRideUpdatesCountPoll = () => {
  const setRideUpdatesCount = useSetRecoilState(rideUpdatesCountAtom);

  const getUpdatesCountCall = useApiCall(AssignmentClient, (c) =>
    c.getAssignmentsWithNewDataFromRideCount()
  );

  const {
    state: { isAuthenticated },
  } = useAuthenticationState();

  const { startIntervalImmediately, stopInterval } = useUpdateInterval(
    async () => {
      const [count] = await getUpdatesCountCall.run();
      if (!Number.isNaN(count) && count !== null) {
        setRideUpdatesCount(count);
      }
    },
    UPDATE_INTERVAL
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (isAuthenticated) {
      setTimeout(() => startIntervalImmediately(), 0);
    } else {
      stopInterval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);
};

const useRideUpdates = () => {
  const [rideUpdates, setRideUpdates] = useRecoilState(rideUpdatesAtom);
  const [rideUpdatesCount, setRideUpdatesCount] =
    useRecoilState(rideUpdatesCountAtom);

  const getUpdatesCall = useApiCall(AssignmentClient, (c) =>
    c.getAssignmentsWithNewDataFromRide()
  );

  const getUpdatesCountCall = useApiCall(AssignmentClient, (c) =>
    c.getAssignmentsWithNewDataFromRideCount()
  );

  const refetchRideUpdates = useCallback(async () => {
    const [updates] = await getUpdatesCall.run();
    const [count] = await getUpdatesCountCall.run();

    if (updates) {
      setRideUpdates(updates);
    }
    if (!Number.isNaN(count) && count !== null) {
      setRideUpdatesCount(count);
    }
  }, [
    getUpdatesCall,
    getUpdatesCountCall,
    setRideUpdates,
    setRideUpdatesCount,
  ]);

  return useMemo(
    () => ({
      loading: getUpdatesCall.status === RequestStatus.Fetching,
      rideUpdates,
      rideUpdatesCount,
      refetchRideUpdates,
    }),
    [getUpdatesCall.status, refetchRideUpdates, rideUpdates, rideUpdatesCount]
  );
};

export default useRideUpdates;
