import { UserClient, UserModel } from 'api';
import { atom, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks/lib';

export const usersAtom = atom<{ [userId: string]: UserModel }>({
  key: 'usersLookup',
  default: {},
});

export const useRefreshUsersCall = () => {
  const [, setState] = useRecoilState(usersAtom);

  const apiCall = useApiCall(UserClient, async (c) => {
    const r = await c.getUsers();

    setState(r);

    return r;
  });

  return apiCall;
};

const useUsers = () => {
  return useRecoilValue(usersAtom);
};

export default useUsers;
