import {
  faDownload,
  faFileExcel,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExportClient, FileResponse } from 'api';
import Button from 'components/inputs/Button';
import Checkbox from 'components/inputs/Checkbox';
import TextButton from 'components/inputs/TextButton';
import SectionHeader from 'components/SectionHeader';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import Routes from 'constants/Routes';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import DateSpanPicker from '../../components/inputs/DateSpanPicker';
import DatePicker, { CustomInput } from 'components/inputs/DatePicker';
import LabelWrap from 'components/inputs/LabelWrap';
import { inputStyle } from 'components/inputs/Input';
import { adjustDateToNoon, formatDate } from 'utils/date-helpers';
import { AutorizedCheckbox } from 'pages/InternalDeliveryGroup/components';
import Roles from 'constants/Roles';
import useMe from 'contexts/authentication/useMe';
import {
  useExportExportAvailabilityData,
  useExportExportUserRatingData,
} from 'api/export/export';
import { showException } from 'utils/exception-helper';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
`;

const Horizontal = styled.div<{ align?: 'top' }>`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  margin: 10px 0px;
  gap: 10px;
`;

const Section = styled.section<{ compact?: boolean }>`
  width: 100%;
  max-width: 1000px;

  margin-bottom: ${(props) => (props.compact ? 20 : 100)}px;
`;

const SectionContent = styled.div`
  padding: 10px 0;
`;

const MyCheckbox = styled(Checkbox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px;
`;

const MyAuthorizedCheckbox = styled(AutorizedCheckbox)`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 6px;
`;

const MyButton = styled(Button)`
  ${inputStyle}
  background-color: ${(props) => props.theme.colors.background.button};
  color: ${(props) => props.theme.colors.foreground.button};
  height: 30px;
  border-style: none;
`;

const SubTitle = styled.h3`
  padding: 10px 0px;
  font-size: ${({ theme }) => theme.sizes.font.small};
`;

const HogiaSelections = styled.div`
  display: flex;
  gap: 10px;
`;

function downloadFileResponse(fileResponse: FileResponse) {
  const url = URL.createObjectURL(fileResponse.data);

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileResponse.fileName
    ? window.decodeURI(fileResponse.fileName)
    : 'Export.xls';
  anchor.click();
}

function downloadBlob(blob: Blob, fileName: string) {
  const url = URL.createObjectURL(blob);

  const anchor = document.createElement('a');
  anchor.href = url;
  anchor.download = fileName;
  anchor.click();
}

const ExcelExports: FC = () => {
  const navigate = useNavigate();
  const me = useMe();
  const hasEconomyRole = me?.roles.includes(Roles.Ekonomi);

  const exportInvoiceStatsCall = useApiCall(
    ExportClient,
    (c, startDate: Date, endDate: Date, includeNotYetClosed: boolean) =>
      c.exportInvoiceStatistics(startDate, endDate, includeNotYetClosed)
  );

  // const exportInvoiceStatsV2Call = useApiCall(
  //   ExportClient,
  //   (c, startDate: Date, endDate: Date, includeNotYetClosed: boolean) =>
  //     c.exportInvoiceStatisticsV2(startDate, endDate, includeNotYetClosed)
  // );

  const exportInvoiceDataCall = useApiCall(
    ExportClient,
    (c, endDate: Date, markAsInvoiced: boolean) =>
      c.exportInvoiceData(endDate, markAsInvoiced)
  );

  const exportAvailabilityDataCall = useExportExportAvailabilityData();
  const exportUserRatingDataCall = useExportExportUserRatingData();

  const exportRestingTimesCall = useApiCall(
    ExportClient,
    (c, startDate: Date, endDate: Date) =>
      c.exportRestingTimes(startDate, endDate, true)
  );

  const [markCasesAsInvoiced, setMarkCasesAsInvoiced] =
    useState<boolean>(false);

  const [includeNotYetClosed, setIncludeNotYetClosed] =
    useState<boolean>(false);

  const exportPendingCall = useApiCall(ExportClient, (c) => c.exportPending());
  const exportSalaryCall = useApiCall(
    ExportClient,
    (c, startDate: Date, endDate: Date) => c.exportSalary(startDate, endDate)
  );
  const exportHogiaSalaryCall = useApiCall(
    ExportClient,
    (c, startDate: Date, endDate: Date) =>
      c.exportHogiaSalary(startDate, endDate)
  );

  const now = new Date();
  const lastMonthStart =
    adjustDateToNoon(new Date(now.getFullYear(), now.getMonth() - 1, 1)) ??
    undefined;
  const lastMonthEnd = adjustDateToNoon(
    new Date(now.getFullYear(), now.getMonth(), 0)
  ) as Date;

  const [invoiceDate, setInvoiceDate] = useState<Date>(now);

  const getPendingClick = async () => {
    const [result, error] = await exportPendingCall.run();

    if (result && !error) {
      downloadFileResponse(result);
    }
  };

  const handleDownloadInvoice = async () => {
    const [result, error] = await exportInvoiceDataCall.run(
      invoiceDate,
      markCasesAsInvoiced
    );
    if (result && !error) {
      downloadFileResponse(result);
    }
  };

  return (
    <Wrapper>
      <Section>
        <SectionHeader>Löneunderlag</SectionHeader>
        <SectionContent>
          <DateSpanPicker
            initialFrom={lastMonthStart}
            initialTo={lastMonthEnd}
            loading={exportSalaryCall.status === RequestStatus.Fetching}
            okButtonLabel={
              <>
                <FontAwesomeIcon icon={faDownload} /> Hämta löneunderlag
              </>
            }
            onDatesSelected={async ({ fromDate, toDate }) => {
              const [result, error] = await exportSalaryCall.run(
                fromDate,
                toDate
              );

              if (result && !error) {
                downloadFileResponse(result);
              }
            }}
            populateHolidays
          />
        </SectionContent>
        {hasEconomyRole && (
          <SectionContent>
            <SubTitle>Export till Hogia</SubTitle>
            <HogiaSelections>
              <DateSpanPicker
                initialFrom={lastMonthStart}
                initialTo={lastMonthEnd}
                loading={
                  exportHogiaSalaryCall.status === RequestStatus.Fetching
                }
                okButtonLabel={
                  <>
                    <FontAwesomeIcon icon={faDownload} /> Hämta Hogia-export
                  </>
                }
                onDatesSelected={async ({ fromDate, toDate }) => {
                  const [result, error] = await exportHogiaSalaryCall.run(
                    fromDate,
                    toDate
                  );
                  if (result && !error) {
                    downloadFileResponse(result);
                  }
                }}
                populateHolidays
                hasDropdowns
              />
            </HogiaSelections>
          </SectionContent>
        )}
      </Section>

      <Section>
        <SectionHeader>Faktureringsunderlag</SectionHeader>

        <SectionContent
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Horizontal>
            <LabelWrap label="Till">
              <DatePicker
                calendarStartDay={1}
                customInput={<CustomInput date={invoiceDate} />}
                dateFormat="yyyy-MM-dd"
                disabled={
                  exportInvoiceDataCall.status === RequestStatus.Fetching
                }
                locale="sv"
                onChange={(date) => {
                  if (date) {
                    setInvoiceDate(date);
                  }
                }}
                populateHolidays
                selected={invoiceDate}
              />
            </LabelWrap>
            <MyButton
              disabled={exportInvoiceDataCall.status === RequestStatus.Fetching}
              onClick={handleDownloadInvoice}
            >
              {exportInvoiceDataCall.status === RequestStatus.Fetching ? (
                <FontAwesomeIcon icon={faSpinner} spin />
              ) : (
                <FontAwesomeIcon icon={faDownload} />
              )}
              <span> Hämta underlag</span>
            </MyButton>
            <MyAuthorizedCheckbox
              checked={markCasesAsInvoiced}
              onChange={(event) =>
                setMarkCasesAsInvoiced(event.currentTarget.checked)
              }
              roles={[Roles.Ekonomi]}
            >
              Markera som fakturerat
            </MyAuthorizedCheckbox>
          </Horizontal>
        </SectionContent>
        <SectionContent>
          {exportInvoiceDataCall.status === RequestStatus.Fetching && (
            <span>
              Hämtar underlag. Det kan upp till ett par minuter beroende på hur
              mycket data som behöver gås igenom.
            </span>
          )}
        </SectionContent>
        <SectionContent>
          <TextButton
            onClick={() => {
              navigate(Routes.excelExports.history);
            }}
          >
            <FontAwesomeIcon icon={faFileExcel} /> Visa exporthistorik
          </TextButton>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Statistikunderlag</SectionHeader>

        <SectionContent>
          <Horizontal>
            <DateSpanPicker
              initialFrom={lastMonthStart}
              initialTo={lastMonthEnd}
              loading={exportInvoiceStatsCall.status === RequestStatus.Fetching}
              okButtonLabel={
                <>
                  <FontAwesomeIcon icon={faDownload} /> Hämta underlag
                </>
              }
              onDatesSelected={async ({ fromDate, toDate }) => {
                const [result, error] = await exportInvoiceStatsCall.run(
                  fromDate,
                  toDate,
                  includeNotYetClosed
                );

                if (result && !error) {
                  downloadFileResponse(result);
                }
              }}
              populateHolidays
            />
            <MyCheckbox
              checked={includeNotYetClosed}
              onChange={(event) =>
                setIncludeNotYetClosed(event.currentTarget.checked)
              }
            >
              Inkludera ännu ej stängda ärenden
            </MyCheckbox>
          </Horizontal>
        </SectionContent>
      </Section>

      <Section>
        <SectionHeader>Vilotidsunderlag</SectionHeader>

        <Horizontal>
          <DateSpanPicker
            initialFrom={lastMonthStart}
            initialTo={lastMonthEnd}
            loading={exportRestingTimesCall.status === RequestStatus.Fetching}
            okButtonLabel={
              <>
                <FontAwesomeIcon icon={faDownload} /> Hämta underlag
              </>
            }
            onDatesSelected={async ({ fromDate, toDate }) => {
              const [result, error] = await exportRestingTimesCall.run(
                fromDate,
                toDate
              );

              if (result && !error) {
                downloadFileResponse(result);
              }
            }}
            populateHolidays
          />
        </Horizontal>
      </Section>

      <Section>
        <SectionHeader>Tillgänglighetsunderlag</SectionHeader>

        <Horizontal>
          <DateSpanPicker
            initialFrom={lastMonthStart}
            initialTo={lastMonthEnd}
            loading={exportRestingTimesCall.status === RequestStatus.Fetching}
            okButtonLabel={
              <>
                <FontAwesomeIcon icon={faDownload} /> Hämta underlag
              </>
            }
            onDatesSelected={async ({ fromDate, toDate }) => {
              try {
                const result = await exportAvailabilityDataCall.mutateAsync({
                  params: {
                    StartDate: fromDate,
                    EndDate: toDate,
                  },
                });
                if (result) {
                  downloadBlob(result, 'Tillgänglighetsunderlag.xlsx');
                }
              } catch (error) {
                showException(error);
              }
            }}
            populateHolidays
          />
        </Horizontal>
      </Section>

      <Section>
        <SectionHeader>Utvärderingsunderlag</SectionHeader>

        <Horizontal>
          <DateSpanPicker
            initialFrom={lastMonthStart}
            initialTo={lastMonthEnd}
            loading={exportRestingTimesCall.status === RequestStatus.Fetching}
            okButtonLabel={
              <>
                <FontAwesomeIcon icon={faDownload} /> Hämta underlag
              </>
            }
            onDatesSelected={async ({ fromDate, toDate }) => {
              try {
                const result = await exportUserRatingDataCall.mutateAsync({
                  params: {
                    StartDate: fromDate,
                    EndDate: toDate,
                  },
                });
                if (result) {
                  downloadBlob(
                    result,
                    `Utvärderingsunderlag_${formatDate(fromDate)}_${formatDate(toDate)}.xlsx`
                  );
                }
              } catch (error) {
                showException(error);
              }
            }}
            populateHolidays
          />
        </Horizontal>
      </Section>

      <Section>
        <SectionHeader>Inventarie</SectionHeader>

        <SectionContent>
          <Button
            disabled={exportPendingCall.status === RequestStatus.Fetching}
            onClick={getPendingClick}
            style={{ width: 220 }}
          >
            {exportPendingCall.status === RequestStatus.Fetching ? (
              <TransparentSpinner currentColor small />
            ) : (
              <>
                <FontAwesomeIcon icon={faDownload} /> Hämta inventarie
              </>
            )}
          </Button>
        </SectionContent>
      </Section>
    </Wrapper>
  );
};

export default ExcelExports;
