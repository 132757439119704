import { FC, useMemo, useState } from 'react';
import { FileClient } from 'api';
import ErrorTryAgain from 'components/ErrorTryAgain';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import styled from 'styled-components';
import { RequestStatus, useResponse } from 'swaggerhooks/lib';
import { Document, Page, pdfjs } from 'react-pdf';
import Button from 'components/inputs/Button';
import {
  faChevronLeft,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const Image = styled.img`
  max-width: calc(80vw - 100px);
  max-height: calc(80vh - 100px);
`;

const PDFBrowserPanel = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 20px;
  font-weight: 600;
`;

interface PDFViewerProps {
  file: string;
  maxHeight?: number;
  browsable?: boolean;
}

const PDFViewer: FC<PDFViewerProps> = ({ file, maxHeight, browsable }) => {
  const [numPages, setNumPages] = useState<number>(1);
  const [pageIndex, setPageIndex] = useState<number>(0);

  const goBack = () => {
    setPageIndex((prev) => Math.max(prev - 1, 0));
  };

  const goForth = () => {
    setPageIndex((prev) => Math.min(prev + 1, numPages - 1));
  };

  return (
    <Document
      file={file}
      onLoadSuccess={async (pdf) => {
        setNumPages(await pdf.numPages);
      }}
    >
      <Page height={maxHeight} pageIndex={pageIndex} renderTextLayer={false} />
      {browsable && (
        <PDFBrowserPanel>
          <Button
            disabled={pageIndex <= 0}
            icon={<FontAwesomeIcon icon={faChevronLeft} />}
            onClick={goBack}
          />
          <span>
            {pageIndex + 1} / {numPages}
          </span>
          <Button
            disabled={pageIndex >= numPages - 1}
            icon={<FontAwesomeIcon icon={faChevronRight} />}
            onClick={goForth}
          />
        </PDFBrowserPanel>
      )}
    </Document>
  );
};

interface FileProps {
  imageB64Url: string;
  mimeType: string;
  maxHeight?: number;
  browsable?: boolean;
}
export const FileViewer: FC<FileProps> = ({
  imageB64Url,
  mimeType,
  maxHeight,
  browsable,
}) => {
  // const [currentPageIndex, setCurrentPageIndex] = useState<number>(0);
  switch (mimeType) {
    case 'application/pdf':
      return (
        <PDFViewer
          browsable={browsable}
          file={imageB64Url}
          maxHeight={maxHeight}
        />
      );
    default:
      return <Image alt="Kvittobild" src={imageB64Url} />;
  }
};

interface Props {
  fileGuid: string;
  fileEnding: string;
  maxHeight?: number;
  browsable?: boolean;
}

export const GenericFileViewer: FC<Props> = ({
  fileGuid,
  fileEnding,
  maxHeight,
  browsable,
}) => {
  const {
    response: imageResponse,
    status,
    update,
  } = useResponse(FileClient, (c) => c.getFile(fileGuid, fileEnding), [
    fileGuid,
    fileEnding,
  ]);

  const imageB64Url = useMemo(() => {
    if (imageResponse?.data) {
      return window.URL.createObjectURL(imageResponse.data);
    }
    return undefined;
  }, [imageResponse?.data]);

  switch (status) {
    case RequestStatus.Idle:
    case RequestStatus.Fetching:
      return <LoadingSpinner />;

    case RequestStatus.Fetched:
      if (imageB64Url) {
        return (
          <FileViewer
            browsable={browsable}
            imageB64Url={imageB64Url}
            maxHeight={maxHeight}
            mimeType={
              fileEnding === 'pdf' ? 'application/pdf' : `image/${fileEnding}`
            }
          />
        );
      }
      return null;

    case RequestStatus.Error:
      return <ErrorTryAgain onTryAgain={() => update()} />;

    default:
      return null;
  }
};
