import { CaseExtendedModel } from 'api';
import { FC } from 'react';
import { useDetailedCaseFormControl } from '../DetailedCaseFormProvider';
import CaseDataForm from './CaseDataForm';
import CaseDataViewer from './CaseDataViewer';

interface Props {
  caseResponse?: CaseExtendedModel;
  className?: string;
}

const CaseData: FC<Props> = ({ caseResponse, className }) => {
  const { editMode } = useDetailedCaseFormControl();

  if (editMode) {
    return <CaseDataForm className={className} />;
  }
  if (caseResponse) {
    return <CaseDataViewer caseResponse={caseResponse} className={className} />;
  }

  // Should never happen, if caseResponse == undefined, we should be in editmode to create a case.
  return null;
};

export default CaseData;
