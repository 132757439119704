import { atom, DefaultValue, useRecoilState } from 'recoil';

export enum TableIdentifierEnum {
  None = 'none',
  GlobalAssignmentSearchTable = 'global-assignment-search-table',
  InternalDeliveryGroupAssignmentsTable = 'internal-delivery-group-assignments-table',
}
type ActiveColumnsState = Record<TableIdentifierEnum, Record<string, boolean>>;

const localStorageEffect =
  (key: string) =>
  ({ setSelf, onSet }: any) => {
    const savedValue = localStorage.getItem(key);

    if (savedValue != null) {
      setSelf(JSON.parse(savedValue));
    }

    onSet((newValue: ActiveColumnsState | DefaultValue) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, JSON.stringify(newValue));
      }
    });
  };

const betaFeaturesAtom = atom<ActiveColumnsState>({
  key: 'activeColumns',
  default: {} as ActiveColumnsState, // default state
  effects_UNSTABLE: [localStorageEffect('activeColumns')],
});

export const useActiveColumns = (
  identifier?: TableIdentifierEnum
): [Record<string, boolean>, (newValue: Record<string, boolean>) => void] => {
  const [state, setState] = useRecoilState(betaFeaturesAtom);
  const setActiveTableColumns = (newValue: Record<string, boolean>) => {
    if (!identifier) return;
    setState((prev) => ({ ...prev, [identifier]: newValue }));
  };
  const deepState = identifier !== undefined ? state[identifier] ?? {} : {};
  return [deepState, setActiveTableColumns];
};

export default useActiveColumns;
