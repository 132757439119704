import { UserClient } from 'api';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ReconfirmEmailPage: React.FC = () => {
  const queryParams = useQuery();
  const b64EmailConfirmToken = queryParams.get('emailConfirmToken');
  const userId = queryParams.get('userId');

  const confirmEmailCall = useApiCall(
    UserClient,
    (c, usrId: number, emailToken: string) =>
      c.confirmUserEmail(usrId, emailToken)
  );

  useEffect(() => {
    if (
      userId &&
      b64EmailConfirmToken &&
      confirmEmailCall.status === RequestStatus.Idle
    ) {
      confirmEmailCall.run(Number(userId), window.atob(b64EmailConfirmToken));
    }
  }, [confirmEmailCall, b64EmailConfirmToken, userId]);

  if (confirmEmailCall.status !== RequestStatus.Fetched) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <h1>Tack!</h1>
      <p>Din nya e-postadress är nu redo att användas.</p>
    </>
  );
};

export default ReconfirmEmailPage;
