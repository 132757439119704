const Roles = {
  Admin: 1,
  User: 2,
  GoSupport: 3,
  TrafikLedarAdmin: 4,
  GruppLedare: 5,
  SuperAdmin: 6,
  AnnonsAdmin: 7,
  Annons: 8,
  Approval: 9,
  Riddermark: 10,
  Ekonomi: 11,
  CircleK: 12,
  KvalitetOUtbildning: 13,
};

export const includesRoles = (roles: number[], userRoles?: number[]) => {
  return roles.some((role) => userRoles?.includes(role));
};

export const isDrivingPolicyAdmin = (userRoles: number[], id: number) => {
  return includesRoles([Roles.SuperAdmin], userRoles) || id === 1895; // || // Mattias Frick ID hard coded (because he doesn't need the super-admin role);
  // return id === 1895; // || // Mattias Frick ID hard coded (because he doesn't need the super-admin role);
};

export default Roles;
