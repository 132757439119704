import { Dispatch, useCallback, useMemo, useReducer } from 'react';

type NameType = string | number;

export type BasicFormState<Name extends NameType> = {
  [name in Name]: string;
};

export interface ValidationResult {
  error?: string;
  warning?: string;
}
export type ValidationResults<Name extends NameType> = {
  [inputName in Name]?: ValidationResult;
};
export type FormValidator<State extends BasicFormState<any>> = (
  value: string,
  inputName: NameType,
  formState: State
) => ValidationResult | undefined;
export type BasicFormValidators<Name extends NameType> = {
  [name in Name]?: FormValidator<BasicFormState<Name>>;
};

export const actions = {
  inputChanged: <Name extends NameType>(inputName: Name, value: string) =>
    ({
      type: 'inputChanged',
      inputName,
      value,
    } as const),
  multipleChanged: <Name extends NameType>(
    values: Partial<BasicFormState<Name>>
  ) =>
    ({
      type: 'multipleChanged',
      values,
    } as const),
} as const;

// not using ActionReturns<> because of generic actions.
export type UseFormActions<Name extends NameType> =
  | {
      type: 'inputChanged';
      inputName: Name;
      value: string;
    }
  | {
      type: 'multipleChanged';
      values: Partial<BasicFormState<Name>>;
    };

type BasicFormReducer<Name extends NameType> = (
  state: BasicFormState<Name>,
  action: UseFormActions<Name>
) => BasicFormState<Name>;

const basicFormReducer = <Name extends NameType>(
  state: BasicFormState<Name>,
  action: UseFormActions<Name>
): BasicFormState<Name> => {
  switch (action.type) {
    case 'inputChanged':
      return { ...state, [action.inputName]: action.value };
    case 'multipleChanged':
      return { ...state, ...action.values };
    default:
      throw Error('Unknown action');
  }
};

export interface UseForm<Name extends NameType> {
  state: BasicFormState<Name>;
  validation: ValidationResults<Name>;
  dispatch: Dispatch<UseFormActions<Name>>;
  input(name: Name): (eve: React.ChangeEvent<HTMLInputElement>) => void;
  checkbox(name: Name): (eve: React.ChangeEvent<HTMLInputElement>) => void;
  select(name: Name): (eve: React.ChangeEvent<HTMLSelectElement>) => void;
  textarea(name: Name): (eve: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onChange(name: Name, value: string): void;
  setMultiple(formState: Partial<BasicFormState<Name>>): void;
}

const useForm = <Name extends NameType>(
  initialState: BasicFormState<Name>,
  validators?: BasicFormValidators<Name>,
  reducer: BasicFormReducer<Name> = basicFormReducer,
  middleWare?: (
    action: UseFormActions<Name>,
    dispatch: Dispatch<UseFormActions<Name>>
  ) => void
): UseForm<Name> => {
  const [state, d] = useReducer(reducer, initialState);

  const dispatch = useCallback(
    (action: UseFormActions<Name>) =>
      middleWare ? middleWare(action, d) : d(action),
    [middleWare]
  );

  const input = useCallback(
    (name: Name) => {
      return (
        eve: React.ChangeEvent<
          HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
        >
      ) => {
        dispatch(actions.inputChanged(name, eve.target.value));
      };
    },
    [dispatch]
  );

  const checkbox = useCallback(
    (name: Name) => {
      return (eve: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(
          actions.inputChanged(name, eve.target.checked ? 'true' : 'false')
        );
      };
    },
    [dispatch]
  );

  const onChange = useCallback(
    (name: Name, value: string) => dispatch(actions.inputChanged(name, value)),
    [dispatch]
  );

  const setMultiple = useCallback(
    (formValues: Partial<BasicFormState<Name>>) => {
      dispatch(actions.multipleChanged(formValues));
    },
    [dispatch]
  );

  const validation = useMemo(() => {
    const validationByInputName: ValidationResults<Name> = {};

    if (validators) {
      Object.entries<FormValidator<BasicFormState<Name>> | undefined>(
        validators
      ).forEach(([inputName, validator]) => {
        if (validator && inputName in state) {
          const v = validator(state[inputName as Name], inputName, state);
          if (v) validationByInputName[inputName as Name] = v;
        }
      });
    }

    return validationByInputName;
  }, [state, validators]);

  return {
    state,
    validation,
    dispatch,
    input,
    checkbox,
    select: input,
    textarea: input,
    onChange,
    setMultiple,
  };
};

export default useForm;
