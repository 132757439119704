import { faAddressCard } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useUsers from 'contexts/basicData/useUsers';
import useModalStack from 'contexts/modal/useModalStack';
import UserFormModal from 'pages/Employees/UserFormModal';
import { Suspense } from 'react';
import { useTheme } from 'styled-components';

type Props = {
  userId: number;
};
const UserModalLink: React.FC<Props> = ({ userId }) => {
  const theme = useTheme();
  const modalStack = useModalStack();
  const users = useUsers();

  const user = users[userId];

  return (
    <Suspense fallback="Laddar...">
      <FontAwesomeIcon
        color={theme.colors.foreground.tint}
        icon={faAddressCard}
        onClick={() => {
          modalStack.push(
            <UserFormModal
              canEdit={false}
              onCancel={() => modalStack.pop()}
              title="Användare"
              user={user}
            />
          );
        }}
        style={{ cursor: 'pointer' }}
      />
    </Suspense>
  );
};

export default UserModalLink;
