import BudgetFilterBar from './components/BudgetFilterBar';
import InternalDeliveryOverviewTable from './components/table/InternalDeliveryOverviewTable';

const Budget = () => {
  return (
    <>
      <BudgetFilterBar />
      <InternalDeliveryOverviewTable />
    </>
  );
};

export default Budget;
