import useUsers from 'contexts/basicData/useUsers';
import { useMemo } from 'react';
import useAuthenticationState from './useAuthentication';

const useMe = () => {
  const {
    state: { userId },
  } = useAuthenticationState();
  const users = useUsers();

  return useMemo(() => {
    const me = userId !== undefined ? users[userId] : null;

    return me;
  }, [userId, users]);
};

export default useMe;
