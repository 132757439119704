import {
  AssignmentClient,
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentViewModel,
  ExpectedExpenseDto,
  IAssignmentViewModel,
  ITranslationLookups,
  UserModel,
} from 'api';
import React, { FC, useCallback, useState } from 'react';
import styled, { css } from 'styled-components';
import { toInputDateTimeString, toInputTimeString } from 'utils/date-helpers';
import { AssignmentEditOperation } from '../useQueuedAssignmentUpdates';
import { fakeSelectClassName } from 'components/inputs/SearchableSelect';
import {
  getSelectableStatusOptions,
  statusOptionIsDisabled,
} from 'components/DetailedCase/CaseAssignments/utils';
import { useApiCall } from 'swaggerhooks/lib';
import workHours from 'utils/workHours';
import useModalStack from 'contexts/modal/useModalStack';
import Modal from 'components/Modal';
import PrioritizedCaseAssignmentUserPicker from 'components/PrioritizedCaseAssignmentUserPicker';
import StandardizedComments from 'components/StandardizedComments/StandardizedComments';
import Dropdown from 'components/Dropdown';
import useStandardizedComments from 'contexts/standardizedComments/useStandardizedComments';
import { getUpdatedExpectedExpensesList } from 'utils/expected-expenses';
import EstimatedDurationPicker from 'components/DetailedCase/CaseAssignments/EstimatedDurationPicker';
import { useAssignmentGetAssignmentRequests } from 'api/assignment/assignment';

const StyledUserPicker = styled(PrioritizedCaseAssignmentUserPicker)`
  display: flex;
  height: 100%;

  .${fakeSelectClassName} {
    width: 100%;
    outline: none;
    background-color: ${({ theme }) => theme.colors.background.primary};
    border: 2px solid ${({ theme }) => theme.colors.background.selection};
    border-radius: 0;
    box-shadow: ${({ theme }) => theme.colors.shadow.dropdown};
  }
`;

interface UserPickerCellProps {
  assignmentViewModel: IAssignmentViewModel;
  onUpdateAssignment(
    assignmentId: number,
    editOperation: AssignmentEditOperation
  ): void;
}

export const UserPickerCell: FC<UserPickerCellProps> = ({
  assignmentViewModel,
  onUpdateAssignment,
}) => {
  const modalStack = useModalStack();
  const workedHoursCall = useApiCall(AssignmentClient, (c, userId: number) =>
    c.getHoursWorked(userId)
  );

  const selectUser = (user: UserModel) => {
    onUpdateAssignment(assignmentViewModel.assignment.assignmentID, (avm) => {
      const currentStatus = avm.assignment.assignmentStatusID;
      let newStatus = avm.assignment.assignmentStatusID;

      // Deselcting user changes status to Created
      if (
        !user &&
        (currentStatus === AssignmentStatusEnum.Planned ||
          currentStatus === AssignmentStatusEnum.Assigned)
      ) {
        newStatus = AssignmentStatusEnum.Created;
      }

      // Selecting user changes status to Planned
      if (
        user &&
        (currentStatus === AssignmentStatusEnum.Created ||
          currentStatus === AssignmentStatusEnum.Assigned)
      ) {
        newStatus = AssignmentStatusEnum.Planned;
      }

      const updatedAvm: AssignmentViewModel = AssignmentViewModel.fromJS({
        ...avm,
        assignment: AssignmentModel.fromJS({
          ...avm.assignment,
          assignedTo: user ?? undefined,

          assignmentStatusID: newStatus,
        }),
      });

      return updatedAvm;
    });
  };

  const handleUserPicked = async (user: UserModel | null) => {
    if (assignmentViewModel.assignment.startTime !== undefined) {
      // this assignment has already started, proceeed without warnings
      selectUser(user!);
    } else {
      // this assignment will impact the user's work hours, so check for warnings before proceeding
      const [workedHours] = await workedHoursCall.run(user?.userID ?? -1);
      const workHourWarnings = workHours.getWorkHourWarnings(workedHours);

      if (workHourWarnings) {
        modalStack.push(
          <Modal
            buttons={[
              {
                label: 'Gå vidare ändå',
                onClick: () => {
                  selectUser(user!);
                  modalStack.pop();
                },
              },
              {
                label: 'Ångra',
                onClick: () => modalStack.pop(),
              },
            ]}
            onClose={() => modalStack.pop()}
            title="Varning"
          >
            {`${user?.name ?? 'Fälttestaren'} ${workHourWarnings}`}
          </Modal>
        );
      } else {
        selectUser(user!);
      }
    }
  };

  return (
    <StyledUserPicker
      assignmentId={assignmentViewModel.assignment.assignmentID}
      assignmentType={assignmentViewModel.assignment.assignmentTypeID}
      autoFocus
      bookedTo={assignmentViewModel.assignment.bookedTo.toString()}
      caseModel={assignmentViewModel.case}
      dropdownPosition="left"
      estimatedDuration={assignmentViewModel.assignment.estimatedDuration ?? 0}
      estimatedStartTime={
        assignmentViewModel.assignment.estimatedStartTime?.toString() ?? ''
      }
      fromZipCode={assignmentViewModel.assignment.fromZip}
      onUserPicked={handleUserPicked}
      selectedUserId={assignmentViewModel.assignment.assignedTo?.userID ?? null}
    />
  );
};

const baseInputStyling = css`
  margin: 0;
  padding: 0px 3px;
  min-width: 100%;
  min-height: 100%;
  position: relative;
  z-index: 1;

  outline: none;
  border: 2px solid ${({ theme }) => theme.colors.background.selection};
  font: inherit;
  box-shadow: ${({ theme }) => theme.colors.shadow.dropdown};
`;

const InputCell = styled.input<{ width?: number }>`
  ${baseInputStyling}
  ${({ width }) =>
    width &&
    css`
      width: ${width}px;
    `}
`;

interface BaseInputCellProps {
  type?: React.HTMLInputTypeAttribute;
  initialValue: string;
  onValueSelected(value: string): void;
  width?: number;
}
const BaseInputCell: FC<BaseInputCellProps> = ({
  type,
  initialValue,
  onValueSelected,
  width,
}) => {
  const [input, setInput] = useState(initialValue);

  return (
    <InputCell
      autoFocus
      onChange={(eve) => setInput(eve.target.value)}
      onKeyUp={(eve) => {
        if (eve.key === 'Enter') {
          onValueSelected(input);

          if (eve.target instanceof HTMLElement) {
            eve.target.blur();
          }
        }

        if (eve.key === 'Escape' && eve.target instanceof HTMLElement) {
          eve.target.blur();
        }
      }}
      type={type}
      value={input}
      width={width}
    />
  );
};

interface BaseCellProps {
  assignmentViewModel: IAssignmentViewModel;
  onUpdateAssignment(
    assignmentId: number,
    editOperation: AssignmentEditOperation
  ): void;
}

export const TimePickerCell: FC<BaseCellProps> = ({
  assignmentViewModel,
  onUpdateAssignment,
}) => (
  <BaseInputCell
    initialValue={toInputTimeString(assignmentViewModel.assignment.bookedTo)}
    onValueSelected={(value) => {
      const [sHour, sMinute] = value.split(':');
      const newDate = new Date(assignmentViewModel.assignment.bookedTo);
      newDate.setHours(Number(sHour));
      newDate.setMinutes(Number(sMinute));

      onUpdateAssignment(assignmentViewModel.assignment.assignmentID, (avm) => {
        return new AssignmentViewModel({
          ...avm,
          assignment: new AssignmentModel({
            ...avm.assignment,
            bookedTo: newDate,
          }),
        });
      });
    }}
    type="time"
  />
);

export const DateTimePickerCell: FC<BaseCellProps> = ({
  assignmentViewModel,
  onUpdateAssignment,
}) => (
  <BaseInputCell
    initialValue={toInputDateTimeString(
      assignmentViewModel.assignment.bookedTo
    )}
    onValueSelected={(value) => {
      const newDate = new Date(value);

      onUpdateAssignment(assignmentViewModel.assignment.assignmentID, (avm) => {
        return new AssignmentViewModel({
          ...avm,
          assignment: new AssignmentModel({
            ...avm.assignment,
            bookedTo: newDate,
          }),
        });
      });
    }}
    type="datetime-local"
  />
);

export const ExtraInfoCell: FC<BaseCellProps> = ({
  assignmentViewModel,
  onUpdateAssignment,
}) => (
  <BaseInputCell
    initialValue={assignmentViewModel.assignment.extraInfo}
    onValueSelected={(value) => {
      onUpdateAssignment(
        assignmentViewModel.assignment.assignmentID,
        (avm) =>
          new AssignmentViewModel({
            ...avm,
            assignment: new AssignmentModel({
              ...avm.assignment,
              extraInfo: value,
            }),
          })
      );
    }}
    width={300}
  />
);

export const StandardizedCommentsCell: FC<BaseCellProps> = ({
  assignmentViewModel,
  onUpdateAssignment,
}) => {
  const globalStandardizedComments = useStandardizedComments();

  return (
    <Dropdown
      content={
        <div style={{ maxWidth: 250, padding: 5 }}>
          <StandardizedComments
            assignmentId={assignmentViewModel.assignment.assignmentID}
            commentsOnAssignment={
              assignmentViewModel.assignment.standardizedComments
            }
            onChange={(comments) => {
              // update expected expenses based on change
              const newExpectedExpenses = getUpdatedExpectedExpensesList({
                globalStandardizedComments,
                existingExpectedExpenses:
                  assignmentViewModel.assignment.expectedExpenses.map(
                    (e) => e.type
                  ),
                newComments: comments,
                existingComments:
                  assignmentViewModel.assignment.standardizedComments,
              });

              onUpdateAssignment(
                assignmentViewModel.assignment.assignmentID,
                (avm) =>
                  new AssignmentViewModel({
                    ...avm,
                    assignment: new AssignmentModel({
                      ...avm.assignment,
                      standardizedComments: comments,
                      expectedExpenses: newExpectedExpenses.map(
                        (e) =>
                          new ExpectedExpenseDto({
                            assignmentId: -1,
                            type: e,
                            estimatedCost: 0,
                          })
                      ),
                    }),
                  })
              );
            }}
          />
        </div>
      }
    >
      {assignmentViewModel.assignment.standardizedComments
        .map((c) => c.comment)
        .join(', ')}
    </Dropdown>
  );
};

const CellSelect = styled.select`
  ${baseInputStyling}
`;

interface StatusPickerCellProps extends BaseCellProps {
  assignmentStatuses: ITranslationLookups['assignmentStatuses'];
}

export const StatusPickerCell: FC<StatusPickerCellProps> = ({
  assignmentViewModel,
  onUpdateAssignment,
  assignmentStatuses,
}) => {
  const assignmentRequests = useAssignmentGetAssignmentRequests({
    assignmentId: assignmentViewModel.assignment.assignmentID,
  });
  const disableOption = useCallback(
    (option: AssignmentStatusEnum) => {
      // disable "Tilldelad" option if assignment has no estimated duration
      if (
        [
          AssignmentStatusEnum.Assigned,
          AssignmentStatusEnum.Accepted,
          AssignmentStatusEnum.Started,
          AssignmentStatusEnum.Arrived,
          AssignmentStatusEnum.TestComplete,
          AssignmentStatusEnum.Inspected,
          AssignmentStatusEnum.Returning,
          AssignmentStatusEnum.Complete,
        ].includes(option) &&
        assignmentViewModel.assignment.estimatedDuration === null
      ) {
        return true;
      }

      return false;
    },
    [assignmentViewModel]
  );

  return (
    <CellSelect
      autoFocus
      onChange={(eve) => {
        const newStatus: AssignmentStatusEnum = Number(eve.target.value);

        onUpdateAssignment(
          assignmentViewModel.assignment.assignmentID,
          (avm) =>
            new AssignmentViewModel({
              ...avm,
              assignment: new AssignmentModel({
                ...avm.assignment,
                assignmentStatusID: newStatus,
              }),
            })
        );

        if (eve.target instanceof HTMLElement) {
          eve.target.blur();
        }
      }}
      onKeyUp={(eve) => {
        if (eve.key === 'Escape' && eve.target instanceof HTMLElement) {
          eve.target.blur();
        }
      }}
      value={assignmentViewModel.assignment.assignmentStatusID}
    >
      {getSelectableStatusOptions(
        assignmentViewModel.assignment.assignmentStatusID,
        assignmentViewModel.case.caseTypeID
      ).map((option) => (
        <option
          disabled={
            disableOption(option) ||
            statusOptionIsDisabled({
              currentAssignmentStatus: option,
              events: assignmentRequests.data ?? [],
              currentAssignedToId:
                assignmentViewModel.assignment.assignedTo?.userID,
              currentAssignmentId: assignmentViewModel.assignment.assignmentID,
              drivingPolicyAssignmentInNeedOfHandling:
                assignmentViewModel.assignment
                  .drivingPolicyAssignmentInNeedOfHandling,
            })
          }
          key={option}
          value={option}
        >
          {assignmentStatuses[option]}
          {disableOption(option) && ' (ange först estimerad tid)'}
        </option>
      ))}
    </CellSelect>
  );
};

interface EstimatedDurationCellProps extends BaseCellProps {
  placeholder?: string;
}

export const EstimatedDurationCell: FC<EstimatedDurationCellProps> = ({
  assignmentViewModel,
  onUpdateAssignment,
  placeholder,
}) => {
  const [updatedAssignment, setUpdatedAssignment] = useState<AssignmentModel>(
    assignmentViewModel.assignment
  );

  return (
    <EstimatedDurationPicker
      assignment={updatedAssignment}
      caseType={assignmentViewModel.case.caseTypeID}
      hideSelectAverageCheckbox
      onChangeEstimatedDuration={(value) => {
        onUpdateAssignment(
          assignmentViewModel.assignment.assignmentID,
          (avm) => {
            return new AssignmentViewModel({
              ...avm,
              assignment: new AssignmentModel({
                ...updatedAssignment,
                estimatedDuration: value,
              }),
            });
          }
        );
      }}
      onChangeSystemEstimatedDurationApprox={(value) => {
        setUpdatedAssignment(
          new AssignmentModel({
            ...updatedAssignment,
            systemEstimatedDurationApprox: value,
          })
        );
      }}
      onChangeSystemEstimatedDurationExact={(value) => {
        setUpdatedAssignment(
          new AssignmentModel({
            ...updatedAssignment,
            systemEstimatedDurationExact: value,
          })
        );
      }}
      placeholder={placeholder}
    />
  );
};
