import Routes from 'constants/Routes';
import { Outlet } from 'react-router-dom';
import AdSubMenu, { SubMenuTitle } from './components/AdSubMenu';
import { BudgetFiltersProvider } from './Overview/components/useBudgetFilters';
// import { BudgetSettingsTableValuesProvider } from './Budget/components/BudgetSettingsTable/useBudgetSettingsTableValues';
import { InternalDeliveryGroupBudgetSettingsFiltersProvider } from './Budget/components/useInternalDeliveryGroupBudgetSettingsFilters';
import { useAssignmentRequestsGetCounts } from 'api/assignment-requests/assignment-requests';

const AdvertisingPage = () => {
  const countsCall = useAssignmentRequestsGetCounts({
    query: {
      refetchInterval: 60000,
      staleTime: 15000,
    },
  });
  return (
    <BudgetFiltersProvider>
      <InternalDeliveryGroupBudgetSettingsFiltersProvider>
        {/* <BudgetSettingsTableValuesProvider> */}
        <AdSubMenu
          items={[
            {
              label: <SubMenuTitle>Uppdrag</SubMenuTitle>,
              path: Routes.internalDeliveryGroup.index,
            },
            {
              label: (
                <SubMenuTitle>
                  Förfrågningar & Bokningar
                  {(countsCall.data?.numberOfUnhandledRequests ?? 0) > 0 && (
                    <span>{` (${countsCall.data?.numberOfUnhandledRequests ?? 0})`}</span>
                  )}
                </SubMenuTitle>
              ),
              path: Routes.internalDeliveryGroup.requests.index,
            },
            {
              label: <SubMenuTitle>Överblick</SubMenuTitle>,
              path: Routes.internalDeliveryGroup.overview,
            },
            {
              label: <SubMenuTitle>Budget</SubMenuTitle>,
              path: Routes.internalDeliveryGroup.budget,
            },
          ]}
        />
        <Outlet />
        {/* </BudgetSettingsTableValuesProvider> */}
      </InternalDeliveryGroupBudgetSettingsFiltersProvider>
    </BudgetFiltersProvider>
  );
};
export default AdvertisingPage;
