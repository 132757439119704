import Table from 'components/Table';
import useInternalDeliveryOverviewColumnSettings, {
  InternalDeliveryOverviewColumnProps,
} from './useInternalDeliveryOverviewColumnSettings';
import { useBudgetFilters } from '../useBudgetFilters';
import { useMemo } from 'react';
import useTranslations from 'contexts/basicData/useTranslations';
import { useInternalDeliveryOverviewGetInternalDeliveryOverview } from 'api/internal-delivery-overview/internal-delivery-overview';
import useWeeklyBudgets from './useWeeklyBudgets';

interface Props {
  onlyTotals?: boolean;
}

const InternalDeliveryOverviewTable: React.FC<Props> = ({ onlyTotals }) => {
  const columnSettings = useInternalDeliveryOverviewColumnSettings({
    onlyTotals,
  });
  const translations = useTranslations();

  const {
    calls: { getWeeklyBudgets },
  } = useWeeklyBudgets();

  const {
    state: { startDate, endDate },
  } = useBudgetFilters();

  const internalDeliveryOverviewCall =
    useInternalDeliveryOverviewGetInternalDeliveryOverview(
      {
        from: startDate,
        to: endDate,
      },
      {
        query: {
          staleTime: 1000 * 30,
        },
      }
    );

  const renderProps = useMemo(
    (): InternalDeliveryOverviewColumnProps => ({
      translations,
      startDate,
      endDate,
      onUpdateRow: async () => {
        await internalDeliveryOverviewCall.refetch();
        await getWeeklyBudgets.refetch();
      },
    }),
    [
      endDate,
      getWeeklyBudgets,
      internalDeliveryOverviewCall,
      startDate,
      translations,
    ]
  );

  return (
    <Table
      columnSettings={columnSettings}
      rows={internalDeliveryOverviewCall.data ?? []}
      renderProps={renderProps}
      fluid
      useColumnWidthAsFlexAmount
    />
  );
};

export default InternalDeliveryOverviewTable;
