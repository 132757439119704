import {
  AssignmentsFiltersState,
  AssignmentsFiltersStateAction,
  AssignmentsFiltersStateActionType,
} from './types';

const assignmentsFiltersReducer = (
  state: AssignmentsFiltersState,
  action: AssignmentsFiltersStateAction
): AssignmentsFiltersState => {
  switch (action.type) {
    case AssignmentsFiltersStateActionType.SET_VISIBLE_ASSIGNMENT_STATUSES: {
      return {
        ...state,
        visibleStatuses: action.statuses,
      };
    }
    case AssignmentsFiltersStateActionType.SET_VISIBLE_ASSIGNMENT_DEVIATIONS: {
      return {
        ...state,
        visibleDeviations: action.deviations,
      };
    }
    case AssignmentsFiltersStateActionType.SET_ADVANCED_ASSIGNMENT_FILTERS: {
      return {
        ...state,
        advancedFilters: action.advancedFilters,
      };
    }
    case AssignmentsFiltersStateActionType.SET_VISIBLE_BUSINESS_UNITS: {
      return {
        ...state,
        visibleBusinessUnits: action.businessUnits,
      };
    }
    case AssignmentsFiltersStateActionType.ERROR: {
      return {
        ...state,
        error: action.error,
      };
    }

    case AssignmentsFiltersStateActionType.SET_DATE: {
      return {
        ...state,
        date: action.date,
      };
    }

    case AssignmentsFiltersStateActionType.SET_SEARCH_TEXT: {
      return {
        ...state,
        searchText: action.text,
      };
    }

    case AssignmentsFiltersStateActionType.SET_SORT_STATE: {
      return {
        ...state,
        sortedColumnIndex: action.sortingState.sortedColumnIndex,
        sortAscending: action.sortingState.ascending,
      };
    }

    case AssignmentsFiltersStateActionType.TRIGGER_ASSIGNMENTS_UPDATE: {
      return { ...state, triggerAssignmentsUpdate: action.uniqueValue };
    }

    case AssignmentsFiltersStateActionType.TRIGGER_ADMIN_HAS_READ_CASE: {
      return { ...state, triggerAdminHasReadCase: action.caseId };
    }

    default: {
      return { ...state };
    }
  }
};

export default assignmentsFiltersReducer;
