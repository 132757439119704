/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AbsenceStatus = (typeof AbsenceStatus)[keyof typeof AbsenceStatus];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AbsenceStatus = {
  REQUESTED: 0,
  APPROVED: 100,
  DECLINED: 200,
} as const;
