import { AssignmentClient, DetailedStandardizedCommentDto } from 'api';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks/lib';

const standardizedCommentsAtom = atom<DetailedStandardizedCommentDto[]>({
  key: 'standardizedComments',
  default: selector({
    key: 'standardizedCommentsSelector',
    get: async (): Promise<DetailedStandardizedCommentDto[]> => {
      const url = `/api/Assignment/GetAllDetailedStandardizedComments`.replace(
        /[?&]$/,
        ''
      );

      const options: RequestInit = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error('FAILED TO GET STANDARDIZED COMMENTS!');
      }

      return (await response.json()) as DetailedStandardizedCommentDto[];
    },
  }),
});

export const useRefreshStandardizedCommentsCall = () => {
  const [, setState] = useRecoilState(standardizedCommentsAtom);

  const apiCall = useApiCall(AssignmentClient, async (c) => {
    const result = await c.getAllDetailedStandardizedComments();

    setState(result);
  });

  return apiCall.run;
};

const useStandardizedComments = () => {
  return useRecoilValue(standardizedCommentsAtom);
};

export default useStandardizedComments;
