import { EventModel } from 'api';
import useForm from 'hooks/useForm';

export interface EventForm {
  message: string;
}

function eventModelToEventForm(event?: EventModel): EventForm {
  if (event) {
    return {
      message: event.message,
    };
  }

  return { message: '' };
}

const useEventEditorForm = (event?: EventModel) => {
  return useForm(eventModelToEventForm(event));
};

export default useEventEditorForm;
