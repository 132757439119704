import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import {
  getGoSupportGetUnhandledWorkGearReportsQueryKey,
  useGoSupportGetUnhandledWorkGearReports,
} from 'api/go-support/go-support';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faCamera,
} from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/inputs/Checkbox';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import { useQueryClient } from '@tanstack/react-query';
import {
  CaseEventDTO,
  WorkGearImageEvaluationResult,
  WorkGearImageEvaluationResultType,
} from 'api/model';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';
import {
  Centered,
  getTableRowClassName,
  SavingRowAnimationWrapper,
} from '../table-helpers';
import { ITranslationLookups } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';
import useModalStack from 'contexts/modal/useModalStack';
import GenericFileViewerModal from 'components/DetailedCase/EventsAndComments/EventAttachmentFileViewer';
import toast from 'react-hot-toast';
import { makeStringComparator } from 'utils/sorting';

const TABLE_NAME = 'work-gear-reports';

const WorkGearReport = ({ dataString }: { dataString: string }) => {
  let data: WorkGearImageEvaluationResult | null = null;
  try {
    data = JSON.parse(dataString) as WorkGearImageEvaluationResult;
  } catch (error) {
    // console.log('Error parsing JSON', error);
  }

  const missingData: string[] = [];
  if (data) {
    if (data.hasGreenPlate !== WorkGearImageEvaluationResultType.True) {
      missingData.push('Grönskylt');
    }
    if (data.hasOBDMeter !== WorkGearImageEvaluationResultType.True) {
      missingData.push('OBD-mätare');
    }
    if (data.hasTyreGauge !== WorkGearImageEvaluationResultType.True) {
      missingData.push('Däcktrycksmätare');
    }
  }

  return <div>{`Saknar: ${missingData.join(', ')}`}</div>;
};

const WorkGearReportsWidget = () => {
  const [gearReports, setGearReports] = useState<CaseEventDTO[]>([]);
  const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();
  const translations = useTranslations();
  const modalStack = useModalStack();

  const queryClient = useQueryClient();
  const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
    useCaseMarkEventsAsReadByAdmin({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            getGoSupportGetUnhandledWorkGearReportsQueryKey(),
          ]);
        },
      },
    });

  const getWorkGearReports = useGoSupportGetUnhandledWorkGearReports(
    {
      startDate: daterange[0] ?? undefined,
      endDate: daterange[1] ?? undefined,
      businessUnitIDs: selectedBusinessUnits,
    },
    {
      query: {
        refetchInterval: UPDATE_INTERVAL,
      },
    }
  );

  useEffect(() => {
    if (getWorkGearReports.data) {
      setGearReports(getWorkGearReports.data);
    } else {
      setGearReports([] as CaseEventDTO[]);
    }
  }, [getWorkGearReports.data]);

  const filteredGearReports = useMemo(() => {
    let filtered = [...gearReports];

    if (searchText.length > 0) {
      filtered = filtered.length
        ? filtered
            .filter((c) => {
              return (
                c.comment?.toLowerCase().includes(searchText.toLowerCase()) ||
                c.registrationNumber
                  .toLowerCase()
                  .includes(searchText.toLowerCase()) ||
                (users[c.createdByID]?.name ?? '')
                  .toLowerCase()
                  .includes(searchText.toLowerCase())
              );
            })
            .sort((a, b) => b.created.getTime() - a.created.getTime()) // Latest first
        : [];
    }

    return filtered;
  }, [gearReports, searchText, users]);

  const columnSettings: ColumnSetting<CaseEventDTO, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<CaseEventDTO, ITranslationLookups>[] = [
        {
          head: 'Nr',
          render: (e, t, f, i) => i + 1,
          width: 15,
        },
        {
          head: 'Reg.nr',
          render: (row) => `${row.registrationNumber}`,
          width: 60,
          sortFunction: (a, b) => {
            return a.registrationNumber.localeCompare(b.registrationNumber);
          },
        },
        {
          head: 'Status',
          render: (row) =>
            `${row.assignmentStatus ? translations.assignmentStatuses[row.assignmentStatus] : ''}`,
          width: 50,
          sortFunction: (a, b) => {
            return a.assignmentStatus - b.assignmentStatus;
          },
        },
        {
          head: 'Tid',
          render: (row) => `${formatDateTime(row.created)}`,
          width: 100,
          sortFunction: (a, b) => a.created.getTime() - b.created.getTime(),
        },
        {
          head: 'Användare',
          render: (row) => {
            return (
              <span>
                {users[row.createdByID] ? users[row.createdByID].name : ''}
              </span>
            );
          },
          width: 100,
          sortFunction: (a, b) =>
            (users[a.createdByID]?.name ?? '').localeCompare(
              users[b.createdByID]?.name ?? ''
            ),
        },
        {
          head: 'Telefonnummer',
          render: (row) => {
            return (
              <span>
                {users[row.createdByID]
                  ? users[row.createdByID].phoneNumber
                  : ''}
              </span>
            );
          },
          width: 120,
          sortFunction: (a, b) =>
            (users[a.createdByID]?.phoneNumber ?? '').localeCompare(
              users[b.createdByID]?.phoneNumber ?? ''
            ),
        },
        {
          head: ' ',
          render: (row) => (
            <Centered>
              <FontAwesomeIcon
                onClick={() => {
                  if (row.attachmentFileGuid && row.attachmentFileEnding) {
                    modalStack.push(
                      <GenericFileViewerModal
                        fileEnding={row.attachmentFileEnding}
                        fileGuid={row.attachmentFileGuid}
                        onClose={() => modalStack.pop()}
                        title={row.registrationNumber}
                        description={row.comment}
                      />
                    );
                  } else {
                    toast.error('Lyckades inte hämta bild');
                  }
                }}
                icon={faCamera}
                fontSize="18px"
                style={{ cursor: 'pointer' }}
              />
            </Centered>
          ),
          width: 20,
        },
        {
          head: 'Rapport',
          render: (row) =>
            row.data ? <WorkGearReport dataString={row.data} /> : row.comment,
          width: 300,
          sortFunction: makeStringComparator((row) => row.data ?? row.comment),
        },
        {
          head: ' ',
          render: (row) => (
            <Centered>
              <FontAwesomeIcon
                onClick={() => {
                  window.open(
                    `${Routes.search.index}/${row.caseID}`,
                    row.caseID.toString() ?? '_blank'
                  );
                }}
                icon={faArrowUpRightFromSquare}
                fontSize="18px"
                style={{ cursor: 'pointer' }}
              />
            </Centered>
          ),
          width: 20,
        },
        {
          head: 'Klart',
          render: (row) => (
            <Centered>
              <Checkbox
                disabled={markAsReadStatus === 'loading'}
                onChange={async () => {
                  setOngoingSaves([...ongoingSaves, row.id]);
                  await markEventAsRead({ data: [row.id] });
                  setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
                }}
              />
            </Centered>
          ),
          width: 30,
        },
      ];
      return columns;
    }, [
      markAsReadStatus,
      markEventAsRead,
      modalStack,
      ongoingSaves,
      translations.assignmentStatuses,
      users,
    ]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.WorkGearReports]}
      count={gearReports.length}
      isLoading={getWorkGearReports.isLoading}
      info="Markera som klar för att markera utrustningsrapport som läst."
    >
      <SavingRowAnimationWrapper
        tableName={TABLE_NAME}
        savingIds={ongoingSaves}
      >
        <Table
          columnSettings={columnSettings}
          rows={filteredGearReports}
          rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
          useColumnWidthAsFlexAmount
          renderProps={translations}
        />
      </SavingRowAnimationWrapper>
    </Widget>
  );
};

export default WorkGearReportsWidget;
