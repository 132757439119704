import { useMemo, useState } from 'react';
import { faCheck, faWarning } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ITranslationLookups } from 'api';
import { useDrivingPolicyGetDrivingReports } from 'api/driving-policy/driving-policy';
import { ColumnSetting } from 'components/Table/utils';
import Button from 'components/inputs/Button';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import useUsers from 'contexts/basicData/useUsers';
import styled, { css } from 'styled-components';
import toHoursAndMinutesString, {
  formatHoursAndMinutesString,
} from 'utils/time-helpers';
import Table from 'components/Table';
import { DrivingPolicyReportDto } from 'api/model';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';
import Input from 'components/inputs/Input';
import MultiSelect, { Option } from 'components/inputs/MultiSelect';
import { useLocalStorage } from 'usehooks-ts';
import useBusinessUnits from 'contexts/basicData/useBusinessUnits';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;
`;

const StyledMultiSelect = styled(MultiSelect)`
  padding: 6px;
  ${({ value, theme }) =>
    value.size > 0 &&
    css`
      background: ${theme.colors.background.highlight};
    `}
` as typeof MultiSelect;

export const RowCss = css`
  align-items: center;
  display: flex;
  padding: 2px 5px;
  &:hover {
    overflow: visible;
    // children of this row
    & > div {
      z-index: 10;
    }
  }
`;

export const ProgressContainer = styled.div`
  width: 100%; // Make the container fill the full width
  height: 100%; // Make the container fill the full height
  background: ${({ theme }) =>
    theme.colors.background.tertiary}; // Background for the unfilled part
`;

export const ProgressBar = styled.div<{ widthPercent: number }>`
  width: ${({ widthPercent }) => widthPercent}%;
  background: ${({ widthPercent, theme }) =>
    widthPercent === 100
      ? theme.colors.foreground.error
      : widthPercent > 70
        ? theme.colors.foreground.warning // You can use your theme color here
        : theme.colors.foreground.good};
  height: 100%; // Make the progress bar fill the container's height
  display: flex;
  align-items: center;
  padding: 0px 5px;
  color: ${({ theme, widthPercent }) =>
    widthPercent === 100
      ? theme.colors.foreground.button
      : theme.colors.foreground.primary};
`;

const DrivingPolicyOverview = () => {
  const [visibleBusinessUnits, setVisibleBusinessUnits] = useLocalStorage<
    number[]
  >('availability.visibleBUs', []);
  const [searchInput, setSearchInput] = useState<string>('');
  const users = useUsers();
  const { unitsById } = useBusinessUnits();

  const businessUnitOptions = useMemo((): Option<number>[] => {
    return Array.from(unitsById.values()).map((unit) => ({
      label: unit.name,
      value: unit.id,
    }));
  }, [unitsById]);

  const {
    data: reports,
    status,
    refetch,
  } = useDrivingPolicyGetDrivingReports();

  // const handleRowClick = (report: DrivingPolicyReportDto) => {
  //   // console.log('report: ', report);
  // };

  const columnSettings: ColumnSetting<
    DrivingPolicyReportDto,
    ITranslationLookups
  >[] = useMemo(() => {
    const columns: ColumnSetting<
      DrivingPolicyReportDto,
      ITranslationLookups
    >[] = [
      {
        head: 'Namn',
        css: RowCss,
        render: (report) => users[report.fieldTesterId!]?.name ?? '',
        sortFunction: (a, b) => {
          const aName = users[a.fieldTesterId!]?.name ?? '';
          const bName = users[b.fieldTesterId!]?.name ?? '';
          return aName.localeCompare(bName);
        },
        width: 300,
      },
      {
        head: '24h',
        css: RowCss,
        render: (report) => (
          <FontAwesomeIcon
            icon={
              report.hasConsecutiveHoursOfRestLast24Hours ? faCheck : faWarning
            }
            style={{
              color: report.hasConsecutiveHoursOfRestLast24Hours
                ? 'green'
                : 'red',
            }}
          />
        ),
        sortFunction: (a, b) =>
          a.hasConsecutiveHoursOfRestLast24Hours ===
          b.hasConsecutiveHoursOfRestLast24Hours
            ? 0
            : a.hasConsecutiveHoursOfRestLast24Hours
              ? 1
              : -1,
        width: 40,
      },
      {
        head: '7d',
        css: RowCss,
        render: (report) => (
          <FontAwesomeIcon
            icon={
              report.hasConsecutiveHoursOfRestThisWeek ? faCheck : faWarning
            }
            style={{
              color: report.hasConsecutiveHoursOfRestThisWeek ? 'green' : 'red',
            }}
          />
        ),
        sortFunction: (a, b) =>
          a.hasConsecutiveHoursOfRestThisWeek ===
          b.hasConsecutiveHoursOfRestThisWeek
            ? 0
            : a.hasConsecutiveHoursOfRestThisWeek
              ? 1
              : -1,
        width: 40,
      },
      {
        head: 'Innevarande vecka',
        css: RowCss,
        render: (report) => {
          const value = Math.max(report?.hoursDrivenThisWeek || 0, 0);
          return report?.hoursDrivenThisWeek ? (
            <ProgressContainer>
              <ProgressBar
                widthPercent={
                  value ? (value / 0.48 >= 100 ? 100 : value / 0.48) : 0
                }
              >
                {getHoursAndMinutesFromMillisecondsString(
                  value * 1000 * 60 * 60
                )}
              </ProgressBar>
            </ProgressContainer>
          ) : (
            '-'
          );
        },
        sortFunction: (a, b) =>
          (a.hoursDrivenThisWeek ?? 0) - (b.hoursDrivenThisWeek ?? 0),
        width: 200,
      },
      {
        head: 'Innevarande månad',
        css: RowCss,
        render: (report) => {
          const value = Math.max(report?.hoursDrivenThisMonth || 0, 0);
          return report?.hoursDrivenThisMonth ? (
            <ProgressContainer>
              <ProgressBar
                widthPercent={
                  value ? (value / 1.92 >= 100 ? 100 : value / 1.92) : 0
                }
              >
                {getHoursAndMinutesFromMillisecondsString(
                  value * 1000 * 60 * 60
                )}
              </ProgressBar>
            </ProgressContainer>
          ) : (
            '-'
          );
        },
        sortFunction: (a, b) =>
          (a.hoursDrivenThisMonth ?? 0) - (b.hoursDrivenThisMonth ?? 0),
        width: 200,
      },
      {
        head: 'De senaste 28 dagarna',
        css: RowCss,
        render: (report) => {
          const value = Math.max(report?.hoursDrivenLast28Days || 0, 0);
          return report?.hoursDrivenLast28Days ? (
            <ProgressContainer>
              <ProgressBar
                widthPercent={
                  value ? (value / 1.92 >= 100 ? 100 : value / 1.92) : 0
                }
              >
                {getHoursAndMinutesFromMillisecondsString(
                  value * 1000 * 60 * 60
                )}
              </ProgressBar>
            </ProgressContainer>
          ) : (
            '-'
          );
        },
        sortFunction: (a, b) =>
          (a.hoursDrivenLast28Days ?? 0) - (b.hoursDrivenLast28Days ?? 0),
        width: 200,
      },
      {
        head: 'Kvar senaste 28 dagarna',
        css: RowCss,
        render: (report) =>
          formatHoursAndMinutesString(
            toHoursAndMinutesString(192 - (report.hoursDrivenLast28Days ?? 0))
          ),
        sortFunction: (a, b) =>
          192 -
          (a.hoursDrivenLast28Days ?? 0) -
          (192 - (b.hoursDrivenLast28Days ?? 0)),
        width: 200,
      },
      {
        head: 'Kvar innevarande månad',
        render: (report) =>
          formatHoursAndMinutesString(
            toHoursAndMinutesString(192 - (report.hoursDrivenThisMonth ?? 0))
          ),
        sortFunction: (a, b) =>
          192 -
          (a.hoursDrivenThisMonth ?? 0) -
          (192 - (b.hoursDrivenThisMonth ?? 0)),
        width: 200,
      },
    ];
    return columns;
  }, [users]);

  const filteredReports = useMemo(() => {
    return (
      reports?.filter((report) => {
        let include = users[report.fieldTesterId!]?.name
          .toLowerCase()
          .includes(searchInput.toLowerCase());
        if (visibleBusinessUnits.length > 0) {
          include =
            include &&
            visibleBusinessUnits.includes(
              users[report.fieldTesterId!].businessUnitID!
            );
        }
        return include;
      }) ?? []
    );
  }, [reports, searchInput, users, visibleBusinessUnits]);

  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  if (status === 'error') {
    return (
      <div>
        <p>Något gick fel, försök igen?</p>
        <Button onClick={() => refetch} type="button">
          Försök igen
        </Button>
      </div>
    );
  }

  return (
    <Wrapper>
      <Actions>
        <Input
          onChange={(e) => setSearchInput(e.target.value)}
          placeholder="Filtrera på namn"
          value={searchInput}
          width={300}
        />
        <StyledMultiSelect
          onChange={(units) => setVisibleBusinessUnits(Array.from(units))}
          options={businessUnitOptions}
          placeholder="Välj enhet"
          value={new Set(visibleBusinessUnits)}
        />
      </Actions>
      <Table
        columnSettings={columnSettings}
        // onRowClick={handleRowClick}
        rows={filteredReports}
      />
    </Wrapper>
  );
};

export default DrivingPolicyOverview;
