import { faCar, faFileText, faWrench } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentModel, AssignmentViewModel, CaseModel } from 'api/model';
import {
  considerHighMileageAbove,
  considerOldYearModelAgeYears,
} from 'constants/AppConstants';
import styled from 'styled-components';

const IconGroup = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

export type WarningInfo = {
  warning: string;
  icon?: React.ReactNode;
  details?: string;
};

const getAssignmentWarnings = (assignment: AssignmentModel): WarningInfo[] => {
  const warnings = [];
  if (assignment.hasUncheckedFlaggedForSpeeding) {
    warnings.push({
      warning: 'Uppdraget har hastighetsöverträdelser',
    });
  }
  if (assignment.hasUncheckedDeviationReport) {
    warnings.push({
      warning: 'Uppdraget har en ohanterad avvikelse-rapport',
      icon: <FontAwesomeIcon icon={faFileText} />,
    });
  }
  if (assignment.assigneeReportedMissingGear) {
    warnings.push({
      warning: 'Fälttestaren saknade arbetsutrustning vid start',
    });
  }
  if (assignment.inspectionApproved === false) {
    warnings.push({
      warning: 'Besiktningen är inte godkänd',
    });
  }
  if (assignment.assignmentVehicleFaultDiscovered) {
    warnings.push({
      warning: 'Fel på fordonet upptäckt',
      icon: (
        <IconGroup>
          <FontAwesomeIcon icon={faWrench} size="2xs" />
          <FontAwesomeIcon icon={faCar} />
        </IconGroup>
      ),
    });
  }

  return warnings;
};

const getCaseWarnings = (model: CaseModel): WarningInfo[] => {
  const warnings = [];
  if (model.vehicleMileage > considerHighMileageAbove) {
    warnings.push({
      warning: 'Högmilare',
    });
  }
  // check year model age
  const currentYear = new Date().getFullYear();
  const { vehicleYearModel } = model;
  if (vehicleYearModel) {
    try {
      const yearModel = parseInt(vehicleYearModel, 10);
      const age = currentYear - yearModel;
      if (age > considerOldYearModelAgeYears) {
        warnings.push({
          warning: 'Fordonet är av äldre modell',
        });
      }
    } catch (error) {
      // console.log('Error parsing vehicle year model', error);
    }
  }
  return warnings;
};

const getCaseAndAssignmentWarnings = (model: AssignmentViewModel) => {
  const warnings = getAssignmentWarnings(model.assignment);
  const caseWarnings = getCaseWarnings(model.case);
  return [...warnings, ...caseWarnings];
};

export default {
  getAssignmentWarnings,
  getCaseWarnings,
  getCaseAndAssignmentWarnings,
};
