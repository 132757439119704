/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentCancelTypeEnum =
  (typeof AssignmentCancelTypeEnum)[keyof typeof AssignmentCancelTypeEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentCancelTypeEnum = {
  CanceledDayBefore: 0,
  CanceledNotStartedSameDay: 1,
  CanceledNotArrived: 2,
  CanceledArrived: 3,
  CanceledRebooked: 4,
  CanceledOther: 5,
} as const;
