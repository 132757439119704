import { useEffect, useMemo, useState } from 'react';
import Widget from '../../../components/Dashboard/Widget';
import Table from 'components/Table';
import { ColumnSetting } from 'components/Table/utils';
import {
  getGoSupportGetUnhandledDeviationReportsQueryKey,
  useGoSupportGetUnhandledDeviationReports,
} from 'api/go-support/go-support';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import Checkbox from 'components/inputs/Checkbox';
import Routes from 'constants/Routes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { useCaseMarkEventsAsReadByAdmin } from 'api/case/case';
import { useQueryClient } from '@tanstack/react-query';
import { CaseEventDTO } from 'api/model';
import {
  Centered,
  getTableRowClassName,
  SavingRowAnimationWrapper,
} from '../table-helpers';
import { GoSupportWidgetsEnum, LongTitles, UPDATE_INTERVAL } from '../utils';

const TABLE_NAME = 'deviation-reports';

const DeviationReportsWidget = () => {
  const [deviationReports, setDeviationReports] = useState<CaseEventDTO[]>([]);
  const [ongoingSaves, setOngoingSaves] = useState<number[]>([]);
  const users = useUsers();
  const {
    dashboardState: { daterange, selectedBusinessUnits, searchText },
  } = useDashboardControls();

  const queryClient = useQueryClient();
  const { mutateAsync: markEventAsRead, status: markAsReadStatus } =
    useCaseMarkEventsAsReadByAdmin({
      mutation: {
        onSuccess: () => {
          queryClient.invalidateQueries([
            `${getGoSupportGetUnhandledDeviationReportsQueryKey()}`,
          ]);
        },
      },
    });

  const getReports = useGoSupportGetUnhandledDeviationReports(
    {
      startDate: daterange[0] ?? undefined,
      endDate: daterange[1] ?? undefined,
      businessUnitIDs: selectedBusinessUnits,
    },
    {
      query: {
        refetchInterval: UPDATE_INTERVAL,
      },
    }
  );

  useEffect(() => {
    if (getReports.data) {
      setDeviationReports(getReports.data);
    } else {
      setDeviationReports([] as CaseEventDTO[]);
    }
  }, [getReports.data]);

  const filteredComments = useMemo(() => {
    if (!searchText.length) {
      return deviationReports;
    }
    return deviationReports
      ? deviationReports.filter((c) => {
          return (
            c.comment?.toLowerCase().includes(searchText.toLowerCase()) ||
            c.registrationNumber
              .toLowerCase()
              .includes(searchText.toLowerCase()) ||
            (users[c.createdByID]?.name ?? '')
              .toLowerCase()
              .includes(searchText.toLowerCase())
          );
        })
      : [];
  }, [deviationReports, searchText, users]);

  const columnSettings: ColumnSetting<CaseEventDTO, any>[] = useMemo(() => {
    const columns: ColumnSetting<CaseEventDTO, any>[] = [
      {
        head: 'Nr',
        render: (e, t, f, i) => i + 1,
        width: 15,
      },
      {
        head: 'Reg.nr',
        render: (row) => `${row.registrationNumber}`,
        width: 50,
        sortFunction: (a, b) => {
          return a.registrationNumber.localeCompare(b.registrationNumber);
        },
      },
      {
        head: 'Tid',
        render: (row) => `${formatDateTime(row.created)}`,
        width: 100,
        sortFunction: (a, b) => a.created.getTime() - b.created.getTime(),
      },
      {
        head: 'Användare',
        render: (row) => {
          return (
            <span>
              {users[row.createdByID] ? users[row.createdByID].name : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.createdByID]?.name ?? '').localeCompare(
            users[b.createdByID]?.name ?? ''
          ),
      },
      {
        head: 'Telefonnummer',
        render: (row) => {
          return (
            <span>
              {users[row.createdByID] ? users[row.createdByID].phoneNumber : ''}
            </span>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          (users[a.createdByID]?.phoneNumber ?? '').localeCompare(
            users[b.createdByID]?.phoneNumber ?? ''
          ),
      },
      {
        head: 'Kommentar',
        render: (row) => row.comment,
        width: 300,
        sortFunction: (a, b) => a.comment.localeCompare(b.comment),
      },
      {
        head: ' ',
        render: (row) => (
          <Centered>
            <FontAwesomeIcon
              onClick={() => {
                window.open(
                  `${Routes.search.index}/${row.caseID}`,
                  row.caseID.toString() ?? '_blank'
                );
              }}
              icon={faArrowUpRightFromSquare}
              fontSize="18px"
              style={{ cursor: 'pointer' }}
            />
          </Centered>
        ),
        width: 20,
      },
      {
        head: 'Klart',
        render: (row) => (
          <Centered>
            <Checkbox
              disabled={markAsReadStatus === 'loading'}
              onChange={async () => {
                setOngoingSaves([...ongoingSaves, row.id]);
                await markEventAsRead({ data: [row.id] });
                setOngoingSaves(ongoingSaves.filter((id) => id !== row.id));
              }}
            />
          </Centered>
        ),
        width: 30,
      },
    ];
    return columns;
  }, [markAsReadStatus, markEventAsRead, ongoingSaves, users]);
  return (
    <Widget
      title={LongTitles[GoSupportWidgetsEnum.DeviationReports]}
      count={deviationReports.length}
      isLoading={getReports.isLoading}
      info="Markera som klar för att markera kommentar som läst."
    >
      <SavingRowAnimationWrapper
        tableName={TABLE_NAME}
        savingIds={ongoingSaves}
      >
        <Table
          columnSettings={columnSettings}
          rows={filteredComments}
          rowClassName={(row) => getTableRowClassName(TABLE_NAME, row.id)}
          useColumnWidthAsFlexAmount
        />
      </SavingRowAnimationWrapper>
    </Widget>
  );
};

export default DeviationReportsWidget;
