/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { useEffect } from 'react';
import { useApiCall, RequestStatus, } from './useApiCall';
/** use deps array to automatically call methodCaller when a dependency changes */
export const useResponse = (client, methodCaller, deps, options) => {
    var _a;
    const apiCall = useApiCall(client, methodCaller, deps, Object.assign(Object.assign({}, options), { initialRequestStatus: (_a = options === null || options === void 0 ? void 0 : options.initialRequestStatus) !== null && _a !== void 0 ? _a : RequestStatus.Fetching }));
    useEffect(() => {
        apiCall.run();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, deps || []);
    const update = (response, updateOptions) => __awaiter(void 0, void 0, void 0, function* () {
        if (response !== undefined) {
            apiCall.setResponse(response);
        }
        else {
            return yield apiCall.advancedRun([], updateOptions !== null && updateOptions !== void 0 ? updateOptions : options);
        }
    });
    return {
        response: apiCall.response,
        status: apiCall.status,
        error: apiCall.error,
        setStatus: apiCall.setStatus,
        update,
    };
};
