import React from 'react';
import { faHandDots } from '@fortawesome/free-solid-svg-icons';
import './waving.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTheme } from 'styled-components';

const WavingHand = () => {
  const theme = useTheme();
  return (
    <span className="wave">
      <FontAwesomeIcon
        color={theme.colors.background.button}
        icon={faHandDots}
      />
    </span>
  );
};

export default WavingHand;
