import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
  z-index: 1;
`;

type LineProps = { angle?: number; width?: number; height?: number };

// calculates the rotate angle based on the width of the line
const Line = styled.div<LineProps>`
  position: absolute;
  width: ${({ width = 1, height = 1 }) =>
    Math.sqrt(width * width + height * height)}px; /* diagonal length */
  height: 2px;
  background-color: ${({ theme }) => theme.colors.background.tertiary};
  top: 0;
  left: 0;
  transform-origin: 0 0;
  transform: rotate(${({ angle }) => angle || 0}deg);
`;

const DiagonalLine: React.FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const [dims, setDims] = React.useState<LineProps>({
    angle: undefined,
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    const updateDims = () => {
      if (ref.current) {
        const a =
          Math.atan(ref.current.offsetHeight / ref.current.offsetWidth) *
          (180 / Math.PI);

        setDims({
          angle: a,
          width: ref.current.offsetWidth,
          height: ref.current.offsetHeight,
        });
      }
    };
    window.addEventListener('resize', updateDims);
    updateDims();

    return () => {
      window.removeEventListener('resize', updateDims);
    };
  }, []);

  return (
    <Container ref={ref}>
      <Line angle={dims.angle} height={dims.height} width={dims.width} />
    </Container>
  );
};

export default DiagonalLine;
