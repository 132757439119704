/* eslint-disable */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useCallback, useContext, useRef, useState, } from 'react';
export const ApiCallGlobalConfigContext = React.createContext({});
export var RequestStatus;
(function (RequestStatus) {
    RequestStatus[RequestStatus["Idle"] = 0] = "Idle";
    RequestStatus[RequestStatus["Fetching"] = 1] = "Fetching";
    RequestStatus[RequestStatus["Fetched"] = 2] = "Fetched";
    RequestStatus[RequestStatus["Error"] = 3] = "Error";
})(RequestStatus || (RequestStatus = {}));
export const useApiCall = (clientConstructor, methodCaller, methodCallerDeps, options) => {
    var _a;
    const [state, setState] = useState({
        response: undefined,
        error: undefined,
        status: (_a = options === null || options === void 0 ? void 0 : options.initialRequestStatus) !== null && _a !== void 0 ? _a : RequestStatus.Idle,
    });
    const globalConfig = useContext(ApiCallGlobalConfigContext);
    const requestCalls = useRef(0);
    const getClient = useCallback(() => {
        return globalConfig.constructClient
            ? globalConfig.constructClient(clientConstructor, options !== null && options !== void 0 ? options : {})
            : new clientConstructor();
    }, [clientConstructor, globalConfig.constructClient, options]);
    const capturedMethodCaller = useCallback(methodCaller, [methodCallerDeps]);
    const advancedRun = useCallback((extra, options) => __awaiter(void 0, void 0, void 0, function* () {
        var _b;
        setState((s) => (Object.assign(Object.assign({}, s), { status: RequestStatus.Fetching })));
        requestCalls.current++;
        const myRequestNumber = requestCalls.current;
        try {
            const response = yield capturedMethodCaller(getClient(), ...extra);
            // ignore setting the response if this call isn't the latest api call
            if (myRequestNumber === requestCalls.current) {
                setState((s) => (Object.assign(Object.assign({}, s), { status: RequestStatus.Fetched, response, error: undefined })));
            }
            return [response, null];
        }
        catch (err) {
            if (options === null || options === void 0 ? void 0 : options.clearResponseOnError) {
                setState((s) => (Object.assign(Object.assign({}, s), { status: RequestStatus.Error, error: err, response: undefined })));
            }
            else {
                setState((s) => (Object.assign(Object.assign({}, s), { status: RequestStatus.Error, error: err })));
            }
            (_b = globalConfig.onApiError) === null || _b === void 0 ? void 0 : _b.call(globalConfig, err, options !== null && options !== void 0 ? options : {});
            return [null, err];
        }
    }), [capturedMethodCaller, getClient, globalConfig.onApiError]);
    const run = useCallback((...extra) => __awaiter(void 0, void 0, void 0, function* () {
        return yield advancedRun(extra, options);
    }), [advancedRun, options]);
    const setResponse = useCallback((setAction) => {
        requestCalls.current++;
        if (setAction instanceof Function) {
            setState((s) => (Object.assign(Object.assign({}, s), { response: setAction(s.response), status: RequestStatus.Fetched })));
        }
        else {
            setState((s) => (Object.assign(Object.assign({}, s), { response: setAction, status: RequestStatus.Fetched })));
        }
    }, []);
    const setStatus = useCallback((setAction) => {
        if (setAction instanceof Function) {
            setState((s) => (Object.assign(Object.assign({}, s), { status: setAction(s.status) })));
        }
        else {
            setState((s) => (Object.assign(Object.assign({}, s), { status: setAction })));
        }
    }, []);
    return {
        run,
        advancedRun,
        response: state.response,
        error: state.error,
        status: state.status,
        setResponse,
        setStatus,
    };
};
