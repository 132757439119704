import { ColumnSetting } from 'components/Table/utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { ITranslationLookups } from 'api';
import styled, { css } from 'styled-components';
import { useInternalDeliveryGroupBudgetSettingsFilters } from '../useInternalDeliveryGroupBudgetSettingsFilters';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';
import Input from 'components/inputs/Input';
import { InternalDeliveryYearlyBudgetDTO } from 'api/model';
import useCompanies from 'contexts/basicData/useCompanies';
import { QueryKey, UseQueryResult } from '@tanstack/react-query';
import { useInternalDeliveryBudgetUpdateYearlyBudget } from 'api/internal-delivery-budget/internal-delivery-budget';

const HeadRowCss = css`
  align-items: center;
  display: flex;
`;

const TitleDate = styled.div<{
  flex: number;
  highlighted: boolean;
  isHoliday?: boolean;
}>`
  display: flex;
  flex: ${({ flex }) => flex};
  font-weight: bold;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid transparent;
  color: ${({ highlighted, theme }) =>
    highlighted
      ? theme.colors.foreground.tint
      : theme.colors.foreground.button};
  gap: 3px;

  ${({ isHoliday }) => isHoliday && 'color: red;'}

  & > span.weekday {
    font-size: 0.8em;
  }
  & > span.weekday {
    font-weight: normal;
  }
`;

export type DataValues = {
  date: Date;
  businessUnit: string;
  unassigned: number;
  planned: number;
  dealtOut: number;
};

export type BusinessUnitData = {
  unitId: number;
  data: DataValues[];
};

export interface FacilityRow {
  facility: string;
  rowData: BusinessUnitData[];
}

export const facilityColors: { facility: string; color: string }[] = [
  { facility: 'Östersund', color: '#9f333c' },
  { facility: 'Sundsvall', color: '#ff9e1e' },
  { facility: 'Västerås', color: '#7b7f8b' },
  { facility: 'Örebro', color: '#c0e3b9' },
  { facility: 'Tyresö', color: '#dce81f' },
  { facility: 'Nacka', color: '#de679b' },
  { facility: 'Järfälla', color: '#fed13e' },
  { facility: 'Täby', color: '#4b9184' },
  { facility: 'Linköping', color: '#f2b2ab' },
  { facility: 'Webblager', color: '#f0e5bd' },
  { facility: 'Rechage', color: '#cacbc6' },
  { facility: 'Transportbildcenter', color: '#8b99d8' },
  { facility: 'Kalmar', color: '#3e6475' },
  { facility: 'Halmstad', color: '#626b9a' },
  { facility: 'Trollhättan', color: '#885b3d' },
];

type CustomInputProps = {
  value: number;
  year: number;
  weekNr: number;
  companyId: number;
  budgetId?: number;
  onSuccess?: () => void;
};

const CustomNumberInput: React.FC<CustomInputProps> = ({
  value,
  year,
  weekNr,
  companyId,
  budgetId,
  onSuccess,
}) => {
  const [inputValue, setInputValue] = useState(value);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const updateBudget = useInternalDeliveryBudgetUpdateYearlyBudget();

  const handleChangeValue = async (val: number) => {
    if (val !== value) {
      updateBudget.mutateAsync(
        {
          data: [
            {
              companyId,
              weeks: [
                {
                  companyID: companyId,
                  budgetID: budgetId ?? 0,
                  year,
                  week: weekNr,
                  amount: val,
                },
              ],
            },
          ],
        },
        {
          onSuccess: () => {
            onSuccess?.();
          },
        }
      );
    }
  };

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  useEffect(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (inputValue !== value) {
      timeoutRef.current = setTimeout(
        () => handleChangeValue(inputValue),
        1000
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputValue]);

  return (
    <Input
      type="number"
      onChange={(eve) => {
        if (Number(eve.target.value) < 0) {
          setInputValue(0);
          return;
        }
        setInputValue(Number(eve.target.value));
      }}
      style={{
        width: '100%',
        textAlign: 'center',
        backgroundColor: 'transparent',
        border: 'none',
      }}
      value={inputValue}
    />
  );
};

export interface ColumnProps {
  translations: ITranslationLookups;
  startDate: Date;
  endDate: Date;
}

const useBudgetSettingsColumnSettings = (
  getBudgetsQuery: UseQueryResult<
    InternalDeliveryYearlyBudgetDTO[],
    unknown
  > & {
    queryKey: QueryKey;
  }
): ColumnSetting<InternalDeliveryYearlyBudgetDTO, ColumnProps>[] => {
  const {
    state: { selectedDate, weekNumbers },
  } = useInternalDeliveryGroupBudgetSettingsFilters();
  const { companies } = useCompanies();

  return useMemo(() => {
    type Column = ColumnSetting<InternalDeliveryYearlyBudgetDTO, ColumnProps>;
    const columns: Column[] = [
      {
        csvValue: (model) =>
          companies.find((c) => c.companyID === model.companyId)?.name ?? '',
        css: HeadRowCss,
        head: 'Anläggning',
        render: (model, _props, _focused, rowIndex) => (
          <div style={{ display: 'flex', gap: '5px' }}>
            <FontAwesomeIcon
              icon={faTag}
              stroke="black"
              strokeWidth={15}
              color={facilityColors[rowIndex] && facilityColors[rowIndex].color}
              style={{ transform: 'rotateY(180deg)' }}
            />
            {companies.find((c) => c.companyID === model.companyId)?.name ?? ''}
          </div>
        ),
        width: 250,
      },
    ] as Column[];

    for (let weekIndex = 0; weekIndex < weekNumbers.length; weekIndex += 1) {
      columns.push({
        head: (
          <TitleDate flex={1} highlighted={false} key={`title_${weekIndex}`}>
            {`Vecka ${weekNumbers[weekIndex]}`}
          </TitleDate>
        ),
        focusable: true,
        render: (model, _props, _focused) => {
          // console.log('Model in cell', model);
          const weekCell = model.weeks.find(
            (week) => week.week === weekNumbers[weekIndex]
          ) ?? {
            amount: 0,
            budgetID: 0,
            companyID: model.companyId,
            week: weekNumbers[weekIndex],
            year: selectedDate.getFullYear(),
          };

          return (
            <CustomNumberInput
              onSuccess={() => {
                getBudgetsQuery.refetch();
              }}
              year={weekCell.year}
              weekNr={weekCell.week}
              companyId={weekCell.companyID}
              budgetId={weekCell.budgetID}
              value={weekCell.amount}
            />
          );
        },
        width: 120,
      });
    }

    return columns.filter((c) => !!c); // remove undefined before returning columns
  }, [companies, getBudgetsQuery, selectedDate, weekNumbers]);
};

export default useBudgetSettingsColumnSettings;
