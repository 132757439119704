import {
  CaseModel,
  CaseStatusEnum,
  CaseTypeEnum,
  CompanyModel,
  ICaseModel,
} from 'api';
import useForm, { BasicFormState } from 'hooks/useForm';
import { toInputDateTimeString } from 'utils/date-helpers';
import { sweMobilePhoneNumberRegex } from 'utils/regex';

export enum CaseDataField {
  caseTypeID,
  caseStatusID,
  closedDate,
  companyID,
  registrationNumber,
  vehicleGearBox,
  vehicleMileage,
  customerName,
  customerPhoneNumber,
  bookerName,
  bookerPhoneNumber,
  dataFromRideManaged,
  rideOrderID,
  rideDeliveryID,
  InvoiceDataID,
  isElectric,
  isPremiumCar,
  isTrailer,
  isTruck,
  vehicleTyreType,
  vehicleYearModel,
  sendCustomerSurvey,
}

export function initializeCaseDataForm(
  caseModel?: ICaseModel,
  caseType?: CaseTypeEnum
): BasicFormState<CaseDataField> {
  if (caseModel) {
    return {
      [CaseDataField.caseTypeID]: String(caseType ?? caseModel.caseTypeID),
      [CaseDataField.caseStatusID]: String(caseModel.caseStatusID),
      [CaseDataField.closedDate]: caseModel.closedDate
        ? toInputDateTimeString(caseModel.closedDate)
        : '',
      [CaseDataField.companyID]: String(caseModel.company?.companyID ?? ''),
      [CaseDataField.registrationNumber]: caseModel.registrationNumber,
      [CaseDataField.vehicleGearBox]: caseModel.vehicleGearBox
        ? 'true'
        : 'false',
      [CaseDataField.vehicleMileage]: String(caseModel.vehicleMileage),
      [CaseDataField.customerName]: caseModel.customerName,
      [CaseDataField.customerPhoneNumber]: caseModel.customerPhoneNumber,
      [CaseDataField.bookerName]: caseModel.bookerName,
      [CaseDataField.bookerPhoneNumber]: caseModel.bookerPhoneNumber,
      [CaseDataField.dataFromRideManaged]: caseModel.newDataFromRide
        ? 'false'
        : 'true',
      [CaseDataField.rideOrderID]: caseModel.rideOrderID
        ? String(caseModel.rideOrderID)
        : '',
      [CaseDataField.rideDeliveryID]: caseModel.rideDeliveryID
        ? String(caseModel.rideDeliveryID)
        : '',
      [CaseDataField.InvoiceDataID]: caseModel.invoiceDataID
        ? String(caseModel.invoiceDataID)
        : '',
      [CaseDataField.isElectric]: caseModel.isElectric ? 'true' : 'false',
      [CaseDataField.isPremiumCar]: caseModel.isPremiumCar ? 'true' : 'false',
      [CaseDataField.isTrailer]: caseModel.isTrailer ? 'true' : 'false',
      [CaseDataField.isTruck]: caseModel.isTruck ? 'true' : 'false',
      [CaseDataField.vehicleTyreType]: caseModel.vehicleTyreType
        ? String(caseModel.vehicleTyreType)
        : '',
      [CaseDataField.vehicleYearModel]: caseModel.vehicleYearModel ?? '',
      [CaseDataField.sendCustomerSurvey]: caseModel.sendCustomerSurvey
        ? 'true'
        : 'false',
    };
  }
  return {
    [CaseDataField.caseTypeID]: String(caseType ?? CaseTypeEnum.Purchase),
    [CaseDataField.caseStatusID]: String(CaseStatusEnum.Open),
    [CaseDataField.closedDate]: '',
    [CaseDataField.companyID]: '',
    [CaseDataField.registrationNumber]: '',
    [CaseDataField.vehicleGearBox]: 'true',
    [CaseDataField.vehicleMileage]: '0',
    [CaseDataField.customerName]: '',
    [CaseDataField.customerPhoneNumber]: '',
    [CaseDataField.bookerName]: '',
    [CaseDataField.bookerPhoneNumber]: '',
    [CaseDataField.dataFromRideManaged]: 'true',
    [CaseDataField.rideOrderID]: '',
    [CaseDataField.rideDeliveryID]: '',
    [CaseDataField.InvoiceDataID]: '',
    [CaseDataField.isElectric]: 'false',
    [CaseDataField.isPremiumCar]: 'false',
    [CaseDataField.isTrailer]: 'false',
    [CaseDataField.isTruck]: 'false',
    [CaseDataField.vehicleTyreType]: '',
    [CaseDataField.vehicleYearModel]: '',
    [CaseDataField.sendCustomerSurvey]: [
      CaseTypeEnum.Purchase,
      CaseTypeEnum.HomeDelivery,
    ].includes(caseType ?? CaseTypeEnum.Purchase)
      ? 'true'
      : 'false',
  };
}

export function caseDataFormToCaseModel(
  base: ICaseModel | undefined,
  form: BasicFormState<CaseDataField>,
  companies: CompanyModel[]
): CaseModel {
  const companyId = Number(form[CaseDataField.companyID]);
  let rideOrderID: number | undefined;
  let rideDeliveryID: number | undefined;
  if (
    form[CaseDataField.rideOrderID] !== '' &&
    form[CaseDataField.rideOrderID] !== undefined
  ) {
    rideOrderID = Number(form[CaseDataField.rideOrderID]);
  }
  if (
    form[CaseDataField.rideDeliveryID] !== '' &&
    form[CaseDataField.rideDeliveryID] !== undefined
  ) {
    rideDeliveryID = Number(form[CaseDataField.rideDeliveryID]);
  }

  return new CaseModel({
    adminHasUnhandledRequests: base?.adminHasUnhandledRequests ?? false,
    caseID: base?.caseID ?? -1,
    caseTypeID: Number(form[CaseDataField.caseTypeID]),
    caseStatusID: Number(form[CaseDataField.caseStatusID]),
    closedDate: form[CaseDataField.closedDate]
      ? new Date(form[CaseDataField.closedDate])
      : undefined,
    rideOrderID,
    rideDeliveryID,
    registrationNumber: form[CaseDataField.registrationNumber],
    vehicleGearBox:
      form[CaseDataField.vehicleGearBox] === 'true'
        ? true
        : form[CaseDataField.vehicleGearBox] === 'false'
          ? false
          : undefined,
    vehicleMileage: Number(form[CaseDataField.vehicleMileage]),
    bookerName: form[CaseDataField.bookerName],
    customerName: form[CaseDataField.customerName],
    customerPhoneNumber: form[CaseDataField.customerPhoneNumber],
    bookerPhoneNumber: form[CaseDataField.bookerPhoneNumber],
    timestamp: base?.timestamp ?? '',
    company: companies.find((company) => company.companyID === companyId),
    assignmentList: [...(base?.assignmentList ?? [])],
    hasLinkedCases: base?.hasLinkedCases ?? false,
    adminHasUnreadComments: base?.adminHasUnreadComments ?? false,
    createdByID: base?.createdByID ?? -1,
    createdBy: base?.createdBy ?? '',
    modifiedByID: base?.modifiedByID ?? -1,
    modifiedBy: base?.modifiedBy ?? '',

    externalProtocolUrl: base?.externalProtocolUrl ?? '',
    externalTestUrl: base?.externalTestUrl ?? '',
    newDataFromRide: form[CaseDataField.dataFromRideManaged] === 'false',
    invoiceDataID: base?.invoiceDataID,
    isElectric: form[CaseDataField.isElectric] === 'true',
    isPremiumCar: form[CaseDataField.isPremiumCar] === 'true',
    isTrailer: form[CaseDataField.isTrailer] === 'true',
    isTruck: form[CaseDataField.isTruck] === 'true',
    vehicleTyreType: form[CaseDataField.vehicleTyreType]
      ? Number(form[CaseDataField.vehicleTyreType])
      : undefined,
    vehicleYearModel: form[CaseDataField.vehicleYearModel] ?? '',
    sendCustomerSurvey: form[CaseDataField.sendCustomerSurvey] === 'true',
  });
}

const bookerOptionalCaseTypes = [
  CaseTypeEnum.Extra,
  CaseTypeEnum.InternalDelivery,
  CaseTypeEnum.FaciliyExchange,
] as const;

const useCaseDataForm = (caseModel?: ICaseModel) => {
  return useForm(initializeCaseDataForm(caseModel), {
    [CaseDataField.companyID]: (v) =>
      v ? undefined : { error: 'Ange ett företag' },
    [CaseDataField.registrationNumber]: (v) =>
      v.trim().length === 0 ? { error: 'Ange ett reg.nr' } : undefined,
    [CaseDataField.customerName]: (v) =>
      v.trim().length === 0 ? { error: 'Ange en kund' } : undefined,
    [CaseDataField.customerPhoneNumber]: (v) =>
      sweMobilePhoneNumberRegex.test(v.replaceAll(' ', '').replaceAll('-', ''))
        ? undefined
        : { warning: 'Kontrollera att kundens telefonnummer är korrekt' },
    [CaseDataField.bookerName]: (v, _, formState) =>
      !bookerOptionalCaseTypes.includes(
        Number(formState[CaseDataField.caseTypeID])
      ) && v.trim().length === 0
        ? { error: 'Ange en bokare' }
        : undefined,
    [CaseDataField.vehicleMileage]: (v) =>
      Number(v) === 0
        ? { warning: 'Miltal är 0, är detta korrekt?' }
        : undefined,
    [CaseDataField.rideOrderID]: (v) =>
      Number.isNaN(Number(v))
        ? { warning: 'RideOrderID, Ange ett nummer eller lämna tomt' }
        : undefined,
    [CaseDataField.InvoiceDataID]: (v) =>
      Number.isNaN(Number(v))
        ? { warning: 'InvoiceDataID, Ange ett nummer eller lämna tomt' }
        : undefined,
  });
};

export default useCaseDataForm;
