import styled from 'styled-components';

const EmptyMessage = styled.div`
  padding: 20px 0;
  color: ${({ theme }) => theme.colors.foreground.primary};
  font-style: italic;
  text-align: center;
  opacity: 0.8;
`;

export default EmptyMessage;
