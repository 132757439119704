import { CaseClient, CaseExtendedModel, ExpenseModel } from 'api';
import { pollersErrorModalId, refreshRate } from 'constants/AppConstants';
import ApiErrorModalIds from 'contexts/ApiCallConfiguration/ApiErrorModalIds';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import useModalStack from 'contexts/modal/useModalStack';
import useUpdateInterval from 'hooks/useUpdateInterval';
import React, { useContext, useEffect, useMemo } from 'react';
import {
  ApiResponse,
  RequestStatus,
  useApiCall,
  useResponse,
} from 'swaggerhooks/lib';
import DetailedCaseFormProvider from '../DetailedCaseFormProvider';
import useCaseActions from './useCaseActions';
import useCaseAssignmentActions from './useCaseAssignmentActions';
import useEventActions from './useEventActions';
import useExpenseActions from './useExpenseActions';

interface DetailedCaseContextValue {
  /** if caseResponse == undefined, then the provided id prop was undefined, to create a new case. */
  caseResponse: ApiResponse<CaseExtendedModel | undefined>;

  onCaseAssignmentCancel(assignmentId: number): void;
  onCaseAssignmentStopOver(assignmentId: number): void;
  onPlannedCaseAssignmentStopOver(assignmentId: number): void;
  onCaseAssignmentDiscard(assignmentId: number): void;
  onCaseAssignmentApprove(assignmentId: number): void;
  isUpdatingAssignmentStatus: boolean;

  onCloseCase(caseId: number): void;
  closeCaseStatus: 'error' | 'idle' | 'loading' | 'success';

  onSaveExpense(expense: ExpenseModel): void;
  onDeleteExpense(expense: ExpenseModel): void;
  saveExpenseStatus: RequestStatus;
  deleteExpenseStatus: RequestStatus;

  onSaveEvent: ReturnType<typeof useEventActions>['onSaveEvent'];
  onMarkEventsAsRead: ReturnType<typeof useEventActions>['onMarkEventsAsRead'];
  onDeleteEvent: ReturnType<typeof useEventActions>['onDeleteEvent'];
  saveEventStatus: RequestStatus;
  markEventsAsReadStatus: 'error' | 'idle' | 'loading' | 'success';
  deleteEventStatus: RequestStatus;
}

const DetailedCaseContext = React.createContext<
  DetailedCaseContextValue | undefined
>(undefined);

export const useDetailedCaseContext = () => {
  const value = useContext(DetailedCaseContext);
  if (!value) {
    throw Error(
      "Can't useDetailedCaseProps() outside of DetailedCase component"
    );
  }
  return value;
};

interface Props {
  /** If left undefined, a new case will be created */
  caseId?: number;
  children: React.ReactNode;
}

const DetailedCaseProvider = ({ caseId, children }: Props) => {
  const { pop: popModal } = useModalStack();
  const { triggerAssignmentsUpdate } = useAssignmentsFilters();

  const caseResponse = useResponse(
    CaseClient,
    async (c) => {
      if (caseId !== undefined) {
        const r = await c.getCase(caseId);
        return r;
      }
      return undefined;
    },
    [caseId]
  );

  const refreshCaseApiCall = useApiCall(
    CaseClient,
    async (c, id: number) => c.getCase(id),
    undefined,
    { errorModalId: pollersErrorModalId }
  );

  const handleCaseWasUpdated = async () => {
    await caseResponse.update();
    triggerAssignmentsUpdate(new Date());
  };

  const { onCloseCase, closeCaseStatus } = useCaseActions(
    caseResponse.response,
    handleCaseWasUpdated
  );

  const {
    onCaseAssignmentApprove,
    onCaseAssignmentCancel,
    onCaseAssignmentStopOver,
    onPlannedCaseAssignmentStopOver,
    onCaseAssignmentDiscard,
    isUpdatingAssignmentStatus,
  } = useCaseAssignmentActions(caseResponse.response, handleCaseWasUpdated);

  const {
    onDeleteExpense,
    onSaveExpense,
    deleteExpenseStatus,
    saveExpenseStatus,
  } = useExpenseActions(caseResponse.response, handleCaseWasUpdated);

  const {
    onSaveEvent,
    onMarkEventsAsRead,
    onDeleteEvent,
    saveEventStatus,
    markEventsAsReadStatus,
    deleteEventStatus,
  } = useEventActions(caseResponse.response, handleCaseWasUpdated);

  const { startInterval, stopInterval, isRunning } = useUpdateInterval(
    async () => {
      if (caseId !== undefined) {
        const [response, error] = await refreshCaseApiCall.run(caseId);

        if (response && !error) {
          popModal(pollersErrorModalId);
          popModal(ApiErrorModalIds.ConnectionError);
          caseResponse.update(response);
        }
      }
    },
    refreshRate,
    false
  );

  useEffect(() => {
    if (!isRunning) {
      startInterval();
    }

    return () => {
      stopInterval();
    };
  }, [
    caseId,
    caseResponse,
    isRunning,
    popModal,
    refreshCaseApiCall,
    startInterval,
    stopInterval,
  ]);

  const contextValue = useMemo(
    (): DetailedCaseContextValue => ({
      caseResponse,

      onCaseAssignmentCancel,
      onCaseAssignmentStopOver,
      onPlannedCaseAssignmentStopOver,
      onCaseAssignmentDiscard,
      onCaseAssignmentApprove,
      isUpdatingAssignmentStatus,

      onCloseCase,
      closeCaseStatus,

      onSaveExpense,
      onDeleteExpense,
      saveExpenseStatus,
      deleteExpenseStatus,

      onSaveEvent,
      onMarkEventsAsRead,
      onDeleteEvent,
      saveEventStatus,
      markEventsAsReadStatus,
      deleteEventStatus,
    }),
    [
      caseResponse,
      onCaseAssignmentCancel,
      onCaseAssignmentStopOver,
      onPlannedCaseAssignmentStopOver,
      onCaseAssignmentDiscard,
      onCaseAssignmentApprove,
      isUpdatingAssignmentStatus,
      onCloseCase,
      closeCaseStatus,
      onSaveExpense,
      onDeleteExpense,
      saveExpenseStatus,
      deleteExpenseStatus,
      onSaveEvent,
      onMarkEventsAsRead,
      onDeleteEvent,
      saveEventStatus,
      markEventsAsReadStatus,
      deleteEventStatus,
    ]
  );

  return (
    <DetailedCaseContext.Provider value={contextValue}>
      <DetailedCaseFormProvider>{children}</DetailedCaseFormProvider>
    </DetailedCaseContext.Provider>
  );
};

export default DetailedCaseProvider;
