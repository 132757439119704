import {
  faArrowDown,
  faCheck,
  faExternalLinkAlt,
  faWarning,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useAssignmentFindAssignmentByIDHook,
  useAssignmentUpdateAssignment,
} from 'api/assignment/assignment';
import {
  AssignmentModel,
  AssignmentStatusEnum,
  AssignmentTypeEnum,
  AssignmentViewModel,
  CaseEventType,
  UserGetDrivingPolicyReportForUnPlannedAssignmentParams,
} from 'api/model';
import { useUserGetDrivingPolicyReportForUnPlannedAssignment } from 'api/user/user';
import Input from 'components/inputs/Input';
import TextButton from 'components/inputs/TextButton';
import KeyValueList from 'components/KeyValueList';
import Modal from 'components/Modal';
import useTranslations from 'contexts/basicData/useTranslations';
import React, { ReactNode, useCallback, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import styled, { useTheme } from 'styled-components';
import { formatAddress } from 'utils/address';
import { openGoogleMapsDirections } from 'utils/address-helper';
import { getHoursAndMinutesFromMillisecondsString } from 'utils/date-helpers';

const Header = styled.div`
  margin-bottom: 10px;
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 1.2em;
`;

const SubHeader = styled.div`
  margin-top: 2rem;
  margin-bottom: 10px;
  font-weight: 600;
  font-size: 1.1em;
`;

const Center = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

const Warning = styled.div`
  margin-top: 2rem;
  color: ${({ theme }) => theme.colors.foreground.error};
`;

type UpdateData = {
  timeBetween?: number;
};

const getDefaultValues = (_assignment: AssignmentModel): UpdateData => {
  return {
    timeBetween: undefined,
  };
};

type Props = {
  currentAssignment: AssignmentViewModel;
  selectedAssignment: AssignmentViewModel;
  onCancel: () => void;
  onConfirm: () => void;
};

const AssignUserToReturnAssignmentModal: React.FC<Props> = ({
  currentAssignment,
  selectedAssignment,
  onCancel,
  onConfirm,
}) => {
  const { assigneeWarnings } = useTranslations();
  const theme = useTheme();
  const { register, watch } = useForm<UpdateData>({
    defaultValues: getDefaultValues(selectedAssignment.assignment),
  });

  const timeBetween = watch('timeBetween');

  const usedDrivingPolicyTimes = useMemo(():
    | UserGetDrivingPolicyReportForUnPlannedAssignmentParams
    | undefined => {
    const isFurtherTransport =
      selectedAssignment.assignment.assignmentTypeID ===
      AssignmentTypeEnum.FurtherTransport;

    // for furthertransport, we want to use the current assignment as the start times (as it is the return assignment)
    // for other assignment types, we do not want to change the times of the selected assignment (as this is probably booked with a customer)
    const startAssignment = isFurtherTransport
      ? currentAssignment.assignment
      : selectedAssignment.assignment;
    const endAssignment = isFurtherTransport
      ? selectedAssignment.assignment
      : currentAssignment.assignment;
    const { assignedTo } = currentAssignment.assignment;
    const excludeAssignmentId = isFurtherTransport
      ? selectedAssignment.assignment.assignmentID
      : currentAssignment.assignment.assignmentID;
    const estimatedDuration = isFurtherTransport
      ? selectedAssignment.assignment.estimatedDuration
      : currentAssignment.assignment.estimatedDuration;

    if (
      !startAssignment.estimatedStartTime ||
      !startAssignment.estimatedDuration ||
      timeBetween === undefined ||
      !endAssignment.estimatedDuration ||
      !assignedTo
    ) {
      return undefined;
    }

    const usedStartTime = new Date(
      startAssignment.estimatedStartTime.getTime() +
        startAssignment.estimatedDuration +
        timeBetween * 60 * 1000
    );

    const usedEndTime = new Date(
      usedStartTime.getTime() + endAssignment.estimatedDuration
    );

    return {
      userId: assignedTo.userID,
      bookedTo: usedStartTime,
      startOrEstimatedStartTime: usedStartTime,
      endOrEstimatedEndTime: usedEndTime,
      estimatedDuration,
      excludeAssignmentId,
    };
  }, [
    currentAssignment.assignment,
    selectedAssignment.assignment,
    timeBetween,
  ]);

  const getDrivingPolicyReportForUnPlannedAssignment =
    useUserGetDrivingPolicyReportForUnPlannedAssignment(
      usedDrivingPolicyTimes,
      {
        query: {
          enabled: !!usedDrivingPolicyTimes,
        },
      }
    );
  const findAssignmentById = useAssignmentFindAssignmentByIDHook();
  const updateAssignment = useAssignmentUpdateAssignment();

  const goToCase = useCallback(() => {
    // open the case in a new tab with the caseID
    window.open(
      `/sok/${selectedAssignment.case.caseID}`,
      selectedAssignment.case.caseID.toString()
    );
  }, [selectedAssignment.case.caseID]);

  const assignUserToAssignment = async () => {
    try {
      // Pass the dynamic assignmentID to refetch
      const freshAssignmentData = await findAssignmentById({
        assignmentID: selectedAssignment.assignment.assignmentID,
      });

      if (!freshAssignmentData || !usedDrivingPolicyTimes) {
        toast.error('Något gick fel');
        return;
      }

      const updatedAssignment: AssignmentViewModel = {
        case: freshAssignmentData.case,
        assignment: {
          ...freshAssignmentData.assignment,
          assignedTo: currentAssignment.assignment.assignedTo,
          bookedTo: usedDrivingPolicyTimes.bookedTo!,
          estimatedStartTime: usedDrivingPolicyTimes.startOrEstimatedStartTime,
        },
      };

      await updateAssignment.mutateAsync({
        data: updatedAssignment,
        params: {
          eventType: CaseEventType.AssignmentAssigned,
          eventMessage: `Uppdraget tilldelades ${currentAssignment.assignment.assignedTo?.name} som returuppdrag för ${currentAssignment.case.registrationNumber}`,
          eventReadByAdmin: true,
        },
      });

      toast.success(
        `Uppdraget tilldelades ${JSON.stringify(updatedAssignment)}`
      );
      goToCase();
    } catch (error) {
      // console.log('Error assigning user to assignment', error);
    }
  };

  const getWarnings = useCallback(() => {
    const warnings: ReactNode[] = [];
    const openAssignments = selectedAssignment.case.assignmentList.filter(
      (x) =>
        !x.assignedTo && x.assignmentStatusID === AssignmentStatusEnum.Created
    );

    if (openAssignments.length > 1) {
      if (
        selectedAssignment.assignment.assignmentTypeID ===
        AssignmentTypeEnum.FurtherTransport
      ) {
        warnings.push(
          <Warning key="warning-too-many-further-transport">
            Obs! Ärendet har flera öppna uppdrag. Försäkra dig om att du vill
            tilldela användaren till detta uppdrag.{' '}
            <TextButton onClick={goToCase}>
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </TextButton>
          </Warning>
        );
      }
      getDrivingPolicyReportForUnPlannedAssignment.data?.warnings.forEach(
        (w) => {
          warnings.push(<Warning key={w}>{assigneeWarnings[w]}</Warning>);
        }
      );
    }
    return warnings;
  }, [
    assigneeWarnings,
    getDrivingPolicyReportForUnPlannedAssignment.data?.warnings,
    goToCase,
    selectedAssignment.assignment.assignmentTypeID,
    selectedAssignment.case.assignmentList,
  ]);

  return (
    <Modal
      buttons={[
        {
          label: 'Tilldela',
          disabled:
            watch('timeBetween') === undefined ||
            !getDrivingPolicyReportForUnPlannedAssignment.data?.canAdd,
          onClick: () => {
            assignUserToAssignment();
            onConfirm();
          },
        },
        {
          label: 'Avbryt',
          onClick: () => {
            onCancel();
          },
        },
      ]}
    >
      <Header>
        Tilldela {currentAssignment.assignment.assignedTo?.name} till
        returuppdrag
        <TextButton onClick={goToCase}>
          {selectedAssignment.case.registrationNumber}{' '}
          <FontAwesomeIcon icon={faExternalLinkAlt} />
        </TextButton>
      </Header>
      <KeyValueList
        keyWidth="250px"
        rows={[
          {
            key: `1. Slutpunkt för ${currentAssignment.case.registrationNumber} (${getHoursAndMinutesFromMillisecondsString(currentAssignment.assignment.estimatedDuration!)})`,
            value: formatAddress({
              name: currentAssignment.assignment.toName,
              address: currentAssignment.assignment.toAddress,
              zip: currentAssignment.assignment.toZip,
              city: currentAssignment.assignment.toCity,
            }),
          },
          {
            value: <FontAwesomeIcon icon={faArrowDown} />,
          },
          {
            key: '2. Nödvändig tid mellan uppdragen',
            value: (
              <Center>
                <Input
                  max={300}
                  style={{
                    width: 60,
                  }}
                  {...register('timeBetween')}
                  type="number"
                />
                <span>minuter</span>
                <TextButton
                  onClick={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    openGoogleMapsDirections({
                      fromAddress: currentAssignment.assignment.toAddress,
                      fromCity: currentAssignment.assignment.toCity,
                      fromZip: currentAssignment.assignment.toZip,
                      toAddress: selectedAssignment.assignment.fromAddress,
                      toCity: selectedAssignment.assignment.fromCity,
                      toZip: selectedAssignment.assignment.fromZip,
                    });
                  }}
                >
                  <img
                    alt="Google maps icon"
                    height={15}
                    src="/images/googlemapsiconsmall.png"
                    width={15}
                  />
                </TextButton>
              </Center>
            ),
          },
          {
            value: <FontAwesomeIcon icon={faArrowDown} />,
          },
          {
            key: `3. Startpunkt för ${selectedAssignment.case.registrationNumber}  (${getHoursAndMinutesFromMillisecondsString(selectedAssignment.assignment.estimatedDuration!)})`,
            value: formatAddress({
              name: selectedAssignment.assignment.fromName,
              address: selectedAssignment.assignment.fromAddress,
              zip: selectedAssignment.assignment.fromZip,
              city: selectedAssignment.assignment.fromCity,
            }),
          },
          {
            key: '',
            value: <FontAwesomeIcon icon={faArrowDown} />,
          },
          {
            key: 'Total arbetstid för båda uppdragen',
            value:
              watch('timeBetween') === undefined ? (
                <span style={{ opacity: 0.4, fontStyle: 'italic' }}>
                  Fyll i Nödvändig tid mellan uppdragen
                </span>
              ) : (
                getHoursAndMinutesFromMillisecondsString(
                  currentAssignment.assignment.estimatedDuration! +
                    selectedAssignment.assignment.estimatedDuration! +
                    watch('timeBetween')! * 60 * 1000
                )
              ),
          },
        ]}
      />
      {watch('timeBetween') !== undefined && (
        <>
          <SubHeader>Körtidspolicy</SubHeader>
          <KeyValueList
            keyWidth="250px"
            rows={[
              {
                key: 'Tillräcklig dygnsvila',
                value: getDrivingPolicyReportForUnPlannedAssignment.data
                  ?.hasConsecutiveHoursOfRestLast24Hours ? (
                  <span>
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={theme.colors.foreground.good}
                    />
                    Ja
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon
                      icon={faWarning}
                      color={theme.colors.foreground.error}
                    />
                    Nej
                  </span>
                ),
              },
              {
                key: 'Tillräcklig veckovila',
                value: getDrivingPolicyReportForUnPlannedAssignment.data
                  ?.hasConsecutiveHoursOfRestThisWeek ? (
                  <span>
                    <FontAwesomeIcon
                      icon={faCheck}
                      color={theme.colors.foreground.good}
                    />
                    Ja
                  </span>
                ) : (
                  <span>
                    <FontAwesomeIcon
                      icon={faWarning}
                      color={theme.colors.foreground.error}
                    />
                    Nej
                  </span>
                ),
              },
            ]}
          />
          {getWarnings()}
        </>
      )}
    </Modal>
  );
};

export default AssignUserToReturnAssignmentModal;
