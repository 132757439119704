/**
 * Convert m/s to km/h
 */
export const mpsToKmph = (mps: number): number => mps * 3.6;

/**
 * Convert km/h to m/s
 * @param kmph
 * @returns m/s
 */
export const kmphToMps = (kmph: number): number => kmph / 3.6;

/**
 * Alert if speedLimit is exceeded based on speedLimitAlert (km/h), speedLimit (km/h) and speed (km/h)
 */
export const alertSpeedLimit = (
  speedLimitAlerts: { [key: number]: number },
  speed: number,
  speedLimit: number
): boolean => {
  if (speedLimitAlerts[speedLimit] && speed > speedLimitAlerts[speedLimit]) {
    return true;
  }
  return false;
};

/**
 * Calculate speed in m/s between two points based on timestamps and distance in meters
 */
export const calculateSpeed = (
  distance?: number,
  timestamp1?: number, // in milliseconds
  timestamp2?: number // in milliseconds
): number | undefined => {
  if (!distance || !timestamp1 || !timestamp2) {
    return undefined;
  }
  const timeDiff = timestamp2 - timestamp1;
  const speed = (1000 * distance) / timeDiff;
  return speed;
};

const deg2rad = (deg: number) => {
  return deg * (Math.PI / 180);
};

interface LatLng {
  latitude: number;
  longitude: number;
}
export const getDistanceInKm = (coord1?: LatLng, coord2?: LatLng) => {
  if (!coord1 || !coord2) {
    return -1;
  }
  const R = 6371; // Radius of the earth in km
  const dLat = deg2rad(coord2.latitude - coord1.latitude); // deg2rad below
  const dLon = deg2rad(coord2.longitude - coord1.longitude);
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(deg2rad(coord1.latitude)) *
      Math.cos(deg2rad(coord2.latitude)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const d = R * c; // Distance in km
  return d;
};

export default {
  alertSpeedLimit,
  calculateSpeed,
  mpsToKmph,
  kmphToMps,
  getDistanceInKm,
  getDistanceInM: (coord1?: LatLng, coord2?: LatLng) => {
    return getDistanceInKm(coord1, coord2) * 1000;
  },
};
