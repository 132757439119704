import FrontendBuildCommit from 'constants/FrontendBuildCommit';
import { FC, forwardRef, useEffect, useState } from 'react';
import { atom, useRecoilState } from 'recoil';
import styled from 'styled-components';
// import Checkbox from './inputs/Checkbox';
import KeyValueList from './KeyValueList';
import Modal, { modalContentClass } from './Modal';
// import { useAuthenticationTestAuthentication } from '../api/authentication/authentication';
import useUsers from 'contexts/basicData/useUsers';
import { UserModel } from 'api';
import Select from './inputs/Select';
import Button from './inputs/Button';
import {
  useDrivingPolicyCheckIfOkToAddAssignment,
  useDrivingPolicyCheckIfOkToAddEstimatedStartTime,
  useDrivingPolicyGetAssignedTimesForUserInDevMenu,
} from 'api/driving-policy/driving-policy';
import DatePicker, { DatePickerButton } from './inputs/DatePicker';
import { formatDate, formatDateTime } from 'utils/date-helpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import Input from './inputs/Input';
import useTranslations from 'contexts/basicData/useTranslations';
import useAuthenticationState from 'contexts/authentication/useAuthentication';
import Table from './Table';
import { AssignedTimeDevMenuDto, GlobalSettingEnum } from 'api/model';
import DurationProgressBar from './DurationProgressBar';
import AssignedTimesTimeline from './AssignedTimesTimeline';
import RadioButton from './inputs/RadioButton';
import { useGlobalSettingsGetSettings } from 'api/global-settings/global-settings';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    display: flex;
    flex-direction: column;
    gap: 10px;
    z-index: 10;
    min-width: 800px;
  }
`;

const Cell = styled.p<{ isCached: boolean }>`
  flex: 1;
  color: ${({ isCached }) => (isCached ? 'black' : 'red')};
`;

const devStateAtom = atom({
  key: 'DevMenuState',
  default: {
    showMenu: false,
    enableCasePendingStatus: false,
  },
});

export const useDevMenuState = () => useRecoilState(devStateAtom);

const CustomInput = forwardRef<HTMLButtonElement, { date?: Date }>(
  ({ date, ...props }, ref) => (
    <DatePickerButton ref={ref} type="button" {...props}>
      <div>{date ? formatDate(date) : 'Välj datum...'}</div>
      <span>
        <FontAwesomeIcon icon={faChevronDown} />
      </span>
    </DatePickerButton>
  )
);

const DevMenu: FC = () => {
  const [userOptions, setUserOptions] = useState<UserModel[]>([]);
  const [userId, setUserId] = useState<number>();
  const [showAsTimeline, setShowAsTimeline] = useState<boolean>(false);
  const [assignmentId, setAssignmentId] = useState<number>();
  const [estimatedStartTime, setEstimatedStartTime] = useState<Date>();
  const [estimatedDuration, setEstimatedDuration] = useState<number>(0);

  const [devState, setDevState] = useRecoilState(devStateAtom);
  const translations = useTranslations();
  const {
    state: { isAuthenticated },
  } = useAuthenticationState();

  // const { data: testData, mutate } = useAuthenticationTestAuthentication();
  const globalSettingsResponse = useGlobalSettingsGetSettings({
    query: {
      refetchInterval: 15000,
    },
  });

  const {
    data: assignedTimes,
    refetch: refetchAssignedTimes,
    isLoading,
  } = useDrivingPolicyGetAssignedTimesForUserInDevMenu(
    { userId },
    { query: { enabled: isAuthenticated } }
  );

  const { data: assignmentIdReport, refetch: refetchAddAssignment } =
    useDrivingPolicyCheckIfOkToAddAssignment(
      { userId, assignmentId },
      {
        query: {
          enabled: false,
        },
      }
    );

  const { data: estimatedStartTimeReport, refetch: refetchEstimatedStartTime } =
    useDrivingPolicyCheckIfOkToAddEstimatedStartTime(
      {
        userId,
        estimatedStartTime,
        estimatedDuration,
      },
      { query: { enabled: false } }
    );

  const users = useUsers();

  useEffect(() => {
    if (users) {
      setUserOptions(
        Object.keys(users).reduce((acc, key) => {
          if (users[key].email.startsWith('slutat')) {
            return acc;
          }

          return [...acc, users[key]];
        }, [] as UserModel[])
      );
    }
  }, [users]);

  useEffect(() => {
    if (isAuthenticated) {
      refetchAssignedTimes();
    }
  }, [isAuthenticated, refetchAssignedTimes, userId]);

  const getItemDurationMillis = (item: AssignedTimeDevMenuDto) => {
    if (item.start && item.end) {
      return new Date(item.end).getTime() - new Date(item.start).getTime();
    }
    return 0;
  };

  if (!devState.showMenu) return null;

  return (
    <MyModal
      closeOnOutsideClick={false}
      onClose={() => setDevState((s) => ({ ...s, showMenu: false }))}
      title="DEV menu"
    >
      <div>
        <b>OBS!</b> stäng denna ruta om du råkat komma åt den av misstag
      </div>
      <br />

      <KeyValueList
        rows={[
          {
            key: 'Build commit',
            value: FrontendBuildCommit,
          },
        ]}
      />
      <hr />
      <Select onChange={(e) => setUserId(Number(e.currentTarget.value))}>
        <option value={-1}>Välj användare</option>
        {userOptions
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((user) => (
            <option value={user.userID} key={user.userID}>
              {user.name}
            </option>
          ))}
      </Select>

      <div style={{ marginBottom: '20px' }}>
        <Button loading={isLoading} onClick={() => refetchAssignedTimes()}>
          Ladda om
        </Button>
        {assignedTimes && (
          <>
            <RadioButton
              checked={!showAsTimeline}
              onChange={(e) => setShowAsTimeline(!e.currentTarget.checked)}
            >
              Visa som tabell
            </RadioButton>
            <RadioButton
              checked={showAsTimeline}
              onChange={(e) => setShowAsTimeline(e.currentTarget.checked)}
            >
              Visa som tidslinje
            </RadioButton>
            {showAsTimeline ? (
              <AssignedTimesTimeline assignedTimes={assignedTimes} isDevMenu />
            ) : (
              <Table
                useColumnWidthAsFlexAmount
                rows={assignedTimes}
                columnSettings={[
                  {
                    head: 'Case',
                    render: (item) => (
                      <Cell isCached={item.isCached}>{item.caseId}</Cell>
                    ),
                    width: 100,
                  },
                  {
                    head: 'Assignment',
                    render: (item) => (
                      <Cell isCached={item.isCached}>{item.assignmentId}</Cell>
                    ),
                    width: 100,
                  },
                  {
                    head: 'Status',
                    render: (item) => (
                      <Cell isCached={item.isCached}>
                        {translations.assignmentStatuses[item.status]}
                      </Cell>
                    ),
                    width: 100,
                  },
                  {
                    head: 'Cached',
                    render: (item) => (
                      <Cell isCached={item.isCached}>
                        {item.isCached ? 'Yes' : 'No'}
                      </Cell>
                    ),
                    width: 100,
                  },
                  {
                    head: 'Start',
                    render: (item) => (
                      <Cell isCached={item.isCached}>
                        {item.start && formatDateTime(new Date(item.start))}
                      </Cell>
                    ),
                    width: 100,
                  },
                  {
                    head: 'End',
                    render: (item) => (
                      <Cell isCached={item.isCached}>
                        {item.end && formatDateTime(new Date(item.end))}
                      </Cell>
                    ),
                    width: 100,
                  },
                  {
                    head: 'Duration',
                    render: (item) => (
                      <Cell isCached={item.isCached}>
                        <DurationProgressBar
                          value={getItemDurationMillis(item)}
                          maxValue={
                            // 13 hours
                            13 * 60 * 60 * 1000
                          }
                        />
                      </Cell>
                    ),
                    width: 100,
                  },
                ]}
              />
            )}
          </>
        )}
      </div>
      <hr />
      <div>
        {userId && (
          <div style={{ display: 'flex', gap: '5px', alignItems: 'baseline' }}>
            <span>AssignmentId</span>
            <Input
              onChange={(e) =>
                setAssignmentId(Number(e.currentTarget.value) ?? undefined)
              }
              type="number"
            />
            <Button
              onClick={() => {
                if (assignmentId) refetchAddAssignment();
              }}
            >
              Get
            </Button>
          </div>
        )}
        {assignmentIdReport && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div>
              <b>Assignment report</b>
              <br />
              <span>
                (Observera att hämtning av en enskild rapport kan ha andra
                beräknade timmar än de som görs i tidslinjen som tar hänsyn till
                ett visst tidsspann)
              </span>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Has 24H-rest:{' '}
              {assignmentIdReport.hasConsecutiveHoursOfRestLast24Hours?.toString()}
            </div>
            <div>
              Has rest this week:{' '}
              {assignmentIdReport.hasConsecutiveHoursOfRestThisWeek?.toString()}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Is within allowed drivingHours for average week:{' '}
              {assignmentIdReport.isWithinAllowedDrivingHoursForAverageWeek?.toString()}
            </div>
            <div>
              Hours driven last 7D: {assignmentIdReport.hoursDrivenLast7Days}
              {' / '}
              {
                globalSettingsResponse.data?.[
                  GlobalSettingEnum.ConsecutiveHoursOfRestLast7Days
                ]
              }
            </div>
            <div>
              Hours driven this week: {assignmentIdReport.hoursDrivenThisWeek}
              {' / '}
              {
                globalSettingsResponse.data?.[
                  GlobalSettingEnum.ConsecutiveHoursOfRestLast7Days
                ]
              }
            </div>
            <div>
              Hours driven last 28D: {assignmentIdReport.hoursDrivenLast28Days}
              {' / '}
              {Number(
                globalSettingsResponse.data?.[
                  GlobalSettingEnum.MaxDrivingHoursLast7DaysAveragePer4Weeks
                ]
              ) * 4}
            </div>

            <div>
              Hours driven this month: {assignmentIdReport.hoursDrivenThisMonth}
            </div>
          </div>
        )}
      </div>
      <hr />
      <div>
        {userId && (
          <div style={{ display: 'flex', gap: '5px', alignItems: 'baseline' }}>
            <span>EstimatedStartTime</span>
            <DatePicker
              calendarStartDay={1}
              customInput={<CustomInput date={estimatedStartTime} />}
              dateFormat="yyyy-MM-dd"
              locale="sv"
              onChange={(d) => setEstimatedStartTime(d ?? undefined)}
              populateHolidays
              selected={estimatedStartTime}
              showTimeSelect
              timeFormat="HH:mm"
              timeIntervals={1}
            />
            <div>Duration (millisec)</div>
            <Input
              onChange={(e) =>
                setEstimatedDuration(Number(e.currentTarget.value))
              }
              type="number"
            />
            <Button onClick={() => refetchEstimatedStartTime()}>Get</Button>
          </div>
        )}
        {estimatedStartTimeReport && (
          <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
            <div>
              <b>Estimations</b>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              24H rest:{' '}
              {estimatedStartTimeReport.hasConsecutiveHoursOfRestLast24Hours?.toString()}
            </div>
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              Within DrivingHours:{' '}
              {estimatedStartTimeReport.isWithinAllowedDrivingHoursForAverageWeek?.toString()}
            </div>
            <div>
              Driving hours 7D:
              {estimatedStartTimeReport.hoursDrivenLast7Days}
            </div>
            <div>
              Driving hours 28D:
              {estimatedStartTimeReport.hoursDrivenLast28Days}
            </div>
            <div>
              Driving hours this month:
              {estimatedStartTimeReport.hoursDrivenThisMonth}
            </div>
          </div>
        )}
      </div>
    </MyModal>
  );
};

export default DevMenu;
