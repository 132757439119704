import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useReducer,
} from 'react';

import {
  AdvancedAssignmentFilterOption,
  AssignmentDeviationEnum,
  AssignmentStatusEnum,
} from 'api';

import assignmentsFiltersReducer from './assignmentsFiltersReducer';
import {
  AssignmentsFiltersState,
  assignmentsFiltersStateActions,
  getInitialAssignmentsFiltersState,
} from './types';
import { SortState } from 'components/Table';

interface AssignmentsFiltersProps {
  state: AssignmentsFiltersState;
  setVisibleStatuses: (value: Set<AssignmentStatusEnum> | null) => void;
  setVisibleDeviations: (value: Set<AssignmentDeviationEnum> | null) => void;
  setVisibleBusinessUnits: (value: number[]) => void;
  setVisibleBusinessUnitGroups: (value: number[]) => void;
  setAdvancedFilters: (
    filters: Set<AdvancedAssignmentFilterOption> | null
  ) => void;
  setDate: (date: Date) => void;
  setSearchText: (text: string) => void;
  setSortState(sortState: SortState): void;

  /** Quick and dirty hack to trigger a refetch within useFilteredAssignments().
   * Pass down a unique value to trigger the refetch (new Date() for example) */
  triggerAssignmentsUpdate(uniqueValue: any): void;
}

const AssignmentsFiltersContext = createContext<AssignmentsFiltersProps>({
  state: getInitialAssignmentsFiltersState(),
  setVisibleStatuses: () => Function,
  setVisibleDeviations: () => Function,
  setVisibleBusinessUnits: () => Function,
  setVisibleBusinessUnitGroups: () => Function,
  setAdvancedFilters: () => Function,
  setDate: () => Function,
  setSearchText: () => Function,
  setSortState: () => Function,

  triggerAssignmentsUpdate: () => {},
});

export const AssignmentsFiltersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [state, dispatch] = useReducer(
    assignmentsFiltersReducer,
    getInitialAssignmentsFiltersState()
  );

  const setVisibleStatuses = useCallback(
    (statuses: Set<AssignmentStatusEnum> | null) => {
      dispatch(
        assignmentsFiltersStateActions.setVisibleAssignmentStatuses(statuses)
      );
    },
    []
  );

  const setVisibleDeviations = useCallback(
    (deviations: Set<AssignmentDeviationEnum> | null) => {
      dispatch(
        assignmentsFiltersStateActions.setVisibleAssignmentDeviations(
          deviations
        )
      );
    },
    []
  );

  const setAdvancedFilters = useCallback(
    (advancedFilters: Set<AdvancedAssignmentFilterOption> | null) => {
      dispatch(
        assignmentsFiltersStateActions.setAdvancedFilters(advancedFilters)
      );
    },
    []
  );

  const setVisibleBusinessUnits = useCallback((businessUnits: number[]) => {
    dispatch(
      assignmentsFiltersStateActions.setVisibleBusinessUnits(businessUnits)
    );
  }, []);

  const setVisibleBusinessUnitGroups = useCallback(
    (businessUnitGroups: number[]) => {
      dispatch(
        assignmentsFiltersStateActions.setVisibleBusinessUnitGroups(
          businessUnitGroups
        )
      );
    },
    []
  );

  const setDate = useCallback((date: Date) => {
    dispatch(assignmentsFiltersStateActions.setDate(date));
  }, []);

  const setSortState = useCallback((sortState: SortState) => {
    dispatch(assignmentsFiltersStateActions.setSortingState(sortState));
  }, []);

  const setSearchText = useCallback((text: string) => {
    dispatch(assignmentsFiltersStateActions.setSearchText(text));
  }, []);

  const triggerAssignmentsUpdate = useCallback((uniqueValue: any) => {
    dispatch(
      assignmentsFiltersStateActions.triggerAssignmentsUpdate(uniqueValue)
    );
  }, []);

  const providerValue = useMemo(
    (): AssignmentsFiltersProps => ({
      state,
      setVisibleStatuses,
      setVisibleDeviations,
      setAdvancedFilters,
      setVisibleBusinessUnits,
      setVisibleBusinessUnitGroups,
      setDate,
      setSearchText,
      setSortState,

      triggerAssignmentsUpdate,
    }),
    [
      state,
      setVisibleStatuses,
      setVisibleDeviations,
      setAdvancedFilters,
      setVisibleBusinessUnits,
      setVisibleBusinessUnitGroups,
      setDate,
      setSearchText,
      setSortState,
      triggerAssignmentsUpdate,
    ]
  );

  return (
    <AssignmentsFiltersContext.Provider value={providerValue}>
      {children}
    </AssignmentsFiltersContext.Provider>
  );
};

export const useAssignmentsFilters = (): AssignmentsFiltersProps => {
  return useContext(AssignmentsFiltersContext);
};
