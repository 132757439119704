import { useEffect, useRef } from 'react';
import { useAssignmentRequestsGetAssignmentRequests } from 'api/assignment-requests/assignment-requests';
import { useAssignmentRequestsFilters } from 'pages/InternalDeliveryGroup/Requests/components/useAssignmentRequestFilters';
import { useQuery } from '@tanstack/react-query';
import { getAssignmentGetAssignmentRequestsQueryKey } from 'api/assignment/assignment';
import { AssignmentRequestModel } from 'api/model';

const useAssignmentRequestsSearch = () => {
  const refreshTimeout = useRef<NodeJS.Timeout | null>(null);
  const {
    state: {
      startDate,
      visibleBusinessUnits,
      visibleBusinessGroups,
      requireFullAllocation,
      searchText,
    },
    selectedStatusView,
  } = useAssignmentRequestsFilters();

  const getAssignmentRequestsMutation =
    useAssignmentRequestsGetAssignmentRequests();

  // Vi kan använda useQuery för att uppdatera datan för react-query
  const { status, data, refetch } = useQuery<AssignmentRequestModel[]>({
    // useQuery behöver nyckeln från cachen
    queryKey: [`${getAssignmentGetAssignmentRequestsQueryKey()}`], // Tydligen viktigt att wrappa i sträng för att det ska fungera
    // och en queryFn som returnerar anropets data
    queryFn: async () => {
      const response = await getAssignmentRequestsMutation.mutateAsync({
        data: {
          date: startDate,
          filterEnum: selectedStatusView,
          toCompanyIds: [],
          businessUnitGroupIds: visibleBusinessGroups,
          businessUnitIds: visibleBusinessUnits,
          requireFullAllocation,
          searchText,
        },
      });
      return response;
    },
  });

  const refreshData = async () => {
    refetch();
  };

  useEffect(() => {
    if (refreshTimeout.current) {
      clearTimeout(refreshTimeout.current);
    }
    refreshTimeout.current = setTimeout(() => {
      refreshData();
    }, 500);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    startDate,
    selectedStatusView,
    visibleBusinessGroups,
    visibleBusinessUnits,
    requireFullAllocation,
    searchText,
  ]);

  return {
    assignmentRequests: data ?? [],
    refresh: refreshData,
    isLoading: getAssignmentRequestsMutation.isLoading || status === 'loading',
  };
};

export default useAssignmentRequestsSearch;
