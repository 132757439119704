import { TeamModel, TeamsClient } from 'api';
import { atom, selector, useRecoilState, useRecoilValue } from 'recoil';
import { useApiCall } from 'swaggerhooks/lib';

const teamsAtom = atom<{
  teams: TeamModel[];
  teamsById: Map<number, TeamModel>;
}>({
  key: 'teamsLookup',
  default: selector({
    key: 'teamsLookupSelector',
    get: async (): Promise<{
      teams: TeamModel[];
      teamsById: Map<number, TeamModel>;
    }> => {
      const url = `/api/Teams/GetTeams`.replace(/[?&]$/, '');

      const options: RequestInit = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        return { teams: [], teamsById: new Map() };
      }

      const deserialized: TeamModel[] = await response.json();

      return {
        teams: deserialized,
        teamsById: new Map(deserialized.map((e) => [e.id, e])),
      };
    },
  }),
});

export const useRefreshTeamsCall = () => {
  const [, setState] = useRecoilState(teamsAtom);

  const apiCall = useApiCall(TeamsClient, async (c) => {
    const result = await c.getTeams();
    setState({
      teams: result,
      teamsById: new Map(result.map((e) => [e.id, e])),
    });
    return result;
  });

  return apiCall;
};

const useTeams = () => {
  return useRecoilValue(teamsAtom);
};

export default useTeams;
