import React from 'react';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  CartesianGrid,
  DotProps,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTheme } from 'styled-components';
import { gold } from 'constants/Colors';
import { AverageRatingDto } from 'api/model';

const CustomizedDot = (props: DotProps) => {
  const { cx, cy } = props; // Extract payload
  const { payload } = props as any; // Cast to `any` to access payload
  const theme = useTheme();
  const averageRating = payload?.averageRating; // Access the pv value from payload

  if (averageRating === 0 || !averageRating) {
    return null;
  }

  if (cx && cy) {
    return (
      <foreignObject x={cx - 13} y={cy - 12} width={24} height={24}>
        <FontAwesomeIcon
          fontSize={24}
          icon={faStar}
          style={{
            color: averageRating >= 4.5 ? gold : theme.colors.foreground.tint,
          }} // Use pv here
        />
        <span>{averageRating}jonas</span>
      </foreignObject>
    );
  }
  return null;
};

type Props = {
  data: AverageRatingDto[];
};

const UserRatingChart: React.FC<Props> = ({ data }) => {
  const theme = useTheme();

  const repurposedData = data.map((item) => ({
    ...item,
    month: String(item.timestamp).slice(0, 7),
  }));
  return (
    <ResponsiveContainer width="100%" height={250}>
      <LineChart
        data={repurposedData}
        margin={{ top: 25, right: 20, left: 0, bottom: 5 }}
      >
        <XAxis dataKey="month" padding={{ left: 30, right: 30 }}>
          <Label
            value="Snittbetyg över de senaste 6 månaderna"
            offset={200}
            position="top"
          />
        </XAxis>
        <Tooltip />
        <CartesianGrid stroke={theme.colors.background.tertiary} />
        <Line
          type="monotone"
          dataKey="averageRating"
          stroke={theme.colors.foreground.tint}
          yAxisId={1}
          dot={<CustomizedDot />}
          activeDot={false} // Disable the circular dot on hover
        />
        <YAxis yAxisId={1} ticks={[1, 2, 3, 4, 5]} domain={[0, 5]} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default UserRatingChart;
