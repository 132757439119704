/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type WorkGearImageEvaluationResultType =
  (typeof WorkGearImageEvaluationResultType)[keyof typeof WorkGearImageEvaluationResultType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkGearImageEvaluationResultType = {
  Unknown: 0,
  True: 1,
  False: 2,
} as const;
