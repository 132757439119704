const loginTokenStorageKey = 'authentication_token';
const refreshTokenStorageKey = 'authentication_refreshToken';
const userIdStorageKey = 'authentication_userId';

export const getLoginToken = () => localStorage.getItem(loginTokenStorageKey);
export const storeLoginToken = (loginToken: string | null) => {
  if (loginToken === null) {
    localStorage.removeItem(loginTokenStorageKey);
  } else {
    localStorage.setItem(loginTokenStorageKey, loginToken);
  }
};

export const getRefreshToken = () =>
  localStorage.getItem(refreshTokenStorageKey);
export const storeRefreshToken = (refreshToken: string | null) => {
  if (refreshToken === null) {
    localStorage.removeItem(refreshTokenStorageKey);
  } else {
    localStorage.setItem(refreshTokenStorageKey, refreshToken);
  }
};

export const getUserId = () => {
  const stringUserId = localStorage.getItem(userIdStorageKey);
  if (stringUserId) {
    try {
      return JSON.parse(stringUserId);
    } catch {
      localStorage.removeItem(userIdStorageKey);
    }
  }
  return null;
};
export const storeUserId = (userId: number | null) => {
  if (userId === null) {
    localStorage.removeItem(userIdStorageKey);
  } else {
    localStorage.setItem(userIdStorageKey, JSON.stringify(userId));
  }
};
