import InternalDeliveryGroupBudgetSettingsFilterBar from './components/InternalDeliveryGroupBudgetSettingsFilterBar';
import InternalDeliveryGroupBudgetSettingsTable from './components/BudgetSettingsTable/InternalDeliveryGroupBudgetSettingsTable';

const InternalDeliveryGroupSettings = () => {
  return (
    <>
      <InternalDeliveryGroupBudgetSettingsFilterBar />
      <InternalDeliveryGroupBudgetSettingsTable />
    </>
  );
};

export default InternalDeliveryGroupSettings;
