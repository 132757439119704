/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentRequestStatusEnum =
  (typeof AssignmentRequestStatusEnum)[keyof typeof AssignmentRequestStatusEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentRequestStatusEnum = {
  Created: 0,
  Denied: 50,
  Assigned: 100,
  KeysHandedOut: 200,
  Removed: 900,
  Expired: 1000,
} as const;
