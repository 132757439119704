import { CaseExtendedModel, ExpenseClient, ExpenseModel } from 'api';
import { useCallback, useMemo } from 'react';
import { useApiCall } from 'swaggerhooks/lib';

const useExpenseActions = (
  currentCase: CaseExtendedModel | undefined,
  currentCaseWasUpdated: () => Promise<any>
) => {
  const saveExpenseCall = useApiCall(
    ExpenseClient,
    (c, expense: ExpenseModel) => c.saveExpense(expense)
  );
  const deleteExpenseCall = useApiCall(
    ExpenseClient,
    (c, expense: ExpenseModel) => c.deleteExpense(expense)
  );

  const handleSaveExpense = useCallback(
    async (expense: ExpenseModel) => {
      const [, error] = await saveExpenseCall.run(expense);

      if (!error) {
        await currentCaseWasUpdated();
      }
    },
    [currentCaseWasUpdated, saveExpenseCall]
  );
  const handleDeleteExpense = useCallback(
    async (expense: ExpenseModel) => {
      const [, error] = await deleteExpenseCall.run(expense);

      if (!error) {
        await currentCaseWasUpdated();
      }
    },
    [currentCaseWasUpdated, deleteExpenseCall]
  );

  return useMemo(
    () => ({
      onSaveExpense: handleSaveExpense,
      onDeleteExpense: handleDeleteExpense,
      saveExpenseStatus: saveExpenseCall.status,
      deleteExpenseStatus: deleteExpenseCall.status,
    }),
    [
      deleteExpenseCall.status,
      handleDeleteExpense,
      handleSaveExpense,
      saveExpenseCall.status,
    ]
  );
};

export default useExpenseActions;
