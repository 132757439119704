import { AssignmentRequestModel, AssignmentViewModel } from 'api/model';
import { useCallback, useEffect, useMemo } from 'react';
import { useAssignmentRequestsGetApplicableAssignments } from 'api/assignment-requests/assignment-requests';
import useAuthenticationState from 'contexts/authentication/useAuthentication';
import MultiSelectAssignmentPicker from 'components/MultiSelectAssignmentPicker';
import useCompanies from 'contexts/basicData/useCompanies';
import useTranslations from 'contexts/basicData/useTranslations';
import { Option } from 'components/inputs/MultiSelect';
import { formatDateTime } from 'utils/date-helpers';

interface Props {
  request?: AssignmentRequestModel;
  disabled?: boolean;
  defaultSelected?: AssignmentViewModel[];
  selected: AssignmentViewModel[];
  onSelected(selected: AssignmentViewModel[]): void;
}

const AssignmentRequestAssignmentPicker: React.FC<Props> = ({
  disabled,
  onSelected,
  selected,
  request,
  defaultSelected,
}) => {
  const { companiesById } = useCompanies();
  const { caseTypes, tyreTypes } = useTranslations();
  const {
    state: { isAuthenticated },
  } = useAuthenticationState();
  // api
  const suitableAssignmentsCall =
    useAssignmentRequestsGetApplicableAssignments();

  useEffect(() => {
    if (isAuthenticated && request?.requestedForID && request?.toCompanyID) {
      suitableAssignmentsCall.mutate({
        data: request,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    request?.requestedForID,
    request?.fromCompanyID,
    request?.toCompanyID,
    request?.targetStartTime,
    isAuthenticated,
  ]);

  const assignments = useMemo(() => {
    const suitable = (suitableAssignmentsCall.data ??
      []) as AssignmentViewModel[];
    const result = [...(defaultSelected ?? [])];

    suitable.forEach((assignment) => {
      const alreadySelected = result.find(
        (a) => a.assignment.assignmentID === assignment.assignment.assignmentID
      );
      if (!alreadySelected) {
        result.push(assignment);
      }
    });

    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [suitableAssignmentsCall.data]);

  const disabledIds = useMemo(() => {
    if (request?.fromCompanyID !== undefined) {
      const fromCompany = companiesById.get(request.fromCompanyID);
      return assignments
        .filter(
          (a) =>
            a.assignment.fromAddress !== fromCompany?.address &&
            a.assignment.fromCity !== fromCompany?.city &&
            a.assignment.fromZip !== fromCompany?.zip
        )
        .map((x) => x.assignment.assignmentID);
    }
    return [];
  }, [assignments, companiesById, request?.fromCompanyID]);

  const renderLabel = useCallback(
    (item: Option<number> | undefined) => {
      const model = assignments.find(
        (a) => a.assignment.assignmentID === item?.value
      );
      if (!model) {
        return <span>-</span>;
      }
      return (
        <span>
          {`${model.case.registrationNumber} (${formatDateTime(model.assignment.bookedTo)}, ${model.assignment.fromCity} – ${model.assignment.toCity}) – ${caseTypes[model.case.caseTypeID]}, ${tyreTypes[model.case.vehicleTyreType as number] ?? 'Okänd däcktyp'}`}
        </span>
      );
    },
    [assignments, caseTypes, tyreTypes]
  );

  if (!request) {
    return <span>Ange uppdragsförfrågan</span>;
  }

  return (
    <MultiSelectAssignmentPicker
      assignments={assignments}
      disabled={disabled}
      selected={selected}
      onSelected={onSelected}
      maxSelected={request.numberOfCars}
      disabledIds={disabledIds}
      renderLabel={renderLabel}
    />
  );
};

export default AssignmentRequestAssignmentPicker;
