import { FC, useEffect, useState } from 'react';
import TooltipInfo from './TooltipInfo';
import { UserModel } from 'api';
import { AssigneeWarning } from 'api/model';
import useTranslations from 'contexts/basicData/useTranslations';
import styled from 'styled-components';
import { useUserGetDrivingPolicyReportForUnPlannedAssignment } from 'api/user/user';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';

export const DrivingPolicyWarnings: AssigneeWarning[] = [
  AssigneeWarning.NotConsecutiveHoursOfRestThisWeek,
  AssigneeWarning.NotConsecutiveHoursOfRestLast24Hours,
  AssigneeWarning.NotConsecutiveHoursOfRestLast7Days,
  AssigneeWarning.NotWithinAllowedDrivingHours,
];

const TooltipWrapper = styled.div`
  margin-left: 5px;
  display: inline-block;
`;

const Info = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
`;

const ErrorSpan = styled.span`
  color: ${({ theme }) => theme.colors.foreground.error};
  font-size: 0.8em;
`;

interface Props {
  warnings?: AssigneeWarning[];
  showWarningsOnlyAsErrorMessage?: boolean;
  isUnavailableViolation?: boolean;
  user?: UserModel;
  fetchDrivingPolicyWarningsParams?: {
    userId: number;
    bookedTo: Date;
    estimatedStartTime?: Date;
    estimatedDuration?: number;
    excludeAssignmentId?: number;
  };
  showOnlyTooltip?: boolean;
  left?: number;
  top?: number;
}

const TooltipInfoWarning: FC<Props> = ({
  warnings,
  showWarningsOnlyAsErrorMessage,
  isUnavailableViolation,
  user,
  fetchDrivingPolicyWarningsParams,
  showOnlyTooltip,
  left,
  top,
}) => {
  const [tooltipIsShowing, setTooltipIsShowing] = useState<boolean>(false);
  const [shownWarnings, setShownWarnings] = useState<Set<AssigneeWarning>>(
    new Set()
  );
  const { assigneeWarnings } = useTranslations();

  const isWarningForDrivingPolicy = DrivingPolicyWarnings.some((w) =>
    shownWarnings.has(w)
  );

  const fetchWarnings = useUserGetDrivingPolicyReportForUnPlannedAssignment(
    {
      ...fetchDrivingPolicyWarningsParams,
    },
    {
      query: {
        enabled: !!fetchDrivingPolicyWarningsParams && tooltipIsShowing,
      },
    }
  );

  useEffect(() => {
    setShownWarnings(
      () =>
        new Set([...(warnings || []), ...(fetchWarnings.data?.warnings || [])])
    );
  }, [fetchWarnings.data, warnings]);

  const renderListOfWarnings = (
    <ul style={{ width: 300 }}>
      {Array.from(shownWarnings).map((w) => (
        <li key={w} style={{ padding: 2 }}>
          {assigneeWarnings[w]}
        </li>
      ))}
    </ul>
  );
  const renderTooltip = !showWarningsOnlyAsErrorMessage || showOnlyTooltip;
  const renderErrors = !showOnlyTooltip || showWarningsOnlyAsErrorMessage;

  return (
    <>
      {renderTooltip && (
        <TooltipWrapper>
          <TooltipInfo
            hoverable
            onChangeShowing={setTooltipIsShowing}
            info={
              <>
                {isUnavailableViolation && (
                  <Info>{`${user?.name ?? 'Fälttestaren'} är inte tillgänglig på vald dag`}</Info>
                )}
                {!!fetchDrivingPolicyWarningsParams &&
                  fetchWarnings.isFetching && (
                    <FontAwesomeIcon icon={faSpinner} spin />
                  )}
                {isWarningForDrivingPolicy && (
                  <span>Bryter mot körtidspolicyn!</span>
                )}
                {shownWarnings && renderListOfWarnings}
              </>
            }
            error
            left={left}
            top={top}
          />
        </TooltipWrapper>
      )}
      {renderErrors && shownWarnings && (
        <ErrorSpan>
          {isWarningForDrivingPolicy && <span>Bryter mot körtidspolicyn!</span>}
          {renderListOfWarnings}
        </ErrorSpan>
      )}
    </>
  );
};

export default TooltipInfoWarning;
