import { FC, Suspense } from 'react';
import { ExpenseModel } from 'api';
import Modal from 'components/Modal';
import useTranslations from 'contexts/basicData/useTranslations';
import useUsers from 'contexts/basicData/useUsers';
import { pdfjs } from 'react-pdf';
import { GenericFileViewer } from 'components/Files/FileViewer';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface ExpenseFileViewerModalProps {
  expense: ExpenseModel;
  maxHeight?: number;
  onClose(): void;
}

const ExpenseFileViewerModal: FC<ExpenseFileViewerModalProps> = ({
  expense,
  onClose,
  maxHeight,
}) => {
  const { paymentTypes, expenseTypes } = useTranslations();
  const users = useUsers();

  return (
    <Suspense fallback="Laddar...">
      <Modal
        buttons={[{ label: 'Stäng', onClick: onClose }]}
        onClose={onClose}
        title={`${expenseTypes[expense.expenseType]} - ${
          paymentTypes[expense.paymentType]
        }, ${users[expense.createdByID]?.name}`}
      >
        <GenericFileViewer
          browsable
          fileEnding={expense.fileEnding}
          fileGuid={expense.fileGuid}
          maxHeight={maxHeight}
        />
      </Modal>
    </Suspense>
  );
};

export default ExpenseFileViewerModal;
