import { AssignmentRequestStatusEnum } from 'api/model';
import moment from 'moment';
import { DateFormat } from 'pages/InternalDeliveryGroup/components/filterBars';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

export interface BudgetFiltersState {
  sortedColumnIndex: number;
  sortAscending: boolean;
  selectedDate: Date;
  startDate: Date;
  endDate: Date;
}

interface BudgetFiltersProps {
  state: BudgetFiltersState;
  setState: React.Dispatch<React.SetStateAction<BudgetFiltersState>>;
  selectedFilterView: DateFormat;
  setSelectedFilterView: React.Dispatch<React.SetStateAction<DateFormat>>;
  selectedStatusView: Set<AssignmentRequestStatusEnum> | null;
  setSelectedStatusView: React.Dispatch<
    React.SetStateAction<Set<AssignmentRequestStatusEnum> | null>
  >;
}

const BudgetFiltersContext = createContext<BudgetFiltersProps>({
  state: {
    sortedColumnIndex: 0,
    sortAscending: true,
    selectedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
  },
  setState: () => Function,
  selectedFilterView: 'week',
  setSelectedFilterView: () => Function,
  selectedStatusView: null,
  setSelectedStatusView: () => Function,
});

export const BudgetFiltersProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedFilterView, setSelectedFilterView] =
    useState<DateFormat>('week');
  const [selectedStatusView, setSelectedStatusView] =
    useState<Set<AssignmentRequestStatusEnum> | null>(null);

  const [state, setState] = useState<BudgetFiltersState>({
    sortedColumnIndex: 0,
    sortAscending: true,
    selectedDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
  });

  const updateDateRange = useCallback(
    (newDate: Date) => {
      if (selectedFilterView === 'date') {
        setState((prev) => ({
          ...prev,
          startDate: moment(newDate).startOf('day').toDate(),
          endDate: newDate,
        }));
      }

      if (selectedFilterView === 'week') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('isoWeek').toDate(),
          endDate: moment(newDate).endOf('isoWeek').toDate(),
        }));
      }

      if (selectedFilterView === 'month') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('month').toDate(),
          endDate: moment(newDate).endOf('month').toDate(),
        }));
      }

      if (selectedFilterView === 'year') {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
          startDate: moment(newDate).startOf('year').toDate(),
          endDate: moment(newDate).endOf('year').toDate(),
        }));
      }
    },
    [selectedFilterView, setState]
  );

  useEffect(() => {
    updateDateRange(state.selectedDate);
  }, [state.selectedDate, updateDateRange]);

  const providerValue = useMemo(
    (): BudgetFiltersProps => ({
      state,
      setState,
      selectedFilterView,
      setSelectedFilterView,
      selectedStatusView,
      setSelectedStatusView,
    }),
    [selectedFilterView, selectedStatusView, state]
  );

  return (
    <BudgetFiltersContext.Provider value={providerValue}>
      {children}
    </BudgetFiltersContext.Provider>
  );
};

export const useBudgetFilters = (): BudgetFiltersProps => {
  return useContext(BudgetFiltersContext);
};
