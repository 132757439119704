import { useEffect, useState } from 'react';

import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css } from 'styled-components';

import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';

const GlobalSearchInputInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const InputBorder = styled.div<{ highlight?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;

  ${({ highlight, theme }) =>
    highlight &&
    css`
      background-color: ${theme.colors.background.highlight};
    `}
`;

const Input = styled.input`
  border: none;
  outline: none;
  font: inherit;
  background: transparent;
  color: inherit;
`;

const GlobalSearchInput: React.FC = () => {
  const [text, setText] = useState<string>('');

  const {
    state: { searchText },
    setSearchText,
  } = useAssignmentSearch();

  useEffect(() => setText(searchText), [searchText]);

  useEffect(() => {
    const doSearch = setTimeout(() => {
      setSearchText(text);
    }, 600);

    return () => clearTimeout(doSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <GlobalSearchInputInputContainer>
      <InputBorder highlight={!!text}>
        <Input onChange={(e) => setText(e.currentTarget.value)} value={text} />
        <FontAwesomeIcon icon={faSearch} onClick={() => setSearchText(text)} />
      </InputBorder>
    </GlobalSearchInputInputContainer>
  );
};

export default GlobalSearchInput;
