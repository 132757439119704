import { useState } from 'react';
import styled from 'styled-components';
import Modal from 'components/Modal';
import { HolidayModel, HolidaysClient, ITranslationLookups } from 'api';
import { useApiCall } from 'swaggerhooks/lib';
import { formatDate } from 'utils/date-helpers';
import CSVReader from 'react-csv-reader';
import { ColumnSetting } from 'components/Table/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Table from 'components/Table';

const IconWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  :hover {
    cursor: pointer;
  }
`;

const FormWrap = styled.div<{ maxWidth?: number; compact?: boolean }>`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ compact }) => (compact ? '0' : '10px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;

type Props = {
  holiday?: HolidayModel;
  onCancel: () => void;
  onSaved: () => void;
};
const BulkAddHolidaysModal: React.FC<Props> = ({ onCancel, onSaved }) => {
  const [rows, setRows] = useState<HolidayModel[]>([]);

  const handleFile = (data: any[]) => {
    // filter out empty rows and rows with invalid date format (correct is YYYY-MM-DD)
    const filteredData = data.filter(
      (d) => d[0] && d[0].match(/^\d{4}-\d{2}-\d{2}$/)
    );
    // convert to HolidayModel[]
    const update: HolidayModel[] = filteredData.map((d) => {
      return {
        date: new Date(d[0]),
        comment: d[1],
        name: d[2],
      } as HolidayModel;
    });
    setRows(update);
  };

  const createCall = useApiCall(HolidaysClient, (c, holidays: HolidayModel[]) =>
    c.bulkAddHolidays(holidays)
  );

  const handleAddClick = async () => {
    const [, error] = await createCall.run(rows);

    if (!error) {
      onSaved();
    }
  };

  const handleDeleteRow = async (index: number) => {
    const update = [...rows];
    update.splice(index, 1);
    setRows(update);
  };

  const columnSettings: ColumnSetting<HolidayModel, ITranslationLookups>[] = [
    {
      head: 'Datum',
      render: (m) => formatDate(m.date),
      width: 150,
      sortFunction: (a, b) =>
        a.date === b.date ? 0 : a.date < b.date ? -1 : 1,
    },
    {
      head: 'Kommentar',
      render: (m) => m.comment,
      sortFunction: (a, b) =>
        a.comment.localeCompare(b.comment, undefined, { sensitivity: 'base' }),
      width: 150,
    },
    {
      head: '',
      render: (m, t, focused, index) => (
        <IconWrapper>
          <FontAwesomeIcon
            icon={faTrash}
            onClick={() => {
              handleDeleteRow(index);
            }}
          />
        </IconWrapper>
      ),
      width: 10,
    },
  ];

  return (
    <Modal
      buttons={[
        {
          label: '+ Ladda upp',
          onClick: handleAddClick,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title="Lägg till helgdagar med CSV"
    >
      <p>
        Ladda upp en CSV-fil med datum, kommentar och namn på helgdagen. Datumet
        ska vara i formatet YYYY-MM-DD.
      </p>
      <FormWrap>
        <CSVReader
          cssClass="csv-input"
          inputId="csv-file-input"
          inputName="csv-file-input"
          onError={(_err) => {
            // console.log('error', err);
          }}
          onFileLoaded={handleFile}
          parserOptions={{}}
        />

        <Table
          columnSettings={columnSettings}
          rows={rows}
          useColumnWidthAsFlexAmount
        />
      </FormWrap>
    </Modal>
  );
};

export default BulkAddHolidaysModal;
