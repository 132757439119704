import { ITranslationLookups, ZipCodeArea } from 'api';
import { atom, selector, useRecoilValue } from 'recoil';

// required to convert the string based keys to number based keys
// const convertFromStringBasedToNumberBased = (
//   data: Record<string, string>
// ): Record<number, string> => {
//   const result: Record<number, string> = {};
//   Object.entries(data).forEach(([key, value]) => {
//     result[parseInt(key, 10)] = value;
//   });
//   return result;
// };

const translationsAtom = atom<ITranslationLookups>({
  key: 'translation',
  default: selector({
    key: 'translationSelector',
    get: async (): Promise<ITranslationLookups> => {
      const url = `/api/Setup/GetTranslationLookups`.replace(/[?&]$/, '');

      const options: RequestInit = {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      };

      const response = await fetch(url, options);

      if (!response.ok) {
        throw new Error('TranslationSelector failed to fetch!');
      }

      const translationLookups = (await response.json()) as ITranslationLookups;

      const zipCodeAreas: { [key: string]: ZipCodeArea } = {};
      Object.entries(translationLookups.zipCodeAreas).forEach(
        ([key, entry]) => {
          zipCodeAreas[key] = new ZipCodeArea(entry);
        }
      );

      return { ...translationLookups, zipCodeAreas };
    },
  }),
});

const useTranslations = () => {
  return useRecoilValue(translationsAtom);
};

export default useTranslations;
