import { getLoginToken, getRefreshToken, getUserId } from './authLocalStorage';

export enum AuthenticationStateActionType {
  LOGIN = 'LOGIN',
  SIGNOUT = 'SIGNOUT',
  ERROR = 'ERROR',
  TOKEN_REFRESHED = 'TOKEN_REFRESHED',
}

export interface AuthenticationState {
  isAuthenticated: boolean;
  token?: string;
  refreshToken?: string;
  userId?: number;
  error?: string;
}

export const getInitialAuthenticationState = (): AuthenticationState => {
  const token = getLoginToken() ?? undefined;
  const refreshToken = getRefreshToken() ?? undefined;
  const userId = getUserId() ?? undefined;

  return {
    isAuthenticated: !!(token && refreshToken),
    token,
    refreshToken,
    userId,
    error: undefined,
  };
};

export type AuthenticationStateAction = ReturnType<
  typeof authenticationStateActions[keyof typeof authenticationStateActions]
>;

export const authenticationStateActions = {
  login: (accessToken: string, refreshToken: string, userId: number) =>
    ({
      type: AuthenticationStateActionType.LOGIN,
      token: accessToken,
      refreshToken,
      userId,
    } as const),
  signout: () =>
    ({
      type: AuthenticationStateActionType.SIGNOUT,
    } as const),
  error: (error: string) =>
    ({
      type: AuthenticationStateActionType.ERROR,
      error,
    } as const),
  tokenRefreshed: (token: string, refreshToken: string, userId: number) =>
    ({
      type: AuthenticationStateActionType.TOKEN_REFRESHED,
      token,
      refreshToken,
      userId,
    } as const),
} as const;
