import { isValidElement, useEffect, useState } from 'react';

import {
  faInfoCircle,
  faWarning,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css, useTheme } from 'styled-components';

const TooltipWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const Tooltip = styled.div<{
  visible: boolean;
  top?: number;
  left?: number;
  maxWidth$?: number;
  minWidth$?: number;
}>`
  display: block;
  position: absolute;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.colors.shadow.dropdown};
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 6px;
  top: ${({ top }) => (top ? `${top}px` : '100%')};
  left: ${({ left }) => (left ? `${left}px` : '0')};
  white-space: wrap;
  ${({ maxWidth$ }) =>
    maxWidth$
      ? css`
          max-width: ${maxWidth$}px;
        `
      : ''};
  ${({ minWidth$ }) =>
    minWidth$
      ? css`
          min-width: ${minWidth$}px;
        `
      : ''};
`;

const NodeWrapper = styled.div`
  cursor: pointer;
`;

interface Props {
  info: React.ReactNode;
  icon?: IconDefinition;
  node?: React.ReactNode;
  hoverable?: boolean;
  left?: number;
  top?: number;
  warning?: boolean;
  redWarning?: boolean;
  error?: boolean;
  maxWidth$?: number;
  minWidth$?: number;
  onChangeShowing?: (showing: boolean) => void;
}

const TooltipInfo: React.FC<Props> = ({
  info,
  icon,
  node,
  hoverable,
  left,
  top,
  warning,
  redWarning,
  error,
  maxWidth$,
  minWidth$,
  onChangeShowing,
}) => {
  const theme = useTheme();

  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  useEffect(() => {
    onChangeShowing?.(showTooltip);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showTooltip]);

  if (node && isValidElement(node)) {
    return (
      <NodeWrapper
        onClick={() => setShowTooltip(true)}
        onMouseLeave={() => setShowTooltip(false)}
        onMouseOver={() => {
          if (hoverable) {
            setShowTooltip(true);
          }
        }}
      >
        {node}
        <Tooltip visible={showTooltip}>{info}</Tooltip>
      </NodeWrapper>
    );
  }

  return (
    <TooltipWrapper data-testid="tooltip-info">
      <FontAwesomeIcon
        color={
          error
            ? theme.colors.foreground.error
            : warning
              ? redWarning
                ? 'red'
                : theme.colors.foreground.warning
              : redWarning
                ? 'red'
                : theme.colors.border.primary
        }
        icon={icon || warning || redWarning || error ? faWarning : faInfoCircle}
        onClick={() => setShowTooltip(true)}
        onMouseEnter={() => {
          if (hoverable) setShowTooltip(true);
        }}
        onMouseLeave={() => setShowTooltip(false)}
        onMouseOver={() => {
          if (hoverable) {
            setShowTooltip(true);
          }
        }}
        style={{ marginRight: '10px', cursor: 'pointer' }}
      />
      {showTooltip && (
        <Tooltip
          left={left}
          minWidth$={minWidth$}
          maxWidth$={maxWidth$}
          top={top}
          visible={showTooltip}
        >
          {info}
        </Tooltip>
      )}
    </TooltipWrapper>
  );
};

export default TooltipInfo;
