import React, { useRef, useState } from 'react';
import ColorPicker from 'react-pick-color';
import styled from 'styled-components';
import Dropdown from './Dropdown';
import Button from './inputs/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTag } from '@fortawesome/free-solid-svg-icons';

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  gap: 10px;
  padding: 5px;
`;

interface Props {
  label?: string;
  color?: string;
  onChange?: (color: string) => void;
}

const ColorSelector: React.FC<Props> = ({ color, label, onChange }) => {
  const initialColor = useRef<string | undefined>(color);
  const [showColorPicker, setShowColorPicker] = useState(false);

  return (
    <Dropdown
      content={
        showColorPicker && (
          <>
            <ColorPicker color={color} onChange={(c) => onChange?.(c.hex)} />
            <ButtonRow>
              <Button
                onClick={() => {
                  onChange?.(initialColor.current || '');
                  setShowColorPicker(false);
                }}
              >
                Avbryt
              </Button>
              <Button onClick={() => setShowColorPicker(false)}>OK</Button>
            </ButtonRow>
          </>
        )
      }
    >
      <div style={{ display: 'flex', gap: '5px' }}>
        {label ?? 'Färg'}
        <FontAwesomeIcon
          icon={faTag}
          stroke="black"
          strokeWidth={15}
          color={color}
          style={{
            transform: 'rotateY(180deg)',
            cursor: 'pointer',
            fontSize: 20,
          }}
          onClick={() => {
            if (onChange) {
              setShowColorPicker(true);
            }
          }}
        />
      </div>
    </Dropdown>
  );
};

export default ColorSelector;
