/* eslint-disable @typescript-eslint/no-unused-vars */
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentViewModel } from 'api/model';
import Dropdown from 'components/Dropdown';
import TextButton from 'components/inputs/TextButton';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import styled from 'styled-components';
import AssignUserToReturnAssignmentModal from './AssignUserToReturnAssignmentModal';

const AssignmentActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 250px;
  min-width: 150px;
  gap: 5px;
  padding: 5px;
  text-align: left;
`;

type Props = {
  currentAssignment: AssignmentViewModel;
  selectedAssignment: AssignmentViewModel;
  onUpdated: () => void;
};

const AssignmentActionDropdown: React.FC<Props> = ({
  currentAssignment,
  selectedAssignment,
  onUpdated,
}) => {
  const modalStack = useModalStack();

  const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

  // console.log('currentAssignment', currentAssignment);

  const handleLostFocus = () => {
    if (dropdownOpen) {
      setDropdownOpen(false);
    }
  };

  const handleAssignUser = () => {
    modalStack.push(
      <AssignUserToReturnAssignmentModal
        currentAssignment={currentAssignment}
        selectedAssignment={selectedAssignment}
        onCancel={() => {
          modalStack.pop();
        }}
        onConfirm={() => {
          modalStack.pop();
          onUpdated();
        }}
      />
    );
  };

  return (
    <Dropdown
      content={
        dropdownOpen && (
          <AssignmentActionsWrapper>
            <TextButton onClick={handleAssignUser}>Tilldela</TextButton>
          </AssignmentActionsWrapper>
        )
      }
      onClick={() => {
        setDropdownOpen(true);
      }}
      onLostFocus={handleLostFocus}
      position="left"
    >
      <TextButton
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
          setDropdownOpen(!dropdownOpen);
        }}
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </TextButton>
    </Dropdown>
  );
};

export default AssignmentActionDropdown;
