import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CaseExtendedModel } from 'api';
import { useCaseCloseCase } from 'api/case/case';
import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import { useCallback, useMemo } from 'react';

const useCaseActions = (
  currentCase: CaseExtendedModel | undefined,
  currentCaseWasUpdated: () => Promise<any>
) => {
  const modalStack = useModalStack();

  const closeCaseCall = useCaseCloseCase();

  const handleCloseCase = useCallback(
    async (caseID: number | undefined) => {
      if (caseID === undefined) return;

      modalStack.push(
        <Modal
          buttons={[
            {
              icon: <FontAwesomeIcon icon={faTrash} />,
              label: 'Stäng ärende',
              onClick: async () => {
                modalStack.pop();
                await closeCaseCall.mutateAsync({
                  params: {
                    caseId: caseID,
                  },
                });
                await currentCaseWasUpdated();
              },
            },
            {
              label: 'Avbryt',
              onClick: () => modalStack.pop(),
            },
          ]}
          onClose={() => modalStack.pop()}
          title="Stäng ärende?"
        >
          Är du säker på att du vill stänga ärendet? Alla uppdrag stängs också.
        </Modal>
      );
    },
    [currentCaseWasUpdated, closeCaseCall, modalStack]
  );

  return useMemo(
    () => ({
      onCloseCase: handleCloseCase,
      closeCaseStatus: closeCaseCall.status,
    }),
    [closeCaseCall.status, handleCloseCase]
  );
};

export default useCaseActions;
