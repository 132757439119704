import { AssignmentRequestReasonEnum, CaseTypeEnum } from 'api/model';

const getAssignmentRequestReason = (
  caseType: CaseTypeEnum
): AssignmentRequestReasonEnum => {
  switch (caseType) {
    case CaseTypeEnum.Purchase:
      return AssignmentRequestReasonEnum.NearbyPurchase;
    case CaseTypeEnum.TradeIn:
      return AssignmentRequestReasonEnum.NearbyTradeIn;
    case CaseTypeEnum.HomeDelivery:
      return AssignmentRequestReasonEnum.HomeDeliveryFromFacility;
    default:
      return AssignmentRequestReasonEnum.Other;
  }
};

export default {
  getAssignmentRequestReason,
};
