import { UserModel } from 'api/model';
import Roles from 'constants/Roles';

const allowSeeingPlannedStopover = (loggedInUser: UserModel | null): boolean =>
  !!loggedInUser?.isBetaTester &&
  (loggedInUser.roles ?? []).includes(Roles.TrafikLedarAdmin);

export default {
  allowSeeingPlannedStopover,
};
