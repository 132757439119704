import React from 'react';
import { useUserGetEmployeeAvailabilityForDate } from 'api/user/user';
import TextButton from 'components/inputs/TextButton';
import useModalStack from 'contexts/modal/useModalStack';
import { Badge } from 'pages/Employees/Availability';
import AvailabilityFormModal from 'pages/Employees/Availability/AvailabilityFormModal';
import { UserModel } from 'api/model';
import { AvailabilityDto as LegacyAvailabilityDto } from 'api';
import { adjustDateToNoon } from 'utils/date-helpers';

type Props = {
  user?: UserModel;
  date: Date;
};

const CaseAssignmentAvailabilityWidget: React.FC<Props> = ({ user, date }) => {
  const modalStack = useModalStack();

  const noonDate = adjustDateToNoon(date)!;

  const availabilityCall = useUserGetEmployeeAvailabilityForDate(
    {
      userId: user?.userID,
      date: noonDate,
    },
    {
      query: {
        enabled: user !== undefined,
      },
    }
  );

  const showModal = () => {
    if (availabilityCall.data && user) {
      modalStack.push(
        <AvailabilityFormModal
          availability={availabilityCall.data as LegacyAvailabilityDto}
          name={user?.name ?? ''}
          onClose={() => modalStack.pop()}
          onSaved={() => modalStack.pop()}
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          userId={user.userID}
          viewOnly
        />
      );
    }
  };

  if (!user) {
    return null;
  }

  return (
    <TextButton onClick={showModal}>
      <Badge>{availabilityCall.data?.bookedAssignments ?? 0}</Badge>
    </TextButton>
  );
};

export default CaseAssignmentAvailabilityWidget;
