import {
  AssignmentClient,
  AssignmentStatusEnum,
  CaseModel,
  TestAssignmentStatusSMSRequest,
  TestClient,
} from 'api';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import TextArea from 'components/inputs/TextArea';
import { FC, useState } from 'react';
import styled from 'styled-components';
import { useApiCall } from 'swaggerhooks/lib';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  textarea {
    width: 500px;
    height: 600px;
  }

  pre {
    max-width: 90vw;
    overflow: auto;
    white-space: pre;
    resize: both;
    border: 1px solid ${({ theme }) => theme.colors.border.primary};
  }
`;

const TestMessageGeneratorPage: FC = () => {
  const [assignmentIdInput, setAssignmentIdInput] = useState('0');
  const [statusTriggerJson, setStatusTriggerJson] = useState('');
  const getAssignmentCall = useApiCall(AssignmentClient, (c, id: number) =>
    c.getAssignmentById(id)
  );
  const testMessageGeneratorCall = useApiCall(
    TestClient,
    (c, statusTrigger: TestAssignmentStatusSMSRequest) =>
      c.testGenerateAssignmentStatusSMS(statusTrigger)
  );

  const prefillWithAssignment = async (assignmentId: number) => {
    const [avm, error] = await getAssignmentCall.run(assignmentId);

    if (!avm || error) return;

    setStatusTriggerJson(
      JSON.stringify(
        new TestAssignmentStatusSMSRequest({
          assignmentModel: avm.assignment,
          caseModel: new CaseModel({ ...avm.case, assignmentList: [] }),
          oldAssignmentStatus: AssignmentStatusEnum.Created,
        }),
        null,
        2
      )
    );
  };

  const handleTestGenerateMessage = async () => {
    testMessageGeneratorCall.run(JSON.parse(statusTriggerJson));
  };

  return (
    <Wrapper>
      <h1>Testa skapa SMS-utskick-meddelande för uppdrag (skickas inte ut)</h1>

      <LabelWrap label="Uppdrags-id">
        <Input
          onChange={(eve) => setAssignmentIdInput(eve.target.value)}
          type="number"
          value={assignmentIdInput}
        />
      </LabelWrap>
      <Button onClick={() => prefillWithAssignment(Number(assignmentIdInput))}>
        Hämta uppdragsdata
      </Button>

      <LabelWrap label="Uppdrags-json">
        <TextArea
          onChange={(eve) => setStatusTriggerJson(eve.target.value)}
          style={{ fontFamily: 'monospace' }}
          value={statusTriggerJson}
        />
      </LabelWrap>

      <Button onClick={handleTestGenerateMessage}>
        Skapa meddelande-text för uppdrag
      </Button>

      {(testMessageGeneratorCall.response ?? []).map((message, i) => (
        <div key={i}>
          <b>Till: {message.toPhoneNr}</b>
          <pre>{message.message}</pre>
          <br />
        </div>
      ))}
    </Wrapper>
  );
};

export default TestMessageGeneratorPage;
