import { faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AssignmentModel, IZipCodeArea } from 'api';
import AddressInput from 'components/AddressInput';
import FormattedAddress from 'components/FormattedAddress';
import KeyValueList from 'components/KeyValueList';
import Modal, { modalContentClass } from 'components/Modal';
import { FC, useState } from 'react';
import styled from 'styled-components';

const MyModal = styled(Modal)`
  .${modalContentClass} {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-bottom: 30px;
  }
`;

const SubHeader = styled.h3`
  margin: 0;
  padding: 0;
  margin-top: 20px;
  font-size: ${({ theme }) => theme.sizes.font.medium};
`;

interface Props {
  originalAssignment: AssignmentModel;
  onAddressPicked(
    name: string,
    address: string,
    zip: string,
    city: string,
    county: IZipCodeArea | null
  ): void;
  onCancel(): void;
}

const StopOverModal: FC<Props> = ({
  originalAssignment,
  onAddressPicked,
  onCancel,
}) => {
  const [name, setName] = useState(originalAssignment.toName);
  const [address, setAddress] = useState(originalAssignment.toAddress);
  const [zip, setZip] = useState(originalAssignment.toZip);
  const [city, setCity] = useState(originalAssignment.toCity);
  const [county, setCounty] = useState<IZipCodeArea | null>(
    originalAssignment.toCounty ?? null
  );

  const handleOk = () => {
    onAddressPicked(name, address, zip, city, county);
  };

  return (
    <MyModal
      buttons={[
        {
          icon: <FontAwesomeIcon icon={faPause} />,
          label: 'Mellanlanda',
          disabled:
            name === originalAssignment.toName &&
            address === originalAssignment.toAddress &&
            zip === originalAssignment.toZip &&
            city === originalAssignment.toCity &&
            county?.id === originalAssignment.toCounty?.id,
          onClick: handleOk,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title="Mellanlanda"
    >
      <p>
        När du mellanlandar det här uppdraget så skapas ett nytt uppdrag för
        vidaretransport till den ursprungliga destinationen.
      </p>

      <SubHeader>Nuvarande uppdrag</SubHeader>
      <KeyValueList
        colonKey
        rows={[
          {
            key: 'Från',
            value: (
              <FormattedAddress
                address={originalAssignment.fromAddress}
                city={originalAssignment.fromCity}
                county={originalAssignment.fromCounty?.areaName}
                name={originalAssignment.fromName}
                zipCode={originalAssignment.fromZip}
              />
            ),
          },
          {
            key: 'Till',
            value: (
              <AddressInput
                address={address}
                city={city}
                countyId={county?.id ?? null}
                name={name}
                onAddressChanged={setAddress}
                onCityChanged={setCity}
                onCompanyPicked={(company, newCounty) => {
                  setAddress(company.address);
                  setName(company.name);
                  setCity(company.city);
                  setCounty(newCounty ?? null);
                  setZip(company.zip);
                }}
                onCountyChanged={setCounty}
                onNameChanged={setName}
                onZipChanged={setZip}
                zip={zip}
              />
            ),
          },
        ]}
      />

      <SubHeader>Nytt uppdrag för vidaretransport</SubHeader>
      <KeyValueList
        colonKey
        rows={[
          {
            key: 'Från',
            value: (
              <FormattedAddress
                address={address}
                city={city}
                county={county?.areaName ?? undefined}
                name={name}
                zipCode={zip}
              />
            ),
          },
          {
            key: 'Till',
            value: (
              <FormattedAddress
                address={originalAssignment.toAddress}
                city={originalAssignment.toCity}
                county={originalAssignment.toCounty?.areaName}
                name={originalAssignment.toName}
                zipCode={originalAssignment.toZip}
              />
            ),
          },
        ]}
      />
    </MyModal>
  );
};

export default StopOverModal;
