import SubMenu, { SubMenuTitle } from 'components/SubMenu';
import Routes from 'constants/Routes';
import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import styled from 'styled-components';

const Wrapper = styled.div``;

const SettingsPage: FC = () => {
  return (
    <Wrapper>
      <SubMenu
        items={[
          {
            label: <SubMenuTitle>Helgdagar</SubMenuTitle>,
            path: Routes.settings.holidays,
          },
          {
            label: <SubMenuTitle>Områden</SubMenuTitle>,
            path: Routes.settings.zipCodeAreas,
          },
          {
            label: <SubMenuTitle>Enheter</SubMenuTitle>,
            path: Routes.settings.businessUnits,
          },
          {
            label: <SubMenuTitle>Standardiserade kommentarer</SubMenuTitle>,
            path: Routes.settings.standardizedComments,
          },
          {
            label: <SubMenuTitle>Produkt-kostnadstyper</SubMenuTitle>,
            path: Routes.settings.productExpenseTypeMappings,
          },
          {
            label: <SubMenuTitle>Avdelningar</SubMenuTitle>,
            path: Routes.settings.companyDepartments,
          },
          {
            label: <SubMenuTitle>Globala inställningar</SubMenuTitle>,
            path: Routes.settings.globalSettings,
          },
        ]}
      />
      <Outlet />
    </Wrapper>
  );
};

export default SettingsPage;
