import {
  faCoins,
  faEdit,
  faLink,
  faReceipt,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpenseModel } from 'api';
import EllipsisContextMenu from 'components/ContextMenu/EllipsisContextMenu';
import { emptyGuid } from 'constants/AppConstants';
import useTranslations from 'contexts/basicData/useTranslations';
import useUsers from 'contexts/basicData/useUsers';
import { FC, Suspense, useState } from 'react';
import styled from 'styled-components';
import DefaultEventHeader from '../EventsAndComments/eventComponents/DefaultEventHeader';
import ExpenseFileViewerModal from './ExpenseFileViewer';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import { useNavigate } from 'react-router-dom';
import Routes from 'constants/Routes';

const parentHoverButton = 'parentHoverButton';
const EventSection = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};

  &:hover .${parentHoverButton}, ${`.${parentHoverButton}`}:focus-within {
    background: ${({ theme }) => theme.colors.background.button};
    color: ${({ theme }) => theme.colors.foreground.button};
  }
`;

const Message = styled.div<{ isSystemMessage?: boolean }>`
  padding: 10px 5px;
  white-space: pre-wrap;
  ${({ isSystemMessage, theme }) =>
    isSystemMessage && `background:${theme.colors.background.secondary}`}
`;

const BottomRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const ExpenseValue = styled.div`
  font-weight: 700;
`;

const BottomRowRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-left: auto;
`;

const LinkButton = styled.button`
  margin: 0;
  padding: 0;

  border: none;
  background: transparent;
  color: ${({ theme }) => theme.colors.foreground.tint};
  font: inherit;
  font-weight: 500;
  text-align: start;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

interface Props {
  expense: ExpenseModel;
  onEditExpense(expenseId: number): void;
  onDeleteExpense(expense: ExpenseModel): void;
}

const Expense: FC<Props> = ({ expense, onEditExpense, onDeleteExpense }) => {
  const navigate = useNavigate();
  const [showExpenseFile, setShowExpenseFile] = useState(false);
  const { paymentTypes, expenseTypes } = useTranslations();
  const users = useUsers();
  const me = useMe();

  return (
    <Suspense fallback="Laddar...">
      <EventSection key={expense.id}>
        <DefaultEventHeader
          created={expense.created}
          denied={expense.deniedBy !== null}
          headerText={
            <>
              <FontAwesomeIcon icon={faCoins} />{' '}
              {expenseTypes[expense.expenseType]} -{' '}
              {paymentTypes[expense.paymentType]}
            </>
          }
          modified={expense.modified}
          userName={users[expense.createdByID]?.name}
          assignmentId={expense.assignmentID}
        />

        <Message>{expense.message}</Message>
        {expense.systemMessage !== null && (
          <Message isSystemMessage>{expense.systemMessage}</Message>
        )}

        <BottomRow>
          <ExpenseValue>{expense.value}kr</ExpenseValue>

          <BottomRowRight>
            {expense.externalReceiptLineItemID !== null && (
              <LinkButton
                onClick={() => {
                  navigate(
                    `${Routes.excelExports.receipts}?lineItemId=${expense.externalReceiptLineItemID}`
                  );
                }}
              >
                Kvitto <FontAwesomeIcon icon={faLink} />
              </LinkButton>
            )}
            {expense.fileGuid !== emptyGuid && (
              <LinkButton onClick={() => setShowExpenseFile(true)}>
                Kvitto <FontAwesomeIcon icon={faReceipt} />
              </LinkButton>
            )}
          </BottomRowRight>

          {me?.roles.includes(Roles.Admin) && (
            <EllipsisContextMenu
              className={parentHoverButton}
              options={[
                {
                  icon: <FontAwesomeIcon icon={faEdit} />,
                  label: 'Redigera',
                  onClick: () => onEditExpense(expense.id),
                },
                {
                  icon: <FontAwesomeIcon icon={faTrash} />,
                  label: 'Ta bort',
                  onClick: () => onDeleteExpense(expense),
                },
              ]}
              position="left"
            />
          )}
        </BottomRow>

        {showExpenseFile && (
          <ExpenseFileViewerModal
            expense={expense}
            onClose={() => setShowExpenseFile(false)}
          />
        )}
      </EventSection>
    </Suspense>
  );
};

export default Expense;
