import { faFolderOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ExpenseModel, ExpenseType, PaymentType } from 'api';
import { FileViewer, GenericFileViewer } from 'components/Files/FileViewer';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import Select from 'components/inputs/Select';
import TextArea from 'components/inputs/TextArea';
import { emptyGuid } from 'constants/AppConstants';
import useTranslations from 'contexts/basicData/useTranslations';
import React, { FC, useCallback } from 'react';
import styled, { css } from 'styled-components';
import { alphabeticCompare } from 'utils/sorting';
import useExpenseEditorForm from './useExpenseEditorForm';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 20px 5px;

  border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary};

  label,
  input,
  select {
    flex: 1;
    width: auto;
  }
`;

const Row = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 10px;
`;

const MyTextArea = styled(TextArea)`
  width: 100%;
  max-width: 100%;
  min-width: 100%;
`;

const ImageSelection = styled.div<{ hasNoImage?: boolean; disabled?: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
  min-height: 40px;
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;

  ${({ hasNoImage }) =>
    hasNoImage &&
    css`
      background-color: ${({ theme }) => theme.colors.background.button};
      color: ${({ theme }) => theme.colors.foreground.button};
    `}
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: ${({ theme }) => theme.colors.background.tertiary};
      color: ${({ theme }) => theme.colors.foreground.button};
    `}

  input {
    width: 0;
    height: 0;
    max-width: 0;
    max-height: 0;
  }

  img {
    width: 100%;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 1000px;
    background-color: ${({ theme }) => theme.colors.background.button};
    color: ${({ theme }) => theme.colors.foreground.button};
    ${({ disabled }) =>
      disabled &&
      css`
        background-color: ${({ theme }) => theme.colors.background.tertiary};
        color: ${({ theme }) => theme.colors.foreground.button};
      `}
  }

  ${({ hasNoImage }) =>
    !hasNoImage &&
    css`
      &:not(:hover) > span {
        display: none;
      }
    `}
`;

const allExpenseTypes: ExpenseType[] = Object.values(ExpenseType).filter(
  (v) => typeof v === 'number'
) as number[];

const allPaymentTypes: PaymentType[] = Object.values(PaymentType).filter(
  (v) => typeof v === 'number'
) as number[];

interface InputsProps {
  expense?: ExpenseModel;
  expenseEditorForm: ReturnType<typeof useExpenseEditorForm>;
}

export const ExpenseEditorInputs: FC<InputsProps> = ({
  expense,
  expenseEditorForm,
}) => {
  const { expenseTypes, paymentTypes } = useTranslations();
  const {
    form: { state, textarea, select, input },
    selectedFile,
    setSelectedFile,
  } = expenseEditorForm;

  const handleFilePicked = useCallback(
    (eve: React.ChangeEvent<HTMLInputElement>) => {
      setSelectedFile(eve.target.files?.item(0) ?? null);
    },
    [setSelectedFile]
  );

  const hasLinkedLineItem =
    typeof expense?.externalReceiptLineItemID === 'number';

  return (
    <>
      <Row>
        <LabelWrap label="Utgiftstyp">
          <Select onChange={select('expenseType')} value={state.expenseType}>
            {allExpenseTypes
              .sort((a, b) =>
                alphabeticCompare(expenseTypes[a], expenseTypes[b])
              )
              .map((expenseType) => (
                <option key={expenseType} value={expenseType}>
                  {expenseTypes[expenseType]}
                </option>
              ))}
          </Select>
        </LabelWrap>

        <LabelWrap label="Betalningstyp">
          <Select
            onChange={select('paymentType')}
            value={state.paymentType}
            disabled={hasLinkedLineItem}
          >
            {allPaymentTypes.map((paymentType) => (
              <option key={paymentType} value={paymentType}>
                {paymentTypes[paymentType]}
              </option>
            ))}
          </Select>
        </LabelWrap>
      </Row>

      <LabelWrap label="Meddelande">
        <MyTextArea onChange={textarea('message')} value={state.message} />
      </LabelWrap>

      <LabelWrap label="Värde (kr)">
        <Input
          onChange={input('value')}
          type="number"
          value={state.value}
          disabled={hasLinkedLineItem}
        />
      </LabelWrap>

      <LabelWrap label="Kvitto">
        <ImageSelection
          disabled={hasLinkedLineItem}
          hasNoImage={
            selectedFile === null ||
            (selectedFile === undefined &&
              (!expense?.fileGuid || expense.fileGuid === emptyGuid))
          }
        >
          <input
            disabled={hasLinkedLineItem}
            accept="image/*,application/pdf"
            onChange={handleFilePicked}
            type="file"
          />
          {selectedFile === undefined
            ? expense &&
              expense.fileGuid &&
              expense.fileGuid !== emptyGuid && (
                <GenericFileViewer
                  fileEnding={expense.fileEnding}
                  fileGuid={expense.fileGuid}
                  maxHeight={100}
                />
              )
            : selectedFile !== null && (
                <FileViewer
                  imageB64Url={URL.createObjectURL(selectedFile)}
                  maxHeight={100}
                  mimeType={selectedFile.type}
                />
              )}
          <span>
            <FontAwesomeIcon icon={faFolderOpen} />
          </span>
        </ImageSelection>
      </LabelWrap>
    </>
  );
};

interface Props {
  /** If left undefined, a new expense will be created */
  expense?: ExpenseModel;
  onSaveExpense(expenseModel: ExpenseModel): void;
  onCancel(): void;
}

const ExpenseEditor: FC<Props> = ({ expense, onSaveExpense, onCancel }) => {
  const form = useExpenseEditorForm(expense, onSaveExpense);

  return (
    <Wrapper>
      <ExpenseEditorInputs expense={expense} expenseEditorForm={form} />

      <Row>
        <Button onClick={() => form.onSaveClick()}>Spara</Button>
        <Button onClick={onCancel}>Avbryt</Button>
      </Row>
    </Wrapper>
  );
};

export default ExpenseEditor;
