/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type WorkType = (typeof WorkType)[keyof typeof WorkType];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const WorkType = {
  Other: 1,
  RentedStaff: 2,
} as const;
