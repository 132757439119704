/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useGoogleMapsContext } from './GoogleMapsContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationPin, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { swedenCenterCoords } from 'constants/AppConstants';
import useModalStack from 'contexts/modal/useModalStack';
import PositionSelectorModal from './PositionSelectorModal';
import Button from 'components/inputs/Button';

const MapContainer = styled.div<{ height: number }>`
  height: ${({ height }) => (height === -1 ? '100%' : `${height}px`)};
  width: 100%;
  user-select: none;
`;

const MapWrapper = styled.div`
  position: relative;
`;

const ClickCover = styled.div`
  cursor: pointer;
  z-index: 1000;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: 'pink';
`;

type Position = { latitude: number; longitude: number };

interface Props {
  companyId: number;
  longitude?: number;
  latitude?: number;
  address?: string;
  onUpdated(): void;
  title?: string;
}

const PositionSelector: React.FC<Props> = ({
  companyId,
  longitude,
  latitude,
  address,
  onUpdated,
  title,
}) => {
  const modalStack = useModalStack();
  const currentPosition = useMemo<Position | undefined>(
    () => (latitude && longitude ? { latitude, longitude } : undefined),
    [latitude, longitude]
  );
  const { scriptLoaded, createMap, fitBounds } = useGoogleMapsContext();

  const ref = useRef<HTMLDivElement>(null);

  const showPicker = () => {
    if (!address) {
      return;
    }
    modalStack.push(
      <PositionSelectorModal
        companyId={companyId}
        address={address}
        initialCenter={currentPosition}
        containerHeight={700}
        onClose={() => modalStack.pop()}
        onUpdated={onUpdated}
        title={title}
      />
    );
  };
  useEffect(() => {
    if (scriptLoaded && ref.current) {
      const map = createMap(
        ref.current,
        8,
        new google.maps.LatLng(
          currentPosition?.latitude ?? swedenCenterCoords.latitude,
          currentPosition?.longitude ?? swedenCenterCoords.longitude
        ),
        false
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptLoaded, createMap, currentPosition]);

  useEffect(() => {
    if (currentPosition) {
      fitBounds();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPosition]);

  if (!scriptLoaded) {
    return <FontAwesomeIcon icon={faSpinner} spin />;
  }
  return (
    <MapWrapper>
      <ClickCover
        onClick={(eve) => {
          eve.stopPropagation();
          eve.preventDefault();
          showPicker();
        }}
      />
      {latitude && longitude ? (
        <MapContainer height={50} ref={ref} />
      ) : (
        <Button onClick={showPicker}>Välj position</Button>
      )}
    </MapWrapper>
  );
};

export default PositionSelector;
