import { useEffect } from 'react';
import styled from 'styled-components';
import LabelWrap from 'components/inputs/LabelWrap';
import Modal from 'components/Modal';
import { useForm } from 'react-hook-form';
import { HolidayModel, HolidaysClient } from 'api';
import { useApiCall } from 'swaggerhooks/lib';
import TextArea from 'components/inputs/TextArea';
import Input from 'components/inputs/Input';
import { formatDate } from 'utils/date-helpers';

const FormWrap = styled.div<{ maxWidth?: number; compact?: boolean }>`
  padding-right: 10px;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ compact }) => (compact ? '0' : '10px')};
  ${({ maxWidth }) => (maxWidth ? `max-width: ${maxWidth}px;` : '')}
`;

const DatePickerWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
`;

const StyledTextArea = styled(TextArea)`
  height: 100px;
`;

const MyLabelWrap = styled(LabelWrap)`
  flex: 1;
`;

type Props = {
  holiday?: HolidayModel;
  onCancel: () => void;
  onSaved: () => void;
};
const AddHolidaysModal: React.FC<Props> = ({ onCancel, onSaved, holiday }) => {
  const { register, handleSubmit, setValue, watch } = useForm<HolidayModel>();

  useEffect(() => {
    if (holiday) {
      setValue('date', holiday.date);
      setValue('name', holiday.name);
      setValue('comment', holiday.comment);
    } else {
      setValue('date', new Date());
      setValue('name', '');
      setValue('comment', '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [holiday]);

  const values = watch();

  const createCall = useApiCall(HolidaysClient, (c, data) =>
    c.createHoliday(data)
  );

  const updateCall = useApiCall(HolidaysClient, (c, data) =>
    c.updateHoliday(data)
  );

  const handleCreateClick = handleSubmit(async (data) => {
    const [, error] = await createCall.run(data);

    if (!error) {
      onSaved();
    }
  });

  const handleUpdateClick = handleSubmit(async (data) => {
    if (!data) return;

    const [, error] = await updateCall.run(data);

    if (!error) {
      onSaved();
    }
  });

  return (
    <Modal
      buttons={[
        {
          label: holiday ? 'Spara' : 'Lägg till',
          onClick: holiday ? handleUpdateClick : handleCreateClick,
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      onClose={onCancel}
      title={holiday ? 'Redigera helgdag' : 'Lägg till helgdag'}
    >
      <FormWrap>
        <DatePickerWrap>
          <MyLabelWrap label="Datum">
            <Input
              {...(register('date'),
              {
                onChange: (e) => {
                  setValue('date', new Date(e.target.value));
                },
              })}
              type="date"
              value={values.date ? formatDate(values.date) : ''}
              width={270}
            />
          </MyLabelWrap>
        </DatePickerWrap>
        <LabelWrap label="Kommentar">
          <StyledTextArea {...register('comment')} width={270} />
        </LabelWrap>
      </FormWrap>
    </Modal>
  );
};

export default AddHolidaysModal;
