import styled, { css } from 'styled-components';

export const getTableRowClassName = (tableName: string, id: number): string => {
  return `${tableName}-${id}`;
};

export const SavingRowAnimationWrapper = styled.div<{
  tableName: string;
  savingIds: number[];
}>`
  display: contents;

  ${({ tableName, savingIds }) =>
    savingIds.map(
      (id) => css`
        .${getTableRowClassName(tableName, id)} {
          animation: assignmentlist-save-animation 1s infinite;
        }
      `
    )}

  @keyframes assignmentlist-save-animation {
    0% {
      background: #ddeefd;
    }
    50% {
      background: ${({ theme }) => theme.colors.background.primary};
    }
    100% {
      background: #ddeefd;
    }
  }
`;

export const Centered = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
