import {
  AssignmentClient,
  AssignmentStatusEnum,
  CaseExtendedModel,
  ExtendedPosition,
} from 'api';
import Button from 'components/inputs/Button';
import Select from 'components/inputs/Select';
import useTranslations from 'contexts/basicData/useTranslations';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import TransparentSpinner from 'components/spinners/TransparentSpinner';

const hasSpeedLimitData = (p: ExtendedPosition) => {
  return p.speedLimitTimestamp !== undefined;
};

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 6px;
`;

const FiltrationWindow = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
  gap: 6px;
  margin-right: 6px;
`;

const SubTitle = styled.h2`
  font-size: 18px;
`;

const AddressSection = styled.section`
  padding-bottom: 20px;
`;

const Spacer = styled.div`
  flex: 1;
`;

const MyButton = styled(Button)`
  width: 225px;
  padding-top: 10px;
`;

type MapControlProps = {
  caseResponse?: CaseExtendedModel;
  positions: ExtendedPosition[];
  onFilter?: (
    assignmentId?: number,
    fromTimestamp?: Date,
    toTimestamp?: Date,
    isLive?: boolean
  ) => void;
};

const MapControls: FC<MapControlProps> = ({
  caseResponse,
  onFilter,
  positions,
}) => {
  const { caseEventTypes } = useTranslations();
  const [assignmentId, setAssignmentId] = useState<number>(-1);
  const [isSingleAssignment, setIsSingleAssignment] = useState(false);
  const [isLive, setIsLive] = useState<boolean>(true);

  const syncSpeedDataCall = useApiCall(
    AssignmentClient,
    (c, _assignmentId: number) => c.syncSpeedLimitData(_assignmentId)
  );

  /**
   * Returns true if there are at least 10 positions in a row that lack speed limit data
   * @param positions
   * @returns boolean
   */
  const hasManyPositionsWithoutSpeedLimitData = useMemo(() => {
    let count = 0;
    for (let i = 0; i < positions.length; i += 1) {
      if (!hasSpeedLimitData(positions[i])) {
        count += 1;
      } else {
        count = 0;
      }
      if (count >= 10) {
        return true;
      }
    }
    return false;
  }, [positions]);

  // Update options when case response changes
  useEffect(() => {
    const isSingle = caseResponse?.case.assignmentList.length === 1;
    setIsSingleAssignment(isSingle);
    if (isSingle) {
      setAssignmentId(caseResponse.case.assignmentList[0].assignmentID);
    }
  }, [caseResponse, assignmentId, caseEventTypes]);

  const hasFinished = useMemo(() => {
    caseResponse?.case.assignmentList.some((a) => {
      if (assignmentId) {
        return (
          a.assignmentID === assignmentId &&
          a.assignmentStatusID >= AssignmentStatusEnum.Complete
        );
      }
      return a.assignmentStatusID >= AssignmentStatusEnum.Complete;
    });
    return true;
  }, [assignmentId, caseResponse?.case.assignmentList]);

  const handleUpdate = useCallback(() => {
    if (onFilter) {
      onFilter(assignmentId === -1 ? undefined : assignmentId);
    }
  }, [onFilter, assignmentId]);

  useEffect(() => {
    handleUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [assignmentId, isLive, hasFinished]);

  const handleAssignmentIdChange = (value: string) => {
    setAssignmentId(Number(value));
  };

  const handleCheckSpeedLimits = async () => {
    if (assignmentId !== -1) {
      // run sync speed limit data
      await syncSpeedDataCall.run(assignmentId);
      // refresh map
      handleUpdate();
    }
  };

  const toggleLiveFeed = () => {
    setIsLive((p) => !p);
  };

  return (
    <FiltrationWindow>
      {isSingleAssignment && (
        <>
          <SubTitle>Från adress:</SubTitle>
          <AddressSection>
            {caseResponse?.case.assignmentList[0].fromAddress}
            <br />
            {caseResponse?.case.assignmentList[0].fromZip}{' '}
            {caseResponse?.case.assignmentList[0].fromCity}
          </AddressSection>
          <SubTitle>Till adress:</SubTitle>
          <AddressSection>
            {caseResponse?.case.assignmentList[0].toAddress}
            <br />
            {caseResponse?.case.assignmentList[0].toZip}{' '}
            {caseResponse?.case.assignmentList[0].toCity}
          </AddressSection>
        </>
      )}
      {!isSingleAssignment && (
        <>
          <span>Uppdrag</span>
          <Select
            onChange={(e) => handleAssignmentIdChange(e.currentTarget.value)}
            value={assignmentId}
            width={225}
          >
            <option key="-1" value={-1}>
              Alla
            </option>
            {caseResponse?.case.assignmentList.map((a) => (
              <option key={a.assignmentID} value={a.assignmentID}>
                {a.fromAddress} - {a.toAddress} ({a.assignedTo?.name})
              </option>
            ))}
          </Select>
        </>
      )}
      {assignmentId !== -1 &&
        hasManyPositionsWithoutSpeedLimitData &&
        hasFinished && (
          <MyButton
            disabled={syncSpeedDataCall.status === RequestStatus.Fetching}
            onClick={handleCheckSpeedLimits}
          >
            <Horizontal>
              Synka med vägdata
              {syncSpeedDataCall.status === RequestStatus.Fetching && (
                <TransparentSpinner small />
              )}
            </Horizontal>
          </MyButton>
        )}
      <Spacer />
      {!hasFinished && (
        <MyButton onClick={toggleLiveFeed}>
          {isLive ? 'Pausa live-feed' : 'Starta live-feed'}
        </MyButton>
      )}
    </FiltrationWindow>
  );
};

export default MapControls;
