import styled from 'styled-components';
import { DatePicker } from '../../components';
import {
  BarSection,
  BarWrapper,
  CustomInput,
  DateFormat,
} from '../../components/filterBars';
import { useInternalDeliveryGroupBudgetSettingsFilters } from './useInternalDeliveryGroupBudgetSettingsFilters';

const MyLabel = styled.label`
  margin-right: 5px;
`;

const DatePickerContainer = styled.div``;

const InternalDeliveryGroupBudgetSettingsFilterBar = () => {
  const {
    state: { selectedDate: date },
    setState,
    selectedFilterView,
  } = useInternalDeliveryGroupBudgetSettingsFilters();

  const handleChangeDate = (
    newDate: Date | [Date | null, Date | null] | null
  ) => {
    if (newDate) {
      if (!Array.isArray(newDate)) {
        setState((prev) => ({
          ...prev,
          selectedDate: newDate,
        }));
      }
    }
  };

  return (
    <BarWrapper>
      <BarSection>
        <MyLabel>Budget för:</MyLabel>
        <DatePickerContainer>
          <DatePicker
            calendarStartDay={1}
            customInput={
              <CustomInput date={date} dateFormat={'month' as DateFormat} />
            }
            dateFormat="yyyy-MM-dd"
            locale="sv"
            onChange={(d) => handleChangeDate(d)}
            populateHolidays
            selected={date}
            showYearPicker={selectedFilterView === 'year'}
            showMonthYearPicker={selectedFilterView === 'month'}
            showWeekNumbers
            isWeekSelect={selectedFilterView === 'week'}
            shouldCloseOnSelect
          />
        </DatePickerContainer>
      </BarSection>
    </BarWrapper>
  );
};

export default InternalDeliveryGroupBudgetSettingsFilterBar;
