import { faStar as faOutlineStar } from '@fortawesome/free-regular-svg-icons';
import {
  faStar as faSolidStar,
  faStarHalfAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import AutoSizedTextArea from './inputs/AutoSizedTextArea';
import { gold } from 'constants/Colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  textarea {
    margin-top: 1em;
    width: 100%;
    max-height: 250px;
    min-width: 100%;
    max-width: 100%;
    min-height: 3em;
    resize: none;
  }
`;

const Info = styled.div<{ warn?: boolean }>`
  margin-left: auto;
  font-size: 0.8em;
  margin-top: 0.5em;
  color: ${(props) =>
    props.warn ? gold : props.theme.colors.foreground.primary};
`;

const Horizontal = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  cursor: pointer;
`;

const Title = styled.div`
  font-size: 1.2em;
  margin-bottom: 1em;
`;

// const StyledTextArea = styled(AutoSizedTextArea)`
//   margin-top: 1em;
//   flex: 1;
//   display: flex;
//   width: 100%;
// `;

interface Props {
  initialRating?: number | null;
  onRatingChanged?(newRating: number, ratingMessage?: string): void;
  title?: string;
  viewMode?: boolean;
  color?: string;
}

const RatingWidget: React.FC<Props> = ({
  initialRating,
  onRatingChanged,
  title,
  viewMode,
  color,
}) => {
  const [rating, setRating] = useState(initialRating);
  const [ratingReason, setRatingReason] = useState<string | undefined>();
  const [currentHover, setCurrentHover] = useState<number | undefined>();

  useEffect(() => {
    setRating(initialRating);
  }, [initialRating]);

  const handleRating = useCallback(
    (newRating: number) => {
      setRating(newRating);
      onRatingChanged?.(newRating, ratingReason);
    },
    [ratingReason, onRatingChanged]
  );

  const handleRatingReason = (newReason: string) => {
    setRatingReason(newReason);
    onRatingChanged?.(rating ?? 0, newReason);
  };

  const renderSelectStars = useCallback(() => {
    const stars = [];
    for (let i = 1; i <= 5; i += 1) {
      const isFilled =
        (currentHover !== undefined && i <= currentHover) ||
        (currentHover === undefined &&
          rating !== undefined &&
          rating !== null &&
          i <= rating);
      stars.push(
        <FontAwesomeIcon
          size="2x"
          icon={isFilled ? faSolidStar : faOutlineStar}
          onMouseOver={() => setCurrentHover(i)}
          onMouseLeave={() => setCurrentHover(undefined)}
          key={i}
          onClick={() => handleRating(i)}
          color={color ?? isFilled ? '#FFD700' : '#D3D3D3'}
        />
      );
    }
    return stars;
  }, [currentHover, rating, color, handleRating]);

  const renderViewStars = useCallback(() => {
    const stars = [];

    // Convert rating to a number if it's null or undefined, fallback to 0
    const currentRating = rating ?? 0;

    for (let i = 1; i <= 5; i += 1) {
      const isFilled = i <= Math.floor(currentRating);
      const isHalfFilled =
        i === Math.ceil(currentRating) && currentRating % 1 !== 0;

      stars.push(
        <FontAwesomeIcon
          size="2x"
          icon={
            isFilled
              ? faSolidStar
              : isHalfFilled
                ? faStarHalfAlt
                : faOutlineStar
          }
          key={i}
          color={color ?? (isFilled || isHalfFilled ? '#FFD700' : '#D3D3D3')}
          style={{ zIndex: 1 }}
        />
      );
    }

    return stars;
  }, [color, rating]);

  return (
    <Wrapper>
      {title && <Title>{title}</Title>}
      <Horizontal>
        {viewMode ? renderViewStars() : renderSelectStars()}
      </Horizontal>
      {(!viewMode || ratingReason) && (
        <AutoSizedTextArea
          placeholder="Ange anledning"
          value={ratingReason}
          onChange={(e) => handleRatingReason(e.currentTarget.value)}
          disabled={viewMode}
        />
      )}
      {!viewMode && (rating ?? 0) <= 3 && (
        <Info warn={(ratingReason?.length ?? 0) < 10}>
          {ratingReason?.length ?? 0}/10
        </Info>
      )}
    </Wrapper>
  );
};

export default RatingWidget;
