import React, { useEffect } from 'react';

const useKeyPress = (eventFn: () => void, targetKeys: string[]) => {
  const [keyPressed, setKeyPressed] = React.useState(false);

  const downHandler = (event: KeyboardEvent) => {
    const { key } = event;

    if (targetKeys.includes(key)) {
      setKeyPressed(true);
    }
  };

  const upHandler = (event: KeyboardEvent) => {
    const { key } = event;

    if (targetKeys.includes(key)) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Run eventFn when keyPressed changes to true
  useEffect(() => {
    if (keyPressed) {
      eventFn();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyPressed]);
};

export default useKeyPress;
