import { ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
  padding: 10px 10px;
  gap: 30px;
  /* border-bottom: 1px solid ${({ theme }) => theme.colors.border.primary}; */
`;

export const MenuItem = styled.button<{ selected?: boolean }>`
  padding-bottom: 1px;
  border: none;
  border-bottom: 2px solid transparent;
  background-color: transparent;
  color: ${({ theme }) => theme.colors.foreground.primary};
  font: inherit;
  text-transform: capitalize;
  cursor: pointer;

  ${({ selected, theme }) =>
    selected &&
    css`
      padding-bottom: 1px;
      border-bottom: 2px solid ${theme.colors.foreground.tint};
      font-weight: bold;
    `}

  &:hover {
    color: ${({ theme, selected }) =>
      selected
        ? theme.colors.foreground.primary
        : theme.colors.foreground.tint};
  }
`;

export const SubMenuTitle = styled.h1`
  flex: 1;
  font-size: ${({ theme }) => theme.sizes.font.medium};
  font-weight: 500;
`;

interface IMenuItem {
  label: ReactNode;
  path: string;
}

type Props = {
  items: IMenuItem[];
};
const AdSubMenu: React.FC<Props> = ({ items }) => {
  const location = useLocation();
  const navigate = useNavigate();
  return (
    <MenuContainer>
      {items.map((i) => (
        <MenuItem
          key={i.path}
          onClick={() => {
            navigate(i.path);
          }}
          selected={location.pathname === i.path}
        >
          {i.label}
        </MenuItem>
      ))}
    </MenuContainer>
  );
};

export default AdSubMenu;
