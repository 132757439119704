/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type ExternalReceiptProvider =
  (typeof ExternalReceiptProvider)[keyof typeof ExternalReceiptProvider];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ExternalReceiptProvider = {
  None: 0,
  CircleK: 1,
} as const;
