import { AssignmentsList } from '../components';
import useFilteredAssignments from 'pages/Cases/useFilteredAssignments';
import { AssignmentExtendedModel, CaseTypeEnum } from 'api/model';
import { Outlet, useNavigate } from 'react-router-dom';
import Routes from 'constants/Routes';
import { useMemo } from 'react';
import useCompanies from 'contexts/basicData/useCompanies';
import Roles from 'constants/Roles';
import useMe from 'contexts/authentication/useMe';
import BulkMessages from 'pages/GlobalSearch/BulkMessages';
import BulkOperations from 'pages/GlobalSearch/BulkOperations';
import useAssignmentsBulkOperations from 'pages/GlobalSearch/useAssignmentsBulkOperations';
import InternalDeliveryAssignmentsFilterBar from './components/InternalDeliveryAssignmentsFilterBar';
import { TableIdentifierEnum } from 'contexts/basicData/useActiveColumns';

const AdvertisingAssignments = () => {
  const navigate = useNavigate();
  const { filteredAssignments, update } = useFilteredAssignments(
    CaseTypeEnum.InternalDelivery
  );
  const me = useMe();
  const userIsGoSupport = me?.roles.includes(Roles.GoSupport);

  const { companies } = useCompanies();

  const getBasePath = (caseType: CaseTypeEnum) => {
    switch (caseType) {
      case CaseTypeEnum.InternalDelivery:
        return Routes.internalDeliveries.index;
      case CaseTypeEnum.Extra:
        return Routes.extrajobs.index;
      case CaseTypeEnum.FaciliyExchange:
        return Routes.facilityExchange.index;
      default:
        return Routes.internalDeliveryGroup.index;
    }
  };

  const strangnasLogistic = useMemo(() => {
    return companies.find(
      (c) => c.isLogisticsCenter && c.name.includes('Strängnäs')
    );
  }, [companies]);

  const onlyStrangnasAssignments = useMemo(() => {
    return filteredAssignments.filter((a) => {
      const logisticString =
        `${strangnasLogistic?.address}${strangnasLogistic?.city}${strangnasLogistic?.zip}`.trim();
      const assignmentString =
        `${a.assignment.fromAddress}${a.assignment.fromCity}${a.assignment.fromZip}`.trim();
      const isMatch = logisticString === assignmentString;
      return isMatch;
    });
  }, [
    filteredAssignments,
    strangnasLogistic?.address,
    strangnasLogistic?.city,
    strangnasLogistic?.zip,
  ]);

  const assignmentsBulkOperations = useAssignmentsBulkOperations(
    onlyStrangnasAssignments ?? [],
    () => update(false)
  );

  const handleAssignmentItemClick = (model: any) => {
    const avm = model as AssignmentExtendedModel;
    navigate(
      `${getBasePath(avm.case.caseTypeID)}/${avm.case.caseID}?assignment=${
        avm.assignment.assignmentID
      }`
    );
  };

  const bulkMessagesButton = userIsGoSupport ? (
    <BulkMessages bulkOperations={assignmentsBulkOperations} />
  ) : null;

  return (
    <>
      <InternalDeliveryAssignmentsFilterBar
        assignments={onlyStrangnasAssignments}
        showSendToFieldTesters={false}
        onUpdate={() => update(false)}
      />
      {!!onlyStrangnasAssignments.length && userIsGoSupport && (
        <BulkOperations
          bulkActions={[bulkMessagesButton]}
          bulkOperations={assignmentsBulkOperations}
          handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
            handleAssignmentItemClick(model)
          }
        />
      )}
      <AssignmentsList
        loading={false}
        tableIdentifier={
          TableIdentifierEnum.InternalDeliveryGroupAssignmentsTable
        }
        assignments={onlyStrangnasAssignments}
        bulkOperations={assignmentsBulkOperations}
        onAssignmentItemClick={handleAssignmentItemClick}
        onAssignmentUpdated={async () => {
          update(false);
        }}
        hideEstimatedDurationAndStartTime
        showStatusColors={false}
      />
      <Outlet />
    </>
  );
};

export default AdvertisingAssignments;
