/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  InternalDeliveryBudgetCreateYearlyBudgetParams,
  InternalDeliveryBudgetGetWeeklyBudgetsOfMonthParams,
  InternalDeliveryBudgetGetYearlyBudgetsParams,
  InternalDeliveryYearlyBudgetDTO,
  InternalDeliveryYearlyBudgetDTOArrayBody,
} from '.././model';
import useInternalDeliveryBudgetGetYearlyBudgetsMutator from '.././mutator/custom-instance';
import useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthMutator from '.././mutator/custom-instance';
import useInternalDeliveryBudgetCreateYearlyBudgetMutator from '.././mutator/custom-instance';
import useInternalDeliveryBudgetUpdateYearlyBudgetMutator from '.././mutator/custom-instance';

export const useInternalDeliveryBudgetGetYearlyBudgetsHook = () => {
  const internalDeliveryBudgetGetYearlyBudgets =
    useInternalDeliveryBudgetGetYearlyBudgetsMutator<
      InternalDeliveryYearlyBudgetDTO[]
    >();

  return (
    params?: InternalDeliveryBudgetGetYearlyBudgetsParams,
    signal?: AbortSignal
  ) => {
    return internalDeliveryBudgetGetYearlyBudgets({
      url: `/api/InternalDeliveryBudget/GetYearlyBudgets`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getInternalDeliveryBudgetGetYearlyBudgetsQueryKey = (
  params?: InternalDeliveryBudgetGetYearlyBudgetsParams
) => {
  return [
    `/api/InternalDeliveryBudget/GetYearlyBudgets`,
    ...(params ? [params] : []),
  ] as const;
};

export const useInternalDeliveryBudgetGetYearlyBudgetsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useInternalDeliveryBudgetGetYearlyBudgetsHook>>
  >,
  TError = unknown,
>(
  params?: InternalDeliveryBudgetGetYearlyBudgetsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useInternalDeliveryBudgetGetYearlyBudgetsHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInternalDeliveryBudgetGetYearlyBudgetsQueryKey(params);

  const internalDeliveryBudgetGetYearlyBudgets =
    useInternalDeliveryBudgetGetYearlyBudgetsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetGetYearlyBudgetsHook>
      >
    >
  > = ({ signal }) => internalDeliveryBudgetGetYearlyBudgets(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetGetYearlyBudgetsHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InternalDeliveryBudgetGetYearlyBudgetsQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useInternalDeliveryBudgetGetYearlyBudgetsHook>>
  >
>;
export type InternalDeliveryBudgetGetYearlyBudgetsQueryError = unknown;

export const useInternalDeliveryBudgetGetYearlyBudgets = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useInternalDeliveryBudgetGetYearlyBudgetsHook>>
  >,
  TError = unknown,
>(
  params?: InternalDeliveryBudgetGetYearlyBudgetsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useInternalDeliveryBudgetGetYearlyBudgetsHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useInternalDeliveryBudgetGetYearlyBudgetsQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook = () => {
  const internalDeliveryBudgetGetWeeklyBudgetsOfMonth =
    useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthMutator<
      InternalDeliveryYearlyBudgetDTO[]
    >();

  return (
    params?: InternalDeliveryBudgetGetWeeklyBudgetsOfMonthParams,
    signal?: AbortSignal
  ) => {
    return internalDeliveryBudgetGetWeeklyBudgetsOfMonth({
      url: `/api/InternalDeliveryBudget/GetWeeklyBudgetsOfMonth`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getInternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryKey = (
  params?: InternalDeliveryBudgetGetWeeklyBudgetsOfMonthParams
) => {
  return [
    `/api/InternalDeliveryBudget/GetWeeklyBudgetsOfMonth`,
    ...(params ? [params] : []),
  ] as const;
};

export const useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook>
    >
  >,
  TError = unknown,
>(
  params?: InternalDeliveryBudgetGetWeeklyBudgetsOfMonthParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getInternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryKey(params);

  const internalDeliveryBudgetGetWeeklyBudgetsOfMonth =
    useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook>
      >
    >
  > = ({ signal }) =>
    internalDeliveryBudgetGetWeeklyBudgetsOfMonth(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type InternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook>
      >
    >
  >;
export type InternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryError = unknown;

export const useInternalDeliveryBudgetGetWeeklyBudgetsOfMonth = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook>
    >
  >,
  TError = unknown,
>(
  params?: InternalDeliveryBudgetGetWeeklyBudgetsOfMonthParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useInternalDeliveryBudgetGetWeeklyBudgetsOfMonthQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useInternalDeliveryBudgetCreateYearlyBudgetHook = () => {
  const internalDeliveryBudgetCreateYearlyBudget =
    useInternalDeliveryBudgetCreateYearlyBudgetMutator<void>();

  return (
    internalDeliveryYearlyBudgetDTOArrayBody: InternalDeliveryYearlyBudgetDTOArrayBody,
    params?: InternalDeliveryBudgetCreateYearlyBudgetParams
  ) => {
    return internalDeliveryBudgetCreateYearlyBudget({
      url: `/api/InternalDeliveryBudget/CreateYearlyBudget`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: internalDeliveryYearlyBudgetDTOArrayBody,
      params,
    });
  };
};

export const useInternalDeliveryBudgetCreateYearlyBudgetMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetCreateYearlyBudgetHook>
      >
    >,
    TError,
    {
      data: InternalDeliveryYearlyBudgetDTOArrayBody;
      params?: InternalDeliveryBudgetCreateYearlyBudgetParams;
    },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useInternalDeliveryBudgetCreateYearlyBudgetHook>
    >
  >,
  TError,
  {
    data: InternalDeliveryYearlyBudgetDTOArrayBody;
    params?: InternalDeliveryBudgetCreateYearlyBudgetParams;
  },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const internalDeliveryBudgetCreateYearlyBudget =
    useInternalDeliveryBudgetCreateYearlyBudgetHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetCreateYearlyBudgetHook>
      >
    >,
    {
      data: InternalDeliveryYearlyBudgetDTOArrayBody;
      params?: InternalDeliveryBudgetCreateYearlyBudgetParams;
    }
  > = (props) => {
    const { data, params } = props ?? {};

    return internalDeliveryBudgetCreateYearlyBudget(data, params);
  };

  return { mutationFn, ...mutationOptions };
};

export type InternalDeliveryBudgetCreateYearlyBudgetMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetCreateYearlyBudgetHook>
      >
    >
  >;
export type InternalDeliveryBudgetCreateYearlyBudgetMutationBody =
  InternalDeliveryYearlyBudgetDTOArrayBody;
export type InternalDeliveryBudgetCreateYearlyBudgetMutationError = unknown;

export const useInternalDeliveryBudgetCreateYearlyBudget = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetCreateYearlyBudgetHook>
      >
    >,
    TError,
    {
      data: InternalDeliveryYearlyBudgetDTOArrayBody;
      params?: InternalDeliveryBudgetCreateYearlyBudgetParams;
    },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useInternalDeliveryBudgetCreateYearlyBudgetHook>
    >
  >,
  TError,
  {
    data: InternalDeliveryYearlyBudgetDTOArrayBody;
    params?: InternalDeliveryBudgetCreateYearlyBudgetParams;
  },
  TContext
> => {
  const mutationOptions =
    useInternalDeliveryBudgetCreateYearlyBudgetMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useInternalDeliveryBudgetUpdateYearlyBudgetHook = () => {
  const internalDeliveryBudgetUpdateYearlyBudget =
    useInternalDeliveryBudgetUpdateYearlyBudgetMutator<void>();

  return (
    internalDeliveryYearlyBudgetDTOArrayBody: InternalDeliveryYearlyBudgetDTOArrayBody
  ) => {
    return internalDeliveryBudgetUpdateYearlyBudget({
      url: `/api/InternalDeliveryBudget/UpdateYearlyBudget`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: internalDeliveryYearlyBudgetDTOArrayBody,
    });
  };
};

export const useInternalDeliveryBudgetUpdateYearlyBudgetMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetUpdateYearlyBudgetHook>
      >
    >,
    TError,
    { data: InternalDeliveryYearlyBudgetDTOArrayBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useInternalDeliveryBudgetUpdateYearlyBudgetHook>
    >
  >,
  TError,
  { data: InternalDeliveryYearlyBudgetDTOArrayBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const internalDeliveryBudgetUpdateYearlyBudget =
    useInternalDeliveryBudgetUpdateYearlyBudgetHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetUpdateYearlyBudgetHook>
      >
    >,
    { data: InternalDeliveryYearlyBudgetDTOArrayBody }
  > = (props) => {
    const { data } = props ?? {};

    return internalDeliveryBudgetUpdateYearlyBudget(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type InternalDeliveryBudgetUpdateYearlyBudgetMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetUpdateYearlyBudgetHook>
      >
    >
  >;
export type InternalDeliveryBudgetUpdateYearlyBudgetMutationBody =
  InternalDeliveryYearlyBudgetDTOArrayBody;
export type InternalDeliveryBudgetUpdateYearlyBudgetMutationError = unknown;

export const useInternalDeliveryBudgetUpdateYearlyBudget = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useInternalDeliveryBudgetUpdateYearlyBudgetHook>
      >
    >,
    TError,
    { data: InternalDeliveryYearlyBudgetDTOArrayBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useInternalDeliveryBudgetUpdateYearlyBudgetHook>
    >
  >,
  TError,
  { data: InternalDeliveryYearlyBudgetDTOArrayBody },
  TContext
> => {
  const mutationOptions =
    useInternalDeliveryBudgetUpdateYearlyBudgetMutationOptions(options);

  return useMutation(mutationOptions);
};
