import { useEffect, useState } from 'react';

import { faInfoCircle, faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { css, useTheme } from 'styled-components';

const Tooltip = styled.div<{ visible: boolean }>`
  display: block;
  position: absolute;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  top: 38px;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.colors.shadow.dropdown};
  right: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 6px;
`;

const SearchInputContainer = styled.div`
  display: flex;
  position: relative;
  align-self: flex-end;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const InputBorder = styled.label<{ highlight?: boolean }>`
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  padding: 7px 10px;
  cursor: text;

  ${({ highlight, theme }) =>
    highlight &&
    css`
      background-color: ${theme.colors.background.highlight};
    `}
`;

const Input = styled.input`
  border: none;
  outline: none;
  font: inherit;
  background: transparent;
  color: inherit;
`;

interface Props {
  className?: string;
  onSearch: (text: string) => void;
}

const MapSearchInput: React.FC<Props> = ({ className, onSearch }) => {
  const theme = useTheme();
  const [text, setText] = useState<string>('');
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const toggleShowTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  useEffect(() => {
    const doSearch = setTimeout(() => {
      onSearch(text);
    }, 200);

    return () => clearTimeout(doSearch);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  return (
    <SearchInputContainer className={className}>
      <InputBorder highlight={!!text}>
        <Input
          onChange={(e) => setText(e.currentTarget.value)}
          placeholder="Filtrera ärenden"
          value={text}
        />
        <FontAwesomeIcon
          color={theme.colors.border.primary}
          icon={faInfoCircle}
          onClick={() => toggleShowTooltip()}
          onMouseLeave={() => setShowTooltip(false)}
          style={{ marginRight: '10px', cursor: 'pointer' }}
        />
        <FontAwesomeIcon icon={faSearch} onClick={() => setText(text)} />
      </InputBorder>
      <Tooltip visible={showTooltip}>
        <strong>Sökbara fält:</strong>
        <ul>
          <li>Regnr</li>
          <li>Kund</li>
          <li>Från</li>
          <li>Till</li>
          <li>Län</li>
          <li>Fälttestare</li>
          <li>Bokare</li>
        </ul>
      </Tooltip>
    </SearchInputContainer>
  );
};

export default MapSearchInput;
