import { UserModel } from 'api';
import useUsers, { useRefreshUsersCall } from 'contexts/basicData/useUsers';
import {
  FC,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import styled, { css } from 'styled-components';
import {
  getUserPropsIconArray,
  userPropsArray,
} from 'pages/Employees/filterableUserFields';
import SearchableSelect, {
  SearchableSelectItem,
  SelectOption,
} from './inputs/SearchableSelect';
import UserModalLink from './UserModalLink';

const ItemsWrapperStyle = css`
  display: grid;
  grid-template-columns: max-content 1fr max-content max-content;
  grid-auto-flow: row;
  .grid-row:hover ~ .grid-row {
    background-color: yellow;
  }
`;

const EmojiWrapper = styled.div<{ amount: number }>`
  ${({ amount }) => css`
    display: grid;
    grid-template-columns: repeat(${amount}, 1fr);
    grid-auto-flow: row;
  `}
`;

const StyledSelectItem = styled(SearchableSelectItem)<{
  isNoneItem?: boolean;
  isHovering?: boolean;
}>`
  width: 100%;

  ${({ isNoneItem }) =>
    isNoneItem &&
    css`
      font-style: italic;
    `}
  ${({ isHovering, theme }) =>
    isHovering &&
    `
      background-color: ${theme.colors.background.selection}!important;
      color: ${theme.colors.foreground.selection}!important;
    `}
`;

const StyledUserModalLink = styled(UserModalLink)`
  margin-left: 0.5rem !important;
`;

type CustomOption = {
  label: string;
  description: JSX.Element;
  value: number;
  extra: UserModel;
};

interface Props {
  selectedUserId: number | null;
  onUserPicked(userModel: UserModel | null): void;
  autoFocus?: boolean;
  className?: string;
  customSort?(): (a: CustomOption, b: CustomOption) => number;
  customFilter?(e: CustomOption): boolean;
  noneOption?: string;
  disabled?: boolean;
}

const UserPicker: FC<Props> = ({
  selectedUserId,
  onUserPicked,
  autoFocus,
  className,
  customSort,
  customFilter,
  noneOption,
  disabled,
}) => {
  const users = useUsers();
  const userTimeout = useRef<NodeJS.Timeout | null>(null);
  const [hovering, setHovering] = useState<string>();
  const refreshUsersCall = useRefreshUsersCall();

  useEffect(() => {
    if (userTimeout.current) clearTimeout(userTimeout.current);
    if (Object.keys(users).length === 0) {
      userTimeout.current = setTimeout(() => {
        refreshUsersCall.run();
      }, 1000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users]);

  const optionRender = useCallback(
    (
      key: string,
      option: SelectOption<number | null, UserModel | null>,
      onClick: () => void,
      isSelected: boolean
    ) => {
      return (
        <>
          <StyledSelectItem
            isHovering={hovering === key}
            isNoneItem={option.value === null}
            key={`${key}_label`}
            onClick={onClick}
            onMouseOut={() => setHovering(undefined)}
            onMouseOver={() => setHovering(key)}
            selected={isSelected}
          >
            <span>{option.label}</span>
          </StyledSelectItem>
          <StyledSelectItem
            isHovering={hovering === key}
            key={`${key}_description`}
            onClick={onClick}
            onMouseOut={() => setHovering(undefined)}
            onMouseOver={() => setHovering(key)}
            selected={isSelected}
          >
            {option.description}
          </StyledSelectItem>
          <StyledSelectItem
            isHovering={hovering === key}
            key={`${key}_city`}
            onClick={onClick}
            onMouseOut={() => setHovering(undefined)}
            onMouseOver={() => setHovering(key)}
            selected={isSelected}
          >
            {`${option.extra?.city ?? ''} ${
              option.extra?.zipCodeLocalArea
                ? `(${option.extra.zipCodeLocalArea.name})`
                : ''
            }`}
          </StyledSelectItem>
          <StyledSelectItem
            isHovering={hovering === key}
            key={`${key}_link`}
            onMouseOut={() => setHovering(undefined)}
            onMouseOver={() => setHovering(key)}
            selected={isSelected}
          >
            {option.extra !== null ? (
              <StyledUserModalLink userId={option.extra.userID} />
            ) : (
              <div />
            )}
          </StyledSelectItem>
        </>
      );
    },
    [hovering]
  );

  const handleItemClick = (
    _userId: number | null,
    _: boolean,
    option: SelectOption<number | null, UserModel | null>
  ) => {
    onUserPicked(option.extra);
  };

  const options = useMemo(() => {
    const opts: SelectOption<number | null, UserModel | null>[] = Object.values(
      users
    )
      .map((usr) => ({
        label: usr.name,
        description: (
          <EmojiWrapper amount={Object.keys(userPropsArray).length}>
            {getUserPropsIconArray(usr).map((e) => (
              <div aria-label={e.title} key={e.title} title={e.title}>
                {e.icon}
              </div>
            ))}
          </EmojiWrapper>
        ),
        value: usr.userID,
        extra: usr,
      }))
      .filter((e) => customFilter?.(e) || true)
      .sort(
        customSort?.() ||
          ((a, b) => {
            if (a.label < b.label) {
              return -1;
            }
            if (a.label > b.label) {
              return 1;
            }
            return 0;
          })
      );

    opts.unshift({
      label: noneOption ?? 'Ingen',
      value: null,
      extra: null,
    });
    return opts;
  }, [customFilter, customSort, noneOption, users]);

  return Object.keys(users).length === 0 ? (
    <div>Laddar...</div>
  ) : (
    <Suspense fallback="Laddar...">
      <SearchableSelect
        autoFocus={autoFocus}
        className={className}
        disabled={disabled}
        itemsWrapperStyle={ItemsWrapperStyle}
        onOptionClicked={handleItemClick}
        optionRender={optionRender}
        options={options}
        searchFilter={(searchString, opts) => {
          const lowerNameFilter = searchString.toLowerCase();
          return opts.filter(
            (opt) =>
              opt.value === null ||
              (opt.extra?.isActive &&
                (opt.extra?.name.toLowerCase().includes(lowerNameFilter) ||
                  opt.extra.city.toLowerCase().includes(lowerNameFilter) ||
                  opt.extra.zipCodeLocalArea?.name
                    .toLowerCase()
                    .includes(lowerNameFilter) ||
                  getUserPropsIconArray(opt.extra).some(
                    (e) =>
                      e.enabled &&
                      e.title?.toLowerCase().includes(lowerNameFilter)
                  )))
          );
        }}
        selectedValue={selectedUserId}
      />
    </Suspense>
  );
};

export default UserPicker;
