import AuthorizedButton from 'components/inputs/AuthorizedButton';
import SectionHeader from 'components/SectionHeader';
import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';

import { AssignmentModel, AssignmentTypeEnum, CaseExtendedModel } from 'api';
import CaseAssignmentForm from './CaseAssignmentForm';
import CaseAssignment from './CaseAssignment';
import {
  useDetailedCaseAssignmentForm,
  useDetailedCaseDataForm,
  useDetailedCaseFormControl,
} from '../DetailedCaseFormProvider';
import { useDetailedCaseContext } from '../DetailedCaseProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { CaseDataField } from '../DetailedCaseFormProvider/useCaseDataForm';
import Cars from 'components/icons/Cars';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
`;

const Assignments = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  overflow: auto;
  overflow-x: hidden;
`;

const AuthorizedAddButton = styled(AuthorizedButton)`
  margin-left: 5px;
`;

const MyCarsIcon = styled(Cars)`
  margin-right: 2px;
`;

const ButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

type AssignmentFocusWrapperProps = {
  children: React.ReactNode;
  triggerFocus(): void;
};

const AssignmentFocusWrapper = React.forwardRef(
  (
    { children, triggerFocus }: AssignmentFocusWrapperProps,
    ref: React.ForwardedRef<HTMLDivElement>
  ) => {
    useEffect(() => {
      triggerFocus();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return <div ref={ref}>{children}</div>;
  }
);

interface CaseAssignmentsProps {
  theCase?: CaseExtendedModel;
  className?: string;
  autoFocusAssignmentID?: number;
  assignmentIdForMarkingAsHandled?: number;
}

const CaseAssignments: React.FC<CaseAssignmentsProps> = ({
  theCase,
  className,
  autoFocusAssignmentID,
  assignmentIdForMarkingAsHandled,
}) => {
  const { editMode, startEdit } = useDetailedCaseFormControl();
  const {
    state: { [CaseDataField.caseTypeID]: formCaseTypeIsStr },
  } = useDetailedCaseDataForm();
  const {
    cancelEdit,
    startEdit: startAssignmentEdit,
    onChange,
    state,
    validations,
  } = useDetailedCaseAssignmentForm();
  const {
    onCaseAssignmentApprove,
    onCaseAssignmentCancel,
    onCaseAssignmentStopOver,
    onPlannedCaseAssignmentStopOver,
    onCaseAssignmentDiscard,
  } = useDetailedCaseContext();

  const assignmentsScrollRef = useRef<HTMLDivElement>(null);
  const assignmentScrollRef = useRef<HTMLDivElement>(null);

  const hasPreviousTestedAssignments = useMemo(
    () =>
      !!theCase?.case.assignmentList.some(
        (ass) => ass.assignmentTypeID === AssignmentTypeEnum.WithTest
      ),
    [theCase?.case.assignmentList]
  );

  const handleCreateAssignmentClick = useCallback(() => {
    if (!editMode) startEdit(theCase ?? null);

    startAssignmentEdit(
      null,
      hasPreviousTestedAssignments
        ? AssignmentTypeEnum.Normal
        : AssignmentTypeEnum.WithTest
    );

    assignmentsScrollRef.current?.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [
    editMode,
    hasPreviousTestedAssignments,
    startAssignmentEdit,
    startEdit,
    theCase,
  ]);

  const handleAssignmentEditClick = useCallback(
    (assignment: AssignmentModel) => {
      startAssignmentEdit(assignment);
    },
    [startAssignmentEdit]
  );

  const triggerFocus = () => {
    assignmentScrollRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  return (
    <Wrapper className={className}>
      <SectionHeader>
        <MyCarsIcon /> Uppdrag
      </SectionHeader>

      <Assignments ref={assignmentsScrollRef}>
        {state[-1] && (
          <CaseAssignmentForm
            assignmentId={-1}
            cancelable={!!theCase}
            caseEvents={theCase?.events ?? []}
            currentCaseType={Number(formCaseTypeIsStr)}
            onCancel={cancelEdit}
            onChange={onChange}
            state={state[-1]}
            validation={validations[-1]}
          />
        )}

        {theCase?.case.assignmentList.map((assignment) => {
          if (assignment.assignmentID in state) {
            return (
              <CaseAssignmentForm
                assignmentId={assignment.assignmentID}
                caseEvents={theCase?.events ?? []}
                currentCaseType={Number(formCaseTypeIsStr)}
                key={assignment.assignmentID}
                onCancel={cancelEdit}
                onChange={onChange}
                state={state[assignment.assignmentID]}
                unmodifiedAssignment={assignment}
                validation={validations[assignment.assignmentID]}
              />
            );
          }

          return autoFocusAssignmentID &&
            autoFocusAssignmentID === assignment.assignmentID ? (
            <AssignmentFocusWrapper
              key={assignment.assignmentID}
              ref={assignmentScrollRef}
              triggerFocus={triggerFocus}
            >
              <CaseAssignment
                assignment={assignment}
                assignmentCase={theCase}
                currentCaseType={theCase.case.caseTypeID}
                editMode={editMode}
                onApproveClick={onCaseAssignmentApprove}
                onCancelClick={onCaseAssignmentCancel}
                onDiscardClick={onCaseAssignmentDiscard}
                onEditClick={handleAssignmentEditClick}
                onStopOverClick={onCaseAssignmentStopOver}
                onPlannedStopOverClick={onPlannedCaseAssignmentStopOver}
                showMarkedAsHandled={
                  assignmentIdForMarkingAsHandled !== undefined &&
                  assignmentIdForMarkingAsHandled === assignment.assignmentID
                }
              />
            </AssignmentFocusWrapper>
          ) : (
            <CaseAssignment
              assignment={assignment}
              assignmentCase={theCase}
              currentCaseType={theCase.case.caseTypeID}
              editMode={editMode}
              key={assignment.assignmentID}
              onApproveClick={onCaseAssignmentApprove}
              onCancelClick={onCaseAssignmentCancel}
              onDiscardClick={onCaseAssignmentDiscard}
              onEditClick={handleAssignmentEditClick}
              onStopOverClick={onCaseAssignmentStopOver}
              onPlannedStopOverClick={onPlannedCaseAssignmentStopOver}
              showMarkedAsHandled={
                assignmentIdForMarkingAsHandled !== undefined &&
                assignmentIdForMarkingAsHandled === assignment.assignmentID
              }
            />
          );
        })}
      </Assignments>

      <ButtonRow>
        {!state[-1] && (
          <AuthorizedAddButton
            // disabled={theCase?.case.invoiceDataID !== null} temp removing this check until efterkoll don't need it
            icon={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleCreateAssignmentClick}
          >
            Nytt uppdrag
          </AuthorizedAddButton>
        )}
      </ButtonRow>
    </Wrapper>
  );
};

export default CaseAssignments;
