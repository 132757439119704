/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AssignmentRequestFilterEnum =
  (typeof AssignmentRequestFilterEnum)[keyof typeof AssignmentRequestFilterEnum];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AssignmentRequestFilterEnum = {
  All: 0,
  Unassigned: 1,
  Assigned: 2,
  CheckedOut: 3,
  Denied: 4,
} as const;
