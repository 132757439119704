/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  CompanyDepartmentGetCompanyDepartmentParams,
  CompanyDepartmentModel,
  CompanyDepartmentModelBody,
} from '.././model';
import useCompanyDepartmentGetCompanyDepartmentMutator from '.././mutator/custom-instance';
import useCompanyDepartmentGetCompanyDepartmentsMutator from '.././mutator/custom-instance';
import useCompanyDepartmentCreateCompanyDepartmentMutator from '.././mutator/custom-instance';
import useCompanyDepartmentUpdateCompanyDepartmentMutator from '.././mutator/custom-instance';

export const useCompanyDepartmentGetCompanyDepartmentHook = () => {
  const companyDepartmentGetCompanyDepartment =
    useCompanyDepartmentGetCompanyDepartmentMutator<CompanyDepartmentModel>();

  return (
    params?: CompanyDepartmentGetCompanyDepartmentParams,
    signal?: AbortSignal
  ) => {
    return companyDepartmentGetCompanyDepartment({
      url: `/api/CompanyDepartment/GetCompanyDepartment`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getCompanyDepartmentGetCompanyDepartmentQueryKey = (
  params?: CompanyDepartmentGetCompanyDepartmentParams
) => {
  return [
    `/api/CompanyDepartment/GetCompanyDepartment`,
    ...(params ? [params] : []),
  ] as const;
};

export const useCompanyDepartmentGetCompanyDepartmentQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentHook>>
  >,
  TError = unknown,
>(
  params?: CompanyDepartmentGetCompanyDepartmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompanyDepartmentGetCompanyDepartmentQueryKey(params);

  const companyDepartmentGetCompanyDepartment =
    useCompanyDepartmentGetCompanyDepartmentHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentHook>
      >
    >
  > = ({ signal }) => companyDepartmentGetCompanyDepartment(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompanyDepartmentGetCompanyDepartmentQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentHook>>
  >
>;
export type CompanyDepartmentGetCompanyDepartmentQueryError = unknown;

export const useCompanyDepartmentGetCompanyDepartment = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentHook>>
  >,
  TError = unknown,
>(
  params?: CompanyDepartmentGetCompanyDepartmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useCompanyDepartmentGetCompanyDepartmentQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useCompanyDepartmentGetCompanyDepartmentsHook = () => {
  const companyDepartmentGetCompanyDepartments =
    useCompanyDepartmentGetCompanyDepartmentsMutator<
      CompanyDepartmentModel[]
    >();

  return (signal?: AbortSignal) => {
    return companyDepartmentGetCompanyDepartments({
      url: `/api/CompanyDepartment/GetCompanyDepartments`,
      method: 'GET',
      signal,
    });
  };
};

export const getCompanyDepartmentGetCompanyDepartmentsQueryKey = () => {
  return [`/api/CompanyDepartment/GetCompanyDepartments`] as const;
};

export const useCompanyDepartmentGetCompanyDepartmentsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentsHook>
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getCompanyDepartmentGetCompanyDepartmentsQueryKey();

  const companyDepartmentGetCompanyDepartments =
    useCompanyDepartmentGetCompanyDepartmentsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentsHook>
      >
    >
  > = ({ signal }) => companyDepartmentGetCompanyDepartments(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentsHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type CompanyDepartmentGetCompanyDepartmentsQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentsHook>>
  >
>;
export type CompanyDepartmentGetCompanyDepartmentsQueryError = unknown;

export const useCompanyDepartmentGetCompanyDepartments = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentGetCompanyDepartmentsHook>
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useCompanyDepartmentGetCompanyDepartmentsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useCompanyDepartmentCreateCompanyDepartmentHook = () => {
  const companyDepartmentCreateCompanyDepartment =
    useCompanyDepartmentCreateCompanyDepartmentMutator<number>();

  return (companyDepartmentModelBody: CompanyDepartmentModelBody) => {
    return companyDepartmentCreateCompanyDepartment({
      url: `/api/CompanyDepartment/CreateCompanyDepartment`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: companyDepartmentModelBody,
    });
  };
};

export const useCompanyDepartmentCreateCompanyDepartmentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentCreateCompanyDepartmentHook>
      >
    >,
    TError,
    { data: CompanyDepartmentModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useCompanyDepartmentCreateCompanyDepartmentHook>
    >
  >,
  TError,
  { data: CompanyDepartmentModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyDepartmentCreateCompanyDepartment =
    useCompanyDepartmentCreateCompanyDepartmentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentCreateCompanyDepartmentHook>
      >
    >,
    { data: CompanyDepartmentModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return companyDepartmentCreateCompanyDepartment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyDepartmentCreateCompanyDepartmentMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentCreateCompanyDepartmentHook>
      >
    >
  >;
export type CompanyDepartmentCreateCompanyDepartmentMutationBody =
  CompanyDepartmentModelBody;
export type CompanyDepartmentCreateCompanyDepartmentMutationError = unknown;

export const useCompanyDepartmentCreateCompanyDepartment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentCreateCompanyDepartmentHook>
      >
    >,
    TError,
    { data: CompanyDepartmentModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useCompanyDepartmentCreateCompanyDepartmentHook>
    >
  >,
  TError,
  { data: CompanyDepartmentModelBody },
  TContext
> => {
  const mutationOptions =
    useCompanyDepartmentCreateCompanyDepartmentMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useCompanyDepartmentUpdateCompanyDepartmentHook = () => {
  const companyDepartmentUpdateCompanyDepartment =
    useCompanyDepartmentUpdateCompanyDepartmentMutator<void>();

  return (companyDepartmentModelBody: CompanyDepartmentModelBody) => {
    return companyDepartmentUpdateCompanyDepartment({
      url: `/api/CompanyDepartment/UpdateCompanyDepartment`,
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      data: companyDepartmentModelBody,
    });
  };
};

export const useCompanyDepartmentUpdateCompanyDepartmentMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentUpdateCompanyDepartmentHook>
      >
    >,
    TError,
    { data: CompanyDepartmentModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useCompanyDepartmentUpdateCompanyDepartmentHook>
    >
  >,
  TError,
  { data: CompanyDepartmentModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const companyDepartmentUpdateCompanyDepartment =
    useCompanyDepartmentUpdateCompanyDepartmentHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentUpdateCompanyDepartmentHook>
      >
    >,
    { data: CompanyDepartmentModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return companyDepartmentUpdateCompanyDepartment(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type CompanyDepartmentUpdateCompanyDepartmentMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentUpdateCompanyDepartmentHook>
      >
    >
  >;
export type CompanyDepartmentUpdateCompanyDepartmentMutationBody =
  CompanyDepartmentModelBody;
export type CompanyDepartmentUpdateCompanyDepartmentMutationError = unknown;

export const useCompanyDepartmentUpdateCompanyDepartment = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useCompanyDepartmentUpdateCompanyDepartmentHook>
      >
    >,
    TError,
    { data: CompanyDepartmentModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useCompanyDepartmentUpdateCompanyDepartmentHook>
    >
  >,
  TError,
  { data: CompanyDepartmentModelBody },
  TContext
> => {
  const mutationOptions =
    useCompanyDepartmentUpdateCompanyDepartmentMutationOptions(options);

  return useMutation(mutationOptions);
};
