/* eslint-disable @typescript-eslint/no-unused-vars */
import { UserClient } from 'api';
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SectionHeader from 'components/SectionHeader';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Routes from 'constants/Routes';
import React, { FC, useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { RequestStatus, useApiCall } from 'swaggerhooks/lib';
import { validPasswordDescription, validPasswordRegex } from 'utils/regex';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 10px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 400px;
  margin-bottom: 100px;

  input {
    width: 100%;
  }
`;

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const ConfirmAccountPage: FC = () => {
  const navigate = useNavigate();
  const queryParams = useQuery();
  const b64EmailConfirmToken = queryParams.get('emailConfirmToken');
  const b64resetPasswordToken = queryParams.get('resetPasswordToken');
  const userId = queryParams.get('userId');

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const isPasswordValid =
    password === confirmPassword && password.match(validPasswordRegex);

  const confirmEmailCall = useApiCall(
    UserClient,
    (c, usrId: number, emailToken: string) =>
      c.confirmUserEmail(usrId, emailToken)
  );
  const resetPasswordCall = useApiCall(
    UserClient,
    (c, usrId: number, passwordToken: string, newPassword: string) =>
      c.resetUserPassword(usrId, passwordToken, newPassword)
  );

  useEffect(() => {
    if (
      userId &&
      b64EmailConfirmToken &&
      confirmEmailCall.status === RequestStatus.Idle
    ) {
      confirmEmailCall.run(Number(userId), window.atob(b64EmailConfirmToken));
    }
  }, [confirmEmailCall, b64EmailConfirmToken, userId]);

  const handleSavePassword = async () => {
    if (!isPasswordValid || !b64resetPasswordToken || !userId) return;

    const [, error] = await resetPasswordCall.run(
      Number(userId),
      window.atob(b64resetPasswordToken),
      password
    );

    if (!error) {
      navigate(Routes.account.login, { replace: true });
    }
  };

  if (!b64resetPasswordToken) {
    return <Navigate replace to={Routes.index} />;
  }

  return (
    <Wrapper>
      {resetPasswordCall.status === RequestStatus.Fetching ? (
        <LoadingSpinner>Sparar lösenord...</LoadingSpinner>
      ) : (
        <Section>
          <SectionHeader>Skapa lösenord till ditt konto</SectionHeader>

          <p>{validPasswordDescription}</p>

          <LabelWrap label="Lösenord">
            <Input
              onChange={(eve) => setPassword(eve.target.value)}
              type="password"
              value={password}
            />
          </LabelWrap>

          <LabelWrap label="Bekräfta lösenord">
            <Input
              onChange={(eve) => setConfirmPassword(eve.target.value)}
              type="password"
              value={confirmPassword}
            />
          </LabelWrap>

          <Button disabled={!isPasswordValid} onClick={handleSavePassword}>
            Spara lösenord
          </Button>
        </Section>
      )}
    </Wrapper>
  );
};

export default ConfirmAccountPage;
