/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-console */
import Button from 'components/inputs/Button';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import SectionHeader from 'components/SectionHeader';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import Routes from 'constants/Routes';
import { useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import useAuthenticationState from '../contexts/authentication/useAuthentication';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  margin-right: auto;
  gap: 16px;
  width: 240px;
  margin-top: 60px;

  input {
    width: 100%;
  }
`;

interface PropState {
  from: { pathname: string };
}

const Login: React.FC = () => {
  const theme = useTheme();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const username = useRef<string>('');
  const password = useRef<string>('');

  const {
    login,
    // signout,
    state: { isAuthenticated, token, error, refreshToken },
  } = useAuthenticationState();

  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as PropState)?.from;
  const path = from?.pathname ?? '/';

  const handleUsernameChange = (newUsername: string) => {
    username.current = newUsername;
  };

  const handlePasswordChange = (newPassword: string) => {
    password.current = newPassword;
  };

  const handleSubmit = () => {
    if (!submitting) {
      login(username.current, password.current);
      setSubmitting(true);
    }
  };

  const handleForgot = () => {
    if (!submitting) {
      navigate(Routes.forgot.index);
    }
  };

  const handleEnterPress = (key: string) => {
    if (key === 'Enter') {
      handleSubmit();
    }
  };

  useEffect(() => {
    if (error) {
      setSubmitting(false);
    }
  }, [error]);

  useEffect(() => {
    if (isAuthenticated) {
      username.current = '';
      password.current = '';
      setSubmitting(false);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (isAuthenticated) navigate(path, { replace: true });
  }, [isAuthenticated]);

  if (!token || !refreshToken) {
    return (
      <LoginContainer>
        <SectionHeader>Logga in</SectionHeader>
        <LabelWrap label="Användarnamn">
          <Input
            id="username"
            disabled={submitting}
            onChange={(e) => handleUsernameChange(e.currentTarget.value)}
            onKeyDown={(e) => handleEnterPress(e.key)}
            type="text"
            autoComplete="username"
          />
        </LabelWrap>

        <LabelWrap label="Lösenord">
          <Input
            id="password"
            disabled={submitting}
            onChange={(e) => handlePasswordChange(e.currentTarget.value)}
            onKeyDown={(e) => handleEnterPress(e.key)}
            type="password"
            autoComplete="password"
          />
        </LabelWrap>

        <Button
          id="login-button"
          disabled={submitting}
          onClick={() => handleSubmit()}
          onKeyUp={(e) => handleEnterPress(e.key)}
          type="button"
        >
          {submitting ? (
            <TransparentSpinner color={theme.colors.foreground.button} small />
          ) : (
            'Logga in'
          )}
        </Button>
        <Button
          disabled={submitting}
          onClick={() => handleForgot()}
          type="button"
        >
          Glömt lösenord
        </Button>

        <Link to={Routes.appInstall.index}>Ladda ner Zäkra Go</Link>
        <Link to={Routes.privacy.index}>Zäkra Go integritetspolicy</Link>
      </LoginContainer>
    );
  }

  return <TransparentSpinner />;
};

export default Login;
