import {
  AssignmentViewModel,
  ExpenseType,
  ReceiptLineItemDTO,
} from 'api/model';
import { AssignmentViewModel as LegacyAssignmentViewModel } from 'api';
import AssignmentPicker, {
  StyledAssignmentSelectItem,
} from 'components/AssignmentPicker';
import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useCallback, useEffect, useState } from 'react';
import { formatDateTime } from 'utils/date-helpers';
import KeyValueList from 'components/KeyValueList';
import useTranslations from 'contexts/basicData/useTranslations';
import ExpenseTypeSelector from 'components/DetailedCase/Expenses/ExpenseEditor/ExpenseTypeSelector';
import {
  useReceiptAssignReceiptToAssignment,
  useReceiptFindApplicableAssignmentsForReceipt,
  useReceiptGetExpenseTypesMapping,
} from 'api/receipt/receipt';
import { SelectOption } from 'components/inputs/SearchableSelect';
// import Checkbox from 'components/inputs/Checkbox';
import LabelWrap from 'components/inputs/LabelWrap';

interface Props {
  lineItem: ReceiptLineItemDTO;
  onUpdate: () => void;
}

const ConnectExternalReceiptModal: React.FC<Props> = ({
  lineItem,
  onUpdate,
}) => {
  const modalStack = useModalStack();
  const { externalReceiptProviders } = useTranslations();

  const [searchRegNo, setSearchRegNo] = useState<string>('');

  const searchTimeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [applicableAssignments, setApplicableAssignments] = useState<
    AssignmentViewModel[]
  >([]);
  const [selectedAssignment, setSelectedAssignment] =
    useState<AssignmentViewModel | null>(null);
  const [selectedExpenseType, setSelectedExpenseType] = useState<
    ExpenseType | undefined
  >(undefined);

  const [hovering, setHovering] = useState<string | undefined>(undefined);

  const assignReceiptToAssignmentCall = useReceiptAssignReceiptToAssignment();
  const expenseTypesMapping = useReceiptGetExpenseTypesMapping();
  // const assignmentSearchCall = useAssignmentSearchAssignment();
  const assignmentSearchCall = useReceiptFindApplicableAssignmentsForReceipt();

  const assignReceiptToAssignment = async () => {
    if (selectedAssignment === null) {
      return;
    }
    try {
      await assignReceiptToAssignmentCall.mutateAsync({
        params: {
          lineItemId: lineItem.lineItem.id,
          assignmentId: selectedAssignment.assignment.assignmentID,
          expenseType: selectedExpenseType,
        },
      });
    } catch (error) {
      // console.log('error', error);
    }

    onUpdate();
    modalStack.pop();
  };
  const fetchAssignmentsAsync = async () => {
    if (searchTimeoutRef.current) {
      clearTimeout(searchTimeoutRef.current);
    }
    searchTimeoutRef.current = setTimeout(async () => {
      const result = await assignmentSearchCall.mutateAsync({
        params: {
          receiptDate: lineItem.receipt.receiptDate,
          cardOwnerUserId: lineItem.cardOwnerUserId,
          regNo: searchRegNo,
        },
      });
      // if there is a selected assignment that is not in the result, splice it in to not have it disappear
      if (
        selectedAssignment &&
        !result.some(
          (a) =>
            a.assignment.assignmentID ===
            selectedAssignment.assignment.assignmentID
        )
      ) {
        result.push(selectedAssignment);
      }
      setApplicableAssignments(result);
    }, 500);
  };
  // refresh assignments search when receipt date changes
  useEffect(() => {
    fetchAssignmentsAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lineItem.receipt.receiptDate, searchRegNo]);

  // auto-select expense type when line item name changes
  useEffect(() => {
    if (lineItem.lineItem.itemName && expenseTypesMapping) {
      if (selectedExpenseType !== undefined) {
        return;
      }
      const expenseType =
        expenseTypesMapping.data?.[lineItem.receipt.provider]?.[
          lineItem.lineItem.itemName
        ] ?? ExpenseType.Other;
      setSelectedExpenseType(expenseType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    expenseTypesMapping,
    lineItem.lineItem.itemName,
    lineItem.receipt.provider,
  ]);

  const optionRender = useCallback(
    (
      key: string,
      option: SelectOption<number | null, AssignmentViewModel | null>,
      onClick: () => void,
      isSelected: boolean
    ) => {
      return (
        <StyledAssignmentSelectItem
          isHovering={hovering === key}
          isNoneItem={option.value === null}
          key={`${key}_label`}
          onClick={onClick}
          onMouseOut={() => setHovering(undefined)}
          onMouseOver={() => setHovering(key)}
          selected={isSelected}
        >
          <div
            style={{ whiteSpace: 'nowrap' }}
          >{`${option.label} ${option.extra?.assignment ? `(${option.extra?.assignment.assignedTo?.name})` : ''}`}</div>
        </StyledAssignmentSelectItem>
      );
    },
    [hovering]
  );

  return (
    <Modal
      buttons={[
        {
          label: 'Koppla till uppdrag',
          loading: assignReceiptToAssignmentCall.isLoading,
          disabled: lineItem.linkedCaseId !== null,
          onClick: assignReceiptToAssignment,
        },
        {
          label: 'Avbryt',
          onClick: () => modalStack.pop(),
        },
      ]}
      title="Koppla kvittorad till uppdrag"
    >
      <div>
        <KeyValueList
          colonKey
          rows={[
            {
              key: 'Datum',
              value: formatDateTime(lineItem.receipt.receiptDate),
            },
            {
              key: 'Partner',
              value: externalReceiptProviders[lineItem.receipt.provider] ?? '',
            },
            { key: 'Kvittonr', value: lineItem.receipt.receiptNumber },
            {
              key: 'Kortinnhavare',
              value: lineItem.cardOwnerName ?? lineItem.cardNumber ?? '',
            },
            { key: 'Produkt', value: lineItem.lineItem.itemName },
            {
              key: 'Antal',
              value: `${lineItem.lineItem.itemQuantity}${lineItem.lineItem.itemUnit}`,
            },
            { key: 'Summa', value: lineItem.lineItem.itemTotal },
            {
              key: 'Uppdrag',
              value: (
                <LabelWrap>
                  <AssignmentPicker
                    assignments={
                      applicableAssignments as LegacyAssignmentViewModel[]
                    }
                    selectedId={
                      selectedAssignment?.assignment.assignmentID ?? null
                    }
                    onSelected={(newValue) => {
                      setSelectedAssignment(
                        newValue as AssignmentViewModel | null
                      );
                    }}
                    onSearch={(search) => {
                      setSearchRegNo(search);
                    }}
                    overrideOptionRender={optionRender}
                  />
                </LabelWrap>
              ),
            },
            {
              key: 'Utgiftstyp',
              value: (
                <ExpenseTypeSelector
                  onChange={setSelectedExpenseType}
                  value={selectedExpenseType}
                />
              ),
            },
          ]}
          keyWidth="150px"
        />
      </div>
    </Modal>
  );
};
export default ConnectExternalReceiptModal;
