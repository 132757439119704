import { FC, useMemo } from 'react';
import Button, { ButtonProps } from './Button';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';

interface Props extends ButtonProps {
  roles?: number[];
}
const AuthorizedButton: FC<Props> = ({
  roles = [Roles.Admin],
  ...buttonProps
}: Props) => {
  const me = useMe();

  // check if me.roles overlaps with roles
  const isAuthorized = useMemo(() => {
    return me?.roles.some((role) => roles.includes(role));
  }, [me, roles]);

  if (!isAuthorized) return null;
  return <Button {...buttonProps} />;
};

export default AuthorizedButton;
