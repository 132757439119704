import { ReceiptLineItemDTO } from 'api/model';
import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import React, { useCallback, useState } from 'react';
import { formatDateTime } from 'utils/date-helpers';
import KeyValueList from 'components/KeyValueList';
import useTranslations from 'contexts/basicData/useTranslations';
import { useReceiptChangeValidStatusOfExternalReceiptLineItem } from 'api/receipt/receipt';
import TextArea from 'components/inputs/TextArea';
import styled from 'styled-components';

const MyTextArea = styled(TextArea)`
  margin-top: 10px;
  width: 100%;
`;

interface Props {
  lineItem: ReceiptLineItemDTO;
  onUpdate: () => void;
}

const InvalidateExternalReceiptModal: React.FC<Props> = ({
  lineItem,
  onUpdate,
}) => {
  const modalStack = useModalStack();
  const { externalReceiptProviders } = useTranslations();

  const [invalidReason, setInvalidReason] = useState<string>('');

  const changeValidStatusOfExternalReceiptLineItemCall =
    useReceiptChangeValidStatusOfExternalReceiptLineItem();

  const updateValidStatus = useCallback(async () => {
    await changeValidStatusOfExternalReceiptLineItemCall.mutateAsync({
      params: {
        reason: invalidReason,
        lineItemId: lineItem.lineItem.id,
        valid: lineItem.lineItem.isInvalid,
      },
    });
    onUpdate();
    modalStack.pop();
  }, [
    changeValidStatusOfExternalReceiptLineItemCall,
    invalidReason,
    lineItem,
    modalStack,
    onUpdate,
  ]);

  return (
    <Modal
      buttons={[
        {
          label: lineItem.lineItem.isInvalid
            ? 'Ångra ogiltighet'
            : 'Markera som ogiltig',
          loading: changeValidStatusOfExternalReceiptLineItemCall.isLoading,
          disabled: lineItem.linkedCaseId !== null,
          onClick: updateValidStatus,
        },
        {
          label: 'Avbryt',
          onClick: () => modalStack.pop(),
        },
      ]}
      title={`Markera kvittorad som ${
        lineItem.lineItem.isInvalid ? 'giltig' : 'ogiltig'
      }`}
    >
      <div>
        <KeyValueList
          colonKey
          rows={[
            {
              key: 'Datum',
              value: formatDateTime(lineItem.receipt.receiptDate),
            },
            {
              key: 'Partner',
              value: externalReceiptProviders[lineItem.receipt.provider] ?? '',
            },
            { key: 'Kvittonr', value: lineItem.receipt.receiptNumber },
            {
              key: 'Kortinnhavare',
              value: lineItem.cardOwnerName ?? lineItem.cardNumber ?? '',
            },
            { key: 'Produkt', value: lineItem.lineItem.itemName },
            {
              key: 'Antal',
              value: `${lineItem.lineItem.itemQuantity}${lineItem.lineItem.itemUnit}`,
            },
            { key: 'Summa', value: lineItem.lineItem.itemTotal },
          ]}
          keyWidth="150px"
        />
        {!lineItem.lineItem.isInvalid && (
          <MyTextArea
            value={invalidReason}
            onChange={(e) => setInvalidReason(e.target.value)}
            placeholder="Ange anledning"
          />
        )}
      </div>
    </Modal>
  );
};
export default InvalidateExternalReceiptModal;
