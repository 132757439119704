import { faAddressBook } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { CompanyModel } from 'api';
import { FC } from 'react';
import styled from 'styled-components';
import CompanyPicker from './CompanyPicker';
import Input, { CombineInputs } from './inputs/Input';
// import { fakeSelectClassName } from './inputs/SearchableSelect';
import Select from './inputs/Select';
import useZipCodeAreas from 'hooks/useZipCodeAreas';
import useTranslations from 'contexts/basicData/useTranslations';
import { ZipCodeArea } from 'api/model';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
`;

// hides the default controls for CompanyPicker, and use a <label> to trigger it instead
// const InvisibleCompanyPicker = styled(CompanyPicker)`
//   .${fakeSelectClassName} {
//     display: none !important;
//   }
// `;

const AddressBookLabel = styled.label`
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: center;

  color: ${({ theme }) => theme.colors.foreground.tint};
  cursor: pointer;

  svg {
    font-size: 1.1em;
  }
`;

interface Props {
  name: string;
  address: string;
  zip: string;
  city: string;
  countyId: string | number | null;

  companyOptionFilter?: (company: CompanyModel) => boolean;

  onNameChanged(value: string): void;
  onAddressChanged(value: string): void;
  onZipChanged(value: string): void;
  onCityChanged(value: string): void;
  onCountyChanged(zipCodeArea: ZipCodeArea | null): void;

  onCompanyPicked?(company: CompanyModel, county?: ZipCodeArea): void;
}

const AddressInput: FC<Props> = ({
  name,
  address,
  zip,
  city,
  countyId,

  companyOptionFilter,
  onNameChanged,
  onAddressChanged,
  onZipChanged,
  onCityChanged,
  onCountyChanged,
  onCompanyPicked,
}) => {
  const { zipCodeAreas } = useTranslations();
  const { selectableZipCodeAreas, zipCodeAreaFromZipCode } = useZipCodeAreas();

  const handleCountyIdPicked = (countyIdStr: string | number) => {
    const zipCodeArea: ZipCodeArea | undefined = zipCodeAreas[countyIdStr];
    onCountyChanged(zipCodeArea ?? null);
  };

  return (
    <Wrapper>
      <CombineInputs>
        <Input
          onChange={(eve) => onNameChanged(eve.target.value)}
          placeholder="Namn"
          small
          title="Namn"
          value={name}
          width={180}
        />
        <Input
          onChange={(eve) => onAddressChanged(eve.target.value)}
          placeholder="Adress"
          small
          title="Adress"
          value={address}
          width={150}
        />
        <Input
          onChange={(eve) => {
            onZipChanged(eve.target.value);

            const zipCodeArea = zipCodeAreaFromZipCode(eve.target.value);
            if (zipCodeArea) handleCountyIdPicked(zipCodeArea.id);
          }}
          placeholder="Postkod"
          small
          title="Postkod"
          value={zip}
          width={65}
        />
        <Input
          onChange={(eve) => onCityChanged(eve.target.value)}
          placeholder="Stad"
          small
          title="Stad"
          value={city}
          width={110}
        />
        <Select
          onChange={(eve) => handleCountyIdPicked(eve.target.value)}
          small
          title="Län"
          value={countyId ?? ''}
          width={130}
        >
          <option value="">-- inget län --</option>
          {selectableZipCodeAreas.map((codeArea) => (
            <option key={codeArea.id} value={codeArea.id}>
              {codeArea.areaName}
            </option>
          ))}
        </Select>
      </CombineInputs>
      {onCompanyPicked && (
        <AddressBookLabel>
          <FontAwesomeIcon icon={faAddressBook} />
          <CompanyPicker
            dropdownPosition="left"
            filter={companyOptionFilter}
            hideInput
            notNullable
            onCompanySelected={(company) => {
              if (company) {
                const zipCodeArea = zipCodeAreaFromZipCode(company.zip);
                onCompanyPicked(company, zipCodeArea);
              }
            }}
            selectedCompanyId={null}
          />
        </AddressBookLabel>
      )}
    </Wrapper>
  );
};

export default AddressInput;
