import { FC, useMemo } from 'react';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import Checkbox, { CheckboxProps } from './Checkbox';

interface Props extends CheckboxProps {
  roles?: number[];
}
const AuthorizedCheckbox: FC<Props> = ({
  roles = [Roles.Admin],
  ...props
}: Props) => {
  const { ref, ...restProps } = props;

  const me = useMe();

  // check if me.roles overlaps with roles
  const isAuthorized = useMemo(() => {
    return me?.roles.some((role) => roles.includes(role));
  }, [me, roles]);

  if (!isAuthorized) return null;
  return <Checkbox {...restProps} />;
};

export default AuthorizedCheckbox;
