import { ITeamGroupModel, TeamGroupModel, TeamsClient } from 'api';
import Modal from 'components/Modal';
import Input from 'components/inputs/Input';
import LabelWrap from 'components/inputs/LabelWrap';
import TextArea from 'components/inputs/TextArea';
import useModalStack from 'contexts/modal/useModalStack';
import { FC } from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import { useApiCall } from 'swaggerhooks/lib';

const MyLabelWrap = styled(LabelWrap)<{ width?: number }>`
  margin-bottom: 10px;
  & > *:last-child {
    width: ${({ width }) => (width ? `${width}px` : 'auto')};
  }
`;

const getDefaultValues = (teamGroup?: TeamGroupModel): ITeamGroupModel => {
  if (teamGroup) {
    return {
      id: teamGroup.id,
      name: teamGroup.name,
      description: teamGroup.description,
      timestamp: teamGroup.timestamp,
    } as ITeamGroupModel;
  }

  return {
    id: 0,
    name: '',
    description: '',
    timestamp: '',
  } as ITeamGroupModel;
};

interface Props {
  teamGroup?: TeamGroupModel;
  onCreated?: (id: number) => void;
}

const TeamGroupFormModal: FC<Props> = ({ teamGroup, onCreated }) => {
  const modalStack = useModalStack();

  const createTeamGroupCall = useApiCall(
    TeamsClient,
    (c, tgg: TeamGroupModel) => c.createTeamGroup(tgg)
  );

  const { watch, setValue, handleSubmit } = useForm<ITeamGroupModel>({
    defaultValues: getDefaultValues(teamGroup),
  });

  const handleSaveClick = handleSubmit(async (data) => {
    const [, error] = await createTeamGroupCall.run(data as TeamGroupModel);
    if (!error) {
      if (onCreated) {
        onCreated(data.id);
      }
      modalStack.pop();
    }
  });

  return (
    <Modal
      buttons={[
        {
          label: 'Avbryt',
          onClick: () => modalStack.pop(),
        },
        {
          label: 'Spara',
          onClick: () => {
            handleSaveClick();
          },
        },
      ]}
      onClose={() => modalStack.pop()}
      title="Skapa avdelning"
    >
      <MyLabelWrap label="Namn">
        <Input
          onChange={(e) => setValue('name', e.currentTarget.value)}
          placeholder="Namn"
          value={watch('name')}
        />
      </MyLabelWrap>
      <MyLabelWrap label="Beskrivning">
        <TextArea
          onChange={(e) => setValue('description', e.currentTarget.value)}
          placeholder="Beskrivning"
          value={watch('description')}
        />
      </MyLabelWrap>
    </Modal>
  );
};

export default TeamGroupFormModal;
