import { useEffect, useState } from 'react';

import styled from 'styled-components';

import { AssignmentViewModel, CaseModel } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';

const Tooltip = styled.div<{ visible: boolean }>`
  display: block;
  position: absolute;
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  top: 0;
  border: 1px solid ${({ theme }) => theme.colors.border.primary};
  border-radius: 3px;
  box-shadow: ${({ theme }) => theme.colors.shadow.dropdown};
  left: 0;
  z-index: 100;
  background-color: ${({ theme }) => theme.colors.background.primary};
  padding: 6px;
`;

const CountContainer = styled.span`
  color: ${({ theme }) => theme.colors.foreground.primary};
  cursor: pointer;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
`;

const ListItem = styled.li`
  white-space: nowrap;
`;

interface CountByKey {
  caseType: string;
  countRegNr: number;
  countCases: number;
}

interface AssignmentCountState {
  assignments: number;
  cases: number;
  registrationNumbers: number;
  countByCaseType: CountByKey[];
}

const getDefaultAssignmentCountState = (): AssignmentCountState => {
  return {
    assignments: 0,
    cases: 0,
    registrationNumbers: 0,
    countByCaseType: [],
  };
};

interface Props {
  assignments?: AssignmentViewModel[];
}

const AssignmentCountWidget: React.FC<Props> = ({ assignments }) => {
  const translations = useTranslations();
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [stats, setStats] = useState<AssignmentCountState>(
    getDefaultAssignmentCountState()
  );
  const toggleShowTooltip = () => setShowTooltip((p) => !p);

  useEffect(() => {
    const assignmentCount = assignments?.length ?? 0;
    const regNrsByCaseType =
      assignments?.reduce((agg, cur) => {
        const updatedAgg = { ...agg };
        updatedAgg[`${cur.case.registrationNumber}-${cur.case.caseTypeID}`] =
          cur.case;
        return updatedAgg;
      }, {} as { [key: string]: CaseModel }) ?? {};
    const casesByCaseType =
      assignments?.reduce((agg, cur) => {
        const updatedAgg = { ...agg };
        if (updatedAgg[cur.case.caseTypeID] === undefined) {
          updatedAgg[cur.case.caseTypeID] = 0;
        }
        updatedAgg[cur.case.caseTypeID] += 1;
        return updatedAgg;
      }, {} as { [key: string]: number }) ?? {};

    const uniqueCases =
      assignments?.reduce((agg, cur) => {
        const updatedAgg = { ...agg };
        updatedAgg[cur.case.caseID] = cur.case;
        return updatedAgg;
      }, {} as { [key: string]: CaseModel }) ?? {};

    const uniqueCaseIds = Object.keys(uniqueCases).map(Number);
    // count unique cases by case type
    const countByCaseType: CountByKey[] = [];
    Object.entries(regNrsByCaseType).forEach(([, theCase]) => {
      const caseType = translations.caseTypes[theCase.caseTypeID] ?? '';
      const currentIndex = countByCaseType.findIndex(
        (ct) => ct.caseType === caseType
      );
      if (currentIndex === -1) {
        countByCaseType.push({
          caseType,
          countRegNr: 1,
          countCases: casesByCaseType[theCase.caseTypeID] ?? 0,
        });
      } else {
        countByCaseType[currentIndex].countRegNr += 1;
      }
    });
    countByCaseType.sort((a, b) => a.caseType.localeCompare(b.caseType));

    setStats({
      assignments: assignmentCount,
      cases: uniqueCaseIds.length,
      registrationNumbers: Object.keys(regNrsByCaseType).length,
      countByCaseType,
    });
  }, [assignments, translations.caseTypes]);

  return (
    <>
      <CountContainer
        onClick={() => toggleShowTooltip()}
        onKeyDown={(event) => {
          if (event.key === 'Enter' || event.key === ' ') {
            toggleShowTooltip();
          }
        }}
        onMouseLeave={() => setShowTooltip(false)}
        role="button"
        tabIndex={0}
      >
        <span>{stats.registrationNumbers}</span>
      </CountContainer>
      <Tooltip visible={showTooltip}>
        <strong>
          Antal Regnr: {stats.registrationNumbers} ({stats.cases} ärenden)
        </strong>
        {stats.countByCaseType.length > 1 && (
          <ul>
            {stats.countByCaseType.map((ct) => (
              <ListItem key={ct.caseType}>
                {ct.caseType}: {ct.countRegNr} ({ct.countCases} ärenden)
              </ListItem>
            ))}
          </ul>
        )}
      </Tooltip>
    </>
  );
};

export default AssignmentCountWidget;
