import { ProductExpenseTypeMappingDto } from 'api/model';
import { useReceiptGetProductExpenseTypeMappings } from 'api/receipt/receipt';
import KeyValueList from 'components/KeyValueList';
import useTranslations from 'contexts/basicData/useTranslations';
import { useMemo } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;

const Header = styled.h2`
  font-size: 1.5rem;
  margin: 20px 0 10px;
`;

const ProductExpenseTypeMappingsPage = () => {
  const { externalReceiptProviders, expenseTypes } = useTranslations();
  const productMappingsResponse = useReceiptGetProductExpenseTypeMappings();

  const mappings = useMemo((): {
    [key: number]: ProductExpenseTypeMappingDto[];
  } => {
    const result: {
      [key: number]: ProductExpenseTypeMappingDto[];
    } = {};
    productMappingsResponse.data?.forEach((mapping) => {
      if (!result[mapping.provider]) {
        result[mapping.provider] = [];
      }
      result[mapping.provider].push(mapping);
    });

    return result;
  }, [productMappingsResponse.data]);

  return (
    <Wrapper>
      <span>
        Denna lista visar hur produktnamn mappas mot kostnadstyper. Så småningom
        kommer det att gå att modifiera detta här.
      </span>
      {Object.keys(mappings).map((provider) => (
        <div key={provider}>
          <Header>{externalReceiptProviders[Number(provider)]}</Header>
          <KeyValueList
            colonKey
            keyWidth="150px"
            rows={mappings[Number(provider)].map((mapping) => ({
              key: mapping.productName,
              value: expenseTypes[mapping.expenseType!] ?? '-',
            }))}
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default ProductExpenseTypeMappingsPage;
