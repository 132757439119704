import { useMemo } from 'react';

import { AssignmentRequestReasonEnum } from 'api/model';
import useTranslations from 'contexts/basicData/useTranslations';
import { SelectOption } from 'components/inputs/SearchableSelect';
import { makeStringComparator } from 'utils/sorting';

const useAssignmentRequestReasonSelectOptions = () => {
  const { assignmentRequestReasons } = useTranslations();

  const options = useMemo(
    () =>
      Object.entries(assignmentRequestReasons)
        .map(
          ([value, label]): SelectOption<AssignmentRequestReasonEnum> => ({
            label: String(label),
            value: Number(value) as AssignmentRequestReasonEnum,
          })
        )
        .sort(
          makeStringComparator((x) => assignmentRequestReasons[x.value] ?? '-')
        ),
    [assignmentRequestReasons]
  );
  return options;
};

export default useAssignmentRequestReasonSelectOptions;
