import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import themes from 'constants/Colors';
import themeSizes from 'constants/Sizes';
import { useDashboardControls } from 'contexts/Dashboard/useDashboardControls';
import { FC, PropsWithChildren } from 'react';
import styled from 'styled-components';

const Box = styled.div<{
  expanded: boolean;
  small: boolean;
  isUpcoming?: boolean;
}>`
  padding: 5px;
  border: 1px solid ${themes.light.border.primary};
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;

  grid-column: span ${({ expanded, small }) => (expanded ? 4 : small ? 1 : 2)};

  ${({ isUpcoming }) =>
    isUpcoming &&
    `
    background-color: ${themes.light.background.secondary};
    color: ${themes.light.foreground.tertiary};
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 5px 0px;
`;

const Title = styled.h1`
  display: flex;
  gap: 10px;
  font-size: ${themeSizes.font.medium};
`;

const Icon = styled.div`
  margin-right: 5px;
`;

const Content = styled.div<{ expanded: boolean }>`
  margin-top: 5px;
  height: ${({ expanded }) => (expanded ? '100%' : '300px')};
  overflow-y: ${({ expanded }) => (expanded ? 'none' : 'auto')};
`;

interface Props extends PropsWithChildren {
  title: string;
  count?: number;
  info?: string;
  small?: boolean;
  isLoading?: boolean;
  isUpcomingFeature?: boolean;
}

const Widget: FC<Props> = ({
  title,
  count,
  info,
  small,
  isLoading,
  children,
  isUpcomingFeature,
}) => {
  const {
    dashboardState: { collapsed, expanded },
  } = useDashboardControls();

  return (
    <Box
      expanded={expanded}
      small={small ?? false}
      isUpcoming={isUpcomingFeature}
    >
      <Header>
        <Title>
          {title}
          {count !== undefined && <span>({count})</span>}
          {isLoading && <FontAwesomeIcon icon={faSpinner} spin />}
        </Title>
        {info && (
          <Icon>
            <FontAwesomeIcon
              icon={faInfoCircle}
              color={themes.light.foreground.tertiary}
              size="lg"
              title={info}
            />
          </Icon>
        )}
      </Header>

      {!collapsed && <Content expanded={expanded}>{children}</Content>}
    </Box>
  );
};

export default Widget;
