/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  AssignedTimeDevMenuDto,
  AssignedTimeDto,
  AssignmentInNeedOfHandlingDto,
  CheckIfOkToStartNowRequest,
  DrivingPolicyCheckIfOkToAddAssignmentParams,
  DrivingPolicyCheckIfOkToAddEstimatedStartTimeParams,
  DrivingPolicyGetAssignedTimesForUserInDevMenuParams,
  DrivingPolicyGetAssignedTimesForUserParams,
  DrivingPolicyReportDto,
  DrivingPolicyRequestOverrideDrivingPolicyParams,
} from '.././model';
import useDrivingPolicyGetAssignmentsInNeedOfHandlingMutator from '.././mutator/custom-instance';
import useDrivingPolicyGetAssignmentsInNeedOfHandlingCountMutator from '.././mutator/custom-instance';
import useDrivingPolicyGetDrivingReportsMutator from '.././mutator/custom-instance';
import useDrivingPolicyGetAssignedTimesForUserMutator from '.././mutator/custom-instance';
import useDrivingPolicyGetAssignedTimesForUserInDevMenuMutator from '.././mutator/custom-instance';
import useDrivingPolicyCheckIfOkToAddEstimatedStartTimeMutator from '.././mutator/custom-instance';
import useDrivingPolicyCheckIfOkToStartNowMutator from '.././mutator/custom-instance';
import useDrivingPolicyCheckIfOkToAddAssignmentMutator from '.././mutator/custom-instance';
import useDrivingPolicyRequestOverrideDrivingPolicyMutator from '.././mutator/custom-instance';

export const useDrivingPolicyGetAssignmentsInNeedOfHandlingHook = () => {
  const drivingPolicyGetAssignmentsInNeedOfHandling =
    useDrivingPolicyGetAssignmentsInNeedOfHandlingMutator<
      AssignmentInNeedOfHandlingDto[]
    >();

  return (signal?: AbortSignal) => {
    return drivingPolicyGetAssignmentsInNeedOfHandling({
      url: `/api/DrivingPolicy/GetAssignmentsInNeedOfHandling`,
      method: 'GET',
      signal,
    });
  };
};

export const getDrivingPolicyGetAssignmentsInNeedOfHandlingQueryKey = () => {
  return [`/api/DrivingPolicy/GetAssignmentsInNeedOfHandling`] as const;
};

export const useDrivingPolicyGetAssignmentsInNeedOfHandlingQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingHook>
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDrivingPolicyGetAssignmentsInNeedOfHandlingQueryKey();

  const drivingPolicyGetAssignmentsInNeedOfHandling =
    useDrivingPolicyGetAssignmentsInNeedOfHandlingHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingHook>
      >
    >
  > = ({ signal }) => drivingPolicyGetAssignmentsInNeedOfHandling(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DrivingPolicyGetAssignmentsInNeedOfHandlingQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingHook>
      >
    >
  >;
export type DrivingPolicyGetAssignmentsInNeedOfHandlingQueryError = unknown;

export const useDrivingPolicyGetAssignmentsInNeedOfHandling = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingHook>
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useDrivingPolicyGetAssignmentsInNeedOfHandlingQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook = () => {
  const drivingPolicyGetAssignmentsInNeedOfHandlingCount =
    useDrivingPolicyGetAssignmentsInNeedOfHandlingCountMutator<number>();

  return (signal?: AbortSignal) => {
    return drivingPolicyGetAssignmentsInNeedOfHandlingCount({
      url: `/api/DrivingPolicy/GetAssignmentsInNeedOfHandlingCount`,
      method: 'GET',
      signal,
    });
  };
};

export const getDrivingPolicyGetAssignmentsInNeedOfHandlingCountQueryKey =
  () => {
    return [`/api/DrivingPolicy/GetAssignmentsInNeedOfHandlingCount`] as const;
  };

export const useDrivingPolicyGetAssignmentsInNeedOfHandlingCountQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook
        >
      >
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDrivingPolicyGetAssignmentsInNeedOfHandlingCountQueryKey();

  const drivingPolicyGetAssignmentsInNeedOfHandlingCount =
    useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook
        >
      >
    >
  > = ({ signal }) => drivingPolicyGetAssignmentsInNeedOfHandlingCount(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook
        >
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DrivingPolicyGetAssignmentsInNeedOfHandlingCountQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook
        >
      >
    >
  >;
export type DrivingPolicyGetAssignmentsInNeedOfHandlingCountQueryError =
  unknown;

export const useDrivingPolicyGetAssignmentsInNeedOfHandlingCount = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook>
    >
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<
          typeof useDrivingPolicyGetAssignmentsInNeedOfHandlingCountHook
        >
      >
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useDrivingPolicyGetAssignmentsInNeedOfHandlingCountQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDrivingPolicyGetDrivingReportsHook = () => {
  const drivingPolicyGetDrivingReports =
    useDrivingPolicyGetDrivingReportsMutator<DrivingPolicyReportDto[]>();

  return (signal?: AbortSignal) => {
    return drivingPolicyGetDrivingReports({
      url: `/api/DrivingPolicy/GetDrivingReports`,
      method: 'GET',
      signal,
    });
  };
};

export const getDrivingPolicyGetDrivingReportsQueryKey = () => {
  return [`/api/DrivingPolicy/GetDrivingReports`] as const;
};

export const useDrivingPolicyGetDrivingReportsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyGetDrivingReportsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyGetDrivingReportsHook>>
    >,
    TError,
    TData
  >;
}) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getDrivingPolicyGetDrivingReportsQueryKey();

  const drivingPolicyGetDrivingReports =
    useDrivingPolicyGetDrivingReportsHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyGetDrivingReportsHook>>
    >
  > = ({ signal }) => drivingPolicyGetDrivingReports(signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyGetDrivingReportsHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DrivingPolicyGetDrivingReportsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useDrivingPolicyGetDrivingReportsHook>>>
>;
export type DrivingPolicyGetDrivingReportsQueryError = unknown;

export const useDrivingPolicyGetDrivingReports = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyGetDrivingReportsHook>>
  >,
  TError = unknown,
>(options?: {
  query?: UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyGetDrivingReportsHook>>
    >,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useDrivingPolicyGetDrivingReportsQueryOptions(options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDrivingPolicyGetAssignedTimesForUserHook = () => {
  const drivingPolicyGetAssignedTimesForUser =
    useDrivingPolicyGetAssignedTimesForUserMutator<AssignedTimeDto[]>();

  return (
    params?: DrivingPolicyGetAssignedTimesForUserParams,
    signal?: AbortSignal
  ) => {
    return drivingPolicyGetAssignedTimesForUser({
      url: `/api/DrivingPolicy/GetAssignedTimesForUser`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getDrivingPolicyGetAssignedTimesForUserQueryKey = (
  params?: DrivingPolicyGetAssignedTimesForUserParams
) => {
  return [
    `/api/DrivingPolicy/GetAssignedTimesForUser`,
    ...(params ? [params] : []),
  ] as const;
};

export const useDrivingPolicyGetAssignedTimesForUserQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserHook>>
  >,
  TError = unknown,
>(
  params?: DrivingPolicyGetAssignedTimesForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDrivingPolicyGetAssignedTimesForUserQueryKey(params);

  const drivingPolicyGetAssignedTimesForUser =
    useDrivingPolicyGetAssignedTimesForUserHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserHook>>
    >
  > = ({ signal }) => drivingPolicyGetAssignedTimesForUser(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DrivingPolicyGetAssignedTimesForUserQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserHook>>
  >
>;
export type DrivingPolicyGetAssignedTimesForUserQueryError = unknown;

export const useDrivingPolicyGetAssignedTimesForUser = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserHook>>
  >,
  TError = unknown,
>(
  params?: DrivingPolicyGetAssignedTimesForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useDrivingPolicyGetAssignedTimesForUserQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDrivingPolicyGetAssignedTimesForUserInDevMenuHook = () => {
  const drivingPolicyGetAssignedTimesForUserInDevMenu =
    useDrivingPolicyGetAssignedTimesForUserInDevMenuMutator<
      AssignedTimeDevMenuDto[]
    >();

  return (
    params?: DrivingPolicyGetAssignedTimesForUserInDevMenuParams,
    signal?: AbortSignal
  ) => {
    return drivingPolicyGetAssignedTimesForUserInDevMenu({
      url: `/api/DrivingPolicy/GetAssignedTimesForUserInDevMenu`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getDrivingPolicyGetAssignedTimesForUserInDevMenuQueryKey = (
  params?: DrivingPolicyGetAssignedTimesForUserInDevMenuParams
) => {
  return [
    `/api/DrivingPolicy/GetAssignedTimesForUserInDevMenu`,
    ...(params ? [params] : []),
  ] as const;
};

export const useDrivingPolicyGetAssignedTimesForUserInDevMenuQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserInDevMenuHook>
    >
  >,
  TError = unknown,
>(
  params?: DrivingPolicyGetAssignedTimesForUserInDevMenuParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useDrivingPolicyGetAssignedTimesForUserInDevMenuHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDrivingPolicyGetAssignedTimesForUserInDevMenuQueryKey(params);

  const drivingPolicyGetAssignedTimesForUserInDevMenu =
    useDrivingPolicyGetAssignedTimesForUserInDevMenuHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserInDevMenuHook>
      >
    >
  > = ({ signal }) =>
    drivingPolicyGetAssignedTimesForUserInDevMenu(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserInDevMenuHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DrivingPolicyGetAssignedTimesForUserInDevMenuQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserInDevMenuHook>
      >
    >
  >;
export type DrivingPolicyGetAssignedTimesForUserInDevMenuQueryError = unknown;

export const useDrivingPolicyGetAssignedTimesForUserInDevMenu = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyGetAssignedTimesForUserInDevMenuHook>
    >
  >,
  TError = unknown,
>(
  params?: DrivingPolicyGetAssignedTimesForUserInDevMenuParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useDrivingPolicyGetAssignedTimesForUserInDevMenuHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useDrivingPolicyGetAssignedTimesForUserInDevMenuQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook = () => {
  const drivingPolicyCheckIfOkToAddEstimatedStartTime =
    useDrivingPolicyCheckIfOkToAddEstimatedStartTimeMutator<DrivingPolicyReportDto>();

  return (
    params?: DrivingPolicyCheckIfOkToAddEstimatedStartTimeParams,
    signal?: AbortSignal
  ) => {
    return drivingPolicyCheckIfOkToAddEstimatedStartTime({
      url: `/api/DrivingPolicy/CheckIfOkToAddEstimatedStartTime`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getDrivingPolicyCheckIfOkToAddEstimatedStartTimeQueryKey = (
  params?: DrivingPolicyCheckIfOkToAddEstimatedStartTimeParams
) => {
  return [
    `/api/DrivingPolicy/CheckIfOkToAddEstimatedStartTime`,
    ...(params ? [params] : []),
  ] as const;
};

export const useDrivingPolicyCheckIfOkToAddEstimatedStartTimeQueryOptions = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook>
    >
  >,
  TError = unknown,
>(
  params?: DrivingPolicyCheckIfOkToAddEstimatedStartTimeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook
          >
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDrivingPolicyCheckIfOkToAddEstimatedStartTimeQueryKey(params);

  const drivingPolicyCheckIfOkToAddEstimatedStartTime =
    useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook>
      >
    >
  > = ({ signal }) =>
    drivingPolicyCheckIfOkToAddEstimatedStartTime(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DrivingPolicyCheckIfOkToAddEstimatedStartTimeQueryResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook>
      >
    >
  >;
export type DrivingPolicyCheckIfOkToAddEstimatedStartTimeQueryError = unknown;

export const useDrivingPolicyCheckIfOkToAddEstimatedStartTime = <
  TData = Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook>
    >
  >,
  TError = unknown,
>(
  params?: DrivingPolicyCheckIfOkToAddEstimatedStartTimeParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<
            typeof useDrivingPolicyCheckIfOkToAddEstimatedStartTimeHook
          >
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions =
    useDrivingPolicyCheckIfOkToAddEstimatedStartTimeQueryOptions(
      params,
      options
    );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDrivingPolicyCheckIfOkToStartNowHook = () => {
  const drivingPolicyCheckIfOkToStartNow =
    useDrivingPolicyCheckIfOkToStartNowMutator<DrivingPolicyReportDto>();

  return (checkIfOkToStartNowRequest: CheckIfOkToStartNowRequest) => {
    return drivingPolicyCheckIfOkToStartNow({
      url: `/api/DrivingPolicy/CheckIfOkToStartNow`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: checkIfOkToStartNowRequest,
    });
  };
};

export const useDrivingPolicyCheckIfOkToStartNowMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToStartNowHook>>
    >,
    TError,
    { data: CheckIfOkToStartNowRequest },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToStartNowHook>>
  >,
  TError,
  { data: CheckIfOkToStartNowRequest },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const drivingPolicyCheckIfOkToStartNow =
    useDrivingPolicyCheckIfOkToStartNowHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToStartNowHook>>
    >,
    { data: CheckIfOkToStartNowRequest }
  > = (props) => {
    const { data } = props ?? {};

    return drivingPolicyCheckIfOkToStartNow(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type DrivingPolicyCheckIfOkToStartNowMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToStartNowHook>>
  >
>;
export type DrivingPolicyCheckIfOkToStartNowMutationBody =
  CheckIfOkToStartNowRequest;
export type DrivingPolicyCheckIfOkToStartNowMutationError = unknown;

export const useDrivingPolicyCheckIfOkToStartNow = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToStartNowHook>>
    >,
    TError,
    { data: CheckIfOkToStartNowRequest },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToStartNowHook>>
  >,
  TError,
  { data: CheckIfOkToStartNowRequest },
  TContext
> => {
  const mutationOptions =
    useDrivingPolicyCheckIfOkToStartNowMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useDrivingPolicyCheckIfOkToAddAssignmentHook = () => {
  const drivingPolicyCheckIfOkToAddAssignment =
    useDrivingPolicyCheckIfOkToAddAssignmentMutator<DrivingPolicyReportDto>();

  return (
    params?: DrivingPolicyCheckIfOkToAddAssignmentParams,
    signal?: AbortSignal
  ) => {
    return drivingPolicyCheckIfOkToAddAssignment({
      url: `/api/DrivingPolicy/CheckIfOkToAddAssignment`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getDrivingPolicyCheckIfOkToAddAssignmentQueryKey = (
  params?: DrivingPolicyCheckIfOkToAddAssignmentParams
) => {
  return [
    `/api/DrivingPolicy/CheckIfOkToAddAssignment`,
    ...(params ? [params] : []),
  ] as const;
};

export const useDrivingPolicyCheckIfOkToAddAssignmentQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToAddAssignmentHook>>
  >,
  TError = unknown,
>(
  params?: DrivingPolicyCheckIfOkToAddAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useDrivingPolicyCheckIfOkToAddAssignmentHook>
        >
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getDrivingPolicyCheckIfOkToAddAssignmentQueryKey(params);

  const drivingPolicyCheckIfOkToAddAssignment =
    useDrivingPolicyCheckIfOkToAddAssignmentHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyCheckIfOkToAddAssignmentHook>
      >
    >
  > = ({ signal }) => drivingPolicyCheckIfOkToAddAssignment(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyCheckIfOkToAddAssignmentHook>
      >
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type DrivingPolicyCheckIfOkToAddAssignmentQueryResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToAddAssignmentHook>>
  >
>;
export type DrivingPolicyCheckIfOkToAddAssignmentQueryError = unknown;

export const useDrivingPolicyCheckIfOkToAddAssignment = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useDrivingPolicyCheckIfOkToAddAssignmentHook>>
  >,
  TError = unknown,
>(
  params?: DrivingPolicyCheckIfOkToAddAssignmentParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<
          ReturnType<typeof useDrivingPolicyCheckIfOkToAddAssignmentHook>
        >
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useDrivingPolicyCheckIfOkToAddAssignmentQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useDrivingPolicyRequestOverrideDrivingPolicyHook = () => {
  const drivingPolicyRequestOverrideDrivingPolicy =
    useDrivingPolicyRequestOverrideDrivingPolicyMutator<void>();

  return (params?: DrivingPolicyRequestOverrideDrivingPolicyParams) => {
    return drivingPolicyRequestOverrideDrivingPolicy({
      url: `/api/DrivingPolicy/RequestOverrideDrivingPolicy`,
      method: 'POST',
      params,
    });
  };
};

export const useDrivingPolicyRequestOverrideDrivingPolicyMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyRequestOverrideDrivingPolicyHook>
      >
    >,
    TError,
    { params?: DrivingPolicyRequestOverrideDrivingPolicyParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyRequestOverrideDrivingPolicyHook>
    >
  >,
  TError,
  { params?: DrivingPolicyRequestOverrideDrivingPolicyParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const drivingPolicyRequestOverrideDrivingPolicy =
    useDrivingPolicyRequestOverrideDrivingPolicyHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyRequestOverrideDrivingPolicyHook>
      >
    >,
    { params?: DrivingPolicyRequestOverrideDrivingPolicyParams }
  > = (props) => {
    const { params } = props ?? {};

    return drivingPolicyRequestOverrideDrivingPolicy(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type DrivingPolicyRequestOverrideDrivingPolicyMutationResult =
  NonNullable<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyRequestOverrideDrivingPolicyHook>
      >
    >
  >;

export type DrivingPolicyRequestOverrideDrivingPolicyMutationError = unknown;

export const useDrivingPolicyRequestOverrideDrivingPolicy = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<
        ReturnType<typeof useDrivingPolicyRequestOverrideDrivingPolicyHook>
      >
    >,
    TError,
    { params?: DrivingPolicyRequestOverrideDrivingPolicyParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<
      ReturnType<typeof useDrivingPolicyRequestOverrideDrivingPolicyHook>
    >
  >,
  TError,
  { params?: DrivingPolicyRequestOverrideDrivingPolicyParams },
  TContext
> => {
  const mutationOptions =
    useDrivingPolicyRequestOverrideDrivingPolicyMutationOptions(options);

  return useMutation(mutationOptions);
};
