import {
  faBan,
  faCheckCircle,
  faComment,
  faExclamationCircle,
  faTaxi,
  faToolbox,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AssignmentDeviationEnum,
  AssignmentExtendedModel,
  AssignmentViewModel,
  CaseTypeEnum,
  ITranslationLookups,
} from 'api';
import { DotSpan } from 'components/AssignmentsList';
import ExpandableWarningsWidget from 'components/AssignmentsList/ExpandableWarningsWidget';
import FakeCheckbox from 'components/inputs/FakeCheckbox';
import {
  ColumnSetting,
  tableCellClassName,
  TableColumnEnum,
  tableHeadClassName,
} from 'components/Table/utils';
import useUsers from 'contexts/basicData/useUsers';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import assignmentWarnings, { WarningInfo } from 'utils/assignment-warnings';
import { validateMoveToApproved } from 'utils/assignmentStatusValidation';
import { formatDate, formatTime } from 'utils/date-helpers';
import { booleanCompare, makeBooleanComparator } from 'utils/sorting';

const renderedCheckboxClassName = 'fake-checkbox';
const disabledCheckboxClassName = 'fake-checkbox disabled-checkbox';
const CheckboxCell = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-left: 3px;
  width: 100%;
  height: 100%;

  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};

  &:hover .${renderedCheckboxClassName} {
    background-color: ${({ theme }) => theme.colors.background.button};
    color: ${({ theme }) => theme.colors.foreground.button};
  }

  &.${disabledCheckboxClassName} {
    cursor: not-allowed;
  }
`;

const CustomIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.background.button};
`;

export interface ColumnRenderProps {
  onSelectClick(assignment: AssignmentViewModel): void;
  selectedAssignmentIds: Set<number>;
  translations: ITranslationLookups;
}

const specialBulkSort = (a: AssignmentViewModel, b: AssignmentViewModel) => {
  if (
    (a.case.caseTypeID === CaseTypeEnum.TradeIn ||
      a.case.caseTypeID === CaseTypeEnum.HomeDelivery) &&
    (b.case.caseTypeID === CaseTypeEnum.TradeIn ||
      b.case.caseTypeID === CaseTypeEnum.HomeDelivery)
  ) {
    if (a.case.rideOrderID == null) {
      return -1;
    }
    if (b.case.rideOrderID == null) {
      return 1;
    }
    if (a.case.rideOrderID > b.case.rideOrderID) {
      return 1;
    }
    if (a.case.rideOrderID < b.case.rideOrderID) {
      return -1;
    }
  }

  // Sort by CaseType
  if (a.case.caseTypeID > b.case.caseTypeID) {
    return 1;
  }
  if (a.case.caseTypeID < b.case.caseTypeID) {
    return -1;
  }
  // If Purchase sort by FromCounty
  if (a.case.caseTypeID === CaseTypeEnum.Purchase) {
    if (a.assignment.fromCounty == null) {
      return -1;
    }
    if (b.assignment.fromCounty == null) {
      return 1;
    }
    if (a.assignment.fromCounty.areaName < b.assignment.fromCounty.areaName) {
      return -1;
    }
    if (a.assignment.fromCounty.areaName > b.assignment.fromCounty.areaName) {
      return 1;
    }
  }

  // Then Sort by User
  if (a.assignment.assignedTo != null && b.assignment.assignedTo != null) {
    if (a.assignment.assignedTo.name < b.assignment.assignedTo.name) {
      return -1;
    }
    if (a.assignment.assignedTo.name > b.assignment.assignedTo.name) {
      return 1;
    }
  }

  // Then Sort by BookedTo
  if (a.assignment.bookedTo < b.assignment.bookedTo) {
    return -1;
  }
  if (a.assignment.bookedTo > b.assignment.bookedTo) {
    return 1;
  }

  // nothing to split them
  return 0;
};

const useColumnSettings = (): ColumnSetting<
  AssignmentViewModel | AssignmentExtendedModel,
  ColumnRenderProps
>[] => {
  const users = useUsers();
  return useMemo(() => {
    type Column = ColumnSetting<
      AssignmentViewModel | AssignmentExtendedModel,
      ColumnRenderProps
    >;
    const columns = [
      {
        head: 'Nr',
        render: (_avm, _props, _focused, rowIndex) => (
          <span aria-label={String(rowIndex + 1)} title={String(rowIndex + 1)}>
            {rowIndex + 1}
          </span>
        ),
        width: 50,
      },
      {
        head: '',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .notifyIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.newMessage};
          }
        `,
        csvValue: (avm) =>
          avm.case.adminHasUnreadComments
            ? 'Har olästa kommentarer'
            : 'Saknar olästa kommentarer',
        render: (avm) =>
          avm.case.adminHasUnreadComments && (
            <FontAwesomeIcon
              className="notifyIcon"
              fixedWidth
              icon={faComment}
              title="Det finns olästa kommentarer"
            />
          ),
        width: 22,
        sortFunction: (a, b) =>
          booleanCompare(
            a.case.adminHasUnreadComments,
            b.case.adminHasUnreadComments
          ),
      },
      {
        head: '',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .notifyIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.error};
          }
        `,
        csvValue: (avm) => {
          const info: string[] = [];
          if (avm.assignment.hasUncheckedFlaggedForSpeeding) {
            info.push('Uppdraget har hastighetsöverträdelser');
          }
          if (avm.assignment.assigneeReportedMissingGear) {
            info.push('Fälttestaren saknar arbetsutrustning vid start');
          }
          return info.join(', ');
        },
        render: (avm) => {
          const icons = [];
          if (avm.assignment.hasUncheckedFlaggedForSpeeding) {
            icons.push(
              <FontAwesomeIcon
                className="notifyIcon"
                fixedWidth
                icon={faTaxi}
                title="Uppdraget har hastighetsöverträdelser"
              />
            );
          }
          if (avm.assignment.assigneeReportedMissingGear) {
            icons.push(
              <FontAwesomeIcon
                className="notifyIcon"
                fixedWidth
                icon={faToolbox}
                title="Fälttestaren saknar arbetsutrustning vid start"
              />
            );
          }
          return <>{icons.map((i) => i)}</>;
        },
        width: 22,
        sortFunction: (a, b) => {
          const aHasAnIssue =
            a.assignment.hasUncheckedFlaggedForSpeeding ||
            (a.assignment.assigneeHasGreenPlate === false &&
              a.assignment.assignedTo?.userID !== undefined &&
              (users[a.assignment.assignedTo.userID]?.greenPlate?.length ?? 0) >
                0) ||
            a.assignment.assigneeHasOBDMeter === false ||
            a.assignment.assigneeHasTyreMeter === false ||
            a.assignment.assigneeHasWorkClothes === false;

          const bHasAnIssue =
            b.assignment.hasUncheckedFlaggedForSpeeding ||
            (b.assignment.assigneeHasGreenPlate === false &&
              b.assignment.assignedTo?.userID !== undefined &&
              (users[b.assignment.assignedTo.userID]?.greenPlate?.length ?? 0) >
                0) ||
            b.assignment.assigneeHasOBDMeter === false ||
            b.assignment.assigneeHasTyreMeter === false ||
            b.assignment.assigneeHasWorkClothes === false;

          return booleanCompare(aHasAnIssue, bHasAnIssue);
        },
      },
      {
        identifier: TableColumnEnum.AssignmentUnreadComments,
        head: '',
        csvHead: 'Olästa kommentarer',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .notifyIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.newMessage};
          }
        `,
        csvValue: (avm) =>
          avm.case.adminHasUnreadComments
            ? 'Har olästa kommentarer'
            : 'Saknar olästa kommentarer',
        render: (avm) =>
          avm.case.adminHasUnreadComments && (
            <FontAwesomeIcon
              className="notifyIcon"
              fixedWidth
              icon={faComment}
              title="Det finns olästa kommentarer"
            />
          ),
        width: 22,
        sortFunction: (a, b) =>
          booleanCompare(
            a.case.adminHasUnreadComments,
            b.case.adminHasUnreadComments
          ),
      },
      {
        identifier: TableColumnEnum.AssignmentWarnings,
        head: '',
        csvHead: 'Varningar',
        css: css`
          &:not(.${tableHeadClassName}) {
            border-right: 0;
          }
          .notifyIcon {
            font-size: 12px;
            color: ${({ theme }) => theme.colors.foreground.error};
          }
        `,
        csvValue: (avm) => {
          const warnings: WarningInfo[] =
            assignmentWarnings.getCaseAndAssignmentWarnings(avm);
          return warnings.map((w) => w.warning).join(', ');
        },
        render: (avm) => {
          const warnings = assignmentWarnings.getCaseAndAssignmentWarnings(avm);
          return <ExpandableWarningsWidget warnings={warnings} />;
        },
        width: 22,
        sortFunction: (a, b) => {
          const aIssues =
            assignmentWarnings.getCaseAndAssignmentWarnings(a).length;
          const bIssues =
            assignmentWarnings.getCaseAndAssignmentWarnings(b).length;

          return aIssues - bIssues;
        },
      },
      {
        head: 'Välj',
        render: (avm, { onSelectClick, selectedAssignmentIds }) => {
          const errors = validateMoveToApproved(avm);

          return errors.length === 0 ? (
            <CheckboxCell
              onClick={(e) => {
                e.stopPropagation();
                onSelectClick(avm);
              }}
            >
              <FakeCheckbox
                checked={selectedAssignmentIds.has(avm.assignment.assignmentID)}
                className={renderedCheckboxClassName}
              />
            </CheckboxCell>
          ) : (
            <CheckboxCell
              title={errors.join('\n')}
              onClick={(e) => {
                e.stopPropagation();
                onSelectClick(avm);
              }}
            >
              <div
                style={{
                  display: 'flex',
                  gap: '15px',
                }}
              >
                <FakeCheckbox
                  checked={selectedAssignmentIds.has(
                    avm.assignment.assignmentID
                  )}
                  className={disabledCheckboxClassName}
                />

                <FontAwesomeIcon icon={faBan} />
              </div>
            </CheckboxCell>
          );
        },
        css: css`
          max-width: 60px;

          &:not(.${tableHeadClassName}) {
            padding: 0;
          }
        `,
        width: 60,
      },
      {
        head: 'Autogodkänns',
        css: css`
          &.${tableCellClassName} {
            color: ${({ theme }) => theme.colors.foreground.good};
          }
        `,
        render: (avm) =>
          avm.assignment.autoApprove ? (
            <FontAwesomeIcon icon={faCheckCircle} />
          ) : null,
        width: 100,
        sortFunction: makeBooleanComparator(
          (avm) => !!avm.assignment.autoApprove
        ),
      },
      {
        head: 'Regnr',
        csvValue: (avm) => avm.case.registrationNumber,
        render: (avm: AssignmentViewModel) => `${avm.case.registrationNumber}`,
        width: 70,
        sortFunction: (a, b) =>
          a.case.registrationNumber > b.case.registrationNumber ? 1 : -1,

        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
      },
      {
        head: 'Uppdragstyp',
        render: (avm, { translations: { caseTypes } }) =>
          caseTypes[avm.case.caseTypeID],
        width: 100,
        sortFunction: specialBulkSort,
      },
      {
        head: 'Avvikelse',
        render: (avm, { translations: { assignmentDeviations } }) =>
          avm.assignment.assignmentDeviationID === AssignmentDeviationEnum.None
            ? ''
            : assignmentDeviations[avm.assignment.assignmentDeviationID],
        width: 80,
        sortFunction: specialBulkSort,
      },
      {
        head: 'Datum',
        render: (avm) => formatDate(avm.assignment.bookedTo),
        width: 100,
        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
        sortFunction: (a, b) =>
          a.assignment.bookedTo > b.assignment.bookedTo ? 1 : -1,
      },
      {
        head: 'Bokad tid',
        render: (avm) => formatTime(avm.assignment.bookedTo),
        width: 90,
        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
        sortFunction: (a, b) =>
          a.assignment.bookedTo > b.assignment.bookedTo ? 1 : -1,
      },
      {
        head: 'Fälttestare',
        render: (assignmentViewModel) =>
          assignmentViewModel.assignment.assignedTo?.name,
        width: 150,
        sortFunction: (a, b) =>
          (a.assignment.assignedTo?.name ?? '') >
          (b.assignment.assignedTo?.name ?? '')
            ? 1
            : -1,
      },
      {
        head: 'Starttid',
        render: (avm) => formatTime(avm.assignment.startTime),
        width: 80,
        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
        sortFunction: (a, b) =>
          (a.assignment.startTime ?? 0) > (b.assignment.startTime ?? 0)
            ? 1
            : -1,
      },
      {
        head: 'Sluttid',
        render: (avm) => formatTime(avm.assignment.endTime),
        width: 80,
        css: css`
          &.${tableCellClassName} {
            font-family: monospace;
          }
        `,
        sortFunction: (a, b) =>
          (a.assignment.endTime ?? 0) > (b.assignment.endTime ?? 0) ? 1 : -1,
      },
      {
        head: 'Rast',
        render: (avm) => `${avm.assignment.breakTime} min`,
        width: 100,
        sortFunction: (a, b) => a.assignment.breakTime - b.assignment.breakTime,
      },
      {
        head: 'Arbetad tid',
        render: (avm) => avm.assignment.workedTimeDisplay,
        width: 100,
        sortFunction: (a, b) => {
          const durationAssignmentA =
            (a.assignment.endTime?.getTime() ?? 0) -
            (a.assignment.startTime?.getTime() ?? 0);

          const durationAssignmentB =
            (b.assignment.endTime?.getTime() ?? 0) -
            (b.assignment.startTime?.getTime() ?? 0);

          return durationAssignmentA - durationAssignmentB;
        },
      },
      {
        head: 'Utlägg',
        render: (avm) => {
          return (
            <>
              {`${avm.assignment.expenseSum} kr`}{' '}
              {avm instanceof AssignmentExtendedModel ? (
                avm.assignment.expectedExpenses.some(
                  (ee) =>
                    !avm.expenses.some(
                      (e) =>
                        e.expenseType === ee.type &&
                        e.assignmentID === ee.assignmentId &&
                        e.approvedBy !== null
                    )
                ) ? (
                  <CustomIcon icon={faExclamationCircle} />
                ) : null
              ) : null}
            </>
          );
        },
        width: 100,
        sortFunction: (a, b) =>
          a.assignment.expenseSum - b.assignment.expenseSum,
      },
      {
        head: 'CircleK',
        render: (avm) => {
          return <>{`${avm.assignment.circleKExpenseSum} kr`} </>;
        },
        width: 100,
        sortFunction: (a, b) =>
          a.assignment.expenseSum - b.assignment.expenseSum,
      },
      {
        head: 'Från län',
        render: ({ assignment: { fromCounty } }) =>
          fromCounty ? (
            <>
              <DotSpan dotColor={fromCounty.hexColorCode} />
              {fromCounty.areaName}
            </>
          ) : (
            ' '
          ),
        width: 140,
        sortFunction: (a, b) =>
          (a.assignment.fromCounty?.areaName || '') >
          (b.assignment.fromCounty?.areaName || '')
            ? 1
            : -1,
      },
      {
        head: 'Till län',
        render: ({ assignment: { toCounty } }) =>
          toCounty ? (
            <>
              <DotSpan dotColor={toCounty.hexColorCode} />
              {toCounty.areaName}
            </>
          ) : (
            ' '
          ),
        width: 140,
        sortFunction: (a, b) =>
          (a.assignment.toCounty?.areaName || '') >
          (b.assignment.toCounty?.areaName || '')
            ? 1
            : -1,
      },
    ] as Column[];

    return columns.filter((c) => !!c); // remove undefined before returning columns
  }, [users]);
};

export default useColumnSettings;
