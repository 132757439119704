import React from 'react';
import { CaseAssignmentPosition } from 'api/model';
import TextButton from './inputs/TextButton';
import { openGoogleMapsPosition } from 'utils/address-helper';
import styled from 'styled-components';

const MyTextButton = styled(TextButton)`
  height: 20px;
`;

interface Props {
  position: CaseAssignmentPosition;
}

const MapOpener: React.FC<Props> = ({ position }) => {
  return (
    <MyTextButton
      onClick={() =>
        openGoogleMapsPosition(position.latitude, position.longitude)
      }
      style={{
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      title="Öppna i Google Maps"
    >
      <img
        alt="Google maps icon"
        height={12}
        src="/images/googlemapsiconsmall.png"
        width={12}
      />
    </MyTextButton>
  );
};

export default MapOpener;
