import { ITranslationLookups, TeamModel, TeamsClient } from 'api';
import styled, { css } from 'styled-components';
import { useResponse } from 'swaggerhooks/lib';
import useModalStack from 'contexts/modal/useModalStack';
import { ColumnSetting } from 'components/Table/utils';
import { useMemo } from 'react';
import Table from 'components/Table';
import TeamFormModal from './TeamFormModal';
import useTeamGroups from 'contexts/basicData/useTeamGroups';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import AuthorizedButton from 'components/inputs/AuthorizedButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Actions = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 50px;
  padding: 10px;
  gap: 10px;
`;

const RowCss = css`
  cursor: pointer;
`;
const StyledButton = styled(AuthorizedButton)`
  padding: 8px;
  white-space: nowrap;
` as typeof AuthorizedButton;

const now = new Date();
now.setHours(0, 0, 0, 0);

const TeamsOverview = () => {
  const me = useMe();
  const modalStack = useModalStack();
  const { teamGroupsById } = useTeamGroups();
  const teamsResponse = useResponse(TeamsClient, (c) => c.getTeams());

  const columnSettings: ColumnSetting<TeamModel, ITranslationLookups>[] =
    useMemo(() => {
      const columns: ColumnSetting<TeamModel, ITranslationLookups>[] = [
        {
          css: RowCss,
          head: 'Namn',
          render: (row: TeamModel) => row.name,
          width: 200,
          sortFunction: (a, b) => a?.name?.localeCompare(b?.name),
        },
        {
          css: RowCss,
          head: 'Medlemmar',
          render: (row: TeamModel) => row.teamMemberIds.length,
          width: 150,
          sortFunction: (a, b) =>
            a.teamMemberIds.length - b.teamMemberIds.length,
        },
        {
          css: RowCss,
          head: 'Beskrivning',
          render: (row: TeamModel) => row.description,
          width: 200,
          sortFunction: (a, b) => a?.description?.localeCompare(b?.description),
        },
        {
          css: RowCss,
          head: 'Avdelning',
          render: (row: TeamModel) =>
            teamGroupsById.get(row.teamGroupId ?? -1)?.name ?? '-',
          width: 200,
          sortFunction: (a, b) => {
            const aName = teamGroupsById.get(a.teamGroupId ?? -1)?.name ?? '-';
            const bName = teamGroupsById.get(b.teamGroupId ?? -1)?.name ?? '-';
            return aName.localeCompare(bName);
          },
        },
      ];
      return columns;
    }, [teamGroupsById]);

  return (
    <Container>
      {me?.roles.includes(Roles.Admin) && (
        <Actions>
          <StyledButton
            onClick={() => {
              modalStack.push(
                <TeamFormModal
                  onCreated={() => {
                    teamsResponse.update();
                  }}
                />
              );
            }}
          >
            + Lägg till grupp
          </StyledButton>
        </Actions>
      )}
      <Table
        columnSettings={columnSettings}
        onRowClick={(row) => {
          modalStack.push(
            <TeamFormModal
              onUpdated={() => {
                teamsResponse.update();
              }}
              team={row}
            />
          );
        }}
        rows={teamsResponse.response ?? []}
      />
    </Container>
  );
};

export default TeamsOverview;
