/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */
import { useMutation, useQuery } from '@tanstack/react-query';
import type {
  MutationFunction,
  QueryFunction,
  QueryKey,
  UseMutationOptions,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  OverlapDTO,
  ProblemDetails,
  TimeReportGetTimeReportParams,
  TimeReportGetTimeReportsForDayByUserParams,
  TimeReportGetTimeReportsForUserParams,
  TimeReportGetTimeReportsParams,
  TimeReportModel,
  TimeReportModelBody,
} from '.././model';
import useTimeReportGetTimeReportsMutator from '.././mutator/custom-instance';
import useTimeReportCreateTimeReportMutator from '.././mutator/custom-instance';
import useTimeReportUpdateTimeReportMutator from '.././mutator/custom-instance';
import useTimeReportGetTimeReportsForDayByUserMutator from '.././mutator/custom-instance';
import useTimeReportCheckForTimeReportOverlapMutator from '.././mutator/custom-instance';
import useTimeReportGetTimeReportsForUserMutator from '.././mutator/custom-instance';
import useTimeReportGetTimeReportMutator from '.././mutator/custom-instance';

export const useTimeReportGetTimeReportsHook = () => {
  const timeReportGetTimeReports =
    useTimeReportGetTimeReportsMutator<TimeReportModel[]>();

  return (params?: TimeReportGetTimeReportsParams, signal?: AbortSignal) => {
    return timeReportGetTimeReports({
      url: `/api/TimeReport/GetTimeReports`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getTimeReportGetTimeReportsQueryKey = (
  params?: TimeReportGetTimeReportsParams
) => {
  return [
    `/api/TimeReport/GetTimeReports`,
    ...(params ? [params] : []),
  ] as const;
};

export const useTimeReportGetTimeReportsQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportsHook>>
  >,
  TError = unknown,
>(
  params?: TimeReportGetTimeReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportsHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTimeReportGetTimeReportsQueryKey(params);

  const timeReportGetTimeReports = useTimeReportGetTimeReportsHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportsHook>>>
  > = ({ signal }) => timeReportGetTimeReports(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportsHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TimeReportGetTimeReportsQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportsHook>>>
>;
export type TimeReportGetTimeReportsQueryError = unknown;

export const useTimeReportGetTimeReports = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportsHook>>
  >,
  TError = unknown,
>(
  params?: TimeReportGetTimeReportsParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportsHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTimeReportGetTimeReportsQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useTimeReportCreateTimeReportHook = () => {
  const timeReportCreateTimeReport =
    useTimeReportCreateTimeReportMutator<number>();

  return (timeReportModelBody: TimeReportModelBody) => {
    return timeReportCreateTimeReport({
      url: `/api/TimeReport/CreateTimeReport`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: timeReportModelBody,
    });
  };
};

export const useTimeReportCreateTimeReportMutationOptions = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeReportCreateTimeReportHook>>>,
    TError,
    { data: TimeReportModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeReportCreateTimeReportHook>>>,
  TError,
  { data: TimeReportModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const timeReportCreateTimeReport = useTimeReportCreateTimeReportHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeReportCreateTimeReportHook>>>,
    { data: TimeReportModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return timeReportCreateTimeReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TimeReportCreateTimeReportMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeReportCreateTimeReportHook>>>
>;
export type TimeReportCreateTimeReportMutationBody = TimeReportModelBody;
export type TimeReportCreateTimeReportMutationError = ProblemDetails;

export const useTimeReportCreateTimeReport = <
  TError = ProblemDetails,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeReportCreateTimeReportHook>>>,
    TError,
    { data: TimeReportModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeReportCreateTimeReportHook>>>,
  TError,
  { data: TimeReportModelBody },
  TContext
> => {
  const mutationOptions = useTimeReportCreateTimeReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useTimeReportUpdateTimeReportHook = () => {
  const timeReportUpdateTimeReport =
    useTimeReportUpdateTimeReportMutator<TimeReportModel>();

  return (timeReportModelBody: TimeReportModelBody) => {
    return timeReportUpdateTimeReport({
      url: `/api/TimeReport/UpdateTimeReport`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: timeReportModelBody,
    });
  };
};

export const useTimeReportUpdateTimeReportMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeReportUpdateTimeReportHook>>>,
    TError,
    { data: TimeReportModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<ReturnType<ReturnType<typeof useTimeReportUpdateTimeReportHook>>>,
  TError,
  { data: TimeReportModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const timeReportUpdateTimeReport = useTimeReportUpdateTimeReportHook();

  const mutationFn: MutationFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeReportUpdateTimeReportHook>>>,
    { data: TimeReportModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return timeReportUpdateTimeReport(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TimeReportUpdateTimeReportMutationResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeReportUpdateTimeReportHook>>>
>;
export type TimeReportUpdateTimeReportMutationBody = TimeReportModelBody;
export type TimeReportUpdateTimeReportMutationError = unknown;

export const useTimeReportUpdateTimeReport = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeReportUpdateTimeReportHook>>>,
    TError,
    { data: TimeReportModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<ReturnType<ReturnType<typeof useTimeReportUpdateTimeReportHook>>>,
  TError,
  { data: TimeReportModelBody },
  TContext
> => {
  const mutationOptions = useTimeReportUpdateTimeReportMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useTimeReportGetTimeReportsForDayByUserHook = () => {
  const timeReportGetTimeReportsForDayByUser =
    useTimeReportGetTimeReportsForDayByUserMutator<TimeReportModel[]>();

  return (params?: TimeReportGetTimeReportsForDayByUserParams) => {
    return timeReportGetTimeReportsForDayByUser({
      url: `/api/TimeReport/GetTimeReportsForDayByUser`,
      method: 'POST',
      params,
    });
  };
};

export const useTimeReportGetTimeReportsForDayByUserMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForDayByUserHook>>
    >,
    TError,
    { params?: TimeReportGetTimeReportsForDayByUserParams },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForDayByUserHook>>
  >,
  TError,
  { params?: TimeReportGetTimeReportsForDayByUserParams },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const timeReportGetTimeReportsForDayByUser =
    useTimeReportGetTimeReportsForDayByUserHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForDayByUserHook>>
    >,
    { params?: TimeReportGetTimeReportsForDayByUserParams }
  > = (props) => {
    const { params } = props ?? {};

    return timeReportGetTimeReportsForDayByUser(params);
  };

  return { mutationFn, ...mutationOptions };
};

export type TimeReportGetTimeReportsForDayByUserMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForDayByUserHook>>
  >
>;

export type TimeReportGetTimeReportsForDayByUserMutationError = unknown;

export const useTimeReportGetTimeReportsForDayByUser = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForDayByUserHook>>
    >,
    TError,
    { params?: TimeReportGetTimeReportsForDayByUserParams },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForDayByUserHook>>
  >,
  TError,
  { params?: TimeReportGetTimeReportsForDayByUserParams },
  TContext
> => {
  const mutationOptions =
    useTimeReportGetTimeReportsForDayByUserMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useTimeReportCheckForTimeReportOverlapHook = () => {
  const timeReportCheckForTimeReportOverlap =
    useTimeReportCheckForTimeReportOverlapMutator<OverlapDTO>();

  return (timeReportModelBody: TimeReportModelBody) => {
    return timeReportCheckForTimeReportOverlap({
      url: `/api/TimeReport/CheckForTimeReportOverlap`,
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      data: timeReportModelBody,
    });
  };
};

export const useTimeReportCheckForTimeReportOverlapMutationOptions = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportCheckForTimeReportOverlapHook>>
    >,
    TError,
    { data: TimeReportModelBody },
    TContext
  >;
}): UseMutationOptions<
  Awaited<
    ReturnType<ReturnType<typeof useTimeReportCheckForTimeReportOverlapHook>>
  >,
  TError,
  { data: TimeReportModelBody },
  TContext
> => {
  const { mutation: mutationOptions } = options ?? {};

  const timeReportCheckForTimeReportOverlap =
    useTimeReportCheckForTimeReportOverlapHook();

  const mutationFn: MutationFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportCheckForTimeReportOverlapHook>>
    >,
    { data: TimeReportModelBody }
  > = (props) => {
    const { data } = props ?? {};

    return timeReportCheckForTimeReportOverlap(data);
  };

  return { mutationFn, ...mutationOptions };
};

export type TimeReportCheckForTimeReportOverlapMutationResult = NonNullable<
  Awaited<
    ReturnType<ReturnType<typeof useTimeReportCheckForTimeReportOverlapHook>>
  >
>;
export type TimeReportCheckForTimeReportOverlapMutationBody =
  TimeReportModelBody;
export type TimeReportCheckForTimeReportOverlapMutationError = unknown;

export const useTimeReportCheckForTimeReportOverlap = <
  TError = unknown,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportCheckForTimeReportOverlapHook>>
    >,
    TError,
    { data: TimeReportModelBody },
    TContext
  >;
}): UseMutationResult<
  Awaited<
    ReturnType<ReturnType<typeof useTimeReportCheckForTimeReportOverlapHook>>
  >,
  TError,
  { data: TimeReportModelBody },
  TContext
> => {
  const mutationOptions =
    useTimeReportCheckForTimeReportOverlapMutationOptions(options);

  return useMutation(mutationOptions);
};
export const useTimeReportGetTimeReportsForUserHook = () => {
  const timeReportGetTimeReportsForUser =
    useTimeReportGetTimeReportsForUserMutator<TimeReportModel[]>();

  return (
    params?: TimeReportGetTimeReportsForUserParams,
    signal?: AbortSignal
  ) => {
    return timeReportGetTimeReportsForUser({
      url: `/api/TimeReport/GetTimeReportsForUser`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getTimeReportGetTimeReportsForUserQueryKey = (
  params?: TimeReportGetTimeReportsForUserParams
) => {
  return [
    `/api/TimeReport/GetTimeReportsForUser`,
    ...(params ? [params] : []),
  ] as const;
};

export const useTimeReportGetTimeReportsForUserQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForUserHook>>
  >,
  TError = unknown,
>(
  params?: TimeReportGetTimeReportsForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForUserHook>>
      >,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ??
    getTimeReportGetTimeReportsForUserQueryKey(params);

  const timeReportGetTimeReportsForUser =
    useTimeReportGetTimeReportsForUserHook();

  const queryFn: QueryFunction<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForUserHook>>
    >
  > = ({ signal }) => timeReportGetTimeReportsForUser(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<
      ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForUserHook>>
    >,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TimeReportGetTimeReportsForUserQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForUserHook>>>
>;
export type TimeReportGetTimeReportsForUserQueryError = unknown;

export const useTimeReportGetTimeReportsForUser = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForUserHook>>
  >,
  TError = unknown,
>(
  params?: TimeReportGetTimeReportsForUserParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<
        ReturnType<ReturnType<typeof useTimeReportGetTimeReportsForUserHook>>
      >,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTimeReportGetTimeReportsForUserQueryOptions(
    params,
    options
  );

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};

export const useTimeReportGetTimeReportHook = () => {
  const timeReportGetTimeReport =
    useTimeReportGetTimeReportMutator<TimeReportModel>();

  return (params?: TimeReportGetTimeReportParams, signal?: AbortSignal) => {
    return timeReportGetTimeReport({
      url: `/api/TimeReport/GetTimeReport`,
      method: 'GET',
      params,
      signal,
    });
  };
};

export const getTimeReportGetTimeReportQueryKey = (
  params?: TimeReportGetTimeReportParams
) => {
  return [
    `/api/TimeReport/GetTimeReport`,
    ...(params ? [params] : []),
  ] as const;
};

export const useTimeReportGetTimeReportQueryOptions = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportHook>>
  >,
  TError = unknown,
>(
  params?: TimeReportGetTimeReportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportHook>>>,
      TError,
      TData
    >;
  }
) => {
  const { query: queryOptions } = options ?? {};

  const queryKey =
    queryOptions?.queryKey ?? getTimeReportGetTimeReportQueryKey(params);

  const timeReportGetTimeReport = useTimeReportGetTimeReportHook();

  const queryFn: QueryFunction<
    Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportHook>>>
  > = ({ signal }) => timeReportGetTimeReport(params, signal);

  return { queryKey, queryFn, ...queryOptions } as UseQueryOptions<
    Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportHook>>>,
    TError,
    TData
  > & { queryKey: QueryKey };
};

export type TimeReportGetTimeReportQueryResult = NonNullable<
  Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportHook>>>
>;
export type TimeReportGetTimeReportQueryError = unknown;

export const useTimeReportGetTimeReport = <
  TData = Awaited<
    ReturnType<ReturnType<typeof useTimeReportGetTimeReportHook>>
  >,
  TError = unknown,
>(
  params?: TimeReportGetTimeReportParams,
  options?: {
    query?: UseQueryOptions<
      Awaited<ReturnType<ReturnType<typeof useTimeReportGetTimeReportHook>>>,
      TError,
      TData
    >;
  }
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const queryOptions = useTimeReportGetTimeReportQueryOptions(params, options);

  const query = useQuery(queryOptions) as UseQueryResult<TData, TError> & {
    queryKey: QueryKey;
  };

  query.queryKey = queryOptions.queryKey;

  return query;
};
