import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import { FC, useState } from 'react';
import { useCopyToClipboard } from 'usehooks-ts';

interface Props {
  errorMessage: string;
}

const ErrorMessageModal: FC<Props> = ({ errorMessage }) => {
  const modalStack = useModalStack();
  const [, copy] = useCopyToClipboard();

  const [copyText, setCopyText] = useState('Kopiera felmeddelande');

  return (
    <Modal
      buttons={[
        {
          label: 'Stäng',
          onClick: () => {
            modalStack.pop();
          },
        },
        {
          label: copyText,
          onClick: () => {
            copy(errorMessage);
            setCopyText('Felmeddelandet har kopierats till urklipp');
            setTimeout(() => {
              setCopyText('Kopiera felmeddelande');
            }, 2000);
          },
        },
      ]}
      title="Felmeddelande"
    >
      <code>{errorMessage}</code>
    </Modal>
  );
};

export default ErrorMessageModal;
