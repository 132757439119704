import React, { FC, useMemo } from 'react';
import { ValidationResults } from 'hooks/useForm';
import styled from 'styled-components';

const Wrapper = styled.ul`
  padding: 10px 0;
`;

const Validation = styled.li<{ err?: boolean }>`
  color: ${({ theme, err }) =>
    err ? theme.colors.foreground.error : theme.colors.foreground.warning};
`;

interface Props {
  validations: ValidationResults<string>;
}

const FormValidations: FC<Props> = ({ validations }) => {
  const allValidations = useMemo(
    () =>
      Object.values(validations).sort((a, b) =>
        a?.error && !b?.error ? -1 : a?.error && b?.error ? 0 : 1
      ),
    [validations]
  );
  const trimmedValidations = allValidations.slice(0, 2);

  return allValidations.length > 0 ? (
    <Wrapper>
      {trimmedValidations.map((validation, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <Validation err={!!validation?.error} key={i}>
          {validation?.error ?? validation?.warning}
        </Validation>
      ))}
      {allValidations.length > trimmedValidations.length && <li>...</li>}
    </Wrapper>
  ) : null;
};

export default FormValidations;
