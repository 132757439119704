import { FC, useCallback, useEffect } from 'react';

import DetailedCase from 'components/DetailedCase';
import Modal, { ModalButton } from 'components/Modal';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import { useDetailedCaseFormControl } from 'components/DetailedCase/DetailedCaseFormProvider';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import DetailedCaseProvider from './DetailedCase/DetailedCaseProvider';
import { CaseTypeEnum } from 'api';
import {
  useCopyCaseState,
  useCopyCaseValue,
} from 'contexts/basicData/useCopyCase';
import { RequestStatus } from 'swaggerhooks/lib';
import { generatePath, useLocation, useNavigate } from 'react-router-dom';
import Routes from 'constants/Routes';

interface Props {
  onClose(): void;
  onCaseCreated(caseId: number): void;
  caseType: CaseTypeEnum;
}

const CreateCaseModal: FC<Props> = ({ onClose, onCaseCreated, caseType }) => {
  const { triggerAssignmentsUpdate } = useAssignmentsFilters();
  const caseCopy = useCopyCaseValue();
  const [, setCaseCopy] = useCopyCaseState();
  const location = useLocation();
  const navigate = useNavigate();

  const {
    save,
    startEdit,
    copy: copyCase,
    createCaseStatus,
    hasErrors,
  } = useDetailedCaseFormControl();

  useEffect(() => {
    if (caseCopy) {
      copyCase(); // copy the case
      setCaseCopy(null); // reset the case copy
    } else {
      startEdit(null, caseType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleSaveClick = useCallback(async () => {
    const [caseId, error] = await save();

    if (!error && caseId !== null) {
      onCaseCreated(caseId);
      triggerAssignmentsUpdate(new Date());

      const assignmentPath =
        location.pathname === Routes.internalDeliveryGroup.index
          ? Routes.internalDeliveryGroup.assignments.index
          : location.pathname;

      navigate(`${generatePath(assignmentPath)}/${caseId}`);
    }
  }, [
    location.pathname,
    navigate,
    onCaseCreated,
    save,
    triggerAssignmentsUpdate,
  ]);

  const renderBody = () => {
    switch (createCaseStatus) {
      case RequestStatus.Fetching:
        return <LoadingSpinner>Skapar uppdrag...</LoadingSpinner>;

      case RequestStatus.Idle:
      case RequestStatus.Fetched:
      case RequestStatus.Error:
      default:
        return <DetailedCase />;
    }
  };

  const getButtons = (): ModalButton[] | undefined => {
    if (createCaseStatus !== RequestStatus.Fetching) {
      let modalButtons: ModalButton[] = [];

      modalButtons = [
        ...modalButtons,
        {
          label: 'Skapa uppdrag',
          disabled: hasErrors,
          onClick: handleSaveClick,
        },
        {
          label: 'Avbryt',
          onClick: handleClose,
        },
      ];

      return modalButtons;
    }

    return undefined;
  };

  return (
    <Modal
      buttons={getButtons()}
      closeOnOutsideClick={false}
      onClose={handleClose}
      title="Skapa uppdrag"
    >
      {renderBody()}
    </Modal>
  );
};

const ContextWrappedCreateCaseModal: FC<Props> = (props) => (
  <DetailedCaseProvider>
    <CreateCaseModal {...props} />
  </DetailedCaseProvider>
);

export default ContextWrappedCreateCaseModal;
