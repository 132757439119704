/**
 * Generated by orval v6.28.2 🍺
 * Do not edit manually.
 * Zäkra API 1.0
 * REST API for Zäkra
 * OpenAPI spec version: v1
 */

export type AbsenceReason = (typeof AbsenceReason)[keyof typeof AbsenceReason];

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const AbsenceReason = {
  ILLNESS: 0,
  CARE_OF_CHILDREN: 1,
  CARE_OF_RELATIVE: 2,
  VACATION: 3,
  OTHER: 4,
  REST_DAY: 5,
} as const;
