import { FC, useEffect } from 'react';
import { generatePath, Outlet, useNavigate } from 'react-router-dom';

import AssignmentsList from 'components/AssignmentsList';
import { AssignmentExtendedModel } from 'api';
import Routes from 'constants/Routes';
import useRideUpdates from './useRideUpdates';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import Roles from 'constants/Roles';
import useMe from 'contexts/authentication/useMe';
import useAssignmentsBulkOperations from 'pages/GlobalSearch/useAssignmentsBulkOperations';
import BulkMessages from 'pages/GlobalSearch/BulkMessages';
import BulkOperations from 'pages/GlobalSearch/BulkOperations';

const CasesWithRideUpdatesPage: FC = () => {
  const navigate = useNavigate();
  const { rideUpdates, refetchRideUpdates, loading } = useRideUpdates();
  const me = useMe();
  const userIsGoSupport = me?.roles.includes(Roles.GoSupport);

  useEffect(() => {
    refetchRideUpdates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleAssignmentItemClick = (model: AssignmentExtendedModel) => {
    navigate(
      generatePath(Routes.rideUpdates.case, { id: String(model.case.caseID) })
    );
  };

  const assignmentsBulkOperations = useAssignmentsBulkOperations(
    rideUpdates ?? [],
    () => refetchRideUpdates()
  );

  const bulkMessagesButton = userIsGoSupport ? (
    <BulkMessages bulkOperations={assignmentsBulkOperations} />
  ) : null;

  const renderList = () => {
    if (!rideUpdates) return <LoadingSpinner>Laddar...</LoadingSpinner>;

    return (
      <>
        {!!rideUpdates.length && userIsGoSupport && (
          <BulkOperations
            bulkActions={[bulkMessagesButton]}
            bulkOperations={assignmentsBulkOperations}
            handleAssignmentItemClick={(model: AssignmentExtendedModel) =>
              handleAssignmentItemClick(model)
            }
          />
        )}
        <AssignmentsList
          assignments={rideUpdates}
          bulkOperations={assignmentsBulkOperations}
          loading={loading}
          onAssignmentItemClick={handleAssignmentItemClick}
          onAssignmentUpdated={() => refetchRideUpdates()}
          showStatusColors
        />
      </>
    );
  };

  return (
    <>
      <Outlet />
      {renderList()}
    </>
  );
};

export default CasesWithRideUpdatesPage;
