import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const StyledRangeInput = styled.input.attrs({ type: 'range' })<{
  percentage: number;
  highlightLeft: boolean;
  reverseDisplay: boolean;
}>`
  cursor: pointer;
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 5px;
  outline: none;
  background: ${({ theme, percentage, highlightLeft, reverseDisplay }) =>
    `linear-gradient(
      to right,
      ${
        highlightLeft
          ? reverseDisplay
            ? theme.colors.background.secondary
            : theme.colors.foreground.tint
          : reverseDisplay
            ? theme.colors.foreground.tint
            : theme.colors.background.secondary
      } 0%,
      ${
        highlightLeft
          ? reverseDisplay
            ? theme.colors.background.secondary
            : theme.colors.foreground.tint
          : reverseDisplay
            ? theme.colors.foreground.tint
            : theme.colors.background.secondary
      } ${percentage}%,
      ${
        highlightLeft
          ? reverseDisplay
            ? theme.colors.foreground.tint
            : theme.colors.background.secondary
          : reverseDisplay
            ? theme.colors.background.secondary
            : theme.colors.foreground.tint
      } ${percentage}%,
      ${
        highlightLeft
          ? reverseDisplay
            ? theme.colors.foreground.tint
            : theme.colors.background.secondary
          : reverseDisplay
            ? theme.colors.background.secondary
            : theme.colors.foreground.tint
      } 100%
    )`};

  /* Hide the default slider thumb */
  &::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 0;
    height: 0;
    background: transparent;
    cursor: pointer;
    border: none;
  }
  &::-moz-range-thumb {
    width: 0;
    height: 0;
    background: transparent;
    cursor: pointer;
    border: none;
  }

  /* For IE */
  &::-ms-thumb {
    width: 0;
    height: 0;
    background: transparent;
    cursor: pointer;
    border: none;
  }
`;

const Wrapper = styled.div<{ showLabel?: boolean; disabled?: boolean }>`
  display: grid;
  grid-template-columns: ${({ showLabel }) =>
    showLabel
      ? '50px 1fr'
      : '1fr'}; /* Set a fixed width for the label column */
  align-items: center;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: 0.4;
  `}
`;

const SliderContainer = styled.div`
  position: relative;
  width: 100%;
`;

const ThumbIconWrapper = styled.div<{ position: number; height: number }>`
  position: absolute;
  top: ${({ height }) => height - 6}px; /* Adjust based on the icon size */
  left: ${({ position }) =>
    `calc(${position}% - 8px)`}; /* Adjust for icon width */
  pointer-events: none; /* Allow slider interaction through the icon */
`;

const Label = styled.span`
  min-width: 50px; /* Set a minimum width */
  max-width: 50px; /* Set a maximum width */
  overflow: hidden;
  text-overflow: ellipsis; /* If the label is too long, it will be truncated */
  white-space: nowrap; /* Prevent the label from wrapping */
`;
export type SliderOption = {
  label: string;
  value: number;
};

type SliderProps = {
  options: SliderOption[];
  value: number;
  onChange: (value: number) => void;
  highlightLeft?: boolean;
  reverseDisplay?: boolean;
  sliderIcon?: React.ReactNode;
  showLabel?: boolean;
  disabled?: boolean;
};

const Slider = ({
  options,
  onChange,
  value,
  highlightLeft = true,
  reverseDisplay = false,
  sliderIcon,
  showLabel,
  disabled,
}: SliderProps) => {
  const maxIndex = options.length - 1;

  // Adjust the displayed value based on reverseDisplay
  const displayedValue = reverseDisplay ? maxIndex - value : value;

  // Calculate the percentage for the background gradient
  const percentage = (displayedValue / maxIndex) * 100;

  // Ref for the input range element
  const rangeRef = useRef<HTMLInputElement>(null);

  // State to store the thumb position
  const [thumbPosition, setThumbPosition] = useState(percentage);

  useEffect(() => {
    setThumbPosition(percentage);
  }, [percentage]);

  return (
    <Wrapper disabled={disabled}>
      {/* Display the correct label based on displayedValue */}
      {showLabel && <Label>{options[value].label}</Label>}
      <SliderContainer>
        <StyledRangeInput
          disabled={disabled}
          ref={rangeRef}
          min="0"
          max={maxIndex}
          value={displayedValue}
          onChange={(e) => {
            const newValue = Number(e.target.value);
            // Adjust the newValue back to the original value
            const adjustedValue = reverseDisplay
              ? maxIndex - newValue
              : newValue;
            onChange(adjustedValue);
          }}
          percentage={percentage}
          highlightLeft={highlightLeft}
          reverseDisplay={reverseDisplay}
        />
        <ThumbIconWrapper position={thumbPosition} height={8}>
          {sliderIcon || <FontAwesomeIcon icon={faCircle} />}
        </ThumbIconWrapper>
      </SliderContainer>
    </Wrapper>
  );
};

export default Slider;
