import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import styled from 'styled-components';
import { inputStyle, InputStyleProps } from './Input';
import TextButton from './TextButton';

const InputWrapper = styled.div`
  ${inputStyle};
  padding: 0;
  display: flex;
  flex-direction: row;
  overflow: hidden;

  input {
    flex: 1;
    width: 0;
    margin: 0;
    padding: ${({ small }) => (small ? '1px 5px' : '5px 10px')};

    border: none;
    font: inherit;
  }
`;

const RemoveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 5px;

  font: inherit;
  border: none;
  border-left: 1px solid ${({ theme }) => theme.colors.border.primary};
  color: ${({ theme }) => theme.colors.foreground.error};
  background: transparent;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.colors.foreground.error};
    color: ${({ theme }) => theme.colors.background.primary};
  }
`;

interface Props extends React.HTMLProps<HTMLInputElement>, InputStyleProps {
  isRemoved: boolean;
  onRemoveClick(): void;
  addLabel: string;
  onAddClick(): void;
}

const RemovableInput = React.forwardRef<HTMLInputElement, Props>(
  (
    { isRemoved, onRemoveClick, addLabel, onAddClick, small, width, ...props },
    ref
  ) => {
    if (isRemoved)
      return (
        <TextButton
          onClick={(eve) => {
            eve.preventDefault();
            onAddClick();
          }}
        >
          {addLabel}
        </TextButton>
      );

    return (
      <InputWrapper small={small} width={width}>
        <input {...props} ref={ref} />
        <RemoveButton
          onClick={(eve) => {
            eve.preventDefault();
            onRemoveClick();
          }}
        >
          <FontAwesomeIcon icon={faTimes} />
        </RemoveButton>
      </InputWrapper>
    );
  }
);

export default RemovableInput;
