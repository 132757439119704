import { EventModel } from 'api';
import { FC } from 'react';
import styled, { css } from 'styled-components';
import { useDetailedCaseContext } from '../DetailedCaseProvider';
import {
  applyEventFilter,
  commentEventFilter,
  EventFilter,
  rideUpdatesEventFilter,
} from './useFilteredEvents';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 5px 0;
  gap: 5px;
`;

const FilterPill = styled.button<{
  active?: boolean;
  alert?: boolean;
  filterType?: 'comment' | 'rideUpdate';
}>`
  padding: 2px 10px;

  --foreground-color: ${({ theme, active, alert, filterType }) => {
    if (active) return theme.colors.foreground.button;
    if (alert)
      switch (filterType) {
        case 'rideUpdate':
          return theme.colors.foreground.error;
        case 'comment':
          return theme.colors.foreground.newMessage;
        default:
          break;
      }

    return theme.colors.foreground.primary;
  }};
  --background-color: ${({ theme, active, alert, filterType }) => {
    if (active) {
      if (alert) {
        switch (filterType) {
          case 'rideUpdate':
            return theme.colors.foreground.error;
          case 'comment':
            return theme.colors.foreground.newMessage;
          default:
            break;
        }
      }
      return theme.colors.background.button;
    }
    return theme.colors.background.primary;
  }};

  border-radius: ${({ theme }) => theme.sizes.radius.input}px;
  border: 1px solid
    ${({ theme, active }) =>
      active ? 'var(--background-color)' : theme.colors.background.button};
  background: var(--background-color);
  color: ${({ active }) => (active ? css`var(--foreground-color)` : 'inherit')};
  font: inherit;
  font-size: ${({ theme }) => theme.sizes.font.small};
  font-weight: ${({ alert, active }) => (alert && active ? '600' : 'inherit')};
  cursor: pointer;
`;

const NotifyCircle = styled.span`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-width: 1.5em;
  min-height: 1.5em;
  padding: 2px;
  border-radius: 1000px;
  background-color: var(--foreground-color);
  color: var(--background-color);
  font-size: 0.8em;
  font-weight: 600;
`;

interface Props {
  allEvents: EventModel[];
  selectedFilter: EventFilter | null;
  onSelectFilter(filter: EventFilter | null): void;
}

const EventsFilterPicker: FC<Props> = ({
  allEvents,
  selectedFilter,
  onSelectFilter,
}) => {
  const { caseResponse } = useDetailedCaseContext();

  return (
    <Wrapper>
      <FilterPill
        active={selectedFilter === null}
        onClick={() => onSelectFilter(null)}
      >
        Alla
      </FilterPill>

      <FilterPill
        active={selectedFilter === commentEventFilter}
        alert={caseResponse.response?.case.adminHasUnreadComments}
        filterType="comment"
        onClick={() => onSelectFilter(commentEventFilter)}
      >
        {commentEventFilter.name}{' '}
        {caseResponse.response?.case.adminHasUnreadComments && (
          <NotifyCircle>
            {
              applyEventFilter(allEvents, commentEventFilter).filter(
                (e) => !e.readByAdmin
              ).length
            }
          </NotifyCircle>
        )}
      </FilterPill>

      <FilterPill
        active={selectedFilter === rideUpdatesEventFilter}
        alert={caseResponse.response?.case.newDataFromRide}
        filterType="rideUpdate"
        onClick={() => onSelectFilter(rideUpdatesEventFilter)}
      >
        {rideUpdatesEventFilter.name}{' '}
        {caseResponse.response?.case.newDataFromRide && (
          <NotifyCircle>
            {applyEventFilter(allEvents, rideUpdatesEventFilter).length}
          </NotifyCircle>
        )}
      </FilterPill>
    </Wrapper>
  );
};

export default EventsFilterPicker;
