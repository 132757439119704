import React from 'react';
import { RatingDataDto } from 'api/model';
import styled from 'styled-components';
import RatingWidget from 'components/RatingWidget';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  background: ${({ theme }) => theme.colors.background.quaternary};
  gap: 0.5rem;
  width: 100%;
`;

const RatingNumber = styled.div`
  font-size: 2rem;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.background.primary};
`;

const RatingDescription = styled.div`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.background.primary};
`;

type Props = {
  averageRating: number;
  ratings: RatingDataDto[];
  expanded?: boolean;
};

const UserRatingStats: React.FC<Props> = ({
  averageRating,
  ratings,
  expanded = true,
}) => {
  // const theme = useTheme();
  return expanded ? (
    <Wrapper>
      <RatingNumber>{averageRating.toFixed(1)}</RatingNumber>
      <RatingWidget
        initialRating={averageRating}
        viewMode
        // color={theme.colors.foreground.tint}
      />
      <RatingDescription>{ratings.length} svarande</RatingDescription>
    </Wrapper>
  ) : (
    <span>{averageRating.toFixed(1)}</span>
  );
};

export default UserRatingStats;
