import { AssignmentViewModel } from 'api/model';
import Modal from 'components/Modal';
import AutoSizedTextArea from 'components/inputs/AutoSizedTextArea';
import Checkbox from 'components/inputs/Checkbox';
import { FC, useMemo, useState } from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  max-width: 800px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

const ReasonInput = styled(AutoSizedTextArea)`
  width: 100%;
  min-height: 100px;
`;

interface Props {
  title: string;
  message: string | React.ReactNode;
  assignments: AssignmentViewModel[];
  onConfirm: (assignmentIDs: number[], reason: string) => void;
  onCancel: () => void;
  isLoading?: boolean;
}

const AssignmentRequestCarKeysModal: FC<Props> = ({
  title,
  message,
  assignments,
  onConfirm,
  onCancel,
  isLoading,
}) => {
  const [reason, setReason] = useState<string>('');
  const [selectedAssignments, setSelectedAssignments] = useState<Set<number>>(
    new Set()
  );

  const options = useMemo(() => {
    return assignments.map((avm) => ({
      id: avm.assignment.assignmentID,
      value: avm.assignment.assignmentID,
      label: avm.case?.registrationNumber,
    }));
  }, [assignments]);

  const renderContent = () => {
    return (
      <ContentWrapper>
        <p>{message}</p>
        {options.map((option) => {
          return (
            <CheckboxWrapper>
              <Checkbox
                onChange={() => {
                  const newValue = new Set(selectedAssignments);
                  if (selectedAssignments.has(option.value)) {
                    newValue.delete(option.value);
                  } else {
                    newValue.add(option.value);
                  }
                  setSelectedAssignments(newValue);
                }}
              />
              {option.label}
            </CheckboxWrapper>
          );
        })}
        <ReasonInput
          value={reason}
          onChange={(eve) => setReason(eve.currentTarget.value)}
          placeholder="Ange anledning"
        />
      </ContentWrapper>
    );
  };

  return (
    <Modal
      buttons={[
        {
          label: 'Bekräfta',
          disabled: isLoading || selectedAssignments.size === 0,
          onClick: () => onConfirm([...selectedAssignments], reason),
        },
        {
          label: 'Avbryt',
          onClick: onCancel,
        },
      ]}
      closeOnOutsideClick={false}
      onClose={onCancel}
      title={title}
    >
      {renderContent()}
    </Modal>
  );
};

export default AssignmentRequestCarKeysModal;
