import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from 'components/inputs/Button';
import Modal from 'components/Modal';
import TransparentSpinner from 'components/spinners/TransparentSpinner';
import { FC, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import useAssignmentsBulkOperations from './useAssignmentsBulkOperations';
import TextArea from 'components/inputs/TextArea';
import { useForm } from 'react-hook-form';

const Wrapper = styled.div``;

interface Props {
  bulkOperations: ReturnType<typeof useAssignmentsBulkOperations>;
}

const BulkMessages: FC<Props> = ({ bulkOperations }) => {
  const theme = useTheme();
  const [showMessageModal, setShowMessageModal] = useState(false);
  const { register, reset, getValues } = useForm();

  const handleSubmitMessages = () => {
    const message = getValues('bulkMessage');
    bulkOperations.messageSelectedAssignments(message);
  };

  return (
    <Wrapper>
      <Button
        disabled={
          bulkOperations.selectedAssignmentIds.size === 0 ||
          bulkOperations.sendMessageStatus === 'loading'
        }
        onClick={() => setShowMessageModal(true)}
      >
        {bulkOperations.sendMessageStatus === 'loading' ? (
          <TransparentSpinner color={theme.colors.foreground.button} small />
        ) : (
          `Meddela ${bulkOperations.selectedAssignmentIds.size || ''} uppdrag`
        )}
      </Button>

      {showMessageModal && (
        <Modal
          buttons={[
            {
              label: 'Avbryt',
              onClick: () => {
                reset();
                setShowMessageModal(false);
              },
            },
            {
              icon: <FontAwesomeIcon icon={faCheck} />,
              label: `Skicka meddelande till ${bulkOperations.selectedAssignmentIds.size} uppdrag`,
              disabled: false,
              onClick: () => {
                handleSubmitMessages();
                reset();
                setShowMessageModal(false);
              },
            },
          ]}
          onClose={() => {
            reset();
            setShowMessageModal(false);
          }}
          title={`Utskick till ${bulkOperations.selectedAssignmentIds.size} uppdrag`}
        >
          <TextArea
            {...register('bulkMessage')}
            style={{ width: '100%', minHeight: 100 }}
            placeholder="Skriv ditt meddelande här"
          />
        </Modal>
      )}
    </Wrapper>
  );
};

export default BulkMessages;
