import {
  faBell,
  faCheckCircle,
  faClock,
  faHome,
  faTaxi,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ApprovalStatus, CaseEventType, EventModel } from 'api';
import { FC, useCallback } from 'react';
import styled from 'styled-components';
import { EventSection, Message } from './components';
import DefaultEventHeader from './DefaultEventHeader';
import useMe from 'contexts/authentication/useMe';
import Roles from 'constants/Roles';
import useUsers from 'contexts/basicData/useUsers';
import { formatDateTime } from 'utils/date-helpers';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import { formatAddress, IAddress } from 'utils/address';
import useTranslations from 'contexts/basicData/useTranslations';
import AuthorizedButton from 'components/inputs/AuthorizedButton';
import {
  useCaseApproveCaseRequest,
  useCaseDenyCaseRequest,
} from 'api/case/case';
import { UserJsonData } from './types';
import roleUtil from 'utils/role-util';

const parentHoverButton = 'parentHoverButton';
const MyEventSection = styled(EventSection)`
  &:hover .${parentHoverButton}, ${`.${parentHoverButton}`}:focus-within {
    background: ${({ theme }) => theme.colors.background.button};
    color: ${({ theme }) => theme.colors.foreground.button};
  }
`;

const MyMessage = styled(Message)`
  flex: 1;
  padding-top: 0;
`;

const StatusWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  padding-top: 5px;
  margin-top: 5px;
  border-top: 1px dotted ${({ theme }) => theme.colors.border.light};
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
`;

const Status = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
`;

const AcceptedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.foreground.good};
`;

const DecinedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.foreground.error};
`;

const NotifyIcon = styled(FontAwesomeIcon)`
  margin-left: 10px;
  color: ${({ theme }) => theme.colors.foreground.newMessage};
`;

const RequestState = styled.span`
  font-style: italic;
  font-size: 0.8em;
`;

type LimitedCaseEventType =
  | CaseEventType.TaxiRequest
  | CaseEventType.BringVehicleHomeRequest
  | CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy;

const Icons: { [key in LimitedCaseEventType]: IconDefinition } = {
  [CaseEventType.TaxiRequest]: faTaxi,
  [CaseEventType.BringVehicleHomeRequest]: faHome,
  [CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy]: faClock,
};

interface Props {
  event: EventModel;
  onUpdate?(): void;
}

const CaseAssignmentRequest: FC<Props> = ({ event, onUpdate }) => {
  const me = useMe();
  const users = useUsers();

  const getUserName = useCallback(
    (jsonData: string) => {
      try {
        const userData = JSON.parse(jsonData) as UserJsonData;
        return users[userData.UserId]?.name ?? '-';
      } catch (error) {
        return '-';
      }
    },
    [users]
  );
  const { caseEventTypes, approvalStatuses } = useTranslations();

  const approveRequestCall = useCaseApproveCaseRequest();

  const declineRequestCall = useCaseDenyCaseRequest();

  const getHeader = useCallback(
    (ev: EventModel) => {
      switch (ev.caseEventType) {
        case CaseEventType.TaxiRequest:
          return ` Taxi-förfrågan för ${ev.assignmentID}`;
        case CaseEventType.BringVehicleHomeRequest:
          return 'Privat mellanlandning';
        case CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy:
          return caseEventTypes[ev.caseEventType];
        default:
          return '';
      }
    },
    [caseEventTypes]
  );

  const disableButtons =
    approveRequestCall.status === 'loading' ||
    declineRequestCall.status === 'loading' ||
    (event.caseEventType ===
      CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy &&
      !me?.isAllowedToOverrideAssigneeWarnings);

  const getActionsOrResult = useCallback(() => {
    return (
      <div>
        {!event.requestHandled ||
        event.approvalStatus === ApprovalStatus.REQUESTED ? (
          <ButtonWrapper>
            <AuthorizedButton
              disabled={disableButtons}
              loading={declineRequestCall.status === 'loading'}
              negative
              onClick={async () => {
                await declineRequestCall.mutateAsync({
                  params: {
                    eventId: event.id,
                  },
                });
                onUpdate?.();
              }}
              roles={[Roles.Admin, Roles.SuperAdmin]}
            >
              Avslå
            </AuthorizedButton>
            <AuthorizedButton
              disabled={disableButtons}
              loading={approveRequestCall.status === 'loading'}
              onClick={async () => {
                await approveRequestCall.mutateAsync({
                  params: {
                    eventId: event.id,
                  },
                });
                onUpdate?.();
              }}
              positive
              roles={[Roles.Admin, Roles.SuperAdmin]}
            >
              Godkänn
            </AuthorizedButton>
          </ButtonWrapper>
        ) : (
          <StatusWrapper>
            <>
              {event.approvalStatus === ApprovalStatus.APPROVED && (
                <Status>
                  <AcceptedIcon icon={faCheckCircle} title="Godkänd" />
                  <p>Godkänd</p>
                </Status>
              )}
              {event.approvalStatus === ApprovalStatus.DECLINED && (
                <Status>
                  <DecinedIcon icon={faCircleXmark} title="Avslagen" />
                  <p>Avslagen</p>
                </Status>
              )}
            </>

            <RequestState>
              {`${
                event.approvalStatus !== undefined &&
                approvalStatuses[event.approvalStatus]
              } ${
                event.requestHandledByID === -1
                  ? 'av ZäkraGo'
                  : roleUtil.hasRoleOverlap(
                        [
                          Roles.SuperAdmin,
                          Roles.GoSupport,
                          Roles.TrafikLedarAdmin,
                        ],
                        me?.roles
                      )
                    ? `av ${users[event.requestHandledByID!]?.name}`
                    : ''
              } ${formatDateTime(event.requestHandled!)}`}
            </RequestState>
          </StatusWrapper>
        )}
      </div>
    );
  }, [
    disableButtons,
    event.requestHandled,
    event.approvalStatus,
    event.requestHandledByID,
    event.id,
    me?.roles,
    declineRequestCall,
    approveRequestCall,
    approvalStatuses,
    users,
    onUpdate,
  ]);

  return (
    <MyEventSection>
      <DefaultEventHeader
        created={event.created}
        headerText={
          <>
            <FontAwesomeIcon
              icon={Icons[event.caseEventType as LimitedCaseEventType]}
            />{' '}
            {getHeader(event)}
            {!event.readByAdmin && <NotifyIcon icon={faBell} title="Oläst" />}
          </>
        }
        modified={event.modified}
        userName={event.modifiedBy}
      />

      <>
        <MyMessage>{event.message}</MyMessage>
        <br />
        {event.caseEventType === CaseEventType.BringVehicleHomeRequest &&
          event.data && (
            <MyMessage>
              <strong>Begärd mellanlandningsadress</strong>
              <br />
              {formatAddress(JSON.parse(event.data) as IAddress)}
            </MyMessage>
          )}
        {event.caseEventType ===
          CaseEventType.RequestAssignFieldTesterOutsideDrivingPolicy &&
          event.data && (
            <MyMessage>
              <strong>Begärd för</strong>
              <br />
              {getUserName(event.data)}
            </MyMessage>
          )}
        {me?.roles.includes(Roles.Admin) && getActionsOrResult()}
      </>
    </MyEventSection>
  );
};

export default CaseAssignmentRequest;
