import { ZipCodeArea } from 'api';
import useTranslations from 'contexts/basicData/useTranslations';
import { useMemo } from 'react';

const useZipCodeAreas = () => {
  const { zipCodeAreas } = useTranslations();

  const allZipCodeAreas = useMemo(
    () =>
      Object.values(zipCodeAreas).sort((a, b) =>
        a.areaName > b.areaName ? 1 : -1
      ),
    [zipCodeAreas]
  );

  const zipCodeAreaByName = useMemo(() => {
    const areasByName = {} as { [areaName: string]: ZipCodeArea };
    allZipCodeAreas.forEach((zca) => {
      if (!(zca.areaName in areasByName)) {
        areasByName[zca.areaName] = zca;
      }
    });

    return areasByName;
  }, [allZipCodeAreas]);

  const selectableZipCodeAreas = useMemo(() => {
    return Object.values(zipCodeAreaByName).sort((a, b) =>
      a.areaName > b.areaName ? 1 : -1
    );
  }, [zipCodeAreaByName]);

  const zipCodeAreaFromZipCode = (zipCode: string) => {
    const zipNumber = Number(zipCode.replace(' ', ''));
    if (!Number.isNaN(zipNumber) && zipNumber >= 10000) {
      const zipArea = allZipCodeAreas.find(
        (zca) => zca.minCode <= zipNumber && zca.maxCode >= zipNumber
      );
      return zipArea?.areaName !== undefined
        ? zipCodeAreaByName[zipArea.areaName]
        : undefined;
    }

    return undefined;
  };

  return {
    allZipCodeAreas,
    zipCodeAreaByName,
    selectableZipCodeAreas,
    zipCodeAreaFromZipCode,
  };
};

export default useZipCodeAreas;
