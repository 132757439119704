import { useCallback, useMemo } from 'react';
import { atom, useRecoilState } from 'recoil';

import {
  AssignmentDeviationEnum,
  AssignmentStatusEnum,
  CaseStatusEnum,
} from 'api';

import {
  AssignmentSearchState,
  getInitialAssignmentSearchState,
} from './types';
import { SortState } from 'components/Table';
import { AdvancedAssignmentFilterOption, CaseTypeEnum } from 'api/model';

const assignmentSearchAtom = atom<AssignmentSearchState>({
  key: 'AssignmentSearch',
  default: getInitialAssignmentSearchState(),
});

interface AssignmentSearchProps {
  readonly state: AssignmentSearchState;
  setCaseTypes: (caseTypes: CaseTypeEnum[]) => void;
  setAssignmentStatuses: (statuses: AssignmentStatusEnum[]) => void;
  setAssignmentDeviations: (deviations: AssignmentDeviationEnum[]) => void;
  setBusinessUnits: (units: number[]) => void;
  setCaseStatuses: (statuses: CaseStatusEnum[]) => void;
  setSearchText: (text: string) => void;
  setSortState(sortState: SortState): void;
  setDateRange(daterange: [Date | null, Date | null]): void;
  setAdvancedFilters(filters: AdvancedAssignmentFilterOption[]): void;
  setFromAddress(fromAddress: string): void;
  stateEqualsFilterPreset(filterPreset: {
    statuses: Set<AssignmentStatusEnum>;
    deviations: Set<AssignmentDeviationEnum>;
  }): boolean;
  setSkip(skip: number): void;

  /** Quick and dirty hack to trigger a refetch within useFilteredAssignments().
   * Pass down a unique value to trigger the refetch (new Date() for example) */
  triggerAssignmentsUpdate(uniqueValue: any): void;
}

const useAssignmentSearch = (): AssignmentSearchProps => {
  const [state, setState] = useRecoilState(assignmentSearchAtom);

  const setAdvancedFilters = useCallback(
    (filters: AdvancedAssignmentFilterOption[]) => {
      setState((s) => ({
        ...s,
        advancedFilters: filters,
      }));
    },
    [setState]
  );

  const setFromAddress = useCallback(
    (fromAddress: string) => {
      setState((s) => ({
        ...s,
        fromAddress,
      }));
    },
    [setState]
  );

  const setCaseTypes = useCallback(
    (caseTypes: CaseTypeEnum[]) => {
      setState((s) => ({
        ...s,
        caseTypes,
      }));
    },
    [setState]
  );

  const setAssignmentStatuses = useCallback(
    (statuses: AssignmentStatusEnum[]) => {
      setState((s) => ({
        ...s,
        assignmentStatuses: statuses,
      }));
    },
    [setState]
  );

  const setAssignmentDeviations = useCallback(
    (deviations: AssignmentDeviationEnum[]) => {
      setState((s) => ({
        ...s,
        assignmentDeviations: deviations,
      }));
    },
    [setState]
  );

  const setBusinessUnits = useCallback(
    (units: number[]) => {
      setState((s) => ({
        ...s,
        businessUnits: units,
      }));
    },
    [setState]
  );

  const setCaseStatuses = useCallback(
    (statuses: CaseStatusEnum[]) => {
      setState((s) => ({
        ...s,
        caseStatuses: statuses,
      }));
    },
    [setState]
  );

  const setSortState = useCallback(
    (sortState: SortState) => {
      setState((s) => ({
        ...s,
        sortedColumnIndex: sortState.sortedColumnIndex,
        sortAscending: sortState.ascending,
      }));
    },
    [setState]
  );

  const setSearchText = useCallback(
    (text: string) => {
      setState((s) => ({
        ...s,
        searchText: text,
      }));
    },
    [setState]
  );

  const setSkip = useCallback(
    (skip: number) => {
      setState((s) => ({
        ...s,
        skip,
      }));
    },
    [setState]
  );

  const setDateRange = useCallback(
    (daterange: [Date | null, Date | null]) => {
      const startDate = daterange[0]
        ? new Date(
            daterange[0].getFullYear(),
            daterange[0].getMonth(),
            daterange[0].getDate(),
            0,
            0,
            0,
            0
          )
        : null;

      const endDate = daterange[1]
        ? new Date(
            daterange[1].getFullYear(),
            daterange[1].getMonth(),
            daterange[1].getDate(),
            23,
            59,
            59,
            999
          )
        : null;

      setState((s) => ({
        ...s,
        daterange: [startDate, endDate],
      }));
    },
    [setState]
  );

  const triggerAssignmentsUpdate = useCallback(
    (uniqueValue: any) => {
      setState((s) => ({
        ...s,
        triggerAssignmentsUpdate: uniqueValue,
      }));
    },
    [setState]
  );

  const stateEqualsFilterPreset = useCallback(
    (filterPreset: {
      statuses: Set<AssignmentStatusEnum>;
      deviations: Set<AssignmentDeviationEnum>;
    }) => {
      if (
        state.caseStatuses.length !== 0 ||
        state.assignmentStatuses.length !== filterPreset.statuses.size ||
        state.assignmentDeviations.length !== filterPreset.deviations.size
      ) {
        return false;
      }

      for (const selectedStatus of state.assignmentStatuses) {
        if (!filterPreset.statuses.has(selectedStatus)) return false;
      }

      for (const selectedDeviation of state.assignmentDeviations) {
        if (!filterPreset.deviations.has(selectedDeviation)) return false;
      }

      return true;
    },
    [
      state.assignmentDeviations,
      state.assignmentStatuses,
      state.caseStatuses.length,
    ]
  );

  return useMemo(
    (): AssignmentSearchProps => ({
      state,
      setAdvancedFilters,
      setAssignmentStatuses,
      setAssignmentDeviations,
      setBusinessUnits,
      setCaseTypes,
      setCaseStatuses,
      setSortState,
      setSearchText,
      setDateRange,
      setFromAddress,
      triggerAssignmentsUpdate,
      stateEqualsFilterPreset,
      setSkip,
    }),
    [
      state,
      setAdvancedFilters,
      setAssignmentStatuses,
      setAssignmentDeviations,
      setBusinessUnits,
      setCaseTypes,
      setCaseStatuses,
      setSortState,
      setSearchText,
      setDateRange,
      setFromAddress,
      triggerAssignmentsUpdate,
      stateEqualsFilterPreset,
      setSkip,
    ]
  );
};

export default useAssignmentSearch;
