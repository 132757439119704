import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import './mapstyles.css';
import { MarkerClusterer } from '@googlemaps/markerclusterer';
import { formatTime } from 'utils/date-helpers';
import { loadMapApi } from './loadMapApi';
import { makeGoogleMapsComputeRoutesApiCall } from './utils';
import { CaseEventType } from 'api';
import useSpeedLimitAlerts from 'contexts/basicData/useSpeedLimitAlerts';
import speedUtil from 'utils/speed';

const API_KEY = 'AIzaSyCBUtx73pL8IJgyM7ELhPSBFYvxjHZnuTY';

export const MapIcons = {
  warningSign: 'images/warning_sign.svg',
  vehicleLive: 'images/pulsating_car.svg',
  vehicleStill: 'images/car.svg',
  vehicleInactive: 'images/car_inactive.svg',
  dot: 'images/regular_dot.svg',
  warningDot: 'images/warning_dot.svg',
  locationDot: 'images/location-dot.svg',
  [CaseEventType.AssignmentStarted]: 'images/play.svg',
  [CaseEventType.AssignmentArrived]: 'images/check-to-slot.svg',
  [CaseEventType.AssignmentReturning]: 'images/arrow-left-long.svg',
  [CaseEventType.AssignmentCompleted]: 'images/stop.svg',
  [CaseEventType.AssignmentTestComplete]: 'images/check-to-slot.svg',
  [CaseEventType.AssignmentInspected]: 'images/check-to-slot.svg',
  [CaseEventType.CarKeyPhoto]: 'images/key.svg',
  [CaseEventType.CasePending]: 'images/pause.svg',
};

const getMarkerInfoContent = (
  speedLimits: { [key: number]: number },
  markers?: google.maps.Marker[]
) => {
  if (!markers) return null;

  const content: string[] = [];
  markers.forEach((marker) => {
    const title = marker.getTitle();
    const link = marker.get('link');
    const speed = marker.get('speed');
    const missingSpeedData = marker.get('missingSpeedData');

    const speedLimit = marker.get('speedLimit');
    const timestamp = marker.get('timestamp');

    content.push(
      link
        ? `<a href='${link}'>${title} </a><span>${formatTime(
            timestamp
          )}</<span>`
        : `<span>${title} ${formatTime(timestamp)}</<span>`
    );

    if (speed) {
      const alertForSpeedLimit = speedUtil.alertSpeedLimit(
        speedLimits,
        speed,
        speedLimit
      );
      content.push(
        `<span class="${alertForSpeedLimit ? 'warning' : ''}">Hastighet: ${
          Math.round((speed + Number.EPSILON) * 100) / 100
        } km/h</span>${
          alertForSpeedLimit
            ? ` <img src="${MapIcons.warningSign}" alt='${title}'>`
            : ''
        }`
      );
    }

    if (speedLimit) {
      content.push(`<span>Hastighetsbegränsning: ${speedLimit} km/h</span>`);
    }

    if (missingSpeedData) {
      content.push(
        `<span class="notabene"><em>Hastighetsdata saknades från enheten och beräknades istället m.h.a. tids- och platsskillnad från föregående position.</em></span>`
      );
    }
  });

  return content.map((c) => `<div class='infobox-row'>${c}</div>`).join('');
};

export interface GoogleMapsContextProps {
  scriptLoaded: boolean;
  map: google.maps.Map | undefined;
  markers: google.maps.Marker[];
  cluster: MarkerClusterer | undefined;
  createMap: (
    ref: HTMLDivElement,
    mapZoom: number,
    mapCenter: google.maps.LatLng,
    zoomControl?: boolean
  ) => google.maps.Map | undefined;
  addMarkers: (positions: google.maps.LatLng[]) => void;
  addCustomMarkers: (
    markers: CustomMarker[],
    withCluster?: boolean
  ) => google.maps.Marker[];
  clearMarkers: () => void;
  drawRoute: (locations: google.maps.LatLng[]) => void;
  clearRoute: () => void;
  fitBounds: (markers?: google.maps.Marker[], padding?: number) => void; // fit map to outermost marker bounds
}

const GoogleMapsContext = createContext<GoogleMapsContextProps>({
  scriptLoaded: false,
  map: undefined,
  markers: [],
  createMap: () => undefined,
  clearMarkers: () => {},
  cluster: undefined,
  addMarkers: () => {},
  addCustomMarkers: () => [],
  drawRoute: () => {},
  clearRoute: () => {},
  fitBounds: () => {},
});

export type CustomMarker = {
  position: {
    lat: number;
    lng: number;
  };
  timestamp?: Date | undefined;
  speed?: number;
  missingSpeedData?: boolean;
  speedLimit?: number;
  label: string | undefined;
  icon: string;
  link?: string;
  zIndex?: number;
};

let clusterClickTime = 0;

export const GoogleMapsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [map, setMap] = useState<google.maps.Map>();
  const markers = useRef<google.maps.Marker[]>([]);
  const cluster = useRef<MarkerClusterer>();
  const [region, setRegion] = useState<google.maps.Polyline>();
  const [scriptLoaded, setScriptLoaded] = useState<boolean>(false);
  const markerInfoWindow = useRef<google.maps.InfoWindow | null>(null);
  const clusterInfoWindow = useRef<google.maps.InfoWindow | null>(null);
  const speedLimitAlerts = useSpeedLimitAlerts();

  // set the info window refs when we have access to the google api
  useEffect(() => {
    if (scriptLoaded) {
      markerInfoWindow.current = new google.maps.InfoWindow();
      clusterInfoWindow.current = new google.maps.InfoWindow();
    }
  }, [scriptLoaded]);

  useEffect(() => {
    const mapListener = () => {
      markerInfoWindow.current?.close();
    };
    if (map && !google.maps.event.hasListeners(mapListener, 'click')) {
      google.maps.event.addListener(map, 'click', mapListener);
    }
  }, [map]);

  const drawRoute = useCallback(
    async (locations: google.maps.LatLng[]) => {
      if (locations.length < 2) {
        // eslint-disable-next-line no-console
        console.log('A route must have two or more locations');
        return;
      }

      const response = await makeGoogleMapsComputeRoutesApiCall(locations);

      if (!response.success || !response.encodedPolyline) {
        // eslint-disable-next-line no-console
        console.log('Failed to get response from computeRoutes');
        return;
      }

      const decodedPath = google.maps.geometry.encoding.decodePath(
        response.encodedPolyline
      );

      if (map) {
        setRegion(
          new google.maps.Polyline({
            path: decodedPath,
            strokeColor: '#FF0000',
            strokeOpacity: 1.0,
            strokeWeight: 2,
            map,
          })
        );
      }
    },
    [map]
  );

  const clearRoute = useCallback(() => {
    if (region) {
      setRegion(undefined);
    }
  }, [region]);

  const clearMarkers = useCallback(() => {
    markers.current.forEach((marker) => {
      if (marker instanceof google.maps.Marker) {
        marker.setMap(null);
      }
    });
    if (cluster.current) {
      cluster.current.clearMarkers();
      cluster.current.setMap(null);
    }

    cluster.current = undefined;
    markers.current = [];
  }, [cluster, markers]);

  const fitBounds = useCallback(
    (_markers?: google.maps.Marker[], padding = 0) => {
      if (map) {
        const bounds = new google.maps.LatLngBounds();
        const markersToCheckForBounds = _markers ?? markers.current;
        markersToCheckForBounds.forEach((marker) => {
          if (marker instanceof google.maps.Marker) {
            const pos = marker.getPosition();
            if (pos instanceof google.maps.LatLng) {
              bounds.extend(pos);
            }
          }
        });
        // map.fitBounds(bounds, padding);
        setTimeout(() => {
          map.fitBounds(bounds, padding);
        }, 1);
      }
    },
    [map, markers]
  );

  const addCustomMarkers = useCallback(
    (data: CustomMarker[], withCluster?: boolean) => {
      if (map) {
        // Create an info window to share between markers.
        const newMarkers: google.maps.Marker[] = [];

        data.forEach((d) => {
          const newMarker = new google.maps.Marker({
            position: d.position,
            icon: d.icon
              ? {
                  url: d.icon,
                  origin: new google.maps.Point(0, 0),
                  anchor: new google.maps.Point(10, 10),
                  scaledSize: new google.maps.Size(20, 20),
                }
              : undefined,
          });
          if (d.zIndex !== undefined) {
            newMarker.setZIndex(d.zIndex);
          }

          newMarker.setTitle(d.label);
          newMarker.set('link', d.link);
          newMarker.set('icon', d.icon);
          newMarker.set('timestamp', d.timestamp);
          newMarker.set('speed', d.speed);
          newMarker.set('missingSpeedData', d.missingSpeedData);
          newMarker.set('speedLimit', d.speedLimit);
          newMarker.setMap(map);

          newMarker.addListener('click', () => {
            markerInfoWindow.current?.setZIndex(999);
            markerInfoWindow.current?.setContent(
              getMarkerInfoContent(speedLimitAlerts, [newMarker])
            );
            markerInfoWindow.current?.open(map, newMarker);
          });
          newMarkers.push(newMarker);
        });

        markers.current = [...markers.current, ...newMarkers];

        if (withCluster) {
          // Add a marker clusterer to manage the markers.

          const newCluster = new MarkerClusterer({
            markers: newMarkers as google.maps.Marker[],
            map,
          });

          newCluster.onClusterClick = (event, c) => {
            const now = Date.now();
            if (now - clusterClickTime < 800) {
              // double click
              fitBounds(c.markers as google.maps.Marker[]);
            } else {
              // single click
              clusterInfoWindow.current?.setContent(
                getMarkerInfoContent(
                  speedLimitAlerts,
                  c.markers as google.maps.Marker[]
                )
              );
              clusterInfoWindow.current?.open(map, c.marker);
            }
            clusterClickTime = Date.now();
          };

          cluster.current = newCluster;
        }
        return markers.current;
      }
      return [];
    },
    [fitBounds, map, speedLimitAlerts]
  );

  const addMarkers = useCallback(
    (positions: google.maps.LatLng[]) => {
      if (map) {
        const newMarkers: google.maps.Marker[] = [];

        positions.forEach((position) => {
          const newMarker = new google.maps.Marker({
            position,
            icon: {
              url: MapIcons.vehicleStill,
              origin: new google.maps.Point(0, 0),
              anchor: new google.maps.Point(50, 50),
              scaledSize: new google.maps.Size(100, 100),
            },
          });
          newMarker.setMap(map);

          newMarkers.push(newMarker);
        });

        // setMarkers(newMarkers);
        markers.current = newMarkers;
      }
    },
    [map]
  );

  useEffect(() => {
    const googleMapScript = loadMapApi(API_KEY);

    googleMapScript.addEventListener('load', () => {
      setScriptLoaded(true);
    });

    return () => {
      googleMapScript.removeEventListener('load', () => {
        setScriptLoaded(false);
      });
    };
  }, []);

  const createMap = useCallback(
    (
      ref: HTMLDivElement,
      mapZoom: number,
      mapCenter: google.maps.LatLng,
      zoomControl?: boolean
    ) => {
      const newMap = new google.maps.Map(ref, {
        zoom: mapZoom,
        center: mapCenter,
        mapTypeControl: false,
        streetViewControl: false,
        rotateControl: false,
        scaleControl: true,
        fullscreenControl: false,
        panControl: false,
        zoomControl,
        scrollwheel: true,
        gestureHandling: 'greedy',
        mapTypeId: google.maps.MapTypeId.ROADMAP,
        draggableCursor: 'pointer',
        clickableIcons: false,
        styles: [
          {
            featureType: 'poi',
            stylers: [{ visibility: 'off' }], // hides default points of interest
          },
        ],
      });

      setMap(newMap);
      return newMap;
    },
    []
  );

  const providerValue = useMemo(
    () => ({
      addCustomMarkers,
      addMarkers,
      clearMarkers,
      clearRoute,
      cluster: cluster.current,
      createMap,
      drawRoute,
      fitBounds,
      map,
      markers: markers.current,
      scriptLoaded,
    }),
    [
      addCustomMarkers,
      addMarkers,
      clearMarkers,
      clearRoute,
      cluster,
      createMap,
      drawRoute,
      fitBounds,
      map,
      markers,
      scriptLoaded,
    ]
  );

  return (
    <GoogleMapsContext.Provider value={providerValue}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

export const useGoogleMapsContext = () => {
  return useContext(GoogleMapsContext);
};
