import { CaseTypeEnum, ExpenseType } from 'api/model';

// eslint-disable-next-line import/prefer-default-export
export const emptyGuid = '00000000-0000-0000-0000-000000000000';

export const NavBarStateKey = 'NavBarState';
export enum NavBarState {
  Horizontal = 'Horizontal',
  Vertical = 'Vertical',
}

// Både case vyn och assignments listan, (ms)
export const refreshRate = 10000;
export const pollersErrorModalId = 'poller-error-modal';

export const swedenCenterCoords = {
  latitude: 59.3293,
  longitude: 18.0686,
};
export const userIDsExcemptFromEstimationValidation = [
  254, 1181, 272, 1182, 1571,
];

export const isExcemptFromEstimationValidation = (assigneeId: number) => {
  // if the assignee name is one of ET (id = 254), NT (id = 1181), or Autologik (id = 272), or Nacka Transport (id = 1182) then don't validate
  // hard-coded based on prod db ids. Not ideal, but a simple way to hack it for now.
  return userIDsExcemptFromEstimationValidation.includes(assigneeId);
};

/**
 * List of hard-coded estimated durations for cases based on list from Zäkra (see card 291 for details).
 */
export const hardCodedEstimatedDurations: Partial<
  Record<CaseTypeEnum, Record<string, number>>
> = {
  [CaseTypeEnum.Purchase]: {
    'stockholm-stockholm': 2.75 * 60 * 60 * 1000,
  },
};

export const userNamesExcemptFromEstimationValidation = [
  'ET',
  'NT',
  'Autologik',
  'Nacka Transport',
  'Kontoret',
];

export const expectableExpenses = [
  ExpenseType.Gas,
  ExpenseType.CarWash,
  ExpenseType.TireChange,
  ExpenseType.SJTicket,
  ExpenseType.Hotel,
  ExpenseType.FlightTicket,
  ExpenseType.Taxi,
  ExpenseType.SLTicket,
];

export const considerHighMileageAbove = 15000;
export const considerOldYearModelAgeYears = 10;

export const maxStandByTimeInHours = 8;
