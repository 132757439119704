import Table from 'components/Table';
import {
  focusableTableCellClassName,
  tableCellClassName,
} from 'components/Table/utils';
import { useAssignmentsFilters } from 'contexts/assignmentsFilters/useAssignmentsFilters';
import useTranslations from 'contexts/basicData/useTranslations';
import React, { useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import {
  AssignmentDeviationEnum,
  AssignmentStatusEnum,
  AssignmentViewModel,
  CaseStatusEnum,
  IAssignmentViewModel,
} from 'api';
import useQueuedAssignmentUpdates from './useQueuedAssignmentUpdates';
import useCompanies from 'contexts/basicData/useCompanies';
import { arraySpreadIf } from 'utils/spreading';
import useAssignmentListColumnSettings, {
  ColumnProps as AssignmentListColumnProps,
} from './useAssignmentListColumnSettings';
import useAssignmentsBulkOperations from 'pages/GlobalSearch/useAssignmentsBulkOperations';
import useActiveColumns, {
  TableIdentifierEnum,
} from 'contexts/basicData/useActiveColumns';
import useAssignmentSearch from 'contexts/assignmentSearch/useAssignmentSearch';
import { AssignmentTypeEnum } from 'api/model';

export const DotSpan = styled.span<{ dotColor?: string }>`
  height: 12px;
  width: 12px;
  background-color: ${({ dotColor }) =>
    dotColor ? `#${dotColor}` : 'transparent'};
  border-radius: 50%;
  display: inline-block;
  margin-right: 3px;
`;

const Center = styled.div`
  text-align: center;
`;

const newDataFromRideClassName = 'new-data-from-ride';
const assigneeIsUnavailableOnDate = 'assignee-is-unavailable-on-date';
const adminHasUnreadCommentsClassName = 'admin-has-unread-comments';
const furtherTransportAssignmentClassName = 'further-transport-assignment';
const statusClassNames = {
  Created: 'assignment-created',
  Assigned: 'assignment-assigned',
  Active: 'assignment-active',
  Complete: 'assignment-completed',
  Removed: 'assignment-removed',
  Approved: 'assignment-approved',
  CasePending: 'case-pending',
};
const makeStatusClassName = (assignment: IAssignmentViewModel) => {
  if (assignment.case.caseStatusID === CaseStatusEnum.Pending) {
    return statusClassNames.CasePending;
  }
  switch (assignment.assignment.assignmentDeviationID) {
    case AssignmentDeviationEnum.Aborted:
    case AssignmentDeviationEnum.Canceled:
      return statusClassNames.Removed;
    default:
    // do nothigg;
  }
  switch (assignment.assignment.assignmentStatusID) {
    case AssignmentStatusEnum.Created:
      return statusClassNames.Created;

    case AssignmentStatusEnum.Assigned:
      return statusClassNames.Assigned;

    case AssignmentStatusEnum.Accepted:
    case AssignmentStatusEnum.Started:
    case AssignmentStatusEnum.Arrived:
    case AssignmentStatusEnum.TestComplete:
    case AssignmentStatusEnum.Returning:
      return statusClassNames.Active;

    case AssignmentStatusEnum.Complete:
      return statusClassNames.Complete;

    case AssignmentStatusEnum.Approved:
      return statusClassNames.Approved;

    case AssignmentStatusEnum.Canceled:
    case AssignmentStatusEnum.Aborted:
      return statusClassNames.Removed;

    default:
      return '';
  }
};

const assignmentIdRowClassName = (assignmentId: number | string): string =>
  `assignment-row-${assignmentId}`;

const rowClassNameMaker = (row: IAssignmentViewModel): string =>
  assignmentIdRowClassName(row.assignment.assignmentID);

const coloredRowClassNameMaker = (row: IAssignmentViewModel): string =>
  [
    assignmentIdRowClassName(row.assignment.assignmentID),
    makeStatusClassName(row),
    ...arraySpreadIf(row.case.newDataFromRide, newDataFromRideClassName),
    ...arraySpreadIf(
      !!row.assignment.assigneeIsUnavailableOnDate,
      assigneeIsUnavailableOnDate
    ),
    ...arraySpreadIf(
      row.case.adminHasUnreadComments,
      adminHasUnreadCommentsClassName
    ),
    ...arraySpreadIf(
      row.assignment.assignmentTypeID ===
        AssignmentTypeEnum.PlannedFurtherTransport,
      furtherTransportAssignmentClassName
    ),
  ].join(' ');

const TableStylerWrapper = styled.div<{ savingAssignmentIds: string[] }>`
  display: contents;

  ${({ savingAssignmentIds }) =>
    savingAssignmentIds.map(
      (id) => css`
        .${assignmentIdRowClassName(id)} {
          animation: assignmentlist-save-animation 1s infinite;
        }
      `
    )}

  @keyframes assignmentlist-save-animation {
    0% {
      background: #ddeefd;
    }
    50% {
      background: ${({ theme }) => theme.colors.background.primary};
    }
    100% {
      background: #ddeefd;
    }
  }
`;

const MyTable = styled(Table)`
  flex: 1;

  /* when a cell is focused, allow overflow for UserPicker */
  ${`.${tableCellClassName}`}:focus-within {
    overflow: visible;
  }

  ${`.${focusableTableCellClassName}`}:focus-within {
    padding: 0;
  }

  .${statusClassNames.Created},
    .${statusClassNames.Assigned},
    .${statusClassNames.Complete},
    .${statusClassNames.CasePending} {
    border-bottom: 1px solid ${({ theme }) => theme.colors.border.light};
  }

  .${statusClassNames.Created} {
    background-color: rgb(249 237 204);
    font-weight: 500;
  }
  .${statusClassNames.Assigned} {
    background: rgb(255 252 224);
  }
  .${statusClassNames.Active} {
  }
  .${statusClassNames.Complete} {
    background-color: rgb(228 243 222);
    font-weight: 500;
  }
  .${statusClassNames.Approved} {
    //background-color: #d7ffc1;
  }
  .${statusClassNames.Removed} .${tableCellClassName} {
    color: #c01111;
    text-decoration: line-through;
  }
  .${statusClassNames.CasePending} {
    background-color: #e7f4ff;
  }

  .${newDataFromRideClassName},
    .${adminHasUnreadCommentsClassName},
    .${assigneeIsUnavailableOnDate} {
    font-weight: bold;
  }

  .${furtherTransportAssignmentClassName} {
    background-color: ${({ theme }) => theme.colors.background.secondary};
  }
` as typeof Table;

interface Props {
  onAssignmentItemClick?(model: AssignmentViewModel): void;
  onAssignmentUpdated(): Promise<any>;
  assignments: AssignmentViewModel[];
  bulkOperations?: ReturnType<typeof useAssignmentsBulkOperations>;
  loading?: boolean;
  showStatusColors: boolean;
  currentPage?: number;
  onGoToPage?(page: number): void;
  totalPageCount?: number;
  pageSize?: number;
  hideEstimatedDurationAndStartTime?: boolean;
  tableIdentifier?: TableIdentifierEnum;
}

const AssignmentsList: React.FC<Props> = ({
  onAssignmentItemClick,
  onAssignmentUpdated,
  assignments,
  bulkOperations,
  loading,
  showStatusColors,
  currentPage,
  onGoToPage,
  totalPageCount,
  pageSize,
  hideEstimatedDurationAndStartTime,
  tableIdentifier,
}) => {
  const translations = useTranslations();
  const { companies } = useCompanies();
  const [activeColumns] = useActiveColumns(tableIdentifier);
  const {
    state: { sortAscending, sortedColumnIndex },
    setSortState: setAssignmentFilterSortState,
    setDate,
  } = useAssignmentsFilters();
  const { setSortState: setAssignmentSearchSortState } = useAssignmentSearch();
  const { enqueueAssignmentUpdate, queues } = useQueuedAssignmentUpdates(
    assignments,
    onAssignmentUpdated
  );

  const columnSettings = useAssignmentListColumnSettings(
    hideEstimatedDurationAndStartTime
  );

  const filteredColumnSettings = useMemo(() => {
    if (!tableIdentifier) return columnSettings;
    return columnSettings.filter((col) => {
      // show the column if it either has not yet been toggled off, or if it is not hidden by default
      if (
        col.identifier === undefined ||
        activeColumns[col.identifier] === true ||
        (activeColumns[col.identifier] === undefined &&
          col.hideByDefault !== true)
      )
        return true;
      return false;
    });
  }, [activeColumns, columnSettings, tableIdentifier]);

  const renderProps = useMemo(
    (): AssignmentListColumnProps => ({
      onSelectClick: (avm) => {
        bulkOperations?.toggleSelectAssignment(avm.assignment.assignmentID);
      },
      selectedAssignmentIds: bulkOperations?.selectedAssignmentIds ?? new Set(),
      translations,
      onUpdateAssignment: enqueueAssignmentUpdate,
      companies: companies.reduce(
        (acc, curr) => {
          let companyAddress = `${curr.address}${curr.zip}${curr.city}`;
          companyAddress = companyAddress.toLowerCase().replaceAll(' ', '');
          acc[companyAddress] = curr;
          return acc;
        },
        {} as AssignmentListColumnProps['companies']
      ),
    }),
    [bulkOperations, companies, enqueueAssignmentUpdate, translations]
  );

  const initialSortState = useRef({
    sortedColumnIndex,
    ascending: sortAscending,
  });

  const ongoingSaves = useMemo(() => Object.keys(queues), [queues]);

  useEffect(() => {
    setDate(new Date());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <TableStylerWrapper savingAssignmentIds={ongoingSaves}>
      {assignments.length === 0 ? (
        <Center>{loading ? 'Laddar' : 'Hittade inga uppdrag'}</Center>
      ) : (
        <MyTable
          columnSettings={filteredColumnSettings}
          currentPage={currentPage}
          fluid
          initialSortState={initialSortState.current}
          onGoToPage={onGoToPage}
          onRowClick={onAssignmentItemClick}
          onSortStateChange={(sortState) => {
            setAssignmentFilterSortState(sortState);
            setAssignmentSearchSortState(sortState);
          }}
          pageSize={pageSize}
          renderProps={renderProps}
          rowClassName={
            showStatusColors ? coloredRowClassNameMaker : rowClassNameMaker
          }
          rows={assignments}
          totalPageCount={totalPageCount}
          useColumnWidthAsFlexAmount={
            // when device width > 768
            window.innerWidth > 768
          }
        />
      )}
    </TableStylerWrapper>
  );
};

export default AssignmentsList;
