import { ExpenseType } from 'api/model';
import Select from 'components/inputs/Select';
import useTranslations from 'contexts/basicData/useTranslations';
import React from 'react';
import { alphabeticCompare } from 'utils/sorting';

const allExpenseTypes: ExpenseType[] = Object.values(ExpenseType).filter(
  (v) => typeof v === 'number'
) as ExpenseType[];

interface Props {
  onChange: (value: ExpenseType | undefined) => void;
  value: ExpenseType | undefined;
}
const ExpenseTypeSelector: React.FC<Props> = ({ onChange, value }) => {
  const { expenseTypes } = useTranslations();
  return (
    <Select
      onChange={(eve) => {
        const numberValue = Number(eve.target.value);
        if (!Number.isNaN(numberValue)) {
          onChange(numberValue as ExpenseType);
        }
      }}
      value={value}
    >
      {allExpenseTypes
        .sort((a, b) => alphabeticCompare(expenseTypes[a], expenseTypes[b]))
        .map((expenseType) => (
          <option key={expenseType} value={expenseType}>
            {expenseTypes[expenseType]}
          </option>
        ))}
    </Select>
  );
};
export default ExpenseTypeSelector;
