import styled from 'styled-components';
import useBetaFeatures from 'contexts/basicData/useBetaFeatures';
import Checkbox from 'components/inputs/Checkbox';

const Wrapper = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: flex-start;
`;

const BetaFeaturesPage = () => {
  const [betaFeaturesState, setBetaFeaturesState] = useBetaFeatures();

  return (
    <Wrapper>
      <Checkbox
        checked={betaFeaturesState?.showBetaFeatures}
        onChange={(e) =>
          setBetaFeaturesState({ showBetaFeatures: e.currentTarget.checked })
        }
      >
        Visa beta-features
      </Checkbox>
      <Checkbox
        checked={betaFeaturesState?.showCustomTableHead}
        onChange={(e) =>
          setBetaFeaturesState({
            ...betaFeaturesState,
            showCustomTableHead: e.currentTarget.checked,
          })
        }
      >
        Visa anpassat tabellhuvud
      </Checkbox>
    </Wrapper>
  );
};

export default BetaFeaturesPage;
