import { faCircleQuestion } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'components/Modal';
import useModalStack from 'contexts/modal/useModalStack';
import styled from 'styled-components';
import TextButton from './TextButton';

const Wrapper = styled.label`
  display: flex;
  flex-direction: column;
`;

const Label = styled.span`
  margin-bottom: 5px;
  min-height: 1em;
`;

// const SubLabel = styled.span`
//   min-height: 1em;
//   font-size: 0.8em;
// `;

const ErrorLabel = styled.span`
  color: ${({ theme }) => theme.colors.foreground.error};
  margin-left: 5px;
`;

interface Props {
  label?: React.ReactNode;
  errorLabel?: React.ReactNode;
  info?: {
    title: string;
    content: React.ReactNode;
  };
  className?: string;
  children?: React.ReactNode;
}

const LabelWrap = ({ label, errorLabel, info, className, children }: Props) => {
  const modalStack = useModalStack();
  return (
    <Wrapper className={className}>
      <Label>
        {label}
        {errorLabel && <ErrorLabel>{errorLabel}</ErrorLabel>}
        {info && (
          <TextButton
            onClick={() => {
              modalStack.push(
                <Modal
                  buttons={[{ label: 'Ok', onClick: () => modalStack.pop() }]}
                  onClose={() => modalStack.pop()}
                  title={info.title}
                >
                  {info.content}
                </Modal>
              );
            }}
            unpadded
          >
            <FontAwesomeIcon icon={faCircleQuestion} />
          </TextButton>
        )}
        {/* {subLabel && <SubLabel>{subLabel}</SubLabel>} */}
      </Label>
      {children}
    </Wrapper>
  );
};

export default LabelWrap;
