import { buttonStyle } from 'components/inputs/Button';
import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faAndroid,
  faApple,
  faAppStore,
} from '@fortawesome/free-brands-svg-icons';
import { RequestStatus, useResponse } from 'swaggerhooks/lib';
import { AppInstallClient } from 'api';
import LoadingSpinner from 'components/spinners/LoadingSpinner';
import SectionHeader from 'components/SectionHeader';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  gap: 20px;
`;

const Section = styled.section`
  display: flex;
  flex-direction: column;
  margin: 20px 40px 0px 20px;
`;

const InstallButton = styled.a`
  ${buttonStyle}
  margin: 10px 0;
  text-decoration: none;
  text-align: center;
`;

const MyIcon = styled(FontAwesomeIcon)`
  margin-right: 5px;
`;

const NumberedList = styled.ol`
  list-style-type: decimal;

  li {
    margin: 10px 0;
  }
`;

const zakraTestFlightUrl = 'https://testflight.apple.com/join/byoDSLSR';
const testflightAppUrl = 'https://apps.apple.com/se/app/testflight/id899247664';

const InstallAppPage: React.FC = () => {
  // const theme = useTheme();
  const appInfoResponse = useResponse(AppInstallClient, (client) =>
    client.getInstallData()
  );
  return (
    <Container>
      {appInfoResponse.status === RequestStatus.Fetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <Section>
            <SectionHeader>
              {`Zäkra Go iOS ${appInfoResponse.response?.iosVersionInfo?.version}`}
            </SectionHeader>
            <InstallButton href={testflightAppUrl}>
              <MyIcon icon={faAppStore} />
              Ladda ner Testflight-appen
            </InstallButton>
            <InstallButton href={zakraTestFlightUrl}>
              <MyIcon icon={faApple} />
              Ladda ner Zäkra Go-appen via Testflight
            </InstallButton>
            <NumberedList>
              <li>Ladda ner Testflight-appen ovan</li>
              <li>Ladda ner Zäkra Go-appen ovan</li>
              <li>Nu kan du börja använda appen!</li>
            </NumberedList>
          </Section>

          <Section>
            <SectionHeader>
              {`Zäkra Go Android ${appInfoResponse.response?.androidVersionInfo?.version}`}
            </SectionHeader>
            <InstallButton href={appInfoResponse.response?.androidInstallPath}>
              <MyIcon icon={faAndroid} /> Ladda ner Android-appen
            </InstallButton>
            <NumberedList>
              <li>Tryck på knappen ovan</li>
              <li>
                Spara och öppna app-filen (kan variera beroende på telefon och
                webbläsare)
              </li>
              <li>
                Du kan eventuellt behöva tillåta att appen ska få installeras av
                webbläsaren
              </li>
              <li>
                Tryck på <b>installera</b>
              </li>
            </NumberedList>
          </Section>
        </>
      )}
    </Container>
  );
};

export default InstallAppPage;
