type GoogleMapsDirectionsProps = {
  fromAddress: string;
  fromCity: string;
  fromZip: string;
  toAddress: string;
  toCity: string;
  toZip: string;
};

export const openGoogleMapsDirections = (props: GoogleMapsDirectionsProps) => {
  const { fromAddress, fromCity, fromZip, toAddress, toCity, toZip } = props;

  const from = `${fromAddress}, ${fromZip} ${fromCity}`;
  const to = `${toAddress}, ${toZip} ${toCity}`;

  window.open(
    `https://www.google.com/maps/dir/?api=1&origin=${encodeURIComponent(
      from
    )}&destination=${encodeURIComponent(to)}`,
    '_blank'
  );
};

export const openGoogleMapsPosition = (lat: number, long: number) => {
  window.open(
    `https://www.google.com/maps/search/?api=1&query=${lat},${long}`,
    '_blank'
  );
};

export default {
  openGoogleMapsDirections,
  openGoogleMapsPosition,
};
